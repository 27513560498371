import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';
import withPermissions from '@components/withTenantPermissions';
import tenantPermissions from '@utils/tenant-permissions';
import IconButton from '@components/Buttons/IconButtons/IconButton';
import useFetch from '@hooks/useFetch';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

export const analyseStatuses = {
  pending: 'Pending',
  started: 'Started',
  finished: 'Finished',
  error: 'Error',
  canceled: 'Canceled',
};

export function TermSheetAnalyseDisabledErrorMessage({
  hasNoTermSheetTemplate,
  wrongFormat,
}: {
  hasNoTermSheetTemplate: boolean;
  wrongFormat: boolean;
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        ml: 1,
        textAlign: 'center',
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontWeight: '700',
          fontSize: '14px',
          fontStyle: 'italic',
          color: theme.palette.grey[400],
        }}
      >
        {wrongFormat && (
          <FormattedMessage id="Driveview.TermSheet.Analyse.FormatNotSupported" />
        )}
        {!wrongFormat && hasNoTermSheetTemplate && (
          <FormattedMessage id="Driveview.TermSheet.Analyse.NoElementsToExtract" />
        )}
      </Typography>
    </Box>
  );
}

export function TermSheetAnalyseStatusProgressBar({
  color,
  progress,
}: {
  color: string;
  progress: number;
}) {
  return (
    <Box
      sx={{
        height: '7px',
        borderRadius: '6px',
        bgcolor: color,
        width: `${progress * 100}%`,
      }}
    />
  );
}

export function TermSheetAnalyseStatus({
  statusText,
  icon,
  colorIcon,
  colorProgressBar,
  progress,
}: {
  statusText: any;
  icon: any;
  colorIcon: string;
  colorProgressBar: string;
  progress: number;
}) {
  const isProgressing = progress !== 100;
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          bgcolor: colorIcon,
          width: '23px',
          height: '20px',
          textAlign: 'center',
          borderRadius: '15px',
          mt: 3,
        }}
      >
        {icon}
      </Box>
      <Box sx={{ ml: 2, width: '100%' }}>
        <Typography
          variant="body2"
          sx={{
            mb: 1,
            fontSize: '14px',
            fontWeight: 'bold',
            color: theme.palette.grey[700],
          }}
        >
          {statusText}
        </Typography>
        {isProgressing && (
          <>
            <TermSheetAnalyseStatusProgressBar
              color={theme.palette.chipInfo.light}
              progress={1}
            />
            <Box sx={{ marginTop: '-7px' }}>
              <TermSheetAnalyseStatusProgressBar
                color={colorProgressBar}
                progress={progress}
              />
            </Box>
          </>
        )}
        {!isProgressing && (
          <TermSheetAnalyseStatusProgressBar
            color={colorProgressBar}
            progress={1}
          />
        )}
      </Box>
    </Box>
  );
}

export function TermSheetAnalyseButton({
  icon,
  onClick,
}: {
  icon: any;
  onClick: () => void;
}) {
  const IconButtonWithPermission = withPermissions(IconButton);

  return (
    <IconButtonWithPermission
      permissions={[tenantPermissions.TermSheetValuesUpdate]}
      sx={{
        float: 'right',
        fontSize: '15px',
        color: 'primary',
        bgcolor: 'primary.200',
        width: '20px',
        height: '20px',
        mr: '3px',
      }}
      onClick={() => onClick()}
      edge="end"
    >
      {icon}
    </IconButtonWithPermission>
  );
}

export default function TermSheetAnalyseCard({
  documentAnalysis,
  hasTermSheetTemplate,
  document,
  refetchtermSheet,
}: {
  documentAnalysis: any;
  hasTermSheetTemplate: boolean;
  document: { id: string; name: string };
  refetchtermSheet: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const [analysis, setanalysis] = useState(documentAnalysis);

  const wrongFormat = document.name?.split('.').pop()?.toLowerCase() !== 'pdf';

  const theme = useTheme();

  const { doFetch: doAnalyse } = useFetch({
    url: `documents/${document.id}/analyses`,
    method: 'POST',
    onSuccess: (response: any) => {
      refetchtermSheet();
      setanalysis(response.data);
    },
    onError: () => {
      enqueueSnackbar(formatMessage({ id: 'Errors.ErrorOccured' }), {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  const { doFetch: doCancel } = useFetch({
    url: `documents/${document.id}/analyses/${analysis?.id}/cancel`,
    method: 'POST',
    onSuccess: (response: any) => {
      refetchtermSheet();
      setanalysis(response.data);
    },
    onError: () => {
      enqueueSnackbar(formatMessage({ id: 'Errors.ErrorOccured' }), {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  const { doFetch: reloadAnalyse } = useFetch({
    url: `documents/${document.id}/analyses/current`,
    method: 'GET',
    onSuccess: (response: any) => {
      setanalysis(response.data);
    },
    onError: () => {
      enqueueSnackbar(formatMessage({ id: 'Errors.ErrorOccured' }), {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  // If status is pending or started, call analyses/current every second to get progress
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        analysis?.status?.name === analyseStatuses.pending ||
        analysis?.status?.name === analyseStatuses.started
      ) {
        reloadAnalyse();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [analysis, reloadAnalyse]);

  return (
    <Box
      sx={{
        mt: 2,
        p: 2,
        pb: 5,
        bgcolor: 'white',
        width: '100%',
        height: 'max-content',
        borderRadius: '12px',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Typography
            sx={{
              position: 'absolute',
              color: 'white',
              fontSize: '11px',
              fontWeight: 'bold',
              ml: 0.8,
              mt: 0.4,
            }}
          >
            <FormattedMessage id="Driveview.TermSheet.Analyse.AI" />
          </Typography>
          <ChatBubbleIcon
            fontSize="medium"
            sx={{
              color:
                !hasTermSheetTemplate || wrongFormat
                  ? 'grey.400'
                  : 'primary.main',
            }}
          />
        </Box>
        <Typography
          variant="h4"
          sx={{
            mt: 0.2,
            ml: 0.5,
            pb: 2,
            textTransform: 'uppercase',
            fontWeight: '700',
            fontSize: '14px',
            color:
              !hasTermSheetTemplate || wrongFormat ? 'grey.400' : 'grey.700',
          }}
        >
          <FormattedMessage id="Driveview.TermSheet.Analyse.DataProcessing" />
        </Typography>
      </Box>

      {(wrongFormat || !hasTermSheetTemplate) && (
        <TermSheetAnalyseDisabledErrorMessage
          hasNoTermSheetTemplate={!hasTermSheetTemplate}
          wrongFormat={wrongFormat}
        />
      )}
      {!wrongFormat && hasTermSheetTemplate && (
        <>
          {/* Status */}
          {(!analysis || !analysis?.status) && (
            <TermSheetAnalyseStatus
              statusText={
                <FormattedMessage id="Driveview.TermSheet.Analyse.Status.TreatmentPending" />
              }
              icon={<MoreHorizIcon sx={{ fontSize: '20px', color: 'white' }} />}
              colorIcon={theme.palette.chipInfo.light}
              colorProgressBar={theme.palette.chipInfo.light}
              progress={1}
            />
          )}
          {(analysis?.status?.name === analyseStatuses.pending ||
            analysis?.status?.name === analyseStatuses.started) && (
            <TermSheetAnalyseStatus
              statusText={
                <FormattedMessage id="Driveview.TermSheet.Analyse.Status.TreatmentInProgress" />
              }
              icon={<MoreHorizIcon sx={{ fontSize: '20px', color: 'white' }} />}
              colorIcon={theme.palette.chipInfo.dark}
              colorProgressBar={theme.palette.chipInfo.dark}
              progress={analysis?.statusInformation?.completion ?? 0}
            />
          )}
          {analysis?.status?.name === analyseStatuses.finished && (
            <TermSheetAnalyseStatus
              statusText={
                <FormattedMessage id="Driveview.TermSheet.Analyse.Status.TreatmentSuccessful" />
              }
              icon={<DoneIcon sx={{ fontSize: '15px', color: 'white' }} />}
              colorIcon={theme.palette.chipSuccess.dark}
              colorProgressBar={theme.palette.chipSuccess.dark}
              progress={1}
            />
          )}
          {(analysis?.status?.name === analyseStatuses.canceled ||
            analysis?.status?.name === analyseStatuses.error) && (
            <TermSheetAnalyseStatus
              statusText={
                analysis?.status?.name === analyseStatuses.canceled ? (
                  <FormattedMessage id="Driveview.TermSheet.Analyse.Status.TreatmentCancelled" />
                ) : (
                  <FormattedMessage id="Driveview.TermSheet.Analyse.Status.TreatmentFailed" />
                )
              }
              icon={
                <PriorityHighIcon sx={{ fontSize: '15px', color: 'white' }} />
              }
              colorIcon={theme.palette.chipError.dark}
              colorProgressBar={theme.palette.chipError.light}
              progress={1}
            />
          )}

          {/* Button */}
          {(!analysis || !analysis?.status) && (
            <TermSheetAnalyseButton
              icon={<PlayArrowIcon sx={{ fontSize: '15px' }} />}
              onClick={doAnalyse}
            />
          )}
          {(analysis?.status?.name === analyseStatuses.pending ||
            analysis?.status?.name === analyseStatuses.started) && (
            <TermSheetAnalyseButton
              icon={<CloseIcon sx={{ fontSize: '15px' }} />}
              onClick={doCancel}
            />
          )}
          {(analysis?.status?.name === analyseStatuses.finished ||
            analysis?.status?.name === analyseStatuses.error ||
            analysis?.status?.name === analyseStatuses.canceled) && (
            <TermSheetAnalyseButton
              icon={<SyncIcon sx={{ fontSize: '15px' }} />}
              onClick={doAnalyse}
            />
          )}
        </>
      )}
    </Box>
  );
}
