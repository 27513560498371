import DialogActionsWithClose from '@components/Dialog/DialogActionsWithClose';
import DialogTitleWithClose from '@components/Dialog/DialogTitleWithClose';
import FormikProvider from '@components/Formik/FormikProvider';
import DialogContent from '@mui/material/DialogContent';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useOutletContext } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import FormikAutocomplete from '@components/Formik/FormikAutocomplete';
import FormikSelect from '@components/Formik/FormikSelect';
import MenuItem from '@mui/material/MenuItem';
import {
  buyersTypes,
  voucherPhysicalOptions,
  voucherSciOptions,
} from '@shells/nexity/nexity-constants';

const schema = Yup.object().shape({
  UserName: Yup.string().nullable().required('Generic.Fields.Required'),
  SelectedElt: Yup.string().nullable().required('Generic.Fields.Required'),
});

const initialValues = { UserName: '', SelectedElt: '' };

export default function RequestVoucherDialog({
  buyersType,
  onSubmit,
  requestingVoucher,
  buyers,
  existingDocuments,
  backVoucherPhysicalOptions,
  backVoucherSciOptions,
}) {
  const { close } = useOutletContext();
  const buyersNames =
    buyers?.map(buyer => `${buyer.FirstName} ${buyer.LastName}`) ?? [];
  const existingDocumentsNames =
    existingDocuments?.map(
      exitingDocument => exitingDocument.documentFolderName
    ) ?? [];
  const defaultOptions = useMemo(
    () =>
      buyersType === buyersTypes.sci
        ? backVoucherSciOptions ?? voucherSciOptions
        : backVoucherPhysicalOptions ?? voucherPhysicalOptions,
    [buyersType, backVoucherPhysicalOptions, backVoucherSciOptions]
  );
  const [options, setOptions] = useState(defaultOptions);

  return (
    <FormikProvider
      schema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <DialogTitleWithClose onClose={close}>
        Ajouter une pièce justificative
      </DialogTitleWithClose>
      <DialogContent>
        <FormikAutocomplete
          as="field"
          name="UserName"
          required
          label="Nom Acquéreur"
          size="small"
          disabled={requestingVoucher}
          options={buyersNames}
          onChange={value => {
            setOptions(
              defaultOptions.filter(
                option =>
                  !existingDocumentsNames.includes(`[${value}] - ${option}`) ||
                  option === 'Autre Justificatif'
              )
            );
          }}
        />
        <FormikSelect
          name="SelectedElt"
          required
          label="Type de pièce justificative"
          disabled={requestingVoucher}
        >
          {options?.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </FormikSelect>
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button type="submit" variant="contained" disabled={requestingVoucher}>
          <FormattedMessage
            id="Folders.RequestVoucherDialog.Button.Validate"
            defaultMessage="Valider"
          />
        </Button>
      </DialogActionsWithClose>
    </FormikProvider>
  );
}
