import { useMemo } from 'react';
import { useLocation, useResolvedPath, matchRoutes } from 'react-router-dom';

const joinPaths = paths => paths.join('/').replace(/\/\/+/g, '/');

const useRouteTabs = rawRouteTabs => {
  const location = useLocation();
  const basePath = useResolvedPath('');

  const mappedRouteTabs = useMemo(
    () =>
      rawRouteTabs.map((routeTab, index) => ({
        ...routeTab?.routeProps,
        path: joinPaths([basePath.pathname, routeTab?.routeProps?.path || '']),
        routeTab: {
          ...routeTab,
          index,
        },
      })),
    [rawRouteTabs, basePath.pathname]
  );

  const routeMatch = useMemo(() => {
    const routeMatches = matchRoutes(mappedRouteTabs, location);
    return routeMatches.length ? routeMatches[routeMatches.length - 1] : null;
  }, [mappedRouteTabs, location]);

  return {
    currentRouteTab: routeMatch.route.routeTab,
  };
};

export default useRouteTabs;
