import { useState } from 'react';

const useDisclosure = defaultIsOpen => {
  const [isOpen, setIsOpen] = useState(Boolean(defaultIsOpen));
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const onToggle = () => setIsOpen(!isOpen);

  return { isOpen, onOpen, onClose, onToggle };
};

export default useDisclosure;
