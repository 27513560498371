import { findParentNodeClosestToPos, findChildrenInRange } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import combineTransactionSteps from './helpers/combine-transaction-steps';
import getChangedRanges from './helpers/get-changed-ranges';
import { Numbering } from '@smartclause-tiptap-extensions/schema-core/dist';

const NumberingExtension = Numbering.extend({
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('numbering-level-assign'),
        appendTransaction: (transactions, oldState, newState) => {
          const docChanges =
            transactions.some(transaction => transaction.docChanged) &&
            !oldState.doc.eq(newState.doc);
          if (!docChanges) {
            return;
          }
          const { tr } = newState;
          const { types } = this.options;
          const transform = combineTransactionSteps(oldState.doc, transactions);
          // get changed ranges based on the old state
          const changes = getChangedRanges(transform);
          changes.forEach(change => {
            const newRange = {
              from: change.newStart,
              to: change.newEnd,
            };
            const newNodes = findChildrenInRange(
              newState.doc,
              newRange,
              node => {
                return types.includes(node.type.name);
              }
            );
            newNodes.forEach(({ node, pos }) => {
              // instead of checking `node.attrs[attributeName]` directly
              // we look at the current state of the node within `tr.doc`.
              // if the node changed multiple times within one transaction
              const currentLevel = tr.doc.nodeAt(pos)?.attrs['numberingLevel'];
              const newLevel = getNodeLevel(tr.doc, node.type.name, pos);
              if (currentLevel !== newLevel) {
                // update level
                tr.setNodeMarkup(
                  pos,
                  undefined,
                  {
                    ...node.attrs,
                    numberingLevel: newLevel,
                  },
                  node.marks
                );
                return;
              }
            });
          });
          if (!tr.steps.length) {
            return;
          }
          return tr;
        },
      }),
    ];
  },

  addCommands() {
    return {
      ...this.parent?.(),
      updateNumberingAttribute:
        (attribute, value) =>
          ({ tr }) => {
            const foundItem = findParentNodeClosestToPos(
              tr.doc.resolve(tr.selection.from),
              node => this.options.types.includes(node.type.name)
            );
            if (foundItem && foundItem.node.attrs[attribute] !== value) {
              tr.setNodeMarkup(
                foundItem.pos,
                undefined,
                {
                  ...foundItem.node.attrs,
                  [attribute]: value,
                },
                foundItem.node.marks
              );
            }
          },
      enableNumberingType:
        enabled =>
          ({ commands }) => {
            commands.updateNumberingAttribute('displayNumbering', enabled);
          },
      enableNumberingMultilevel:
        enabled =>
          ({ commands }) => {
            commands.updateNumberingAttribute('numberingMultilevel', enabled);
          },
      disableContinueNumbering:
        () =>
          ({ chain }) => {
            chain()
              .updateNumberingAttribute('continueNumbering', false)
              .updateNumberingAttribute('continueNumberingValue', undefined);
          },
      setContinueNumberingValue:
        value =>
          ({ chain }) => {
            chain()
              .updateNumberingAttribute('continueNumbering', true)
              .updateNumberingAttribute('continueNumberingValue', value);
          },
      setNumberingType:
        type =>
          ({ commands }) => {
            commands.updateNumberingAttribute('numberingType', type);
          },
      setNumberingPrefix:
        value =>
          ({ commands }) => {
            commands.updateNumberingAttribute(
              'numberingPrefix',
              (value ?? '').trim() + ' '
            );
          },
      setNumberingSuffix:
        value =>
          ({ commands }) => {
            commands.updateNumberingAttribute(
              'numberingSuffix',
              (value ?? '').trim() + ' '
            );
          },
      setnumberingMultilevelSeparator:
        value =>
          ({ commands }) => {
            commands.updateNumberingAttribute(
              'numberingMultilevelSeparator',
              value
            );
          },
    };
  },
});

export default NumberingExtension;

function getNodeLevel(doc, typeName, pos) {
  const parentData = findParentNodeClosestToPos(
    doc.resolve(pos),
    parent => parent.type.name === typeName
  );
  const parentNode = parentData ? doc.nodeAt(parentData.pos) : null;
  return (parentNode?.attrs.numberingLevel ?? 0) + 1;
}
