import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function ContactCard({
  firstName = '',
  lastName = '',
  address = '',
  email = '',
  phoneMobile = '',
}) {
  return (
    <Card sx={{ maxWidth: '400px' }}>
      <CardHeader
        avatar={<PersonIcon />}
        title={`${lastName} ${firstName}`}
        titleTypographyProps={{
          color: 'secondary',
          variant: 'h6',
          gutterBottom: false,
        }}
        sx={{ pb: 0 }}
      />
      <CardContent component={Stack} gap={2}>
        <Stack direction="row" gap={2}>
          <LocationOnIcon />
          <Typography>{address}</Typography>
        </Stack>
        <Stack direction="row" gap={2}>
          <AlternateEmailIcon />
          <Typography>{email}</Typography>
        </Stack>
        <Stack direction="row" gap={2}>
          <PhoneIcon />
          <Typography>{phoneMobile}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ContactCard;
