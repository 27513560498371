import useNexityTheme from './hooks/useNexityTheme';

export const blockDocumentTypes = {
  template: 'template',
  nexityPiece: 'nexity-piece',
  prospectPiece: 'prospect-piece',
} as const;

export const buyersTypes = {
  physical: 'physical',
  sci: 'sci',
} as const;

export const nexityRoles = {
  administrators: 'Administrators',
  animator: 'Animator',
  manager: 'Manager',
  affiliateValidator: 'Affiliate Validator',
  buyer: 'External',
  dealer: 'Dealer',
} as const;

export const nexityAdminViewRoles = [nexityRoles.administrators] as const;

export const nexityDashboardViewRoles = [
  nexityRoles.administrators,
  nexityRoles.animator,
  nexityRoles.manager,
  nexityRoles.affiliateValidator,
  nexityRoles.dealer,
] as const;

export const nexityStatisticsViewRoles = [
  nexityRoles.administrators,
  nexityRoles.animator,
  nexityRoles.manager,
  nexityRoles.affiliateValidator,
] as const;

export const nexityFolderStatuses = {
  created: 'CREATED',
  pendingValidation: 'PENDING_VALIDATION',
  validated: 'VALIDATED',
  purged: 'PURGED',
  generated: 'GENERATED',
  signed: 'SIGNED',
} as const;

export const nexitySignatureStatuses = {
  pending: 'pending',
  revoked: 'revoked',
} as const;

export const nexitySignatureOptions = {
  inPerson: 'in-person',
  online: 'online',
} as const;

export const voucherPhysicalOptions = [
  "Pièce d'identité (en cours de validité) - recto",
  "Pièce d'identité (en cours de validité) - verso",
  'Justificatif de domicile - 3 mois',
  "Avis d'imposition N-1",
  "Avis d'imposition N-2 (si prêt à taux 0%)",
  "L'attestation de dépôt de pièces",
  'Justificatif apport (si apport > 9 999€)',
  'Mandat / Pouvoir',
  'Justificatif de dépôt de garantie',
  'Autre Justificatif',
] as const;

export const voucherSciOptions = [
  'KBIS',
  "Pièce d'identité du dirigeant - recto",
  "Pièce d'identité du dirigeant - verso",
  'Statut',
  'Autre Justificatif',
] as const;

export const useNexityTimelineSteps = () => {
  const { name } = useNexityTheme();
  const entrepriseName = name === 'edouard-denis' ? 'Edouard Denis' : 'Nexity';
  return [
    'Entretien avec mon conseiller',
    'Complétion et validation de mes informations',
    `Validation de mon dossier par ${entrepriseName}`,
    `Création de mon contrat par ${entrepriseName}`,
    'Signature électronique de mon contrat',
    "C'est terminé !",
  ];
};

export const nexityUploadAccept = [
  '.pdf,.png,.jpg,.jpeg,.jpe,.jfif,.jif',
] as const;
