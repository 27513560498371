import { defineMessages } from 'react-intl';

export default defineMessages({
  UserPickerLabel: {
    id: 'Picker.User.Label',
    defaultMessage: 'Collaborator (Name or LDAP number)',
  },
  ContributorTypeUser: {
    id: 'Contributor.Pickers.Type.1.Label',
    defaultMessage: 'Users',
  },
  ContributorTypeRole: {
    id: 'Contributor.Pickers.Type.2.Label',
    defaultMessage: 'Roles',
  },
  ContributorTypeNone: {
    id: 'Contributor.Pickers.Type.0.Label',
    defaultMessage: 'None',
  },
  FileFieldValue: {
    id: 'FileField.Value',
    defaultMessage:
      '{length, select, 0 {} 1 {{name}} other {{length} files selected}}',
  },
});
