import { ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '@mui/material/Alert';
import FormattedMessageWithFallback, {
  defaultFallbackId,
} from '@components/Intl/FormattedMessageWithFallback';

type ErrorMessageProps = {
  error: any;
  mapper?: (error: any) => any;
  DetailComponent?: React.FC<{ error: any }>;
} & ComponentProps<typeof Alert>;

function ErrorMessage({
  error,
  mapper = x => x,
  sx = null,
  severity = 'error',
  children = null,
  DetailComponent = () => null,
}: ErrorMessageProps) {
  if (typeof error === 'boolean') {
    return null;
  }

  if (typeof error === 'string') {
    return (
      <Alert severity={severity} sx={sx}>
        <FormattedMessageWithFallback id={`Errors.${error}`} code={error} />
        {children}
      </Alert>
    );
  }

  if (error instanceof Error) {
    const id =
      error.message === 'Failed to fetch'
        ? `Errors.FailedToFetch`
        : `Errors.${error.message}`;
    return (
      <Alert severity={severity} sx={sx}>
        <FormattedMessageWithFallback id={id} code={error.message} />
        {children}
      </Alert>
    );
  }

  if (Array.isArray(error)) {
    if (!error.length) {
      return null;
    }
    return (
      <Alert severity={severity} sx={sx}>
        {error
          .flat()
          .map(mapper)
          .map(key => {
            const messageId = typeof key === 'object' ? key.message : key;
            return (
              <FormattedMessageWithFallback
                tagName="div"
                key={messageId}
                id={`Errors.${messageId}`}
                code={messageId}
              />
            );
          })}
        {children}
      </Alert>
    );
  }

  if (error instanceof Response) {
    const data = 'data' in error ? (error.data as any) : null;
    const errors = data?.Errors ?? data?.errors;
    if (errors) {
      return (
        <Alert severity={severity} sx={sx}>
          {Object.values(errors)
            .flat()
            .map(mapper)
            .map(key => {
              const messageId = typeof key === 'object' ? key.message : key;
              return (
                <>
                  <FormattedMessageWithFallback
                    tagName="div"
                    key={messageId}
                    id={`Errors.${messageId}`}
                    values={typeof key === 'object' ? { ...key } : undefined}
                    code={messageId}
                  />
                  <DetailComponent error={key} />
                </>
              );
            })}
          {children}
        </Alert>
      );
    }

    const message = data?.Message ?? data?.message;
    if (message) {
      return (
        <Alert severity={severity} sx={sx}>
          <FormattedMessage id={defaultFallbackId} values={{ code: message }} />
          {children}
        </Alert>
      );
    }

    const title = data?.Title ?? data?.title;
    if (title) {
      return (
        <Alert severity={severity} sx={sx}>
          <FormattedMessage id={defaultFallbackId} values={{ code: title }} />
          {children}
        </Alert>
      );
    }

    const { status } = error;
    if (status === 403) {
      return (
        <Alert severity={severity} sx={sx}>
          <FormattedMessage id="Errors.Forbidden" />
          {children}
        </Alert>
      );
    }

    return (
      <Alert severity={severity} sx={sx}>
        <FormattedMessage id="Errors.FailedToFetch" />
        {children}
      </Alert>
    );
  }

  return null;
}

export default ErrorMessage;
