import React from 'react';
import { useRecoilValue } from 'recoil';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { localeStateSelector } from '@recoil/locale';
import messages from '@i18n/messages';

function ScmIntlProvider({ children }) {
  const locale = useRecoilValue(localeStateSelector);
  return (
    <IntlProvider
      locale={locale}
      defaultLocale="en"
      messages={messages[locale]}
      onError={error => {
        if (error.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
          // eslint-disable-next-line no-console
          console.warn(error.message);
        } else {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }}
    >
      {children}
    </IntlProvider>
  );
}

export default ScmIntlProvider;
