import React from 'react';
import { isActive } from '@tiptap/core';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';

import FormatSizeIcon from '@mui/icons-material/FormatSize';

import { useTiptapEditor } from '../../use-tiptap-editor';
import MenuListItem from '../MenuListItem';

const fontSizeOptions = [
  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
  26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 60, 96,
];

const ITEM_HEIGHT = 36;

function ButtonFontSize() {
  const editor = useTiptapEditor();
  return (
    <PopupState variant="popper" popupId="font-size-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={<FormattedMessage id="Editor.Toolbar.Command.FontSize" />}
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <FormatSizeIcon />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <MenuList
                  sx={{ p: 0, maxHeight: ITEM_HEIGHT * 8, overflow: 'auto' }}
                  component={Paper}
                  elevation={8}
                >
                  {fontSizeOptions.map(size => (
                    <MenuListItem
                      key={size}
                      label={size}
                      onClick={() => {
                        editor.chain().focus().setFontSize(`${size}pt`).run();
                        popupState.close();
                      }}
                      selector={state =>
                        Boolean(state) &&
                        isActive(state, null, { fontSize: `${size}pt` })
                      }
                    />
                  ))}
                </MenuList>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonFontSize;
