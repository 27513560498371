/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FastField, Field } from 'formik';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import styled from '@emotion/styled';
import { getDateTimezoneIndependantFromTimestamp, getTimeFromDateTimezoneIndependant} from '@utils/date-helpers';

const StyledTextField = styled(TextField)({
  '& .MuiIconButton-root': {
    marginRight: 0,
  },
});

function MuiTimePicker({
  field,
  form: { errors, setFieldValue },
  readOnly,
  TextFieldProps = {},
  ...props
}) {
  const { name, value: timestampValue } = field;
  const error = Boolean(errors[name]);
  return (
    <TimePicker
      {...props}
      {...field}
      value={getDateTimezoneIndependantFromTimestamp(timestampValue)}
      onChange={newValue => {
        setFieldValue(
          name,
          newValue ? getTimeFromDateTimezoneIndependant(newValue) : ''
        );
      }}
      renderInput={params => (
        <StyledTextField
          fullWidth
          margin="normal"
          autoComplete="off"
          {...TextFieldProps}
          {...params}
          InputProps={{
            readOnly,
            ...params.InputProps,
          }}
          error={error}
          helperText={
            error && (
              <FormattedMessage
                id={errors[field.name]}
                values={{
                  label: props?.label ?? '',
                  value: field?.value ?? '',
                }}
              />
            )
          }
        />
      )}
    />
  );
}

function FormikTimePicker({ as = 'field', ...props }) {
  if (as === 'fastfield') {
    return <FastField component={MuiTimePicker} {...props} />;
  }
  return <Field component={MuiTimePicker} {...props} />;
}

export default FormikTimePicker;
