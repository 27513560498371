import { useTheme } from '@mui/styles';
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import useFetch from '@hooks/useFetch';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { useIntl } from 'react-intl';
import FilterWrapper, { getFilterName } from './FilterWrapper';
import { PopoverFiltersProps } from './helpers/types';

function getStyles(value: string, values: any, theme: any) {
  if (Array.isArray(values)) {
    return {
      fontWeight:
        values.indexOf(value) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  return {
    fontWeight:
      values !== value
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function SelectPopoverFilters({
  filter,
  onValidate = undefined,
  onRemoveFilter = undefined,
  advancedSearchRequest = undefined,
  setAdvancedSearchRequest = undefined,
  titleSx,
}: PopoverFiltersProps) {
  const intl = useIntl();
  const {
    id,
    data = [],
    multiple = false,
    showSearchButton,
    termSheetValueType = undefined,
  } = filter;
  const theme = useTheme();
  const [searchRequest, setSearchRequest] = useState(
    advancedSearchRequest ?? {}
  );

  const [localData, setLocalData] = useState(data);

  const elementSheetValues = useFetch({
    url: `term-sheets/elements/${id}/values`,
    lazy: !termSheetValueType,
    method: 'GET',
    onSuccess: (response: any) => {
      setLocalData(response?.data?.values);
    },
  });

  const getCurrentValue = () => {
    if (termSheetValueType) {
      const index = searchRequest?.termSheetQuery?.findIndex(
        (x: any) => x?.elementId === id
      );
      if (index >= 0) {
        return searchRequest?.termSheetQuery[index].items;
      }
      return multiple ? [] : '';
    }
    return searchRequest?.[id] ?? (multiple ? [] : '');
  };
  const setCurrentValue = (value: any, forceUpdate = false) => {
    const tmpValue = cloneDeep(searchRequest);
    if (termSheetValueType) {
      const index = tmpValue?.termSheetQuery?.findIndex(
        (x: any) => x?.elementId === id
      );
      if (index >= 0) {
        tmpValue.termSheetQuery.splice(index, 1);
      }
      if ((multiple && value.length > 0) || (!multiple && value !== '-1')) {
        if (tmpValue.termSheetQuery === undefined) {
          tmpValue.termSheetQuery = [];
        }
        tmpValue.termSheetQuery.push({
          elementId: id,
          items: value,
        });
      }
      if (forceUpdate === true && setAdvancedSearchRequest) {
        setAdvancedSearchRequest(tmpValue);
      } else {
        setSearchRequest(tmpValue);
      }
    } else if (
      (multiple && value.length === 0) ||
      (!multiple && value === '-1')
    ) {
      // eslint-disable-next-line no-param-reassign
      delete searchRequest[id];
      if (forceUpdate === true && setAdvancedSearchRequest) {
        setAdvancedSearchRequest({ ...searchRequest });
      } else {
        setSearchRequest({ ...searchRequest });
      }
    } else if (forceUpdate === true && setAdvancedSearchRequest) {
      setAdvancedSearchRequest({
        ...searchRequest,
        [id]: multiple ? value : [value],
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [id]: multiple ? value : [value],
      });
    }
  };
  const onchange = (event: SelectChangeEvent) => {
    if (
      setAdvancedSearchRequest === undefined ||
      advancedSearchRequest === undefined
    ) {
      return;
    }
    setCurrentValue(event.target.value);
  };
  const onClear = (forceUpdate = false) => {
    if (multiple) {
      setCurrentValue([], forceUpdate);
    } else {
      setCurrentValue('-1', forceUpdate);
    }
  };
  const onDelete = () => {
    onClear(true);
    if (onRemoveFilter) {
      onRemoveFilter(id);
    }
  };

  const onLocalValidate = () => {
    if (setAdvancedSearchRequest) {
      setAdvancedSearchRequest(searchRequest);
    }
    if (onValidate) {
      onValidate();
    }
  };
  return (
    <FilterWrapper
      showSearchButton={showSearchButton}
      onValidate={onLocalValidate}
      onClear={() => onClear()}
      onDelete={onDelete}
      filter={filter}
      isRemovable={
        !(filter.isAdditionalFilter ?? false) &&
        termSheetValueType !== undefined
      }
      titleSx={titleSx}
    >
      <FormControl fullWidth>
        <Select
          multiple={multiple}
          value={getCurrentValue()}
          onChange={onchange}
          disabled={elementSheetValues.errored}
          input={<OutlinedInput label={getFilterName(intl, filter)} />}
          sx={{ width: 320 }}
        >
          {!multiple && (
            <MenuItem
              key="empty"
              value="-1"
              style={getStyles('empty', getCurrentValue(), theme)}
            >
              &nbsp;
            </MenuItem>
          )}
          {localData?.map((name: any, index: number) => (
            <MenuItem
              key={name}
              value={
                // eslint-disable-next-line no-nested-ternary
                filter.getValueFromOption
                  ? filter.getValueFromOption(name, index)
                  : termSheetValueType
                  ? name
                  : index + 1
              }
              style={getStyles(name, getCurrentValue(), theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        {termSheetValueType && (
          <ErrorMessage
            error={elementSheetValues.error}
            sx={{ mt: 2, width: '320px' }}
          />
        )}
      </FormControl>
    </FilterWrapper>
  );
}

export default SelectPopoverFilters;
