import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import NotificationsIcon from '@mui/icons-material/Notifications';
import TimelineIcon from '@mui/icons-material/Timeline';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MoreTimeIcon from '@mui/icons-material/MoreTime';

import messages from '@i18n/keys';
import { useNexityTimelineSteps } from '@shells/nexity/nexity-constants';
import FormattedDateTime from '@components/FormattedDates/FormattedDateTime';

function DashboardCardButton({ id, shouldOpenRgpd, openRgpd }) {
  const to = `/folders/${id}`;
  const label = <FormattedMessage id={messages.verbs.consult} />;

  return !shouldOpenRgpd ? (
    <Button component={RouterLink} color="secondary" to={to}>
      {label}
    </Button>
  ) : (
    <Button color="secondary" onClick={() => openRgpd({ to })}>
      {label}
    </Button>
  );
}

function DashboardCard({
  id,
  name,
  timelineStepIndex,
  buyersCount,
  creationTime,
  lastModificationTime,
  shouldOpenRgpd,
  openRgpd,
}) {
  const nexityTimelineSteps = useNexityTimelineSteps();
  return (
    <Card sx={{ maxWidth: '400px' }}>
      <CardHeader
        title={name}
        titleTypographyProps={{
          color: 'primary',
          variant: 'h6',
          sx: { textAlign: 'center' },
          gutterBottom: false,
        }}
        action={<NotificationsIcon />}
      />
      <CardContent component={Stack} gap={2}>
        <Stack direction="row" gap={1}>
          <TimelineIcon />
          <Typography>
            Etape : &quot;{nexityTimelineSteps[timelineStepIndex || 0]}&quot;
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <PeopleIcon />
          <Typography>Co-acquéreur(s) : {buyersCount}</Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <AccessTimeIcon />
          <Typography>
            Date création : <FormattedDateTime value={creationTime} />
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <MoreTimeIcon />
          <Typography>
            Date modification :{' '}
            <FormattedDateTime value={lastModificationTime} />
          </Typography>
        </Stack>
        <DashboardCardButton
          id={id}
          shouldOpenRgpd={shouldOpenRgpd}
          openRgpd={openRgpd}
        />
      </CardContent>
    </Card>
  );
}

export default DashboardCard;
