import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector';

import { useTiptapContext } from './use-tiptap-context';

const defaultEditorSelector = editor => editor;

export const useTiptapEditor = (selector = defaultEditorSelector, isEqual) => {
  const context = useTiptapContext();
  return useSyncExternalStoreWithSelector(
    context.subscribe,
    () => context.editor,
    null,
    selector,
    isEqual
  );
};
