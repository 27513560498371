import React from 'react';
import { useIntl } from 'react-intl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import messages from '@i18n/descriptors';
import styled from '@emotion/styled';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:not(.Mui-error):hover fieldset': {
      borderColor: 'transparent',
    },
    '&:not(.Mui-error).Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: '#666666',
  },
});

interface StyledSearchProps {
  value?: string;
  setValue: (value: any) => void;
  sx?: any;
  disabled?: boolean;
}

export default function StyledSearch({
  value = '',
  setValue,
  sx = undefined,
  disabled = false,
}: StyledSearchProps) {
  const intl = useIntl();

  return (
    <StyledTextField
      disabled={disabled}
      className="Search-root"
      fullWidth
      sx={sx}
      value={value}
      onChange={e => setValue(e.target.value)}
      placeholder={intl.formatMessage(messages.verbs.search)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
