import React from 'react';
import Viewer from '@components/Viewer/Viewer';
import { useParams } from 'react-router-dom';
import DialogWithClose from './DialogWithClose';

function DialogViewer({
  titleLabel = null,
  baseFetchUrl = '/documents',
  paramName = 'id',
  nameParamName = '',
}) {
  const params = useParams();
  const id = params[paramName];
  const name = nameParamName ? params[nameParamName] : null;

  return (
    <DialogWithClose titleLabel={titleLabel || name}>
      <Viewer fetchUrl={`${baseFetchUrl}/${id}/view`} fileName={name} />
    </DialogWithClose>
  );
}

export default DialogViewer;
