import { defineMessages } from 'react-intl';

export default defineMessages({
  NexityFoldersAcquirerContactDetails: {
    id: 'Nexity.Folders.AcquirerContactDetails',
    defaultMessage: 'Acquirer contact details',
  },
  NexityFoldersFormsToCompleteLabel: {
    id: 'Nexity.Folders.FormsToComplete.Label',
    defaultMessage: 'Forms to complete',
  },
  NexityFoldersVouchers: {
    id: 'Nexity.Folders.Vouchers',
    defaultMessage: 'Vouchers',
  },
  NexityFoldersFormsToCompleteCivilState: {
    id: 'Nexity.Folders.FormsToComplete.CivilState',
    defaultMessage: 'Civil state',
  },
  NexityFoldersFormsToCompleteFamilySituation: {
    id: 'Nexity.Folders.FormsToComplete.FamilySituation',
    defaultMessage: 'Family situation',
  },
  NexityFoldersFormsToCompleteFinancingInsurance: {
    id: 'Nexity.Folders.FormsToComplete.Financing',
    defaultMessage: 'Financing',
  },
  NexityFoldersFormsToCompleteProject: {
    id: 'Nexity.Folders.FormsToComplete.Project',
    defaultMessage: 'Project',
  },
  NexityFoldersFormsToCompleteStatusToComplete: {
    id: 'Nexity.Folders.FormsToComplete.Status.ToComplete',
    defaultMessage: 'To complete',
  },
  NexityFoldersFormsToCompleteStatusToValidate: {
    id: 'Nexity.Folders.FormsToComplete.Status.ToValidate',
    defaultMessage: 'To be validated',
  },
  NexityFoldersFormsToCompleteStatusToRectify: {
    id: 'Nexity.Folders.FormsToComplete.Status.ToRectify',
    defaultMessage: 'To rectify',
  },
  NexityFoldersFormsToCompleteStatusValidated: {
    id: 'Nexity.Folders.FormsToComplete.Status.Validated',
    defaultMessage: 'Validated',
  },
  ErrorsNexityFolderAlreadySigned: {
    id: 'Errors.Nexity.ContractAlreadySigned',
    defaultMessage: 'The contract is already signed.',
  },
  ErrorsNexityContractualizationNotGenerated: {
    id: 'Errors.Nexity.ContractualizationNotGenerated',
    defaultMessage:
      'The contractualisation has not been generated for this folder, please contact an administrator.',
  },
  ErrorsNexityContractAlreadySentForSignature: {
    id: 'Errors.Nexity.ContractAlreadySentForSignature',
    defaultMessage: 'The contract is already sent for signature.',
  },
  ErrorsNexityFolderPrepareDataNotFound: {
    id: 'Errors.Nexity.FolderPrepareDataNotFound',
    defaultMessage:
      "The folder's data was not found, please contact an administrator.",
  },
  ErrorsNexitySpaceWorkflowMustBeCompleted: {
    id: 'Errors.Nexity.SpaceWorkflowMustBeCompleted',
    defaultMessage: 'Not all workflow steps have been completed.',
  },
  ErrorsNexityContractualizationAlreadyInvalidated: {
    id: 'Errors.Nexity.ContractualizationAlreadyInvalidated',
    defaultMessage: 'The contractualisation has already been invalidated.',
  },
  ErrorsNexitySpaceHasNoWorkflow: {
    id: 'Errors.Nexity.SpaceHasNoWorkflow',
    defaultMessage:
      "The folder's workflow was not found, please contact an administrator.",
  },
  ErrorsNexityClientInfoAlreadyValidated: {
    id: 'Errors.Nexity.ClientInfoAlreadyValidated',
    defaultMessage: 'Client informations have been already validated.',
  },
});
