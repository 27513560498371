import React from 'react';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { clauseType } from '@smartclause-tiptap-extensions/schema-core/dist';

function ClauseIcon({ type, ...props }) {
  if (type === clauseType.mcq.value) {
    return <CheckBoxIcon color="primary" {...props} />;
  }
  if (type === clauseType.scq.value) {
    return <RadioButtonCheckedIcon color="primary" {...props} />;
  }
  return <TextFieldsIcon color="primary" {...props} />;
}

export default ClauseIcon;
