import { SearchFilter } from '@components/Search/Filters/helpers/types';
import type { ChipProps } from '@mui/material';
import {
  ComponentType,
  PropsWithChildren,
  createContext,
  useContext,
} from 'react';

export type ContractCustomUiProps = {
  contract: any;
  contractId: string;
  fetchingContract: boolean;
  contractError: boolean;
  refreshContract(): void;
  getEditorContent(): Promise<unknown>;
};

export type ContractCustomUi = {
  prelude?: JSX.Element;
  actions?: JSX.Element;
  blockNavigation?: boolean;
};

export type DriveClientStatusDescriptor = {
  name: string;
  value: number;
  text: string;
  icon: ComponentType;
  color: ChipProps['color'];
};

export type HeaderCustomUserMenuUI = {
  email?: string;
  pictureUrl?: string;
};

export type AppContextValue = {
  tenantClient?: string;
  contract: {
    workflow: {
      hidden: boolean;
      showEmail?: boolean;
      showExternalIds?: boolean;
    };
    versions: {
      hidden: boolean;
    };
    annexes: {
      hidden: boolean;
    };
    notes: {
      hidden: boolean;
    };
    embedded?: {
      showHeader?: boolean;
    };
    showSidebar?: boolean;
    useCustomUi(props: ContractCustomUiProps): ContractCustomUi;
  };
  calendar: {
    disabled: boolean;
  };
  dashboard: {
    disabled: boolean;
  };
  drive: {
    url?: string;
    hideCreate: boolean;
    hideSearchExport: boolean;
    hideProgress: boolean;
    filters: {
      loadOptions?: boolean;
      hideCreatedBy: boolean;
      additionalFilters?: SearchFilter[] | null;
    };
    clientStatuses?: DriveClientStatusDescriptor[];
  };
  statistics: {
    disabled: boolean;
  };
  headers: {
    useCustomUserMenu(props: HeaderCustomUserMenuUI): React.ReactNode;
  };
  authentication?: {
    hideResetPassword: boolean;
  };
};

export const defaultAppContextValue: AppContextValue = {
  contract: {
    workflow: { hidden: false },
    versions: { hidden: false },
    annexes: { hidden: false },
    notes: { hidden: false },
    useCustomUi: () => ({}),
  },
  dashboard: { disabled: false },
  drive: {
    hideCreate: false,
    hideSearchExport: false,
    hideProgress: false,
    filters: { hideCreatedBy: false },
  },
  calendar: { disabled: false },
  statistics: { disabled: false },
  headers: {
    useCustomUserMenu: () => null,
  },
};

const AppContext = createContext<AppContextValue>(defaultAppContextValue);

export function AppContextProvider({
  value,
  children,
}: PropsWithChildren<{ value: AppContextValue }>) {
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext() {
  return useContext(AppContext);
}
