import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import FormikTextField from '@components/Formik/FormikTextField';
import useFetch from '@hooks/useFetch';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { tenantAuthenticationSelector } from '@recoil/tenant-token';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useLayoutEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useRecoilState } from 'recoil';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  token: yup.string().trim().required('Generic.Fields.Required'),
});

export const defaultValues = {
  token: '',
};

function AuthorizeElevateForm({ backTo, sendSmsUrl, sendSmsBody }) {
  const [searchParams] = useSearchParams();
  const redirectToParams = searchParams.get('redirectTo') || '';
  const ref = useRef({});
  const navigate = useNavigate();
  const location = useLocation();
  const [tenantState, setTenantAuthenticationState] = useRecoilState(
    tenantAuthenticationSelector
  );

  const elevate = useFetch({
    method: 'POST',
    url: '/authentication/authorize/elevate',
    onSuccess: response => {
      const token = response.data;
      const refreshToken = tenantState?.refreshToken;
      const result = {
        succeeded: true,
        token,
        refreshToken,
      };
      setTenantAuthenticationState(result);
      const dashboardTo = { pathname: '/dashboard' };
      const from = location.state?.from;
      let fromTo = null;
      if (from) {
        fromTo = `${from.pathname}${from.search}${from.hash}`;
      } else if (redirectToParams !== '') {
        fromTo = redirectToParams;
      }
      const to = !fromTo || fromTo === '/' ? dashboardTo : fromTo;
      navigate(to);
    },
  });

  const onSubmitCallback = form => {
    elevate.doFetch({ body: validationSchema.cast(form) });
  };

  const formik = useFormik({
    validationSchema,
    initialValues: defaultValues,
    onSubmit: onSubmitCallback,
  });

  const sendSms = useFetch({
    method: 'POST',
    url: sendSmsUrl || '/authentication/send-sms',
    onSuccess: () => {
      ref.current.sentSms = true;
    },
    onError: () => {
      ref.current.sentSms = false;
    },
  });

  useLayoutEffect(() => {
    if (!ref.current.sentSms && !sendSms.fetching && !sendSms.didFetch) {
      sendSms.doFetch({ body: sendSmsBody ?? null });
    }
  }, [sendSms, sendSmsBody]);

  const onSendClick = () => {
    sendSms.doFetch({ body: { force: true } });
  };

  const error = sendSms.error ?? elevate.error;
  const fetching = sendSms.fetching || elevate.fetching;

  return (
    <FormikProvider value={formik}>
      <Form noValidate>
        <ErrorMessage error={error} sx={{ mt: 1, mb: 2 }} />
        {sendSms.succeeded && (
          <>
            <Alert severity="info" sx={{ mt: 2 }}>
              {sendSms.data?.existing ? (
                <>
                  Un SMS avec un code d&apos;authentification vous a déjà été
                  envoyé. Vous pouvez le réutiliser pour vous connecter.
                </>
              ) : (
                <>
                  Un SMS avec un code d&apos;authentification vous a été envoyé.
                </>
              )}
            </Alert>
            {sendSms.data?.existing && !sendSms.data?.force && (
              <Button
                type="button"
                fullWidth
                variant="outlined"
                color="secondary"
                sx={{ mt: 2, mb: 2 }}
                onClick={onSendClick}
              >
                Renvoyer un code
              </Button>
            )}
          </>
        )}
        <FormikTextField
          name="token"
          label="Code d’authentification"
          required
          disabled={fetching}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          disabled={fetching}
        >
          <FormattedMessage id="Authorize.Button" />
        </Button>
        <Button
          type="button"
          fullWidth
          variant="text"
          color="secondary"
          sx={{ mt: 2 }}
          to={backTo}
          component={Link}
        >
          <FormattedMessage id="Generic.Back" />
        </Button>
      </Form>
    </FormikProvider>
  );
}

export default AuthorizeElevateForm;
