import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Viewer as CorePdfViewer,
  Worker as CorePdfWorker,
} from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

import Fetching from '@components/Fetching/Fetching';

import useFetch from '@hooks/useFetch';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export function PdfViewer({ fileUrl, fileName }) {
  const ref = useRef({ fileName: null });
  ref.current.fileName = fileName;

  const layoutPlugin = defaultLayoutPlugin({
    toolbarPlugin: {
      getFilePlugin: {
        /* NOTE: we store and use the fileName in the component's ref in order to
         * have the latest fileName if the props change in between renders.
         */
        fileNameGenerator: !fileName ? null : () => ref.current.fileName,
      },
    },
  });
  return (
    <Box sx={{ height: '80vh' }}>
      <CorePdfViewer fileUrl={fileUrl} plugins={[layoutPlugin]} />
    </Box>
  );
}

export function Worker({ children }) {
  return (
    <CorePdfWorker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
      {children}
    </CorePdfWorker>
  );
}

function Viewer({ fetchUrl, fileName }) {
  const { data, fetching, error } = useFetch({
    method: 'GET',
    url: fetchUrl,
  });

  const fileUrl = data?.uri;

  return (
    <Fetching fetching={fetching}>
      {!fetching &&
        (error ? (
          <Alert severity="error">
            <FormattedMessage id="Components.Viewer.Error.DefaultMessage" />
          </Alert>
        ) : (
          Boolean(fileUrl) && (
            <PdfViewer fileUrl={fileUrl} fileName={fileName} />
          )
        ))}
    </Fetching>
  );
}

export default Viewer;
