import { defineMessages } from 'react-intl';

export default defineMessages({
  CalendarWeekTitleLabel: {
    id: 'Calendar.Week.Title.Label',
    defaultMessage: 'Week {index}',
  },
  CalendarUpdateReminderButtonUpdateLabel: {
    id: 'Calendar.Update.Reminder.Button.Update.Label',
    defaultMessage: 'Update',
  },
  CalendarViewCalenderJanLabel: {
    id: 'CalendarView.Calender.jan.Label',
    defaultMessage: 'January',
  },
  CalendarViewCalenderFebLabel: {
    id: 'CalendarView.Calender.feb.label',
    defaultMessage: 'February',
  },
  CalendarViewCalenderMarLabel: {
    id: 'CalendarView.Calender.mar.label',
    defaultMessage: 'March',
  },
  CalendarViewCalenderAprLabel: {
    id: 'CalendarView.Calender.apr.label',
    defaultMessage: 'April',
  },
  CalendarViewCalenderMayLabel: {
    id: 'CalendarView.Calender.may.label',
    defaultMessage: 'May',
  },
  CalendarViewCalenderJunLabel: {
    id: 'CalendarView.Calender.Jun.label',
    defaultMessage: 'Jun',
  },
  CalendarViewCalenderJulLabel: {
    id: 'CalendarView.Calender.Jul.label',
    defaultMessage: 'July',
  },
  CalendarViewCalenderAguLabel: {
    id: 'CalendarView.Calender.aug.label',
    defaultMessage: 'August',
  },
  CalendarViewCalenderSepLabel: {
    id: 'CalendarView.Calender.sep.label',
    defaultMessage: 'September',
  },
  CalendarViewCalenderOctLabel: {
    id: 'CalendarView.Calender.oct.label',
    defaultMessage: 'October',
  },
  CalendarViewCalenderNovLabel: {
    id: 'CalendarView.Calender.nov.label',
    defaultMessage: 'November',
  },
  CalendarViewCalenderDecLabel: {
    id: 'CalendarView.Calender.deb.label',
    defaultMessage: 'December',
  },
  ReminderSheetModalPopupCompleteTitle: {
    id: 'ReminderSheetModal.Popup.Complete.Title',
    defaultMessage: 'Mark the reminder as handled',
  },
  ReminderSheetModalPopupCompleteDescription: {
    id: 'ReminderSheetModal.Popup.Complete.Description',
    defaultMessage:
      'Are you sure you want to mark the reminder as handled? \n\r This action will send a notification to all users sharing the reminder and will disable the originally scheduled alert.',
  },
  ReminderSheetModalPopupUncompleteTitle: {
    id: 'ReminderSheetModal.Popup.Uncomplete.Title',
    defaultMessage: 'Mark the reminder as unhandled',
  },
  ReminderSheetModalPopupUncompleteDescription: {
    id: 'ReminderSheetModal.Popup.Uncomplete.Description',
    defaultMessage:
      'Are you sure you want to mark the reminder as unhandled? \n\r This action will send a notification to all users sharing the reminder and will enable the originally scheduled alert.',
  },
  ReminderSheetModalButtonsToRegisterLabel: {
    id: 'ReminderSheetModal.Buttons.To.Register.Label',
    defaultMessage: 'SAVE',
  },
  DashboardDeadlineReminderSheetModalTitleText: {
    id: 'Dashboard.Deadline.ReminderSheetModal.Title.Text',
    defaultMessage: 'Reminder sheet',
  },
  DashboardDeadlineReminderSheetModalExportTitleText: {
    id: 'Dashboard.Deadline.ReminderSheetModal.Export.Title.Text',
    defaultMessage: 'Export to my calendar',
  },
  ReminderSheetModalContentRelatedDocumentText: {
    id: 'ReminderSheetModal.Content.RelatedDocument.Text',
    defaultMessage: 'Related document:',
  },
  ReminderSheetModalContentMarkTreatedText: {
    id: 'ReminderSheetModal.Content.MarkTreated.Text',
    defaultMessage: 'Mark as processed',
  },
  ReminderSheetModalContentReminderDateText: {
    id: 'ReminderSheetModal.Content.ReminderDate.Text',
    defaultMessage: 'Reminder on:',
  },
  ReminderSheetModalReminderDateText: {
    id: 'ReminderSheetModal.ReminderDate.Text',
    defaultMessage: 'End date :',
  },
  DashboardReminderAboutTextLabel: {
    id: 'Dashboard.Reminder.About.Text.Label',
    defaultMessage: 'Reminder about <b>{name}</b>: {date}',
  },
  DashboardReminderModalTextLabel: {
    id: 'Dashboard.Reminder.Modal.Text.Label',
    defaultMessage: 'Reminder name',
  },
  DashboardReminderModalFormDateLabel: {
    id: 'Dashboard.ReminderModal.Form.Date.Label',
    defaultMessage: 'Reminder',
  },
  DashboardReminderModalTextAreaLabel: {
    id: 'Dashboard.Reminder.Modal.TextArea.Label',
    defaultMessage: 'Notes',
  },
  DashboardReminderModalImportanceLabel: {
    id: 'Dashboard.Reminder.Modal.Importance.Label',
    defaultMessage: 'Importance',
  },
  DashboardReminderModalShareTheReminderLabel: {
    id: 'Dashboard.Reminder.Modal.ShareTheReminder.Label',
    defaultMessage: 'Share this reminder',
  },
  ReminderSheetModalButtonsDeleteLabel: {
    id: 'ReminderSheetModal.Buttons.Delete.Label',
    defaultMessage: 'Delete this reminder',
  },
  CalendarReminderSaveSuccess: {
    id: 'Calendar.Reminder.Save.Success',
    defaultMessage: 'Reminder has been saved successfully',
  },
  CalendarReminderSaveFailure: {
    id: 'Calendar.Reminder.Save.Failure',
    defaultMessage: 'The save has failed',
  },
  CalendarReminderFormContractLinkText: {
    id: 'Calendar.Reminder.Form.ContractLink.Text',
    defaultMessage: 'Linked document',
  },
  DeadlineSheetModalPopupTitleLabel: {
    id: 'DeadlineSheetModal.Popup.Title.Label',
    defaultMessage: 'Mark the deadline as handled',
  },
  DeadlineSheetModalPopupDescriptionLabel: {
    id: 'DeadlineSheetModal.Popup.Description.Label',
    defaultMessage:
      'Are you sure you want to mark the deadline as handled? \n\r This action will send a notification to all users sharing the reminder and will disable the originally scheduled alert.',
  },
  DeadlineSheetModalPopupTitleActionsExportToAgendaLabel: {
    id: 'DeadlineSheetModal.Popup.Title.Actions.ExportToAgenda.Label',
    defaultMessage: 'Export to my agenda',
  },
  CalendarDeadlineFormDeadlinekindText: {
    id: 'Calendar.Deadline.Form.Deadlinekind.Text',
    defaultMessage: 'Deadline kind',
  },
  CalendarDeadlineFormDateText: {
    id: 'Calendar.Deadline.Form.Date.Text',
    defaultMessage: 'Deadline date',
  },
  CalendarDeadlineFormDocumentPathText: {
    id: 'Calendar.Deadline.Form.DocumentPath.Text',
    defaultMessage: 'Document path',
  },
  CalendarDeadlineFormProjectText: {
    id: 'Calendar.Deadline.Form.Project.Text',
    defaultMessage: 'Project',
  },
  CalendarDeadlineFormOwnerText: {
    id: 'Calendar.Deadline.Form.Owner.Text',
    defaultMessage: 'Owner',
  },
  CalendarDeadlineFormRemindersText: {
    id: 'Calendar.Deadline.Form.Reminders.Text',
    defaultMessage: 'Linked reminders',
  },
  CalendarDeadlineFormMyRemindersText: {
    id: 'Calendar.Deadline.Form.My.Reminders.Text',
    defaultMessage: 'My reminders',
  },
  CalendarDeadlineSheetModalTitleText: {
    id: 'Calendar.Deadline.SheetModal.Title.Text',
    defaultMessage: 'Deadline sheet',
  },
});
