import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ConfirmDialog from '@components/Dialog/ConfirmDialog';

import { bold } from '@utils/chunks';

import { useWorkflowContext } from '../WorkflowContextProvider';

function WorkflowStepDeleteDialog({ open, onClose, onConfirm }) {
  const { active } = useWorkflowContext();
  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      titleComponent={
        <FormattedMessage id="Workflow.Step.Dialogs.Delete.Title" />
      }
      actionComponent={
        <Button variant="contained" color="primary" onClick={onConfirm}>
          <FormattedMessage id="Verbs.Delete" />
        </Button>
      }
    >
      <Typography>
        <FormattedMessage
          id="Workflow.Step.Dialogs.Delete.Message"
          values={{
            name: active?.name ?? '',
            b: bold,
          }}
        />
      </Typography>
    </ConfirmDialog>
  );
}

export default WorkflowStepDeleteDialog;
