import React, { useMemo } from 'react';
import {
  useParams,
  Routes,
  Route,
  useResolvedPath,
  Navigate,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Fetching from '@components/Fetching/Fetching';
import useFetch from '@hooks/useFetch';
import useBreadcrumbs from '@hooks/useBreadcrumbs';
import messages from '@i18n/keys';
import RouteTabs from '@components/Tabs/Tabs';
import {
  nexityFolderStatuses,
  nexityRoles,
  nexitySignatureStatuses,
} from '@shells/nexity/nexity-constants';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import useTenantRoles from '@hooks/useTenantRoles';
import { ContractEmbeddedView } from '@views/Tiptap/ContractViewWithEmbedded';
import { useRecoilValue } from 'recoil';
import { tenantRolesIsExternalSelector } from '@recoil/tenant-token';
import FolderTimeline from './FolderTimeline';
import FolderClientInfo from './FolderClientInfo';
import FolderContractualization from './FolderContractualization';
import FolderContractPdf from './FolderContractPdf';
import FolderWorkflowSchemaView from './components/FolderWorkflowSchemaView';
import FolderInformations from './FolderInformations/FolderInformations';

const buyerRouteTabs = [
  {
    routeProps: {
      path: '*',
      index: true,
    },
    linkProps: {
      to: '',
    },
    tabProps: {
      label: 'Info clients',
    },
  },
];

const dealerRouteTabs = [
  {
    routeProps: {
      path: '*',
      index: true,
    },
    linkProps: {
      to: '',
    },
    tabProps: {
      label: 'Info clients',
    },
  },
  {
    routeProps: {
      path: 'contract-pdf/*',
    },
    linkProps: {
      to: 'contract-pdf',
    },
    tabProps: {
      label: 'Contrat Pdf',
    },
  },
];

const allRouteTabs = [
  ...dealerRouteTabs,
  {
    routeProps: {
      path: 'contractualization/*',
    },
    linkProps: {
      to: 'contractualization',
    },
    tabProps: {
      label: 'Modification/Validation du contrat',
    },
  },
];

const breadcrumbs = [{ to: '/', message: messages.header.menu.drive }];

function FolderDefaultView() {
  const { id } = useParams();

  const { data, fetching, doFetch, error } = useFetch({
    url: `/nexity/dashboard/folders/${id}?IncludePrepareElement=true`,
  });

  const refreshFolder = () => {
    doFetch();
  };

  const breadcrumbsMemo = React.useMemo(() => {
    if (data) {
      return [...breadcrumbs, { to: '', label: data.folder.name }];
    }
    return breadcrumbs;
  }, [data]);
  useBreadcrumbs(breadcrumbsMemo);

  const isAdmin = useTenantRoles({ roles: [nexityRoles.administrators] });
  const isExternal = useRecoilValue(tenantRolesIsExternalSelector);
  const isManagerOrAdmin = Boolean(data?.isManager) || isAdmin;
  const isDealerOrManagerOrAdmin = Boolean(data?.isDealer) || isManagerOrAdmin;
  const isContractualizationAvailable = Boolean(
    data?.space?.status === nexityFolderStatuses.generated ||
    data?.space?.status === nexityFolderStatuses.signed
  );
  const isContractualizationGenerated = Boolean(
    data?.space?.status === nexityFolderStatuses.generated
  );
  const isSigning = Boolean(
    data?.space?.signature?.status === nexitySignatureStatuses.pending
  );
  const readOnly = data?.space?.status === nexityFolderStatuses.signed;
  const showContractPdf =
    isContractualizationAvailable && isDealerOrManagerOrAdmin;
  const showContractualization =
    isContractualizationAvailable &&
    (isManagerOrAdmin || data?.isUserInWorkflow);
  const preventModifications = Boolean(data?.preventModifications);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'grid',
        gridTemplateColumns: '1fr 380px',
        gridTemplateRows: 'auto 1fr',
      }}
    >
      {!fetching && (showContractPdf || showContractualization) && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <RouteTabs
            routeTabs={
              isManagerOrAdmin /*|| (data?.isUserInWorkflow ?? false)*/
                ? allRouteTabs
                : isDealerOrManagerOrAdmin
                  ? dealerRouteTabs
                  : buyerRouteTabs
            }
          />
        </Box>
      )}
      <Fetching fetching={fetching} sx={{ flexGrow: 1 }} />
      <ErrorMessage error={error} />
      <Routes>
        <Route
          path="*"
          element={
            <FolderClientInfo
              id={id}
              data={data}
              doFetch={doFetch}
              preventModifications={preventModifications}
              fetching={fetching}
            />
          }
        />
        <Route
          path="contract-pdf/*"
          element={
            fetching || showContractPdf ? (
              <FolderContractPdf
                id={id}
                fetching={fetching}
                fileName={
                  null /* TODO: use "Aperçu contract - ..." from server */
                }
              />
            ) : (
              <Navigate to=".." />
            )
          }
        />
        <Route
          path="contractualization/*"
          element={
            fetching || showContractualization ? (
              <FolderContractualization
                id={id}
                refreshFolder={refreshFolder}
                spaceData={data}
                space={data?.space}
                fetching={fetching}
                fetchData={data}
                readOnly={readOnly}
              />
            ) : (
              <Navigate to=".." />
            )
          }
        />
      </Routes>
      <Box
        sx={{
          p: 2,
          bgcolor: 'grey.100',
          borderLeft: '1px solid',
          borderColor: 'grey.400',
          gridColumn: '2',
          gridRow: '1 / -1',
        }}
      >
        <FolderTimeline
          timelineStepIndex={data?.space?.timelineStepIndex ?? null}
          isDealerOrManagerOrAdmin={isDealerOrManagerOrAdmin}
          isContractualizationGenerated={isContractualizationGenerated}
          contributors={data?.contributors}
          signers={data?.signers}
          canEditWorkflow={!isSigning}
        />
        <FolderInformations
          data={data}
          dealer={data?.prospect?.Dealer}
          signers={data?.signers}
          notifications={data?.prepareElement?.data?.Notifications}
          isManagerOrAdmin={isManagerOrAdmin}
          isExternal={isExternal}
          isDealer={data?.isDealer}
        />
      </Box>
    </Box>
  );
}

function mapIdentityRolesToGroups({ identity, folder }) {
  if (!identity || !folder) return identity;

  const groupNamesPerCondition = {
    [nexityRoles.affiliateValidator]: folder.isAffiliateValidator,
    [nexityRoles.animator]: folder.isAnimator,
    [nexityRoles.buyer]: folder.isBuyer,
    [nexityRoles.dealer]: folder.isDealer,
    [nexityRoles.manager]: folder.isManager,
  };

  const groups = identity.roles.filter(
    role =>
      !(role.name in groupNamesPerCondition) ||
      groupNamesPerCondition[role.name]
  );

  return { ...identity, groups };
}

function FolderViewNexity() {
  const folderPathObject = useResolvedPath('../contractualization');
  const folderUrl = `${folderPathObject.pathname}${folderPathObject.search}${folderPathObject.hash}`;
  const { id } = useParams();
  const folderFetch = useFetch({
    url: `/nexity/dashboard/folders/${id}`,
  });
  const identityFetch = useFetch({
    url: '/identity/users/current',
  });

  const folder = folderFetch.data;
  const identityUnmapped = identityFetch.data;

  // Explicitly telling which groups the user belongs to is not yet supported on the embedded view.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const identityMapped = useMemo(
    () => mapIdentityRolesToGroups({ identity: identityUnmapped, folder }),
    [identityUnmapped, folder]
  );

  const fetching = folderFetch.fetching || identityFetch.fetching;

  return (
    <Fetching fetching={fetching} sx={{ flexGrow: 1 }}>
      <ContractEmbeddedView
        backUrl={folderUrl}
        hideWorkflow
        hideTabs
        iframeParameters={{ hideNextStepButton: true }}
      />
      {/* <ContractView
        signed={signed}
        identity={identityMapped}
      /> */}
    </Fetching>
  );
}

function FolderView() {
  return (
    <Routes>
      <Route path="*" element={<FolderDefaultView />} />
      <Route path="contract/:contractId/*" element={<FolderViewNexity />} />
      <Route
        path="workflow/*"
        element={<FolderWorkflowSchemaView fullWidth backUrl=".." />}
      />
    </Routes>
  );
}

export default FolderView;
