import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';

import Fetching from '@components/Fetching/Fetching';

import useFetch from '@hooks/useFetch';
import WorkflowContextProvider from '@components/WorkflowGeneric/WorkflowContextProvider';
import WorkflowFormikProvider from '@components/WorkflowGeneric/WorkflowFormikProvider';
import WorkflowEditor from '@components/WorkflowGeneric/WorkflowEditor';
import { useParams } from 'react-router-dom';
import FolderWorkflowSchemaHeader from './FolderWorkflowSchemaHeader';

function FolderWorkflowSchemaView({ fullWidth, backUrl }) {
  const { formatMessage } = useIntl();
  const { id } = useParams();
  const { data, fetching, error } = useFetch({
    url: `/nexity/folders/${id}/workflow`,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        ...(fullWidth ? { width: '100%' } : {}),
      }}
    >
      <Fetching fetching={fetching} sx={{ flexGrow: 1, mr: 2, mb: 2 }}>
        <WorkflowContextProvider
          workflow={data?.workflow}
          defaultStep={{
            name: formatMessage({ id: 'Workflow.Step.Fields.Name.Default' }),
            mandatoryContributors: [],
            otherContributors: [],
          }}
        >
          <WorkflowFormikProvider
            FormProps={
              fullWidth ? { style: { width: '100%', padding: '0 16px' } } : {}
            }
          >
            <FolderWorkflowSchemaHeader folderId={id} backUrl={backUrl} />
            <WorkflowEditor
              sx={{ pr: 2, pb: 2 }}
              error={error}
              hideNotifyContributors
              hideNewUser
              hideAccess
              hideCondition
              hideRemove
              onlyOneContributorByStep
              hideAddNewStep
              hideRemoveStep
              hideDocumentAuthor
              hideEmptyUser
              cantRenameStep
            />
          </WorkflowFormikProvider>
        </WorkflowContextProvider>
      </Fetching>
    </Box>
  );
}

export default FolderWorkflowSchemaView;
