/**
 * Removes duplicated values within an array.
 * Supports numbers, strings and objects.
 */
export default function removeDuplicates(array = [], by = JSON.stringify) {
  const seen = {};

  return array.filter(item => {
    const key = by(item);

    return Object.prototype.hasOwnProperty.call(seen, key)
      ? false
      : (seen[key] = true);
  });
}
