import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import Fetching from '@components/Fetching/Fetching';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';

import useBreadcrumbs from '@hooks/useBreadcrumbs';
import useFetch from '@hooks/useFetch';

import messages from '@i18n/keys';

import { useCurrentUser } from '@shells/nexity/contexts/CurrentUserContext';

import SearchWithFilters from '@components/Search/SearchWithFilters';
import useDebounced from '@hooks/useDebounced';
import useTenantRoles from '@hooks/useTenantRoles';
import { nexityRoles } from '@shells/nexity/nexity-constants';
import useNexityTheme from '@shells/nexity/hooks/useNexityTheme';
import DriveCard from './DriveCard';
import useFilters from './useFilters';

function RgpdDialog({ open, onClose, onConfirm, acceptRgpd }) {
  const { name } = useNexityTheme();
  const tenantLabel = name === 'edouard-denis' ? 'Edouard Denis' : 'Nexity';
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>RGPD et vos droits</DialogTitle>
      <DialogContent>
        <ErrorMessage error={acceptRgpd.error} />
        <DialogContentText>
          Cet espace personnel permet de compléter votre dossier de réservation.
          A cette fin, {tenantLabel} collecte des données à caractère personnel.
        </DialogContentText>
        <DialogContentText>
          Le fondement légal de ces traitements est l’exécution des mesures
          précontractuelles nécessaires à l’établissement de votre contrat de
          réservation. Les catégories de données personnelles collectées sont :
          Etat civil, situation de famille, Moyens de financement de votre
          projet. Elles concernent toutes les parties prenantes à la
          réservation.
        </DialogContentText>
        <DialogContentText>
          Les destinataires de ces informations sont les services concernés de{' '}
          {tenantLabel}. Ces informations sont conservées 10 ans à compter de la
          remise des clés, et/ou de la levée de toutes réserves. A tout moment,
          vous pouvez exercer vos droits en cliquant sur le lien « Exercez vos
          droits ».
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={acceptRgpd.fetching} onClick={onConfirm}>
          Fermer cette fenêtre
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const breadcrumbs = [{ to: '', message: messages.header.menu.drive }];

function DriveView() {
  useBreadcrumbs(breadcrumbs);

  const navigate = useNavigate();
  const hasSearch = useTenantRoles({
    roles: [
      nexityRoles.administrators,
      nexityRoles.animator,
      nexityRoles.manager,
      nexityRoles.affiliateValidator,
      nexityRoles.dealer,
    ],
  });

  const ref = useRef({
    didFetchOnMount: false,
    searching: false,
    doSearch: null,
  });
  const [searchParams] = useSearchParams();
  const searchRequestString = searchParams.get('query') ?? '';
  const requestQueryParams = JSON.parse(searchParams.get('filters') || '{}');
  const [advancedSearchRequest, setAdvancedSearchRequest] = useState(
    hasSearch && requestQueryParams ? requestQueryParams : {}
  );
  const [finalSearchRequest, setFinalSearchRequest] = useState(
    hasSearch && requestQueryParams ? requestQueryParams : {}
  );
  const [searchRequest, setSearchRequest] = useState(
    hasSearch && searchRequestString ? searchRequestString : ''
  );

  const {
    data,
    fetching,
    error,
    doFetch: doSearch,
  } = useFetch({
    url: '/nexity/dashboard/search',
    method: 'POST',
  });
  ref.current.searching = fetching;
  ref.current.doSearch = doSearch;

  const { acceptedRgpd, setAcceptedRgpd } = useCurrentUser();
  const [clickedOpen, setClickedOpen] = useState(false);
  const [redirectRgpdTo, setRedirectRgpdTo] = useState(null);

  const acceptRgpd = useFetch({
    method: 'PUT',
    url: 'nexity/users/current/accept-rgpd',
    onSuccess: () => {
      setAcceptedRgpd(true);
      if (redirectRgpdTo) {
        navigate(redirectRgpdTo);
      }
    },
  });

  const open = clickedOpen && !acceptedRgpd;
  const openRgpd = ({ to }) => {
    setClickedOpen(true);
    setRedirectRgpdTo(to);
  };

  const onChange = event => {
    setSearchRequest(event.target.value);
  };

  const handleSearch = () => {
    setFinalSearchRequest(advancedSearchRequest);
  };

  const debouncedSearchRequest = useDebounced(searchRequest, 1000);

  useEffect(() => {
    if (ref.current.searching) return;
    if (searchRequest !== debouncedSearchRequest) return;
    ref.current.doSearch({
      body: { query: debouncedSearchRequest, filters: finalSearchRequest },
    });
  }, [debouncedSearchRequest, searchRequest, finalSearchRequest]);

  const foldersComponent = useMemo(
    () =>
      data?.folders?.map(folder => (
        <DriveCard
          key={folder.id}
          {...folder}
          shouldOpenRgpd={!acceptedRgpd}
          openRgpd={openRgpd}
        />
      )),
    [acceptedRgpd, data?.folders]
  );

  const filters = useFilters();

  return (
    <Stack flexGrow={1} alignItems="center">
      {hasSearch && (
        <Box sx={{ my: 4, mx: 6, width: '800px', maxWidth: '100%' }}>
          <SearchWithFilters
            value={searchRequest}
            onChange={onChange}
            filters={filters}
            onSearch={handleSearch}
            advancedSearchRequest={advancedSearchRequest}
            setAdvancedSearchRequest={setAdvancedSearchRequest}
            filterTitleSx={{ fontSize: '16px', fontWeight: 700 }}
          />
        </Box>
      )}
      <Fetching fetching={fetching} sx={{ flexGrow: 1 }}>
        <ErrorMessage error={error} />
        <Box
          sx={{
            p: 2,
            flexGrow: 1,
            justifyContent: 'center',
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 350px)',
            gridAutoRows: 'min-content',
            gap: 4,
          }}
        >
          {foldersComponent}
        </Box>
      </Fetching>
      <RgpdDialog
        open={open}
        onClose={() => setClickedOpen(false)}
        onConfirm={() => acceptRgpd.doFetch()}
        acceptRgpd={acceptRgpd}
      />
    </Stack>
  );
}

export default DriveView;
