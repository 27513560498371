import { defineMessages } from 'react-intl';

export default defineMessages({
  'Completion.Settings.Title': {
    id: 'Completion.Settings.Title',
    defaultMessage: 'Auto-completion settings: <b>{name}</b>',
  },
  'Completion.Item.Type.Label': {
    id: 'Completion.Item.Type.Label',
    defaultMessage: 'Completion item type',
  },
  'Completion.Field.Label': {
    id: 'Completion.Field.Label',
    defaultMessage: 'Field to be completed',
  },
  'Completion.ClauseVariant.Label': {
    id: 'Completion.ClauseVariant.Label',
    defaultMessage: 'Clause option to choose',
  },
  'Completion.Referential.Label': {
    id: 'Completion.Referential.Label',
    defaultMessage: 'Linked referential',
  },
  'Completion.Data.Label': {
    id: 'Completion.Data.Label',
    defaultMessage: 'Data to be imported',
  },
  'Completion.ContractField.Label': {
    id: 'Completion.ContractField.Label',
    defaultMessage: 'Information to be filled in',
  },
  'Completion.ContractField.Label.CreationTime': {
    id: 'Completion.ContractField.Label.CreationTime',
    defaultMessage: 'Contract creation date',
  },
  'Completion.ContractField.Label.IncrementValue': {
    id: 'Completion.ContractField.Label.IncrementValue',
    defaultMessage: 'Contract number',
  },
  'Completion.Items.Label': {
    id: 'Completion.Items.Label',
    defaultMessage: 'Field auto-completion',
  },
  'Completion.Items.Buttons.Add.Label': {
    id: 'Completion.Items.Buttons.Add.Label',
    defaultMessage: 'Add a auto-completion field',
  },
  'Completion.NoVariables': {
    id: 'Completion.NoVariables',
    defaultMessage: 'There are no variables to be completed.',
  },
  'Completion.UnknownField.Label': {
    id: 'Completion.UnknownField.Label',
    defaultMessage: 'An unknown variable',
  },
  'Completion.Settings.Save.Success': {
    id: 'Completion.Settings.Save.Success',
    defaultMessage: 'Auto-completion settings has been saved successfully',
  },

  'Completion.Item.Type.Values.VariableValue': {
    id: 'Completion.Item.Type.Values.VariableValue',
    defaultMessage: 'Fill the value of a variable',
  },
  'Completion.Item.Type.Values.ClauseVariantChoice': {
    id: 'Completion.Item.Type.Values.ClauseVariantChoice',
    defaultMessage: 'Choose the option of a clause',
  },
  'Completion.Item.Type.Values.ContractField': {
    id: 'Completion.Item.Type.Values.ContractField',
    defaultMessage: 'Fill the value of a variable with a contract information',
  },

  'Completion.ConditionOperator.Label': {
    id: 'Completion.ConditionOperator.Label',
    defaultMessage: 'How to compare the data',
  },
  'Completion.ConditionOperator.Values.Equal': {
    id: 'Completion.ConditionOperator.Values.Equal',
    defaultMessage: 'Is equal to',
  },
  'Completion.ConditionOperator.Values.NotEqual': {
    id: 'Completion.ConditionOperator.Values.NotEqual',
    defaultMessage: 'Is not equal to',
  },
  'Completion.ConditionValue.Label': {
    id: 'Completion.ConditionValue.Label',
    defaultMessage: 'The value to compare against',
  },
  'Completion.Item.Warning.ReferentialElementPathNotFound': {
    id: 'Completion.Item.Warning.ReferentialElementPathNotFound',
    defaultMessage: 'The data to be imported is not an available option.',
  },
});
