import { useOutletContext, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import * as Yup from 'yup';

import DialogTitleWithClose from '@components/Dialog/DialogTitleWithClose';
import DialogActionsWithClose from '@components/Dialog/DialogActionsWithClose';

import FormikProvider from '@components/Formik/FormikProvider';
import FormikFileField from '@components/Formik/FormikFileField';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import AddButton from '@components/Buttons/AddButton';
import { Box } from '@mui/system';
import { useState } from 'react';
import DeleteIconButton from '@components/Buttons/IconButtons/DeleteIconButton';

const schema = Yup.object({
  folderId: Yup.string(),
});

const defaultValues = {
  folderId: '',
};

function UploadDialog({ fetch, onSubmit, accept, replacing }) {
  const { close } = useOutletContext();
  const { documentId } = useParams();
  const [fileFields, setFileFields] = useState(['file1']);
  return (
    <FormikProvider
      schema={schema}
      initialValues={{ ...defaultValues, folderId: documentId }}
      onSubmit={onSubmit}
    >
      <DialogTitleWithClose onClose={close}>
        {replacing ? (
          <FormattedMessage
            id="FormView.DocumentToImport.ReplaceDialog.Title"
            defaultMessage="Remplacer un document"
          />
        ) : (
          <FormattedMessage
            id="FormView.DocumentToImport.UploadDialog.Title"
            defaultMessage="Importer un document"
          />
        )}
      </DialogTitleWithClose>
      <DialogContent>
        <ErrorMessage error={fetch?.error} />
        {fileFields.map(fieldId => (
          <Box
            key={`area-${fieldId}`}
            sx={{
              display: 'flex',
            }}
          >
            <FormikFileField
              key={fieldId}
              name={fieldId}
              required
              accept={accept}
              label={
                <FormattedMessage
                  id="FormView.DocumentToImport.DocumentUploadRow.Fields.Document"
                  defaultMessage="Document à importer"
                />
              }
            />
            {Boolean(fileFields.length > 1) && (
              <DeleteIconButton
                sx={{
                  mt: 4,
                  ml: 1,
                }}
                onClick={() => {
                  setFileFields(fileFields.filter(ff => ff !== fieldId));
                }}
              />
            )}
          </Box>
        ))}
        <Box sx={{ textAlign: 'center' }}>
          <AddButton
            variant="text"
            label="Ajouter un document"
            onClick={() => {
              const newFileFieldNumber =
                parseInt(
                  fileFields[fileFields.length - 1].replace('file', ''),
                  10
                ) + 1;
              setFileFields(current => [
                ...current,
                `file${newFileFieldNumber}`,
              ]);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button type="submit" variant="contained" disabled={fetch?.fetching}>
          {replacing ? (
            <FormattedMessage
              id="FormView.DocumentToImport.DocumentUploadRow.Button.Replace"
              defaultMessage="Remplacer"
            />
          ) : (
            <FormattedMessage
              id="FormView.DocumentToImport.DocumentUploadRow.Button.Import"
              defaultMessage="Importer"
            />
          )}
        </Button>
      </DialogActionsWithClose>
    </FormikProvider>
  );
}

export default UploadDialog;
