import React from 'react';
import { Button, DialogContent, Grid } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import DialogTitleWithClose from '@components/Dialog/DialogTitleWithClose';
import DialogActionsWithClose from '@components/Dialog/DialogActionsWithClose';
import '@utils/yup/phone'; // add Yup.phone() validation
import FormikTextField from '@components/Formik/FormikTextField';
import { FieldArray, Form, Formik, FormikProvider } from 'formik';
import DeleteIconButton from '@components/Buttons/IconButtons/DeleteIconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box } from '@mui/system';
import useFetch from '@hooks/useFetch';

function EmailNotificationForm({ values, push, remove }) {
  // Document Search
  return (
    <Box>
      <>
        {values.emails.map((_, index) => (
          <Grid container>
            <Grid item xs={11}>
              <FormikTextField
                sx={{ mt: 1 }}
                name={`emails.${index}.EmailNotification`}
                label="Email"
              />
            </Grid>
            <Grid item xs={1}>
              <DeleteIconButton sx={{ mt: 4 }} onClick={() => remove(index)}>
                Remove
              </DeleteIconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          variant="text"
          startIcon={<AddCircleIcon />}
          onClick={() => push({ emailNotification: '' })}
          sx={{ mt: 2 }}
        >
          Ajouter un email
        </Button>
      </>
    </Box>
  );
}

function UpdateEmailNotificationDialog({
  folderId,
  notifications,
  refreshFolder,
}) {
  const { close } = useOutletContext();

  const initialValues = {
    emails: notifications ?? [{ EmailNotification: '' }],
  };

  const submitForm = useFetch({
    method: 'PUT',
    url: `/nexity/dashboard/folders/${folderId}/folderInformations/updateNotificationEmails`,
    onSuccess: () => {
      close();
      refreshFolder();
    },
  });

  const onSubmitCallback = React.useCallback(
    values => {
      submitForm.doFetch({ body: { emails: values.emails } });
    },
    [submitForm]
  );

  return (
    <FormikProvider>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmitCallback}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray name="emails">
              {({ push, remove }) => (
                <>
                  <DialogTitleWithClose onClose={close}>
                    Modifier le/les email(s) de notification
                  </DialogTitleWithClose>
                  <DialogContent>
                    <EmailNotificationForm
                      values={values}
                      setFieldValue={setFieldValue}
                      push={push}
                      remove={remove}
                    />
                  </DialogContent>
                  <DialogActionsWithClose onClose={close}>
                    <Button type="submit" variant="contained">
                      Enregistrer
                    </Button>
                  </DialogActionsWithClose>
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </FormikProvider>
  );
}

export default UpdateEmailNotificationDialog;
