import { FormControl, TextField } from '@mui/material';
import { useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import styled from '@emotion/styled';
import FilterWrapper from './FilterWrapper';
import { PopoverFiltersProps } from './helpers/types';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:not(.Mui-error):hover fieldset': {
      borderColor: 'transparent',
    },
    '&:not(.Mui-error).Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: '#666666',
  },
});

function NumberPopoverFilters({
  filter,
  onValidate = undefined,
  onRemoveFilter = undefined,
  advancedSearchRequest = undefined,
  setAdvancedSearchRequest = undefined,
  titleSx,
}: PopoverFiltersProps) {
  const { id, showSearchButton, termSheetValueType = undefined } = filter;
  const [searchRequest, setSearchRequest] = useState(
    advancedSearchRequest ?? {}
  );
  const getCurrentValue = () => {
    if (termSheetValueType) {
      const index = searchRequest?.termSheetQuery?.findIndex(
        (x: any) => x?.elementId === id
      );
      if (index >= 0) {
        return searchRequest?.termSheetQuery[index].items;
      }
      return '';
    }
    return searchRequest?.[id] ?? '';
  };
  const setCurrentValue = (value: any) => {
    const tmpValue = cloneDeep(searchRequest);
    if (termSheetValueType) {
      const index = tmpValue.termSheetQuery?.findIndex(
        (x: any) => x?.elementId === id
      );
      if (index >= 0) {
        tmpValue.termSheetQuery.splice(index, 1);
      }
      if (value !== '') {
        if (tmpValue.termSheetQuery === undefined) {
          tmpValue.termSheetQuery = [];
        }
        tmpValue.termSheetQuery.push({
          elementId: id,
          items: value,
        });
      }
      setSearchRequest(tmpValue);
    } else if (value === '') {
      // eslint-disable-next-line no-param-reassign
      delete searchRequest[id];
      setSearchRequest({ ...searchRequest });
    } else {
      setSearchRequest({
        ...searchRequest,
        [id]: value,
      });
    }
  };
  const onchange = (e: any) => {
    if (
      setAdvancedSearchRequest === undefined ||
      advancedSearchRequest === undefined
    ) {
      return;
    }
    setCurrentValue(e.target.value);
  };
  const onClear = () => {
    setCurrentValue('');
  };
  const onDelete = () => {
    onClear();
    if (onRemoveFilter) {
      onRemoveFilter(id);
    }
  };
  const onLocalValidate = () => {
    if (setAdvancedSearchRequest) {
      setAdvancedSearchRequest(searchRequest);
    }
    if (onValidate) {
      onValidate();
    }
  };
  return (
    <FilterWrapper
      showSearchButton={showSearchButton}
      onValidate={onLocalValidate}
      onClear={onClear}
      onDelete={onDelete}
      filter={filter}
      isRemovable={
        !(filter.isAdditionalFilter ?? false) &&
        termSheetValueType !== undefined
      }
      titleSx={titleSx}
    >
      <FormControl fullWidth>
        <StyledTextField
          type="number"
          value={getCurrentValue()}
          onChange={onchange}
          sx={{ width: 320 }}
        />
      </FormControl>
    </FilterWrapper>
  );
}

export default NumberPopoverFilters;
