import React from 'react';
import { useFormikContext } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import FormikDatePicker from '@components/Formik/FormikDatePicker';
// import FormikTextField from '@components/Formik/FormikTextField';
import FormikSelect from '@components/Formik/FormikSelect';

import fields from './fields';

function FamilyFormMarried({ name, index, isFieldReadOnly }) {
  const { values } = useFormikContext();
  const { marriageContract } = values[name][index];
  const namePrefix = `${name}.${index}`;
  return (
    <>
      {/* <FormikDatePicker
        required
        disabled={readOnly}
        margin="dense"
        maxDate={new Date()}
        name={`${name}.${index}.${fields.dateOfMarriage}`}
        label="Date du mariage"
        TextFieldProps={{ color: 'secondary', required: true, margin: 'dense' }}
      />
      <FormikTextField
        required
        disabled={readOnly}
        margin="dense"
        name={`${name}.${index}.${fields.marriagePlace}`}
        label="Lieu du mariage"
        color="secondary"
      /> */}
      <FormikSelect
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.marriageType}`)}
        name={`${namePrefix}.${fields.marriageType}`}
        label="Régime adopté"
        FormControlProps={{
          color: 'secondary',
          margin: 'dense',
          disabled: isFieldReadOnly(`${namePrefix}.${fields.marriageType}`),
        }}
        margin="dense"
      >
        <MenuItem value="separation">Séparation des biens</MenuItem>
        <MenuItem value="shared">Partage des biens</MenuItem>
      </FormikSelect>
      <FormikSelect
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.marriageContract}`)}
        name={`${namePrefix}.${fields.marriageContract}`}
        label="Contrat de mariage"
        FormControlProps={{
          color: 'secondary',
          margin: 'dense',
          disabled: isFieldReadOnly(`${namePrefix}.${fields.marriageContract}`),
        }}
        margin="dense"
      >
        <MenuItem value="true">Oui</MenuItem>
        <MenuItem value="false">Non</MenuItem>
      </FormikSelect>
      {marriageContract === 'true' && (
        <FormikDatePicker
          required
          disabled={isFieldReadOnly(
            `${namePrefix}.${fields.marriageContractDate}`
          )}
          margin="dense"
          maxDate={new Date()}
          name={`${namePrefix}.${fields.marriageContractDate}`}
          label="Date du contrat de mariage"
          TextFieldProps={{
            color: 'secondary',
            required: true,
            margin: 'dense',
            placeholder: 'dd/mm/yyyy',
          }}
        />
      )}
    </>
  );
}

export default FamilyFormMarried;
