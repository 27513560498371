import History from '@utils/History';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export const defaultNavigationBlockMessage = 'Voulez-vous quitter la page ?';

function NavigationBlocker({ unsavedData, message }) {
  const navigate = useNavigate();
  const listenerRef = useRef(null);

  // Confirm message before navigate other location
  useEffect(() => {
    const unblock = History.block(tx => {
      const isModalUrl =
        tx.location.state === 'modals' || !!tx.location.state?.modal;
      if (unsavedData && !isModalUrl) {
        if (window.confirm(message || defaultNavigationBlockMessage)) {
          unblock();
          navigate(tx.location);
        }
      } else {
        unblock();
        navigate(tx.location);
      }
      return false;
    });

    // just in case theres an unmount we can unblock if it exists
    return unblock;
  }, [unsavedData, message, navigate]);

  // Confirm message before refresh or close page
  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const handleUnload = event => {
      if (unsavedData) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = message || defaultNavigationBlockMessage;
        return message || defaultNavigationBlockMessage;
      }
    };
    if (unsavedData) {
      window.addEventListener('beforeunload', handleUnload);
      listenerRef.current = handleUnload;
    } else if (listenerRef.current) {
      window.removeEventListener('beforeunload', listenerRef.current);
      listenerRef.current = null;
    }
    return () => {
      if (listenerRef.current) {
        window.removeEventListener('beforeunload', listenerRef.current);
        listenerRef.current = null;
      }
    };
  }, [message, unsavedData]);

  return null;
}

export default NavigationBlocker;
