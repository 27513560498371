import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import FormikSelect from '@components/Formik/FormikSelect';
import { useAppContext } from '@contexts/AppContext';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { hostTokenSelector } from '@recoil/host-token';
import { tenantAuthenticationSelector } from '@recoil/tenant-token';
import Fetching from '@components/Fetching/Fetching';
import { authorize } from '@utils/authentication';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useFetch from '@hooks/useFetch';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as yup from 'yup';

const defaultSchema = yup.object().shape({
  tenant: yup.string().required('Authorize.Fields.Tenant.Required'),
});

export const defaultValues = {
  tenant: '',
};

function AuthorizeForm({ backTo = '..', tenantClient: tenantClientProp }) {
  const appContext = useAppContext();
  const tenantClient = tenantClientProp ?? appContext.tenantClient;
  const [searchParams] = useSearchParams();
  const redirectToParams = searchParams.get('redirectTo') || '';
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = React.useState('');
  const [forceRedirect, setForceRedirect] = React.useState(false);
  const [errorPermissionRedirect, setErrorPermissionRedirect] =
    React.useState(false);
  const hostToken = useRecoilValue(hostTokenSelector);
  const setTenantAuthenticationState = useSetRecoilState(
    tenantAuthenticationSelector
  );
  const [fetching, setFetching] = useState(false);
  const regexFindTenantId = /\/tenant\/([0-9a-fA-F-]{36})\//;

  const validationSchema = defaultSchema;

  const { data, fetching: fetchingTenants } = useFetch({
    method: 'GET',
    host: true,
    url: `identity/users/current/tenants?tenantClient=${tenantClient ?? ''}`,
  });

  const tenants = data?.tenants;

  const onSubmitCallback = async form => {
    setFetching(true);
    const result = await authorize(hostToken, form);
    setFetching(false);

    if (result.requireMfa) {
      setTenantAuthenticationState(result);
      navigate(
        `/signin/authorize/elevate${
          redirectToParams !== '' ? `?redirectTo=${redirectToParams}` : ''
        }`,
        {
          state: location.state,
        }
      );
    } else if (!result.succeeded) {
      setError(result.error);
      setTenantAuthenticationState(null);
    } else {
      setTenantAuthenticationState(result);
      const defaultTo = { pathname: '/' };
      const from = location.state?.from;
      let fromTo = null;
      if (from) {
        fromTo = `${from.pathname}${from.search}${from.hash}`;
      } else if (redirectToParams !== '') {
        fromTo = decodeURIComponent(
          redirectToParams.replace(regexFindTenantId, '/')
        );
      }
      const to =
        !fromTo || fromTo === '/' || errorPermissionRedirect === true
          ? defaultTo
          : fromTo;
      navigate(to);
    }
  };
  const formik = useFormik({
    validationSchema,
    initialValues: defaultValues,
    onSubmit: onSubmitCallback,
  });

  const tenantsOptions = React.useMemo(
    () =>
      tenantClient
        ? tenants?.filter(t => !t.client || t.client === tenantClient)
        : tenants,
    [tenants, tenantClient]
  );

  const checkTenantIdByRedirectTo = async path => {
    const matchId = path.match(regexFindTenantId);
    const tenantId = matchId ? matchId[1] : null;
    if (tenantId === null) {
      setForceRedirect(false);
      if (tenantsOptions?.length === 1) {
        formik.setFieldValue('tenant', tenantsOptions[0].id);
        await onSubmitCallback({ tenant: tenantsOptions[0].id });
      }
      return;
    }
    const tenantIdExist = tenantsOptions?.filter(x => x.id === tenantId);
    if (tenantIdExist?.length === 1) {
      setForceRedirect(true);
      formik.setFieldValue('tenant', tenantId);
      await onSubmitCallback({ tenant: tenantId });
    } else if (tenantIdExist === undefined || tenantIdExist === null) {
      // nothing
    } else {
      setForceRedirect(false);
      setErrorPermissionRedirect(true);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => {
    if (location.state?.from) {
      await checkTenantIdByRedirectTo(location.state?.from.pathname);
      setForceRedirect(false);
    } else if (redirectToParams !== '') {
      checkTenantIdByRedirectTo(redirectToParams);
    } else if (tenantsOptions?.length === 1) {
      formik.setFieldValue('tenant', tenantsOptions[0].id);
      await onSubmitCallback({ tenant: tenantsOptions[0].id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantsOptions]);

  const hideTenantsSelect =
    fetching ||
    (tenantsOptions?.length === 1 && errorPermissionRedirect === false) ||
    forceRedirect === true;

  return (
    <FormikProvider value={formik}>
      <Fetching fetching={fetchingTenants}>
        <Form noValidate>
          {error !== '' && <ErrorMessage error={error} sx={{ mt: 1, mb: 2 }} />}
          {errorPermissionRedirect && (
            <ErrorMessage error="UnauthorizedBuAccess" sx={{ mt: 1, mb: 2 }} />
          )}
          {tenantsOptions && !hideTenantsSelect && (
            <FormikSelect
              name="tenant"
              required
              label={<FormattedMessage id="Authorize.Fields.Tenant.Label" />}
            >
              {tenantsOptions.map(t => (
                <MenuItem key={t.id} value={t.id}>
                  {t.name}
                </MenuItem>
              ))}
            </FormikSelect>
          )}
          {!fetching && (
            <>
              {!error && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  <FormattedMessage id="Authorize.Button" />
                </Button>
              )}
              <Button
                type="button"
                fullWidth
                variant="text"
                color="secondary"
                sx={{ mt: 2 }}
                to={backTo}
                component={Link}
              >
                <FormattedMessage id="Generic.Back" />
              </Button>
            </>
          )}
        </Form>
      </Fetching>
    </FormikProvider>
  );
}

export default AuthorizeForm;
