import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FastField, Field } from 'formik';
import TextField from '@mui/material/TextField';

import { valueFromPath } from '@utils/json-path';
import styled from '@emotion/styled';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  getDateTimezoneIndependantFromTimestamp,
  getTimestampFromDateTimezoneIndependant,
} from '@utils/date-helpers';

const StyledTextField = styled(TextField)({
  '& .MuiIconButton-root': {
    marginRight: 0,
  },
});

function MuiDateTimePicker({
  field,
  form: { errors, touched, setFieldValue, setFieldTouched },
  readOnly,
  TextFieldProps = {},
  ...props
}) {
  const { name, value: timestampValue } = field;
  const error = name.includes('.') ? valueFromPath(errors, name) : errors[name];
  const touch = name.includes('.')
    ? valueFromPath(touched, name)
    : touched[name];
  const hasError = Boolean(touch) && Boolean(error);

  return (
    <DateTimePicker
      {...props}
      {...field}
      value={getDateTimezoneIndependantFromTimestamp(timestampValue)}
      onChange={newValue => {
        setFieldValue(
          name,
          newValue ? getTimestampFromDateTimezoneIndependant(newValue) : ''
        );
      }}
      renderInput={params => (
        <StyledTextField
          fullWidth
          margin="normal"
          autoComplete="off"
          {...TextFieldProps}
          {...params}
          placeholder={
            TextFieldProps?.placeholder ?? params?.inputProps?.placeholder ?? ''
          }
          InputProps={{
            readOnly,
            ...params.InputProps,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            placeholder:
              TextFieldProps?.placeholder ??
              params?.inputProps?.placeholder ??
              '',
          }}
          required={props?.required ?? false}
          onBlur={() => {
            setFieldTouched(name, true);
          }}
          error={hasError}
          helperText={
            hasError && (
              <FormattedMessage
                id={error}
                values={{
                  label: props?.label ?? '',
                  value: field?.value ?? '',
                }}
              />
            )
          }
        />
      )}
    />
  );
}

function FormikDateTimePicker({ as = 'field', ...props }) {
  if (as === 'fastfield') {
    return <FastField component={MuiDateTimePicker} {...props} />;
  }
  return <Field component={MuiDateTimePicker} {...props} />;
}

export default FormikDateTimePicker;
