import React from 'react';
import FormikDatePicker from '@components/Formik/FormikDatePicker';
// import FormikTextField from '@components/Formik/FormikTextField';
import FormikCheckbox from '@components/Formik/FormikCheckbox';

import fields from './fields';

function FamilyFormDivorce({ name, index, isFieldReadOnly }) {
  const namePrefix = `${name}.${index}`;
  return (
    <>
      {/* <FormikTextField
        required
        disabled={readOnly}
        margin="dense"
        name={`${name}.${index}.divorceCourt`}
        label="Jugement du Tribunal de (nom tribunal)"
        color="secondary"
      /> */}
      <FormikDatePicker
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.dateOfDivorce}`)}
        maxDate={new Date()}
        margin="dense"
        name={`${namePrefix}.${fields.dateOfDivorce}`}
        label="Date jugement du tribunal"
        TextFieldProps={{
          color: 'secondary',
          required: true,
          margin: 'dense',
          placeholder: 'dd/mm/yyyy',
        }}
      />
      <FormikCheckbox
        disabled={isFieldReadOnly(`${namePrefix}.${fields.divorceSeparated}`)}
        margin="dense"
        name={`${namePrefix}.${fields.divorceSeparated}`}
        label="Séparé(e) de corps et de biens"
      />
    </>
  );
}

export default FamilyFormDivorce;
