import React, { useCallback, useState } from 'react';
import Stack from '@mui/material/Stack';
import useFetch from '@hooks/useFetch';
import Fetching from '@components/Fetching/Fetching';
import { Alert, Button, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import ConfirmModal from '@components/Modals/ConfirmModal';
import { FormattedMessage, useIntl } from 'react-intl';
import SaveIcon from '@material-ui/icons/Save';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import { Route, useNavigate } from 'react-router-dom';
import {
  ContractsAccessResponse,
  workflowContributorAccess,
} from '@utils/api/types';
import useTenantPermissions from '@hooks/useTenantPermissions';
import tenantPermissions from '@utils/tenant-permissions';
import VersionList from './VersionList';
import AddVersionModal from './AddVersionModal';

type ContractVersionViewProps = {
  contractId: string;
};

function AddVersionButton({ canContribute }: { canContribute: boolean }) {
  const navigate = useNavigate();
  const hasCreatePermission = useTenantPermissions({
    permissions: [tenantPermissions.VersionsCreate],
  });

  if (!hasCreatePermission) return null;

  return (
    <Button
      disableElevation
      startIcon={<SaveIcon />}
      variant="outlined"
      color="primary"
      sx={{ borderRadius: '4px' }}
      onClick={() => navigate('versions-modals/version/add')}
      disabled={!canContribute}
    >
      <FormattedMessage id="Contract.Version.Button.Add.Label" />
    </Button>
  );
}

function ContractVersionView({ contractId }: ContractVersionViewProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isOpen, setModalOpened] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>();

  const close = () => setModalOpened(false);

  const { data: accessData, fetching: fetchingAccess } =
    useFetch<ContractsAccessResponse>({
      url: `contracts/${contractId}/access`,
      method: 'GET',
    });

  const canContribute = Boolean(
    accessData?.workflowAccesses?.some(
      access =>
        access === workflowContributorAccess.Form ||
        access === workflowContributorAccess.FormReview ||
        access === workflowContributorAccess.Review
    )
  );

  const { data, fetching, doFetch } = useFetch({
    url: `contracts/${contractId}/versions`,
    method: 'GET',
  });

  const versions = data?.versions;

  const { fetching: restoreFetching, doFetch: restoreDoFetch } = useFetch({
    method: 'PUT',
    lazy: true,
    onSuccess: () => {
      toast.success(
        intl.formatMessage({ id: 'Contract.Version.Restore.Success' }),
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      doFetch();
    },
  });

  const downloadVersion = useCallback((versionId: string) => {
    setSelectedVersion(versionId);
  }, []);

  const restoreVersion = useCallback((versionId: string) => {
    setSelectedVersion(versionId);
    setModalOpened(true);
  }, []);

  const handleSubmit = () => {
    setModalOpened(false);
    restoreDoFetch({
      url: `contracts/${contractId}/versions/${selectedVersion}/restore`,
    });
  };

  const compareVersion = useCallback((versionId: string) => {
    setSelectedVersion(versionId);
  }, []);

  const navigateToContract = () => navigate(`.`);

  const refresh = () => {
    navigateToContract();
    doFetch();
  };

  return (
    <>
      <Stack
        direction="column"
        sx={{ width: '100%', minHeight: '100%', mb: 5 }}
      >
        <Stack
          direction="row"
          sx={{
            width: '100%',
            height: '48px',
            minHeight: '48px',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            p: '10px 24px',
          }}
        >
          <AddVersionButton canContribute={canContribute} />
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: '30%',
            borderRight: 'solid 1px lightgray',
            minHeight: '100%',
          }}
        >
          {!fetchingAccess && !canContribute && (
            <Alert severity="info">
              <Typography>
                <FormattedMessage id="Contract.Version.Access.Warning.Text" />
              </Typography>
            </Alert>
          )}
          <Fetching fetching={fetching || restoreFetching || fetchingAccess}>
            <VersionList
              versions={versions}
              onCompare={compareVersion}
              onDownload={downloadVersion}
              onRestore={restoreVersion}
              canContribute={canContribute}
            />
          </Fetching>
        </Stack>
        <Stack direction="column" sx={{ width: '70%' }} />
      </Stack>
      <ConfirmModal
        isOpen={isOpen}
        onClose={close}
        onSubmit={handleSubmit}
        title="Contract.Version.Dialog.Restore.Title"
        description="Contract.Version.Dialog.Restore.Description.Label"
        cancelButtonText="Cancel"
        submitButtonText="Confirm"
      />
      <DialogOutletRoutes path="versions-modals/*" onClose={navigateToContract}>
        <Route
          path="version/add"
          element={
            <AddVersionModal contractId={contractId} onSuccess={refresh} />
          }
        />
      </DialogOutletRoutes>
    </>
  );
}

export default ContractVersionView;
