import React from 'react';
import { isActive } from '@tiptap/core';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { FontFamilyExtension } from '@smartclause-tiptap-extensions/schema-core/dist';

import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined';

import { useTiptapEditor } from '../../use-tiptap-editor';
import MenuListItem from '../MenuListItem';

const defaultFontFamilyOptions = [
  'Arial',
  'Calibri',
  'Garamond',
  'Georgia',
  'Helvetica',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];

function ButtonFontFamily() {
  const editor = useTiptapEditor();
  const additionalFontFamilyOptions = editor?.options?.extensions?.find(e => e?.name === FontFamilyExtension.name)?.options?.additionalOptions;

  const fontFamilyOptions = additionalFontFamilyOptions
    ? defaultFontFamilyOptions.concat(additionalFontFamilyOptions)
    : defaultFontFamilyOptions;

  return (
    <PopupState variant="popper" popupId="font-family-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={<FormattedMessage id="Editor.Toolbar.Command.FontFamily" />}
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <FontDownloadOutlinedIcon />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <MenuList sx={{ p: 0 }} component={Paper} elevation={8}>
                  {fontFamilyOptions.map(font => (
                    <MenuListItem
                      key={font}
                      label={font}
                      onClick={() => {
                        editor.chain().focus().setFontFamily(font).run();
                        popupState.close();
                      }}
                      selector={state =>
                        Boolean(state) &&
                        isActive(state, null, { fontFamily: font })
                      }
                    />
                  ))}
                </MenuList>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonFontFamily;
