import React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import FormsToCompleteStatus from './FormsToCompleteStatus';
import FormsToCompleteButton from './FormsToCompleteButton';

function FormsToCompleteRow({ id, type, status, children }) {
  return (
    <Paper sx={{ my: 2, mx: '5%', p: 2 }}>
      <Stack direction="row" alignItems="center" gap={2}>
        <DynamicFormIcon />
        <Typography
          color="secondary"
          sx={{ textTransform: 'uppercase' }}
          variant="h6"
        >
          {children}
        </Typography>
        <FormsToCompleteStatus status={status} />
        <FormsToCompleteButton status={status} id={id} type={type} />
      </Stack>
    </Paper>
  );
}

export default FormsToCompleteRow;
