import React from 'react';
import { isActive } from '@tiptap/core';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';

import TitleIcon from '@mui/icons-material/Title';

import { useTiptapEditor } from '../../use-tiptap-editor';
import MenuListItem from '../MenuListItem';

const headingLevelOptions = [1, 2, 3, 4, 5];

function ButtonHeading() {
  const editor = useTiptapEditor();
  return (
    <PopupState variant="popper" popupId="heading-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={<FormattedMessage id="Editor.Toolbar.Command.Heading" />}
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <TitleIcon />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <MenuList sx={{ p: 0 }} component={Paper} elevation={8}>
                  <MenuListItem
                    label={
                      <FormattedMessage id="Editor.Toolbar.Command.Heading.Paragraph" />
                    }
                    onClick={() => {
                      editor.chain().focus().setParagraph().run();
                      popupState.close();
                    }}
                    selector={state =>
                      Boolean(state) && isActive(state, 'paragraph')
                    }
                  />
                  {headingLevelOptions.map(level => (
                    <MenuListItem
                      key={level}
                      label={
                        <FormattedMessage
                          id="Editor.Toolbar.Command.Heading.Level"
                          values={{ level }}
                        />
                      }
                      onClick={() => {
                        editor.chain().focus().toggleHeading({ level }).run();
                        popupState.close();
                      }}
                      selector={state =>
                        Boolean(state) && isActive(state, 'heading', { level })
                      }
                    />
                  ))}
                </MenuList>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonHeading;
