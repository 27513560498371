import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';

export function createNexityTheme(moreThemeOptions = {}) {
  const defaultThemeOptions = {
    palette: {
      primary: {
        dark: '#a61b2c',
        main: '#D22238',
        light: '#e87281',
      },
      secondary: {
        main: '#4A4A4A',
        100: '#D222381A',
        600: '#67B800',
      },
      muted: {
        main: '#6c757d',
      },
      danger: {
        main: '#E5175C',
        contrastText: '#fff',
      },
      grey: {
        main: '#959595',
        200: '#e8e8e8',
        400: '#cccccc',
        500: '#959595',
        700: '#666666',
        900: '#1f1f1f',
      },
      buttonDisabled: {
        main: '#959595',
      },
      white: {
        main: '#FFFFFF',
      },
      chipSuccess: { main: '#F4FBEE', contrastText: '#A7DF76' },
      chipWarning: { main: '#FBF4EA', contrastText: '#F5A623' },
      chipError: { main: '#FCE7EE', contrastText: '#E5175C' },
      chipDefault: { main: '#E8E8E8', contrastText: '#6c757d' },
      chipInfo: { main: '#0d99ff1f', contrastText: '#0D99FF' },
      chipGrey: { main: '#EBEBEB', contrastText: '#666666' },
      chipPending: { main: '#0d99ff1f', contrastText: '#0D99FF' },
      upload: { main: '#4B8270' },
      delete: { main: '#c40f11' },
      modal: { main: '#FFFFFF' },
    },
    typography: {
      subtitle1: {
        fontSize: '14px',
        color: '#6c757d',
      },
      tableCell: {
        fontSize: '12px',
      },
      tableCellEllipsis: {
        fontSize: '12px',
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        cursor: 'default',
      },
    },
  };

  const finalThemeOptions = {
    ...defaultThemeOptions,
    palette: {
      ...defaultThemeOptions.palette,
      ...moreThemeOptions?.palette,
    },
  };

  let theme = createTheme(finalThemeOptions);

  theme = createTheme(theme, {
    components: {
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            color: theme.palette.grey[700],
            '& .MuiTypography-root': {
              fontSize: '0.6875rem',
            },
            '& .MuiLink-root': {
              color: 'inherit',
              textDecoration: 'none',
            },
            '& .MuiSvgIcon-root': {
              color: 'inherit',
            },
          },
          li: {
            ':first-of-type': {
              fontWeight: 'bold',
              '& .MuiTypography-root': {
                fontSize: '1rem',
              },
            },
            ':not(:first-of-type) .MuiLink-root': {
              fontWeight: 'bold',
              fontSize: '1rem',
              display: 'block',
            },
            ':last-of-type:not(.MuiLink-root)': {
              '& .MuiTypography-root': {
                fontSize: '1rem',
              },
              color: theme.palette.grey[900],
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
        styleOverrides: {
          root: {
            borderRadius: '1rem',
            fontSize: '0.75rem',
            letterSpacing: '0.75px',
            '&.FileField-button': {
              borderTopRightRadius: '6px',
              borderBottomRightRadius: '6px',
            },
            '&.DuplicateField-button': {
              borderTopRightRadius: '6px',
              borderBottomRightRadius: '6px',
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '& .MuiSvgIcon-root': {
              color: 'inherit',
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
        @keyframes spinner-rotation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .scm-editor {
          min-width: 100%;
          min-height: 100%;
          cursor: auto;
          font-family: 'Arial';
        }
        .scm-editor:focus-visible {
          outline: none;
        }
        .scm-editor .scm-section .headers-options {
          width: 21cm;
          background-color: ${theme.palette.white.main};
          z-index: 2;
          padding: 5px;
          border: 1px solid black;
          border-bottom: none;
        }
        .scm-editor .scm-section .headers-options .tab-btn {
          color: ${theme.palette.grey[400]};
        }
        .scm-editor .scm-section .headers-options .tab-btn.Mui-selected {
          color: ${theme.palette.white.main};
        }
        .scm-editor .scm-section .headers-options .MuiTabs-indicator {
          background-color: ${theme.palette.white.main};
        }
        .scm-editor .edit-hf .scm-section header,
        .scm-editor .edit-hf .scm-section footer {
          z-index: 1;
          padding: 15px 25px;
          max-height: 25%;
          overflow: auto;
          width: 21cm;
          background-color:white;border:1px solid black;
        }
        .scm-editor .scm-clause__body > div > div > p:first-of-type {
          margin-top: 0;
        }
        .scm-editor .scm-clause__body > div > div > p:last-of-type {
          margin-bottom: 0;
        }
        .scm-editor .MuiTabs-scrollButtons.Mui-disabled {
          opacity: 0.3;
        }
        .scm-editor .node-clause.selected .MuiCard-root {
          background-color: ${alpha(theme.palette.primary.main, 0.1)};
          border-color: ${theme.palette.primary.main};
        }
        .scm-editor .node-clause.selected .MuiCard-root .MuiCardContent-root {
          background-color: ${theme.palette.common.white};
        }
        .scm-editor .scm-faq.visible {
          padding: 16px;
          border-radius: 0px 0px 8px 8px;
          border: 2px solid ${theme.palette.primary.main};
          position: relative;
          width: 90%;
        }
        .scm-editor .scm-faq.visible.editing {
          width: 100%;
        }
        .scm-editor .scm-faq.visible.selected {
          background-color: ${theme.palette.secondary.light}20;
          margin-left: auto;
        }
        .scm-editor .clausevariant-placeholder {
          color: ${theme.palette.grey[500]};
          font-style: italic;
          pointer-events: none;
        }
        .scm-editor span.insertion {
          background-color: ${alpha(theme.palette.success.main, 0.4)};
        }
        .scm-editor span.format-change {
          background-color: ${alpha(theme.palette.warning.main, 0.4)};
        }
        .scm-editor span.deletion {
          background-color: ${alpha(theme.palette.error.main, 0.4)};
          text-decoration: line-through;
        }
        .scm-editor [data-track] {
          position: relative;
        }
        .scm-editor [data-track]:not(table, tr, td)::before {
          content: '';
          position: absolute;
          border-left: 3px solid ${theme.palette.warning.main};
          left: -10px;
          top: 0px;
          bottom: 0px;
        }
        .scm-editor tr[data-track] td:first-of-type::before {
          content: '';
          position: absolute;
          border-left: 3px solid ${theme.palette.warning.main};
          left: -2px;
          top: 0px;
          bottom: 0px;
        }
        .scm-editor .annotation {
          background-color: #599EFF;
        }
        .scm-editor :is(.deletion, .insertion, .format-change) .annotation {
          background-color: transparent !important;
        }
        /* Placeholder (at the top) */
        .ProseMirror p.is-editor-empty:first-child::before {
          color: #adb5bd;
          content: attr(data-placeholder);
          float: left;
          height: 0;
          pointer-events: none;
        }
        /* Give a remote user a caret */
        .collaboration-cursor__caret {
          border-left: 1px solid #0d0d0d;
          border-right: 1px solid #0d0d0d;
          margin-left: -1px;
          margin-right: -1px;
          pointer-events: none;
          position: relative;
          word-break: normal;
        }
        /* Render the username above the caret */
        .collaboration-cursor__label {
          border-radius: 3px 3px 3px 0;
          color: #0d0d0d;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          left: -1px;
          line-height: normal;
          padding: 0.1rem 0.3rem;
          position: absolute;
          top: -1.4em;
          user-select: none;
          white-space: nowrap;
        }
        .imagePluginOverlay > button {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        .scm-html-editor {
          min-width: 100%;
          min-height: 100%;
          padding: 0 ${theme.spacing(2)};
          cursor: auto;
          font-family: 'Arial';
        }
        .scm-html-editor:focus-visible {
          outline: none;
        }
        [role='tooltip'] {
          z-index: 10;
        }
        .ProseMirror .tableWrapper,
        .ProseMirror table,
        .ProseMirror table tbody,
        .ProseMirror table tr,
        .ProseMirror table td,
        .ProseMirror table th {
          position: relative;
        }
        .ProseMirror table {
          border-collapse: collapse;
          margin: 0;
          overflow: hidden;
          table-layout: fixed;
          width: 100%;
        }
        .ProseMirror table td,
        .ProseMirror table th {
          border: 2px solid #ced4da;
          box-sizing: border-box;
          min-width: 1em;
          padding: 3px 5px;
          position: relative;
          vertical-align: top;
        }
        .ProseMirror table > * {
          margin-bottom: 0;
        }
        .ProseMirror table th {
          background-color: #f1f3f5;
          font-weight: bold;
          text-align: left;
        }
        .ProseMirror table .selectedCell:after {
          background: rgba(200, 200, 255, 0.4);
          content: "";
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          pointer-events: none;
          position: absolute;
          z-index: 2;
        }
        .ProseMirror table .column-resize-handle {
          background-color: #adf;
          bottom: -2px;
          position: absolute;
          right: -2px;
          pointer-events: none;
          top: 0;
          width: 4px;
        }
      `,
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '8px',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: 'center',
            padding: theme.spacing(3, 5),
            '& > :not(:first-of-type)': {
              marginLeft: theme.spacing(2),
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            justifyContent: 'center',
            padding: theme.spacing(0, 5),
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            color: theme.palette.grey[900],
            margin: theme.spacing(3, 5, 0),
            padding: theme.spacing(0, 0, 3),
            fontSize: '1rem',
            [theme.breakpoints.up('sm')]: { fontSize: '1rem' },
            [theme.breakpoints.up('md')]: { fontSize: '1rem' },
            [theme.breakpoints.up('lg')]: { fontSize: '1rem' },
            fontWeight: 700,
            letterSpacing: '0.15px',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          marginDense: {
            margin: 0,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            marginRight: 0,
            '& .MuiSvgIcon-root': {
              fontSize: '1rem',
              color: theme.palette.grey[700],
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: '0.6875rem',
            backgroundColor: theme.palette.grey[200],
            '&.MuiInputBase-adornedStart': {
              paddingLeft: theme.spacing(1),
            },
          },
          input: {
            height: '17px',
            borderRadius: '6px',
            '&.MuiOutlinedInput-input': {
              padding: theme.spacing(1),
            },
            '&::placeholder': {
              color: theme.palette.grey[600],
              opacity: 1,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            position: 'relative',
            top: 'unset',
            left: 'unset',
            fontSize: '0.6875rem',
            transform: 'none',
            fontWeight: 700,
            marginBottom: theme.spacing(1),
            '&, &.Mui-focused': {
              color: theme.palette.grey[700],
            },
          },
          shrink: {
            transform: 'none',
          },
          asterisk: {
            color: theme.palette.danger.main,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[200],
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
            },
          },
          notchedOutline: {
            borderColor: theme.palette.grey[200],
            '& > legend': {
              width: 0,
            },
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          ul: {
            justifyContent: 'center',
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            fontSize: '0.6875rem',
            minWidth: '25px',
            height: '25px',
            '&:hover, &.Mui-focusVisible': {
              color: theme.palette.primary.main,
            },
          },
          page: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.grey[400],
            margin: '0 6px',
            '&:hover, &.Mui-focusVisible': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
            },
          },
          previousNext: {
            color: theme.palette.grey[400],
            backgroundColor: 'transparent',
            '&:hover, &.Mui-focusVisible': {
              color: theme.palette.primary.main,
              backgroundColor: 'transparent',
            },
          },
          firstLast: {
            color: theme.palette.grey[400],
            backgroundColor: 'transparent',
            '&:hover, &.Mui-focusVisible': {
              color: theme.palette.primary.main,
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: theme.palette.muted.main,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '12px',
          },
          body: {
            color: theme.palette.muted.main,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '&.Search-root .MuiInputBase-root': {
              borderRadius: '8px',
            },
          },
        },
      },
      MuiTimelineItem: {
        styleOverrides: {
          root: {
            '&:before': {
              display: 'none',
            },
          },
        },
      },
    },
  });

  return responsiveFontSizes(theme);
}

export default createNexityTheme();
