import './assets/fonts/nexity/index.css';
import './assets/fonts/nexity/Nexity-Regular.ttf';
import './assets/fonts/nexity/Nexity-Italic.ttf';
import './assets/fonts/nexity/Nexity-Light.ttf';
import './assets/fonts/nexity/Nexity-Light Italic.ttf';
import './assets/fonts/nexity/Nexity-Medium.ttf';
import './assets/fonts/nexity/Nexity-Medium Italic.ttf';
import './assets/fonts/nexity/Nexity-Bold.ttf';
import './assets/fonts/nexity/Nexity-Bold Italic.ttf';
import './assets/fonts/nexity/Nexity-Heavy.ttf';
import './assets/fonts/nexity/Nexity-Heavy Italic.ttf';

import nexityTheme from '@styles/nexity';
import GenericApp from '@components/App/GenericApp';
import { AppContextProvider } from '@contexts/AppContext';
import NexityRoutes from './nexity-routes';
import { NexityThemeContextProvider } from './contexts/NexityThemeContext';

const nexityAppContextValue = {
  contract: {
    workflow: { hidden: false, showEmail: true },
    versions: { hidden: false },
    annexes: { hidden: false },
    notes: { hidden: false },
    useCustomUi: () => ({}),
  },
  dashboard: { disabled: false },
  drive: {
    hideCreate: false,
    hideSearchExport: false,
    hideProgress: false,
    filters: { hideCreatedBy: false },
  },
  calendar: { disabled: false },
  statistics: { disabled: false },
  headers: {
    useCustomUserMenu: () => null,
  },
};

function chooseTokenNavigation({ type, element }) {
  if (type) {
    if (type === 'dashboard') {
      return { pathname: '/' };
    }
    if (type === 'folder' && element) {
      return {
        pathname: `/folders/${element}`,
      };
    }
  }
  return {
    pathname: '/',
  };
}

function NexityApp({ theme = nexityTheme, themeName = 'nexity' }) {
  return (
    <AppContextProvider value={nexityAppContextValue}>
      <NexityThemeContextProvider value={{ name: themeName }}>
        <GenericApp
          theme={theme}
          chooseTokenNavigation={chooseTokenNavigation}
          forcedLanguage="fr"
        >
          <NexityRoutes />
        </GenericApp>
      </NexityThemeContextProvider>
    </AppContextProvider>
  );
}

export default NexityApp;
