import { defineMessages } from 'react-intl';

export default defineMessages({
  ContractContentSaveSuccess: {
    id: 'Contract.Content.Save.Success',
    defaultMessage: 'Document content has been saved successfully',
  },
  ContractContentSaveError: {
    id: 'Contract.Content.Save.Error',
    defaultMessage: 'An error occured during the saving process',
  },
  ContractWorkflowValidateSuccess: {
    id: 'Contract.Workflow.Validate.Success',
    defaultMessage: 'Your contribution has been validated successfully',
  },
  ContractWorkflowInvalidatedSuccess: {
    id: 'Contract.Workflow.Invalidate.Success',
    defaultMessage: 'Your contribution has been invalidated successfully',
  },
  ContractWorkflowDialogValidateTitle: {
    id: 'Contract.Workflow.Dialog.Validate.Title',
    defaultMessage: 'Validate your contribution',
  },
  ContractWorkflowDialogValidateParagraph: {
    id: 'Contract.Workflow.Dialog.Validate.Paragraph',
    defaultMessage:
      'You are about to validate your contribution to the current workflow step <b>{name}</b>.',
  },
  ContractWorkflowDialogValidateExplanation: {
    id: 'Contract.Workflow.Dialog.Validate.Explanation',
    defaultMessage:
      'If everyone has validated their contribution for the current step, the next workflow step will start.',
  },
  ContractWorkflowDialogInvalidateTitle: {
    id: 'Contract.Workflow.Dialog.Invalidate.Title',
    defaultMessage: 'Invalidate your contribution',
  },
  ContractWorkflowDialogInvalidateParagraph: {
    id: 'Contract.Workflow.Dialog.Invalidate.Paragraph',
    defaultMessage:
      'You are about to invalidate your contribution to the current workflow step <b>{name}</b>.',
  },
  ContractDialogsSignatureSendTitle: {
    id: 'Contract.Dialogs.Signature.Send.Title',
    defaultMessage: 'Send for signature',
  },
  ContractDialogsSignatureSendParagraph: {
    id: 'Contract.Dialogs.Signature.Send.Paragraph',
    defaultMessage:
      'You are about to send the contract <b>{name}</b> for signature.',
  },
  ContractSignatureSendButton: {
    id: 'Contract.Signature.Send.Button',
    defaultMessage: 'Send for signature',
  },
  ContractDialogsSignatureCancelTitle: {
    id: 'Contract.Dialogs.Signature.Cancel.Title',
    defaultMessage: 'Cancel signing process',
  },
  ContractDialogsSignatureCancelParagraph: {
    id: 'Contract.Dialogs.Signature.Cancel.Paragraph',
    defaultMessage:
      'You are about to cancel the signing process for the contract <b>{name}</b>.',
  },
  ContractSignatureCancelButton: {
    id: 'Contract.Signature.Cancel.Button',
    defaultMessage: 'Cancel signing process',
  },
  ContractSignatureSendSuccess: {
    id: 'Contract.Signature.Send.Success',
    defaultMessage: 'The contract was sent for signature.',
  },
  ContractSignatureCancelSuccess: {
    id: 'Contract.Signature.Cancel.Success',
    defaultMessage: 'The signing process was cancelled.',
  },
  ContractContentCallExpertSuccess: {
    id: 'Contract.Content.CallExpert.Success',
    defaultMessage: 'The expert has been called.',
  },
  ContractContentCallExpertError: {
    id: 'Contract.Content.CallExpert.Error',
    defaultMessage: 'The expert could not be called.',
  },
  ContractWorkflowButtonsComplete: {
    id: 'Contract.Workflow.Buttons.Complete',
    defaultMessage: 'Complete the workflow step',
  },
  ContractWorkflowCompleteSuccess: {
    id: 'Contract.Workflow.Complete.Success',
    defaultMessage: 'The workflow step was completed',
  },
  ContractWorkflowDialogCompleteTitle: {
    id: 'Contract.Workflow.Dialog.Complete.Title',
    defaultMessage: 'Complete the workflow step',
  },
  ContractWorkflowDialogCompleteParagraph: {
    id: 'Contract.Workflow.Dialog.Complete.Paragraph',
    defaultMessage:
      'You are about to complete the workflow step <b>{name}</b>.',
  },
  ContractWorkflowDialogCompleteExplanation: {
    id: 'Contract.Workflow.Dialog.Complete.Explanation',
    defaultMessage:
      'The next workflow step will begin immediatly after completing the current step.',
  },
  MultiSignatureModalDescriptionText: {
    id: 'MultiSignatureModal.Description.Text',
    defaultMessage:
      'Send all or a part of the validated documents to signature.',
  },
  MultiSignatureModalSectionsDocumentsTitle: {
    id: 'MultiSignatureModal.Sections.Documents.Title',
    defaultMessage: 'Documents',
  },
  MultiSignatureModalSectionsSignatureTitle: {
    id: 'MultiSignatureModal.Sections.Signature.Title',
    defaultMessage: 'Signatories',
  },
  MultiSignatureModalSectionSignatureButtonAddNewSignatureForm: {
    id: 'MultiSignatureModal.Section.Signature.Button.AddNewSignatureForm',
    defaultMessage: 'Add a new signatory',
  },
  MultiSignatureModalButtonsSubmit: {
    id: 'MultiSignatureModal.Buttons.Submit',
    defaultMessage: 'Continue',
  },
  MultiSignatureModalButtonsCancel: {
    id: 'MultiSignatureModal.Buttons.Cancel',
    defaultMessage: 'Cancel',
  },
  MultiSignatureModalTitle: {
    id: 'MultiSignatureModal.Title',
    defaultMessage: 'Signature',
  },
  MultiSignatureModalDescriptionHeader: {
    id: 'MultiSignatureModal.Description.Header',
    defaultMessage: 'Signature',
  },
  ContractDialogsDeleteTitle: {
    id: 'Contract.Dialogs.Delete.Title',
    defaultMessage: 'Delete a contract',
  },
  ContractDialogsDeleteMessage: {
    id: 'Contract.Dialogs.Delete.Message',
    defaultMessage: 'You are about to delete the contract <b>{name}</b>',
  },
  ContractWorkflowButtonsReject: {
    id: 'Contract.Workflow.Buttons.Reject',
    defaultMessage: 'Reject the document',
  },
  ContractWorkflowRejectSuccess: {
    id: 'Contract.Workflow.Reject.Success',
    defaultMessage: 'The document was successfully rejected',
  },
  ContractWorkflowDialogRejectTitle: {
    id: 'Contract.Workflow.Dialog.Reject.Title',
    defaultMessage: 'Reject the document',
  },
  ContractWorkflowDialogRejectExplanation: {
    id: 'Contract.Workflow.Dialog.Reject.Explanation',
    defaultMessage:
      'Rejecting the document will return it to the first step of the workflow for a new approval cycle. <b>This action is irreversible.</b>',
  },
  ContractWorkflowDialogRejectReasonLabel: {
    id: 'Contract.Workflow.Dialog.Reject.Reason.Label',
    defaultMessage: 'Reason for rejection',
  },
  ContractWorkflowDialogRejectReasonDescription: {
    id: 'Contract.Workflow.Dialog.Reject.Reason.Description',
    defaultMessage:
      'Add details of why you rejected the document so that the recipient can make relevant changes.',
  },
  ContractWorkflowDialogRejectReasonPlaceholder: {
    id: 'Contract.Workflow.Dialog.Reject.Reason.Placeholder',
    defaultMessage: 'Reason for rejection',
  },
  ContractWorkflowDialogRejectConfirmationQuestion: {
    id: 'Contract.Workflow.Dialog.Reject.Confirmation.Question',
    defaultMessage: 'Confirm rejection of the document?',
  },
  ContractWorkflowDialogRejectConfirmationPeople: {
    id: 'Contract.Workflow.Dialog.Reject.Confirmation.People',
    defaultMessage:
      'The following people will be notified of the reason for your rejection by email and the author of the document will be invited to start a new approval process taking your comments into account.',
  },
  ContractAnnexButtonNewLabel: {
    id: 'Contract.Annex.Button.New.Label',
    defaultMessage: 'From the platform',
  },
  ContractAnnexButtonImportLabel: {
    id: 'Contract.Annex.Button.Import.Label',
    defaultMessage: 'From my computer',
  },
  ContractAnnexDialogAddTitle: {
    id: 'Contract.Annex.Dialog.Add.Title',
    defaultMessage: 'New annex',
  },
  ContractAnnexListButtonAdd: {
    id: 'Contract.Annex.List.Button.Add',
    defaultMessage: 'Add an annex',
  },
  ContractAnnexListNoData: {
    id: 'Contract.Annex.List.NoData',
    defaultMessage: 'No annexes have been added',
  },
  ContractAnnexListHelperDescription: {
    id: 'Contract.Annew.List.Helper.Description',
    defaultMessage:
      'Below, you can rearrange the order of the appendices using the dots.',
  },
  ContractAnnexDialogEditTitle: {
    id: 'Contract.Annex.Dialog.Edit.Title',
    defaultMessage: 'Edit annex',
  },
  ContractAnnexToastAddTitle: {
    id: 'Contract.Annex.Toast.Add.Title',
    defaultMessage: 'The "{name}" annex has been added',
  },
  ContractAnnexToastDeleteTitle: {
    id: 'Contract.Annex.Toast.Delete.Title',
    defaultMessage: 'The annex has been deleted',
  },
  ContractAnnexToastEditTitle: {
    id: 'Contract.Annex.Toast.Edit.Title',
    defaultMessage: 'The annex has been updated',
  },
  ContractAnnexToastReorderTitle: {
    id: 'Contract.Annex.Toast.Reorder.Title',
    defaultMessage: 'The annexes have been reordered',
  },
  ContractAnnexDialogDeleteTitle: {
    id: 'Contract.Annex.Dialog.Delete.Title',
    defaultMessage: 'Delete annex',
  },
  ContractAnnexDialogDeleteWarning: {
    id: 'Contract.Annex.Dialog.Delete.Warning',
    defaultMessage: 'Warning',
  },
  ContractAnnexDialogDeleteExplanation: {
    id: 'Contract.Annex.Dialog.Delete.Explanation',
    defaultMessage: 'Do you really want to delete this annex ?',
  },
  ContractNotesDialogDeleteTitle: {
    id: 'Contract.Notes.Dialog.Delete.Title',
    defaultMessage: 'Delete the note',
  },
  ContractNotesDialogDeleteParagraph: {
    id: 'Contract.Notes.Dialog.Delete.Paragraph',
    defaultMessage: 'You are about to delete this note.',
  },
  ContractVersionAccessWarningText: {
    id: 'Contract.Version.Access.Warning.Text',
    defaultMessage:
      'Your access rights defined within the document workflow limit the possible actions on the versions of this document.',
  },
  ContractVersionDialogRestoreTitle: {
    id: 'Contract.Version.Dialog.Restore.Title',
    defaultMessage: 'Restore version',
  },
  ContractVersionDialogRestoreDescriptionLabel: {
    id: 'Contract.Version.Dialog.Restore.Description.Label',
    defaultMessage: 'Do you really want to restore this version ?',
  },
  ContractVersionRestoreSuccess: {
    id: 'Contract.Version.Restore.Success',
    defaultMessage: 'The version has been restored',
  },
  ContractVersionMenuDownloadLabel: {
    id: 'Contract.Version.Menu.Download.Label',
    defaultMessage: 'Download',
  },
  ContractVersionMenuCompareLabel: {
    id: 'Contract.Version.Menu.Compare.Label',
    defaultMessage: 'Compare',
  },
  ContractVersionMenuRestoreLabel: {
    id: 'Contract.Version.Menu.Restore.Label',
    defaultMessage: 'Restore',
  },
  ContractVersionDefaultLabel: {
    id: 'Contract.Version.Default.Label',
    defaultMessage: 'Current version',
  },
  ContractVersionInitialLabel: {
    id: 'Contract.Version.Initial.Label',
    defaultMessage: 'Initial version',
  },
  ContractVersionButtonAddLabel: {
    id: 'Contract.Version.Button.Add.Label',
    defaultMessage: 'Save a new version',
  },
  ContractVersionAddSuccess: {
    id: 'Contract.Version.Add.Success',
    defaultMessage: 'The new version has been recorded',
  },
  ContractVersionDialogAddTitle: {
    id: 'Contract.Version.Dialog.Add.Title',
    defaultMessage: 'Register this version',
  },
  ContractVersionDialogFormsNameLabel: {
    id: 'Contract.Version.Dialog.Forms.Name.Label',
    defaultMessage: 'Version name',
  },
  ContractExternalWrapperLabel: {
    id: 'Contract.External.Wrapper.Label',
    defaultMessage:
      "This workspace is <b>confidential</b>. No one other than those indicated above has access to your comments and revisions to the document.\n\r\n\rYou can invite other contributors to participate in reviewing the document by clicking at any time on the <b>“Add a contributor”</b>button.\n\r\n\rOnce your revisions and public comments are finalized, <b>don't forget to click on the “Validate my contribution”</b>to return the document to its original author.",
  },
  ContractPDFPreviewError: {
    id: 'Contract.PDF.Preview.Error',
    defaultMessage: 'An error occurred when trying to preview PDF the contract',
  },
});
