import React from 'react';
import { isActive } from '@tiptap/core';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';

import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';

import { useTiptapEditor } from '../../use-tiptap-editor';
import MenuListItem from '../MenuListItem';

const lineHeightOptions = [1, 1.15, 1.5, 2, 2.5, 3];

function ButtonLineHeight() {
  const editor = useTiptapEditor();
  return (
    <PopupState variant="popper" popupId="font-size-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={<FormattedMessage id="Editor.Toolbar.Command.Line.Height" />}
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <FormatLineSpacingIcon />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <MenuList
                  sx={{ p: 0, overflow: 'auto' }}
                  component={Paper}
                  elevation={8}
                >
                  {lineHeightOptions.map(size => (
                    <MenuListItem
                      key={size}
                      label={size}
                      onClick={() => {
                        editor.chain().focus().setLineHeight(size).run();
                        popupState.close();
                      }}
                      selector={state =>
                        Boolean(state) &&
                        isActive(state, null, { lineHeight: size })
                      }
                    />
                  ))}
                </MenuList>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonLineHeight;
