import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import FormikProvider from '@components/Formik/FormikProvider';
import FolderInfoCard from '../../FolderInfoCard/FolderInfoCard';
import FolderOptionsCard from '../../FolderOptionsCard/FolderInfoOptionsCard';
import DocumentLinksCard from '../../DocumentLinksCard/DocumentLinksCard';

const schema = Yup.object().shape({
  countries: Yup.string(),
  language: Yup.string(),
  project: Yup.string(),
  status: Yup.string(),
});

export default function FolderInfo({
  fetching,
  documentInformation,
  documentLinksfetch,
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parentId = searchParams.get('parentId');

  const onSave = () => {};

  const initialValues = useMemo(
    () => ({
      countries: '',
      language: '',
      project: '',
      status: documentInformation?.status,
    }),
    [documentInformation?.status]
  );

  const isFolder = documentInformation?.type === 'folder';
  const navigateToShareModal = () => {
    if (isFolder) {
      navigate(
        `modals/share-folder/${documentInformation?.id}?parentId=${
          parentId || ''
        }`
      );
    } else {
      navigate(
        `modals/share-document/${documentInformation?.id}?parentId=${
          parentId || ''
        }`
      );
    }
  };
  return (
    <FormikProvider
      schema={schema}
      initialValues={initialValues}
      onSubmit={onSave}
      validateOnBlur={false}
      enableReinitialize
    >
      <Box>
        <FolderInfoCard information={documentInformation} fetching={fetching} />
        {!isFolder && (
          <DocumentLinksCard
            documentId={documentInformation.id}
            parentId={parentId}
            documentLinksfetch={documentLinksfetch}
          />
        )}
        {!isFolder && (
          <FolderOptionsCard
            fetching={fetching}
            status={initialValues?.status}
          />
        )}

        <Stack alignContent="center" alignItems="center">
          <Button
            onClick={navigateToShareModal}
            variant="outlined"
            sx={{ color: 'primary.main', px: 4 }}
            startIcon={<ShareIcon color="primary" />}
          >
            <FormattedMessage
              id="DriveView.ShareMenu.Share.Button.Label"
              defaultMessage="Partager"
            />
          </Button>
        </Stack>
      </Box>
    </FormikProvider>
  );
}
