import { Typography } from '@mui/material';
import MuiList from '@mui/material/List';
import { Box, Stack } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { List, arrayMove } from 'react-movable';
import { useState } from 'react';
import AnnexListItem from './AnnexListItem';

type AnnexListProps = {
  annexes: any[];
  onDelete: (annexId: string) => void;
  onDownload: (annexId: string) => void;
  onUpdate: (annexId: string) => void;
  onMove: (annexesOrdered: any[]) => void;
};

function AnnexList({
  annexes,
  onDelete,
  onDownload,
  onUpdate,
  onMove,
}: AnnexListProps) {
  const [items, setItems] = useState(annexes);
  const handleListChange = (reorderedItems: any) => {
    const updatedItems = reorderedItems.map((item: any, index: number) => ({
      ...item,
      orderId: index + 1,
    }));
    onMove(updatedItems);
    setItems(updatedItems);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <List
        lockVertically
        values={items}
        onChange={({ oldIndex, newIndex }) => {
          const moveArray = arrayMove(items, oldIndex, newIndex);
          handleListChange(moveArray);
        }}
        renderList={({ children, props }) => (
          <MuiList component="div" disablePadding {...props}>
            {children}
          </MuiList>
        )}
        renderItem={({ value, props }) => (
          <AnnexListItem
            {...props}
            annex={value}
            onDownload={() => onDownload(value.id)}
            onDelete={() => onDelete(value.id)}
            onUpdate={() => onUpdate(value.id)}
          />
        )}
      />
      {!items ||
        (items.length === 0 && (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap="5px"
            sx={{ mt: 2 }}
          >
            <InsertDriveFileIcon />
            <Typography component="span" variant="caption">
              <FormattedMessage id="Contract.Annex.List.NoData" />
            </Typography>
          </Stack>
        ))}
    </Box>
  );
}

export default AnnexList;
