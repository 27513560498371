import React from 'react';
import Viewer from '@components/Viewer/Viewer';
import useFetch from '@hooks/useFetch';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FolderAlerts from './FolderAlerts';

function FolderContractPdf({ id, fetching, fileName }) {
  const detail = useFetch({
    /* NOTE: the added "for=contract-pdf" is a hack to prevent useFetch from cancelling
     * the request when quickly switching to the Contractualization tab.
     */
    url: `/nexity/dashboard/folders/${id}/contractualization?for=contract-pdf`,
  });

  return (
    <Box>
      {!fetching && <FolderAlerts detail={detail} />}
      {!fetching && (
        <Container sx={{ py: 2 }}>
          <Viewer
            fetchUrl={`/nexity/dashboard/folders/${id}/contractualization/preview`}
            fileName={fileName}
          />
        </Container>
      )}
    </Box>
  );
}

export default FolderContractPdf;
