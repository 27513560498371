import React from 'react';
import MenuItem from '@mui/material/MenuItem';

import { useTiptapState } from '../use-tiptap-state';

function MenuListItem({ selector, label, onClick }) {
  const active = useTiptapState(selector);
  return (
    <MenuItem component="div" selected={active} onClick={onClick}>
      {label}
    </MenuItem>
  );
}

export default MenuListItem;
