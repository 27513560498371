import Paragraph from '@tiptap/extension-paragraph';
import Heading from '@tiptap/extension-heading';
import Text from '@tiptap/extension-text';
import History from '@tiptap/extension-history';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import Code from '@tiptap/extension-code';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import HardBreak from '@tiptap/extension-hard-break';
import { BackgroundColor, ColorExtension, FontFamilyExtension, FontSize, LineHeight, ImageExtension, Margin, TableCell, ListItem } from '@smartclause-tiptap-extensions/schema-core/dist';
import '@editor/schema/extensions/editor.css';
import ScmHelper from '@editor/schema/extensions/scm-helper';
import Image from '@tiptap/extension-image';
import TableCustomCommands from '@editor/schema/extensions/table-custom-commands';
import Numbering from '@editor/schema/extensions/numbering';
import { GapCursor } from '@editor/schema/extensions/gapcursor';

export const tiptapPlugins = (options = {}) => [
  History.configure({
    depth: 10,
  }),
  Paragraph,
  Heading.configure({
    levels: [1, 2, 3, 4, 5],
  }),
  Text,
  Bold,
  Italic,
  Underline,
  Strike,
  Code,
  BulletList,
  OrderedList,
  ListItem,
  Link.configure({
    openOnClick: false,
  }),
  TextAlign.configure({
    types: ['paragraph', 'clausevariant', 'heading'],
    alignments: ['left', 'center', 'right', 'justify'],
    defaultAlignment: 'left',
  }),
  LineHeight.configure({
    types: ['paragraph', 'clausevariant', 'heading', 'listItem'],
  }),
  TextStyle,
  BackgroundColor,
  ColorExtension.configure({
    types: ['textStyle', 'listItem'],
  }),
  FontFamilyExtension.configure({
    types: ['textStyle', 'listItem'],
    additionalOptions: options?.fontFamily?.additionalOptions,
  }),
  FontSize.configure({
    types: ['textStyle', 'listItem'],
  }),
  Highlight.configure({
    multicolor: true,
  }),
  Table.extend({ allowGapCursor: true }).configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  TableCustomCommands,
  GapCursor,
  ImageExtension.configure({
    allowBase64: true,
    inline: false,
  }),
  HardBreak,
  ScmHelper,
  Margin.configure({
    types: [Paragraph.name, Table.name, Image.name, Heading.name],
  }),
  Numbering.configure({
    types: [OrderedList.name],
  }),
];

export const TiptapPlugins = tiptapPlugins();
