import React, { useMemo, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { FormattedMessage, useIntl } from 'react-intl';
import { label } from '@utils/contributor-helpers';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import { Route, useNavigate } from 'react-router-dom';
import { AddAccountModalWithPermissions } from '@views/Administration/UserAccounts/components/AddAccountModal';
import tenantPermissions from '@utils/tenant-permissions';
import useFetch from '@hooks/useFetch';
import {
  systemRolesEnum,
  roleTypeEnum,
  workflowContributorTypes,
} from '@views/constants';
import { useAppContext } from '@contexts/AppContext';
import messages from '@i18n/keys';
import WorkflowContributorEditor, {
  DeleteWorkflowContributorButton,
} from './WorkflowContributorEditor';
import { contributorUsers } from '../WorkflowContextProvider';
import WorkflowContributorAddModal from './Modal/WorkflowContributorAddModal';

const WorkflowContributorEditorMemoed = React.memo(WorkflowContributorEditor);

function WorkflowContributorList({
  name,
  primary,
  action,
  readOnly = false,
  showSignature = false,
  hideNewUser,
  defaultContributor = {
    contributor: null,
    access: '',
    condition: '',
    assignments: [],
  },
  variablesData,
  hideAccess = false,
  hideCondition = false,
  hideRemove = false,
  usersOnly,
  schema,
  readOnlyContributors,
  children,
  onlyOneContributorByStep = false,
  hideEmptyUser = false,
  allowGroupsToHaveUsers = null,
}) {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext();
  const appContext = useAppContext();
  const contributors = React.useMemo(() => values[name], [values, name]);
  const allContributorUsers =
    readOnlyContributors?.flatMap(c => contributorUsers(c)) ?? [];
  const [currentPushArray, setCurrentPushArray] = useState(null);
  const [newExternalUser, setExternalUser] = useState(null);
  const hideAddContributorButton =
    onlyOneContributorByStep &&
    values.mandatoryContributors.length + values.otherContributors.length > 0;

  const navigate = useNavigate();
  const navigateWorkflow = () => {
    navigate('.', { state: 'modals' });
  };

  const { data: rolesAndGroups } = useFetch({
    method: 'GET',
    url: `/identity/roles`,
  });
  const externalRole = useMemo(
    () =>
      rolesAndGroups?.find(
        roleOrGroup => roleOrGroup.name === systemRolesEnum.External
      ),
    [rolesAndGroups]
  );

  const { doFetch: doPost } = useFetch({
    method: 'POST',
    url: '/identity/users',
    onSuccess: response => {
      const contributor = defaultContributor;
      contributor.contributor = {
        type: workflowContributorTypes.user,
        typeLabel: formatMessage({
          id: messages.contributor.pickers.type[workflowContributorTypes.user]
            .label,
        }),
        entity: {
          email: response?.data?.email,
          id: response?.data?.id,
          firstName: response?.data?.firstName,
          lastName: response?.data?.lastName,
        },
      };
      contributor.access = newExternalUser.workflowAccess;
      currentPushArray(contributor);
      navigateWorkflow();
    },
  });

  const onSubmit = valuesSubmited => {
    if (valuesSubmited.selectedContributor && currentPushArray) {
      const contributor = defaultContributor;
      contributor.contributor = valuesSubmited.selectedContributor;
      contributor.access = valuesSubmited.workflowAccess;
      currentPushArray(contributor);
    }
    if (valuesSubmited.mode === 'add') {
      navigate(`contributor-modals/${name}/create`);
    } else {
      navigateWorkflow();
    }
  };

  const handleCreateNewAccount = valuesNewUser => {
    const roleIds = valuesNewUser.groupIds ?? [];
    roleIds.push(externalRole.id);
    doPost({
      body: { ...valuesNewUser, roleIds, userEmail: valuesNewUser.email },
    });
  };

  const handleCreateNewExternalAccount = valuesNewUser => {
    setExternalUser(valuesNewUser);
    handleCreateNewAccount(valuesNewUser);
  };

  return (
    <>
      <FieldArray name={name}>
        {({ remove, push, replace }) => (
          <List component="div" disablePadding>
            <ListItem component="div">
              <ListItemText primary={primary} />
              <ListItemSecondaryAction sx={{ right: 0 }}>
                {!readOnly && !hideAddContributorButton && (
                  <Button
                    variant="text"
                    color="primary"
                    fullWidth
                    startIcon={<AddCircleIcon />}
                    onClick={() => {
                      if (name !== 'notifyContributors') {
                        setCurrentPushArray(() => push);
                        navigate(`contributor-modals/${name}/search`, {
                          state: 'modals',
                        });
                      } else {
                        push(defaultContributor);
                      }
                    }}
                  >
                    {action}
                  </Button>
                )}
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <Box sx={{ m: 2, mb: 0 }}>
              <List component={Stack} rowGap={1} disablePadding>
                {allContributorUsers?.length > 0 &&
                  allContributorUsers.map((user, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ListItem component={Paper} variant="outlined" key={index}>
                      <Stack
                        direction="row"
                        width={1}
                        columnGap={4}
                        alignItems="center"
                      >
                        <Box flexGrow={1}>
                          <TextField
                            margin="dense"
                            value={label(user, formatMessage, {
                              showEmail:
                                appContext.contract.workflow.showEmail ?? false,
                              showExternalIds:
                                appContext.contract.workflow.showExternalIds ?? true,
                            })}
                            label={
                              <FormattedMessage id="Workflow.ContributorPicker.Label" />
                            }
                            fullWidth
                            disabled
                          />
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip
                          title={formatMessage({
                            id: 'Workflow.Contributor.Buttons.Remove.NextStepContributorWarningText',
                          })}
                        >
                          <Box>
                            <DeleteWorkflowContributorButton disabled />
                          </Box>
                        </Tooltip>
                      </Stack>
                    </ListItem>
                  ))}
                {contributors?.length > 0 &&
                  contributors.map((_, index) => (
                    <WorkflowContributorEditorMemoed
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      name={name}
                      index={index}
                      readOnly={readOnly}
                      showSignature={showSignature}
                      onRemove={() => remove(index)}
                      variablesData={variablesData}
                      hideNewUser={hideNewUser}
                      hideAccess={hideAccess}
                      hideCondition={hideCondition}
                      hideRemove={hideRemove}
                      usersOnly={usersOnly}
                      schema={schema}
                      hideEmptyUser={hideEmptyUser}
                      allowGroupsToHaveUsers={allowGroupsToHaveUsers}
                      rolesAndGroups={rolesAndGroups}
                      externalRole={externalRole}
                      navigateWorkflow={navigateWorkflow}
                      contributors={contributors}
                      replace={replace}
                    />
                  ))}
              </List>
              {children}
            </Box>
          </List>
        )}
      </FieldArray>
      <DialogOutletRoutes
        path={`contributor-modals/${name}/*`}
        onClose={navigateWorkflow}
      >
        <Route
          path="search"
          element={
            <WorkflowContributorAddModal
              showSignature={showSignature}
              hideNewUser={hideNewUser}
              onSubmit={onSubmit}
              schema={schema}
              contributorType={name}
            />
          }
        />
        <Route
          path="create"
          element={
            <AddAccountModalWithPermissions
              permissions={tenantPermissions.ContractsContributorsCreate}
              groups={rolesAndGroups?.filter(
                roleOrGroup =>
                  roleOrGroup.type === roleTypeEnum.Group &&
                  roleOrGroup.usersType !== 'Internal'
              )}
              onSubmit={handleCreateNewExternalAccount}
              hideEmptyList
              showWorkflowAccess
              showSignatureWorkflowAccess={showSignature}
              title={
                <FormattedMessage id="Workflow.Contributor.Modal.Search.Title" />
              }
            />
          }
        />
      </DialogOutletRoutes>
    </>
  );
}

export default WorkflowContributorList;
