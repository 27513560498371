import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';

import GridOnIcon from '@mui/icons-material/GridOn';

import { useTiptapEditor } from '../../use-tiptap-editor';

import TableGridSizeEditor from '../TableGridSizeEditor';

function ButtonTable() {
  const editor = useTiptapEditor();
  return (
    <PopupState variant="popper" popupId="table-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={
              <FormattedMessage id="Editor.Toolbar.Command.Table.Insert" />
            }
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <GridOnIcon />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <Paper sx={{ p: 0, pb: 1 }} elevation={8}>
                  <TableGridSizeEditor
                    onGridSelect={values => {
                      editor
                        .chain()
                        .focus()
                        .insertTable({
                          rows: values.rows,
                          cols: values.cols,
                          withHeaderRow: false,
                        })
                        .run();
                      popupState.close();
                    }}
                  />
                </Paper>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonTable;
