import { defineMessages } from 'react-intl';

export default defineMessages({
  TermSheetSchemaValueFieldsElementTitleLabel: {
    id: 'TermSheet.SchemaValue.Fields.ElementTitle.Label',
    defaultMessage: 'Element title',
  },
  TermSheetSchemaEditorDescription: {
    id: 'TermSheet.Schema.Editor.Description',
    defaultMessage:
      'Define the default elements that will be proposed in the contract form for this type of contract. It will always be possible to add specific elements for each document.',
  },
  TermSheetSchemaValueFieldsIconLabel: {
    id: 'TermSheet.SchemaValue.Fields.Icon.Label',
    defaultMessage: 'Icon',
  },
  TermSheetSchemaValueFieldsSourceLabel: {
    id: 'TermSheet.SchemaValue.Fields.Source.Label',
    defaultMessage: 'Data source',
  },
  TermSheetSchemaValueFieldsDataToBeCollectedLabel: {
    id: 'TermSheet.SchemaValue.Fields.DataToBeCollected.Label',
    defaultMessage: 'Data to be collected',
  },
  TermSheetSchemaValueFieldsDataToBeCollectedFreeLabel: {
    id: 'TermSheet.DataSource.Free.Label',
    defaultMessage: 'Free data',
  },
  TermSheetSchemaValueFieldsDataToBeCollectedContractLabel: {
    id: 'TermSheet.DataSource.Contract.Label',
    defaultMessage: 'Data from the contract (automatic recovery)',
  },
  TermSheetSchemaValueFieldsTypeLabel: {
    id: 'TermSheet.SchemaValue.Fields.Type.Label',
    defaultMessage: 'Data type to display',
  },
  TermSheetSchemaValueButtonDeleteLabel: {
    id: 'TermSheet.SchemaValue.Button.Delete.Label',
    defaultMessage: 'Delete this element',
  },
  TermSheetSchemaValueButtonAddDataToBeCollected: {
    id: 'TermSheet.SchemaValue.Button.AddDataToBeCollected',
    defaultMessage: 'Add data to be collected',
  },
  TermSheetSchemaButtonAddElementLabel: {
    id: 'TermSheet.Schema.Button.AddElement.Label',
    defaultMessage: 'Add an element',
  },
  TermSheetDataTypeTextLabel: {
    id: 'TermSheet.DataType.Text.Label',
    defaultMessage: 'Text',
  },
  TermSheetDataTypeNumberLabel: {
    id: 'TermSheet.DataType.Number.Label',
    defaultMessage: 'Number',
  },
  TermSheetDataTypeBooleanLabel: {
    id: 'TermSheet.DataType.Boolean.Label',
    defaultMessage: 'Boolean',
  },
  TermSheetDataTypeTimestampLabel: {
    id: 'TermSheet.DataType.Timestamp.Label',
    defaultMessage: 'Timestamp',
  },
  TermSheetDataTypeDateOnlyLabel: {
    id: 'TermSheet.DataType.DateOnly.Label',
    defaultMessage: 'DateOnly',
  },
  TermSheetDataTypeTimeOnlyLabel: {
    id: 'TermSheet.DataType.TimeOnly.Label',
    defaultMessage: 'TimeOnly',
  },
  TermSheetEntitiesElementsSystemSignatureDateLabel: {
    id: 'TermSheet.Entities.Elements.System.SignatureDate.Label',
    defaultMessage: 'Signature date',
  },
  TermSheetEntitiesElementsSystemValidationDateLabel: {
    id: 'TermSheet.Entities.Elements.System.ValidationDate.Label',
    defaultMessage: 'Validation date',
  },
});
