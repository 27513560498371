import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'react-movable';

import Stack from '@mui/material/Stack';
import MuiList from '@mui/material/List';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import WorkflowStepListItem from './WorkflowStepListItem';
import { useWorkflowContext } from '../WorkflowContextProvider';

function WorkflowStepList({
  schema,
  hideAddNewStep,
  allowNoContributors = false,
}) {
  const {
    first,
    active,
    signature,
    notMovable,
    movable,
    methods: { set, move, add },
  } = useWorkflowContext();
  return (
    <Stack gap={1}>
      <Stack>
        {first && (
          <MuiList component="div" disablePadding>
            <WorkflowStepListItem
              step={first}
              isFirst
              hideDragIndicator
              selected={active?.id === first.id}
              onClick={() => set(first)}
              schema={schema}
              allowNoContributors={allowNoContributors}
            />
          </MuiList>
        )}
        {notMovable && (
          <MuiList component="div" disablePadding>
            {notMovable.map(value => (
              <WorkflowStepListItem
                step={value}
                hideDragIndicator
                selected={active?.id === value.id}
                onClick={() => set(value)}
                schema={schema}
                allowNoContributors={allowNoContributors}
              />
            ))}
          </MuiList>
        )}
        {movable && (
          <List
            lockVertically
            values={movable}
            onChange={({ oldIndex, newIndex }) => {
              move(
                oldIndex + notMovable.length + 1,
                newIndex + notMovable.length + 1
              );
            }}
            renderList={({ children, props }) => (
              <MuiList component="div" disablePadding {...props}>
                {children}
              </MuiList>
            )}
            renderItem={({ value, props }) => (
              <WorkflowStepListItem
                {...props}
                step={value}
                selected={active?.id === value.id}
                onClick={() => set(value)}
                schema={schema}
                allowNoContributors={allowNoContributors}
              />
            )}
          />
        )}
        {signature && (
          <MuiList component="div" disablePadding>
            <WorkflowStepListItem
              step={signature}
              hideDragIndicator
              selected={active?.id === signature.id}
              onClick={() => set(signature)}
              schema={schema}
              allowNoContributors={allowNoContributors}
            />
          </MuiList>
        )}
      </Stack>
      {!hideAddNewStep && (
        <Button
          variant="text"
          color="primary"
          fullWidth
          startIcon={<AddCircleIcon />}
          onClick={() => add()}
        >
          <FormattedMessage id="Workflow.Step.Buttons.Add" />
        </Button>
      )}
    </Stack>
  );
}

export default WorkflowStepList;
