import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';

import messages from '@i18n/keys';

// 0: ToComplete, 1: ToValidate, 2: ToRectify, 3: Validated
function FormsToCompleteButton({ id, type, status }) {
  if (status === 0) {
    return (
      <Button
        to={`/folders/${id}/forms/${type}`}
        component={Link}
        variant="contained"
        color="secondary"
        sx={{ ml: 'auto' }}
      >
        <FormattedMessage id={messages.verbs.complete} />
      </Button>
    );
  }
  if (status === 1) {
    return (
      <Button
        to={`/folders/${id}/forms/${type}`}
        component={Link}
        variant="outlined"
        color="secondary"
        sx={{ ml: 'auto' }}
      >
        <FormattedMessage id={messages.verbs.consult} />
      </Button>
    );
  }
  if (status === 2) {
    return (
      <Button
        to={`/folders/${id}/forms/${type}`}
        component={Link}
        variant="outlined"
        color="error"
        sx={{ ml: 'auto' }}
      >
        <FormattedMessage id={messages.verbs.rectify} />
      </Button>
    );
  }
  return (
    <Button
      to={`/folders/${id}/forms/${type}`}
      component={Link}
      variant="outlined"
      color="secondary"
      sx={{ ml: 'auto' }}
    >
      <FormattedMessage id={messages.verbs.consult} />
    </Button>
  );
}

export default FormsToCompleteButton;
