import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Stack from '@mui/material/Stack';

import CheckIcon from '@mui/icons-material/Check';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AdjustIcon from '@mui/icons-material/Adjust';
import { useNexityTimelineSteps } from '@shells/nexity/nexity-constants';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import ContributorChip from '@components/Chips/ContributorChip';

function FolderTimelineItem({
  first,
  last,
  dotColor,
  icon,
  textColor,
  children,
}) {
  return (
    <TimelineItem>
      <TimelineSeparator>
        {!first && <TimelineConnector />}
        <TimelineDot color={dotColor}>{icon}</TimelineDot>
        {!last && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography component="span" sx={{ color: textColor }}>
          {children}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

function SuccessTimelineItem(props) {
  return (
    <FolderTimelineItem
      dotColor="success"
      icon={<CheckIcon sx={{ color: 'common.white' }} />}
      textColor="success.light"
      {...props}
    />
  );
}

function PendingTimelineItem(props) {
  return (
    <FolderTimelineItem
      dotColor="info"
      icon={<AutorenewIcon sx={{ color: 'common.white' }} />}
      textColor="info.light"
      {...props}
    />
  );
}

function OtherTimelineItem(props) {
  return (
    <FolderTimelineItem
      dotColor="grey"
      icon={<AdjustIcon sx={{ color: 'grey' }} />}
      textColor="grey.600"
      {...props}
    />
  );
}

function FolderTimeline({
  timelineStepIndex,
  isDealerOrManagerOrAdmin,
  isContractualizationGenerated,
  contributors,
  signers,
  canEditWorkflow,
}) {
  const nexityTimelineSteps = useNexityTimelineSteps();
  const isLastStep = timelineStepIndex === nexityTimelineSteps.length - 1;
  const pendingStepIndex = isLastStep
    ? timelineStepIndex + 1
    : timelineStepIndex;

  return (
    <>
      <Box>
        <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
          Mon dossier en {nexityTimelineSteps.length} étapes
        </Typography>
      </Box>
      <Paper>
        {isDealerOrManagerOrAdmin &&
          isContractualizationGenerated &&
          canEditWorkflow && (
            <Box textAlign="center">
              <Button
                variant="outlined"
                sx={{ mt: 2 }}
                component={Link}
                to="../workflow"
              >
                Editer
              </Button>
            </Box>
          )}
        <Timeline>
          {nexityTimelineSteps.map((stepText, index) => {
            let Component;
            if (index < pendingStepIndex) {
              Component = SuccessTimelineItem;
            } else if (index === pendingStepIndex) {
              Component = PendingTimelineItem;
            } else {
              Component = OtherTimelineItem;
            }
            return (
              <Component
                key={stepText}
                first={index === 0}
                last={index === nexityTimelineSteps.length - 1}
              >
                {stepText}
                {Boolean(index === 3 && contributors?.length) && (
                  <Stack gap={1}>
                    {contributors?.map(contributor => (
                      <Box>
                        <ContributorChip
                          key={contributor.id}
                          sx={{
                            bgcolor: 'grey.200',
                            textOverflow: 'ellipsis',
                            maxWidth: 160,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                          type={1}
                          entityId={contributor.id}
                          entity={contributor}
                          disabled={false}
                        />
                      </Box>
                    ))}
                  </Stack>
                )}
                {Boolean(index === 4 && signers?.length) && (
                  <Stack gap={1}>
                    {signers.map(signer => (
                      <Box>
                        <ContributorChip
                          key={signer.id}
                          sx={{
                            bgcolor: 'grey.200',
                            textOverflow: 'ellipsis',
                            maxWidth: 160,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                          type={1}
                          entityId={signer.id}
                          entity={signer}
                          disabled={false}
                        />
                      </Box>
                    ))}
                  </Stack>
                )}
              </Component>
            );
          })}
        </Timeline>
      </Paper>
    </>
  );
}

export default FolderTimeline;
