import { useLayoutEffect, useRef, useState } from 'react';

export function useDebouncedState<T>(current: T, ms: number) {
  const ref = useRef<number | null>(null);

  const [debounced, setDebounced] = useState(current);

  useLayoutEffect(() => {
    const clear = () => {
      if (ref.current !== null) {
        window.clearTimeout(ref.current);
        ref.current = null;
      }
    };

    clear();
    if (ms) {
      ref.current = window.setTimeout(() => {
        setDebounced(current);
      }, ms);
    } else {
      setDebounced(current);
    }

    return clear;
  }, [current, ms]);

  return [ms ? debounced : current, setDebounced] as const;
}

export default function useDebounced<T>(current: T, ms: number) {
  const [debounced] = useDebouncedState(current, ms);
  return debounced;
}
