import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import withPermissions from '@components/withTenantPermissions';
import tenantPermissions from '@utils/tenant-permissions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import useFetch from '@hooks/useFetch';
import Fetching from '@components/Fetching/Fetching';
import { useSnackbar } from 'notistack';

export default function TermSheetTemplateCard({
  contractId,
  parentId,
  termSheetTemplate,
  refetchContract,
  refetchtermSheet,
}: {
  contractId: string;
  parentId: string;
  termSheetTemplate: { id: string; name: string };
  refetchContract: () => void;
  refetchtermSheet: () => void;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const backUrl = searchParams.get('backUrl');
  const IconButtonWithPermission = withPermissions(IconButton);
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const { doFetch: doDelete, fetching: fetchingDelete } = useFetch({
    url: `contracts/${contractId}/termsheet`,
    method: 'PUT',
    body: {},
    onSuccess: () => {
      refetchtermSheet();
      refetchContract();
    },
    onError: () => {
      enqueueSnackbar(formatMessage({ id: 'Errors.ErrorOccured' }), {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  return (
    <Fetching fetching={fetchingDelete}>
      <Box
        sx={{
          mt: 2,
          p: 2,
          bgcolor: 'white',
          width: '100%',
          height: 'max-content',
          borderRadius: '12px',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            pb: 2,
            textTransform: 'uppercase',
            fontWeight: '700',
            fontSize: '14px',
          }}
        >
          <FormattedMessage id="Driveview.TermSheet.Template.AssociatedTemplate" />
        </Typography>
        <Stack direction="column">
          <Box
            sx={{
              px: 2,
              py: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: '12px',
              boxShadow: 2,
            }}
          >
            {termSheetTemplate && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'primary.main',
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                >
                  {termSheetTemplate.name}
                </Typography>
                <Stack direction="row" gap={1} ml="auto">
                  <>
                    <IconButtonWithPermission
                      permissions={[tenantPermissions.TermSheetValuesUpdate]}
                      sx={{ fontSize: '1.1rem' }}
                      onClick={() =>
                        navigate(
                          `modals/term-sheet-template/${contractId}/edit?parentId=${
                            parentId || ''
                          }&backUrl=${encodeURIComponent(backUrl ?? '')}`,
                          { state: 'modals' }
                        )
                      }
                      color="primary"
                      edge="end"
                    >
                      <EditIcon sx={{ fontSize: '1.1rem' }} />
                    </IconButtonWithPermission>
                    <IconButtonWithPermission
                      permissions={[tenantPermissions.TermSheetValuesUpdate]}
                      sx={{ fontSize: '1.1rem' }}
                      color="error"
                      onClick={() => {
                        doDelete({
                          body: {},
                        });
                      }}
                      edge="end"
                    >
                      <DeleteOutlineIcon sx={{ fontSize: '1.1rem' }} />
                    </IconButtonWithPermission>
                  </>
                </Stack>
              </>
            )}
            {!termSheetTemplate && (
              <>
                <Typography variant="body2">
                  <FormattedMessage id="Driveview.TermSheet.Template.NoAssociatedTemplate" />
                </Typography>
                <Stack direction="row" gap={1} ml="auto">
                  <IconButtonWithPermission
                    permissions={[tenantPermissions.TermSheetValuesUpdate]}
                    sx={{ fontSize: '1.1rem' }}
                    onClick={() =>
                      navigate(
                        `modals/term-sheet-template/${contractId}/edit?parentId=${
                          parentId || ''
                        }&backUrl=${encodeURIComponent(backUrl ?? '')}`,
                        { state: 'modals' }
                      )
                    }
                    color="primary"
                    edge="end"
                  >
                    <EditIcon sx={{ fontSize: '1.1rem' }} />
                  </IconButtonWithPermission>
                </Stack>
              </>
            )}
          </Box>
        </Stack>
      </Box>
    </Fetching>
  );
}
