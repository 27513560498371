import React, { Fragment, useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Fetching from '@components/Fetching/Fetching';
import useFetch from '@hooks/useFetch';

import HtmlEditor from '@views/Tiptap/HtmlEditor';
import useCustomizedSnackbar from '@hooks/useCustomizedSnackbar';
import { ContractNoteResponse, ContractNotesListResponse } from '@api/types';
import FormattedDateTime from '@components/FormattedDates/FormattedDateTime';
import { getUserFullName } from '@utils/username-helpers';
import { useRecoilValue } from 'recoil';
import { tenantUserIdentifierSelector } from '@recoil/tenant-token';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import { Link, Route, useNavigate, useParams } from 'react-router-dom';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import NotFoundView from '@views/NotFoundView/NotFoundView';
import useTenantPermissions from '@hooks/useTenantPermissions';
import tenantPermissions from '@utils/tenant-permissions';

function DeleteNoteDialog({
  contractId,
  onSuccess,
}: {
  contractId: string;
  onSuccess(): void;
}) {
  const { noteId } = useParams();
  const { doFetch, error, fetching } = useFetch({
    url: `contracts/${contractId}/notes/${noteId}`,
    method: 'DELETE',
    onSuccess,
  });

  return (
    <DialogWithClose
      titleLabel={<FormattedMessage id="Contract.Notes.Dialog.Delete.Title" />}
      dialogActions={
        <Button
          variant="contained"
          onClick={() => doFetch()}
          disabled={fetching}
        >
          <FormattedMessage id="Verbs.Delete" />
        </Button>
      }
    >
      <ErrorMessage error={error} />
      <Typography>
        <FormattedMessage id="Contract.Notes.Dialog.Delete.Paragraph" />
      </Typography>
    </DialogWithClose>
  );
}

type ContractNotesViewProps = {
  contractId: string;
};

const titlePerNotificationType: Record<number, string> = {
  201: 'Rejet de document',
} as const;

function getNoteTitle(note: ContractNoteResponse) {
  if (note.notificationType) {
    return note.notificationType in titlePerNotificationType
      ? titlePerNotificationType[note.notificationType]
      : 'Note';
  }

  return note.title || 'Note';
}

function ContractNotesView({ contractId }: ContractNotesViewProps) {
  const { enqueueSnackbar } = useCustomizedSnackbar();
  const { formatMessage } = useIntl();
  const userId = useRecoilValue(tenantUserIdentifierSelector);
  const editorRef = useRef<{ commands: { clearContent(): void } }>(null);

  const navigate = useNavigate();
  const onClose = useCallback(() => {
    navigate({ pathname: '.' });
  }, [navigate]);

  const listFetch = useFetch<ContractNotesListResponse>({
    url: `/contracts/${contractId}/notes`,
  });

  const createFetch = useFetch({
    url: `/contracts/${contractId}/notes`,
    method: 'POST',
    onSuccess: () => {
      enqueueSnackbar(formatMessage({ id: 'Editor.Notebook.Save.Success' }), {
        variant: 'success',
      });
      listFetch.doFetch();
      editorRef.current?.commands.clearContent();
    },
    onError: () => {
      enqueueSnackbar(formatMessage({ id: 'Editor.Save.Failure' }), {
        variant: 'error',
      });
    },
  });

  const { doFetch: doCreate } = createFetch;
  const onSaveCallback = React.useCallback(
    newContent => {
      doCreate({
        body: {
          contentType: 'html',
          content: newContent,
        },
      });
    },
    [doCreate]
  );

  const canCreateNote = useTenantPermissions({
    permissions: [tenantPermissions.NotesCreate],
  });

  const canDeleteNote = useTenantPermissions({
    permissions: [tenantPermissions.NotesDelete],
  });

  const canDeleteOthersNotes = useTenantPermissions({
    permissions: [tenantPermissions.NotesOthersDelete],
  });

  const rgpdAlert = (
    <Alert severity="info" sx={{ p: 2 }}>
      <AlertTitle>Mention RGPD</AlertTitle>
      Attention : cette zone de commentaire vous permet de vous exprimer
      librement et de rendre compte de votre activité. Afin de respecter la vie
      privée des personnes que vous pourriez citer, vous devez impérativement
      rédiger des commentaires objectifs et jamais excessifs ou insultants, à
      l’exclusion de toute donnée considérée comme sensible (origine raciale ou
      ethnique, opinions politiques, philosophiques ou religieuses, appartenance
      syndicale, données relatives à la santé ou à la vie sexuelle, infractions,
      condamnations, mesure de sûreté).
    </Alert>
  );

  const dialogProps = {
    contractId,
    onSuccess: () => {
      onClose();
      listFetch.doFetch();
    },
  };

  return (
    <>
      <Stack direction="column" sx={{ width: '100%', mb: 5 }}>
        {canCreateNote ? (
          <HtmlEditor
            content={undefined}
            published={undefined}
            onSave={onSaveCallback}
            saveBtnProps={{ disabled: createFetch.fetching }}
            prelude={rgpdAlert}
            editorRef={editorRef}
          />
        ) : (
          rgpdAlert
        )}
        <Fetching
          fetching={listFetch.fetching || createFetch.fetching}
          sx={{ pt: 2 }}
        >
          <List>
            {listFetch.data?.notes?.map(note => {
              const isAuthor = note.creatorUser.id === userId;
              const canDelete =
                canDeleteNote &&
                !note.isSystem &&
                (isAuthor || canDeleteOthersNotes);

              return (
                <Fragment key={note.id}>
                  <ListItem
                    secondaryAction={
                      canDelete && (
                        <IconButton
                          edge="end"
                          component={Link}
                          to={`notes-modals/delete/${note.id}`}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )
                    }
                  >
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            component="span"
                            fontWeight="bold"
                            color="primary"
                          >
                            {getNoteTitle(note)}
                          </Typography>
                          {' - '}
                          <Typography component="span" fontWeight="bold">
                            {getUserFullName(note.creatorUser)}
                          </Typography>
                          {' - '}
                          <Typography component="span">
                            <FormattedDateTime value={note.creationTime} />
                          </Typography>
                        </>
                      }
                      secondary={
                        (note.contentType === 'html' ? (
                          <span
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              // __html: DOMPurify.sanitize(note.content),
                              __html: note.content,
                            }}
                          />
                        ) : (
                          <span>{note.content}</span>
                        )) ?? ''
                      }
                    />
                  </ListItem>
                  <Divider />
                </Fragment>
              );
            })}
          </List>
        </Fetching>
      </Stack>
      <DialogOutletRoutes path="notes-modals/*" onClose={onClose}>
        <Route
          path="delete/:noteId"
          element={<DeleteNoteDialog {...dialogProps} />}
        />
        <Route path="*" element={<NotFoundView />} />
      </DialogOutletRoutes>
    </>
  );
}

export default ContractNotesView;
