import React, { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import { useOutletContext } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';

import Fetching from '@components/Fetching/Fetching';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import { Box } from '@mui/material';
import DialogTitleWithClose from './DialogTitleWithClose';
import DialogActionsWithClose from './DialogActionsWithClose';
import { DialogOutletContext } from './DialogOutlet';

type DialogWithCloseProps = PropsWithChildren<{
  titleLabel?: ReactNode | null | undefined;
  dialogActions?: ReactNode | null | undefined;
  fetching?: boolean | null | undefined;
  closeColor?: ComponentProps<typeof DialogActionsWithClose>['color'];
  disabled?: boolean | null | undefined;
  closeLabel?: ReactNode | null | undefined;
  titleActions?: ReactNode;
}>;

function DialogWithClose({
  children,
  titleLabel,
  dialogActions,
  fetching,
  closeColor,
  disabled,
  closeLabel,
  titleActions,
}: DialogWithCloseProps) {
  const { close } = useOutletContext<DialogOutletContext>();
  return (
    <>
      <DialogTitleWithClose onClose={close}>
        {titleLabel}
        <Box>{titleActions}</Box>
      </DialogTitleWithClose>
      <DialogContent>
        <Fetching fetching={fetching || false} sx={{ pb: 4 }}>
          {children}
        </Fetching>
      </DialogContent>
      {!fetching && (
        <DialogActionsWithClose
          color={closeColor}
          closeLabel={closeLabel}
          onClose={close}
          disabled={disabled ?? false}
        >
          <ErrorBoundary>{dialogActions}</ErrorBoundary>
        </DialogActionsWithClose>
      )}
    </>
  );
}

export default DialogWithClose;
