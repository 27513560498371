import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormattedMessage, useIntl } from 'react-intl';

import Fetching from '@components/Fetching/Fetching';
import { Link, useLocation } from 'react-router-dom';
import useFetch from '@hooks/useFetch';
import { Stack } from '@mui/system';
import { toast } from 'react-toastify';
import {
  DocumentLinkTypes,
  DocumentLinkTypesOptions,
} from './DocumentLinkTypes';

export function useDocumentLinksFetch({ documentId }) {
  return useFetch({
    url: `documents/${documentId}/links`,
    method: 'GET',
    lazy: !documentId,
  });
}

function DocumentLinksTemplate({ linkType, documentlinks }) {
  const intl = useIntl();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}${location.hash}`;

  const { doFetch: doGetPdfLink } = useFetch({
    method: 'GET',
    lazy: true,
    onSuccess: response => {
      window.open(response?.data?.uri, '_blank', 'noreferrer');
    },
    onError: response => {
      const dataError = 'data' in response ? response.data : null;
      const errors = dataError?.Errors ?? dataError?.errors;
      if (errors) {
        toast.error(intl.formatMessage({ id: 'Errors.ErrorOccured' }), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    },
  });

  return (
    <Stack>
      {Boolean(documentlinks && documentlinks.length > 0) && (
        <Box sx={{ mt: 1 }}>
          <Typography variant="subtitle2">
            <FormattedMessage id={DocumentLinkTypesOptions[linkType].title} />:
          </Typography>
          {documentlinks.map(documentlink => (
            <Box sx={{ mt: 1 }}>
              {documentlink.targetDocumentId && (
                <Button
                  variant="text"
                  sx={{
                    width: '100%',
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    border: '1px solid gray',
                    borderRadius: '5px',
                  }}
                  onClick={() => {
                    window.open(
                      `/contract/${
                        documentlink.targetDocumentId
                      }?backUrl=${encodeURIComponent(currentUrl)}`,
                      '_blank'
                    );
                  }}
                >
                  <Typography variant="subtitle2">
                    {documentlink.targetDocumentName}
                  </Typography>
                </Button>
              )}
              {documentlink.annexId && (
                <Button
                  variant="text"
                  sx={{
                    width: '100%',
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    border: '1px solid gray',
                    borderRadius: '5px',
                  }}
                  onClick={() => {
                    doGetPdfLink({
                      url: `documents/${documentlink.sourceDocumentId}/annexes/${documentlink.annexId}/view`,
                    });
                  }}
                >
                  <Typography variant="subtitle2">
                    {documentlink.annexName}
                  </Typography>
                </Button>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Stack>
  );
}

export default function DocumentLinksCard({
  documentId,
  parentId,
  documentLinksfetch,
}) {
  const { data, fetching } = documentLinksfetch;

  return (
    <Box>
      <Fetching fetching={fetching}>
        <Box sx={{ bgcolor: '#FFFFFF', my: 2, borderRadius: '6px', p: 1 }}>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                <FormattedMessage id="DriveView.DocumentLinks.Label" />
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Button
                variant="text"
                component={Link}
                to={`./modals/documentlinks?documentId=${documentId}&jsonLinks=${encodeURIComponent(
                  JSON.stringify(data?.documentLinks)
                )}&parentId=${parentId ?? ''}`}
                sx={{ fontSize: '11px', textTransform: 'none' }}
              >
                {Boolean(
                  data?.documentLinks && data.documentLinks.length === 0
                ) && (
                  <FormattedMessage id="DriveView.DocumentLinks.Button.Add" />
                )}
                {Boolean(
                  data?.documentLinks && data.documentLinks.length >= 1
                ) && (
                  <FormattedMessage id="DriveView.DocumentLinks.Button.Update" />
                )}
              </Button>
            </Grid>
          </Grid>
          <Box>
            <DocumentLinksTemplate
              linkType={DocumentLinkTypes.IsAmendmentOf}
              documentlinks={data?.documentLinks.filter(
                dl => dl.type === DocumentLinkTypes.IsAmendmentOf
              )}
            />
            <DocumentLinksTemplate
              linkType={DocumentLinkTypes.HasForMasterAgreement}
              documentlinks={data?.documentLinks.filter(
                dl => dl.type === DocumentLinkTypes.HasForMasterAgreement
              )}
            />
            <DocumentLinksTemplate
              linkType={DocumentLinkTypes.IsAnnexOf}
              documentlinks={data?.documentLinks.filter(
                dl => dl.type === DocumentLinkTypes.IsAnnexOf
              )}
            />
            <DocumentLinksTemplate
              linkType={DocumentLinkTypes.HasForAmendment}
              documentlinks={data?.documentLinks.filter(
                dl => dl.type === DocumentLinkTypes.HasForAmendment
              )}
            />
            <DocumentLinksTemplate
              linkType={DocumentLinkTypes.IsMasterAgreementOf}
              documentlinks={data?.documentLinks.filter(
                dl => dl.type === DocumentLinkTypes.IsMasterAgreementOf
              )}
            />
            <DocumentLinksTemplate
              linkType={DocumentLinkTypes.HasForAnnex}
              documentlinks={data?.documentLinks.filter(
                dl => dl.type === DocumentLinkTypes.HasForAnnex
              )}
            />
            <DocumentLinksTemplate
              linkType={DocumentLinkTypes.IsLinkedTo}
              documentlinks={data?.documentLinks.filter(
                dl => dl.type === DocumentLinkTypes.IsLinkedTo
              )}
            />
          </Box>
        </Box>
      </Fetching>
    </Box>
  );
}
