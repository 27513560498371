/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import {
  useNavigate,
  Route,
  useOutletContext,
  Link,
  useParams,
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';

import PersonIcon from '@mui/icons-material/Person';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import useFetch from '@hooks/useFetch';
import useTenantRoles from '@hooks/useTenantRoles';

import messages from '@i18n/keys';

import UploadDialog from '@shells/nexity/views/Folders/components/UploadDialog';
import DeleteDialog from '@components/Documents/DeleteDialog';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';

import DialogTitleWithClose from '@components/Dialog/DialogTitleWithClose';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import AddButton from '@components/Buttons/AddButton';
import DialogActionsWithClose from '@components/Dialog/DialogActionsWithClose';
import FormikTextField from '@components/Formik/FormikTextField';
import FormikProvider from '@components/Formik/FormikProvider';
import {
  nexityFolderStatuses,
  nexityRoles,
  nexityUploadAccept,
} from '@shells/nexity/nexity-constants';

import DialogViewer from '@components/Dialog/DialogViewer';
import useNexityTheme from '@shells/nexity/hooks/useNexityTheme';
import RequestVoucherDialog from './components/RequestVoucherDialog';
import FormsToComplete from './FormsToComplete';
import ContactCard from './ContactCard';
import DocumentUploadRowWithConfidentiality from './DocumentUploadRowWithConfidentiality';
import UpdateDealerDialog from './FolderInformations/UpdateDealerDialog';
import UpdateSignerDialog from './FolderInformations/UpdateSignerDialog';
import UpdateEmailNotificationDialog from './FolderInformations/UpdateEmailNotificationDialog';
import UpdateBuyersNotificationDialog from './FolderInformations/UpdateBuyersNotificationDialog';

function UploadSupportingDialog({ id, onSuccess, replacing }) {
  const { supportingFolderId } = useParams();
  const uploadFetch = useFetch({
    url: `/nexity/dashboard/folders/${id}/supporting/${supportingFolderId}/document`,
    method: 'POST',
    noContentType: true,
    onSuccess,
  });

  const onSubmitCallback = React.useCallback(
    values => {
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        if (key.includes('file')) {
          formData.append('Files', values[key]);
        }
      });

      uploadFetch.doFetch({ body: formData });
    },
    [uploadFetch]
  );

  return (
    <UploadDialog
      fetch={uploadFetch}
      onSubmit={onSubmitCallback}
      accept={nexityUploadAccept}
      replacing={replacing}
    />
  );
}

function SendDialog({ folderId, refreshFolder }) {
  const { close } = useOutletContext();

  const put = useFetch({
    url: `nexity/dashboard/folders/${folderId}/send`,
    method: 'PUT',
    onSuccess: () => {
      close();
      refreshFolder();
    },
  });

  const onSubmit = () => {
    put.doFetch();
  };

  return (
    <>
      <DialogTitleWithClose onClose={close}>
        Envoyer vos informations
      </DialogTitleWithClose>
      <DialogContent>
        <ErrorMessage error={put.error} />
        <Typography>
          Vous vous apprêtez à envoyer vos informations. Vous pourrez toujours
          modifier vos informations en attendant la validation du vendeur.
        </Typography>
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button variant="contained" disabled={put.fetching} onClick={onSubmit}>
          Envoyer
        </Button>
      </DialogActionsWithClose>
    </>
  );
}

const invalidateSchema = yup.object({
  reason: yup.string().nullable().required('Generic.Fields.Required'),
});

const invalidateInitialValues = { reason: '' };

function InvalidateDialog({ folderId, refreshFolder }) {
  const { close } = useOutletContext();
  const { name } = useNexityTheme();

  const put = useFetch({
    url: `nexity/dashboard/folders/${folderId}/invalidate`,
    method: 'PUT',
    onSuccess: () => {
      close();
      refreshFolder();
    },
  });

  const onSubmit = values => {
    put.doFetch({ body: { ...values, themeName: name } });
  };

  return (
    <FormikProvider
      validationSchema={invalidateSchema}
      initialValues={invalidateInitialValues}
      onSubmit={onSubmit}
    >
      <DialogTitleWithClose onClose={close}>
        Invalider les informations client
      </DialogTitleWithClose>
      <DialogContent>
        <ErrorMessage error={put.error} />
        <Typography>
          Vous vous apprêtez à invalider les informations client. Le client
          devra compléter/corriger ses informations avant de vous les renvoyer.
        </Typography>
        <FormikTextField
          name="reason"
          label="Votre message à destination du client"
          multiline
          rows={5}
        />
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button variant="contained" type="submit" disabled={put.fetching}>
          Invalider
        </Button>
      </DialogActionsWithClose>
    </FormikProvider>
  );
}

function ValidateDialog({ folderId, refreshFolder }) {
  const { close } = useOutletContext();
  const { name } = useNexityTheme();

  const put = useFetch({
    url: `nexity/dashboard/folders/${folderId}/validate`,
    method: 'PUT',
    onSuccess: () => {
      close();
      refreshFolder();
    },
  });

  const onSubmit = () => {
    put.doFetch({ body: { themeName: name } });
  };

  return (
    <>
      <DialogTitleWithClose onClose={close}>
        Valider les informations client
      </DialogTitleWithClose>
      <DialogContent>
        <ErrorMessage error={put.error} sx={{ mb: 2 }} />
        <Typography>
          Vous vous apprêtez à valider les informations client.
        </Typography>
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button variant="contained" disabled={put.fetching} onClick={onSubmit}>
          Valider
        </Button>
      </DialogActionsWithClose>
    </>
  );
}

function RemoveDialog({ id, refreshPage }) {
  const { close } = useOutletContext();
  const { documentFolderId } = useParams();
  const navigate = useNavigate();
  const {
    error,
    fetching,
    doFetch: doDelete,
  } = useFetch({
    url: `nexity/dashboard/folders/${id}/supporting/${documentFolderId}`,
    method: 'DELETE',
    onSuccess: () => {
      navigate(`/folders/${id}`);
      refreshPage();
    },
  });
  return (
    <>
      <DialogTitleWithClose onClose={close}>
        Enlever une pièce justificative
      </DialogTitleWithClose>
      <DialogContent>
        <Box>Etes vous sur de vouloir enlever la pièce justificative ?</Box>
        <ErrorMessage error={error} />
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button variant="contained" onClick={doDelete} disabled={fetching}>
          Enlever
        </Button>
      </DialogActionsWithClose>
    </>
  );
}

function FolderClientInfo({
  id,
  data,
  doFetch,
  preventModifications,
  fetching,
}) {
  const navigate = useNavigate();

  const documents = data?.supportingDocuments;
  const civilForms = data?.forms?.civil?.forms;
  const civilStates =
    (Array.isArray(civilForms) ? civilForms : civilForms?.civilStates) ?? [];

  const { doFetch: requestVoucher, fetching: requestingVoucher } = useFetch({
    method: 'POST',
    url: `nexity/dashboard/folders/${id}/supporting`,
    onSuccess: () => {
      navigate(`/folders/${id}`);
      doFetch();
    },
  });

  const onRequestVoucherSubmit = useCallback(
    values => {
      const allowDuplicate = values.SelectedElt === 'Autre Justificatif';
      const folderName = `[${values.UserName}] - ${values.SelectedElt}`;
      requestVoucher({
        body: {
          Name: folderName,
          AllowDuplicate: allowDuplicate,
        },
      });
    },
    [requestVoucher]
  );

  const buyersType = data?.forms?.civil?.forms?.buyersType;
  const clientInfoInvalidationReason =
    data?.space?.clientInfoInvalidationReason;

  const isBuyer = Boolean(data?.isBuyer);
  const isDealer = Boolean(data?.isDealer);
  const isManager = Boolean(data?.isManager);
  const isAdmin = useTenantRoles({ roles: [nexityRoles.administrators] });

  const isCreatedOrPending =
    data?.space?.status === nexityFolderStatuses.created ||
    data?.space?.status === nexityFolderStatuses.pendingValidation;
  const showClientInfoSentAlert = Boolean(
    data?.space?.clientInfoSent && isCreatedOrPending
  );
  const showClientInfoInvalidatedAlert = Boolean(
    data?.space?.clientInfoInvalidated &&
      clientInfoInvalidationReason &&
      isCreatedOrPending
  );

  return (
    <>
      {!fetching && (
        <Box
          sx={{
            p: 2,
          }}
        >
          {isBuyer && showClientInfoSentAlert && (
            <Alert severity="info" sx={{ mb: 2 }}>
              <Typography>
                Vous avez envoyé vos informations au vendeur.
              </Typography>
              <Typography>
                Vous pouvez encore modifier vos informations en attendant sa
                validation en cas d&apos;erreur.
              </Typography>
            </Alert>
          )}
          {showClientInfoInvalidatedAlert && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <Typography>Le vendeur a invalidé les informations :</Typography>
              <Typography>{clientInfoInvalidationReason}</Typography>
            </Alert>
          )}
          <Typography
            component={Stack}
            direction="row"
            gap={1}
            alignItems="center"
            sx={{
              bgcolor: 'secondary.main',
              p: 1,
              color: 'common.white',
            }}
          >
            <PersonIcon
              sx={{
                color: 'common.white',
              }}
            />
            <FormattedMessage
              id={messages.nexity.folders.acquirercontactdetails}
            />
          </Typography>
          <Box
            sx={{
              p: 2,
              display: 'grid',
              gridAutoColumns: '400px',
              gridAutoFlow: 'column',
              justifyContent: 'center',
              gap: 4,
            }}
          >
            {civilStates.map((f, i) => (
              <ContactCard key={i} {...f} />
            ))}
          </Box>
          <FormsToComplete id={id} forms={data?.forms} />
          <Typography
            component={Stack}
            direction="row"
            gap={1}
            sx={{
              bgcolor: 'secondary.main',
              p: 1,
              color: 'common.white',
            }}
          >
            <FileCopyIcon
              sx={{
                color: 'common.white',
              }}
            />
            <FormattedMessage id={messages.nexity.folders.vouchers} />
          </Typography>
          {documents?.map(document => (
            <DocumentUploadRowWithConfidentiality
              key={document.id}
              {...document}
              folderId={id}
              refreshPage={doFetch}
            />
          ))}
          {!preventModifications && (isDealer || isManager || isAdmin) && (
            <AddButton
              variant="text"
              label="Ajouter une piece justificative"
              onClick={() => navigate(`modals/request-voucher`)}
            />
          )}
          <Stack
            direction="row"
            gap={2}
            justifyContent="center"
            sx={{
              my: 2,
            }}
            alignItems="center"
          >
            {data?.showSendSpace && (
              <Button
                to="modals/send"
                component={Link}
                disabled={!(data?.canSendSpace ?? false)}
              >
                Envoyer les pièces et l&apos;espace
              </Button>
            )}
            {data?.canInvalidateSpace && (
              <Button to="modals/invalidate" component={Link}>
                Invalider les pièces et l&apos;espace
              </Button>
            )}
            {data?.showValidateSpace && (
              <Button
                to="modals/validate"
                component={Link}
                disabled={!(data?.canValidateSpace ?? false)}
              >
                Valider les pièces et l&apos;espace
              </Button>
            )}
          </Stack>
        </Box>
      )}
      <DialogOutletRoutes
        path="modals/*"
        onClose={() => navigate(`/folders/${id}`)}
      >
        <Route
          path="send"
          element={<SendDialog folderId={id} refreshFolder={() => doFetch()} />}
        />
        <Route
          path="invalidate"
          element={
            <InvalidateDialog folderId={id} refreshFolder={() => doFetch()} />
          }
        />
        <Route
          path="validate"
          element={
            <ValidateDialog folderId={id} refreshFolder={() => doFetch()} />
          }
        />
        <Route
          path="request-voucher"
          element={
            <RequestVoucherDialog
              buyersType={buyersType}
              onSubmit={onRequestVoucherSubmit}
              requestingVoucher={requestingVoucher}
              buyers={data?.prospect?.Buyers}
              existingDocuments={documents}
              backVoucherPhysicalOptions={data?.voucherPhysicalOptions}
              backVoucherSciOptions={data?.voucherSciOptions}
            />
          }
        />
        <Route
          path="import/:supportingFolderId"
          element={
            <UploadSupportingDialog
              id={id}
              onSuccess={() => {
                navigate('.');
                doFetch();
              }}
            />
          }
        />
        <Route
          path="replace/:supportingFolderId"
          element={
            <UploadSupportingDialog
              replacing
              id={id}
              onSuccess={() => {
                navigate('.');
                doFetch();
              }}
            />
          }
        />
        <Route
          path="delete/:fileId"
          element={<DeleteDialog id={id} refreshPage={doFetch} />}
        />
        <Route
          path="remove/:documentFolderId"
          element={<RemoveDialog id={id} refreshPage={doFetch} />}
        />
        <Route
          path="view/:documentId/:documentName"
          element={
            <DialogViewer paramName="documentId" nameParamName="documentName" />
          }
        />
        <Route
          path="update-dealer-dialog"
          element={
            <UpdateDealerDialog
              folderId={id}
              dealer={data?.prospect?.Dealer}
              refreshFolder={() => doFetch()}
            />
          }
        />
        <Route
          path="update-signer-dialog/signer/:signerIndex"
          element={
            <UpdateSignerDialog
              folderId={id}
              signers={data?.signers}
              refreshFolder={() => doFetch()}
            />
          }
        />
        <Route
          path="update-emails-notification-dialog"
          element={
            <UpdateEmailNotificationDialog
              folderId={id}
              notifications={data?.prepareElement?.data?.Notifications}
              refreshFolder={() => doFetch()}
            />
          }
        />
        <Route
          path="activate-buyers-notification"
          element={
            <UpdateBuyersNotificationDialog
              folderId={id}
              buyersNotification
              refreshFolder={() => doFetch()}
            />
          }
        />
        <Route
          path="deactivate-buyers-notification"
          element={
            <UpdateBuyersNotificationDialog
              folderId={id}
              refreshFolder={() => doFetch()}
            />
          }
        />
      </DialogOutletRoutes>
    </>
  );
}

export default FolderClientInfo;
