import React from 'react';
import { Link } from 'react-router-dom';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import useRouteTabs from '@hooks/useRouteTabs';

function RouteTabs({ routeTabs }) {
  const { currentRouteTab } = useRouteTabs(routeTabs);
  return (
    <MuiTabs value={currentRouteTab?.index || 0}>
      {routeTabs.map((routeTab, index) => (
        <MuiTab
          key={routeTab.linkProps.to}
          value={index}
          component={Link}
          {...routeTab.tabProps}
          {...routeTab.linkProps}
        />
      ))}
    </MuiTabs>
  );
}

export default RouteTabs;
