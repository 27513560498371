import {
  tenantIdentifierSelector,
  tenantParentIdentifierSelector,
} from '@recoil/tenant-token';
import {
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { useRecoilValue } from 'recoil';

export type FetchContextValue = {
  host?: boolean;
  hostLevel?: boolean;
  hostTenants?: boolean;
  hostTenantsRequested?: string | string[] | null;
  tenantParent?: boolean;
};

const defaultValue = {
  host: false,
  hostLevel: false,
  hostTenants: false,
  hostTenantsRequested: null,
  tenantParent: false,
};

const FetchContext = createContext<FetchContextValue>(defaultValue);

export function FetchContextProvider({
  host,
  hostLevel,
  hostTenants,
  hostTenantsRequested,
  tenantParent,
  children,
}: PropsWithChildren<FetchContextValue>) {
  const value = useMemo(
    () => ({
      host,
      hostLevel,
      hostTenants,
      hostTenantsRequested,
      tenantParent,
    }),
    [host, hostLevel, hostTenants, hostTenantsRequested, tenantParent]
  );
  return (
    <FetchContext.Provider value={value}>{children}</FetchContext.Provider>
  );
}

export function FetchContextCurrentProvider({
  tenantId,
  children,
}: PropsWithChildren<{
  tenantId: string | null | undefined;
}>): JSX.Element {
  const currentTenantIdentifier = useRecoilValue(tenantIdentifierSelector);

  if (!tenantId) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  if (tenantId === currentTenantIdentifier) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <FetchContextProvider host hostTenants hostTenantsRequested={tenantId}>
      {children}
    </FetchContextProvider>
  );
}

export function ParentTenantFetchContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const tenantParentIdentifier = useRecoilValue(tenantParentIdentifierSelector);

  return !tenantParentIdentifier ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <FetchContextProvider
      host={false}
      tenantParent
      hostTenantsRequested={tenantParentIdentifier}
    >
      {children}
    </FetchContextProvider>
  );
}

export function useFetchContext() {
  const value = useContext(FetchContext);
  return value ?? defaultValue;
}

export default FetchContext;
