import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@components/Buttons/IconButtons/IconButton';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { getTermSheetElementDisplayName } from '@views/MangeData/components/utils';
import { SearchFilter } from './helpers/types';

export function getFilterName(
  intl: IntlShape,
  filter: SearchFilter | null | undefined
) {
  if (!filter) return '';

  if (filter.item) {
    return getTermSheetElementDisplayName(intl, filter.item);
  }

  return filter.title;
}

interface FilterWrapperProps {
  showSearchButton: boolean;
  isRemovable?: boolean;
  onClear?: () => void;
  onDelete?: () => void;
  onValidate?: () => void;
  children: any;
  titleSx?: object;
  filter?: SearchFilter;
}

function FilterWrapper({
  showSearchButton = false,
  isRemovable = false,
  onClear,
  onDelete,
  onValidate,
  children,
  titleSx,
  filter,
}: FilterWrapperProps) {
  const intl = useIntl();

  return (
    <Stack spacing={2}>
      <Grid
        container
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
        direction="row"
      >
        <Grid item xs={isRemovable ? 10 : 12}>
          <Typography sx={titleSx}>{getFilterName(intl, filter)}</Typography>
        </Grid>
        {isRemovable && (
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <IconButton
              aria-label="delete"
              color="warning"
              size="small"
              onClick={onDelete}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Box sx={{ maxHeight: '36rem', overflowY: 'auto' }}>{children}</Box>
      {Boolean(showSearchButton) && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          {onClear && (
            <Button variant="text" onClick={onClear}>
              <FormattedMessage id="Verbs.Clear" />
            </Button>
          )}
          {onValidate && (
            <Button variant="contained" onClick={onValidate}>
              <FormattedMessage id="Verbs.Validate" />
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default FilterWrapper;
