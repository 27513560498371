import { useRecoilValue } from 'recoil';
import { tenantTokenSelector } from '@recoil/tenant-token';
import { useCallback, useState } from 'react';

type UseDownloadFileOptions = {
  url: string;
  outputFileName?: string;
  outputFileType?: string;
  method?: 'GET' | 'POST' | 'PUT';
  content?: any;
  onError?: (error: any) => void;
  onSuccess?: () => void;
};

const getFileNameFromContentDispostionHeader = (header: string) => {
  const fileNameToken = `filename*=UTF-8''`;

  let fileName = 'document.pdf';
  header.split(';').every((value: string) => {
    if (value.trim().indexOf(fileNameToken) === 0) {
      fileName = decodeURIComponent(value.trim().replace(fileNameToken, ''));
      return false;
    }
    return true;
  });
  return fileName;
};

const useDownloadFile = ({
  url,
  outputFileName,
  outputFileType = 'application/octet-stream',
  method = 'GET',
  content = null,
  onError,
  onSuccess,
}: UseDownloadFileOptions) => {
  const token = useRecoilValue(tenantTokenSelector);
  const fetchUrl = `${process.env.REACT_APP_API_URI}${url}`;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const doDownload = useCallback(
    async (options?: any) => {
      setError(null);
      setIsLoading(true);
      try {
        const usedContent = options?.content ?? content;
        const response = await fetch(fetchUrl, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          method,
          body:
            typeof usedContent === 'string' ||
            usedContent === undefined ||
            usedContent === null
              ? usedContent
              : JSON.stringify(usedContent),
        });
        if (!response.ok) {
          const json = await response.json();
          (response as any).data = json;
          setError(response);
          onError?.(response);
          return response;
        }
        const blob = new Blob(
          [
            ...(outputFileType === 'text/csv'
              ? [new Uint8Array([0xef, 0xbb, 0xbf])]
              : []),
            await response.blob(),
          ],
          {
            type: outputFileType,
          }
        );
        let filename;
        if (!outputFileName) {
          const contentDispositionHeader = response.headers.get(
            'content-disposition'
          );
          if (contentDispositionHeader) {
            filename = getFileNameFromContentDispostionHeader(
              contentDispositionHeader
            );
          }
        } else {
          filename = outputFileName;
        }
        const uri = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        if (filename) {
          link.setAttribute('download', filename);
        }
        link.href = uri;
        link.click();
        if (onSuccess) {
          onSuccess();
        }
        return response;
      } catch (err) {
        setError(err);
        onError?.(err);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [
      fetchUrl,
      token,
      method,
      content,
      outputFileType,
      outputFileName,
      onError,
      onSuccess,
    ]
  );

  return { doDownload, isLoading, error };
};

export default useDownloadFile;
