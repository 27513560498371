import { defineMessages } from 'react-intl';

export default defineMessages({
  AddTenant: {
    id: 'Buttons.Tenants.Add',
    defaultMessage: 'Add a business unit',
  },
  AddAdministrator: {
    id: 'Buttons.Administrator.Add',
    defaultMessage: 'Add an administrator',
  },
  NoTenants: {
    id: 'Tenants.NoResult',
    defaultMessage: 'No business unit',
  },
  AddTenantDialogTitle: {
    id: 'Dialogs.Tenants.Add.Title',
    defaultMessage: 'Add a business unit',
  },
  UpdateTenantDialogTitle: {
    id: 'Dialogs.Tenants.Update.Title',
    defaultMessage: 'Modify a business unit',
  },
  TenantNameLabel: {
    id: 'Tenants.Name.Label',
    defaultMessage: 'Business unit name',
  },
  TenantNameRequired: {
    id: 'Tenants.Name.Required',
    defaultMessage: 'Business unit name is required',
  },
  TenantNegotiationTypeLabel: {
    id: 'Tenants.NegotiationType.Label',
    defaultMessage: 'Negotiation Type',
  },
  TenantNegotiationTypeRequired: {
    id: 'Tenants.NegotiationType.Required',
    defaultMessage: 'Negotiation Type is required',
  },
  TenantNegotiationTypeOvertime: {
    id: 'Tenants.NegotiationType.Overtime',
    defaultMessage: 'Over time negotiation',
  },
  TenantNegotiationTypeAnnual: {
    id: 'Tenants.NegotiationType.Annual',
    defaultMessage: 'Annual negotiation campaign',
  },
  TenantAdministratorRequired: {
    id: 'Teams.TenantAdministrator.Required',
    defaultMessage: 'Administrator is required',
  },
});
