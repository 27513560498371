import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DownloadIcon from '@mui/icons-material/Download';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Fetching from '@components/Fetching/Fetching';
import { Typography } from '@mui/material';
import useFetch from '@hooks/useFetch';
import withPermissions from '@components/withTenantPermissions';
import tenantPermissions from '@utils/tenant-permissions';
import { DriveEntityType } from '@views/constants';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import TermSheetCard from './TermSheetCard';
import TermSheetTemplateCard from './TermSheetTemplate/TermSheetTemplateCard';
import TermSheetAnalyseCard from './TermSheetAnalyseCard';

export function useTermSheetFetch({
  entityId,
}: {
  entityId: string | null | undefined;
}) {
  return useFetch({
    url: `term-sheets/entities/${entityId}`,
    lazy: !entityId,
  });
}

export function useContractFetch({
  entityId,
}: {
  entityId: string | null | undefined;
}) {
  return useFetch({
    url: `contracts/${entityId}?includeWorkflow=true&includeTermSheetTemplate=true&includeCurrentDocumentAnalysis=true`,
    lazy: !entityId,
    method: 'GET',
  });
}

export default function TermSheet({
  termSheetFetch,
  entityId,
  entityType,
  parentId,
  isContractView,
  contractFetch,
}: {
  termSheetFetch: ReturnType<typeof useTermSheetFetch>;
  entityId: string;
  entityType: DriveEntityType;
  parentId: string;
  isContractView: boolean;
  contractFetch: ReturnType<typeof useContractFetch>;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const backUrl = searchParams.get('backUrl');
  const ButtonWithPermission = withPermissions(Button);

  return (
    <Box sx={{ mt: 2 }}>
      <Fetching fetching={termSheetFetch.fetching}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ButtonWithPermission
            permissions={[tenantPermissions.TermSheetValuesUpdate]}
            onClick={() =>
              navigate(
                `modals/add-element/${entityId}?parentId=${
                  parentId || ''
                }&backUrl=${encodeURIComponent(backUrl ?? '')}`,
                {
                  state: 'modals',
                }
              )
            }
            startIcon={<AddCircleIcon />}
            variant="text"
            size="small"
          >
            <FormattedMessage
              id="Driveview.TermSheet.Buttons.Add.Label"
              defaultMessage="Add an element"
            />
          </ButtonWithPermission>
          {entityType === 'document' && (
            <ButtonWithPermission
              permissions={
                entityType === 'document'
                  ? [tenantPermissions.DocumentTermSheetExport] // Handle other types permission here here
                  : undefined
              }
              startIcon={<DownloadIcon />}
              variant="text"
              size="small"
              component={Link}
              to={`modals/term-sheet/export/${entityId}/${entityType}?parentId=${
                parentId || ''
              }`}
            >
              <FormattedMessage
                id="Driveview.TermSheet.Buttons.Export.Label"
                defaultMessage="Export"
              />
            </ButtonWithPermission>
          )}
        </Stack>
        <Box
          style={{
            overflow: 'auto',
          }}
        >
          {contractFetch?.data && !contractFetch.data.templateId && (
            <TermSheetTemplateCard
              contractId={entityId}
              parentId={parentId}
              termSheetTemplate={contractFetch?.data?.termSheetTemplate}
              refetchContract={contractFetch.doFetch}
              refetchtermSheet={termSheetFetch.doFetch}
            />
          )}
          <Box
            sx={{
              mt: 2,
              p: 2,
              bgcolor: 'white',
              width: '100%',
              height: 'max-content',
              borderRadius: '12px',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textTransform: 'uppercase',
                fontWeight: '700',
                fontSize: '14px',
              }}
            >
              <FormattedMessage id="Driveview.TermSheet.Elements" />
            </Typography>
            <ErrorMessage error={termSheetFetch.error} />
            {termSheetFetch.data?.values.map((termSheetItem: any) => (
              <TermSheetCard
                termSheetItem={termSheetItem}
                parentId={parentId}
                key={termSheetItem.id}
                isContractView={isContractView}
              />
            ))}
            {termSheetFetch.data?.values?.length <= 0 && (
              <Typography
                sx={{ fontSize: '12px', color: 'secondary.main', mt: 2 }}
              >
                <FormattedMessage
                  id="SharedMenu.Element.NoData"
                  defaultMessage="Aucun élément"
                />
              </Typography>
            )}
          </Box>

          {contractFetch?.data && !contractFetch.data.templateId && (
            <TermSheetAnalyseCard
              documentAnalysis={contractFetch?.data?.currentDocumentAnalysis}
              hasTermSheetTemplate={
                contractFetch?.data?.termSheetTemplate != null
              }
              document={{
                id: contractFetch?.data?.id,
                name: contractFetch?.data?.document?.name,
              }}
              refetchtermSheet={termSheetFetch.doFetch}
            />
          )}
        </Box>
      </Fetching>
    </Box>
  );
}
