import React, { useRef } from 'react';
import { useFormikContext, useField } from 'formik';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import * as Yup from 'yup';

import FormikTextField from '@components/Formik/FormikTextField';
import {
  requiredIf,
  requiredOrStripWhen,
  stripWhenNot,
} from '@utils/yup-required-when';

const toFixedRounded = num => (Math.round(num * 100) / 100).toFixed(2);

export const fields = {
  operationCost: 'operationCost',
  ownFunds: 'ownFunds',
  warrantyCostEstimate: 'warrantyCostEstimate',
  acquisitionCostsEstimate: 'acquisitionCostsEstimate',
  globalCost: 'globalCost',
  propertyResale: 'propertyResale',
  remainingToFinance: 'remainingToFinance',
  coproEstimateFee: 'coproEstimateFee',
  fundingMethod: 'fundingMethod',
};

export const fundingMethods = {
  credit: 'credit',
  cash: 'cash',
};

export const getSchemaOperationForm = required =>
  Yup.object().shape({
    [fields.operationCost]: Yup.lazy(values => {
      if (values != null && values !== undefined && values !== '') {
        return Yup.number().min(0, 'Generic.Fields.MustBePositive');
      }
      return requiredIf(Yup.string(), required);
    }),
    [fields.ownFunds]: Yup.lazy(values => {
      if (values != null && values !== undefined && values !== '') {
        return Yup.number().min(0, 'Generic.Fields.MustBePositive');
      }
      return requiredIf(Yup.string(), required);
    }),
    [fields.warrantyCostEstimate]: (required
      ? requiredOrStripWhen
      : stripWhenNot)(
      Yup.number().default(0).min(0, 'Generic.Fields.MustBePositive'),
      fields.fundingMethod,
      fundingMethods.credit
    ),
    [fields.coproEstimateFee]: Yup.lazy(values => {
      if (values != null && values !== undefined && values !== '') {
        return Yup.number().min(0, 'Generic.Fields.MustBePositive');
      }
      return requiredIf(Yup.string(), required);
    }),
    [fields.acquisitionCostsEstimate]: Yup.lazy(values => {
      if (values != null && values !== undefined && values !== '') {
        return Yup.number().min(0, 'Generic.Fields.MustBePositive');
      }
      return requiredIf(Yup.string(), required);
    }),
    [fields.globalCost]: Yup.number().default(0),
    [fields.propertyResale]: Yup.lazy(values => {
      if (values != null && values !== undefined && values !== '') {
        return Yup.number().min(0, 'Generic.Fields.MustBePositive');
      }
      return requiredIf(Yup.string(), required);
    }),
    [fields.remainingToFinance]: Yup.number().default(0),
  });

export const defaultOperationFormValues = {
  [fields.operationCost]: '',
  [fields.ownFunds]: '',
  [fields.warrantyCostEstimate]: '',
  [fields.acquisitionCostsEstimate]: '',
  [fields.globalCost]: 0,
  [fields.propertyResale]: '',
  [fields.remainingToFinance]: 0,
  [fields.coproEstimateFee]: 500,
};

function numberOrZero(s) {
  return Number(s) || 0;
}

function OperationForm({ readOnly = false, required }) {
  const ref = useRef({
    hadAcquisitionCostEffect: false,
    hadGlobalCostEffect: false,
  });

  const {
    values: {
      [fields.globalCost]: oldGlobalCost,
      [fields.remainingToFinance]: oldRemaining,
      [fields.operationCost]: operationCost,
      [fields.warrantyCostEstimate]: warrantyCostEstimate,
      [fields.acquisitionCostsEstimate]: acquisitionCostsEstimate,
      [fields.propertyResale]: propertyResale,
      [fields.ownFunds]: ownFunds,
      [fields.coproEstimateFee]: coproEstimateFee,
    },
    setFieldValue,
  } = useFormikContext();
  const [{ value: fundingMethod }] = useField(fields.fundingMethod);
  React.useEffect(() => {
    if (!ref.current.hadAcquisitionCostEffect) {
      ref.current.hadAcquisitionCostEffect = true;
      return;
    }

    const newAcquisitionCost = toFixedRounded(
      numberOrZero(operationCost) * 0.025
    );
    setFieldValue(fields.acquisitionCostsEstimate, newAcquisitionCost);
  }, [operationCost, setFieldValue]);

  React.useEffect(() => {
    if (!ref.current.hadGlobalCostEffect) {
      ref.current.hadGlobalCostEffect = true;
      return;
    }

    const newGlobalCost =
      numberOrZero(operationCost) +
      numberOrZero(warrantyCostEstimate) +
      numberOrZero(acquisitionCostsEstimate) +
      numberOrZero(coproEstimateFee);
    const newRemaining =
      newGlobalCost - (numberOrZero(propertyResale) + numberOrZero(ownFunds));
    if (newGlobalCost !== oldGlobalCost) {
      setFieldValue(fields.globalCost, toFixedRounded(newGlobalCost));
    }

    if (newRemaining !== oldRemaining) {
      setFieldValue(fields.remainingToFinance, toFixedRounded(newRemaining));
    }
  }, [
    operationCost,
    warrantyCostEstimate,
    acquisitionCostsEstimate,
    propertyResale,
    ownFunds,
    oldGlobalCost,
    oldRemaining,
    setFieldValue,
    coproEstimateFee,
  ]);

  return (
    <Stack direction="column" gap={1} pt={1}>
      <Typography variant="h5" gutterBottom>
        Coût de l&apos;opération
      </Typography>
      <Grid container columnSpacing={4}>
        <Grid item xs={6}>
          <Stack direction="column" gap={1}>
            <FormikTextField
              as="fastfield"
              required={required}
              disabled={readOnly}
              margin="dense"
              color="secondary"
              name={fields.operationCost}
              label="Prix du bien"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="end">&euro;</InputAdornment>
                ),
              }}
            />
            <FormikTextField
              as="fastfield"
              required={required}
              disabled={readOnly}
              margin="dense"
              color="secondary"
              name={fields.acquisitionCostsEstimate}
              label="Estimation des frais d'acquisition"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="end">&euro;</InputAdornment>
                ),
              }}
            />
            <FormikTextField
              as="fastfield"
              required={required}
              disabled={readOnly}
              margin="dense"
              color="secondary"
              name={fields.coproEstimateFee}
              label="Estimation frais de règlement de copropriété"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="end">&euro;</InputAdornment>
                ),
              }}
            />
            {fundingMethod === fundingMethods.credit && (
              <FormikTextField
                as="fastfield"
                required={required}
                disabled={readOnly}
                margin="dense"
                color="secondary"
                name={fields.warrantyCostEstimate}
                label="Estimation frais de garantie de prêt obligatoire"
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: (
                    <InputAdornment position="end">&euro;</InputAdornment>
                  ),
                }}
              />
            )}

            <FormikTextField
              as="fastfield"
              disabled
              margin="dense"
              color="secondary"
              name={fields.globalCost}
              label="Coût global TTC (calcul automatique)"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="end">&euro;</InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="column" gap={1}>
            <FormikTextField
              as="fastfield"
              required={required}
              disabled={readOnly}
              margin="dense"
              color="secondary"
              name={fields.propertyResale}
              label="Revente d'un bien"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="end">&euro;</InputAdornment>
                ),
              }}
            />
            <FormikTextField
              as="fastfield"
              required={required}
              disabled={readOnly}
              margin="dense"
              color="secondary"
              name={fields.ownFunds}
              label="Fonds propres"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="end">&euro;</InputAdornment>
                ),
              }}
            />
            <FormikTextField
              as="fastfield"
              disabled
              margin="dense"
              color="secondary"
              name={fields.remainingToFinance}
              label="Reste à financer (calcul automatique)"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">&euro;</InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default OperationForm;
