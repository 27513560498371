import { defineMessages } from 'react-intl';

export default defineMessages({
  CampaignsListInformation: {
    id: 'Campaigns.List.Information',
    defaultMessage:
      'Before creating a new campaign, it is recommended to configure the contractual pack in the "contract package" tab.',
  },
  CampaignsListLabel: {
    id: 'Campaigns.List.Label',
    defaultMessage: 'Manage your negociation campaigns',
  },
  CampaignsListButtonsAdd: {
    id: 'Campaigns.List.Buttons.Add',
    defaultMessage: 'Add a campaign',
  },
  CampaignsItemTitle: {
    id: 'Campaigns.Item.Title',
    defaultMessage: 'Negociation campaign of {year}',
  },
  CampaignsItemInformation: {
    id: 'Campaigns.Item.Information',
    defaultMessage:
      'By default all documents will be taken from the previous year, select the items you want to renegociate totally or partially from the previous year.',
  },
  CampaignsItemNegociatedLabel: {
    id: 'Campaigns.Item.Negociated.Label',
    defaultMessage: 'Negociated documents',
  },
  CampaignsItemSignedLabel: {
    id: 'Campaigns.Item.Signed.Label',
    defaultMessage: 'Signed documents',
  },
  CampaignsItemSupportingLabel: {
    id: 'Campaigns.Item.Supporting.Label',
    defaultMessage: 'Supporting documents',
  },
  CampaignsItemButtonsLaunch: {
    id: 'Campaigns.Item.Buttons.Launch',
    defaultMessage: 'Launch the {year} campaign',
  },
  CampaignsItemNegociatedTypeAmendment: {
    id: 'Campaigns.Item.Negociated.Type.Amendment',
    defaultMessage: 'Amendment',
  },
  CampaignsItemNegociatedTypeRenegociation: {
    id: 'Campaigns.Item.Negociated.Type.Renegociation',
    defaultMessage: 'Global renegociation',
  },
  CampaignsItemNegociatedTypeLabel: {
    id: 'Campaigns.Item.Negociated.Type.Label',
    defaultMessage: 'Type',
  },
  CampaignsFieldsNameLabel: {
    id: 'Campaigns.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  CampaignsFieldsNameRequired: {
    id: 'Campaigns.Fields.Name.Required',
    defaultMessage: 'The campaign name is required',
  },
  CampaignsFieldsYearLabel: {
    id: 'Campaigns.Fields.Year.Label',
    defaultMessage: 'Year',
  },
  CampaignsFieldsYearRequired: {
    id: 'Campaigns.Fields.Year.Required',
    defaultMessage: 'The campaign year is required',
  },
  CampaignsFieldsYearInvalid: {
    id: 'Campaigns.Fields.Year.Invalid',
    defaultMessage: 'The campaign year must be a number',
  },
  CampaignsDialogsCreateTitle: {
    id: 'Campaigns.Dialogs.Create.Title',
    defaultMessage: 'Create a new campaign',
  },
  CampaignsDialogsCreateMailMergeTitle: {
    id: 'Campaigns.Dialogs.Create.Mailmerge.Title',
    defaultMessage: 'Create a new campaign of mail merge',
  },
  CampaignsDialogsMailmergeDetailChoiceItem: {
    id: 'Campaigns.Dialogs.Mailmerge.Detail.ChoiceItem',
    defaultMessage: 'Resume a previously saved campaign',
  },
  CampaignsDialogsNewMailMergeTitle: {
    id: 'Campaigns.Dialogs.New.MailMerge.Title',
    defaultMessage:
      'Give your mail merge campaign a name so you can save it and come back to it later',
  },
  CampaignsDialogsMailmergeRecoverySelectedItemText: {
    id: 'Campaigns.Dialogs.Mailmerge.Recovery.SelectedItem.Text',
    defaultMessage: 'Select the mail merge campaign to edit',
  },
  CampaignsCampaginMailMergeViewButtonsInitializeCampaignLabel: {
    id: 'Campaigns.CampaginMailMergeView.Buttons.InitializeCampaign.Label',
    defaultMessage: 'Initialize mail merge campaign',
  },
  CampaignsMailMergeViewFieldsTemplateLabel: {
    id: 'Campaigns.MailMergeView.Fields.Template.Label',
    defaultMessage: 'Template',
  },
  CampaignsMailMergeViewFieldsReferentialLabel: {
    id: 'Campaigns.MailMergeView.Fields.Referential.Label',
    defaultMessage: 'Mail merge file',
  },
  CampaignsMailMergeViewFieldsReferentialDescription: {
    id: 'Campaigns.MailMergeView.Fields.Referential.Description',
    defaultMessage:
      'Select the board from which the mail merge will be made. You can filter this table \n\r in the next step to select only the rows concerned by the mail merge.',
  },
  CampaignsMailMergeViewModalButtonsCancel: {
    id: 'Campaigns.MailMergeView.Modal.Buttons.Cancel',
    defaultMessage: 'Cancel',
  },
  CampaignsMailMergeViewModalButtonsNext: {
    id: 'Campaigns.MailMergeView.Modal.Buttons.Next',
    defaultMessage: 'Next',
  },
  CampaignsMailMergeViewModalButtonCreateLabel: {
    id: 'Campaigns.MailMergeView.Modal.Button.Create.Label',
    defaultMessage: 'Create',
  },
  CampaignsMailMergeViewModalReferentialTitleLabel: {
    id: 'Campaigns.MailMergeView.Modal.Referential.Title.Label',
    defaultMessage: 'Referential details',
  },
  CampaignsMailMergeViewModalButtonsSave: {
    id: 'Campaigns.MailMergeView.Modal.Button.Save',
    defaultMessage: 'Save',
  },
  CampaignsMailMergeViewModalButtonValidSelectedItemsLabel: {
    id: 'Campaigns.MailMergeView.Modal.Button.ValidSelectedItems.Label',
    defaultMessage: 'Valid selected items',
  },
  CampaignsMailMergeViewModalReferentialStep1FirstLabel: {
    id: 'Campaigns.MailMergeView.Modal.Referential.Step1First.Label',
    defaultMessage: 'Step 1/3',
  },
  CampaignsMailMergeViewModalReferentialStep1SecondLabel: {
    id: 'Campaigns.MailMergeView.Modal.Referential.Step1Second.Label',
    defaultMessage: 'Select items to be mail merged with filters',
  },
  CampaignConfirmModalDeletePopupTitleLabel: {
    id: 'Campaign.ConfirmModal.Delete.Popup.Title.Label',
    defaultMessage: 'Delete a mail merge',
  },
  CampaignConfirmModalDeletePopupDescriptionLabel: {
    id: 'Campaign.ConfirmModal.Delete.Popup.Description.Label',
    defaultMessage:
      'Are you sure you want to delete this mail merge Campaign ?',
  },
  CampaignsMailMergeViewMappingsStep2FirstLabel: {
    id: 'Campaigns.MailMergeView.Mappings.Step2First.Label',
    defaultMessage: 'Step 2/3',
  },
  CampaignsMailMergeViewMappingsStep2SecondLabel: {
    id: 'Campaigns.MailMergeView.Mappings.Step2Second.Label',
    defaultMessage: 'Select the people to invite at each stage',
  },
  CampaignsMailMergeViewMappingsStepsSectionDescriptionPart1Label: {
    id: 'Campaigns.MailMergeView.Mappings.StepsSection.Description.Part1.Label',
    defaultMessage: '1. Check that the document validation process suits you',
  },
  CampaignsMailMergeViewMappingsStepsSectionDescriptionPart2Label: {
    id: 'Campaigns.MailMergeView.Mappings.StepsSection.Description.Part2.Label',
    defaultMessage:
      'The validation steps mentioned below have been programmed for your mailing',
  },
  CampaignsMailMergeViewMappingsSectionInformationLabel: {
    id: 'Campaigns.MailMergeView.Mappings.Section.Information.Label',
    defaultMessage:
      'To modify them, you must have access to the Administration menu',
  },
  CampaignsMailMergeViewMappingsMappingsSectionDescriptionPart1Label: {
    id: 'Campaigns.MailMergeView.Mappings.MappingsSection.Description.Part1.Label',
    defaultMessage:
      '2. Assign the relevant column from the mail merge file for each user group',
  },
  CampaignsMailMergeViewMappingsMappingsStepsSectionDescriptionPart2Label: {
    id: 'Campaigns.MailMergeView.Mappings.MappingsSection.Description.Part2.Label',
    defaultMessage:
      'One or more categories of users present in the document validation process have been identified without an email address.',
  },
  CampaignsMailMergeViewMappingsMappingsStepsSectionDescriptionPart3Label: {
    id: 'Campaigns.MailMergeView.Mappings.MappingsSection.Description.Part3.Label',
    defaultMessage:
      'Please select from each of the lists below the column in your mailing list containing the Named User category emails.',
  },
  CampaignsMailMergeViewMappingsMappingsSectionSendSignatureInfoText: {
    id: 'Campaigns.MailMergeView.Mappings.MappingsSection.SendSignatureInfo.Text',
    defaultMessage:
      'If you wish to send the created documents for signature, other information such as first name, last name and telephone number may be required. You can choose them here.',
  },
  CampaignsMailMergeViewMappingsFieldsEmailLabel: {
    id: 'Campaigns.MailMergeView.Mappings.Fields.Email.Label',
    defaultMessage: 'Email adress',
  },
  CampaignsMailMergeViewMappingsFieldsFirstNameLabel: {
    id: 'Campaigns.MailMergeView.Mappings.Fields.FirstName.Label',
    defaultMessage: 'First name',
  },
  CampaignsMailMergeViewMappingsFieldsLastNameLabel: {
    id: 'Campaigns.MailMergeView.Mappings.Fields.LastName.Label',
    defaultMessage: 'Last name',
  },
  CampaignsMailMergeViewMappingsFieldsPhoneNumberLabel: {
    id: 'Campaigns.MailMergeView.Mappings.Fields.PhoneNumber.Label',
    defaultMessage: 'Phone number',
  },
  CampaignsMailMergeViewMappingsFieldsPlaceholder: {
    id: 'Campaigns.MailMergeView.Mappings.Fields.Placeholder',
    defaultMessage: 'Choose an item',
  },
  CampaignsMailMergeViewSaveSuccess: {
    id: 'Campaigns.MailMergeView.Save.Success',
    defaultMessage: 'The campaign mail merge has been saved successfully',
  },
  CampaignsMailMergeViewSaveFailure: {
    id: 'Campaigns.MailMergeView.Save.Failure',
    defaultMessage: 'The save has failed',
  },
  CampaignsMailMergeViewConfigSavingStep3FirstLabel: {
    id: 'Campaigns.MailMergeView.ConfigSaving.Step3First.Label',
    defaultMessage: 'Step 3/3',
  },
  CampaignsMailMergeViewConfigSavingStep3SecondLabel: {
    id: 'Campaigns.MailMergeView.ConfigSaving.Step3Second.Label',
    defaultMessage: 'Configure saving of mailed documents',
  },
  CampaignsMailMergeViewConfigSavingStepsSectionDescriptionPart1Label: {
    id: 'Campaigns.MailMergeView.ConfigSaving.StepsSection.Description.Part1.Label',
    defaultMessage:
      '1. Write below the desired nomenclature for each of the documents that will be created via mail merge :',
  },
  CampaignsMailMergeViewConfigSavingStepsSectionDescriptionPart2Label: {
    id: 'Campaigns.MailMergeView.ConfigSaving.StepsSection.Description.Part2.Label',
    defaultMessage:
      'Here is an example of a title format that will be applied to your documents following mail merge',
  },
  CampaignsMailMergeViewConfigSavingChooseFolderDescriptionPart1Label: {
    id: 'Campaigns.MailMergeView.ConfigSaving.ChooseFolder.Description.Part1.Label',
    defaultMessage:
      '2. Validate or modify the document registration folder following the mailing',
  },
  CampaignsMailMergeViewConfigSavingSavingFolderDescriptionPart1Label: {
    id: 'Campaigns.MailMergeView.ConfigSaving.SavingFolder.Description.Part1.Label',
    defaultMessage:
      '3. Choose the desired saving option for your documents following the mailing:',
  },
  CampaignsMailMergeViewConfigSavingSavingFolderDescriptionPart2Label: {
    id: 'Campaigns.MailMergeView.ConfigSaving.SavingFolder.Description.Part2.Label',
    defaultMessage: 'Save all documents in a folder',
  },
  CampaignsMailMergeViewConfigSavingSavingFolderDescriptionPart3Label: {
    id: 'Campaigns.MailMergeView.ConfigSaving.SavingFolder.Description.Part3.Label',
    defaultMessage: 'Save each document in a separate subfolder',
  },
  CampaignsMailMergeViewNomenclatureDynamicChoicescontractIndexLabel: {
    id: 'Campaigns.MailMergeView.Nomenclature.DynamicChoices.contractIndex.Label',
    defaultMessage: '​Document number',
  },
  CampaignConfirmModalLaunchPopupTitleLabel: {
    id: 'Campaign.ConfirmModal.Launch.Popup.Title.Label',
    defaultMessage: 'Confirm the launch of the mail merge',
  },
  CampaignConfirmModalLaunchPopupDescriptionLabel: {
    id: 'Campaign.ConfirmModal.Launch.Popup.Description.Label',
    defaultMessage:
      'You are about to validate the creation of {count} documents. The recipients will be notified by email from your confirmation.',
  },
  CampaignsMailMergeViewModalButtonsConfirmLaunch: {
    id: 'Campaigns.MailMergeView.Modal.Buttons.ConfirmLaunch',
    defaultMessage: 'Confirm launch',
  },
  CampaignConfirmModalLaunchWithWarningPopupTitleLabel: {
    id: 'Campaign.ConfirmModal.Launch.WithWarning.Popup.Title.Label',
    defaultMessage: 'Configuration error of your nomenclature',
  },
  CampaignConfirmModalLaunchWithWarningInformationLabel: {
    id: 'Campaign.ConfirmModal.Launch.WithWarning.Information.Label',
    defaultMessage:
      'The configured nomenclature does not make it possible to differentiate the title of each document',
  },
  CampaignConfirmModalLaunchWithWarningPopupDescriptionPart1Label: {
    id: 'Campaign.ConfirmModal.Launch.WithWarning.Popup.Description.Part1.Label',
    defaultMessage:
      'The titles of each of the {count} documents created during this mail merge must be unique.',
  },
  CampaignConfirmModalLaunchWithWarningPopupDescriptionPart2Label: {
    id: 'Campaign.ConfirmModal.Launch.WithWarning.Popup.Description.Part2.Label',
    defaultMessage: '. Click on Cancel to modify your nomenclature.',
  },
  CampaignConfirmModalLaunchWithWarningPopupDescriptionPart3Label: {
    id: 'Campaign.ConfirmModal.Launch.WithWarning.Popup.Description.Part3.Label',
    defaultMessage:
      '. Or click on Confirm the launch of the mail merge to which case we will automatically add a number to the name of each document in the order established by the referential file. The recipients will be notified by email from your confirmation.',
  },
  CampaignsMailMergeViewGetNamingSuccess: {
    id: 'Campaigns.MailMergeView.Get.Naming.Success',
    defaultMessage:
      'The fill naming of campaign mail merge has been executed successfully',
  },
  CampaignsMailMergeViewGetNamingFailure: {
    id: 'Campaigns.MailMergeView.Get.Naming.Failure',
    defaultMessage: 'The fill naming of campaign mail merge has failed',
  },
  CampaignsMailMergeViewBulkContractsSuccess: {
    id: 'Campaigns.MailMergeView.Bulk.Contracts.Success',
    defaultMessage:
      'The Generate contracts of campaign mail merge has been executed successfully',
  },
  CampaignsMailMergeViewBulkContractsFailure: {
    id: 'Campaigns.MailMergeView.Bulk.Contracts.Failure',
    defaultMessage: 'The Generate contracts of campaign mail merge has failed',
  },
  CampaignsDialogsNewMailMergeText: {
    id: 'Campaigns.Dialogs.New.MailMerge.Text',
    defaultMessage:
      'Do you want to create a new mail merge campaign or continue a saved campaign?',
  },
  CampaignsMailMergeViewModalButtonLaunchCampaignLabel: {
    id: 'Campaigns.MailMergeView.Modal.Button.LaunchCampaign.Label',
    defaultMessage: 'Launch campaign',
  },
  CampaignsMailMergeViewMappingsEmptyStepsMessageLabel: {
    id: 'Campaigns.MailMergeView.Mappings.EmptySteps.Message.Label',
    defaultMessage:
      'No steps configured yet for this contract template. Please reconfigure it before proceeding to the next step',
  },
  CampaignsMailMergeViewSaveFailureMissingWorkflowSchema: {
    id: 'Campaigns.MailMergeView.Save.Failure.MissingWorkflowSchema',
    defaultMessage:
      'Please note, this document template does not have an attached workflow. Please set up a workflow in order to be able to launch the mail merge.',
  },

  CampaignConfirmModalInfoPopupTitleLabel: {
    id: 'Campaign.ConfirmModal.Info.Popup.Title.Label',
    defaultMessage: 'The mail merge is launched on several batches.',
  },

  CampaignConfirmModalInfoPopupDescriptionLabel: {
    id: 'Campaign.ConfirmModal.Info.Popup.Description.Label',
    defaultMessage:
      'Please note, leave the page open until all contracts are created. Otherwise, you could lose contracts.',
  },
  CampaignsMailMergeViewSaveFailureCampaignMailMergeNameNotExist: {
    id: 'Campaigns.MailMergeView.Save.Failure.CampaignMailMergeNameNotExist',
    defaultMessage:
      'The name of your campaign has already been used. Please modify it before moving after the mail merge process.',
  },
  CampaignsMailMergeViewSendToSignatureLabelPart1: {
    id: 'Campaigns.MailMergeView.SendToSignature.Description.Part1.Label',
    defaultMessage:
      '1. Choose if you want to send the created documents to signature',
  },
  CampaignsMailMergeViewSendToSignatureLabelPart2: {
    id: 'Campaigns.MailMergeView.SendToSignature.Description.Part2.Label',
    defaultMessage:
      'Tick the checkbox below if you wish to send to signature the documents when they are created',
  },
  CampaignsMailMergeViewSendToSignatureNoSignatureStepWarning: {
    id: 'Campaigns.MailMergeView.SendToSignature.NoSignatureStep.Label',
    defaultMessage:
      'You cannot send the documents to signature because the workflow associated to the model you chose for this campaign does not contain a signature step. If you wish to send the documents to signature, please modify the associated workflow.',
  },
  CampaignsMailMergeViewSendToSignatureCheckboxLabel: {
    id: 'Campaigns.MailMergeView.SendToSignature.Checkbox.Label',
    defaultMessage: 'Send to signature the documents when they are created',
  },
});
