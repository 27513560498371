import Typography from '@mui/material/Typography';
import { useOutletContext, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import useFetch from '@hooks/useFetch';
import { toast } from 'react-toastify';

type DeleteAnnexModalProps = {
  documentId: string;
  onSuccess: () => void;
};

export default function DeleteAnnexModal({
  documentId,
  onSuccess,
}: DeleteAnnexModalProps) {
  const { close } = useOutletContext<any>();
  const { annexId } = useParams();
  const intl = useIntl();

  const deleteFetch = useFetch({
    url: `documents/${documentId}/annexes/${annexId}`,
    method: 'DELETE',
    onSuccess: () => {
      toast.success(
        intl.formatMessage({ id: 'Contract.Annex.Toast.Delete.Title' }),
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      onSuccess();
      close();
    },
  });

  const onDelete = () => {
    deleteFetch.doFetch();
  };

  return (
    <DialogWithClose
      titleLabel={<FormattedMessage id="Contract.Annex.Dialog.Delete.Title" />}
      dialogActions={
        <Button onClick={onDelete}>
          <FormattedMessage id="Verbs.Delete" />
        </Button>
      }
    >
      <Stack gap={2}>
        <Typography sx={{ fontWeight: 600 }}>
          <FormattedMessage id="Contract.Annex.Dialog.Delete.Warning" />
        </Typography>
        <Typography>
          <FormattedMessage id="Contract.Annex.Dialog.Delete.Explanation" />
        </Typography>
      </Stack>
    </DialogWithClose>
  );
}
