import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useBreadcrumbs(breadcrumbs, hideBreadcrumbs = false) {
  const location = useLocation();
  const navigate = useNavigate();
  const to = useMemo(
    () => ({
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
    }),
    [location.pathname, location.search, location.hash]
  );

  useEffect(() => {
    navigate(to, {
      replace: true,
      state: {
        breadcrumbs,
        hideBreadcrumbs,
      },
    });
  }, [navigate, to, breadcrumbs, hideBreadcrumbs]);
}
