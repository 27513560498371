import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';

function importPolyfill(locale) {
  return Promise.all([
    import(`@formatjs/intl-displaynames/locale-data/${locale}`),
    import(`@formatjs/intl-pluralrules/locale-data/${locale}`),
    import(`@formatjs/intl-relativetimeformat/locale-data/${locale}`),
  ]);
}

const importedLocales = new Set();

export default function polyfill(locale) {
  if (importedLocales.has(locale)) return;
  importedLocales.add(locale);
  // eslint-disable-next-line consistent-return
  return importPolyfill(locale);
}

function importDateFns(locale) {
  // eslint-disable-next-line no-param-reassign
  if (locale === 'en') locale = 'en-US';
  return import(`date-fns/locale/${locale}`);
}

const polyfilledDateFns = new Map();

export function polyfillDateFns(locale) {
  if (polyfilledDateFns.has(locale)) {
    return polyfilledDateFns.get(locale);
  }

  const promise = importDateFns(locale);
  polyfilledDateFns.set(locale, promise);
  return promise;
}
