import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import GetAppIcon from '@mui/icons-material/GetApp';
import AddIcon from '@mui/icons-material/Add';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import TermSheetFilterModal from '@views/Drive/components/TermSheetFilterModal/TermSheetFilterModal';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import { Route, useNavigate } from 'react-router-dom';
import {
  TermSheetElementResponse,
  TermSheetElementsListResponse,
} from '@api/types';
import useFetch, { ResponseWithData } from '@hooks/useFetch';
import cloneDeep from 'lodash.clonedeep';
import { useRecoilValue } from 'recoil';
import { tenantRolesIsExternalSelector } from '@recoil/tenant-token';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import { SearchFilter, SearchFilterType } from './Filters/helpers/types';
import PopoverFilters from './Filters/PopoverFilters';
import ButtonSearchFilter from './ButtonSearchFilter';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:not(.Mui-error):hover fieldset': {
      borderColor: 'transparent',
    },
    '&:not(.Mui-error).Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: '#666666',
  },
});

interface SearchWithFiltersProps {
  value?: string;
  onChange: (event: any) => void;
  disabled?: boolean;
  isTrash?: boolean;
  filters?: any[];
  onSearch?: () => void;
  moreFilter?: boolean;
  advancedSearchRequest?: any;
  setAdvancedSearchRequest?: Dispatch<SetStateAction<any>>;
  onExport?: () => void;
  exportIsLoading?: boolean;
  filterTitleSx?: object;
}

function SearchWithFilters({
  value,
  onChange,
  filters = [],
  disabled = false,
  isTrash = false,
  onSearch = undefined,
  moreFilter = false,
  advancedSearchRequest = undefined,
  setAdvancedSearchRequest = undefined,
  onExport = undefined,
  exportIsLoading = false,
  filterTitleSx,
}: SearchWithFiltersProps) {
  const [showFilters, setShowFilters] = useState(
    Boolean(advancedSearchRequest) &&
      Object.keys(advancedSearchRequest).length > 0
  );
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentFilterId, setCurrentFilterId] = React.useState(null);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [localFilters, setLocalFilters] = useState(filters);
  const currentFilter = localFilters?.find(f => f.id === currentFilterId);
  const open = Boolean(anchorEl && currentFilter);
  const isExternal = useRecoilValue(tenantRolesIsExternalSelector);
  const handleClick = (event: any, id: any) => {
    setAnchorEl(event.currentTarget);
    setCurrentFilterId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentFilterId(null);
  };
  const initTermSheetFiltersOnLoad = (
    termSheets: TermSheetElementResponse[] | undefined
  ) => {
    if (moreFilter && advancedSearchRequest?.termSheetQuery?.length > 0) {
      let tmpFilters = localFilters;
      let tmpSelectedFilters = selectedFilters;
      advancedSearchRequest.termSheetQuery.forEach((x: any) => {
        if (
          x !== undefined &&
          tmpFilters.findIndex(f => f.id === x.elementId) < 0
        ) {
          const currentTermSheet = termSheets?.find(t => t.id === x.elementId);
          if (currentTermSheet === undefined) return;
          const termSheetFilter: SearchFilter = {
            id: currentTermSheet.id,
            title: currentTermSheet.name,
            showSearchButton: true,
            type: SearchFilterType.AutoComplete,
            termSheetValueType: currentTermSheet.valueType,
            urlData: `term-sheets/elements/${currentTermSheet.id}/values`,
            tenantId: currentTermSheet.tenantId,
            dataMappingFromUrl: (data: any) =>
              data?.values?.map((termSheet: any) => ({
                label: termSheet,
                value: termSheet,
              })),
          };
          tmpFilters = [...tmpFilters, termSheetFilter];
          tmpSelectedFilters = [...tmpSelectedFilters, x.elementId];
        }
      });

      setLocalFilters(tmpFilters);
      setSelectedFilters(tmpSelectedFilters);
    }
  };

  useFetch<TermSheetElementsListResponse>({
    url: `term-sheets/elements`,
    method: 'GET',
    lazy: !(moreFilter && advancedSearchRequest?.termSheetQuery?.length > 0),
    onSuccess: (response: ResponseWithData<TermSheetElementsListResponse>) => {
      const filtersId: string[] = filters?.map(f => f.id);
      const allFiltersId: string[] = [...selectedFilters, ...filtersId];
      setSelectedFilters(allFiltersId);
      const result = response?.data?.termSheetElements.filter(
        item =>
          ['Text', 'Number', 'Boolean', 'Timestamp'].includes(item.valueType) &&
          !allFiltersId?.includes(item.id)
      );
      initTermSheetFiltersOnLoad(result);
    },
  });

  const onSelectedFilter = useCallback(
    (item: TermSheetElementResponse) => {
      const termSheetFilter: SearchFilter = {
        id: item.id,
        title: item.name,
        showSearchButton: true,
        type:
          item.valueType === 'DateOnly' || item.valueType === 'Timestamp'
            ? SearchFilterType.Timestamp
            : SearchFilterType.AutoComplete,
        periode:
          item.valueType === 'DateOnly' || item.valueType === 'Timestamp',
        termSheetValueType: item.valueType,
        urlData: `term-sheets/elements/${item.id}/values`,
        tenantId: item.tenantId,
        dataMappingFromUrl: (data: any) =>
          data?.values?.map((termSheet: any) => ({
            label: termSheet,
            value: termSheet,
          })),
        item,
      };
      setLocalFilters([...localFilters, termSheetFilter]);
      setSelectedFilters([...selectedFilters, item.id]);
    },
    [localFilters, selectedFilters]
  );
  const onLocalRemoveFilter = (id: string) => {
    handleClose();
    const tmpFilters = cloneDeep(localFilters);
    const tmpSelectedFilters = cloneDeep(selectedFilters);
    const indexFilter = tmpFilters?.findIndex(
      (x: SearchFilter) => x.id === id && x.termSheetValueType !== undefined
    );
    const indexSelectedFilters = tmpSelectedFilters.indexOf(id);
    if (indexFilter >= 0) {
      tmpFilters.splice(indexFilter, 1);
      setLocalFilters(tmpFilters);
    }
    if (indexSelectedFilters >= 0) {
      tmpSelectedFilters.splice(indexSelectedFilters, 1);
      setSelectedFilters(tmpSelectedFilters);
    }
    if (onSearch) onSearch();
  };

  return (
    <>
      <Stack direction="column">
        <Box>
          <StyledTextField
            disabled={disabled}
            value={value}
            onChange={onChange}
            sx={{
              borderRadius: 1,
              backgroundColor: '#E8E8E8',
            }}
            fullWidth
            placeholder="Recherche"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={onSearch ?? undefined}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: !filters?.length ? null : (
                <InputAdornment position="end">
                  {onExport !== undefined && !isExternal && (
                    <Tooltip title="Exporter les résultats en csv">
                      <IconButton
                        size="small"
                        onClick={onExport}
                        disabled={exportIsLoading}
                      >
                        {!exportIsLoading ? (
                          <GetAppIcon color="primary" />
                        ) : (
                          <CircularProgress size={20} />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                  {!isExternal && (
                    <Tooltip title="Affiner la recherche">
                      <IconButton onClick={() => setShowFilters(b => !b)}>
                        <TuneIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                onSearch?.();
              }
            }}
          />
        </Box>
        <Box>
          {showFilters && (
            <Stack direction="row" spacing={1} mt={1}>
              <Grid container direction="row">
                <Grid item xs={10} sx={{ mb: 1 }}>
                  <Stack direction="row" gap={1} flexWrap="wrap">
                    {localFilters?.map(filter => (
                      <ButtonSearchFilter
                        key={filter.id}
                        id={filter.id}
                        filter={filter}
                        isMultiple={filter.multiple}
                        isTermSheet={filter.termSheetValueType !== undefined}
                        advancedSearchRequest={advancedSearchRequest}
                        onClick={handleClick}
                      />
                    ))}
                    {moreFilter && (
                      <Tooltip title="Ajouter des filtres de recherche">
                        <Button
                          size="small"
                          color="secondary"
                          disableElevation
                          variant="text"
                          sx={{ borderRadius: 1, fontSize: '0.65rem' }}
                          onClick={() => navigate('modal/filter')}
                        >
                          <AddIcon sx={{ fontSize: '0.70rem' }} /> Plus de
                          filtres
                        </Button>
                      </Tooltip>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                >
                  <Button
                    size="small"
                    color="primary"
                    disableElevation
                    variant="contained"
                    startIcon={<SearchIcon />}
                    sx={{ fontSize: '0.65rem' }}
                    onClick={onSearch}
                  >
                    Rechercher
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          )}

          <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box p={2}>
              {Boolean(currentFilter) && (
                <ErrorBoundary>
                  <PopoverFilters
                    filter={currentFilter}
                    isTrash={isTrash}
                    advancedSearchRequest={advancedSearchRequest}
                    setAdvancedSearchRequest={setAdvancedSearchRequest}
                    onRemoveFilter={onLocalRemoveFilter}
                    onValidate={handleClose}
                    titleSx={filterTitleSx}
                  />
                </ErrorBoundary>
              )}
            </Box>
          </Popover>
        </Box>
      </Stack>
      <DialogOutletRoutes path="modal/*" onClose={null}>
        <Route
          path="filter"
          element={
            <TermSheetFilterModal
              onSelectedFilter={onSelectedFilter}
              selectedFilters={selectedFilters}
            />
          }
        />
      </DialogOutletRoutes>
    </>
  );
}

export default SearchWithFilters;
