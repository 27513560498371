/* eslint-disable no-bitwise */
const hueFromString = stringValue =>
  stringValue
    .split('')
    .map(s => s.charCodeAt(0))
    .reduce((acc, cur) => cur + ((acc << 5) - acc), 0) % 360;

const toHslColor = stringValue =>
  `hsl(${hueFromString(stringValue)}, 30%, 80%)`;

const toHexColor = stringValue => {
  const h = hueFromString(stringValue);
  const s = 30;
  const l = 0.8;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export { toHslColor, toHexColor };
export default hueFromString;
