export const resetPassword = async form => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/authentication/ResetPassword`,
    {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: form.email,
        password: form.password,
        passwordConfirmation: form.passwordConfirmation,
        code: form.code,
      }),
    }
  );
  if (!response.ok) {
    return { succeeded: false, error: 'ErrorOccured' };
  }

  return {
    succeeded: true,
  };
};

export const forgotPassword = async form => {
  throw new Error('this endpoint is not implemented generically');

  // eslint-disable-next-line no-unreachable
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/${process.env.REACT_APP_SHELL}/authentication/ForgotPassword`,
    {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: form.email,
      }),
    }
  );
  if (!response.ok) {
    return { succeeded: false, error: 'ErrorOccured' };
  }

  return {
    succeeded: true,
  };
};

export const signin = async form => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URI}/authentication/signin`,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: form.email,
          password: form.password,
        }),
      }
    );
    if (!response.ok) {
      return { succeeded: false, error: 'ErrorOccured' };
    }
    const json = await response.json();
    if (!json.result.succeeded) {
      if (json.result.isLockedOut) {
        return { succeeded: false, error: 'UserLockedOut' };
      }
      if (json.result.isNotFound) {
        return { succeeded: false, error: 'UserNotFound' };
      }
      if (json.result.hasNoPassword) {
        return { succeeded: false, error: 'UserHasNoPassword' };
      }
      if (json.result.isDeactivated) {
        return { succeeded: false, error: 'UserDeactivated' };
      }
      return { succeeded: false, error: 'InvalidEmailPassword' };
    }
    if (!!json.tenants?.length === 0) {
      return { succeeded: false, error: 'UserHasNoTenants' };
    }
    return {
      succeeded: true,
      token: json.token,
      refreshToken: json.refreshToken,
      tenants: json.tenants,
    };
  } catch (e) {
    return { succeeded: false, error: 'NetworkErrorOccured' };
  }
};

export const authorize = async (token, form) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URI}/authentication/authorize`,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          tenantId: form.tenant,
          ...form,
        }),
      }
    );
    const json = await response.json();
    if (!response.ok) {
      const error = Object.values(
        json?.Errors ?? json?.errors ?? {}
      )?.flat()?.[0];
      return { succeeded: false, error: error || 'ErrorOccured' };
    }
    if (!json.result.succeeded) {
      if (json.result.isLockedOut) {
        return { succeeded: false, error: 'UserLockedOut' };
      }
      if (json.result.isNotFound) {
        return { succeeded: false, error: 'UserNotFound' };
      }
      if (json.result.hasNoPassword) {
        return { succeeded: false, error: 'UserHasNoPassword' };
      }
      if (json.result.isDeactivated) {
        return { succeeded: false, error: 'UserDeactivated' };
      }
      return { succeeded: false, error: 'InvalidEmailPassword' };
    }
    if (!!json.tenants?.length === 0) {
      return { succeeded: false, error: 'UserHasNoTenants' };
    }
    return {
      succeeded: true,
      token: json.token,
      refreshToken: json.refreshToken,
      requireMfa: json.result.requireMfa,
    };
  } catch (e) {
    return { succeeded: false, error: 'NetworkErrorOccured' };
  }
};

export const elevate = async (token, form) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URI}/authentication/authorize/elevate`,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(form),
      }
    );
    const json = await response.json();
    if (!response.ok) {
      const error = Object.values(
        json?.Errors ?? json?.errors ?? {}
      )?.flat()?.[0];
      return { succeeded: false, error: error || 'ErrorOccured' };
    }
    if (!json.result.succeeded) {
      if (json.result.isLockedOut) {
        return { succeeded: false, error: 'UserLockedOut' };
      }
      if (json.result.isNotFound) {
        return { succeeded: false, error: 'UserNotFound' };
      }
      if (json.result.hasNoPassword) {
        return { succeeded: false, error: 'UserHasNoPassword' };
      }
      return { succeeded: false, error: 'InvalidEmailPassword' };
    }
    if (!!json.tenants?.length === 0) {
      return { succeeded: false, error: 'UserHasNoTenants' };
    }
    return {
      succeeded: true,
      token: json.token,
      refreshToken: json.refreshToken,
      requireMfa: json.result.requireMfa,
    };
  } catch (e) {
    return { succeeded: false, error: 'NetworkErrorOccured' };
  }
};

export const refresh = async (token, refreshToken) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URI}/authentication/token/refresh`,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          refreshToken,
        }),
      }
    );
    if (!response.ok) {
      return { succeeded: false, error: 'ErrorOccured' };
    }
    const json = await response.json();
    if (!json.result.succeeded) {
      if (json.result.isLockedOut) {
        return { succeeded: false, error: 'UserLockedOut' };
      }
      if (json.result.isNotFound) {
        return { succeeded: false, error: 'UserNotFound' };
      }
      if (json.result.hasNoPassword) {
        return { succeeded: false, error: 'UserHasNoPassword' };
      }
      if (json.result.isForbidden) {
        return { succeeded: false, error: 'Forbidden' };
      }
      return { succeeded: false, error: 'InvalidEmailPassword' };
    }
    if (!!json.tenants?.length === 0) {
      return { succeeded: false, error: 'UserHasNoTenants' };
    }
    return {
      succeeded: true,
      token: json.token,
      refreshToken: json.refreshToken,
    };
  } catch (e) {
    return { succeeded: false, error: 'NetworkErrorOccured' };
  }
};

export const resolve = async localStorageKey =>
  new Promise((presolve, preject) => {
    const token = localStorage.getItem(localStorageKey);
    if (token === null) {
      presolve(null);
    } else {
      const localToken = JSON.parse(token);
      const now = Date.now();
      if (Date.parse(localToken.token.expires) < now) {
        if (
          !localToken.refreshToken ||
          Date.parse(localToken.refreshToken.expires) < now
        ) {
          localStorage.removeItem(localStorageKey);
          presolve(null);
        } else {
          refresh(localToken.token.value, localToken.refreshToken.value)
            .then(newToken => {
              if (!newToken.succeeded) {
                localStorage.removeItem(localStorageKey);
                presolve(null);
              } else {
                localStorage.setItem(localStorageKey, JSON.stringify(newToken));
                presolve(newToken);
              }
            })
            .catch(preject);
        }
      } else {
        presolve(localToken);
      }
    }
  });
