import React from 'react';

import useTenantRoles from '@hooks/useTenantRoles';

export function RolesComponent({
  roles = [],
  mode = 'at-least',
  invert = false,
  bypass = false,
  fallback = null,
  children,
}) {
  const hasRoles = useTenantRoles({ roles, mode });
  if (bypass) return children;
  if (invert && hasRoles) return fallback;
  if (!invert && !hasRoles) return fallback;
  return children;
}

const withRoles = WrappedComponent =>
  function withRolesWrapped({
    roles = [],
    mode = 'at-least',
    invert = false,
    bypass = false,
    ...rest
  }) {
    return (
      <RolesComponent roles={roles} mode={mode} invert={invert} bypass={bypass}>
        <WrappedComponent {...rest} />
      </RolesComponent>
    );
  };

export default withRoles;
