import React from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { tenantDecodedTokenSelector } from '@recoil/tenant-token';

export function TenantAuthenticated({ children, requireMfa }) {
  const location = useLocation();
  const tenantToken = useRecoilValue(tenantDecodedTokenSelector);
  if (!tenantToken) {
    return (
      <Navigate
        to={`/signin?redirectTo=${encodeURIComponent(
          location.pathname + location.search + location.hash
        )}`}
        state={{ from: location }}
        replace
      />
    );
  }
  if (requireMfa && tenantToken.auth !== 'sms') {
    return (
      <Navigate
        to={`/signin/authorize/elevate?redirectTo=${encodeURIComponent(
          location.pathname + location.search + location.hash
        )}`}
        state={{ from: location }}
        replace
      />
    );
  }
  return children || null;
}

function TenantAuthenticatedRoute(props) {
  return (
    <TenantAuthenticated {...props}>
      <Outlet />
    </TenantAuthenticated>
  );
}

export default TenantAuthenticatedRoute;
