import React, { useEffect, useMemo, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import useFetch from '@hooks/useFetch';
import useDebounced from '@hooks/useDebounced';

import messages from '@i18n/keys';
import { label } from '@utils/contributor-helpers';
import useCustomizedSnackbar from '@hooks/useCustomizedSnackbar';
import { styled } from '@mui/system';
import { useAppContext } from '@contexts/AppContext';
import { workflowContributorTypes } from '@views/constants';

const StyledAutoComplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    // padding: 0,
  },
});

function ContributorPicker({
  params: paramsProp,
  value: valueProp,
  onChange: onChangeProp,
  PickerProps = {},
  disabled = false,
  usersOnly,
  multiple,
  emptyUser,
  filterRoleIds,
  excludeRoleIds,
  noResultElement,
  ...props
}) {
  const { formatMessage } = useIntl();
  const appContext = useAppContext();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounced(inputValue, 300);
  const snackbar = useCustomizedSnackbar();

  const urlParams = useMemo(
    () =>
      createSearchParams({
        ...paramsProp,
        query: debouncedInputValue ?? '',
        filterRoleIds: filterRoleIds ?? [],
        excludeRoleIds: excludeRoleIds ?? [],
        limit: 10,
        hideDeactivated: true
      }),
    [paramsProp, debouncedInputValue, filterRoleIds, excludeRoleIds]
  );

  const url = `/identity/roles-and-users?${urlParams}`;

  const { fetching, data, doFetch } = useFetch({
    url,
    lazy: true,
    onError: () => {
      snackbar.enqueueSnackbar(formatMessage('Errors.ErrorOccured'), {
        variant: 'error',
      });
    },
  });

  const usersOptions = React.useMemo(() => {
    if (!data) return [];
    const type = workflowContributorTypes.user;
    const typeLabel = formatMessage({
      id: messages.contributor.pickers.type[type].label,
    });
    const result = (data.users ?? []).map(u => ({
      type,
      typeLabel,
      entity: u,
    }));
    if (emptyUser) {
      result.push({
        type,
        typeLabel,
        entity: {
          id: '00000000-0000-0000-0000-000000000000',
        },
      });
    }
    return result;
  }, [data, formatMessage, emptyUser]);

  const allOptions = React.useMemo(() => {
    if (!data) return [];
    return [
      ...usersOptions,
      ...(data.roles ?? []).map(r => ({
        type: workflowContributorTypes.role,
        typeLabel: formatMessage({
          id: messages.contributor.pickers.type[2].label,
        }),
        entity: r,
      })),
    ];
  }, [data, usersOptions, formatMessage]);

  useEffect(() => {
    if (open && url && debouncedInputValue) {
      doFetch();
    }
  }, [open, url, debouncedInputValue, doFetch]);

  const options = useMemo(
    () =>
      usersOnly
        ? usersOptions.sort((a, b) => -b.typeLabel.localeCompare(a.typeLabel))
        : allOptions.sort((a, b) => -b.typeLabel.localeCompare(a.typeLabel)),
    [allOptions, usersOnly, usersOptions]
  );

  const getOptionLabel = option => {
    const res = label(option, formatMessage, {
      showEmail: appContext.contract.workflow.showEmail ?? false,
      showExternalIds: appContext.contract.workflow.showExternalIds ?? true,
    });
    return res || '';
  };

  return (
    <StyledAutoComplete
      fullWidth
      {...PickerProps}
      open={open}
      disabled={disabled}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={valueProp}
      multiple={multiple}
      onChange={onChangeProp}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      isOptionEqualToValue={(option, value) =>
        option.entity.id === value.entity.id
      }
      getOptionLabel={getOptionLabel}
      groupBy={option => option.typeLabel}
      options={options}
      loading={open && fetching}
      filterOptions={x => x}
      openText={formatMessage({
        id: 'Workflow.ContributorPicker.Open.Text',
      })}
      closeText={formatMessage({
        id: 'Workflow.ContributorPicker.Close.Text',
      })}
      clearText={formatMessage({
        id: 'Workflow.ContributorPicker.Clear.Text',
      })}
      loadingText={formatMessage({
        id: 'Workflow.ContributorPicker.Loading.Text',
      })}
      noOptionsText={
        <>
          {formatMessage({
            id: 'Workflow.ContributorPicker.NoOptions.Text',
          })}
          {noResultElement}
        </>
      }
      renderOption={(optionProps, option) => (
        <li
          {...optionProps}
          key={`option-${option?.type}-${option?.entity?.id}`}
        >
          {getOptionLabel(option)}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          {...props}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {fetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default ContributorPicker;
