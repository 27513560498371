import { createSvgIcon } from '@mui/material';
import React from 'react';

const FileIcon = createSvgIcon(
  <svg
    width="25"
    height="33"
    viewBox="0 0 24 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.04167 5.09359V0.666504H1.53125C1.09831 0.666504 0.75 1.01481 0.75 1.44775V16.5519C0.75 16.9849 1.09831 17.3332 1.53125 17.3332H12.4688C12.9017 17.3332 13.25 16.9849 13.25 16.5519V5.87484H8.82292C8.39323 5.87484 8.04167 5.52327 8.04167 5.09359ZM10.125 12.7759C10.125 12.9907 9.94922 13.1665 9.73438 13.1665H4.26562C4.05078 13.1665 3.875 12.9907 3.875 12.7759V12.5155C3.875 12.3006 4.05078 12.1248 4.26562 12.1248H9.73438C9.94922 12.1248 10.125 12.3006 10.125 12.5155V12.7759ZM9.73438 11.0832C9.94922 11.0832 10.125 10.9074 10.125 10.6925V10.4321C10.125 10.2173 9.94922 10.0415 9.73438 10.0415H4.26562C4.05078 10.0415 3.875 10.2173 3.875 10.4321V10.6925C3.875 10.9074 4.05078 11.0832 4.26562 11.0832H9.73438ZM10.125 8.3488V8.60921C10.125 8.82406 9.94922 8.99984 9.73438 8.99984H4.26562C4.05078 8.99984 3.875 8.82406 3.875 8.60921V8.3488C3.875 8.13395 4.05078 7.95817 4.26562 7.95817H9.73438C9.94922 7.95817 10.125 8.13395 10.125 8.3488ZM13.25 4.83317V4.6346C13.25 4.42952 13.1686 4.23096 13.0221 4.08447L9.83529 0.894368C9.6888 0.747884 9.49024 0.666504 9.2819 0.666504H9.08333V4.83317H13.25Z"
      fill="black"
    />
  </svg>,

  'FileIcon'
);

export default FileIcon;
