import { useRecoilValue } from 'recoil';

import {
  tenantIsAuthenticatedSelector,
  tenantRolesSelector,
} from '@recoil/tenant-token';

const useTenantRoles = ({ roles = [], mode = 'at-least' } = {}) => {
  let hasPermission = false;
  const isAuthenticated = useRecoilValue(tenantIsAuthenticatedSelector);
  const userRoles = useRecoilValue(tenantRolesSelector);
  if (isAuthenticated) {
    if (Array.isArray(roles)) {
      if (mode === 'at-least') {
        hasPermission = roles.some(p => userRoles.includes(p));
      } else {
        hasPermission = roles.every(p => userRoles.includes(p));
      }
    } else {
      hasPermission = userRoles.includes(roles);
    }
  }
  return hasPermission;
};

export default useTenantRoles;
