import { defineMessages } from 'react-intl';

export default defineMessages({
  ViewerErrorDefaultMessage: {
    id: 'Components.Viewer.Error.DefaultMessage',
    defaultMessage: 'An error occured when viewing document.',
  },
  ComponentsSearchPlaceholderLabel: {
    id: 'Components.Search.Placeholder.Label',
    defaultMessage: 'Search',
  },
  ComponentsNotAvailableText: {
    id: 'Components.NotAvailable.Text',
    defaultMessage:
      'This feature is not available yet. Please, contact us for more information.',
  },
});
