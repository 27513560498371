import React from 'react';
import Box from '@mui/material/Box';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';

import WorkflowStepList from './WorkflowSteps/WorkflowStepList';
import WorkflowStepEditor from './WorkflowSteps/WorkflowStepEditor';

function WorkflowEditor({
  schema,
  variablesData,
  error,
  sx = {},
  backUrl,
  hideNotifyContributors,
  hideNewUser,
  hideAccess = false,
  hideCondition = false,
  hideRemove = false,
  hideMandatoryContributors = false,
  hideOtherContributor = false,
  hideAddNewStep = false,
  onlyOneContributorByStep = false,
  hideRemoveStep = false,
  hideEmptyUser = false,
  cantRenameStep = false,
  allowNoContributors = false,
  allowGroupsToHaveUsers = null,
}) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'minmax(350px, 0.3fr) 1fr',
        gridTemplateRows: 'min-content 1fr',
        columnGap: 2,
        ...sx,
      }}
    >
      <WorkflowStepList
        schema={schema}
        backUrl={backUrl}
        hideAddNewStep={hideAddNewStep}
        allowNoContributors={allowNoContributors}
      />
      <ErrorBoundary>
        <WorkflowStepEditor
          schema={schema}
          variablesData={variablesData}
          error={error}
          hideNotifyContributors={hideNotifyContributors}
          hideNewUser={hideNewUser}
          onlyOneContributorByStep={onlyOneContributorByStep}
          hideAccess={hideAccess}
          hideCondition={hideCondition}
          hideRemove={hideRemove}
          hideMandatoryContributors={hideMandatoryContributors}
          hideOtherContributor={hideOtherContributor}
          hideRemoveStep={hideRemoveStep}
          hideEmptyUser={hideEmptyUser}
          cantRenameStep={cantRenameStep}
          allowNoContributors={allowNoContributors}
          allowGroupsToHaveUsers={allowGroupsToHaveUsers}
        />
      </ErrorBoundary>
    </Box>
  );
}

export default WorkflowEditor;
