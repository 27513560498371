import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormikTextField from '@components/Formik/FormikTextField';
import FormikSelect from '@components/Formik/FormikSelect';
import { useFormikContext } from 'formik';
import FormikPhone from '@components/Formik/FormikPhone';

function NotaryFormFields({ readOnly = false }) {
  const { values } = useFormikContext();
  const { callNotary } = values;

  const showMoreFields = callNotary === 'true' || callNotary?.value === 'true';

  return (
    <>
      <FormikSelect
        required
        disabled={readOnly}
        margin="dense"
        color="secondary"
        name="callNotary"
        label="Souhaitez-vous faire appel à votre notaire ?"
        FormControlProps={{
          color: 'secondary',
          margin: 'dense',
          disabled: readOnly,
        }}
      >
        <MenuItem value="true">Oui</MenuItem>
        <MenuItem value="false">Non</MenuItem>
      </FormikSelect>
      {showMoreFields && (
        <>
          <FormikTextField
            as="fastfield"
            disabled={readOnly}
            required
            margin="dense"
            color="secondary"
            name="address"
            label="Adresse"
          />
          <FormikPhone
            disabled={readOnly}
            required
            margin="dense"
            name="phone"
            label="Numéro de téléphone"
            color="secondary"
            defaultCountry="fr"
          />
          <FormikTextField
            as="fastfield"
            disabled={readOnly}
            required
            margin="dense"
            color="secondary"
            name="fullName"
            label="Nom + Prénom"
          />
          <FormikSelect
            required
            disabled={readOnly}
            name="presenceOfTheNotaryAtTheSigning"
            label="Présence du notaire à la signature"
            FormControlProps={{
              color: 'secondary',
              margin: 'dense',
              disabled: readOnly,
            }}
          >
            <MenuItem value="true">Oui</MenuItem>
            <MenuItem value="false">Non</MenuItem>
          </FormikSelect>
        </>
      )}
    </>
  );
}

function NotaryForm({ readOnly = false }) {
  return (
    <Stack direction="column" gap={1} pt={1}>
      <Typography variant="h5" gutterBottom>
        Notaire
      </Typography>
      <NotaryFormFields readOnly={readOnly} />
    </Stack>
  );
}

export default NotaryForm;
