import { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { FetchProvider } from '@bjornagh/use-fetch';
import { Worker } from '@components/Viewer/Viewer';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import initializeState from '@recoil/initialize-state';
import Loader from '@components/Loader/Loader';
import IntlProvider from '@components/IntlProvider/IntlProvider';
import LocalizationProvider from '@components/Localization/LocalizationProvider';
import TokenCapture from '@components/Token/TokenCapture';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomBrowerRouter from '@components/BrowserRouter/CustomBrowserRouter';
import History from '@utils/History';
import MuiProLicense from '../License/MuiProLicense';

const cache = new Map();

function defaultChooseTokenNavigation() {
  return {
    pathname: '/',
  };
}

function GenericApp({
  theme,
  chooseTokenNavigation = null,
  forcedLanguage = null,
  children,
}) {
  return (
    <>
      <Worker>
        <ToastContainer />
        <SnackbarProvider maxSnack={10}>
          <RecoilRoot
            initializeState={snapshot =>
              initializeState(snapshot, forcedLanguage)
            }
          >
            <RecoilNexus />
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Suspense fallback={<Loader sx={{ height: '100vh' }} />}>
                <CustomBrowerRouter history={History}>
                  <TokenCapture
                    chooseNavigation={
                      chooseTokenNavigation ?? defaultChooseTokenNavigation
                    }
                  >
                    <FetchProvider cache={cache}>
                      <IntlProvider>
                        <LocalizationProvider>{children}</LocalizationProvider>
                      </IntlProvider>
                    </FetchProvider>
                  </TokenCapture>
                </CustomBrowerRouter>
              </Suspense>
            </ThemeProvider>
          </RecoilRoot>
        </SnackbarProvider>
      </Worker>
      <MuiProLicense />
    </>
  );
}

export default GenericApp;
