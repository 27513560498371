import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import Fetching from '@components/Fetching/Fetching';
import WorkflowStepper from '@components/Workflow/WorkflowStepper';

function DocumentWorkflow({
  contract,
  fetching,
}: {
  contract: any;
  fetching: any;
}) {
  const workflow = contract?.workflow ?? undefined;

  return (
    <Box
      style={{
        maxHeight: 'calc(100vh - 205px)',
        marginLeft: '-24px',
        marginRight: '-24px',
        marginBottom: '-24px',
        padding: '24px',
        overflow: 'auto',
      }}
    >
      <Fetching fetching={fetching}>
        {workflow !== undefined && (
          <Box sx={{ bgcolor: '#FFFFFF', borderRadius: '6px', p: 1 }}>
            <WorkflowStepper workflow={workflow} isOpen />
          </Box>
        )}
        {workflow === undefined && (
          <Typography sx={{ fontSize: '12px', color: 'secondary.main' }}>
            <FormattedMessage
              id="SharedMenu.Workflow.NoData"
              defaultMessage="Aucun workflow"
            />
          </Typography>
        )}
      </Fetching>
    </Box>
  );
}

export default DocumentWorkflow;
