import React, { useMemo } from 'react';
import FormikProvider from '@components/Formik/FormikProvider';
import * as Yup from 'yup';
import { useOutletContext } from 'react-router-dom';
import useFetch from '@hooks/useFetch';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Stack } from '@mui/material';
import FormikTextField from '@components/Form/TextField';
import { toast } from 'react-toastify';

type AddVersionModalProps = {
  onSuccess: () => void;
  contractId: string;
};

const schema = Yup.object().shape({
  name: Yup.string().required('Generic.Fields.Required'),
});

export default function AddVersionModal({
  onSuccess,
  contractId,
}: AddVersionModalProps) {
  const { close } = useOutletContext<any>();
  const initialValues = useMemo(
    () => ({
      name: '',
    }),
    []
  );
  const intl = useIntl();
  const addFetch = useFetch({
    url: `contracts/${contractId}/versions`,
    method: 'POST',
    onSuccess: () => {
      toast.success(
        intl.formatMessage({ id: 'Contract.Version.Add.Success' }),
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      onSuccess();
      close();
    },
  });

  const onSubmit = (values: any) => {
    console.log(values);
    addFetch.doFetch({ body: { Name: values.name } });
  };

  return (
    <FormikProvider
      schema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      enableReinitialize
    >
      <DialogWithClose
        titleLabel={<FormattedMessage id="Contract.Version.Dialog.Add.Title" />}
        dialogActions={
          <Button type="submit">
            <FormattedMessage id="Verbs.Save" defaultMessage="Save" />
          </Button>
        }
      >
        <Stack>
          <FormikTextField
            required
            name="name"
            label={
              <FormattedMessage id="Contract.Version.Dialog.Forms.Name.Label" />
            }
            color="secondary"
            margin="dense"
          />
        </Stack>
      </DialogWithClose>
    </FormikProvider>
  );
}
