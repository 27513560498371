/* eslint-disable */
import React from 'react';
import { useOutletContext, useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';

import DialogTitleWithClose from '@components/Dialog/DialogTitleWithClose';
import DialogActionsWithClose from '@components/Dialog/DialogActionsWithClose';

import useFetch from '@hooks/useFetch';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';

function DeleteDialog({ id, refreshPage }) {
  const { close } = useOutletContext();
  const { fileId } = useParams();
  const navigate = useNavigate();
  const { error, fetching, doFetch: doDelete } = useFetch({
    url: `documents/${fileId}`,
    method: 'DELETE',
    onSuccess: () => {
      navigate(`/folders/${id}`);
      refreshPage();
    },
  });
  return (
    <>
      <DialogTitleWithClose onClose={close}>
        <FormattedMessage
          id="FormView.DocumentToImport.DeleteDialog.Title"
          defaultMessage="Supprimer un document"
        />
      </DialogTitleWithClose>
      <DialogContent>
        <ErrorMessage error={error}/>
        <FormattedMessage
          id="FormView.DocumentToImport.DeleteDialog.Confirm"
          defaultMessage="Etes vous sur de vouloir supprimer le document ?"
        />
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button variant="contained" onClick={doDelete} disabled={fetching}>
          <FormattedMessage
            id="FormView.DocumentToImport.DocumentUploadRow.Button.Delete"
            defaultMessage="Supprimer"
          />
        </Button>
      </DialogActionsWithClose>
    </>
  );
}

export default DeleteDialog;
