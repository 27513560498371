import React from 'react';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { nexitySignatureStatuses } from '@shells/nexity/nexity-constants';

function FolderAlerts({ detail, contractualization }) {
  const invalidatedContractualization = Boolean(
    detail.data?.invalidatedContractualization
  );
  const invalidatedByAffiliate = Boolean(detail.data?.invalidatedByAffiliate);
  const showRevocationMessage = Boolean(
    contractualization &&
      detail.data?.signature?.status === nexitySignatureStatuses.revoked &&
      !invalidatedContractualization &&
      !invalidatedByAffiliate
  );

  return (
    <>
      {invalidatedContractualization && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <Typography>
            Le contrat a été invalidé, le lot est en attente de remise en vente
            :
          </Typography>
          <Typography>{detail.data?.invalidationMessage}</Typography>
        </Alert>
      )}
      {invalidatedByAffiliate && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <Typography>Le contrat a été invalidé par le manageur :</Typography>
          <Typography>{detail.data?.invalidationMessage}</Typography>
        </Alert>
      )}
      {showRevocationMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <Typography>
            La signature du contrat a été révoquée ou refusée :
          </Typography>
          <Typography>
            {detail.data?.signature.revocationMessage || 'Aucun message'}
          </Typography>
        </Alert>
      )}
    </>
  );
}

export default FolderAlerts;
