import React from 'react';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

export default function ScmMuiDateWidget(props) {
  const {
    value,
    setValue,
    readonly,
    customProps,
    dateFormat,
    valueFormat,
    placeholder,
    jsType,
  } = props;

  const formatSingleValue = v =>
    v && v.isValid() ? v.format(valueFormat) : undefined;
  const handleChange = v => {
    setValue(jsType === 'string' ? formatSingleValue(v) : v);
  };
  const renderInput = params => (
    <TextField size="small" variant="standard" {...params} />
  );

  return (
    <FormControl>
      <DatePicker
        readOnly={readonly}
        disabled={readonly}
        toolbarPlaceholder={!readonly ? placeholder : ''}
        inputFormat={dateFormat}
        value={value || null}
        onChange={handleChange}
        renderInput={renderInput}
        {...customProps}
      />
    </FormControl>
  );
}
