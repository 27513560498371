/* generated */
/* eslint-disable */

export default {
  "adeo": {
    "addsupplier": {
      "button": "Adeo.AddSupplier.Button",
      "selectproductmanager": "Adeo.AddSupplier.SelectProductManager",
      "selectsupplier": {
        "country": "Adeo.AddSupplier.SelectSupplier.Country",
        "default": "Adeo.AddSupplier.SelectSupplier.Default",
        "fiscalid": "Adeo.AddSupplier.SelectSupplier.FiscalID",
        "headquartercity": "Adeo.AddSupplier.SelectSupplier.HeadquarterCity",
        "name": "Adeo.AddSupplier.SelectSupplier.Name",
        "partnerid": "Adeo.AddSupplier.SelectSupplier.PartnerId",
        "preselected": "Adeo.AddSupplier.SelectSupplier.Preselected",
        "referenced": "Adeo.AddSupplier.SelectSupplier.Referenced"
      },
      "step": {
        "selectproductmanager": "Adeo.AddSupplier.Step.SelectProductManager",
        "selectsupplier": "Adeo.AddSupplier.Step.SelectSupplier"
      }
    },
    "administration": {
      "team": {
        "addproductmanager": "Adeo.Administration.Team.AddProductManager",
        "productmanagerrequired": "Adeo.Administration.Team.ProductManagerRequired"
      }
    },
    "cancelsignaturemodal": {
      "button": "Adeo.CancelSignatureModal.Button",
      "canceled": "Adeo.CancelSignatureModal.Canceled",
      "description1": "Adeo.CancelSignatureModal.Description1",
      "description2": "Adeo.CancelSignatureModal.Description2",
      "message": "Adeo.CancelSignatureModal.Message",
      "notify": "Adeo.CancelSignatureModal.Notify",
      "title": "Adeo.CancelSignatureModal.Title"
    },
    "dashboard": {
      "header": {
        "negociations": "Adeo.Dashboard.Header.Negociations",
        "statistics": "Adeo.Dashboard.Header.Statistics",
        "suppliers": "Adeo.Dashboard.Header.Suppliers",
        "team": "Adeo.Dashboard.Header.Team"
      }
    },
    "document": {
      "remainingsignatoriescount": "Adeo.Document.RemainingSignatoriesCount"
    },
    "documentstatus": {
      "negotiating": "Adeo.DocumentStatus.Negotiating",
      "signed": "Adeo.DocumentStatus.Signed",
      "signing": "Adeo.DocumentStatus.Signing",
      "tobecompleted": "Adeo.DocumentStatus.ToBeCompleted",
      "tobesignedbybu": "Adeo.DocumentStatus.ToBeSignedByBU",
      "tobesignedbysupplier": "Adeo.DocumentStatus.ToBeSignedBySupplier",
      "tobevalidated": "Adeo.DocumentStatus.ToBeValidated",
      "tosendforsignature": "Adeo.DocumentStatus.ToSendForSignature"
    },
    "formik": {
      "datepicker": {
        "field": "Adeo.Formik.DatePicker.Field"
      }
    },
    "massreminder": {
      "modal": {
        "button": {
          "continue": "Adeo.MassReminder.Modal.Button.Continue",
          "send": "Adeo.MassReminder.Modal.Button.Send"
        }
      },
      "title": "Adeo.MassReminder.Title"
    },
    "massremindermodal": {
      "alert": {
        "description": "Adeo.MassReminderModal.Alert.Description",
        "noreminders": "Adeo.MassReminderModal.Alert.NoReminders",
        "title": "Adeo.MassReminderModal.Alert.Title"
      },
      "description": "Adeo.MassReminderModal.Description",
      "section": {
        "documenttype": {
          "title": "Adeo.MassReminderModal.Section.DocumentType.Title"
        },
        "notreminded": {
          "title": "Adeo.MassReminderModal.Section.NotReminded.Title"
        }
      },
      "submiterrors": {
        "documentstype": {
          "required": "Adeo.MassReminderModal.SubmitErrors.DocumentsType.Required"
        },
        "reminderdate": {
          "required": "Adeo.MassReminderModal.SubmitErrors.ReminderDate.Required"
        }
      }
    },
    "noresult": "Adeo.NoResult",
    "notallowed": {
      "description": "Adeo.NotAllowed.Description"
    },
    "notifications": {
      "types": {
        "replaceproductmanager": {
          "text": "Adeo.Notifications.Types.ReplaceProductManager.Text"
        }
      }
    },
    "roles": {
      "administrator": "Adeo.Roles.Administrator",
      "assistantproductmanager": "Adeo.Roles.AssistantProductManager",
      "expert": "Adeo.Roles.Expert",
      "marketleader": "Adeo.Roles.MarketLeader",
      "offerleader": "Adeo.Roles.OfferLeader",
      "productmanager": "Adeo.Roles.ProductManager",
      "supplier": "Adeo.Roles.Supplier",
      "tcpc": "Adeo.Roles.TCPC"
    },
    "sso": {
      "errors": {
        "erroroccured": "Adeo.SSO.Errors.ErrorOccured",
        "noaccess": "Adeo.SSO.Errors.NoAccess",
        "notallowed": "Adeo.SSO.Errors.NotAllowed",
        "notallowedsupplier": "Adeo.SSO.Errors.NotAllowedSupplier"
      }
    },
    "share": {
      "modal": {
        "document": {
          "users": {
            "title": "Adeo.Share.Modal.Document.Users.Title"
          }
        },
        "folder": {
          "users": {
            "title": "Adeo.Share.Modal.Folder.Users.Title"
          }
        },
        "search": {
          "placeholder": "Adeo.Share.Modal.Search.PlaceHolder"
        }
      }
    },
    "signmodal": {
      "action": {
        "addsigner": "Adeo.SignModal.Action.AddSigner",
        "cancel": "Adeo.SignModal.Action.Cancel",
        "close": "Adeo.SignModal.Action.Close",
        "continue": "Adeo.SignModal.Action.Continue",
        "send": "Adeo.SignModal.Action.Send"
      },
      "error": {
        "duplicate": "Adeo.SignModal.Error.Duplicate"
      },
      "newsupplier": "Adeo.SignModal.NewSupplier",
      "nophone": "Adeo.SignModal.NoPhone",
      "step": {
        "selectdocument": "Adeo.SignModal.Step.SelectDocument",
        "selectsignatories": "Adeo.SignModal.Step.SelectSignatories",
        "selectsignatories2": "Adeo.SignModal.Step.SelectSignatories2",
        "selectsignatories3": "Adeo.SignModal.Step.SelectSignatories3",
        "sending": "Adeo.SignModal.Step.Sending",
        "sent": "Adeo.SignModal.Step.Sent",
        "title": {
          "selectdocument": "Adeo.SignModal.Step.Title.SelectDocument",
          "selectsignatories": "Adeo.SignModal.Step.Title.SelectSignatories",
          "sending": "Adeo.SignModal.Step.Title.Sending"
        }
      },
      "title": "Adeo.SignModal.Title"
    },
    "statistics": {
      "cards": {
        "beingsigned": "Adeo.Statistics.Cards.BeingSigned",
        "signed": "Adeo.Statistics.Cards.Signed",
        "signedbysupplier": "Adeo.Statistics.Cards.SignedBySupplier",
        "suppliers": "Adeo.Statistics.Cards.Suppliers",
        "target": "Adeo.Statistics.Cards.Target",
        "tobesigned": "Adeo.Statistics.Cards.ToBeSigned",
        "tosend": "Adeo.Statistics.Cards.ToSend"
      },
      "export": {
        "button": "Adeo.Statistics.Export.Button",
        "columns": {
          "contractname": "Adeo.Statistics.Export.Columns.ContractName",
          "contractstatus": "Adeo.Statistics.Export.Columns.ContractStatus",
          "departments": "Adeo.Statistics.Export.Columns.Departments",
          "offerresponsible": "Adeo.Statistics.Export.Columns.OfferResponsible",
          "signatory": "Adeo.Statistics.Export.Columns.Signatory",
          "suppliername": "Adeo.Statistics.Export.Columns.SupplierName"
        },
        "loading": "Adeo.Statistics.Export.Loading",
        "signatorystatus": {
          "pending": "Adeo.Statistics.Export.SignatoryStatus.Pending",
          "rejected": "Adeo.Statistics.Export.SignatoryStatus.Rejected",
          "signed": "Adeo.Statistics.Export.SignatoryStatus.Signed",
          "waiting": "Adeo.Statistics.Export.SignatoryStatus.Waiting"
        }
      },
      "filter": {
        "creationdate": {
          "placeholder": "Adeo.Statistics.Filter.CreationDate.Placeholder"
        },
        "departments": {
          "placeholder": "Adeo.Statistics.Filter.Departments.Placeholder"
        },
        "documenttypes": {
          "placeholder": "Adeo.Statistics.Filter.DocumentTypes.Placeholder"
        },
        "title": "Adeo.Statistics.Filter.Title"
      }
    },
    "supplier": {
      "remind": {
        "button": "Adeo.Supplier.Remind.Button",
        "description": "Adeo.Supplier.Remind.Description",
        "previusshouldsign": "Adeo.Supplier.Remind.PreviusShouldSign",
        "send": "Adeo.Supplier.Remind.Send",
        "sendreminder": "Adeo.Supplier.Remind.SendReminder",
        "sent": "Adeo.Supplier.Remind.Sent",
        "signedon": "Adeo.Supplier.Remind.SignedOn",
        "title": "Adeo.Supplier.Remind.Title"
      },
      "view": {
        "button": "Adeo.Supplier.View.Button"
      }
    },
    "suppliername": "Adeo.SupplierName",
    "update": {
      "productmanager": {
        "description": "Adeo.Update.ProductManager.Description",
        "title": "Adeo.Update.ProductManager.Title"
      }
    }
  },
  "admin": {
    "administration": "Admin.Administration",
    "controlpanel": "Admin.ControlPanel",
    "externalaccounts": {
      "title": "Admin.ExternalAccounts.Title"
    },
    "manage": {
      "referentials": "Admin.Manage.Referentials",
      "templates": "Admin.Manage.Templates"
    },
    "managereferences": "Admin.ManageReferences",
    "useraccounts": {
      "url": "Admin.UserAccounts.Url"
    }
  },
  "adminview": {
    "cards": {
      "button": "AdminView.Cards.Button",
      "clauses": {
        "description": "AdminView.Cards.Clauses.Description",
        "title": "AdminView.Cards.Clauses.Title"
      },
      "integrations": {
        "description": "AdminView.Cards.Integrations.Description",
        "title": "AdminView.Cards.Integrations.Title"
      },
      "manageaccounts": {
        "description": "AdminView.Cards.ManageAccounts.Description"
      },
      "managedata": {
        "description": "AdminView.Cards.ManageData.Description",
        "title": "AdminView.Cards.ManageData.Title"
      },
      "manageparenttemplates": {
        "description": "AdminView.Cards.ManageParentTemplates.Description",
        "title": "AdminView.Cards.ManageParentTemplates.Title"
      },
      "managereferences": {
        "description": "AdminView.Cards.ManageReferences.Description",
        "title": "AdminView.Cards.ManageReferences.Title"
      },
      "managetemplates": {
        "description": "AdminView.Cards.ManageTemplates.Description",
        "title": "AdminView.Cards.ManageTemplates.Title"
      },
      "managetenanttemplates": {
        "description": "AdminView.Cards.ManageTenantTemplates.Description",
        "title": "AdminView.Cards.ManageTenantTemplates.Title"
      },
      "manageusers": {
        "description": "AdminView.Cards.ManageUsers.Description",
        "title": "AdminView.Cards.ManageUsers.Title"
      },
      "notiontypes": {
        "description": "AdminView.Cards.NotionTypes.Description",
        "title": "AdminView.Cards.NotionTypes.Title"
      },
      "tags": {
        "description": "AdminView.Cards.Tags.Description",
        "title": "AdminView.Cards.Tags.Title"
      }
    }
  },
  "authorize": {
    "button": "Authorize.Button",
    "fields": {
      "tenant": {
        "label": "Authorize.Fields.Tenant.Label",
        "required": "Authorize.Fields.Tenant.Required"
      }
    }
  },
  "backtohome": {
    "button": "BackToHome.Button"
  },
  "buttons": {
    "administrator": {
      "add": "Buttons.Administrator.Add"
    },
    "projectmanager": {
      "add": "Buttons.ProjectManager.Add"
    },
    "teams": {
      "add": "Buttons.Teams.Add"
    },
    "tenants": {
      "add": "Buttons.Tenants.Add"
    }
  },
  "calendar": {
    "deadline": {
      "form": {
        "date": {
          "text": "Calendar.Deadline.Form.Date.Text"
        },
        "deadlinekind": {
          "text": "Calendar.Deadline.Form.Deadlinekind.Text"
        },
        "documentpath": {
          "text": "Calendar.Deadline.Form.DocumentPath.Text"
        },
        "my": {
          "reminders": {
            "text": "Calendar.Deadline.Form.My.Reminders.Text"
          }
        },
        "owner": {
          "text": "Calendar.Deadline.Form.Owner.Text"
        },
        "project": {
          "text": "Calendar.Deadline.Form.Project.Text"
        },
        "reminders": {
          "text": "Calendar.Deadline.Form.Reminders.Text"
        }
      },
      "sheetmodal": {
        "title": {
          "text": "Calendar.Deadline.SheetModal.Title.Text"
        }
      }
    },
    "reminder": {
      "form": {
        "contractlink": {
          "text": "Calendar.Reminder.Form.ContractLink.Text"
        }
      },
      "save": {
        "failure": "Calendar.Reminder.Save.Failure",
        "success": "Calendar.Reminder.Save.Success"
      }
    },
    "update": {
      "reminder": {
        "button": {
          "update": {
            "label": "Calendar.Update.Reminder.Button.Update.Label"
          }
        }
      }
    },
    "week": {
      "title": {
        "label": "Calendar.Week.Title.Label"
      }
    }
  },
  "calendarview": {
    "calender": {
      "jul": {
        "label": "CalendarView.Calender.Jul.label"
      },
      "jun": {
        "label": "CalendarView.Calender.Jun.label"
      },
      "apr": {
        "label": "CalendarView.Calender.apr.label"
      },
      "aug": {
        "label": "CalendarView.Calender.aug.label"
      },
      "deb": {
        "label": "CalendarView.Calender.deb.label"
      },
      "feb": {
        "label": "CalendarView.Calender.feb.label"
      },
      "jan": {
        "label": "CalendarView.Calender.jan.Label"
      },
      "mar": {
        "label": "CalendarView.Calender.mar.label"
      },
      "may": {
        "label": "CalendarView.Calender.may.label"
      },
      "nov": {
        "label": "CalendarView.Calender.nov.label"
      },
      "oct": {
        "label": "CalendarView.Calender.oct.label"
      },
      "sep": {
        "label": "CalendarView.Calender.sep.label"
      }
    }
  },
  "campaign": {
    "confirmmodal": {
      "delete": {
        "popup": {
          "description": {
            "label": "Campaign.ConfirmModal.Delete.Popup.Description.Label"
          },
          "title": {
            "label": "Campaign.ConfirmModal.Delete.Popup.Title.Label"
          }
        }
      },
      "info": {
        "popup": {
          "description": {
            "label": "Campaign.ConfirmModal.Info.Popup.Description.Label"
          },
          "title": {
            "label": "Campaign.ConfirmModal.Info.Popup.Title.Label"
          }
        }
      },
      "launch": {
        "popup": {
          "description": {
            "label": "Campaign.ConfirmModal.Launch.Popup.Description.Label"
          },
          "title": {
            "label": "Campaign.ConfirmModal.Launch.Popup.Title.Label"
          }
        },
        "withwarning": {
          "information": {
            "label": "Campaign.ConfirmModal.Launch.WithWarning.Information.Label"
          },
          "popup": {
            "description": {
              "part1": {
                "label": "Campaign.ConfirmModal.Launch.WithWarning.Popup.Description.Part1.Label"
              },
              "part2": {
                "label": "Campaign.ConfirmModal.Launch.WithWarning.Popup.Description.Part2.Label"
              },
              "part3": {
                "label": "Campaign.ConfirmModal.Launch.WithWarning.Popup.Description.Part3.Label"
              }
            },
            "title": {
              "label": "Campaign.ConfirmModal.Launch.WithWarning.Popup.Title.Label"
            }
          }
        }
      }
    }
  },
  "campaigns": {
    "campaginmailmergeview": {
      "buttons": {
        "initializecampaign": {
          "label": "Campaigns.CampaginMailMergeView.Buttons.InitializeCampaign.Label"
        }
      }
    },
    "dialogs": {
      "create": {
        "mailmerge": {
          "title": "Campaigns.Dialogs.Create.Mailmerge.Title"
        },
        "title": "Campaigns.Dialogs.Create.Title"
      },
      "mailmerge": {
        "detail": {
          "choiceitem": "Campaigns.Dialogs.Mailmerge.Detail.ChoiceItem"
        },
        "recovery": {
          "selecteditem": {
            "text": "Campaigns.Dialogs.Mailmerge.Recovery.SelectedItem.Text"
          }
        }
      },
      "new": {
        "mailmerge": {
          "text": "Campaigns.Dialogs.New.MailMerge.Text",
          "title": "Campaigns.Dialogs.New.MailMerge.Title"
        }
      }
    },
    "fields": {
      "name": {
        "label": "Campaigns.Fields.Name.Label",
        "required": "Campaigns.Fields.Name.Required"
      },
      "year": {
        "invalid": "Campaigns.Fields.Year.Invalid",
        "label": "Campaigns.Fields.Year.Label",
        "required": "Campaigns.Fields.Year.Required"
      }
    },
    "item": {
      "buttons": {
        "launch": "Campaigns.Item.Buttons.Launch"
      },
      "information": "Campaigns.Item.Information",
      "negociated": {
        "label": "Campaigns.Item.Negociated.Label",
        "type": {
          "amendment": "Campaigns.Item.Negociated.Type.Amendment",
          "label": "Campaigns.Item.Negociated.Type.Label",
          "renegociation": "Campaigns.Item.Negociated.Type.Renegociation"
        }
      },
      "signed": {
        "label": "Campaigns.Item.Signed.Label"
      },
      "supporting": {
        "label": "Campaigns.Item.Supporting.Label"
      },
      "title": "Campaigns.Item.Title"
    },
    "list": {
      "buttons": {
        "add": "Campaigns.List.Buttons.Add"
      },
      "information": "Campaigns.List.Information",
      "label": "Campaigns.List.Label"
    },
    "mailmergeview": {
      "bulk": {
        "contracts": {
          "failure": "Campaigns.MailMergeView.Bulk.Contracts.Failure",
          "success": "Campaigns.MailMergeView.Bulk.Contracts.Success"
        }
      },
      "configsaving": {
        "choosefolder": {
          "description": {
            "part1": {
              "label": "Campaigns.MailMergeView.ConfigSaving.ChooseFolder.Description.Part1.Label"
            }
          }
        },
        "savingfolder": {
          "description": {
            "part1": {
              "label": "Campaigns.MailMergeView.ConfigSaving.SavingFolder.Description.Part1.Label"
            },
            "part2": {
              "label": "Campaigns.MailMergeView.ConfigSaving.SavingFolder.Description.Part2.Label"
            },
            "part3": {
              "label": "Campaigns.MailMergeView.ConfigSaving.SavingFolder.Description.Part3.Label"
            }
          }
        },
        "step3first": {
          "label": "Campaigns.MailMergeView.ConfigSaving.Step3First.Label"
        },
        "step3second": {
          "label": "Campaigns.MailMergeView.ConfigSaving.Step3Second.Label"
        },
        "stepssection": {
          "description": {
            "part1": {
              "label": "Campaigns.MailMergeView.ConfigSaving.StepsSection.Description.Part1.Label"
            },
            "part2": {
              "label": "Campaigns.MailMergeView.ConfigSaving.StepsSection.Description.Part2.Label"
            }
          }
        }
      },
      "fields": {
        "referential": {
          "description": "Campaigns.MailMergeView.Fields.Referential.Description",
          "label": "Campaigns.MailMergeView.Fields.Referential.Label"
        },
        "template": {
          "label": "Campaigns.MailMergeView.Fields.Template.Label"
        }
      },
      "get": {
        "naming": {
          "failure": "Campaigns.MailMergeView.Get.Naming.Failure",
          "success": "Campaigns.MailMergeView.Get.Naming.Success"
        }
      },
      "mappings": {
        "emptysteps": {
          "message": {
            "label": "Campaigns.MailMergeView.Mappings.EmptySteps.Message.Label"
          }
        },
        "fields": {
          "email": {
            "label": "Campaigns.MailMergeView.Mappings.Fields.Email.Label"
          },
          "firstname": {
            "label": "Campaigns.MailMergeView.Mappings.Fields.FirstName.Label"
          },
          "lastname": {
            "label": "Campaigns.MailMergeView.Mappings.Fields.LastName.Label"
          },
          "phonenumber": {
            "label": "Campaigns.MailMergeView.Mappings.Fields.PhoneNumber.Label"
          },
          "placeholder": "Campaigns.MailMergeView.Mappings.Fields.Placeholder"
        },
        "mappingssection": {
          "description": {
            "part1": {
              "label": "Campaigns.MailMergeView.Mappings.MappingsSection.Description.Part1.Label"
            },
            "part2": {
              "label": "Campaigns.MailMergeView.Mappings.MappingsSection.Description.Part2.Label"
            },
            "part3": {
              "label": "Campaigns.MailMergeView.Mappings.MappingsSection.Description.Part3.Label"
            }
          },
          "sendsignatureinfo": {
            "text": "Campaigns.MailMergeView.Mappings.MappingsSection.SendSignatureInfo.Text"
          }
        },
        "section": {
          "information": {
            "label": "Campaigns.MailMergeView.Mappings.Section.Information.Label"
          }
        },
        "step2first": {
          "label": "Campaigns.MailMergeView.Mappings.Step2First.Label"
        },
        "step2second": {
          "label": "Campaigns.MailMergeView.Mappings.Step2Second.Label"
        },
        "stepssection": {
          "description": {
            "part1": {
              "label": "Campaigns.MailMergeView.Mappings.StepsSection.Description.Part1.Label"
            },
            "part2": {
              "label": "Campaigns.MailMergeView.Mappings.StepsSection.Description.Part2.Label"
            }
          }
        }
      },
      "modal": {
        "button": {
          "create": {
            "label": "Campaigns.MailMergeView.Modal.Button.Create.Label"
          },
          "launchcampaign": {
            "label": "Campaigns.MailMergeView.Modal.Button.LaunchCampaign.Label"
          },
          "save": "Campaigns.MailMergeView.Modal.Button.Save",
          "validselecteditems": {
            "label": "Campaigns.MailMergeView.Modal.Button.ValidSelectedItems.Label"
          }
        },
        "buttons": {
          "cancel": "Campaigns.MailMergeView.Modal.Buttons.Cancel",
          "confirmlaunch": "Campaigns.MailMergeView.Modal.Buttons.ConfirmLaunch",
          "next": "Campaigns.MailMergeView.Modal.Buttons.Next"
        },
        "referential": {
          "step1first": {
            "label": "Campaigns.MailMergeView.Modal.Referential.Step1First.Label"
          },
          "step1second": {
            "label": "Campaigns.MailMergeView.Modal.Referential.Step1Second.Label"
          },
          "title": {
            "label": "Campaigns.MailMergeView.Modal.Referential.Title.Label"
          }
        }
      },
      "nomenclature": {
        "dynamicchoices": {
          "contractindex": {
            "label": "Campaigns.MailMergeView.Nomenclature.DynamicChoices.contractIndex.Label"
          }
        }
      },
      "save": {
        "failure": "Campaigns.MailMergeView.Save.Failure",
        "success": "Campaigns.MailMergeView.Save.Success"
      },
      "sendtosignature": {
        "checkbox": {
          "label": "Campaigns.MailMergeView.SendToSignature.Checkbox.Label"
        },
        "description": {
          "part1": {
            "label": "Campaigns.MailMergeView.SendToSignature.Description.Part1.Label"
          },
          "part2": {
            "label": "Campaigns.MailMergeView.SendToSignature.Description.Part2.Label"
          }
        },
        "nosignaturestep": {
          "label": "Campaigns.MailMergeView.SendToSignature.NoSignatureStep.Label"
        }
      }
    }
  },
  "completion": {
    "clausevariant": {
      "label": "Completion.ClauseVariant.Label"
    },
    "conditionoperator": {
      "label": "Completion.ConditionOperator.Label",
      "values": {
        "equal": "Completion.ConditionOperator.Values.Equal",
        "notequal": "Completion.ConditionOperator.Values.NotEqual"
      }
    },
    "conditionvalue": {
      "label": "Completion.ConditionValue.Label"
    },
    "contractfield": {
      "label": "Completion.ContractField.Label"
    },
    "data": {
      "label": "Completion.Data.Label"
    },
    "field": {
      "label": "Completion.Field.Label"
    },
    "item": {
      "type": {
        "label": "Completion.Item.Type.Label",
        "values": {
          "clausevariantchoice": "Completion.Item.Type.Values.ClauseVariantChoice",
          "contractfield": "Completion.Item.Type.Values.ContractField",
          "variablevalue": "Completion.Item.Type.Values.VariableValue"
        }
      },
      "warning": {
        "referentialelementpathnotfound": "Completion.Item.Warning.ReferentialElementPathNotFound"
      }
    },
    "items": {
      "buttons": {
        "add": {
          "label": "Completion.Items.Buttons.Add.Label"
        }
      },
      "label": "Completion.Items.Label"
    },
    "novariables": "Completion.NoVariables",
    "referential": {
      "label": "Completion.Referential.Label"
    },
    "settings": {
      "save": {
        "success": "Completion.Settings.Save.Success"
      },
      "title": "Completion.Settings.Title"
    },
    "unknownfield": {
      "label": "Completion.UnknownField.Label"
    }
  },
  "components": {
    "notavailable": {
      "text": "Components.NotAvailable.Text"
    },
    "search": {
      "placeholder": {
        "label": "Components.Search.Placeholder.Label"
      }
    },
    "viewer": {
      "error": {
        "defaultmessage": "Components.Viewer.Error.DefaultMessage"
      }
    }
  },
  "contract": {
    "annew": {
      "list": {
        "helper": {
          "description": "Contract.Annew.List.Helper.Description"
        }
      }
    },
    "annex": {
      "button": {
        "import": {
          "label": "Contract.Annex.Button.Import.Label"
        },
        "new": {
          "label": "Contract.Annex.Button.New.Label"
        }
      },
      "dialog": {
        "add": {
          "title": "Contract.Annex.Dialog.Add.Title"
        },
        "delete": {
          "explanation": "Contract.Annex.Dialog.Delete.Explanation",
          "title": "Contract.Annex.Dialog.Delete.Title",
          "warning": "Contract.Annex.Dialog.Delete.Warning"
        },
        "edit": {
          "title": "Contract.Annex.Dialog.Edit.Title"
        }
      },
      "list": {
        "button": {
          "add": "Contract.Annex.List.Button.Add"
        },
        "nodata": "Contract.Annex.List.NoData"
      },
      "toast": {
        "add": {
          "title": "Contract.Annex.Toast.Add.Title"
        },
        "delete": {
          "title": "Contract.Annex.Toast.Delete.Title"
        },
        "edit": {
          "title": "Contract.Annex.Toast.Edit.Title"
        },
        "reorder": {
          "title": "Contract.Annex.Toast.Reorder.Title"
        }
      }
    },
    "content": {
      "callexpert": {
        "error": "Contract.Content.CallExpert.Error",
        "success": "Contract.Content.CallExpert.Success"
      },
      "save": {
        "error": "Contract.Content.Save.Error",
        "success": "Contract.Content.Save.Success"
      }
    },
    "dialogs": {
      "delete": {
        "message": "Contract.Dialogs.Delete.Message",
        "title": "Contract.Dialogs.Delete.Title"
      },
      "signature": {
        "cancel": {
          "paragraph": "Contract.Dialogs.Signature.Cancel.Paragraph",
          "title": "Contract.Dialogs.Signature.Cancel.Title"
        },
        "send": {
          "paragraph": "Contract.Dialogs.Signature.Send.Paragraph",
          "title": "Contract.Dialogs.Signature.Send.Title"
        }
      }
    },
    "external": {
      "wrapper": {
        "label": "Contract.External.Wrapper.Label"
      }
    },
    "notes": {
      "dialog": {
        "delete": {
          "paragraph": "Contract.Notes.Dialog.Delete.Paragraph",
          "title": "Contract.Notes.Dialog.Delete.Title"
        }
      }
    },
    "pdf": {
      "preview": {
        "error": "Contract.PDF.Preview.Error"
      }
    },
    "signature": {
      "cancel": {
        "button": "Contract.Signature.Cancel.Button",
        "success": "Contract.Signature.Cancel.Success"
      },
      "send": {
        "button": "Contract.Signature.Send.Button",
        "success": "Contract.Signature.Send.Success"
      }
    },
    "version": {
      "access": {
        "warning": {
          "text": "Contract.Version.Access.Warning.Text"
        }
      },
      "add": {
        "success": "Contract.Version.Add.Success"
      },
      "button": {
        "add": {
          "label": "Contract.Version.Button.Add.Label"
        }
      },
      "default": {
        "label": "Contract.Version.Default.Label"
      },
      "dialog": {
        "add": {
          "title": "Contract.Version.Dialog.Add.Title"
        },
        "forms": {
          "name": {
            "label": "Contract.Version.Dialog.Forms.Name.Label"
          }
        },
        "restore": {
          "description": {
            "label": "Contract.Version.Dialog.Restore.Description.Label"
          },
          "title": "Contract.Version.Dialog.Restore.Title"
        }
      },
      "initial": {
        "label": "Contract.Version.Initial.Label"
      },
      "menu": {
        "compare": {
          "label": "Contract.Version.Menu.Compare.Label"
        },
        "download": {
          "label": "Contract.Version.Menu.Download.Label"
        },
        "restore": {
          "label": "Contract.Version.Menu.Restore.Label"
        }
      },
      "restore": {
        "success": "Contract.Version.Restore.Success"
      }
    },
    "workflow": {
      "buttons": {
        "complete": "Contract.Workflow.Buttons.Complete",
        "reject": "Contract.Workflow.Buttons.Reject"
      },
      "complete": {
        "success": "Contract.Workflow.Complete.Success"
      },
      "dialog": {
        "complete": {
          "explanation": "Contract.Workflow.Dialog.Complete.Explanation",
          "paragraph": "Contract.Workflow.Dialog.Complete.Paragraph",
          "title": "Contract.Workflow.Dialog.Complete.Title"
        },
        "invalidate": {
          "paragraph": "Contract.Workflow.Dialog.Invalidate.Paragraph",
          "title": "Contract.Workflow.Dialog.Invalidate.Title"
        },
        "reject": {
          "confirmation": {
            "people": "Contract.Workflow.Dialog.Reject.Confirmation.People",
            "question": "Contract.Workflow.Dialog.Reject.Confirmation.Question"
          },
          "explanation": "Contract.Workflow.Dialog.Reject.Explanation",
          "reason": {
            "description": "Contract.Workflow.Dialog.Reject.Reason.Description",
            "label": "Contract.Workflow.Dialog.Reject.Reason.Label",
            "placeholder": "Contract.Workflow.Dialog.Reject.Reason.Placeholder"
          },
          "title": "Contract.Workflow.Dialog.Reject.Title"
        },
        "validate": {
          "explanation": "Contract.Workflow.Dialog.Validate.Explanation",
          "paragraph": "Contract.Workflow.Dialog.Validate.Paragraph",
          "title": "Contract.Workflow.Dialog.Validate.Title"
        }
      },
      "invalidate": {
        "success": "Contract.Workflow.Invalidate.Success"
      },
      "reject": {
        "success": "Contract.Workflow.Reject.Success"
      },
      "validate": {
        "success": "Contract.Workflow.Validate.Success"
      }
    }
  },
  "contributor": {
    "pickers": {
      "type": {
        "0": {
          "label": "Contributor.Pickers.Type.0.Label"
        },
        "1": {
          "label": "Contributor.Pickers.Type.1.Label"
        },
        "2": {
          "label": "Contributor.Pickers.Type.2.Label"
        }
      }
    }
  },
  "dashboard": {
    "button": {
      "new": {
        "label": "Dashboard.Button.New.Label"
      },
      "sendtosignature": {
        "label": "Dashboard.Button.SendToSignature.Label"
      }
    },
    "campaigns": {
      "campaignsview": {
        "expertswitch": {
          "allsuppliers": "Dashboard.Campaigns.CampaignsView.ExpertSwitch.AllSuppliers",
          "clausestobevalidated": "Dashboard.Campaigns.CampaignsView.ExpertSwitch.ClausesToBeValidated"
        },
        "select": {
          "department": "Dashboard.Campaigns.CampaignsView.Select.Department",
          "options": {
            "alldepartments": "Dashboard.Campaigns.CampaignsView.Select.Options.AllDepartments",
            "allmembers": "Dashboard.Campaigns.CampaignsView.Select.Options.AllMembers"
          },
          "user": "Dashboard.Campaigns.CampaignsView.Select.User"
        }
      },
      "folders": {
        "label": "Dashboard.Campaigns.Folders.Label"
      },
      "noresult": "Dashboard.Campaigns.NoResult"
    },
    "currentnegotiations": {
      "empty": {
        "text": "Dashboard.CurrentNegotiations.Empty.Text"
      },
      "title": "Dashboard.CurrentNegotiations.Title"
    },
    "deadline": {
      "remindersheetmodal": {
        "export": {
          "title": {
            "text": "Dashboard.Deadline.ReminderSheetModal.Export.Title.Text"
          }
        },
        "title": {
          "text": "Dashboard.Deadline.ReminderSheetModal.Title.Text"
        }
      }
    },
    "folder": {
      "new": {
        "label": "Dashboard.Folder.New.Label"
      },
      "rename": {
        "label": "Dashboard.Folder.Rename.Label"
      }
    },
    "lastconsulted": {
      "columns": {
        "lastconsultedfolders": "Dashboard.LastConsulted.Columns.LastConsultedFolders",
        "lastmodificationdate": "Dashboard.LastConsulted.Columns.LastModificationDate",
        "owner": "Dashboard.LastConsulted.Columns.Owner"
      },
      "empty": {
        "text": "Dashboard.LastConsulted.Empty.Text"
      }
    },
    "leader": {
      "member": {
        "filescount": "Dashboard.Leader.Member.FilesCount"
      },
      "order": {
        "name": "Dashboard.Leader.Order.Name",
        "progress": "Dashboard.Leader.Order.Progress"
      },
      "totalgrowth": "Dashboard.Leader.TotalGrowth"
    },
    "new": {
      "template": {
        "label": "Dashboard.New.Template.Label"
      }
    },
    "news": {
      "nodata": {
        "label": "Dashboard.News.NoData.Label"
      },
      "notifications": {
        "title": {
          "label": "Dashboard.News.Notifications.Title.Label"
        }
      },
      "read": {
        "button": {
          "label": "Dashboard.News.Read.Button.Label"
        }
      },
      "switch": {
        "all": {
          "label": "Dashboard.News.Switch.All.Label"
        },
        "unread": {
          "label": "Dashboard.News.Switch.Unread.Label"
        }
      },
      "title": {
        "label": "Dashboard.News.Title.Label"
      },
      "unread": {
        "button": {
          "label": "Dashboard.News.Unread.Button.Label"
        }
      }
    },
    "nextdeadlines": {
      "empty": {
        "text": "Dashboard.NextDeadlines.Empty.Text"
      },
      "title": "Dashboard.NextDeadlines.Title"
    },
    "recycle": {
      "bin": {
        "label": "Dashboard.Recycle.Bin.Label"
      }
    },
    "reminder": {
      "about": {
        "text": {
          "label": "Dashboard.Reminder.About.Text.Label"
        }
      },
      "modal": {
        "by": "Dashboard.Reminder.Modal.By",
        "importance": {
          "label": "Dashboard.Reminder.Modal.Importance.Label"
        },
        "remindertype": {
          "after": "Dashboard.Reminder.Modal.ReminderType.After",
          "before": "Dashboard.Reminder.Modal.ReminderType.Before"
        },
        "sharethereminder": {
          "label": "Dashboard.Reminder.Modal.ShareTheReminder.Label"
        },
        "text": {
          "label": "Dashboard.Reminder.Modal.Text.Label"
        },
        "textarea": {
          "label": "Dashboard.Reminder.Modal.TextArea.Label"
        },
        "the": "Dashboard.Reminder.Modal.The"
      }
    },
    "remindermodal": {
      "form": {
        "date": {
          "label": "Dashboard.ReminderModal.Form.Date.Label"
        }
      }
    },
    "search": {
      "breadcrumb": {
        "label": "Dashboard.Search.Breadcrumb.Label"
      }
    },
    "supplier": {
      "experts": {
        "save": {
          "success": "Dashboard.Supplier.Experts.Save.Success"
        }
      }
    },
    "suppliers": {
      "experts": {
        "selectexpertview": {
          "alerts": {
            "information": {
              "description": "Dashboard.Suppliers.Experts.SelectExpertView.Alerts.Information.Description",
              "title": "Dashboard.Suppliers.Experts.SelectExpertView.Alerts.Information.Title"
            }
          },
          "expert": {
            "label": "Dashboard.Suppliers.Experts.SelectExpertView.Expert.Label"
          },
          "header": {
            "affecteddepartment": "Dashboard.Suppliers.Experts.SelectExpertView.Header.AffectedDepartment",
            "fiscalid": "Dashboard.Suppliers.Experts.SelectExpertView.Header.FiscalID",
            "idgha": "Dashboard.Suppliers.Experts.SelectExpertView.Header.IdGHA",
            "partnerid": "Dashboard.Suppliers.Experts.SelectExpertView.Header.PartnerID",
            "productmanager": "Dashboard.Suppliers.Experts.SelectExpertView.Header.ProductManager"
          },
          "noavailableexperts": "Dashboard.Suppliers.Experts.SelectExpertView.NoAvailableExperts",
          "nobody": "Dashboard.Suppliers.Experts.SelectExpertView.Nobody",
          "title": "Dashboard.Suppliers.Experts.SelectExpertView.Title"
        }
      },
      "negociated": {
        "buttons": {
          "add": {
            "label": "Dashboard.Suppliers.Negociated.Buttons.Add.Label"
          }
        },
        "dialog": {
          "renegociate": {
            "paragraph": "Dashboard.Suppliers.Negociated.Dialog.Renegociate.Paragraph",
            "title": "Dashboard.Suppliers.Negociated.Dialog.Renegociate.Title"
          }
        },
        "dialogs": {
          "create": {
            "title": "Dashboard.Suppliers.Negociated.Dialogs.Create.Title"
          },
          "delete": {
            "message": "Dashboard.Suppliers.Negociated.Dialogs.Delete.Message",
            "title": "Dashboard.Suppliers.Negociated.Dialogs.Delete.Title"
          },
          "update": {
            "title": "Dashboard.Suppliers.Negociated.Dialogs.Update.Title"
          },
          "view": {
            "title": "Dashboard.Suppliers.Negociated.Dialogs.View.Title"
          }
        },
        "fields": {
          "name": {
            "label": "Dashboard.Suppliers.Negociated.Fields.Name.Label"
          },
          "template": {
            "label": "Dashboard.Suppliers.Negociated.Fields.Template.Label"
          }
        },
        "label": "Dashboard.Suppliers.Negociated.Label",
        "renegociationtype": {
          "amendment": {
            "label": "Dashboard.Suppliers.Negociated.RenegociationType.Amendment.Label"
          },
          "label": "Dashboard.Suppliers.Negociated.RenegociationType.Label",
          "template": {
            "label": "Dashboard.Suppliers.Negociated.RenegociationType.Template.Label"
          }
        }
      },
      "negotiated": {
        "negotiatedviews": {
          "alerts": {
            "error": {
              "title": "Dashboard.Suppliers.Negotiated.NegotiatedViews.Alerts.Error.Title"
            }
          }
        }
      },
      "noresult": "Dashboard.Suppliers.NoResult",
      "signed": {
        "buttons": {
          "add": {
            "label": "Dashboard.Suppliers.Signed.Buttons.Add.Label"
          }
        },
        "dialogs": {
          "create": {
            "title": "Dashboard.Suppliers.Signed.Dialogs.Create.Title"
          },
          "delete": {
            "message": "Dashboard.Suppliers.Signed.Dialogs.Delete.Message",
            "title": "Dashboard.Suppliers.Signed.Dialogs.Delete.Title"
          },
          "update": {
            "title": "Dashboard.Suppliers.Signed.Dialogs.Update.Title"
          },
          "view": {
            "title": "Dashboard.Suppliers.Signed.Dialogs.View.Title"
          }
        },
        "fields": {
          "file": {
            "label": "Dashboard.Suppliers.Signed.Fields.File.Label"
          },
          "name": {
            "label": "Dashboard.Suppliers.Signed.Fields.Name.Label"
          }
        },
        "label": "Dashboard.Suppliers.Signed.Label"
      },
      "supporting": {
        "buttons": {
          "create": {
            "label": "Dashboard.Suppliers.Supporting.Buttons.Create.Label"
          }
        },
        "dialogs": {
          "create": {
            "title": "Dashboard.Suppliers.Supporting.Dialogs.Create.Title"
          },
          "delete": {
            "message": "Dashboard.Suppliers.Supporting.Dialogs.Delete.Message",
            "title": "Dashboard.Suppliers.Supporting.Dialogs.Delete.Title"
          },
          "update": {
            "title": "Dashboard.Suppliers.Supporting.Dialogs.Update.Title"
          },
          "view": {
            "title": "Dashboard.Suppliers.Supporting.Dialogs.View.Title"
          }
        },
        "document": {
          "dialogs": {
            "create": {
              "title": "Dashboard.Suppliers.Supporting.Document.Dialogs.Create.Title"
            },
            "delete": {
              "message": "Dashboard.Suppliers.Supporting.Document.Dialogs.Delete.Message",
              "title": "Dashboard.Suppliers.Supporting.Document.Dialogs.Delete.Title"
            }
          },
          "fields": {
            "file": {
              "label": "Dashboard.Suppliers.Supporting.Document.Fields.File.Label"
            }
          }
        },
        "fields": {
          "name": {
            "label": "Dashboard.Suppliers.Supporting.Fields.Name.Label"
          }
        },
        "label": "Dashboard.Suppliers.Supporting.Label"
      },
      "tabs": {
        "contracts": "Dashboard.Suppliers.Tabs.Contracts",
        "expertteam": "Dashboard.Suppliers.Tabs.ExpertTeam"
      }
    }
  },
  "deadlinesheetmodal": {
    "popup": {
      "description": {
        "label": "DeadlineSheetModal.Popup.Description.Label"
      },
      "title": {
        "actions": {
          "exporttoagenda": {
            "label": "DeadlineSheetModal.Popup.Title.Actions.ExportToAgenda.Label"
          }
        },
        "label": "DeadlineSheetModal.Popup.Title.Label"
      }
    }
  },
  "department": {
    "buttons": {
      "add": "Department.Buttons.Add"
    },
    "dialogs": {
      "create": {
        "title": "Department.Dialogs.Create.Title"
      },
      "delete": {
        "message": "Department.Dialogs.Delete.Message",
        "title": "Department.Dialogs.Delete.Title"
      },
      "update": {
        "title": "Department.Dialogs.Update.Title"
      }
    },
    "fields": {
      "externalid": {
        "label": "Department.Fields.ExternalId.Label",
        "required": "Department.Fields.ExternalId.Required"
      },
      "name": {
        "label": "Department.Fields.Name.Label",
        "required": "Department.Fields.Name.Required"
      },
      "notificationmode": {
        "expert": "Department.Fields.NotificationMode.Expert",
        "label": "Department.Fields.NotificationMode.Label",
        "projectmanager": "Department.Fields.NotificationMode.ProjectManager",
        "required": "Department.Fields.NotificationMode.Required"
      }
    },
    "noresult": "Department.NoResult"
  },
  "dialogs": {
    "teams": {
      "add": {
        "title": "Dialogs.Teams.Add.Title"
      },
      "delete": {
        "message": "Dialogs.Teams.Delete.Message",
        "title": "Dialogs.Teams.Delete.Title"
      },
      "update": {
        "title": "Dialogs.Teams.Update.Title"
      }
    },
    "tenants": {
      "add": {
        "title": "Dialogs.Tenants.Add.Title"
      },
      "update": {
        "title": "Dialogs.Tenants.Update.Title"
      }
    }
  },
  "document": {
    "buttons": {
      "show": {
        "all": "Document.Buttons.Show.All",
        "less": "Document.Buttons.Show.Less"
      }
    },
    "card": {
      "amendment": "Document.Card.Amendment",
      "createdon": "Document.Card.CreatedOn",
      "editedon": "Document.Card.EditedOn",
      "lastreminder": "Document.Card.LastReminder",
      "negotiationtype": "Document.Card.NegotiationType",
      "previousversions": "Document.Card.PreviousVersions",
      "abandoned": "Document.Card.abandoned",
      "finished": "Document.Card.finished",
      "pending": "Document.Card.pending",
      "rejected": "Document.Card.rejected",
      "signed": "Document.Card.signed",
      "signing": "Document.Card.signing"
    },
    "fields": {
      "status": {
        "0": "Document.Fields.Status.0",
        "1": "Document.Fields.Status.1",
        "2": "Document.Fields.Status.2",
        "3": "Document.Fields.Status.3",
        "4": "Document.Fields.Status.4",
        "5": "Document.Fields.Status.5",
        "6": "Document.Fields.Status.6",
        "7": "Document.Fields.Status.7",
        "unknown": "Document.Fields.Status.Unknown"
      }
    },
    "table": {
      "header": {
        "createdon": "Document.Table.Header.CreatedOn",
        "department": "Document.Table.Header.Department",
        "editedon": "Document.Table.Header.EditedOn",
        "id": "Document.Table.Header.Id",
        "productmanager": "Document.Table.Header.ProductManager"
      }
    }
  },
  "driveview": {
    "adddocument": {
      "buttons": {
        "blank": {
          "label": "DriveView.AddDocument.Buttons.Blank.Label"
        },
        "import": {
          "label": "DriveView.AddDocument.Buttons.Import.Label"
        }
      }
    },
    "addfolder": {
      "buttons": {
        "import": {
          "label": "DriveView.AddFolder.Buttons.Import.Label"
        },
        "new": {
          "label": "DriveView.AddFolder.Buttons.New.Label"
        }
      }
    },
    "buttons": {
      "adddocument": {
        "label": "DriveView.Buttons.AddDocument.Label"
      },
      "addfolder": {
        "label": "DriveView.Buttons.AddFolder.Label"
      },
      "addmailmerge": {
        "label": "DriveView.Buttons.AddMailMerge.Label"
      },
      "duplicate": {
        "label": "DriveView.Buttons.Duplicate.Label"
      },
      "new": {
        "label": "DriveView.Buttons.New.Label"
      },
      "remindsigner": {
        "label": "DriveView.Buttons.RemindSigner.Label"
      }
    },
    "columns": {
      "lastmodification": {
        "text": "DriveView.Columns.LastModification.Text"
      },
      "name": {
        "text": "DriveView.Columns.Name.Text"
      },
      "owner": {
        "text": "DriveView.Columns.Owner.Text"
      },
      "progress": {
        "text": "DriveView.Columns.Progress.Text"
      },
      "status": {
        "text": "DriveView.Columns.Status.Text"
      }
    },
    "deletetermsheetelement": {
      "buttons": {
        "delete": {
          "label": "DriveView.DeleteTermSheetElement.Buttons.Delete.Label"
        }
      },
      "description": {
        "text": "DriveView.DeleteTermSheetElement.Description.Text"
      }
    },
    "dialogs": {
      "createfolder": {
        "fields": {
          "isconfidential": {
            "label": "DriveView.Dialogs.CreateFolder.Fields.IsConfidential.Label",
            "tooltip": "DriveView.Dialogs.CreateFolder.Fields.IsConfidential.Tooltip"
          },
          "iscontentfrozen": {
            "label": "DriveView.Dialogs.CreateFolder.Fields.IsContentFrozen.Label"
          },
          "isfrozen": {
            "label": "DriveView.Dialogs.CreateFolder.Fields.IsFrozen.Label"
          },
          "name": {
            "label": "DriveView.Dialogs.CreateFolder.Fields.Name.Label"
          }
        }
      },
      "deletedocument": {
        "explanation": "DriveView.Dialogs.DeleteDocument.Explanation",
        "title": "DriveView.Dialogs.DeleteDocument.Title",
        "warning": "DriveView.Dialogs.DeleteDocument.Warning"
      },
      "deletedocumentfolder": {
        "explanation": "DriveView.Dialogs.DeleteDocumentFolder.Explanation",
        "title": "DriveView.Dialogs.DeleteDocumentFolder.Title",
        "warning": "DriveView.Dialogs.DeleteDocumentFolder.Warning"
      },
      "edittermsheetelement": {
        "title": "DriveView.Dialogs.EditTermSheetElement.Title"
      },
      "edittermsheettemplate": {
        "button": "DriveView.Dialogs.EditTermSheetTemplate.Button",
        "input": {
          "label": "DriveView.Dialogs.EditTermSheetTemplate.Input.Label"
        },
        "title": "DriveView.Dialogs.EditTermSheetTemplate.Title"
      },
      "editworkflowtemplate": {
        "input": {
          "label": "DriveView.Dialogs.EditWorkflowTemplate.Input.Label"
        }
      },
      "importfolder": {
        "fields": {
          "file": {
            "label": "DriveView.Dialogs.ImportFolder.Fields.File.Label"
          }
        },
        "title": "DriveView.Dialogs.ImportFolder.Title"
      },
      "newfolder": {
        "title": "DriveView.Dialogs.NewFolder.Title"
      },
      "newtermsheetelement": {
        "title": "DriveView.Dialogs.NewTermSheetElement.Title"
      },
      "deletetermsheetelement": {
        "title": "Driveview.Dialogs.DeleteTermSheetElement.Title"
      }
    },
    "documentlinks": {
      "button": {
        "add": "DriveView.DocumentLinks.Button.Add",
        "update": "DriveView.DocumentLinks.Button.Update"
      },
      "dialogs": {
        "annexwarning": "DriveView.DocumentLinks.Dialogs.AnnexWarning",
        "button": {
          "add": "DriveView.DocumentLinks.Dialogs.Button.Add",
          "save": "DriveView.DocumentLinks.Dialogs.Button.Save"
        },
        "input": {
          "placeholder": "DriveView.DocumentLinks.Dialogs.Input.Placeholder"
        },
        "subtitle": "DriveView.DocumentLinks.Dialogs.Subtitle",
        "title": "DriveView.DocumentLinks.Dialogs.Title"
      },
      "documenttypename": {
        "amendment": "DriveView.DocumentLinks.DocumentTypeName.Amendment",
        "annex": "DriveView.DocumentLinks.DocumentTypeName.Annex",
        "applicationagreement": "DriveView.DocumentLinks.DocumentTypeName.ApplicationAgreement",
        "linkeddocument": "DriveView.DocumentLinks.DocumentTypeName.LinkedDocument",
        "maincontract": "DriveView.DocumentLinks.DocumentTypeName.MainContract",
        "masteragreement": "DriveView.DocumentLinks.DocumentTypeName.MasterAgreement",
        "originalcontract": "DriveView.DocumentLinks.DocumentTypeName.OriginalContract"
      },
      "label": "DriveView.DocumentLinks.Label",
      "type": {
        "choose": "DriveView.DocumentLinks.Type.Choose",
        "hasforamendment": "DriveView.DocumentLinks.Type.HasForAmendment",
        "hasforannex": "DriveView.DocumentLinks.Type.HasForAnnex",
        "hasformasteragreement": "DriveView.DocumentLinks.Type.HasForMasterAgreement",
        "isamendmentof": "DriveView.DocumentLinks.Type.IsAmendmentOf",
        "isannexof": "DriveView.DocumentLinks.Type.IsAnnexOf",
        "islinkedto": "DriveView.DocumentLinks.Type.IsLinkedTo",
        "ismasteragreementof": "DriveView.DocumentLinks.Type.IsMasterAgreementOf"
      }
    },
    "import": {
      "actions": {
        "tocomment": {
          "label": "DriveView.Import.Actions.ToComment.Label"
        },
        "toreview": {
          "label": "DriveView.Import.Actions.ToReview.Label"
        },
        "tosign": {
          "label": "DriveView.Import.Actions.ToSign.Label"
        }
      },
      "contract": {
        "hasstate": {
          "message": {
            "error": "DriveView.Import.Contract.HasState.Message.Error"
          }
        },
        "message": {
          "success": "DriveView.Import.Contract.Message.Success"
        }
      },
      "document": {
        "docx": {
          "message": {
            "error": "DriveView.Import.Document.Docx.Message.Error"
          }
        },
        "message": {
          "success": "DriveView.Import.Document.Message.Success"
        }
      },
      "field": {
        "name": {
          "label": "DriveView.Import.Field.Name.Label"
        },
        "signed": {
          "date": {
            "label": "DriveView.Import.Field.Signed.Date.Label"
          }
        },
        "validated": {
          "date": {
            "label": "DriveView.Import.Field.Validated.Date.Label"
          }
        }
      },
      "type": {
        "actions": {
          "label": "DriveView.Import.Type.Actions.Label"
        },
        "default": {
          "workflow": {
            "label": "DriveView.Import.Type.Default.WorkFlow.Label"
          }
        },
        "document": {
          "statut": {
            "label": "DriveView.Import.Type.Document.Statut.Label"
          },
          "workflow": {
            "label": "DriveView.Import.Type.Document.WorkFlow.Label"
          }
        },
        "fichecontrat": {
          "associate": {
            "label": "DriveView.Import.Type.FicheContrat.Associate.Label"
          },
          "completion": {
            "label": "DriveView.Import.Type.FicheContrat.Completion.Label"
          }
        },
        "finished": {
          "label": "DriveView.Import.Type.Finished.Label"
        },
        "no": {
          "status": {
            "label": "DriveView.Import.Type.No.Status.Label"
          }
        },
        "pending": {
          "label": "DriveView.Import.Type.Pending.Label"
        },
        "signed": {
          "label": "DriveView.Import.Type.Signed.Label"
        },
        "specific": {
          "workflow": {
            "label": "DriveView.Import.Type.Specific.WorkFlow.Label"
          }
        },
        "toanalysebyia": {
          "label": "DriveView.Import.Type.ToAnalyseByIa.Label",
          "tooltips": {
            "nofichecontrat": {
              "label": "DriveView.Import.Type.ToAnalyseByIa.Tooltips.NoFicheContrat.Label"
            },
            "wrongdocument": {
              "label": "DriveView.Import.Type.ToAnalyseByIa.Tooltips.WrongDocument.Label"
            }
          }
        }
      }
    },
    "modal": {
      "importdocument": {
        "button": {
          "import": "DriveView.Modal.ImportDocument.Button.Import"
        },
        "title": "DriveView.Modal.ImportDocument.Title"
      },
      "remindsigner": {
        "text": "DriveView.Modal.RemindSigner.Text",
        "title": "DriveView.Modal.RemindSigner.Title"
      }
    },
    "search": {
      "exportresults": {
        "error": "DriveView.Search.ExportResults.Error",
        "tooltip": "DriveView.Search.ExportResults.Tooltip"
      }
    },
    "share": {
      "modal": {
        "document": {
          "users": {
            "title": "DriveView.Share.Modal.Document.Users.Title"
          }
        },
        "folder": {
          "users": {
            "title": "DriveView.Share.Modal.Folder.Users.Title"
          }
        },
        "removerights": "DriveView.Share.Modal.RemoveRights",
        "rights": {
          "contributor": "DriveView.Share.Modal.Rights.Contributor",
          "owner": "DriveView.Share.Modal.Rights.Owner",
          "readandwrite": "DriveView.Share.Modal.Rights.ReadAndWrite",
          "readonly": "DriveView.Share.Modal.Rights.ReadOnly",
          "visible": "DriveView.Share.Modal.Rights.Visible"
        },
        "save": {
          "error": "DriveView.Share.Modal.Save.Error",
          "success": "DriveView.Share.Modal.Save.Success"
        },
        "search": {
          "placeholder": "DriveView.Share.Modal.Search.PlaceHolder"
        },
        "type": {
          "access": {
            "title": "DriveView.Share.Modal.Type.Access.Title"
          }
        }
      }
    },
    "termsheet": {
      "modal": {
        "export": {
          "message": "DriveView.Termsheet.Modal.Export.Message",
          "title": "DriveView.Termsheet.Modal.Export.Title"
        }
      },
      "analyse": {
        "ai": "Driveview.TermSheet.Analyse.AI",
        "dataprocessing": "Driveview.TermSheet.Analyse.DataProcessing",
        "formatnotsupported": "Driveview.TermSheet.Analyse.FormatNotSupported",
        "noelementstoextract": "Driveview.TermSheet.Analyse.NoElementsToExtract",
        "status": {
          "treatmentcancelled": "Driveview.TermSheet.Analyse.Status.TreatmentCancelled",
          "treatmentfailed": "Driveview.TermSheet.Analyse.Status.TreatmentFailed",
          "treatmentinprogress": "Driveview.TermSheet.Analyse.Status.TreatmentInProgress",
          "treatmentpending": "Driveview.TermSheet.Analyse.Status.TreatmentPending",
          "treatmentsuccessful": "Driveview.TermSheet.Analyse.Status.TreatmentSuccessful"
        }
      },
      "buttons": {
        "add": {
          "label": "Driveview.TermSheet.Buttons.Add.Label"
        },
        "export": {
          "label": "Driveview.TermSheet.Buttons.Export.Label"
        }
      },
      "elements": "Driveview.TermSheet.Elements",
      "template": {
        "associatedtemplate": "Driveview.TermSheet.Template.AssociatedTemplate",
        "noassociatedtemplate": "Driveview.TermSheet.Template.NoAssociatedTemplate"
      }
    },
    "trash": {
      "label": "DriveView.Trash.Label"
    },
    "upload": {
      "file": {
        "modal": {
          "label": "DriveView.Upload.File.Modal.Label"
        }
      }
    },
    "reminder": {
      "buttons": {
        "add": {
          "label": "Driveview.Reminder.Buttons.Add.Label"
        }
      },
      "day": {
        "after": "Driveview.Reminder.Day.After",
        "before": "Driveview.Reminder.Day.Before"
      },
      "month": {
        "after": "Driveview.Reminder.Month.After",
        "before": "Driveview.Reminder.Month.Before"
      },
      "week": {
        "after": "Driveview.Reminder.Week.After",
        "before": "Driveview.Reminder.Week.Before"
      }
    }
  },
  "editor": {
    "clause": {
      "fields": {
        "explanations": {
          "label": "Editor.Clause.Fields.Explanations.Label",
          "placeholder": "Editor.Clause.Fields.Explanations.Placeholder"
        },
        "faq": {
          "content": {
            "label": "Editor.Clause.Fields.Faq.Content.Label"
          },
          "expertise": {
            "label": "Editor.Clause.Fields.Faq.Expertise.Label"
          },
          "language": {
            "label": "Editor.Clause.Fields.Faq.Language.Label"
          }
        },
        "mandatory": {
          "label": "Editor.Clause.Fields.Mandatory.Label"
        },
        "name": {
          "label": "Editor.Clause.Fields.Name.Label",
          "required": "Editor.Clause.Fields.Name.Required"
        },
        "question": {
          "label": "Editor.Clause.Fields.Question.Label",
          "required": "Editor.Clause.Fields.Question.Required"
        },
        "recursive": {
          "label": "Editor.Clause.Fields.Recursive.Label"
        },
        "type": {
          "label": "Editor.Clause.Fields.Type.Label",
          "mcq": {
            "label": "Editor.Clause.Fields.Type.Mcq.Label"
          },
          "scq": {
            "label": "Editor.Clause.Fields.Type.Scq.Label"
          }
        }
      }
    },
    "comment": {
      "message": {
        "deleted": "Editor.Comment.Message.Deleted",
        "label": "Editor.Comment.Message.Label",
        "placeholder": "Editor.Comment.Message.Placeholder"
      }
    },
    "condition": {
      "manage": {
        "label": "Editor.Condition.Manage.Label"
      }
    },
    "dialog": {
      "add": {
        "variable": {
          "linked": {
            "title": "Editor.Dialog.Add.Variable.Linked.Title"
          }
        }
      },
      "delete": {
        "clause": {
          "content": "Editor.Dialog.Delete.Clause.Content",
          "qdata": {
            "content": "Editor.Dialog.Delete.Clause.Qdata.Content"
          },
          "title": "Editor.Dialog.Delete.Clause.Title"
        },
        "variable": {
          "content": "Editor.Dialog.Delete.Variable.Content",
          "title": "Editor.Dialog.Delete.Variable.Title"
        },
        "variant": {
          "content": "Editor.Dialog.Delete.Variant.Content",
          "title": "Editor.Dialog.Delete.Variant.Title"
        }
      }
    },
    "image": {
      "fromcomputer": {
        "label": "Editor.Image.FromComputer.Label"
      },
      "frominternet": {
        "invalidformat": "Editor.Image.FromInternet.InvalidFormat",
        "label": "Editor.Image.FromInternet.Label",
        "required": "Editor.Image.FromInternet.Required"
      },
      "invalidformat": "Editor.Image.InvalidFormat"
    },
    "negotiation": {
      "bot": {
        "messages": {
          "clause": {
            "invalidated": "Editor.Negotiation.Bot.Messages.Clause.Invalidated",
            "modified": "Editor.Negotiation.Bot.Messages.Clause.Modified",
            "validated": "Editor.Negotiation.Bot.Messages.Clause.Validated"
          }
        }
      },
      "edit": {
        "clause": "Editor.Negotiation.Edit.Clause"
      },
      "faq": {
        "delete": "Editor.Negotiation.FAQ.Delete"
      },
      "see": {
        "messages": "Editor.Negotiation.See.Messages"
      }
    },
    "notebook": {
      "save": {
        "success": "Editor.Notebook.Save.Success"
      }
    },
    "numbering": {
      "list": {
        "continue": "Editor.Numbering.List.Continue",
        "new": "Editor.Numbering.List.New"
      },
      "multilevel": {
        "disable": "Editor.Numbering.Multilevel.Disable",
        "enable": "Editor.Numbering.Multilevel.Enable"
      },
      "prefix": {
        "label": "Editor.Numbering.Prefix.Label",
        "suffix": {
          "set": {
            "label": "Editor.Numbering.Prefix.Suffix.Set.Label"
          }
        }
      },
      "set": {
        "value": "Editor.Numbering.Set.Value"
      },
      "suffix": {
        "label": "Editor.Numbering.Suffix.Label",
        "list": {
          "label": "Editor.Numbering.Suffix.List.Label"
        }
      },
      "type": {
        "alpha": {
          "lower": "Editor.Numbering.Type.Alpha.Lower",
          "upper": "Editor.Numbering.Type.Alpha.Upper"
        },
        "article": "Editor.Numbering.Type.Article",
        "label": "Editor.Numbering.Type.Label",
        "none": "Editor.Numbering.Type.None",
        "numerical": "Editor.Numbering.Type.Numerical",
        "roman": {
          "lower": "Editor.Numbering.Type.Roman.Lower",
          "upper": "Editor.Numbering.Type.Roman.Upper"
        }
      }
    },
    "page": {
      "break": {
        "insert": "Editor.Page.Break.Insert",
        "label": "Editor.Page.Break.Label"
      }
    },
    "save": {
      "failure": "Editor.Save.Failure"
    },
    "section": {
      "options": {
        "label": "Editor.Section.Options.Label"
      },
      "page": {
        "close": {
          "label": "Editor.Section.Page.Close.Label"
        },
        "even": {
          "label": "Editor.Section.Page.Even.Label",
          "odd": {
            "different": {
              "label": "Editor.Section.Page.Even.Odd.Different.Label"
            }
          }
        },
        "first": {
          "different": {
            "label": "Editor.Section.Page.First.Different.Label"
          },
          "even": {
            "odd": {
              "different": {
                "label": "Editor.Section.Page.First.Even.Odd.Different.Label"
              }
            }
          },
          "label": "Editor.Section.Page.First.Label"
        },
        "headers": {
          "options": {
            "label": "Editor.Section.Page.Headers.Options.Label"
          }
        },
        "number": {
          "label": "Editor.Section.Page.Number.Label",
          "toggle": {
            "label": "Editor.Section.Page.Number.Toggle.Label"
          }
        },
        "odd": {
          "label": "Editor.Section.Page.Odd.Label"
        },
        "other": {
          "label": "Editor.Section.Page.Other.Label"
        }
      },
      "same": {
        "label": "Editor.Section.Same.Label"
      }
    },
    "signature": {
      "addslot": {
        "label": "Editor.Signature.AddSlot.Label"
      },
      "button": {
        "add": {
          "checkbox": "Editor.Signature.Button.Add.Checkbox",
          "date": "Editor.Signature.Button.Add.Date",
          "firstname": "Editor.Signature.Button.Add.FirstName",
          "freetext": "Editor.Signature.Button.Add.FreeText",
          "initials": "Editor.Signature.Button.Add.Initials",
          "lastname": "Editor.Signature.Button.Add.LastName",
          "signature": "Editor.Signature.Button.Add.Signature"
        },
        "remove": {
          "slot": "Editor.Signature.Button.Remove.Slot"
        }
      },
      "checkbox": {
        "group": {
          "name": "Editor.Signature.Checkbox.Group.Name"
        },
        "label": "Editor.Signature.Checkbox.Label",
        "linked": "Editor.Signature.Checkbox.Linked",
        "max": {
          "allowed": "Editor.Signature.Checkbox.Max.Allowed"
        },
        "min": {
          "required": "Editor.Signature.Checkbox.Min.Required"
        }
      },
      "freetext": {
        "label": "Editor.Signature.FreeText.Label",
        "rows": {
          "label": "Editor.Signature.FreeText.Rows.Label"
        },
        "tooltiptext": {
          "label": "Editor.Signature.FreeText.TooltipText.Label"
        }
      },
      "orientation": {
        "description": "Editor.Signature.Orientation.Description",
        "label": "Editor.Signature.Orientation.Label"
      },
      "role": {
        "target": {
          "description": "Editor.Signature.Role.Target.Description",
          "label": "Editor.Signature.Role.Target.Label"
        }
      },
      "settings": {
        "label": "Editor.Signature.Settings.Label"
      },
      "slot": {
        "element": {
          "on": {
            "shared": {
              "content": {
                "unavailable": {
                  "message": "Editor.Signature.Slot.Element.On.Shared.Content.Unavailable.Message"
                }
              }
            }
          }
        },
        "label": "Editor.Signature.Slot.Label",
        "shared": {
          "content": {
            "explanation": "Editor.Signature.Slot.Shared.Content.Explanation",
            "label": "Editor.Signature.Slot.Shared.Content.Label"
          }
        }
      },
      "type": {
        "checkbox": "Editor.Signature.Type.Checkbox",
        "date": "Editor.Signature.Type.Date",
        "firstname": "Editor.Signature.Type.FirstName",
        "freetext": "Editor.Signature.Type.FreeText",
        "horizontal": "Editor.Signature.Type.Horizontal",
        "initials": "Editor.Signature.Type.Initials",
        "lastname": "Editor.Signature.Type.LastName",
        "signature": "Editor.Signature.Type.Signature",
        "vertical": "Editor.Signature.Type.Vertical"
      }
    },
    "template": {
      "affectedinfo": {
        "text": "Editor.Template.AffectedInfo.Text"
      }
    },
    "title": "Editor.Title",
    "toolbar": {
      "command": {
        "align": {
          "center": "Editor.Toolbar.Command.Align.Center",
          "justify": "Editor.Toolbar.Command.Align.Justify",
          "left": "Editor.Toolbar.Command.Align.Left",
          "right": "Editor.Toolbar.Command.Align.Right"
        },
        "backgroundcolor": "Editor.Toolbar.Command.BackgroundColor",
        "bold": "Editor.Toolbar.Command.Bold",
        "clause": {
          "delete": "Editor.Toolbar.Command.Clause.Delete",
          "mcq": {
            "add": "Editor.Toolbar.Command.Clause.Mcq.Add"
          },
          "qdata": {
            "add": "Editor.Toolbar.Command.Clause.Qdata.Add"
          },
          "scq": {
            "add": "Editor.Toolbar.Command.Clause.Scq.Add"
          }
        },
        "clearformat": "Editor.Toolbar.Command.ClearFormat",
        "color": {
          "clear": "Editor.Toolbar.Command.Color.Clear"
        },
        "comment": {
          "add": "Editor.Toolbar.Command.Comment.Add"
        },
        "fontfamily": "Editor.Toolbar.Command.FontFamily",
        "fontsize": "Editor.Toolbar.Command.FontSize",
        "format": {
          "painter": "Editor.Toolbar.Command.Format.Painter"
        },
        "headerfooter": {
          "toogle": "Editor.Toolbar.Command.HeaderFooter.Toogle"
        },
        "heading": "Editor.Toolbar.Command.Heading",
        "image": {
          "insert": "Editor.Toolbar.Command.Image.Insert"
        },
        "italic": "Editor.Toolbar.Command.Italic",
        "line": {
          "above": "Editor.Toolbar.Command.Line.Above",
          "below": "Editor.Toolbar.Command.Line.Below",
          "height": "Editor.Toolbar.Command.Line.Height"
        },
        "list": {
          "ordered": "Editor.Toolbar.Command.List.Ordered",
          "unordered": "Editor.Toolbar.Command.List.Unordered"
        },
        "margin": {
          "bottom": "Editor.Toolbar.Command.Margin.Bottom",
          "label": "Editor.Toolbar.Command.Margin.Label",
          "left": "Editor.Toolbar.Command.Margin.Left",
          "right": "Editor.Toolbar.Command.Margin.Right",
          "top": "Editor.Toolbar.Command.Margin.Top"
        },
        "redo": "Editor.Toolbar.Command.Redo",
        "strike": "Editor.Toolbar.Command.Strike",
        "table": {
          "cell": "Editor.Toolbar.Command.Table.Cell",
          "cellparam": {
            "border": {
              "dashed": "Editor.Toolbar.Command.Table.CellParam.Border.Dashed",
              "dotted": "Editor.Toolbar.Command.Table.CellParam.Border.Dotted",
              "enabled": "Editor.Toolbar.Command.Table.CellParam.Border.Enabled",
              "none": "Editor.Toolbar.Command.Table.CellParam.Border.None",
              "settings": "Editor.Toolbar.Command.Table.CellParam.Border.Settings",
              "solid": "Editor.Toolbar.Command.Table.CellParam.Border.Solid",
              "style": "Editor.Toolbar.Command.Table.CellParam.Border.Style",
              "toggle": {
                "bottom": "Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Bottom",
                "left": "Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Left",
                "right": "Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Right",
                "top": "Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Top"
              }
            },
            "verticalalign": "Editor.Toolbar.Command.Table.CellParam.VerticalAlign"
          },
          "column": "Editor.Toolbar.Command.Table.Column",
          "delete": "Editor.Toolbar.Command.Table.Delete",
          "header": "Editor.Toolbar.Command.Table.Header",
          "insert": "Editor.Toolbar.Command.Table.Insert",
          "row": "Editor.Toolbar.Command.Table.Row"
        },
        "textcolor": "Editor.Toolbar.Command.TextColor",
        "underline": "Editor.Toolbar.Command.Underline",
        "undo": "Editor.Toolbar.Command.Undo",
        "variable": {
          "add": "Editor.Toolbar.Command.Variable.Add",
          "linked": {
            "add": "Editor.Toolbar.Command.Variable.Linked.Add"
          }
        },
        "variant": {
          "add": "Editor.Toolbar.Command.Variant.Add"
        }
      }
    },
    "track": {
      "changes": {
        "accepted": {
          "add": {
            "notebook": {
              "modal": {
                "title": "Editor.Track.Changes.Accepted.Add.NoteBook.Modal.Title"
              }
            }
          },
          "btn": {
            "cancel": {
              "modification": "Editor.Track.Changes.Accepted.Btn.Cancel.Modification"
            },
            "save": {
              "add": {
                "notes": "Editor.Track.Changes.Accepted.Btn.Save.Add.Notes"
              },
              "without": {
                "notes": "Editor.Track.Changes.Accepted.Btn.Save.Without.Notes"
              }
            }
          }
        }
      }
    },
    "trackchanges": {
      "annotation": {
        "label": "Editor.TrackChanges.Annotation.Label"
      },
      "deleted": {
        "label": "Editor.TrackChanges.Deleted.Label"
      },
      "deletion": {
        "label": "Editor.TrackChanges.Deletion.Label"
      },
      "inserted": {
        "label": "Editor.TrackChanges.Inserted.Label"
      },
      "insertion": {
        "label": "Editor.TrackChanges.Insertion.Label"
      },
      "modification": {
        "label": "Editor.TrackChanges.Modification.Label"
      },
      "replaced": {
        "label": "Editor.TrackChanges.Replaced.Label"
      },
      "replacedwith": {
        "label": "Editor.TrackChanges.ReplacedWith.Label"
      }
    },
    "variable": {
      "fields": {
        "allowedroles": {
          "label": "Editor.Variable.Fields.AllowedRoles.Label"
        },
        "defaultvalue": {
          "label": "Editor.Variable.Fields.DefaultValue.Label"
        },
        "linked": {
          "clause": {
            "label": "Editor.Variable.Fields.Linked.Clause.Label"
          },
          "variable": {
            "label": "Editor.Variable.Fields.Linked.Variable.Label"
          },
          "variant": {
            "label": "Editor.Variable.Fields.Linked.Variant.Label"
          }
        },
        "name": {
          "label": "Editor.Variable.Fields.Name.Label",
          "required": "Editor.Variable.Fields.Name.Required"
        },
        "payload": {
          "datetime": {
            "format": {
              "example": "Editor.Variable.Fields.Payload.DateTime.Format.Example",
              "full": "Editor.Variable.Fields.Payload.DateTime.Format.Full",
              "label": "Editor.Variable.Fields.Payload.DateTime.Format.Label",
              "long": "Editor.Variable.Fields.Payload.DateTime.Format.Long",
              "medium": "Editor.Variable.Fields.Payload.DateTime.Format.Medium",
              "short": "Editor.Variable.Fields.Payload.DateTime.Format.Short"
            }
          },
          "label": "Editor.Variable.Fields.Payload.Label",
          "listitem": {
            "format": {
              "conjunction": "Editor.Variable.Fields.Payload.ListItem.Format.Conjunction",
              "disjunction": "Editor.Variable.Fields.Payload.ListItem.Format.Disjunction",
              "example": "Editor.Variable.Fields.Payload.ListItem.Format.Example",
              "label": "Editor.Variable.Fields.Payload.ListItem.Format.Label",
              "unit": "Editor.Variable.Fields.Payload.ListItem.Format.Unit"
            },
            "label": "Editor.Variable.Fields.Payload.ListItem.Label",
            "required": "Editor.Variable.Fields.Payload.ListItem.Required",
            "style": {
              "label": "Editor.Variable.Fields.Payload.ListItem.Style.Label",
              "long": "Editor.Variable.Fields.Payload.ListItem.Style.Long",
              "narrow": "Editor.Variable.Fields.Payload.ListItem.Style.Narrow",
              "short": "Editor.Variable.Fields.Payload.ListItem.Style.Short"
            }
          },
          "number": {
            "currency": {
              "label": "Editor.Variable.Fields.Payload.Number.Currency.Label"
            },
            "format": {
              "currency": "Editor.Variable.Fields.Payload.Number.Format.Currency",
              "decimal": "Editor.Variable.Fields.Payload.Number.Format.Decimal",
              "example": "Editor.Variable.Fields.Payload.Number.Format.Example",
              "label": "Editor.Variable.Fields.Payload.Number.Format.Label",
              "percent": "Editor.Variable.Fields.Payload.Number.Format.Percent"
            },
            "maximumfractiondigits": {
              "label": "Editor.Variable.Fields.Payload.Number.MaximumFractionDigits.Label",
              "required": "Editor.Variable.Fields.Payload.Number.MaximumFractionDigits.Required"
            },
            "style": {
              "code": "Editor.Variable.Fields.Payload.Number.Style.Code",
              "label": "Editor.Variable.Fields.Payload.Number.Style.Label",
              "name": "Editor.Variable.Fields.Payload.Number.Style.Name",
              "symbol": "Editor.Variable.Fields.Payload.Number.Style.Symbol"
            }
          },
          "required": {
            "": "Editor.Variable.Fields.Payload.Required."
          },
          "select": {
            "explanation": "Editor.Variable.Fields.Payload.Select.Explanation"
          }
        },
        "placeholder": {
          "label": "Editor.Variable.Fields.Placeholder.Label"
        },
        "required": {
          "label": "Editor.Variable.Fields.Required.Label"
        },
        "type": {
          "calculated": {
            "label": "Editor.Variable.Fields.Type.Calculated.Label"
          },
          "date": {
            "label": "Editor.Variable.Fields.Type.Date.Label"
          },
          "datespan": {
            "label": "Editor.Variable.Fields.Type.DateSpan.Label"
          },
          "email": {
            "label": "Editor.Variable.Fields.Type.Email.Label"
          },
          "image": {
            "label": "Editor.Variable.Fields.Type.Image.Label"
          },
          "label": "Editor.Variable.Fields.Type.Label",
          "linked": {
            "label": "Editor.Variable.Fields.Type.Linked.Label"
          },
          "list": {
            "label": "Editor.Variable.Fields.Type.List.Label"
          },
          "multiline": {
            "label": "Editor.Variable.Fields.Type.Multiline.Label"
          },
          "number": {
            "label": "Editor.Variable.Fields.Type.Number.Label"
          },
          "percent": {
            "label": "Editor.Variable.Fields.Type.Percent.Label"
          },
          "phone": {
            "label": "Editor.Variable.Fields.Type.Phone.Label"
          },
          "selectlist": {
            "label": "Editor.Variable.Fields.Type.SelectList.Label"
          },
          "text": {
            "label": "Editor.Variable.Fields.Type.Text.Label"
          },
          "time": {
            "label": "Editor.Variable.Fields.Type.Time.Label"
          },
          "timespan": {
            "label": "Editor.Variable.Fields.Type.TimeSpan.Label"
          }
        }
      }
    },
    "variant": {
      "fields": {
        "name": {
          "label": "Editor.Variant.Fields.Name.Label",
          "required": "Editor.Variant.Fields.Name.Required"
        }
      }
    }
  },
  "errors": {
    "accessdeniedcancelcontractsignature": "Errors.AccessDeniedCancelContractSignature",
    "accessdeniedcreatedocument": "Errors.AccessDeniedCreateDocument",
    "accessdeniedcreatedocumentfolder": "Errors.AccessDeniedCreateDocumentFolder",
    "accessdenieddeletecontract": "Errors.AccessDeniedDeleteContract",
    "accessdenieddeletedocument": "Errors.AccessDeniedDeleteDocument",
    "accessdenieddeletedocumentfolder": "Errors.AccessDeniedDeleteDocumentFolder",
    "accessdenieddetaildocument": "Errors.AccessDeniedDetailDocument",
    "accessdenieddetaildocumentfolder": "Errors.AccessDeniedDetailDocumentFolder",
    "accessdeniedrenegociatecontract": "Errors.AccessDeniedRenegociateContract",
    "accessdeniedsharedocument": "Errors.AccessDeniedShareDocument",
    "accessdeniedsharedocumentfolder": "Errors.AccessDeniedShareDocumentFolder",
    "accessdeniedupdatedocument": "Errors.AccessDeniedUpdateDocument",
    "accessdeniedupdatedocumentfolder": "Errors.AccessDeniedUpdateDocumentFolder",
    "adeo": {
      "buidfoujuridcombinationalreadyexists": "Errors.Adeo.BuIdFouJurIdCombinationAlreadyExists",
      "buidpartneridcombinationalreadyexists": "Errors.Adeo.BuIdPartnerIdCombinationAlreadyExists",
      "foujurnotfound": "Errors.Adeo.FouJurNotFound",
      "packalreadyexists": "Errors.Adeo.PackAlreadyExists",
      "partnerisnotreferenced": "Errors.Adeo.PartnerIsNotReferenced",
      "roleassignment": {
        "team": {
          "userisexpert": "Errors.Adeo.RoleAssignment.Team.UserIsExpert",
          "useristeamleader": "Errors.Adeo.RoleAssignment.Team.UserIsTeamLeader"
        }
      },
      "signmodal": {
        "error": {
          "fetchingsignersfailed": "Errors.Adeo.SignModal.Error.FetchingSignersFailed"
        }
      }
    },
    "affiliatevalidatorssignersnotfound": "Errors.AffiliateValidatorsSignersNotFound",
    "allcontactsmusthavephonenumber": "Errors.AllContactsMustHavePhoneNumber",
    "blockdocumentsarerequired": "Errors.BlockDocumentsAreRequired",
    "campaignalreadyexists": "Errors.CampaignAlreadyExists",
    "contractbeingnegotiatedcannotbecomeannex": "Errors.ContractBeingNegotiatedCannotBecomeAnnex",
    "contractcannotbecomeannex": "Errors.ContractCannotBecomeAnnex",
    "contractformnotcompleted": "Errors.ContractFormNotCompleted",
    "contracthascommentsandneedsconfirmation": "Errors.ContractHasCommentsAndNeedsConfirmation",
    "contracthascommentsandnextworkflowstepissign": "Errors.ContractHasCommentsAndNextWorkflowStepIsSign",
    "contracthasnopdfcontent": "Errors.ContractHasNoPdfContent",
    "contractproviderfailed": "Errors.ContractProviderFailed",
    "contractsignaturecancelfailed": "Errors.ContractSignatureCancelFailed",
    "contractsignaturesendfailed": "Errors.ContractSignatureSendFailed",
    "contractualpacknegociatedtemplatenotfound": "Errors.ContractualPackNegociatedTemplateNotFound",
    "contractualpacksigneddocumentnotfound": "Errors.ContractualPackSignedDocumentNotFound",
    "contractualpacksupportingdocumentalreadyexists": "Errors.ContractualPackSupportingDocumentAlreadyExists",
    "contractualpacksupportingdocumentnotfound": "Errors.ContractualPackSupportingDocumentNotFound",
    "contractualizationcontractsnotcompleted": "Errors.ContractualizationContractsNotCompleted",
    "contractualizationrequireddocumentsmissing": "Errors.ContractualizationRequiredDocumentsMissing",
    "createdocumentcommand": {
      "document": {
        "alreadyexists": "Errors.CreateDocumentCommand.Document.AlreadyExists"
      }
    },
    "createdocumentviewuricommandresult": {
      "document": {
        "doesnotexist": "Errors.CreateDocumentViewUriCommandResult.Document.DoesNotExist"
      }
    },
    "createtemplatecommand": {
      "template": {
        "alreadyexists": "Errors.CreateTemplateCommand.Template.AlreadyExists"
      }
    },
    "datecannotbeinfuture": "Errors.DateCannotBeInFuture",
    "departmentalreadyexists": "Errors.DepartmentAlreadyExists",
    "departmentnotempty": "Errors.DepartmentNotEmpty",
    "departmentnotfound": "Errors.DepartmentNotFound",
    "departmentrootnotfound": "Errors.DepartmentRootNotFound",
    "docusignerror": "Errors.DocuSignError",
    "documentfolderparentisdeleted": "Errors.DocumentFolderParentIsDeleted",
    "documentquery": {
      "document": {
        "notfound": "Errors.DocumentQuery.Document.NotFound"
      }
    },
    "documenttemplateidisrequired": "Errors.DocumentTemplateIdIsRequired",
    "editoroccured": "Errors.EditorOccured",
    "emailconfirmationfailed": "Errors.EmailConfirmationFailed",
    "emailconfirmationinvalidtoken": "Errors.EmailConfirmationInvalidToken",
    "emailisinvalid": "Errors.EmailIsInvalid",
    "erroroccured": "Errors.ErrorOccured",
    "erroroccuredfallback": "Errors.ErrorOccuredFallback",
    "expertalreadyexists": "Errors.ExpertAlreadyExists",
    "expertisealreadyexists": "Errors.ExpertiseAlreadyExists",
    "expertisenotfound": "Errors.ExpertiseNotFound",
    "failedtofetch": "Errors.FailedToFetch",
    "fileextensioninvalid": "Errors.FileExtensionInvalid",
    "folderformsnotvalidated": "Errors.FolderFormsNotValidated",
    "folderhasasigningcontract": "Errors.FolderHasASigningContract",
    "folderhasanongoingcontract": "Errors.FolderHasAnOngoingContract",
    "foldernotfound": "Errors.FolderNotFound",
    "forbidden": "Errors.Forbidden",
    "idcardsrequired": "Errors.IdCardsRequired",
    "inpersonsigninghostmustbevaliddocusignuser": "Errors.InPersonSigningHostMustBeValidDocuSignUser",
    "invalidemailpassword": "Errors.InvalidEmailPassword",
    "jsontopdf": "Errors.JsonToPdf",
    "lapostecontractelectronicsignaturefailed": "Errors.LaPosteContractElectronicSignatureFailed",
    "lapostecontractelectronicsignaturefailedbecausealreadysent": "Errors.LaPosteContractElectronicSignatureFailedBecauseAlreadySent",
    "lapostecontracthascomments": "Errors.LaPosteContractHasComments",
    "lapostecontracthascommentsforsendingcommercial": "Errors.LaPosteContractHasCommentsForSendingCommercial",
    "lapostecontracthascommentsforsendingsignature": "Errors.LaPosteContractHasCommentsForSendingSignature",
    "mergepdf": "Errors.MergePdf",
    "minimumonefileisrequired": "Errors.MinimumOneFileIsRequired",
    "multisignaturemodal": {
      "submiterrors": {
        "documents": {
          "reason": {
            "finished": "Errors.MultiSignatureModal.SubmitErrors.Documents.Reason.Finished",
            "pending": "Errors.MultiSignatureModal.SubmitErrors.Documents.Reason.Pending",
            "signing": "Errors.MultiSignatureModal.SubmitErrors.Documents.Reason.Signing",
            "unknown": "Errors.MultiSignatureModal.SubmitErrors.Documents.Reason.Unknown"
          },
          "required": "Errors.MultiSignatureModal.SubmitErrors.Documents.Required"
        },
        "signatures": {
          "required": "Errors.MultiSignatureModal.SubmitErrors.Signatures.Required"
        },
        "users": {
          "reasons": "Errors.MultiSignatureModal.SubmitErrors.Users.Reasons"
        }
      }
    },
    "networkerroroccured": "Errors.NetworkErrorOccured",
    "nexity": {
      "clientinfoalreadyvalidated": "Errors.Nexity.ClientInfoAlreadyValidated",
      "contractalreadysentforsignature": "Errors.Nexity.ContractAlreadySentForSignature",
      "contractalreadysigned": "Errors.Nexity.ContractAlreadySigned",
      "contractualizationalreadyinvalidated": "Errors.Nexity.ContractualizationAlreadyInvalidated",
      "contractualizationnotgenerated": "Errors.Nexity.ContractualizationNotGenerated",
      "folderpreparedatanotfound": "Errors.Nexity.FolderPrepareDataNotFound",
      "spacehasnoworkflow": "Errors.Nexity.SpaceHasNoWorkflow",
      "spaceworkflowmustbecompleted": "Errors.Nexity.SpaceWorkflowMustBeCompleted"
    },
    "notimplemented": "Errors.NotImplemented",
    "packdoesnotdefinesigners": "Errors.PackDoesNotDefineSigners",
    "parentfoldercannotbesamefolder": "Errors.ParentFolderCannotBeSameFolder",
    "parentfolderisinfolder": "Errors.ParentFolderIsInFolder",
    "projectmanageralreadyexists": "Errors.ProjectManagerAlreadyExists",
    "projectmanagerisleader": "Errors.ProjectManagerIsLeader",
    "referentialprovidernotexportable": "Errors.ReferentialProviderNotExportable",
    "resetpasswordfailed": "Errors.ResetPasswordFailed",
    "resetpasswordinvalidtoken": "Errors.ResetPasswordInvalidToken",
    "resetpasswordpasswordrequiresdigit": "Errors.ResetPasswordPasswordRequiresDigit",
    "resetpasswordpasswordrequireslower": "Errors.ResetPasswordPasswordRequiresLower",
    "resetpasswordpasswordrequiresnonalphanumeric": "Errors.ResetPasswordPasswordRequiresNonAlphanumeric",
    "resetpasswordpasswordrequiresupper": "Errors.ResetPasswordPasswordRequiresUpper",
    "resetpasswordpasswordtooshort": "Errors.ResetPasswordPasswordTooShort",
    "sharedaccessrequestalreadyaccepted": "Errors.SharedAccessRequestAlreadyAccepted",
    "sharedaccessrequestalreadyrejected": "Errors.SharedAccessRequestAlreadyRejected",
    "signatureproviderinfonotfound": "Errors.SignatureProviderInfoNotFound",
    "signersnotfoundfromrole": "Errors.SignersNotFoundFromRole",
    "statusdateisnotavailable": "Errors.StatusDateIsNotAvailable",
    "suppliersreferentialnotfound": "Errors.SuppliersReferentialNotFound",
    "supportingalreadyexists": "Errors.SupportingAlreadyExists",
    "supportingdocumentalreadyexists": "Errors.SupportingDocumentAlreadyExists",
    "supportingdocumentnotfound": "Errors.SupportingDocumentNotFound",
    "supportingelementnotfound": "Errors.SupportingElementNotFound",
    "supportinghasdocument": "Errors.SupportingHasDocument",
    "supportingnotfound": "Errors.SupportingNotFound",
    "teamalreadyexists": "Errors.TeamAlreadyExists",
    "templatefolderparentisdeleted": "Errors.TemplateFolderParentIsDeleted",
    "tenantadministratoralreadyexists": "Errors.TenantAdministratorAlreadyExists",
    "tenantalreadyexists": "Errors.TenantAlreadyExists",
    "termsheetelementhasvalues": "Errors.TermSheetElementHasValues",
    "termsheetvalueconnectionvariableuidnotfound": "Errors.TermSheetValueConnectionVariableUidNotFound",
    "termsheetvalueisfilledfromcontract": "Errors.TermSheetValueIsFilledFromContract",
    "tokenisinvalid": "Errors.TokenIsInvalid",
    "unauthorizedbuaccess": "Errors.UnauthorizedBuAccess",
    "useralreadyexistinworkflow": "Errors.UserAlreadyExistInWorkflow",
    "useralreadyexists": "Errors.UserAlreadyExists",
    "userdeactivated": "Errors.UserDeactivated",
    "userhasnopassword": "Errors.UserHasNoPassword",
    "userhasnophonenumberformfa": "Errors.UserHasNoPhoneNumberForMfa",
    "userhasnotenants": "Errors.UserHasNoTenants",
    "userisnotauthorized": "Errors.UserIsNotAuthorized",
    "userlockedout": "Errors.UserLockedOut",
    "usernotfound": "Errors.UserNotFound",
    "workflowcontributorsnotvalidated": "Errors.WorkflowContributorsNotValidated",
    "workflowschemaisinvalid": "Errors.WorkflowSchemaIsInvalid",
    "workflowschemastepsarerequired": "Errors.WorkflowSchemaStepsAreRequired",
    "workflowstepcontributornotfound": "Errors.WorkflowStepContributorNotFound",
    "workflowstepcontributorsarerequired": "Errors.WorkflowStepContributorsAreRequired",
    "workflowstepisnotsign": "Errors.WorkflowStepIsNotSign"
  },
  "expert": {
    "buttons": {
      "add": "Expert.Buttons.Add"
    }
  },
  "expertise": {
    "buttons": {
      "add": "Expertise.Buttons.Add"
    },
    "dialogs": {
      "create": {
        "title": "Expertise.Dialogs.Create.Title"
      },
      "delete": {
        "message": "Expertise.Dialogs.Delete.Message",
        "title": "Expertise.Dialogs.Delete.Title"
      },
      "update": {
        "title": "Expertise.Dialogs.Update.Title"
      }
    },
    "fields": {
      "expert": {
        "label": "Expertise.Fields.Expert.Label",
        "required": "Expertise.Fields.Expert.Required"
      },
      "name": {
        "label": "Expertise.Fields.Name.Label",
        "required": "Expertise.Fields.Name.Required"
      },
      "notificationmode": {
        "expert": "Expertise.Fields.NotificationMode.Expert",
        "label": "Expertise.Fields.NotificationMode.Label",
        "projectmanager": "Expertise.Fields.NotificationMode.ProjectManager",
        "required": "Expertise.Fields.NotificationMode.Required"
      }
    },
    "noresult": "Expertise.NoResult"
  },
  "external": {
    "accounts": {
      "view": {
        "empty": {
          "label": "External.Accounts.View.Empty.Label"
        }
      }
    }
  },
  "externalaccount": {
    "disable": {
      "modal": {
        "title": {
          "label": "ExternalAccount.Disable.Modal.Title.Label"
        }
      }
    },
    "invitation": {
      "modal": {
        "title": {
          "label": "ExternalAccount.Invitation.Modal.Title.Label"
        }
      }
    },
    "lock": {
      "modal": {
        "title": {
          "label": "ExternalAccount.Lock.Modal.Title.Label"
        }
      }
    },
    "modal": {
      "buttons": {
        "disable": {
          "label": "ExternalAccount.Modal.Buttons.Disable.Label"
        },
        "lock": {
          "label": "ExternalAccount.Modal.Buttons.Lock.Label"
        },
        "reactivate": {
          "label": "ExternalAccount.Modal.Buttons.Reactivate.Label"
        },
        "unlock": {
          "label": "ExternalAccount.Modal.Buttons.Unlock.Label"
        }
      },
      "descriptions": {
        "disable": {
          "label": "ExternalAccount.Modal.Descriptions.Disable.Label"
        },
        "lock": {
          "text": "ExternalAccount.Modal.Descriptions.Lock.Text"
        },
        "reactivate": {
          "label": "ExternalAccount.Modal.Descriptions.Reactivate.Label"
        },
        "unlock": {
          "text": "ExternalAccount.Modal.Descriptions.Unlock.Text"
        },
        "invitation": {
          "text": "ExternalAccount.Modal.Descriptions.invitation.Text"
        }
      },
      "title": {
        "label": "ExternalAccount.Modal.Title.Label"
      }
    },
    "reactivate": {
      "modal": {
        "title": {
          "label": "ExternalAccount.Reactivate.Modal.Title.Label"
        }
      }
    },
    "unlock": {
      "modal": {
        "title": {
          "label": "ExternalAccount.UnLock.Modal.Title.Label"
        }
      }
    }
  },
  "externalaccountsview": {
    "lock": {
      "success": "ExternalAccountsView.Lock.Success"
    },
    "unlock": {
      "success": "ExternalAccountsView.Unlock.Success"
    }
  },
  "externalusersaccountsview": {
    "table": {
      "tableheader": {
        "actions": {
          "label": "ExternalUsersAccountsView.Table.TableHeader.Actions.Label"
        },
        "email": {
          "label": "ExternalUsersAccountsView.Table.TableHeader.Email.Label"
        },
        "firstname": {
          "label": "ExternalUsersAccountsView.Table.TableHeader.FirstName.Label"
        },
        "lastname": {
          "label": "ExternalUsersAccountsView.Table.TableHeader.LastName.Label"
        },
        "phone": {
          "number": {
            "label": "ExternalUsersAccountsView.Table.TableHeader.Phone.Number.Label"
          }
        }
      }
    }
  },
  "filefield": {
    "value": "FileField.Value"
  },
  "forgotpassword": {
    "button": "ForgotPassword.Button",
    "fields": {
      "email": {
        "label": "ForgotPassword.Fields.Email.Label"
      }
    },
    "gotosignin": "ForgotPassword.GoToSignIn"
  },
  "generic": {
    "back": "Generic.Back",
    "fields": {
      "date": {
        "maxtoday": "Generic.Fields.Date.MaxToday"
      },
      "invalidformat": "Generic.Fields.InvalidFormat",
      "mustbepositive": "Generic.Fields.MustBePositive",
      "required": "Generic.Fields.Required"
    }
  },
  "header": {
    "menu": {
      "administration": "Header.Menu.Administration",
      "back": "Header.Menu.Back",
      "calendar": "Header.Menu.Calendar",
      "dashboard": "Header.Menu.Dashboard",
      "delegators": "Header.Menu.Delegators",
      "drive": "Header.Menu.Drive",
      "help": "Header.Menu.Help",
      "helpcenter": "Header.Menu.HelpCenter",
      "logout": "Header.Menu.LogOut",
      "parameters": "Header.Menu.Parameters",
      "policy": "Header.Menu.Policy",
      "profile": "Header.Menu.Profile",
      "statistics": "Header.Menu.Statistics"
    }
  },
  "helpcenter": {
    "document": {
      "buttons": {
        "add": "HelpCenter.Document.Buttons.Add"
      },
      "dialogs": {
        "create": {
          "title": "HelpCenter.Document.Dialogs.Create.Title"
        },
        "delete": {
          "message": "HelpCenter.Document.Dialogs.Delete.Message",
          "title": "HelpCenter.Document.Dialogs.Delete.Title"
        },
        "update": {
          "title": "HelpCenter.Document.Dialogs.Update.Title"
        }
      },
      "fields": {
        "file": {
          "invalidformat": "HelpCenter.Document.Fields.File.InvalidFormat",
          "label": "HelpCenter.Document.Fields.File.Label",
          "required": "HelpCenter.Document.Fields.File.Required"
        },
        "name": {
          "label": "HelpCenter.Document.Fields.Name.Label"
        },
        "role": {
          "label": "HelpCenter.Document.Fields.Role.Label",
          "required": "HelpCenter.Document.Fields.Role.Required"
        }
      },
      "name": {
        "required": "HelpCenter.Document.Name.Required"
      },
      "role": {
        "everybody": "HelpCenter.Document.Role.Everybody"
      },
      "title": "HelpCenter.Document.Title"
    },
    "html": {
      "title": "HelpCenter.Html.Title"
    },
    "menu": {
      "help": "HelpCenter.Menu.Help",
      "releases": "HelpCenter.Menu.Releases"
    },
    "release": {
      "buttons": {
        "publish": "HelpCenter.Release.Buttons.Publish"
      },
      "external": {
        "title": "HelpCenter.Release.External.Title"
      },
      "internal": {
        "title": "HelpCenter.Release.Internal.Title"
      }
    }
  },
  "homepage": {
    "menu": {
      "contactus": "Homepage.Menu.ContactUs",
      "legalnotice": "Homepage.Menu.LegalNotice",
      "privacypolicy": "Homepage.Menu.PrivacyPolicy"
    },
    "welcome": {
      "message": "Homepage.Welcome.Message"
    }
  },
  "integrationsview": {
    "buttons": {
      "seemore": {
        "label": "IntegrationsView.Buttons.SeeMore.Label"
      }
    },
    "cards": {
      "docusign": {
        "description": "IntegrationsView.Cards.DocuSign.Description"
      },
      "lexpersona": {
        "description": "IntegrationsView.Cards.LexPersona.Description"
      },
      "salesforce": {
        "description": "IntegrationsView.Cards.SalesForce.Description"
      },
      "yousign": {
        "description": "IntegrationsView.Cards.YouSign.Description"
      }
    }
  },
  "managedataview": {
    "breadcrumb": {
      "text": "ManageDataView.Breadcrumb.Text"
    },
    "buttons": {
      "create": {
        "label": "ManageDataView.Buttons.Create.Label"
      }
    },
    "createelement": {
      "button": {
        "create": {
          "label": "ManageDataView.CreateElement.Button.Create.Label"
        }
      }
    },
    "dataoptions": {
      "buttons": {
        "merge": {
          "label": "ManageDataView.DataOptions.Buttons.Merge.Label"
        }
      }
    },
    "datatypes": {
      "boolean": {
        "label": "ManageDataView.DataTypes.Boolean.Label"
      },
      "customobject": {
        "label": "ManageDataView.DataTypes.CustomObject.Label"
      },
      "dateonly": {
        "label": "ManageDataView.DataTypes.DateOnly.Label"
      },
      "identifier": {
        "label": "ManageDataView.DataTypes.Identifier.Label"
      },
      "number": {
        "label": "ManageDataView.DataTypes.Number.Label"
      },
      "text": {
        "label": "ManageDataView.DataTypes.Text.Label"
      },
      "timeonly": {
        "label": "ManageDataView.DataTypes.TimeOnly.Label"
      },
      "timestamp": {
        "label": "ManageDataView.DataTypes.Timestamp.Label"
      }
    },
    "dialogs": {
      "create": {
        "fields": {
          "content": {
            "label": "ManageDataView.Dialogs.Create.Fields.Content.Label"
          },
          "datatype": {
            "label": "ManageDataView.Dialogs.Create.Fields.DataType.Label"
          },
          "icon": {
            "label": "ManageDataView.Dialogs.Create.Fields.Icon.Label",
            "required": "ManageDataView.Dialogs.Create.Fields.Icon.Required"
          },
          "name": {
            "label": "ManageDataView.Dialogs.Create.Fields.Name.Label"
          }
        },
        "title": "ManageDataView.Dialogs.Create.Title"
      },
      "delete": {
        "confirmationmessage": {
          "text": "ManageDataView.Dialogs.Delete.ConfirmationMessage.Text"
        },
        "title": "ManageDataView.Dialogs.Delete.title"
      },
      "edit": {
        "title": "ManageDataView.Dialogs.Edit.Title"
      },
      "merge": {
        "confirmationmessage": {
          "text": "ManageDataView.Dialogs.Merge.ConfirmationMessage.Text"
        },
        "confirmationmessageend": {
          "text": "ManageDataView.Dialogs.Merge.ConfirmationMessageEnd.Text"
        },
        "fields": {
          "type": {
            "label": "ManageDataView.Dialogs.Merge.Fields.Type.Label"
          }
        },
        "first": {
          "label": "ManageDataView.Dialogs.Merge.First.Label"
        },
        "second": {
          "label": "ManageDataView.Dialogs.Merge.Second.Label"
        },
        "title": "ManageDataView.Dialogs.Merge.Title"
      }
    },
    "table": {
      "columns": {
        "actions": {
          "label": "ManageDataView.Table.Columns.Actions.Label"
        },
        "datatype": {
          "label": "ManageDataView.Table.Columns.DataType.Label"
        },
        "icon": {
          "label": "ManageDataView.Table.Columns.Icon.Label"
        },
        "name": {
          "label": "ManageDataView.Table.Columns.Name.Label"
        },
        "numberdocuments": {
          "label": "ManageDataView.Table.Columns.NumberDocuments.Label"
        },
        "numbertemplates": {
          "label": "ManageDataView.Table.Columns.NumberTemplates.Label"
        }
      },
      "template": {
        "label": "ManageDataView.Table.Template.Label"
      }
    },
    "updateelement": {
      "button": {
        "update": {
          "label": "ManageDataView.UpdateElement.Button.Update.Label"
        }
      }
    }
  },
  "modal": {
    "button": {
      "group": {
        "cancel": {
          "button": {
            "label": "Modal.Button.Group.Cancel.Button.Label"
          }
        },
        "submit": {
          "button": {
            "label": "Modal.Button.Group.Submit.Button.Label"
          }
        }
      },
      "ok": {
        "button": {
          "label": "Modal.Button.Ok.Button.Label"
        }
      }
    }
  },
  "multisignaturemodal": {
    "buttons": {
      "cancel": "MultiSignatureModal.Buttons.Cancel",
      "submit": "MultiSignatureModal.Buttons.Submit"
    },
    "description": {
      "header": "MultiSignatureModal.Description.Header",
      "text": "MultiSignatureModal.Description.Text"
    },
    "section": {
      "signature": {
        "button": {
          "addnewsignatureform": "MultiSignatureModal.Section.Signature.Button.AddNewSignatureForm"
        }
      }
    },
    "sections": {
      "documents": {
        "title": "MultiSignatureModal.Sections.Documents.Title"
      },
      "signature": {
        "title": "MultiSignatureModal.Sections.Signature.Title"
      }
    },
    "title": "MultiSignatureModal.Title"
  },
  "nexity": {
    "folders": {
      "acquirercontactdetails": "Nexity.Folders.AcquirerContactDetails",
      "formstocomplete": {
        "civilstate": "Nexity.Folders.FormsToComplete.CivilState",
        "familysituation": "Nexity.Folders.FormsToComplete.FamilySituation",
        "financing": "Nexity.Folders.FormsToComplete.Financing",
        "label": "Nexity.Folders.FormsToComplete.Label",
        "project": "Nexity.Folders.FormsToComplete.Project",
        "status": {
          "tocomplete": "Nexity.Folders.FormsToComplete.Status.ToComplete",
          "torectify": "Nexity.Folders.FormsToComplete.Status.ToRectify",
          "tovalidate": "Nexity.Folders.FormsToComplete.Status.ToValidate",
          "validated": "Nexity.Folders.FormsToComplete.Status.Validated"
        }
      },
      "vouchers": "Nexity.Folders.Vouchers"
    }
  },
  "notifications": {
    "action": {
      "acceptsharedaccessrequest": {
        "label": "Notifications.Action.AcceptSharedAccessRequest.Label"
      },
      "consultworkflow": {
        "label": "Notifications.Action.ConsultWorkflow.Label"
      },
      "opendocument": {
        "label": "Notifications.Action.OpenDocument.Label"
      },
      "opendocumentfolder": {
        "label": "Notifications.Action.OpenDocumentFolder.Label"
      },
      "rejectsharedaccessrequest": {
        "label": "Notifications.Action.RejectSharedAccessRequest.Label"
      }
    },
    "dialogs": {
      "acceptsharedaccessrequest": {
        "title": "Notifications.Dialogs.AcceptSharedAccessRequest.Title"
      },
      "rejectsharedaccessrequest": {
        "title": "Notifications.Dialogs.RejectSharedAccessRequest.Title"
      }
    },
    "types": {
      "abandoncontract": {
        "text": "Notifications.Types.AbandonContract.Text"
      },
      "abandoncontractactivity": {
        "text": "Notifications.Types.AbandonContractActivity.Text"
      },
      "abandoncontractwithdelegationactivity": {
        "text": "Notifications.Types.AbandonContractWithDelegationActivity.Text"
      },
      "closedclauseassignedactivity": {
        "text": "Notifications.Types.ClosedClauseAssignedActivity.Text"
      },
      "closedclauseassignedwithdelegationactivity": {
        "text": "Notifications.Types.ClosedClauseAssignedWithDelegationActivity.Text"
      },
      "closedclausecontentcommentedactivity": {
        "text": "Notifications.Types.ClosedClauseContentCommentedActivity.Text"
      },
      "closedclausecontentcommentedwithdelegationactivity": {
        "text": "Notifications.Types.ClosedClauseContentCommentedWithDelegationActivity.Text"
      },
      "closedclausecontentrevisedactivity": {
        "text": "Notifications.Types.ClosedClauseContentRevisedActivity.Text"
      },
      "closedclausecontentrevisedwithdelegationactivity": {
        "text": "Notifications.Types.ClosedClauseContentRevisedWithDelegationActivity.Text"
      },
      "closedclausereassignedactivity": {
        "text": "Notifications.Types.ClosedClauseReassignedActivity.Text"
      },
      "closedclausereassignedwithdelegationactivity": {
        "text": "Notifications.Types.ClosedClauseReassignedWithDelegationActivity.Text"
      },
      "closedclauseresolvedactivity": {
        "text": "Notifications.Types.ClosedClauseResolvedActivity.Text"
      },
      "closedclauseresolvedwithdelegationactivity": {
        "text": "Notifications.Types.ClosedClauseResolvedWithDelegationActivity.Text"
      },
      "contentcommentedactivity": {
        "text": "Notifications.Types.ContentCommentedActivity.Text"
      },
      "contentcommentedwithdelegationactivity": {
        "text": "Notifications.Types.ContentCommentedWithDelegationActivity.Text"
      },
      "contentrevisedactivity": {
        "text": "Notifications.Types.ContentRevisedActivity.Text"
      },
      "contentrevisedwithdelegationactivity": {
        "text": "Notifications.Types.ContentRevisedWithDelegationActivity.Text"
      },
      "delegation": {
        "titlemessage": "Notifications.Types.Delegation.TitleMessage"
      },
      "documentcreated": {
        "text": "Notifications.Types.DocumentCreated.Text"
      },
      "documentcreatedwithuser": {
        "text": "Notifications.Types.DocumentCreatedWithUser.Text"
      },
      "documentcreatedwithuseranddelegation": {
        "text": "Notifications.Types.DocumentCreatedWithUserAndDelegation.Text"
      },
      "documentduplicated": {
        "text": "Notifications.Types.DocumentDuplicated.Text"
      },
      "documentduplicatedwithdelegation": {
        "text": "Notifications.Types.DocumentDuplicatedWithDelegation.Text"
      },
      "invitedusertoworkflowstepnotification": {
        "text": "Notifications.Types.InvitedUserToWorkflowStepNotification.Text"
      },
      "receivedelegation": {
        "text": "Notifications.Types.ReceiveDelegation.Text"
      },
      "remindworkflownotification": {
        "text": "Notifications.Types.RemindWorkflowNotification.Text"
      },
      "sharedaccessrequestaccepted": {
        "text": "Notifications.Types.SharedAccessRequestAccepted.Text"
      },
      "sharedaccessrequestrejected": {
        "text": "Notifications.Types.SharedAccessRequestRejected.Text"
      },
      "sharedaccessrequested": {
        "text": "Notifications.Types.SharedAccessRequested.Text"
      },
      "signerrefused": {
        "text": "Notifications.Types.SignerRefused.Text"
      },
      "statussettoabsentwithdelegation": {
        "text": "Notifications.Types.StatusSetToAbsentWithDelegation.Text"
      },
      "statussettoabsentwithoutdelegation": {
        "text": "Notifications.Types.StatusSetToAbsentWithoutDelegation.Text"
      },
      "statussettoactive": {
        "text": "Notifications.Types.StatusSetToActive.Text"
      },
      "statusupdate": {
        "titlemessage": "Notifications.Types.StatusUpdate.TitleMessage"
      },
      "workflowcompleted": {
        "text": "Notifications.Types.WorkflowCompleted.Text"
      },
      "workflowcompletedmultiusersactivity": {
        "text": "Notifications.Types.WorkflowCompletedMultiUsersActivity.Text"
      },
      "workflowcompletedoneuseractivity": {
        "text": "Notifications.Types.WorkflowCompletedOneUserActivity.Text"
      },
      "workflowcompletedoneuserwithdelegationactivity": {
        "text": "Notifications.Types.WorkflowCompletedOneUserWithDelegationActivity.Text"
      },
      "workflowrejected": {
        "action": {
          "label": "Notifications.Types.WorkflowRejected.Action.Label"
        },
        "text": "Notifications.Types.WorkflowRejected.Text"
      },
      "workflowrejectedwithdelegation": {
        "text": "Notifications.Types.WorkflowRejectedWithDelegation.Text"
      },
      "workflowsigned": {
        "text": "Notifications.Types.WorkflowSigned.Text"
      },
      "workflowstepadded": {
        "text": "Notifications.Types.WorkflowStepAdded.Text"
      },
      "workflowstepaddedwithdelegation": {
        "text": "Notifications.Types.WorkflowStepAddedWithDelegation.Text"
      },
      "workflowstepcompletedmultiusersactivity": {
        "text": "Notifications.Types.WorkflowStepCompletedMultiUsersActivity.Text"
      },
      "workflowstepcompletedoneuseractivity": {
        "text": "Notifications.Types.WorkflowStepCompletedOneUserActivity.Text"
      },
      "workflowstepcompletedoneuseractivitywithdelegation": {
        "text": "Notifications.Types.WorkflowStepCompletedOneUserActivityWithDelegation.Text"
      },
      "workflowstepinvalidated": {
        "text": "Notifications.Types.WorkflowStepInvalidated.Text"
      },
      "workflowstepinvalidatedwithdelegation": {
        "text": "Notifications.Types.WorkflowStepInvalidatedWithDelegation.Text"
      },
      "workflowstepremoved": {
        "text": "Notifications.Types.WorkflowStepRemoved.Text"
      },
      "workflowstepremovedwithdelegation": {
        "text": "Notifications.Types.WorkflowStepRemovedWithDelegation.Text"
      },
      "workflowstepvalidated": {
        "text": "Notifications.Types.WorkflowStepValidated.Text"
      },
      "workflowstepvalidatedwithdelegation": {
        "text": "Notifications.Types.WorkflowStepValidatedWithDelegation.Text"
      }
    }
  },
  "packages": {
    "negociated": {
      "buttons": {
        "add": "Packages.Negociated.Buttons.Add"
      },
      "dialogs": {
        "create": {
          "title": "Packages.Negociated.Dialogs.Create.Title"
        },
        "delete": {
          "message": "Packages.Negociated.Dialogs.Delete.Message",
          "title": "Packages.Negociated.Dialogs.Delete.Title"
        },
        "update": {
          "title": "Packages.Negociated.Dialogs.Update.Title"
        }
      },
      "fields": {
        "name": {
          "label": "Packages.Negociated.Fields.Name.Label",
          "required": "Packages.Negociated.Fields.Name.Required"
        }
      },
      "label": "Packages.Negociated.Label"
    },
    "noresult": "Packages.NoResult",
    "signed": {
      "buttons": {
        "add": "Packages.Signed.Buttons.Add"
      },
      "dialogs": {
        "create": {
          "title": "Packages.Signed.Dialogs.Create.Title"
        },
        "delete": {
          "message": "Packages.Signed.Dialogs.Delete.Message",
          "title": "Packages.Signed.Dialogs.Delete.Title"
        },
        "update": {
          "title": "Packages.Signed.Dialogs.Update.Title"
        },
        "view": {
          "title": "Packages.Signed.Dialogs.View.Title"
        }
      },
      "fields": {
        "file": {
          "invalidformat": "Packages.Signed.Fields.File.InvalidFormat",
          "label": "Packages.Signed.Fields.File.Label",
          "required": "Packages.Signed.Fields.File.Required"
        },
        "name": {
          "label": "Packages.Signed.Fields.Name.Label",
          "required": "Packages.Signed.Fields.Name.Required"
        }
      },
      "label": "Packages.Signed.Label"
    },
    "supporting": {
      "buttons": {
        "add": "Packages.Supporting.Buttons.Add"
      },
      "dialogs": {
        "create": {
          "title": "Packages.Supporting.Dialogs.Create.Title"
        },
        "delete": {
          "message": "Packages.Supporting.Dialogs.Delete.Message",
          "title": "Packages.Supporting.Dialogs.Delete.Title"
        },
        "update": {
          "title": "Packages.Supporting.Dialogs.Update.Title"
        }
      },
      "fields": {
        "name": {
          "label": "Packages.Supporting.Fields.Name.Label",
          "required": "Packages.Supporting.Fields.Name.Required"
        }
      },
      "label": "Packages.Supporting.Label"
    }
  },
  "picker": {
    "user": {
      "label": "Picker.User.Label"
    }
  },
  "resendinvitation": {
    "button": "ReSendInvitation.Button"
  },
  "reader": {
    "stepper": {
      "edit": {
        "label": "Reader.Stepper.Edit.Label"
      }
    }
  },
  "referentials": {
    "actions": "Referentials.Actions",
    "addelement": "Referentials.AddElement",
    "createreferential": "Referentials.CreateReferential",
    "datakey": "Referentials.DataKey",
    "datavalue": "Referentials.DataValue",
    "deleteelement": "Referentials.DeleteElement",
    "deletereferential": "Referentials.DeleteReferential",
    "elementcount": "Referentials.ElementCount",
    "exportreferential": "Referentials.ExportReferential",
    "file": "Referentials.File",
    "name": "Referentials.Name",
    "noelementfound": "Referentials.NoElementFound",
    "noreferentialfound": "Referentials.NoReferentialFound",
    "seereferential": "Referentials.SeeReferential",
    "updateelement": "Referentials.UpdateElement",
    "updatereferential": "Referentials.UpdateReferential"
  },
  "referentialsview": {
    "fields": {
      "document": "ReferentialsView.Fields.Document",
      "keyfieldname": "ReferentialsView.Fields.KeyFieldName",
      "name": "ReferentialsView.Fields.Name"
    },
    "tableheaders": {
      "actions": {
        "label": "ReferentialsView.TableHeaders.Actions.Label"
      },
      "count": {
        "label": "ReferentialsView.TableHeaders.Count.Label"
      },
      "name": {
        "label": "ReferentialsView.TableHeaders.Name.Label"
      },
      "provider": {
        "label": "ReferentialsView.TableHeaders.Provider.Label"
      }
    }
  },
  "remindersheetmodal": {
    "buttons": {
      "delete": {
        "label": "ReminderSheetModal.Buttons.Delete.Label"
      },
      "to": {
        "register": {
          "label": "ReminderSheetModal.Buttons.To.Register.Label"
        }
      }
    },
    "content": {
      "marktreated": {
        "text": "ReminderSheetModal.Content.MarkTreated.Text"
      },
      "relateddocument": {
        "text": "ReminderSheetModal.Content.RelatedDocument.Text"
      },
      "reminderdate": {
        "text": "ReminderSheetModal.Content.ReminderDate.Text"
      }
    },
    "popup": {
      "complete": {
        "description": "ReminderSheetModal.Popup.Complete.Description",
        "title": "ReminderSheetModal.Popup.Complete.Title"
      },
      "uncomplete": {
        "description": "ReminderSheetModal.Popup.Uncomplete.Description",
        "title": "ReminderSheetModal.Popup.Uncomplete.Title"
      }
    },
    "reminderdate": {
      "text": "ReminderSheetModal.ReminderDate.Text"
    }
  },
  "resetpassword": {
    "button": "ResetPassword.Button",
    "fields": {
      "confirmpassword": {
        "different": "ResetPassword.Fields.ConfirmPassword.Different",
        "label": "ResetPassword.Fields.ConfirmPassword.Label",
        "required": "ResetPassword.Fields.ConfirmPassword.Required"
      },
      "newpassword": {
        "label": "ResetPassword.Fields.NewPassword.Label",
        "required": "ResetPassword.Fields.NewPassword.Required"
      }
    },
    "gotosignin": "ResetPassword.GoToSignIn"
  },
  "sharedmenu": {
    "activity": {
      "label": "SharedMenu.Activity.Label",
      "nodata": "SharedMenu.Activity.NoData"
    },
    "element": {
      "nodata": "SharedMenu.Element.NoData"
    },
    "fiche": {
      "label": "SharedMenu.Fiche.Label"
    },
    "info": {
      "label": "SharedMenu.Info.Label"
    },
    "workflow": {
      "label": "SharedMenu.Workflow.Label",
      "nodata": "SharedMenu.Workflow.NoData"
    }
  },
  "sharedmodal": {
    "document": {
      "label": "SharedModal.Document.Label"
    },
    "locations": {
      "label": "SharedModal.Locations.Label"
    }
  },
  "signin": {
    "button": "Signin.Button",
    "fields": {
      "email": {
        "invalid": "Signin.Fields.Email.Invalid",
        "label": "Signin.Fields.Email.Label",
        "required": "Signin.Fields.Email.Required"
      },
      "password": {
        "label": "Signin.Fields.Password.Label",
        "required": "Signin.Fields.Password.Required"
      },
      "passwordconfirmation": {
        "label": "Signin.Fields.PasswordConfirmation.Label"
      },
      "passwordreset": {
        "label": "Signin.Fields.PasswordReset.Label"
      }
    },
    "forgetpassword": {
      "description": {
        "field": {
          "message": "Signin.ForgetPassword.Description.Field.Message"
        }
      }
    },
    "password": {
      "requirements": {
        "validate": {
          "message": "Signin.Password.Requirements.Validate.Message"
        }
      }
    },
    "reset": {
      "password": {
        "fields": {
          "description": {
            "message": "Signin.Reset.Password.Fields.Description.Message"
          }
        }
      }
    }
  },
  "statisticsview": {
    "sections": {
      "card": {
        "averagesigning": {
          "text": "StatisticsView.Sections.Card.AverageSigning.Text"
        },
        "inscriptions": {
          "text": "StatisticsView.Sections.Card.Inscriptions.Text"
        },
        "negociation": {
          "text": "StatisticsView.Sections.Card.Negociation.Text"
        },
        "trendingmonth": {
          "text": "StatisticsView.Sections.Card.TrendingMonth.Text"
        }
      },
      "cards": {
        "contract": {
          "label": "StatisticsView.Sections.Cards.Contract.Label"
        },
        "creationdocuments": {
          "label": "StatisticsView.Sections.Cards.CreationDocuments.Label"
        },
        "distribution": {
          "label": "StatisticsView.Sections.Cards.Distribution.Label"
        },
        "documents": {
          "label": "StatisticsView.Sections.Cards.Documents.Label"
        },
        "documentsstatistics": {
          "header": "StatisticsView.Sections.Cards.DocumentsStatistics.Header"
        },
        "draft": {
          "label": "StatisticsView.Sections.Cards.Draft.Label"
        },
        "evolutionusestatistics": {
          "header": "StatisticsView.Sections.Cards.EvolutionUseStatistics.Header"
        },
        "externalusers": {
          "label": "StatisticsView.Sections.Cards.ExternalUsers.Label"
        },
        "inscriptions": {
          "label": "StatisticsView.Sections.Cards.Inscriptions.Label"
        },
        "internalusers": {
          "label": "StatisticsView.Sections.Cards.InternalUsers.Label"
        },
        "library": {
          "label": "StatisticsView.Sections.Cards.Library.Label"
        },
        "negotiation": {
          "label": "StatisticsView.Sections.Cards.Negotiation.Label"
        },
        "none": {
          "label": "StatisticsView.Sections.Cards.None.Label"
        },
        "other": {
          "label": "StatisticsView.Sections.Cards.Other.Label"
        },
        "published": {
          "label": "StatisticsView.Sections.Cards.Published.Label"
        },
        "registereddocuments": {
          "label": "StatisticsView.Sections.Cards.RegisteredDocuments.Label"
        },
        "registereddocumentsbystatus": {
          "label": "StatisticsView.Sections.Cards.RegisteredDocumentsByStatus.Label"
        },
        "registrationsconnections": {
          "label": "StatisticsView.Sections.Cards.RegistrationsConnections.Label"
        },
        "signature": {
          "label": "StatisticsView.Sections.Cards.Signature.Label"
        },
        "signaturestatistics": {
          "header": "StatisticsView.Sections.Cards.SignatureStatistics.Header"
        },
        "signaturetime": {
          "text": "StatisticsView.Sections.Cards.SignatureTime.Text"
        },
        "signed": {
          "label": "StatisticsView.Sections.Cards.Signed.Label"
        },
        "signeddocuments": {
          "label": "StatisticsView.Sections.Cards.SignedDocuments.Label"
        },
        "templaterepartition": {
          "label": "StatisticsView.Sections.Cards.TemplateRepartition.Label"
        },
        "templaterepartitionsignature": {
          "label": "StatisticsView.Sections.Cards.TemplateRepartitionSignature.Label"
        },
        "validated": {
          "label": "StatisticsView.Sections.Cards.Validated.Label"
        },
        "visitsusesbyperiodstatistics": {
          "header": "StatisticsView.Sections.Cards.VisitsUsesByPeriodStatistics.Header"
        }
      },
      "documents": "StatisticsView.Sections.Documents",
      "group": "StatisticsView.Sections.Group",
      "hours": "StatisticsView.Sections.Hours",
      "models": "StatisticsView.Sections.Models",
      "registrations": "StatisticsView.Sections.Registrations",
      "signeddocuments": "StatisticsView.Sections.SignedDocuments",
      "users": "StatisticsView.Sections.Users"
    },
    "tooltip": {
      "differencepercentage": {
        "text": "StatisticsView.Tooltip.DifferencePercentage.Text"
      },
      "notavailable": {
        "text": "StatisticsView.Tooltip.NotAvailable.Text"
      }
    }
  },
  "suppliers": {
    "noresult": "Suppliers.NoResult",
    "search": {
      "label": "Suppliers.Search.Label"
    }
  },
  "teams": {
    "leaderuser": {
      "label": "Teams.LeaderUser.Label",
      "required": "Teams.LeaderUser.Required"
    },
    "noresult": "Teams.NoResult",
    "projectmanager": {
      "required": "Teams.ProjectManager.Required"
    },
    "teamtitle": "Teams.TeamTitle",
    "tenantadministrator": {
      "required": "Teams.TenantAdministrator.Required"
    },
    "type": {
      "label": "Teams.Type.Label",
      "marketmanager": "Teams.Type.MarketManager",
      "offerleader": "Teams.Type.OfferLeader",
      "other": "Teams.Type.Other",
      "required": "Teams.Type.Required"
    }
  },
  "template": {
    "autocompletion": {
      "save": {
        "error": "Template.AutoCompletion.Save.Error"
      }
    },
    "content": {
      "save": {
        "error": "Template.Content.Save.Error",
        "success": "Template.Content.Save.Success"
      }
    },
    "create": {
      "blank": {
        "button": {
          "label": "Template.Create.Blank.Button.Label"
        },
        "title": "Template.Create.Blank.Title"
      },
      "button": {
        "label": "Template.Create.Button.Label"
      },
      "importword": {
        "button": {
          "label": "Template.Create.ImportWord.Button.Label"
        }
      },
      "importzip": {
        "button": {
          "label": "Template.Create.ImportZip.Button.Label"
        }
      },
      "word": {
        "title": "Template.Create.Word.Title"
      }
    },
    "fields": {
      "name": {
        "label": "Template.Fields.Name.Label"
      }
    },
    "folder": {
      "create": {
        "button": {
          "label": "Template.Folder.Create.Button.Label"
        }
      },
      "fields": {
        "name": {
          "label": "Template.Folder.Fields.Name.Label"
        }
      }
    },
    "header": {
      "lastmodif": {
        "label": "Template.Header.LastModif.Label"
      },
      "name": {
        "label": "Template.Header.Name.Label"
      },
      "statut": {
        "label": "Template.Header.Statut.Label"
      }
    },
    "scopeschema": {
      "save": {
        "error": "Template.ScopeSchema.Save.Error"
      }
    }
  },
  "templatecomponents": {
    "templateoptions": {
      "buttons": {
        "edit": {
          "label": "TemplateComponents.TemplateOptions.Buttons.Edit.Label"
        }
      }
    }
  },
  "templateview": {
    "templateinfo": {
      "card": {
        "createdby": {
          "label": "TemplateView.TemplateInfo.Card.CreatedBy.Label"
        },
        "creationtime": {
          "label": "TemplateView.TemplateInfo.Card.CreationTime.Label"
        },
        "lastmodificationtime": {
          "label": "TemplateView.TemplateInfo.Card.LastModificationTime.Label"
        }
      }
    }
  },
  "templates": {
    "create": {
      "popup": {
        "title": "Templates.Create.Popup.Title"
      }
    },
    "folder": {
      "delete": {
        "description": "Templates.Folder.Delete.Description"
      },
      "fields": {
        "name": {
          "label": "Templates.Folder.Fields.Name.Label"
        }
      }
    }
  },
  "templatesview": {
    "affectation": {
      "breadcrumb": {
        "label": "TemplatesView.Affectation.Breadcrumb.Label"
      },
      "select": {
        "message": "TemplatesView.Affectation.Select.Message"
      },
      "text": "TemplatesView.Affectation.Text",
      "title": "TemplatesView.Affectation.Title",
      "update": {
        "error": {
          "text": "TemplatesView.Affectation.Update.Error.Text"
        },
        "success": {
          "text": "TemplatesView.Affectation.Update.Success.Text"
        }
      }
    },
    "buttons": {
      "addfolder": {
        "label": "TemplatesView.Buttons.AddFolder.Label"
      },
      "addtemplate": {
        "label": "TemplatesView.Buttons.AddTemplate.Label"
      },
      "new": {
        "label": "TemplatesView.Buttons.New.Label"
      }
    },
    "naming": {
      "breadcrumb": {
        "label": "TemplatesView.Naming.Breadcrumb.Label"
      },
      "nomenclature": {
        "availableformats": {
          "text": "TemplatesView.Naming.Nomenclature.AvailableFormats.Text"
        },
        "customnomenclature": {
          "label": "TemplatesView.Naming.Nomenclature.CustomNomenclature.Label"
        },
        "defaultnomenclature": {
          "label": "TemplatesView.Naming.Nomenclature.DefaultNomenclature.Label"
        },
        "document": {
          "placeholder": {
            "text": "TemplatesView.Naming.Nomenclature.Document.Placeholder.Text"
          }
        },
        "dynamicchoices": {
          "creationdate": {
            "label": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Label",
            "options": {
              "daymonthyearlong": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.DayMonthYearLong",
              "daymonthyearshort": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.DayMonthYearShort",
              "monthdayyearlong": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.MonthDayYearLong",
              "monthdayyearshort": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.MonthDayYearShort",
              "yearlongmonthday": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.YearLongMonthDay",
              "yearshortmonthday": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.YearShortMonthDay"
            }
          },
          "creationday": {
            "label": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDay.Label",
            "options": {
              "day": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDay.Options.Day"
            }
          },
          "creationmonth": {
            "label": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationMonth.Label",
            "options": {
              "month": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationMonth.Options.Month"
            }
          },
          "creationyear": {
            "label": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationYear.Label",
            "options": {
              "yearlong": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationYear.Options.YearLong",
              "yearshort": "TemplatesView.Naming.Nomenclature.DynamicChoices.CreationYear.Options.YearShort"
            }
          },
          "foldername": {
            "label": "TemplatesView.Naming.Nomenclature.DynamicChoices.FolderName.Label"
          },
          "referentialelementname": {
            "defaultname": "TemplatesView.Naming.Nomenclature.DynamicChoices.ReferentialElementName.DefaultName",
            "label": "TemplatesView.Naming.Nomenclature.DynamicChoices.ReferentialElementName.Label",
            "nodata": "TemplatesView.Naming.Nomenclature.DynamicChoices.ReferentialElementName.NoData",
            "selectedreferential": "TemplatesView.Naming.Nomenclature.DynamicChoices.ReferentialElementName.SelectedReferential"
          },
          "templatename": {
            "label": "TemplatesView.Naming.Nomenclature.DynamicChoices.TemplateName.Label"
          }
        },
        "explanation": {
          "text": "TemplatesView.Naming.Nomenclature.Explanation.Text"
        },
        "insertdynamicdata": {
          "text": "TemplatesView.Naming.Nomenclature.InsertDynamicData.Text"
        },
        "selectedformat": {
          "text": "TemplatesView.Naming.Nomenclature.SelectedFormat.Text"
        },
        "title": {
          "text": "TemplatesView.Naming.Nomenclature.Title.Text"
        }
      },
      "tabs": {
        "nomenclature": {
          "label": "TemplatesView.Naming.Tabs.Nomenclature.Label"
        },
        "title": {
          "label": "TemplatesView.Naming.Tabs.Title.Label"
        }
      },
      "titletab": {
        "description": {
          "explanation": {
            "text": "TemplatesView.Naming.TitleTab.Description.Explanation.Text"
          },
          "title": {
            "text": "TemplatesView.Naming.TitleTab.Description.Title.Text"
          }
        },
        "name": {
          "explanation": {
            "text": "TemplatesView.Naming.TitleTab.Name.Explanation.Text"
          },
          "title": {
            "text": "TemplatesView.Naming.TitleTab.Name.Title.Text"
          }
        }
      },
      "update": {
        "error": {
          "text": "TemplatesView.Naming.Update.Error.Text"
        },
        "success": {
          "text": "TemplatesView.Naming.Update.Success.Text"
        }
      }
    },
    "popup": {
      "title": "TemplatesView.Popup.Title"
    }
  },
  "tenants": {
    "name": {
      "label": "Tenants.Name.Label",
      "required": "Tenants.Name.Required"
    },
    "negotiationtype": {
      "annual": "Tenants.NegotiationType.Annual",
      "label": "Tenants.NegotiationType.Label",
      "overtime": "Tenants.NegotiationType.Overtime",
      "required": "Tenants.NegotiationType.Required"
    },
    "noresult": "Tenants.NoResult"
  },
  "termsheet": {
    "datasource": {
      "contract": {
        "label": "TermSheet.DataSource.Contract.Label"
      },
      "free": {
        "label": "TermSheet.DataSource.Free.Label"
      }
    },
    "datatype": {
      "boolean": {
        "label": "TermSheet.DataType.Boolean.Label"
      },
      "dateonly": {
        "label": "TermSheet.DataType.DateOnly.Label"
      },
      "number": {
        "label": "TermSheet.DataType.Number.Label"
      },
      "text": {
        "label": "TermSheet.DataType.Text.Label"
      },
      "timeonly": {
        "label": "TermSheet.DataType.TimeOnly.Label"
      },
      "timestamp": {
        "label": "TermSheet.DataType.Timestamp.Label"
      }
    },
    "entities": {
      "elements": {
        "system": {
          "signaturedate": {
            "label": "TermSheet.Entities.Elements.System.SignatureDate.Label"
          },
          "validationdate": {
            "label": "TermSheet.Entities.Elements.System.ValidationDate.Label"
          }
        }
      }
    },
    "schema": {
      "button": {
        "addelement": {
          "label": "TermSheet.Schema.Button.AddElement.Label"
        }
      },
      "editor": {
        "description": "TermSheet.Schema.Editor.Description"
      }
    },
    "schemavalue": {
      "button": {
        "adddatatobecollected": "TermSheet.SchemaValue.Button.AddDataToBeCollected",
        "delete": {
          "label": "TermSheet.SchemaValue.Button.Delete.Label"
        }
      },
      "fields": {
        "datatobecollected": {
          "label": "TermSheet.SchemaValue.Fields.DataToBeCollected.Label"
        },
        "elementtitle": {
          "label": "TermSheet.SchemaValue.Fields.ElementTitle.Label"
        },
        "icon": {
          "label": "TermSheet.SchemaValue.Fields.Icon.Label"
        },
        "source": {
          "label": "TermSheet.SchemaValue.Fields.Source.Label"
        },
        "type": {
          "label": "TermSheet.SchemaValue.Fields.Type.Label"
        }
      }
    }
  },
  "useraccounts": {
    "accountlinkbutton": {
      "label": "UserAccounts.AccountLinkButton.Label"
    },
    "accountstable": {
      "columns": {
        "action": {
          "label": "UserAccounts.AccountsTable.Columns.Action.Label"
        },
        "activation": {
          "label": "UserAccounts.AccountsTable.Columns.Activation.Label"
        },
        "email": {
          "label": "UserAccounts.AccountsTable.Columns.Email.Label"
        },
        "firstname": {
          "label": "UserAccounts.AccountsTable.Columns.FirstName.Label"
        },
        "group": {
          "label": "UserAccounts.AccountsTable.Columns.Group.Label"
        },
        "nom": {
          "label": "UserAccounts.AccountsTable.Columns.Nom.Label"
        },
        "phonenumber": {
          "label": "UserAccounts.AccountsTable.Columns.PhoneNumber.Label"
        },
        "role": {
          "label": "UserAccounts.AccountsTable.Columns.Role.Label"
        }
      },
      "shared": {
        "nom": {
          "label": "UserAccounts.AccountsTable.Shared.Nom.Label"
        }
      }
    },
    "buttons": {
      "adduser": {
        "label": "UserAccounts.Buttons.AddUser.Label"
      },
      "manageroles": {
        "label": "UserAccounts.Buttons.ManageRoles.Label"
      },
      "settings": {
        "label": "UserAccounts.Buttons.Settings.Label"
      }
    },
    "create": {
      "success": {
        "label": "UserAccounts.Create.Success.Label"
      }
    },
    "createnewaccount": {
      "title": {
        "label": "UserAccounts.CreateNewAccount.Title.Label"
      }
    },
    "edit": {
      "title": {
        "label": "UserAccounts.Edit.Title.Label"
      }
    },
    "editaccountmodal": {
      "buttons": {
        "save": {
          "label": "UserAccounts.EditAccountModal.Buttons.Save.Label"
        }
      }
    },
    "lockaccount": {
      "buttons": {
        "save": {
          "label": "UserAccounts.LockAccount.Buttons.Save.Label"
        }
      },
      "description": {
        "text": "UserAccounts.LockAccount.Description.Text"
      },
      "title": {
        "label": "UserAccounts.LockAccount.Title.Label",
        "text": "UserAccounts.LockAccount.Title.Text"
      }
    },
    "unlockaccount": {
      "buttons": {
        "save": {
          "label": "UserAccounts.UnlockAccount.Buttons.Save.Label"
        }
      },
      "description": {
        "text": "UserAccounts.UnlockAccount.Description.Text"
      },
      "title": {
        "label": "UserAccounts.UnlockAccount.Title.Label",
        "text": "UserAccounts.UnlockAccount.Title.Text"
      }
    }
  },
  "verbs": {
    "accept": "Verbs.Accept",
    "add": "Verbs.Add",
    "apply": "Verbs.Apply",
    "browse": "Verbs.Browse",
    "cancel": "Verbs.Cancel",
    "choose": "Verbs.Choose",
    "clear": "Verbs.Clear",
    "close": "Verbs.Close",
    "complete": "Verbs.Complete",
    "confirm": "Verbs.Confirm",
    "consult": "Verbs.Consult",
    "create": "Verbs.Create",
    "delete": "Verbs.Delete",
    "done": "Verbs.Done",
    "download": "Verbs.Download",
    "duplicate": "Verbs.Duplicate",
    "edit": "Verbs.Edit",
    "export": "Verbs.Export",
    "import": "Verbs.Import",
    "invalidate": "Verbs.Invalidate",
    "link": "Verbs.Link",
    "modify": "Verbs.Modify",
    "move": "Verbs.Move",
    "next": "Verbs.Next",
    "previous": "Verbs.Previous",
    "publish": "Verbs.Publish",
    "rectify": "Verbs.Rectify",
    "reject": "Verbs.Reject",
    "rename": "Verbs.Rename",
    "renegociate": "Verbs.Renegociate",
    "restore": "Verbs.Restore",
    "save": "Verbs.Save",
    "search": "Verbs.Search",
    "share": "Verbs.Share",
    "show": "Verbs.Show",
    "sign": "Verbs.Sign",
    "test": "Verbs.Test",
    "unpublish": "Verbs.Unpublish",
    "update": "Verbs.Update",
    "validate": "Verbs.Validate"
  },
  "workflow": {
    "step": {
      "edit": {
        "title": "WorkFlow.Step.Edit.Title"
      },
      "author": {
        "label": "Workflow.Step.Author.Label"
      },
      "buttons": {
        "add": "Workflow.Step.Buttons.Add",
        "back": {
          "label": "Workflow.Step.Buttons.Back.Label"
        },
        "mandatory": {
          "add": "Workflow.Step.Buttons.Mandatory.Add"
        },
        "notifycontributors": {
          "add": "Workflow.Step.Buttons.NotifyContributors.Add"
        },
        "others": {
          "add": "Workflow.Step.Buttons.Others.Add"
        }
      },
      "completed": {
        "label": "Workflow.Step.Completed.Label"
      },
      "contributor": {
        "by": "Workflow.Step.Contributor.By",
        "delegator": "Workflow.Step.Contributor.Delegator"
      },
      "dialogs": {
        "delete": {
          "message": "Workflow.Step.Dialogs.Delete.Message",
          "title": "Workflow.Step.Dialogs.Delete.Title"
        }
      },
      "duration": {
        "message": "Workflow.Step.Duration.Message"
      },
      "fields": {
        "completionmessage": {
          "label": "Workflow.Step.Fields.CompletionMessage.Label",
          "placeholder": "Workflow.Step.Fields.CompletionMessage.Placeholder"
        },
        "contributors": {
          "onlyonecontributorbystep": "Workflow.Step.Fields.Contributors.OnlyOneContributorByStep",
          "required": "Workflow.Step.Fields.Contributors.Required"
        },
        "identifier": {
          "required": "Workflow.Step.Fields.Identifier.Required"
        },
        "mandatory": {
          "label": "Workflow.Step.Fields.Mandatory.Label",
          "required": "Workflow.Step.Fields.Mandatory.Required"
        },
        "name": {
          "default": "Workflow.Step.Fields.Name.Default",
          "label": "Workflow.Step.Fields.Name.Label",
          "required": "Workflow.Step.Fields.Name.Required"
        },
        "notifycontributors": {
          "label": "Workflow.Step.Fields.NotifyContributors.Label"
        },
        "others": {
          "label": "Workflow.Step.Fields.Others.Label"
        }
      }
    },
    "condtion": {
      "conjunction": {
        "and": {
          "label": "Workflow.Condtion.Conjunction.AND.Label"
        },
        "not": {
          "label": "Workflow.Condtion.Conjunction.Not.Label"
        },
        "or": {
          "label": "Workflow.Condtion.Conjunction.OR.Label"
        }
      },
      "operator": {
        "equal": {
          "label": "Workflow.Condtion.Operator.Equal.Label"
        },
        "isempty": {
          "label": "Workflow.Condtion.Operator.IsEmpty.Label"
        },
        "isnotempty": {
          "label": "Workflow.Condtion.Operator.IsNotEmpty.Label"
        },
        "isnotnull": {
          "label": "Workflow.Condtion.Operator.IsNotNull.Label"
        },
        "isnull": {
          "label": "Workflow.Condtion.Operator.IsNull.Label"
        },
        "like": {
          "label": "Workflow.Condtion.Operator.Like.Label"
        },
        "notequal": {
          "label": "Workflow.Condtion.Operator.NotEqual.Label"
        },
        "notlike": {
          "label": "Workflow.Condtion.Operator.NotLike.Label"
        },
        "operator": {
          "label": "Workflow.Condtion.Operator.Operator.Label"
        }
      },
      "remove": {
        "group": {
          "confirmmessage": {
            "label": "Workflow.Condtion.Remove.Group.ConfirmMessage.Label"
          }
        },
        "ok": {
          "label": "Workflow.Condtion.Remove.Ok.Label"
        },
        "rule": {
          "confirmmessage": {
            "label": "Workflow.Condtion.Remove.Rule.ConfirmMessage.Label"
          }
        }
      },
      "type": {
        "addgroup": {
          "label": "Workflow.Condtion.Type.AddGroup.Label"
        },
        "addrule": {
          "label": "Workflow.Condtion.Type.AddRule.Label"
        },
        "addsubrule": {
          "label": "Workflow.Condtion.Type.AddSubRule.Label"
        },
        "field": {
          "label": "Workflow.Condtion.Type.Field.Label"
        },
        "selectfield": {
          "label": "Workflow.Condtion.Type.SelectField.Label"
        },
        "selectoperator": {
          "label": "Workflow.Condtion.Type.SelectOperator.Label"
        },
        "value": {
          "label": "Workflow.Condtion.Type.Value.Label"
        }
      },
      "value": {
        "placeholder": {
          "label": "Workflow.Condtion.Value.Placeholder.Label"
        }
      }
    },
    "contributor": {
      "buttons": {
        "condition": {
          "add": "Workflow.Contributor.Buttons.Condition.Add",
          "edit": "Workflow.Contributor.Buttons.Condition.Edit"
        },
        "remove": {
          "nextstepcontributorwarningtext": "Workflow.Contributor.Buttons.Remove.NextStepContributorWarningText"
        }
      },
      "dialogs": {
        "delete": {
          "message": "Workflow.Contributor.Dialogs.Delete.Message",
          "title": "Workflow.Contributor.Dialogs.Delete.Title"
        }
      },
      "fields": {
        "access": {
          "0": {
            "label": "Workflow.Contributor.Fields.Access.0.Label"
          },
          "1": {
            "label": "Workflow.Contributor.Fields.Access.1.Label"
          },
          "2": {
            "label": "Workflow.Contributor.Fields.Access.2.Label"
          },
          "3": {
            "label": "Workflow.Contributor.Fields.Access.3.Label"
          },
          "4": {
            "label": "Workflow.Contributor.Fields.Access.4.Label"
          },
          "5": {
            "label": "Workflow.Contributor.Fields.Access.5.Label"
          },
          "6": {
            "label": "Workflow.Contributor.Fields.Access.6.Label"
          },
          "7": {
            "label": "Workflow.Contributor.Fields.Access.7.Label"
          },
          "label": "Workflow.Contributor.Fields.Access.Label",
          "required": "Workflow.Contributor.Fields.Access.Required"
        },
        "entity": {
          "required": "Workflow.Contributor.Fields.Entity.Required"
        }
      },
      "modal": {
        "add": {
          "success": "Workflow.Contributor.Modal.Add.Success",
          "title": "Workflow.Contributor.Modal.Add.Title"
        },
        "remove": {
          "description": "Workflow.Contributor.Modal.Remove.Description",
          "title": "Workflow.Contributor.Modal.Remove.Title",
          "success": "Workflow.Contributor.Modal.remove.Success"
        },
        "search": {
          "description": "Workflow.Contributor.Modal.Search.Description",
          "option": {
            "1": {
              "title": "Workflow.Contributor.Modal.Search.Option.1.Title"
            },
            "2": {
              "title": "Workflow.Contributor.Modal.Search.Option.2.Title"
            }
          },
          "select": {
            "label": "Workflow.Contributor.Modal.Search.Select.Label"
          },
          "title": "Workflow.Contributor.Modal.Search.Title"
        }
      }
    },
    "contributorpicker": {
      "addexternaluser": {
        "label": "Workflow.ContributorPicker.AddExternalUser.Label"
      },
      "clear": {
        "text": "Workflow.ContributorPicker.Clear.Text"
      },
      "close": {
        "text": "Workflow.ContributorPicker.Close.Text"
      },
      "label": "Workflow.ContributorPicker.Label",
      "loading": {
        "text": "Workflow.ContributorPicker.Loading.Text"
      },
      "nooptions": {
        "text": "Workflow.ContributorPicker.NoOptions.Text"
      },
      "open": {
        "text": "Workflow.ContributorPicker.Open.Text"
      },
      "users": {
        "label": "Workflow.ContributorPicker.Users.Label"
      }
    },
    "settings": {
      "save": {
        "success": "Workflow.Settings.Save.Success"
      },
      "title": "Workflow.Settings.Title"
    }
  }
};