import React from 'react';
import { useFormikContext } from 'formik';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';

import FormikSelect from '@components/Formik/FormikSelect';
import FormikTextField from '@components/Formik/FormikTextField';

import { requiredOrStripWhen, stripWhenNot } from '@utils/yup-required-when';
import InputAdornment from '@mui/material/InputAdornment';
import FamilyFormMarried from './FamilyFormMarried';
// import FamilyFormPacs from './FamilyFormPacs';
import FamilyFormDivorce from './FamilyFormDivorce';
import fields, { maritalStatuses } from './fields';

const dateSchema = Yup.number()
  .nullable()
  .transform(v => Number(v) || null)
  .max(
    Math.floor(new Date().getTime()),
    'La date ne peut être supérieur à la date du jour'
  )
  .default(null);

export const schemaFamilyForm = Yup.object().shape({
  [fields.maritalStatus]: Yup.string().required('Generic.Fields.Required'),
  [fields.annualResources]: Yup.lazy(values => {
    if (values != null && values !== undefined && values !== '') {
      return Yup.number().min(0, 'Generic.Fields.MustBePositive');
    }
    return Yup.string().default('').required('Generic.Fields.Required');
  }),
  [fields.marriageType]: requiredOrStripWhen(
    Yup.string().trim(),
    fields.maritalStatus,
    maritalStatuses.married
  ),
  [fields.marriageContract]: requiredOrStripWhen(
    Yup.string().trim(),
    fields.maritalStatus,
    maritalStatuses.married
  ),
  [fields.marriageContractDate]: requiredOrStripWhen(
    dateSchema,
    'marriageContract',
    'true'
  ),
  // [fields.dateOfPacs]: requiredOrStripWhen(
  //   dateSchema,
  //   fields.maritalStatus,
  //   maritalStatuses.pacs
  // ),
  // [fields.pacsPlace]: requiredOrStripWhen(
  //   Yup.string().trim(),
  //   fields.maritalStatus,
  //   maritalStatuses.pacs
  // ),
  // [fields.divorceCourt]: requiredOrStripWhen(
  //   Yup.string().trim(),
  //   fields.maritalStatus,
  //   maritalStatuses.divorce
  // ),
  [fields.dateOfDivorce]: requiredOrStripWhen(
    dateSchema,
    fields.maritalStatus,
    maritalStatuses.divorce
  ),
  [fields.divorceSeparated]: stripWhenNot(
    Yup.bool(),
    fields.maritalStatus,
    maritalStatuses.divorce
  ),
  [fields.childrenNumber]: Yup.lazy(value => {
    if (value != null && value !== undefined && value !== '') {
      return Yup.number().min(0, 'Generic.Fields.MustBePositive');
    }
    return Yup.string().default('').required('Generic.Fields.Required');
  }),
});

export const defaultFamilyFormValues = {
  [fields.maritalStatus]: '',
  // [fields.dateOfMarriage]: null,
  // [fields.marriagePlace]: '',
  [fields.marriageType]: '',
  [fields.marriageContract]: '',
  [fields.marriageContractDate]: null,
  // [fields.dateOfPacs]: null,
  // [fields.pacsPlace]: '',
  // [fields.divorceCourt]: '',
  [fields.dateOfDivorce]: null,
  [fields.divorceSeparated]: false,
  [fields.childrenNumber]: '',
  [fields.annualResources]: '',
};

function FamilyForm({ name, index, isFieldReadOnly }) {
  const { values } = useFormikContext();
  const { maritalStatus } = values[name][index];
  const namePrefix = `${name}.${index}`;
  return (
    <Stack direction="column" gap={1} pt={1}>
      <Typography variant="h5" gutterBottom>
        Situation familiale du co-acquéreur {index + 1}
      </Typography>
      <FormikSelect
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.maritalStatus}`)}
        name={`${namePrefix}.${fields.maritalStatus}`}
        label="Statut marital"
        FormControlProps={{
          color: 'secondary',
          margin: 'dense',
          disabled: isFieldReadOnly(`${namePrefix}.${fields.maritalStatus}`),
        }}
        margin="dense"
      >
        <MenuItem value={maritalStatuses.married}>Marié(e)</MenuItem>
        <MenuItem value={maritalStatuses.pacs}>Pacsé(e)</MenuItem>
        <MenuItem value={maritalStatuses.cohabitation}>Concubinage</MenuItem>
        <MenuItem value={maritalStatuses.single}>Célibataire</MenuItem>
        <MenuItem value={maritalStatuses.divorce}>Divorcé(e)</MenuItem>
      </FormikSelect>
      {maritalStatus === maritalStatuses.married && (
        <FamilyFormMarried
          name={name}
          index={index}
          isFieldReadOnly={isFieldReadOnly}
        />
      )}
      {/* {maritalStatus === maritalStatuses.pacs && (
        <FamilyFormPacs name={name} index={index} readOnly={readOnly} />
      )} */}
      {maritalStatus === maritalStatuses.divorce && (
        <FamilyFormDivorce
          name={name}
          index={index}
          isFieldReadOnly={isFieldReadOnly}
        />
      )}
      <FormikTextField
        as="fastfield"
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.annualResources}`)}
        margin="dense"
        color="secondary"
        name={`${namePrefix}.${fields.annualResources}`}
        label="Ressources annuelles"
        type="number"
        InputProps={{
          inputProps: { min: 0 },
          endAdornment: <InputAdornment position="end">&euro;</InputAdornment>,
        }}
      />
      <FormikTextField
        as="fastfield"
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.childrenNumber}`)}
        margin="dense"
        color="secondary"
        name={`${namePrefix}.${fields.childrenNumber}`}
        label="Nombre d'enfants à charge"
        type="number"
        InputProps={{
          inputProps: { min: 0 },
        }}
      />
    </Stack>
  );
}

export default React.memo(FamilyForm);
