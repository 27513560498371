import React from 'react';

function ConditionComponent({ condition = false, children }) {
  if (!condition) return null;
  return children;
}

const withCondition = WrappedComponent =>
  function withConditionWrapped({ condition = false, ...rest }) {
    return (
      <ConditionComponent condition={condition}>
        <WrappedComponent {...rest} />
      </ConditionComponent>
    );
  };

export default withCondition;
