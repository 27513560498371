import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BubbleMenu } from '@tiptap/react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { useTiptapEditor } from '../use-tiptap-editor';

import ButtonTableHeader from './buttons/button-table-header';
import ButtonTableRow from './buttons/button-table-row';
import ButtonTableColumn from './buttons/button-table-column';
import ButtonTableCell from './buttons/button-table-cell';
import ButtonTableBorderStyle from './buttons/button-table-border-style';
import ButtonTableBorderAround from './buttons/button-table-border-around';
import ButtonTableBorderColor from './buttons/button-table-border-color';
import ButtonTableColor from './buttons/button-table-color';
import ButtonTableVerticalAlign from './buttons/button-table-vertical-align';

function BubbleTable() {
  const editor = useTiptapEditor();
  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{ duration: 100, maxWidth: 'none' }}
      shouldShow={({ editor, state: { selection } }) =>
        editor.isActive(editor.schema.nodes.table.name) &&
        (selection.empty || selection?.constructor.name === 'CellSelection')
      }
    >
      <ButtonGroup
        variant="text"
        sx={{
          bgcolor: 'common.white',
          borderStyle: 'solid',
          borderColor: 'primary.light',
        }}
      >
        <ButtonTableHeader />
        <ButtonTableRow />
        <ButtonTableColumn />
        <ButtonTableCell />
        <ButtonTableBorderStyle />
        <ButtonTableBorderAround />
        <ButtonTableBorderColor />
        <ButtonTableColor />
        <ButtonTableVerticalAlign />
        <Tooltip
          placement="top"
          title={<FormattedMessage id="Editor.Toolbar.Command.Table.Delete" />}
        >
          <Button
            sx={{ color: 'error.main' }}
            onClick={() => editor.chain().focus().deleteTable().run()}
          >
            <DeleteOutlineOutlinedIcon />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </BubbleMenu>
  );
}

export default BubbleTable;
