import { defineMessages } from 'react-intl';

export default defineMessages({
  ExpertButtonsAdd: {
    id: 'Expert.Buttons.Add',
    defaultMessage: 'Add an expert',
  },
  ExpertiseNoResult: {
    id: 'Expertise.NoResult',
    defaultMessage: 'No expertise for the current department',
  },
  ExpertiseDialogsCreateTitle: {
    id: 'Expertise.Dialogs.Create.Title',
    defaultMessage: 'Add an expertise',
  },
  ExpertiseDialogsUpdateTitle: {
    id: 'Expertise.Dialogs.Update.Title',
    defaultMessage: 'Modify an expertise',
  },
  ExpertiseDialogsDeleteTitle: {
    id: 'Expertise.Dialogs.Delete.Title',
    defaultMessage: 'Delete an expertise',
  },
  ExpertiseDialogsDeleteMessage: {
    id: 'Expertise.Dialogs.Delete.Message',
    defaultMessage: 'You are about to delete the expertise <b>{name}</b>',
  },
  ExpertiseFieldsExpertLabel: {
    id: 'Expertise.Fields.Expert.Label',
    defaultMessage: 'Collaborator (Name or LDAP number)',
  },
  ExpertiseFieldsExpertRequired: {
    id: 'Expertise.Fields.Expert.Required',
    defaultMessage: 'Collaborator is required',
  },
  ExpertiseFieldsNameLabel: {
    id: 'Expertise.Fields.Name.Label',
    defaultMessage: 'Expertise name',
  },
  ExpertiseFieldsNameRequired: {
    id: 'Expertise.Fields.Name.Required',
    defaultMessage: 'Expertise name is required',
  },
  ExpertiseFieldsNotificationModeLabel: {
    id: 'Expertise.Fields.NotificationMode.Label',
    defaultMessage: 'Role of the Expert during the negociation',
  },
  ExpertiseFieldsNotificationModeRequired: {
    id: 'Expertise.Fields.NotificationMode.Required',
    defaultMessage: 'Role of the Expert during the negociation is required',
  },
  ExpertiseFieldsNotificationModeProjectManager: {
    id: 'Expertise.Fields.NotificationMode.ProjectManager',
    defaultMessage:
      'Experts only intervene at the request of the offer leader who is their only intermediary',
  },
  ExpertiseFieldsNotificationModeExpert: {
    id: 'Expertise.Fields.NotificationMode.Expert',
    defaultMessage:
      'Experts are contacted directly as soon as a supplier asks a question about the contract',
  },
});
