export const maritalStatuses = {
  married: 'married',
  pacs: 'pacs',
  cohabitation: 'cohabitation',
  single: 'single',
  divorce: 'divorce',
};

const fields = {
  maritalStatus: 'maritalStatus',
  dateOfMarriage: 'dateOfMarriage',
  marriagePlace: 'marriagePlace',
  marriageType: 'marriageType',
  marriageContract: 'marriageContract',
  marriageContractDate: 'marriageContractDate',
  dateOfPacs: 'dateOfPacs',
  pacsPlace: 'pacsPlace',
  divorceCourt: 'divorceCourt',
  dateOfDivorce: 'dateOfDivorce',
  divorceSeparated: 'divorceSeparated',
  childrenNumber: 'childrenNumber',
  annualResources: 'annualResources',
};

export default fields;
