import { atom, selector } from 'recoil';
import decode from 'jwt-decode';
import { resolve } from '@utils/authentication';

const defaultState = resolve('host-token');

export const hostAuthenticationState = atom({
  key: 'hostAuthenticationState',
  default: defaultState,
});

export const hostIsAuthenticatedSelector = selector({
  key: 'hostIsAuthenticatedSelector',
  get: ({ get }) => {
    const state = get(hostAuthenticationState);
    return state;
  },
  set: ({ set }, newValue) => {
    if (newValue === null) {
      localStorage.removeItem('host-token');
    } else {
      localStorage.setItem('host-token', JSON.stringify(newValue));
    }
    set(hostAuthenticationState, newValue);
  },
});

export const hostTokenSelector = selector({
  key: 'hostTokenSelector',
  get: ({ get }) => {
    const state = get(hostAuthenticationState);
    return state?.token?.value ?? '';
  },
});

export const hostTenantsSelector = selector({
  key: 'hostTenantsSelector',
  get: ({ get }) => {
    const state = get(hostAuthenticationState);
    return state?.tenants ?? [];
  },
});

export const hostPermissionsSelector = selector({
  key: 'hostPermissionsSelector',
  get: ({ get }) => {
    const state = get(hostAuthenticationState);
    if ((state?.token?.value ?? '') === '') {
      return '';
    }
    const decoded = decode(state?.token?.value);
    return (
      decoded[
        'https://schemas.seraphin.legal/2022/01/identity/claims/permissions'
      ] ?? ''
    )
      .split('')
      .map(c => c.charCodeAt(0));
  },
});
