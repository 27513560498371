import { IntlShape, useIntl } from 'react-intl';

export function intlMessageExists(
  intl: IntlShape,
  id: string | null | undefined
) {
  return !!id && !!intl.messages[id];
}

export default function useIntlMessageExists(id: string | null | undefined) {
  const intl = useIntl();
  return intlMessageExists(intl, id);
}
