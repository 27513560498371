import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import Box from '@mui/material/Box';

import FormikProvider from '@components/Formik/FormikProvider';
import FormikSubmitOnChange from '@components/Formik/FormikSubmitOnChange';
import FormikFileField from '@components/Formik/FormikFileField';

const defaultSchema = Yup.object().shape({
  file: Yup.mixed().test(
    'fileFormat',
    'Editor.Image.InvalidFormat',
    value => value && value.type.startsWith('image/')
  ),
});

const defaultValues = {
  file: '',
};

function ButtonImagePanelUpload({ onSubmit }) {
  return (
    <Box sx={{ px: 2 }}>
      <FormikProvider
        schema={defaultSchema}
        initialValues={defaultValues}
        onSubmit={values => onSubmit(values.file)}
      >
        <FormikSubmitOnChange />
        <FormikFileField
          name="file"
          label={<FormattedMessage id="Editor.Image.FromComputer.Label" />}
          required
          onDrop={event => console.log(event)}
          inputProps={{
            accept: 'image/*',
            onDrop: event => console.log(event),
          }}
        />
      </FormikProvider>
    </Box>
  );
}

export default ButtonImagePanelUpload;
