import Grid from '@mui/material/Grid';
import { useField } from 'formik';
import Checkbox from '@mui/material/Checkbox';
import { iconOptions } from './constants';

export default function BorderedIcon({ name = 'icon', disabled = false }) {
  const [, meta, helpers] = useField(name);
  const active = meta.value;
  const { setValue } = helpers;
  return (
    <Grid container alignItems="center" gap={2}>
      {iconOptions.map(option => {
        const checked = active?.toLowerCase() === option.name.toLowerCase();
        return (
          <Grid key={option.name} item>
            <Checkbox
              sx={{
                bgcolor: 'primary.light',
                border: '1px solid',
                borderColor: checked ? 'primary.main' : 'primary.light',
                borderRadius: 2,
              }}
              icon={<option.BorderIcon sx={{ color: 'primary.main' }} />}
              checkedIcon={<option.Icon sx={{ color: 'primary.main' }} />}
              checked={checked}
              onChange={() => !disabled && setValue(option.name)}
              disabled={disabled}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
