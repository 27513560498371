import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';

import { toHslColor } from '@utils/hue-from-string';
import { compute } from '@utils/username-helpers';

function UserChip({
  email,
  pictureUrl = '',
  firstName = '',
  lastName = '',
  disabled,
  ...rest
}) {
  const username = compute(email, firstName, lastName, '');
  return (
    <Chip
      icon={disabled ? <NoAccountsIcon /> : null}
      avatar={
        !disabled ? (
          <Avatar sx={{ bgcolor: toHslColor(email ?? '') }} src={pictureUrl}>
            {username.first}
          </Avatar>
        ) : null
      }
      label={username.value}
      {...rest}
    />
  );
}

UserChip.propTypes = {
  email: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

UserChip.defaultProps = {
  pictureUrl: '',
  firstName: '',
  lastName: '',
};

export default UserChip;
