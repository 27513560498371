import { atom } from 'recoil';

export const editorDialogTypes = {
  delete: {
    variable: 'delete-variable',
    variant: 'delete-variant',
    clause: 'delete-clause',
    clauseQdata: 'delete-clause-qdata',
  },
  add: {
    variablelinked: 'add-variable-linked',
  },
  edit: {
    conditions: 'edit-conditions',
  },
};

export const showEditorDialog = atom({
  key: 'showEditorDialog',
  default: {
    type: '',
    pos: 0,
  },
});
