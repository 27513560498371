import {
  SearchFilter,
  SearchFilterType,
} from '@components/Search/Filters/helpers/types';
import { useNexityTimelineSteps } from '@shells/nexity/nexity-constants';

const useFilters = () => {
  const nexityTimelineSteps = useNexityTimelineSteps();

  const ProspectFilter: SearchFilter = {
    id: 'BuyersQuery',
    title: 'Prospect',
    type: SearchFilterType.Text,
    showSearchButton: true,
  };

  const VendeurFilter: SearchFilter = {
    id: 'DealerQuery',
    title: 'Vendeur',
    type: SearchFilterType.Text,
    showSearchButton: true,
  };

  const EtapeFilter: SearchFilter = {
    id: 'TimelineStepIndexes',
    title: 'Etape',
    type: SearchFilterType.Select,
    multiple: true,
    showSearchButton: true,
    data: nexityTimelineSteps,
    getValueFromOption: (step: any, index: number) => index,
  };

  const defaultFilters = [ProspectFilter, VendeurFilter, EtapeFilter] as const;

  return defaultFilters;
};

export default useFilters;
