import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FourKIcon from '@mui/icons-material/FourK';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import HistoryIcon from '@mui/icons-material/History';
import PanToolIcon from '@mui/icons-material/PanTool';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import CancelIcon from '@mui/icons-material/Cancel';

const STATUSES = [
  {
    color: 'chipSuccess',
    text: 'Recu',
    icon: CheckCircleIcon,
  },
  {
    color: 'chipWarning',
    text: 'En Attente',
    icon: AccessTimeFilledIcon,
  },
  {
    color: 'chipError',
    text: 'Completion',
    icon: AccessTimeFilledIcon,
  },
  {
    color: 'chipWarning',
    text: 'En cours',
    icon: PlayCircleFilledIcon,
  },
  {
    color: 'chipError',
    text: 'MANQUANT',
    icon: AccessTimeFilledIcon,
  },
  {
    color: 'chipSuccess',
    text: 'Publié',
    icon: CheckCircleIcon,
  },
  {
    color: 'chipDefault',
    text: 'Brouillon',
    icon: FourKIcon,
  },
  {
    color: 'chipPending',
    text: 'En attente de signature',
    icon: CheckCircleIcon,
  },
  {
    color: 'chipSuccess',
    text: 'Signé',
    icon: CheckCircleIcon,
  },
  {
    color: 'chipSuccess',
    text: 'Validé',
    icon: CheckCircleIcon,
  },
  {
    color: 'chipPending',
    text: 'Etape actuelle',
    icon: HistoryIcon,
  },
  {
    color: 'chipError',
    text: 'Rejeté',
    icon: PanToolIcon,
  },
  {
    color: 'chipSuccess',
    text: 'Terminé',
    icon: CheckCircleIcon,
  },
  {
    color: 'chipError',
    text: 'Abandonné',
    icon: CancelIcon,
  },
];

// eslint-disable-next-line arrow-body-style
const StyledChip = styled(Chip)(({ theme, color }) => {
  return {
    color: theme.palette[color].contrastText,
    borderColor: theme.palette[color].main,
    padding: '3px',
    height: 'auto',
    borderRadius: '13px',
    fontSize: '9px',
    textTransform: 'uppercase',
    fontWeight: 700,
    marginTop: 0,
  };
});

export function StatusChipInner({ color, icon: Icon, label, ...rest }) {
  return (
    <StyledChip
      color={color}
      icon={<Icon sx={{ fontSize: '13px' }} />}
      label={label}
      {...rest}
    />
  );
}

export default function StatusChip({ status, ...rest }) {
  const chipStatus = STATUSES[status - 1];
  if (!chipStatus) return null;
  return (
    <StatusChipInner
      color={chipStatus.color}
      icon={chipStatus.icon}
      label={chipStatus.text}
      {...rest}
    />
  );
}

StatusChip.propTypes = {
  status: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14])
    .isRequired,
};
