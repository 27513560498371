import React from 'react';
import Stack from '@mui/material/Stack';
import Spinner from '@components/Spinner';

function Loader({ ...props }) {
  return (
    <Stack alignItems="center" justifyContent="center" {...props}>
      <Spinner />
    </Stack>
  );
}

export default Loader;
