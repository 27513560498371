import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import Box from '@mui/material/Box';

import FormikProvider from '@components/Formik/FormikProvider';
import FormikSubmitOnChange from '@components/Formik/FormikSubmitOnChange';
import FormikTextField from '@components/Formik/FormikTextField';

const defaultSchema = Yup.object().shape({
  url: Yup.string()
    .ensure()
    .trim()
    .required('Editor.Image.FromInternet.Required')
    .url('Editor.Image.FromInternet.InvalidFormat'),
});

const defaultValues = {
  url: '',
};

function ButtonImagePanelUrl({ onSubmit }) {
  return (
    <Box sx={{ px: 2 }}>
      <FormikProvider
        schema={defaultSchema}
        initialValues={defaultValues}
        onSubmit={values => onSubmit(values.url)}
      >
        <FormikSubmitOnChange />
        <FormikTextField
        as="fastfield"
          name="url"
          label={<FormattedMessage id="Editor.Image.FromInternet.Label" />}
          required
        />
      </FormikProvider>
    </Box>
  );
}

export default ButtonImagePanelUrl;
