import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { ComponentProps, useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import { FormattedMessage } from 'react-intl';
import { DriveEntityType } from '@views/constants';
import DocumentActivies from './Activities/DocumentActivities';
import TermSheet from './TermSheet/TermSheet';
import FolderInfo from './FolderInfo/FolderInfo';
import DocumentWorkflow from './Workflow/DocumentWorkflow';
import DocumentLinksCard from '../DocumentLinksCard/DocumentLinksCard';

export const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #CCCCCC',
  '& .MuiTabs-indicator': {
    backgroundColor: 'primary.main',
  },
});

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontSize: '11px',
  fontWeight: 400,
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: (theme.palette.primary as any)[200],
    borderRadius: '4px',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

function TabPanel(
  props: ComponentProps<typeof Box> & { value: number; index: number }
) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`info-tabpanel-${index}`}
      aria-labelledby={`info-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `info-tab-${index}`,
    'aria-controls': `info-tabpanel-${index}`,
  };
}

const infoTab = {
  messageId: 'SharedMenu.Info.Label',
  defaultMessage: 'Info',
} as const;

const termSheetTab = {
  messageId: 'SharedMenu.Fiche.Label',
  defaultMessage: 'Fiche',
} as const;

const activityTab = {
  messageId: 'SharedMenu.Activity.Label',
  defaultMessage: 'Activity',
} as const;

const workflowTab = {
  messageId: 'SharedMenu.Workflow.Label',
  defaultMessage: 'Workflow',
} as const;

export default function ShareMenuTabs({
  entityId,
  entityType,
  parentId,
  contract,
  fetching,
  isContractView,
  item,
  termSheetFetch,
  hideInfoTab,
  documentLinksfetch,
  contractFetch,
}: {
  entityId: string;
  entityType: DriveEntityType;
  parentId: string;
  contract: any;
  fetching: boolean;
  isContractView: boolean;
  item: any;
  hideInfoTab?: boolean;
  termSheetFetch: ComponentProps<typeof TermSheet>['termSheetFetch'];
  documentLinksfetch: ComponentProps<
    typeof DocumentLinksCard
  >['documentLinksfetch'];
  contractFetch: ComponentProps<typeof TermSheet>['contractFetch'];
}) {
  const shownTabs = hideInfoTab
    ? [termSheetTab, activityTab, workflowTab]
    : [infoTab, termSheetTab, activityTab, workflowTab];

  const [value, setValue] = useState(0);

  const handleChange = (event: unknown, newValue: any) => {
    setValue(newValue);
  };

  const isFolder = item?.type === 'folder';
  useEffect(() => {
    if (isFolder) {
      setValue(0);
    }
  }, [isFolder]);

  return (
    <Box>
      {!isFolder ? (
        <StyledTabs value={value} onChange={handleChange}>
          {shownTabs.map((shownTab, index) => (
            <StyledTab
              label={
                <FormattedMessage
                  id={shownTab.messageId}
                  defaultMessage={shownTab.defaultMessage}
                />
              }
              {...a11yProps(index)}
            />
          ))}
        </StyledTabs>
      ) : (
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab
            label={
              <FormattedMessage
                id={infoTab.messageId}
                defaultMessage={infoTab.defaultMessage}
              />
            }
            {...a11yProps(0)}
          />
        </StyledTabs>
      )}
      {shownTabs.map((shownTab, index) => (
        <TabPanel value={value} index={index}>
          {shownTab === infoTab && Boolean(item) && (
            <ErrorBoundary>
              <FolderInfo
                documentInformation={item}
                fetching={fetching}
                documentLinksfetch={documentLinksfetch}
              />
            </ErrorBoundary>
          )}
          {shownTab === termSheetTab && !isFolder && (
            <ErrorBoundary>
              <TermSheet
                entityId={entityId}
                entityType={entityType}
                parentId={parentId}
                isContractView={isContractView}
                termSheetFetch={termSheetFetch}
                contractFetch={contractFetch}
              />
            </ErrorBoundary>
          )}
          {shownTab === activityTab && !isFolder && (
            <ErrorBoundary>
              <DocumentActivies documentId={entityId} />
            </ErrorBoundary>
          )}
          {shownTab === workflowTab && !isFolder && (
            <ErrorBoundary>
              <DocumentWorkflow contract={contract} fetching={fetching} />
            </ErrorBoundary>
          )}
        </TabPanel>
      ))}
    </Box>
  );
}
