import { useState, ReactNode, SyntheticEvent } from 'react';
import { Field, FastField, FieldProps } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useDebounced from '@hooks/useDebounced';
import useFetch from '@hooks/useFetch';
import {
  TermSheetElementResponse,
  TermSheetElementsListResponse,
} from '@utils/api/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { valueFromPath } from '@utils/json-path';
import { getTermSheetElementDisplayName } from '@views/MangeData/components/utils';

type FormikTermSheetElementSelectProps = {
  name: string;
  as?: 'field' | 'fastfield';
  label?: ReactNode;
  required?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  onChange?: (
    event: SyntheticEvent<Element, Event>,
    newValue: TermSheetElementResponse | null
  ) => void;
  disabled?: boolean;
};

type MuiTermSheetElementSelectProps =
  FieldProps<TermSheetElementResponse | null> &
    FormikTermSheetElementSelectProps;

function MuiTermSheetElementSelect({
  field,
  form: { touched, errors },
  label,
  required,
  margin,
  onChange,
  disabled,
}: MuiTermSheetElementSelectProps) {
  const intl = useIntl();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounced(query, 500);

  const searchFetch = useFetch<TermSheetElementsListResponse>({
    method: 'GET',
    url: `term-sheets/elements?limit=20&query=${encodeURIComponent(
      debouncedQuery
    )}`,
  });

  const termSheetElements = searchFetch.data?.termSheetElements;

  const { name } = field;
  const error = name.includes('.') ? valueFromPath(errors, name) : errors[name];
  const touch = name.includes('.')
    ? valueFromPath(touched, name)
    : touched[name];
  const hasError = Boolean(touch) && Boolean(error);

  return (
    <Autocomplete<TermSheetElementResponse>
      fullWidth
      loading={searchFetch.fetching}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.name
      }
      filterOptions={x => x}
      options={termSheetElements ?? []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      noOptionsText="Aucun élément disponible"
      loadingText="Chargement..."
      {...field}
      disabled={disabled}
      multiple={undefined}
      onChange={onChange ?? field?.onChange}
      onInputChange={(event, newInputValue) => setQuery(newInputValue)}
      isOptionEqualToValue={(option, v) => option?.id === v?.id}
      sx={{
        '& .MuiOutlinedInput-root': {
          padding: 0,
        },
      }}
      renderInput={params => (
        <TextField
          label={label}
          required={required}
          margin={margin}
          {...params}
          helperText={
            hasError && (
              <FormattedMessage
                id={error}
                values={{
                  label: label ?? '',
                  value: field?.value ?? '',
                }}
              />
            )
          }
        />
      )}
      renderOption={(props, option) => (
        <li {...props} title={option.id}>
          <Typography variant="body1">
            {getTermSheetElementDisplayName(intl, option)}
          </Typography>
        </li>
      )}
    />
  );
}

function FormikTermSheetElementSelect({
  as = 'field',
  ...props
}: FormikTermSheetElementSelectProps) {
  if (as === 'field') {
    return <Field component={MuiTermSheetElementSelect} {...props} />;
  }
  return <FastField component={MuiTermSheetElementSelect} {...props} />;
}

export default FormikTermSheetElementSelect;
