import { defineMessages } from 'react-intl';

export default defineMessages({
  PackagesNegociatedLabel: {
    id: 'Packages.Negociated.Label',
    defaultMessage: 'Document to negociate',
  },
  PackagesSignedLabel: {
    id: 'Packages.Signed.Label',
    defaultMessage: 'Non-negotiable document',
  },
  PackagesSupportingLabel: {
    id: 'Packages.Supporting.Label',
    defaultMessage: 'Document to provide',
  },
  PackagesNegociatedButtonsAdd: {
    id: 'Packages.Negociated.Buttons.Add',
    defaultMessage: 'Add a negotiable document',
  },
  PackagesNoResult: {
    id: 'Packages.NoResult',
    defaultMessage:
      'The contractual pack was not found, please contact an administrator.',
  },
  PackagesSignedButtonsAdd: {
    id: 'Packages.Signed.Buttons.Add',
    defaultMessage: 'Add a document to be signed',
  },
  PackagesSupportingButtonsAdd: {
    id: 'Packages.Supporting.Buttons.Add',
    defaultMessage: 'Add a document to provide',
  },
  PackagesNegociatedDialogsCreateTitle: {
    id: 'Packages.Negociated.Dialogs.Create.Title',
    defaultMessage: 'Create a negotiable document',
  },
  PackagesNegociatedDialogsUpdateTitle: {
    id: 'Packages.Negociated.Dialogs.Update.Title',
    defaultMessage: 'Modify a negotiable document',
  },
  PackagesNegociatedDialogsDeleteTitle: {
    id: 'Packages.Negociated.Dialogs.Delete.Title',
    defaultMessage: 'Delete a negotiable document',
  },
  PackagesNegociatedDialogsDeleteMessage: {
    id: 'Packages.Negociated.Dialogs.Delete.Message',
    defaultMessage:
      'You are about to delete the negotiable document <b>{name}</b>',
  },
  PackagesNegociatedFieldsNameLabel: {
    id: 'Packages.Negociated.Fields.Name.Label',
    defaultMessage: 'Title',
  },
  PackagesNegociatedFieldsNameRequired: {
    id: 'Packages.Negociated.Fields.Name.Required',
    defaultMessage: 'Document title is required',
  },
  PackagesSignedDialogsCreateTitle: {
    id: 'Packages.Signed.Dialogs.Create.Title',
    defaultMessage: 'Add a document to be signed',
  },
  PackagesSignedDialogsUpdateTitle: {
    id: 'Packages.Signed.Dialogs.Update.Title',
    defaultMessage: 'Modify a document to be signed',
  },
  PackagesSignedDialogsDeleteTitle: {
    id: 'Packages.Signed.Dialogs.Delete.Title',
    defaultMessage: 'Delete a document to be signed',
  },
  PackagesSignedDialogsDeleteMessage: {
    id: 'Packages.Signed.Dialogs.Delete.Message',
    defaultMessage:
      'You are about to delete the document to be signed <b>{name}</b>',
  },
  PackagesSignedDialogsViewTitle: {
    id: 'Packages.Signed.Dialogs.View.Title',
    defaultMessage: 'View a document to be signed',
  },
  PackagesSignedFieldsNameLabel: {
    id: 'Packages.Signed.Fields.Name.Label',
    defaultMessage: 'Title',
  },
  PackagesSignedFieldsNameRequired: {
    id: 'Packages.Signed.Fields.Name.Required',
    defaultMessage: 'Document title is required',
  },
  PackagesSignedFieldsFileLabel: {
    id: 'Packages.Signed.Fields.File.Label',
    defaultMessage: 'Document to upload',
  },
  PackagesSignedFieldsFileRequired: {
    id: 'Packages.Signed.Fields.File.Required',
    defaultMessage: 'Document to upload is required',
  },
  PackagesSignedFieldsFileInvalidFormat: {
    id: 'Packages.Signed.Fields.File.InvalidFormat',
    defaultMessage: 'Document to upload must be a PDF file',
  },
  PackagesSupportingDialogsCreateTitle: {
    id: 'Packages.Supporting.Dialogs.Create.Title',
    defaultMessage: 'Create a document to provide',
  },
  PackagesSupportingDialogsUpdateTitle: {
    id: 'Packages.Supporting.Dialogs.Update.Title',
    defaultMessage: 'Modify a document to provide',
  },
  PackagesSupportingDialogsDeleteTitle: {
    id: 'Packages.Supporting.Dialogs.Delete.Title',
    defaultMessage: 'Delete a document to provide',
  },
  PackagesSupportingDialogsDeleteMessage: {
    id: 'Packages.Supporting.Dialogs.Delete.Message',
    defaultMessage:
      'You are about to delete the document to provide <b>{name}</b>',
  },
  PackagesSupportingFieldsNameLabel: {
    id: 'Packages.Supporting.Fields.Name.Label',
    defaultMessage: 'Title',
  },
  PackagesSupportingFieldsNameRequired: {
    id: 'Packages.Supporting.Fields.Name.Required',
    defaultMessage: 'Document title is required',
  },
});
