import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { useOutletContext, useParams } from 'react-router-dom';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { DialogOutletContext } from '@components/Dialog/DialogOutlet';
import ConfirmDialog from '@components/Dialog/ConfirmDialog';
import { Typography } from '@mui/material';
import { localeStateSelector } from '@recoil/locale';
import { useRecoilValue } from 'recoil';
import useDownloadFile from '@hooks/useDownloadFile';

export default function ExportTermsheetModal() {
  const { entityId, entityType } = useParams();
  const { close } = useOutletContext<DialogOutletContext>();

  const locale = useRecoilValue(localeStateSelector);
  const { doDownload, isLoading, error } = useDownloadFile({
    url: `/${entityType}/${entityId}/export-termsheet?language=${locale}`,
    method: 'GET',
    onSuccess: close,
  });

  const onSubmit = () => {
    doDownload();
  };

  return (
    <ConfirmDialog
      open
      onClose={close}
      titleComponent={
        <FormattedMessage id="DriveView.Termsheet.Modal.Export.Title" />
      }
      actionComponent={
        <Button variant="contained" onClick={onSubmit} disabled={isLoading}>
          <FormattedMessage id="Verbs.Export" />
        </Button>
      }
      fetching={isLoading}
    >
      <>
        <ErrorMessage error={error} />
        <Typography>
          <FormattedMessage id="DriveView.Termsheet.Modal.Export.Message" />
        </Typography>
      </>
    </ConfirmDialog>
  );
}
