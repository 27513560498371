import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FastField } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';

import { valueFromPath } from '@utils/json-path';

function MuiPhone({
  field,
  form: { touched, errors, setFieldValue },
  onChange,
  ...props
}) {
  const { name } = field;
  const error = name.includes('.') ? valueFromPath(errors, name) : errors[name];
  const touch = name.includes('.')
    ? valueFromPath(touched, name)
    : touched[name];
  const hasError = Boolean(touch) && Boolean(error);
  return (
    <MuiPhoneNumber
      fullWidth
      margin="normal"
      autoComplete="off"
      variant="outlined"
      enableLongNumbers
      {...props}
      {...field}
      onChange={onChange ?? (value => setFieldValue(name, value))}
      error={hasError}
      helperText={
        hasError && (
          <FormattedMessage
            id={error}
            values={{ label: props?.label ?? '', value: field?.value ?? '' }}
          />
        )
      }
    />
  );
}

function FormikPhone(props) {
  return <FastField component={MuiPhone} {...props} />;
}

export default FormikPhone;
