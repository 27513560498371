import React from 'react';
import PropTypes from 'prop-types';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from './IconButton';

export default function DeleteIconButton(props) {
  return (
    <IconButton aria-label="delete" {...props}>
      <DeleteOutlineOutlinedIcon />
    </IconButton>
  );
}

DeleteIconButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'grey', 'delete']),
  onClick: PropTypes.func,
};

DeleteIconButton.defaultProps = {
  color: 'delete',
  onClick: null,
};
