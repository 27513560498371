import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import FormikTextField from '@components/Formik/FormikTextField';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import useDebounced from '@hooks/useDebounced';
import useFetch from '@hooks/useFetch';
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export function getDocumentDriveUrl({ folderId }: { folderId: string }) {
  return `documents/drive?parentId=${
    folderId ? encodeURIComponent(folderId) : ''
  }`;
}

type AnnexFormProps = {
  disabled: boolean;
  currentContractId: string;
};

export default function AnnexForm({
  disabled,
  currentContractId,
}: AnnexFormProps) {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounced(searchValue, 500);
  const { setFieldValue } = useFormikContext();
  const {
    data: searchResults,
    error,
    fetching: searchResultsFetching,
  } = useFetch({
    url:
      `documents/filters/document-available-annex?` +
      `query=${encodeURIComponent(debouncedSearchValue)}`,
    method: 'GET',
    lazy: !debouncedSearchValue,
  });

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const handleDocumentSelect = (event: any, selectedDocument: any | null) => {
    const documentId = selectedDocument?.id || '';
    const documentName = selectedDocument?.name || '';
    setFieldValue('documentId', documentId);
    setFieldValue('documentName', documentName);
    setFieldValue('name', documentName);
  };

  return (
    <Stack gap={2}>
      <Field name="documentName" required>
        {({ field }: { field: any }) => (
          <Autocomplete
            {...field}
            size="small"
            required
            options={
              searchResults?.items?.filter(
                (x: any) => x.id !== currentContractId
              ) || []
            }
            getOptionLabel={(option: any) => option.name ?? option}
            fullWidth
            onInputChange={handleSearchTextChange}
            onChange={handleDocumentSelect}
            loading={searchResultsFetching}
            noOptionsText="Aucun résultat"
            isOptionEqualToValue={(option: any, value: any) =>
              typeof value === 'string'
                ? option?.name === value
                : option?.name === value?.name
            }
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Choisir le fichier"
                required
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <InsertDriveFileIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {searchResultsFetching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      </Field>
      <ErrorMessage error={error} />
      <FormikTextField
        required
        name="name"
        label="Nom de l'annexe"
        disabled={disabled}
      />
    </Stack>
  );
}
