import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Box, Stack } from '@mui/system';
import EditIcon from '@shells/nexity/assets/editIcon.png';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  nexityFolderStatuses,
  nexityRoles,
  nexitySignatureStatuses,
} from '@shells/nexity/nexity-constants';
import Switch from '@components/Buttons/Switch';
import useTenantRoles from '@hooks/useTenantRoles';

function FolderInformations({
  data,
  dealer,
  signers,
  notifications,
  isManagerOrAdmin,
  isExternal,
  isDealer,
}) {
  const isSigning =
    data?.space?.signature?.status === nexitySignatureStatuses.pending;
  const isSigned = data?.space?.status === nexityFolderStatuses.signed;
  const canModifyDealer = !!data?.space && !(isSigning || isSigned);
  const canModifySigners = !!data?.space && !(isSigning || isSigned);
  const canModifyNotifications = !!data?.space && !(isSigning || isSigned);
  const buyersNotification = data?.buyersNotification;

  const canUpdateBuyersNotification =
    useTenantRoles({ roles: [nexityRoles.administrators] }) ||
    data?.isDealer ||
    data?.isAnimator ||
    data?.isBuyer;

  const getSignerButton = signerIndex =>
    isManagerOrAdmin &&
    canModifySigners && (
      <Button
        startIcon={
          <img src={EditIcon} alt="edit icon" style={{ height: '20px' }} />
        }
        variant="text"
        color="primary"
        sx={{ p: 0 }}
        component={Link}
        to={`modals/update-signer-dialog/signer/${signerIndex}`}
      />
    );

  return (
    <>
      <Box>
        <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
          Informations Dossier
        </Typography>
      </Box>
      <Paper sx={{ p: 2 }}>
        {Boolean(dealer) && (
          <Stack sx={{ my: 2 }}>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Typography sx={{ fontWeight: 'bold' }}>Vendeur</Typography>
              {isManagerOrAdmin && canModifyDealer && (
                <Button
                  startIcon={
                    <img
                      src={EditIcon}
                      alt="edit icon"
                      style={{ height: '20px' }}
                    />
                  }
                  variant="text"
                  color="primary"
                  sx={{ p: 0 }}
                  component={Link}
                  to="modals/update-dealer-dialog"
                />
              )}
            </Box>
            <Box sx={{ fontSize: '12px', color: 'gray' }}>
              Nom: {dealer?.LastName} <br />
              Prénom: {dealer?.FirstName} <br />
              Email: {dealer?.Email} <br />
              Tel: {dealer?.Tel} <br />
            </Box>
          </Stack>
        )}
        {Boolean(signers?.length && !isExternal && !isDealer) && (
          <Stack sx={{ my: 2 }}>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Signataire Nexity
              </Typography>
              {signers.length === 1 && getSignerButton(0)}
            </Box>

            {signers.map((signer, signerIndex) => (
              <Box sx={{ display: 'flex', mb: 1 }}>
                <Box sx={{ fontSize: '12px', color: 'gray' }}>
                  Nom: {signer?.lastName} <br />
                  Prénom: {signer?.firstName} <br />
                  Email: {signer?.email} <br />
                  Tel: {signer?.phoneNumber} <br />
                </Box>
                <Box>{signers.length > 1 && getSignerButton(signerIndex)}</Box>
              </Box>
            ))}
          </Stack>
        )}
        {Boolean(notifications && !isExternal && !isDealer) && (
          <Stack sx={{ my: 2 }}>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Typography sx={{ fontWeight: 'bold' }}>Notifications</Typography>
              {isManagerOrAdmin && canModifyNotifications && (
                <Button
                  startIcon={
                    <img
                      src={EditIcon}
                      alt="edit icon"
                      style={{ height: '20px' }}
                    />
                  }
                  variant="text"
                  color="primary"
                  sx={{ p: 0 }}
                  component={Link}
                  to="modals/update-emails-notification-dialog"
                />
              )}
            </Box>
            <Box sx={{ color: 'gray' }}>
              {notifications.map(notification => (
                <Typography sx={{ fontSize: '12px' }}>
                  {notification.EmailNotification}
                </Typography>
              ))}
            </Box>
          </Stack>
        )}
        <Stack sx={{ my: 2 }}>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Notifications client(s)
            </Typography>
          </Box>
          <Box>
            {canUpdateBuyersNotification && (
              <Switch
                checked={buyersNotification}
                component={Link}
                to={
                  buyersNotification
                    ? `modals/deactivate-buyers-notification`
                    : `modals/activate-buyers-notification`
                }
              />
            )}
            {!canUpdateBuyersNotification && (
              <Switch readOnly checked={buyersNotification} />
            )}
          </Box>
        </Stack>
      </Paper>
    </>
  );
}

export default FolderInformations;
