import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector';

import { useTiptapContext } from './use-tiptap-context';

const defaultStateSelector = state => state;

export const useTiptapState = (selector = defaultStateSelector, isEqual) => {
  const context = useTiptapContext();
  return useSyncExternalStoreWithSelector(
    context.subscribe,
    () => context.editor?.view?.state,
    null,
    selector,
    isEqual
  );
};
