import React from 'react';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import FormikTextField from '@components/Formik/FormikTextField';
import FormikSelect from '@components/Formik/FormikSelect';
import FormikDatePicker from '@components/Formik/FormikDatePicker';
import FormikPhone from '@components/Formik/FormikPhone';

const fields = {
  civility: 'civility',
  lastName: 'lastName',
  firstName: 'firstName',
  dateOfBirth: 'dateOfBirth',
  birthPlace: 'birthPlace',
  address: 'address',
  city: 'city',
  zipcode: 'zipcode',
  nationality: 'nationality',
  phoneHome: 'phoneHome',
  phoneMobile: 'phoneMobile',
  email: 'email',
  profession: 'profession',
  companyName: 'companyName',
  companyEntryDate: 'companyEntryDate',
};

const stringTrimRequired = Yup.string()
  .trim()
  .transform(value => value || '')
  .required('Generic.Fields.Required')
  .default('');

const dateSchema = Yup.number()
  .nullable()
  .transform(v => Number(v) || null)
  .max(Math.floor(new Date().getTime()), 'Generic.Fields.Date.MaxToday')
  .required('Generic.Fields.Required')
  .default(null);

const dateSchemaNotRequired = Yup.number()
  .nullable()
  .transform(v => Number(v) || null)
  .max(Math.floor(new Date().getTime()), 'Generic.Fields.Date.MaxToday')
  .default(null);

export const schemaCivilForm = Yup.object()
  .shape({
    [fields.civility]: stringTrimRequired,
    [fields.lastName]: stringTrimRequired,
    [fields.firstName]: stringTrimRequired,
    [fields.dateOfBirth]: dateSchema,
    [fields.birthPlace]: stringTrimRequired,
    [fields.address]: stringTrimRequired,
    [fields.city]: stringTrimRequired,
    [fields.zipcode]: stringTrimRequired,
    [fields.nationality]: stringTrimRequired,
    [fields.phoneHome]: Yup.string()
      .trim()
      .transform(value => value || '')
      .default(''),
    [fields.phoneMobile]: stringTrimRequired,
    [fields.email]: Yup.string()
      .trim()
      .email('Generic.Fields.InvalidFormat')
      .transform(value => value || '')
      .required('Generic.Fields.Required')
      .default(''),
    [fields.profession]: stringTrimRequired,
    [fields.companyName]: Yup.string()
      .trim()
      .transform(value => value || '')
      .default(''),
    [fields.companyEntryDate]: dateSchemaNotRequired,
  })
  .default({});

export const defaultCivilFormValues = {
  [fields.civility]: '',
  [fields.lastName]: '',
  [fields.firstName]: '',
  [fields.dateOfBirth]: null,
  [fields.birthPlace]: '',
  [fields.address]: '',
  [fields.city]: '',
  [fields.zipcode]: '',
  [fields.nationality]: '',
  [fields.phoneHome]: '',
  [fields.phoneMobile]: '',
  [fields.email]: '',
  [fields.profession]: '',
  [fields.companyName]: '',
  [fields.companyEntryDate]: null,
};

function CivilForm({ name, index, isFieldReadOnly, readOnly }) {
  const namePrefix = `${name}.${index}`;
  return (
    <Stack direction="column" gap={1} pt={1} mt={2}>
      <Typography variant="h5" gutterBottom>
        État civil co-acquéreur {index + 1}
      </Typography>
      <FormikSelect
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.civility}`)}
        name={`${namePrefix}.${fields.civility}`}
        label="Civilité"
        FormControlProps={{
          color: 'secondary',
          margin: 'dense',
          disabled: isFieldReadOnly(`${namePrefix}.${fields.civility}`),
        }}
        margin="dense"
      >
        <MenuItem value="Monsieur">M</MenuItem>
        <MenuItem value="Madame">Mme</MenuItem>
      </FormikSelect>
      <FormikTextField
        as="fastfield"
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.lastName}`)}
        margin="dense"
        name={`${namePrefix}.${fields.lastName}`}
        label="Nom"
        color="secondary"
      />
      <FormikTextField
        as="fastfield"
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.firstName}`)}
        margin="dense"
        name={`${namePrefix}.${fields.firstName}`}
        label="Prénom"
        color="secondary"
      />
      <FormikDatePicker
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.dateOfBirth}`)}
        maxDate={new Date()}
        name={`${namePrefix}.${fields.dateOfBirth}`}
        label="Date de naissance"
        TextFieldProps={{
          color: 'secondary',
          margin: 'dense',
          placeholder: 'dd/mm/yyyy',
        }}
      />
      <FormikTextField
        as="fastfield"
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.birthPlace}`)}
        margin="dense"
        name={`${namePrefix}.${fields.birthPlace}`}
        label="Lieu de naissance"
        color="secondary"
      />
      <FormikTextField
        as="fastfield"
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.nationality}`)}
        margin="dense"
        name={`${namePrefix}.${fields.nationality}`}
        label="Nationalité"
        color="secondary"
      />
      <FormikTextField
        as="fastfield"
        required
        disabled={readOnly}
        margin="dense"
        name={`${namePrefix}.${fields.address}`}
        label="Numéro et libellé de la voie"
        color="secondary"
      />
      <FormikTextField
        as="fastfield"
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.city}`)}
        margin="dense"
        name={`${namePrefix}.${fields.city}`}
        label="Ville "
        color="secondary"
      />
      <FormikTextField
        as="fastfield"
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.zipcode}`)}
        margin="dense"
        name={`${namePrefix}.${fields.zipcode}`}
        label="Code postal"
        color="secondary"
      />
      <FormikPhone
        disabled={isFieldReadOnly(`${namePrefix}.${fields.phoneHome}`)}
        margin="dense"
        name={`${namePrefix}.${fields.phoneHome}`}
        label="Tel domicile"
        color="secondary"
        defaultCountry="fr"
      />
      <FormikPhone
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.phoneMobile}`)}
        margin="dense"
        name={`${namePrefix}.${fields.phoneMobile}`}
        label="Tel portable"
        color="secondary"
        defaultCountry="fr"
      />
      <FormikTextField
        as="fastfield"
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.email}`)}
        margin="dense"
        type="email"
        name={`${namePrefix}.${fields.email}`}
        label="Adresse email"
        color="secondary"
      />
      <FormikTextField
        as="fastfield"
        required
        disabled={isFieldReadOnly(`${namePrefix}.${fields.profession}`)}
        margin="dense"
        name={`${namePrefix}.${fields.profession}`}
        label="Profession"
        color="secondary"
      />
      <FormikTextField
        as="fastfield"
        disabled={isFieldReadOnly(`${namePrefix}.${fields.companyName}`)}
        margin="dense"
        name={`${namePrefix}.${fields.companyName}`}
        label="Nom de l'entreprise"
        color="secondary"
      />
      <FormikDatePicker
        disabled={isFieldReadOnly(`${namePrefix}.${fields.companyEntryDate}`)}
        maxDate={new Date()}
        name={`${namePrefix}.${fields.companyEntryDate}`}
        label="Date d'entrée dans l'entreprise"
        TextFieldProps={{
          color: 'secondary',
          margin: 'dense',
          placeholder: 'dd/mm/yyyy',
        }}
      />
    </Stack>
  );
}

export default React.memo(CivilForm);
