import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import FormatClearIcon from '@mui/icons-material/FormatClear';

import { useTiptapEditor } from '../use-tiptap-editor';

import ButtonHeading from './buttons/button-heading';
import ButtonFontFamily from './buttons/button-font-family';
import ButtonFontSize from './buttons/button-font-size';
import ButtonTextColor from './buttons/button-text-color';
import ButtonBackgroundColor from './buttons/button-background-color';
import ButtonApplyStyle from './buttons/button-apply-style';

function ToolbarFonts() {
  const editor = useTiptapEditor();
  return (
    <ButtonGroup variant="text">
      <ButtonHeading />
      <ButtonFontFamily />
      <ButtonFontSize />
      <ButtonTextColor />
      <ButtonBackgroundColor />
      <ButtonApplyStyle />
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.ClearFormat" />}
      >
        <Button
          sx={{ color: 'primary.main' }}
          onClick={() =>
            editor
              .chain()
              .focus()
              .clearNodes()
              .unsetAllMarks()
              .unsetBackgroundColor()
              .unsetColor()
              .unsetFontFamily()
              .unsetFontSize()
              .run()
          }
        >
          <FormatClearIcon />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
}

export default ToolbarFonts;
