import { createContext, useContext } from 'react';

const CurrentUserContext = createContext(null);

export const CurrentUserContextProvider = CurrentUserContext.Provider;

export function useCurrentUser() {
  const value = useContext(CurrentUserContext);
  if (!value)
    throw new Error('useCurrentUser must be inside a CurrentUserContext');
  return value;
}
