import { Box } from '@mui/material';
import VersionListItem from './VersionListItem';

type VersionListProps = {
  versions: any[];
  canContribute: boolean;
  onDownload: (versionId: string) => void;
  onRestore: (versionId: string) => void;
  onCompare: (versionId: string) => void;
};

function VersionList({
  versions,
  canContribute,
  onDownload,
  onRestore,
  onCompare,
}: VersionListProps) {
  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'auto',
        height: 'calc(100vh - 170px)',
        p: '24px',
      }}
    >
      {versions.map((version: any, index: number) => (
        <VersionListItem
          key={version.id}
          version={version}
          isFirst={index === 0}
          onDownload={onDownload}
          onCompare={onCompare}
          onRestore={onRestore}
          canContribute={canContribute}
        />
      ))}
    </Box>
  );
}

export default VersionList;
