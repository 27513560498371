import cssjs from 'jotform-css.js';
import cloneDeep from 'lodash.clonedeep';

// remove comments that surround the styles
export function retrieveHTMLStyles(html) {
  const commentedTags = html.matchAll(
    /<!--\[if[\s\S]*?\]>[\s]*(?<outer>[\s\S]*?<(?<tagName>.*)>(?<inner>[\s\S]*?)[\s]*?<\/(\k<tagName>)>)[\s\S]*?-->/gm
  );
  for (const match of commentedTags) {
    if (match.groups.tagName === 'xml') {
      html = html.replace(match[0], '');
    } else if (match.groups.tagName === 'style') {
      html = html.replace(match[0], match.groups.outer);
    }
  }

  const styleCommented = html.matchAll(
    /<style>\s*?(?<outer>[\s]*?<!--(?<inner>[\s\S]*?)[\s]*?-->)[\s\S]*?-->/gm
  );
  for (const match of styleCommented) {
    html = html.replace(match.groups.outer, match.groups.inner);
  }
  return html;
}

function separateGroupedCssRules(cssRules) {
  const newSeparatedRules = [];
  for (const rule of cssRules) {
    if (rule.selector.indexOf(',') !== -1) {
      const separatedRules = rule.selector.split(',');
      rule.selector = separatedRules[0].trim();
      newSeparatedRules.push(
        ...separatedRules.slice(1).map(sepRule => ({
          ...cloneDeep(rule),
          selector: sepRule.trim(),
        }))
      );
    }
  }
  cssRules.push(...newSeparatedRules);
  return cssRules;
}

function removeSpecifiedEnglobingElement(englobingSelector, cssRuleSelector) {
  const regex = new RegExp(
    `${englobingSelector}\((?:[^)(]|\((?:[^)(]|\((?:[^)(]|\([^)(]*\))*\))*\))*\)`
  );
  var selectorRegex = new RegExp(
    `${englobingSelector}\\((?:[^)(]|\\((?:[^)(]|\\((?:[^)(]|\\([^)(]*\\))*\\))*\\))*\\)`
  );
  var result;
  do {
    result = selectorRegex.exec(cssRuleSelector);
    if (result) {
      cssRuleSelector = cssRuleSelector.replace(
        result[0],
        ' ' + result[0].slice(5, -1)
      ); // remove englobing element and add space
    }
  } while (result);
  return cssRuleSelector;
}

function parseSelector(selector) {
  selector = removeSpecifiedEnglobingElement(':not', selector);
  selector = selector.split('.').join(' .');
  selector = selector.split('#').join(' #');
  selector = selector.replaceAll('*', ''); // * count as 0 for specificity
  const splitCharSelectors = ['+', '~', '>'];
  for (const splitChar of splitCharSelectors) {
    selector = selector.replaceAll(splitChar, ' ');
  }
  return selector
    .trim()
    .split(' ')
    .map(s => s.trim());
}

function sortCssSpecificity(cssRules) {
  for (const rule of cssRules) {
    const simpleSelectors = parseSelector(rule.selector);
    const idCount = simpleSelectors.filter(s => s.startsWith('#')).length;
    const attributesClassesPseudoClassesCount = simpleSelectors.filter(
      s => s.startsWith('.') || s.match(/^:[^:]+/) || s.match(/^\[.+\]$/)
    ).length;
    const tagSelectorCount =
      simpleSelectors.length - (idCount + attributesClassesPseudoClassesCount);

    rule.specificity = {
      a: idCount,
      b: attributesClassesPseudoClassesCount,
      c: tagSelectorCount,
    };
  }
  return cssRules
    .sort((r1, r2) => r2.specificity.c - r1.specificity.c)
    .sort((r1, r2) => r2.specificity.b - r1.specificity.b)
    .sort((r1, r2) => r2.specificity.a - r1.specificity.a); // sort by highest priority on the top
}

export function applyStyleOnElements(htmlNodes) {
  const parser = new cssjs.cssjs();
  const stylesTags = htmlNodes.querySelectorAll('style');
  const allCssDeclarations = [...stylesTags]
    .map(s => s.innerHTML?.trim() ?? '')
    .join('');
  const allRules = sortCssSpecificity(
    separateGroupedCssRules(parser.parseCSS(allCssDeclarations))
  );

  for (const selectRules of allRules) {
    if (!selectRules.selector.startsWith('@')) {
      try {
        const elements = htmlNodes.querySelectorAll(selectRules.selector);

        // apply style on all elements
        for (const elem of elements) {
          for (const rule of selectRules.rules.filter(
            r => !r.directive.startsWith('mso-')
          )) {
            if (
              !elem.style[rule.directive] ||
              rule.value.endsWith('!important')
            ) {
              elem.style[rule.directive] = rule.value;
            }
          }
        }
      } catch (e) {}
    }
  }
}

const stylesToNotInherit = [
  'width',
  'height',
  'min*',
  'max*',
  'border*',
  'margin*',
  'padding*',
  'content',
  'counter*',
  'break*',
  'left',
  'right',
  'mso-*', // Word copied style
  '-aw-*', // aspose word style
];
const regexStylesNotInherit = stylesToNotInherit.map(style =>
  convertStringToRegex(style)
);

function convertStringToRegex(str) {
  const regStr = `^${str.replaceAll('*', '[\\s\\S]*?')}$`;
  return new RegExp(regStr);
}

function getStyles(styleAttribute) {
  const splitStyles = styleAttribute?.split(';') ?? [];
  const styles = [];

  for (const style of splitStyles) {
    const values = style.split(':');
    const propertyName = values[0].trim();
    if (
      values.length >= 2 &&
      !regexStylesNotInherit.some(reg => reg.test(propertyName))
    ) {
      styles.push([propertyName, values[1].trim()]);
    }
  }
  return styles;
}

function mergeStyles(node, parentStyles) {
  if (!node.getAttribute) return parentStyles;
  const styles = getStyles(node.getAttribute('style'));
  // apply new styles or important styles on node
  for (let idx = 0; idx < parentStyles.length; ++idx) {
    const style = parentStyles[idx];
    if (!node.style[style[0]]) {
      node.style[style[0]] = style[1];

      // replace or append style
      const stylePos = styles.findIndex(s => s[0] === style[0]);
      if (stylePos !== -1) {
        styles[stylePos][1] = style[1];
      } else {
        styles.push([style[0], style[1]]);
      }
    }
  }
  return styles;
}

function applyStyleOnChildren(node, parentStyles) {
  if (node.nodeType === document.TEXT_NODE) {
    // create span to add the style to a text node
    const span = document.createElement('span');
    node.parentElement.insertBefore(span, node);
    span.appendChild(node);
    node = span;
  }

  const styles = mergeStyles(node, parentStyles);
  if (styles.length > 0) {
    for (let idx = 0; idx < node.childNodes.length; ++idx) {
      const child = node.childNodes[idx];
      if (
        child.nodeType !== document.TEXT_NODE ||
        idx > 0 ||
        !['SPAN', 'P'].includes(child.parentElement.tagName)
      ) {
        applyStyleOnChildren(child, styles);
      }
    }
  }
}

// inherit style for children
export function inheritStyleForChildren(htmlNodes) {
  const elemsToInherit = (
    htmlNodes.querySelector('body') ?? htmlNodes
  ).querySelectorAll('*[style]');

  for (const parent of elemsToInherit) {
    const styles = getStyles(parent.getAttribute('style'));

    if (styles.length > 0) {
      for (let idx = 0; idx < parent.childNodes.length; ++idx) {
        const child = parent.childNodes[idx];
        if (
          child.nodeType !== document.TEXT_NODE ||
          idx > 0 ||
          !['SPAN', 'P'].includes(child.parentElement.tagName)
        ) {
          applyStyleOnChildren(child, styles);
        }
      }
    }
  }
}
