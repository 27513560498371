import { findElementsInContent } from '@editor/utils/document';
import {
  buildRtfMap,
  hexToBase64,
  fixPastedImages,
} from './clean-pasted-images';
import {
  retrieveHTMLStyles,
  applyStyleOnElements,
  inheritStyleForChildren,
} from './apply-styles';

export function getParentAlignment(node) {
  let nodeIdx = node;
  let alignment = null;

  while (nodeIdx && !alignment) {
    if (nodeIdx.align || nodeIdx.style.textAlign) {
      alignment = nodeIdx.align || nodeIdx.style.textAlign;
      if (!['left', 'right', 'center'].includes(alignment)) {
        // only allow left, right and center values
        alignment = null;
        break;
      }
    }
    nodeIdx = nodeIdx.parentElement;
  }
  return alignment ?? 'left';
}

function cleanHTML(htmlNodes) {
  const msSpans = htmlNodes.querySelectorAll('[style*="mso"]');

  for (const msSpan of msSpans) {
    const styles = msSpan.getAttribute('style').split(';');
    for (let idx = styles.length - 1; idx >= 0; --idx) {
      const stylesProp = styles[idx].trim().split(':');
      if (stylesProp[0].startsWith('mso')) {
        styles.splice(idx, 1);
      }
    }
    if (styles.length === 0) {
      msSpan.removeAttribute('style');
    } else {
      msSpan.setAttribute('style', styles.join(';'));
    }
  }
}

export function transformPastedHTML(html, view) {
  const originalHtml = html;
  html = html.replace(/\/\*[\s\S]*?\*\//gm, ''); // remove comments
  html = retrieveHTMLStyles(html);
  html = html.replace(/<!--[\s\S]*?-->/gm, ''); // remove comments
  html = html.replace(/<o:p>[\s\S]*?<\/o:p>/g, ''); // remove <o:p> tags
  const htmlNodes = document.createElement('html');
  htmlNodes.innerHTML = html;
  applyStyleOnElements(htmlNodes);
  inheritStyleForChildren(htmlNodes);
  fixPastedImages(htmlNodes, originalHtml);
  cleanHTML(htmlNodes);
  // console.log('oaste htm', htmlNodes.innerHTML);
  return htmlNodes.innerHTML;
}

export function handlePaste(view, event, slice) {
  if (event?.clipboardData?.types.includes('text/rtf')) {
    // get potential images fron rtf
    const rtf = event?.clipboardData.getData('text/rtf');
    if (!rtf) {
      return false;
    }
    // console.log('clip', rtf);
    const rtfMap = buildRtfMap(rtf);
    const images = findElementsInContent(
      slice.content,
      node => node.type?.name === 'image'
    );

    Object.entries(rtfMap).forEach(([key, value]) => {
      const image = images.find(i => i.attrs.shapeId?.split(',').includes(key));

      if (image) {
        image.attrs.src = `data:${value.imageType};base64,${hexToBase64(
          value.imageHex
        )}`;
      }
    });
    // console.log('slice', slice);
    // console.log('retf map', rtf_map);
    return false;
  }

  return false;
}

function isWordHTML(html) {
  return html.match(
    /(class="?Mso|class='?Mso|class="?Xl|class='?Xl|class=Xl|style="[^"]*\bmso-|style='[^']*\bmso-|w:WordDocument|LibreOffice)/gi
  );
}
