import React, { useMemo } from 'react';
import { FormattedTime, FormattedDate } from 'react-intl';

function FormattedDateTime({ value, ...rest }) {
  const date = useMemo(() => new Date(value), [value]);
  return (
    <>
      <FormattedDate value={date} {...rest} />{' '}
      <FormattedTime value={date} {...rest} />
    </>
  );
}

export default FormattedDateTime;
