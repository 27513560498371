import React from 'react';
import Alert from '@mui/material/Alert';
import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';

export default function GenericAlert({ description, margin = '3rem' }) {
  const StyledAlert = styled(props => <Alert {...props} />)(() => ({
    backgroundColor: '#0D99FF1F',
    borderRadius: '8px',
    marginBottom: margin,
    color: '#0D99FF',
  }));

  return (
    <StyledAlert icon={<InfoIcon sx={{ color: '#0D99FF' }} />} severity="info">
      {description}
    </StyledAlert>
  );
}
