import React from 'react';
import { useSetRecoilState } from 'recoil';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import LinkIcon from '@mui/icons-material/Link';

import { showEditorDialog, editorDialogTypes } from '@recoil/editor-dialogs';

import { useTiptapEditor } from '../../use-tiptap-editor';

function ButtonVariableLinked() {
  const editor = useTiptapEditor();
  const showEditorDialogCallback = useSetRecoilState(showEditorDialog);
  const onClickCallback = React.useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      editor.view.pluginViews.forEach(plugin => {
        plugin.tippy?.hide();
      });
      showEditorDialogCallback({
        type: editorDialogTypes.add.variablelinked,
        pos: 0,
      });
    },
    [editor]
  );
  return (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage id="Editor.Toolbar.Command.Variable.Linked.Add" />
      }
    >
      <Button sx={{ color: 'primary.main' }} onClick={onClickCallback}>
        <LinkIcon />
      </Button>
    </Tooltip>
  );
}

export default ButtonVariableLinked;
