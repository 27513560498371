import { defineMessages } from 'react-intl';

export default defineMessages({
  SearchLabel: {
    id: 'Suppliers.Search.Label',
    defaultMessage: 'Search a supplier',
  },
  NoResult: {
    id: 'Suppliers.NoResult',
    defaultMessage: 'No suppliers for the current business unit',
  },
});
