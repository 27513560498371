import { findChildren } from '@tiptap/core';

export const hasMark = (doc, type) => doc.rangeHasMark(0, doc.nodeSize, type);

export const containsNode = (doc, type) =>
  !!findChildren(doc, node => node.type === type)?.length;

function findElementsDataInContent(content, pos, predicate) {
  const result = [];
  if (predicate(content)) {
    result.push({ node: content, pos });
  }

  if (content.content) {
    let array = [];
    if (Array.isArray(content.content)) {
      array = content.content;
    } else if (
      content.content.content &&
      Array.isArray(content.content.content)
    ) {
      array = content.content.content;
    }

    for (const child of array) {
      result.push(...findElementsDataInContent(child, pos + 1, predicate));
      pos += child.nodeSize;
    }
  }
  return result;
}

export function findElementsInContent(content, predicate) {
  const data = findElementsDataInContent(content, 0, predicate);

  return data.map(d => d.node);
}

export function findElementsPosInContent(content, predicate) {
  const data = findElementsDataInContent(content, 0, predicate);

  return data.map(d => d.pos);
}
