import React from 'react';
import * as Yup from 'yup';
import { findParentNode } from '@tiptap/core';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { FormattedMessage } from 'react-intl';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import { useTiptapEditor } from '../../use-tiptap-editor';
import { useTiptapSelection } from '../../use-tiptap-selection';

import FormikProvider from '@components/Formik/FormikProvider';
import FormikSubmitOnChange from '@components/Formik/FormikSubmitOnChange';

import MarginFormik from '@editor/sidebars/formik/MarginFormik';

const schema = Yup.object().shape({
  marginTop: Yup.number().nullable(true),
  marginBottom: Yup.number().nullable(true),
  marginLeft: Yup.number().nullable(true),
  marginRight: Yup.number().nullable(true),
});

const initialValues = {
  marginTop: null,
  marginBottom: null,
  marginLeft: null,
  marginRight: null,
};

function ButtonMargin() {
  const editor = useTiptapEditor();
  const selection = useTiptapSelection();
  const marginTypes = React.useMemo(
    () =>
      editor?.options.extensions.find(e => e.name === 'margin')?.options
        .types ?? [],
    [editor]
  );
  const node = React.useMemo(() => {
    if (!selection) return null;
    const parent = findParentNode(parent =>
      marginTypes.includes(parent.type.name)
    )(selection);
    return parent;
  }, [selection]);
  const currentValues = React.useMemo(() => {
    return {
      ...initialValues,
      ...Object.fromEntries(
        // remove null values
        Object.entries(node?.node.attrs ?? {}).filter(
          ([key, value]) => value !== null && initialValues.hasOwnProperty(key)
        )
      ),
    };
  }, [node]);
  const onSubmitCallback = React.useCallback(
    values => {
      if (node) {
        editor
          .chain()
          .focus()
          .command(({ tr }) => {
            const newAttrs = {
              ...node.node.attrs,
              marginTop: values.marginTop,
              marginBottom: values.marginBottom,
              marginLeft: values.marginLeft,
              marginRight: values.marginRight,
            };

            tr.setNodeMarkup(node.pos, undefined, newAttrs, node.node.marks);
          })
          .run();
      }
    },
    [node]
  );

  return (
    <PopupState variant="popper" popupId="heading-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={
              <FormattedMessage id="Editor.Toolbar.Command.Margin.Label" />
            }
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <UnfoldMoreIcon />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <FormikProvider
                  schema={schema}
                  initialValues={currentValues}
                  enableReinitialize
                  onSubmit={onSubmitCallback}
                >
                  <FormikSubmitOnChange />
                  <MarginFormik />
                </FormikProvider>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonMargin;
