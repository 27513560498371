import parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

const YUP_PHONE = 'phone';
const DEFAUTL_ERROR = 'Generic.Fields.Required';

Yup.addMethod(
  Yup.string,
  YUP_PHONE,
  function isValidPhoneNumber2(errorMessage: string = DEFAUTL_ERROR) {
    return this.test(
      'isValidPhoneNumber',
      errorMessage,
      function validate(value) {
        const { path, createError } = this;

        if (value == null) {
          return createError({
            path,
            message: errorMessage,
          });
        }

        const phoneNumber: PhoneNumber | undefined = parsePhoneNumber(value);
        if (!phoneNumber || !phoneNumber.isValid()) {
          return createError({
            path,
            message: errorMessage,
          });
        }
        return true;
      }
    );
  }
);
