import { Extension } from '@tiptap/react';
import { Schema } from 'prosemirror-model';
import { defaultSettings, updateImageNode, imagePlugin } from 'prosemirror-image-plugin';
import 'prosemirror-image-plugin/dist/styles/common.css';
import 'prosemirror-image-plugin/dist/styles/withResize.css';
import './image-plugin-overwrite.css';
import { Plugin } from 'prosemirror-state';
// import "prosemirror-image-plugin/dist/styles/sideResize.css";

const ImageTools = Extension.create({
  name: 'image-tools',
  priority: 9998,

  addOptions() {
    return {
      ...defaultSettings,
      proxyUrl: '',
      hasTitle: false,
      uploadFile: (file, ...args) => {
        return resizeImageFile(file);
      },
      allowGapCursor: true,
    };
  },

  onBeforeCreate() {
    this.editor.schema = new Schema({
      nodes: updateImageNode(this.editor.schema.spec.nodes, {
        ...this.options,
        extraAttributes: {
          ...this.options.extraAttributes,
          align: 'left', // set align left as default value
          shapeId: null,
          track: [], // track-changes attributes
          tracked: false, // track-changes attributes
        },
      }),
      marks: this.editor.schema.spec.marks,
    });
  },

  addProseMirrorPlugins() {
    return [imagePlugin(this.editor.schema, this.options)];
  },
});

export default ImageTools;

function resizeImageCallback(img, resolve) {
  const MAX_WIDTH = (21 * 72) / 2.54;
  const MAX_HEIGHT = (29.7 * 72) / 2.54;
  let width = img.width;
  let height = img.height;
  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width;
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
  }
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(img, 0, 0, width, height);
  resolve(canvas.toDataURL('image/png'));
}

export function resizeImageFile(file) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.onload = function () {
      resizeImageCallback(img, resolve);
    };
    var reader = new FileReader();
    reader.onload = function (e) {
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  });
}

export function resizeImageFromUrl(imageUrl, editor) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const extensionOptions = editor.options.extensions.find(e => e.name === ImageTools.name).options;
    img.src = extensionOptions.proxyUrl + imageUrl;
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = function () {
      resizeImageCallback(img, resolve);
    };
  });
}

export function imageNoResizeOverlay() {
  const div = document.createElement('div');
  div.className = 'no-img-resize';
  return div;
}
