import polyfill, { polyfillDateFns } from '@i18n/polyfill';
import { atom, selector } from 'recoil';

const defaultLocale = new Promise(resolve => {
  resolve(localStorage.getItem('locale') || 'fr');
});

export const localeState = atom({
  key: 'localeState',
  default: defaultLocale,
});

export const isLocaleForcedState = atom({
  key: 'islocaleForcedState',
  default: false,
});

export const localeStateSelector = selector({
  key: 'localeStateSelector',
  get: async ({ get }) => {
    const locale = get(localeState);
    await polyfill(locale);
    return locale;
  },
  set: ({ set }, newValue) => {
    localStorage.setItem('locale', newValue);
    set(localeState, newValue);
  },
});

export const localeDateFnsSelector = selector({
  key: 'localeDateFnsSelector',
  get: async ({ get }) => {
    const locale = get(localeState);
    return (await polyfillDateFns(locale))?.default;
  },
});
