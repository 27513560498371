import React from 'react';
import { FormattedMessage } from 'react-intl';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Document from '@tiptap/extension-document';

import TiptapProvider from '@editor/TiptapProvider';
import TiptapContent from '@editor/TiptapContent';

import EditorToolbar from '@editor/EditorToolbar';

import { TiptapPlugins } from '@editor/plugins/tiptap-plugins';

import { useTiptapEditor } from '@editor/use-tiptap-editor';

function HtmlEditorActions({
  published,
  showPublished = false,
  onSave,
  saveBtnText,
  saveBtnProps,
  actionsProps,
  children,
}) {
  const editor = useTiptapEditor();
  const onSwitchCallback = React.useCallback(
    e => onSave(editor.getHTML(), e.target.checked),
    [onSave, editor]
  );
  const onSaveCallback = React.useCallback(
    () => onSave(editor.getHTML(), published),
    [onSave, published, editor]
  );
  return !children ? (
    <Stack
      direction="row"
      justifyContent={showPublished ? 'space-between' : 'flex-end'}
      alignItems="center"
      component={Paper}
      elevation={0}
      square
      variant="outlined"
      sx={{ width: 1, p: 1 }}
      {...actionsProps}
    >
      {showPublished && (
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={published} onChange={onSwitchCallback} />}
            label={<FormattedMessage id="HelpCenter.Release.Buttons.Publish" />}
          />
        </FormGroup>
      )}
      <Button variant="contained" onClick={onSaveCallback} {...saveBtnProps}>
        {!saveBtnText && <FormattedMessage id="Verbs.Save" />}
        {saveBtnText}
      </Button>
    </Stack>
  ) : (
    children({ editor, published })
  );
}

function HtmlEditor({
  content,
  published,
  showPublished = false,
  onSave,
  saveBtnText = undefined,
  saveBtnProps = {},
  actionsProps = {},
  editorStyle = {},
  children = undefined,
  prelude,
  editorRef,
  ...props
}) {
  const extensions = [Document, ...TiptapPlugins];
  return (
    <TiptapProvider editorRef={editorRef}>
      <Stack direction="column" sx={{ flexGrow: 1 }} {...props}>
        <EditorToolbar variant="html" />
        {prelude}
        <Paper
          component="section"
          elevation={0}
          square
          variant="outlined"
          sx={{
            width: 1,
            minHeight: '300px',
            margin: 'auto',
            overflow: 'hidden',
            display: 'flex',
            '& > div': { flexGrow: 1, display: 'flex', overflow: 'hidden' },
            p: 2,
            pr: 4,
            pl: 4,
            ...editorStyle,
          }}
        >
          <TiptapContent
            variant="html"
            extensions={extensions}
            content={content}
          />
        </Paper>
        <HtmlEditorActions
          published={published}
          showPublished={showPublished}
          onSave={onSave}
          saveBtnText={saveBtnText}
          saveBtnProps={saveBtnProps}
          actionsProps={actionsProps}
        >
          {children}
        </HtmlEditorActions>
      </Stack>
    </TiptapProvider>
  );
}

export default HtmlEditor;
