import React, { useMemo } from 'react';
import cloneDeep from 'lodash.clonedeep';
import FormikProvider from '@components/Formik/FormikProvider';
import * as Yup from 'yup';
import useFetch from '@hooks/useFetch';
import { useOutletContext, useParams } from 'react-router-dom';
import EditElementToTermSheetForm from './EditElementToTermSheetForm';

const schema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string().required('Generic.Fields.Required'),
  icon: Yup.string().required('Generic.Fields.Required'),
  type: Yup.string().required('Generic.Fields.Required'),
  content: Yup.string(),
});

export default function EditElementToTermSheetModal({
  refresh,
  entityId,
  title,
}) {
  const { id: termSheetValueId } = useParams();
  const { close } = useOutletContext();

  const {
    doFetch: doPut,
    fetching,
    error,
  } = useFetch({
    url: `term-sheets/entities/${entityId}/values/${termSheetValueId}`,
    method: 'PUT',
    onSuccess: () => {
      refresh();
      close();
    },
  });

  const { data: termSheetValues } = useFetch({
    url: `/term-sheets/entities/${entityId}/values/${termSheetValueId}`,
    method: 'GET',
  });

  const onSubmit = values => {
    let content = cloneDeep(values.content);
    switch (values.entityType) {
      case 'TimeOnly': {
        const date = new Date(parseInt(values.content, 10));
        content =
          date.getUTCHours() * 3600000 +
          date.getUTCMinutes() * 60000 +
          date.getUTCSeconds() * 1000;
        break;
      }
      default: {
        break;
      }
    }
    const send = doPut;
    send({
      body: {
        id: entityId,
        Items: [content],
        name: values.name,
        icon: values.icon,
        type: values.type,
        entityType: termSheetValues?.entityType,
      },
    });
  };

  const getContentValue = (items, type) => {
    if (items?.length > 0) {
      if (
        type === 'Timestamp' ||
        type === 'TimeOnly' ||
        type === 'DateOnly' ||
        type === 'TimeOnly' ||
        type === 'Number'
      ) {
        return parseInt(items[0], 10);
      }
      if (type === 'Boolean') {
        return JSON.parse(items[0]);
      }
      return items[0];
    }
    return '';
  };
  const initialValues = useMemo(
    () => ({
      id: termSheetValueId,
      content: getContentValue(termSheetValues?.items, termSheetValues?.type),
      name: termSheetValues?.name ?? null,
      icon: termSheetValues?.icon ?? null,
      type: termSheetValues?.type ?? null,
    }),
    [
      termSheetValueId,
      termSheetValues?.items,
      termSheetValues?.name,
      termSheetValues?.icon,
      termSheetValues?.type,
    ]
  );

  return (
    <FormikProvider
      schema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      enableReinitialize
    >
      <EditElementToTermSheetForm
        disabled={fetching}
        title={title}
        actionButtonText="ManageDataView.UpdateElement.Button.Update.Label"
        defaultActionButtonText="Update"
        error={error}
      />
    </FormikProvider>
  );
}
