import React from 'react';
import { FormattedMessage } from 'react-intl';
import { alpha } from '@mui/system/colorManipulator';
import Chip from '@mui/material/Chip';

import AvTimerIcon from '@mui/icons-material/AvTimer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

import messages from '@i18n/keys';

// 0: ToComplete, 1: ToValidate, 2: ToRectify, 3: Validated
function FormsToCompleteStatus({ status }) {
  if (status === 0) {
    return (
      <Chip
        size="small"
        variant="outlined"
        icon={<AvTimerIcon />}
        color="info"
        sx={{
          textTransform: 'uppercase',
          bgcolor: theme => alpha(theme.palette.info.main, 0.2),
        }}
        label={
          <FormattedMessage
            id={messages.nexity.folders.formstocomplete.status.tocomplete}
          />
        }
      />
    );
  }
  if (status === 1) {
    return (
      <Chip
        size="small"
        variant="outlined"
        icon={<HourglassBottomIcon />}
        color="warning"
        sx={{
          textTransform: 'uppercase',
          bgcolor: theme => alpha(theme.palette.warning.main, 0.2),
        }}
        label={
          <FormattedMessage
            id={messages.nexity.folders.formstocomplete.status.tovalidate}
          />
        }
      />
    );
  }
  if (status === 2) {
    return (
      <Chip
        size="small"
        variant="outlined"
        icon={<CancelIcon />}
        color="error"
        sx={{
          textTransform: 'uppercase',
          bgcolor: theme => alpha(theme.palette.error.main, 0.2),
        }}
        label={
          <FormattedMessage
            id={messages.nexity.folders.formstocomplete.status.torectify}
          />
        }
      />
    );
  }
  return (
    <Chip
      size="small"
      variant="outlined"
      icon={<CheckCircleIcon />}
      color="success"
      sx={{
        textTransform: 'uppercase',
        bgcolor: theme => alpha(theme.palette.success.main, 0.2),
      }}
      label={
        <FormattedMessage
          id={messages.nexity.folders.formstocomplete.status.validated}
        />
      }
    />
  );
}

export default FormsToCompleteStatus;
