import { defineMessages } from 'react-intl';

export default defineMessages({
  AdminControlPanel: {
    id: 'Admin.ControlPanel',
    defaultMessage: 'Control panel',
  },
  AdminManageReferences: {
    id: 'Admin.ManageReferences',
    defaultMessage: 'Referential management',
  },
  AdminAdministration: {
    id: 'Admin.Administration',
    defaultMessage: 'Administration',
  },
  AdminManageTemplates: {
    id: 'Admin.Manage.Templates',
    defaultMessage: 'Manage contract templates',
  },
  AdminManageReferentials: {
    id: 'Admin.Manage.Referentials',
    defaultMessage: 'Manage references',
  },
  AdminViewCardsManageTemplatesTitle: {
    id: 'AdminView.Cards.ManageTemplates.Title',
    defaultMessage: 'Manage templates',
  },
  AdminViewCardsManageTemplatesDescription: {
    id: 'AdminView.Cards.ManageTemplates.Description',
    defaultMessage:
      'Administration of document templates, contract forms and associated workflows.',
  },
  AdminViewCardsManageParentTemplatesTitle: {
    id: 'AdminView.Cards.ManageParentTemplates.Title',
    defaultMessage: 'Administer templates',
  },
  AdminViewCardsManageParentTemplatesDescription: {
    id: 'AdminView.Cards.ManageParentTemplates.Description',
    defaultMessage:
      'Administration of document templates, contract forms and associated workflows.',
  },
  AdminViewCardsManageTenantTemplatesTitle: {
    id: 'AdminView.Cards.ManageTenantTemplates.Title',
    defaultMessage: 'Manage my BU templates',
  },
  AdminViewCardsManageTenantTemplatesDescription: {
    id: 'AdminView.Cards.ManageTenantTemplates.Description',
    defaultMessage:
      'Administration of document templates, contract sheets and associated workflows assigned to my BU.',
  },
  AdminViewCardsButton: {
    id: 'AdminView.Cards.Button',
    defaultMessage: 'SET UP',
  },
  AdminViewCardsManageUsersTitle: {
    id: 'AdminView.Cards.ManageUsers.Title',
    defaultMessage: 'Manage user accounts',
  },
  AdminViewCardsManageUsersDescription: {
    id: 'AdminView.Cards.ManageUsers.Description',
    defaultMessage:
      'Administration of internal users, their roles and lockdown',
  },
  AdminViewCardsManageAccountsTitle: {
    id: 'Admin.ExternalAccounts.Title',
    defaultMessage: 'Manage external accounts',
  },
  AdminViewCardsManageAccountsDescription: {
    id: 'AdminView.Cards.ManageAccounts.Description',
    defaultMessage:
      'Administration of external users, invited to collaborate in contractual negotiations.',
  },
  AdminViewCardsManageReferencesTitle: {
    id: 'AdminView.Cards.ManageReferences.Title',
    defaultMessage: 'Manage references',
  },
  AdminViewCardsManageReferencesDescription: {
    id: 'AdminView.Cards.ManageReferences.Description',
    defaultMessage:
      'Management of your imported referentials using an Excel table for example, to link the fields of your templates to the data of these referentials.',
  },
  AdminViewCardsManageDataTitle: {
    id: 'AdminView.Cards.ManageData.Title',
    defaultMessage: 'Manage data',
  },
  AdminViewCardsManageDataDescription: {
    id: 'AdminView.Cards.ManageData.Description',
    defaultMessage:
      'Creation, deletion, merging of data categories in the summary sheets to optimise research and statistical monitoring.',
  },
  AdminViewCardsIntegrationsTitle: {
    id: 'AdminView.Cards.Integrations.Title',
    defaultMessage: 'Integrations',
  },
  AdminViewCardsIntegrationsDescription: {
    id: 'AdminView.Cards.Integrations.Description',
    defaultMessage: 'Connecting third-party software to Seraphin.',
  },
  AdminViewCardsClausesTitle: {
    id: 'AdminView.Cards.Clauses.Title',
    defaultMessage: 'Manage clauses',
  },
  AdminViewCardsClausesDescription: {
    id: 'AdminView.Cards.Clauses.Description',
    defaultMessage: 'Clause administration.',
  },
  AdminViewCardsTagsTitle: {
    id: 'AdminView.Cards.Tags.Title',
    defaultMessage: 'Manage tags',
  },
  AdminViewCardsTagsDescription: {
    id: 'AdminView.Cards.Tags.Description',
    defaultMessage:
      'Creating, personalizing and tracking the use of tags to label contractual clauses.',
  },
  AdminViewCardsNotionTypesTitle: {
    id: 'AdminView.Cards.NotionTypes.Title',
    defaultMessage: 'Manage clause types',
  },
  AdminViewCardsNotionTypesDescription: {
    id: 'AdminView.Cards.NotionTypes.Description',
    defaultMessage:
      'Creating, personalizing and tracking the use of standard concepts to label contractual clauses.',
  },
});
