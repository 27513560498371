import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { apiStatusTexts } from '@components/Chips/DocumentChip';

const STATUS_COLORS = {
  [apiStatusTexts.pending]: 'chipInfo',
  [apiStatusTexts.finished]: 'chipSuccess',
};

const STATUS_LABELS = {
  [apiStatusTexts.pending]: 'En cours',
  [apiStatusTexts.finished]: 'Validé',
};

const StyledChip = styled(Chip)(({ theme, color }) => ({
  color: theme.palette[color].contrastText,
  borderColor: theme.palette[color].contrastText,
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: '3px 23px',
  borderRadius: '4px',
  fontSize: '14px',
  fontWeight: 700,
  cursor: 'inherit',
}));

function DocumentChip({ status, ...rest }) {
  return (
    <StyledChip
      color={STATUS_COLORS[status] || 'chipInfo'}
      label={STATUS_LABELS[status] || 'En cours'}
      {...rest}
    />
  );
}

export default DocumentChip;
