/* eslint-disable react/no-array-index-key */
import React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { FieldArray, useField } from 'formik';
import * as Yup from 'yup';

import FormikSelect from '@components/Formik/FormikSelect';
import FormikTextField from '@components/Formik/FormikTextField';
import { requiredOrStripWhen } from '@utils/yup-required-when';
import { buyersTypes } from '@shells/nexity/nexity-constants';
import CivilForm, { schemaCivilForm } from './CivilForm';

import withFormsBehavior from '../withFormsBehavior';

const fields = {
  buyersType: 'buyersType',
  civilStates: 'civilStates',
  sciName: 'sciName',
  sciRcs: 'sciRcs',
};

const schema = Yup.object().shape({
  [fields.buyersType]: Yup.string()
    .nullable()
    .required('Generic.Fields.Required')
    .default(''),
  [fields.civilStates]: Yup.array().of(schemaCivilForm).default([]),
  [fields.sciName]: requiredOrStripWhen(
    Yup.string().default(''),
    fields.buyersType,
    buyersTypes.sci
  ),
  [fields.sciRcs]: requiredOrStripWhen(
    Yup.string().default(''),
    fields.buyersType,
    buyersTypes.sci
  ),
});

const defaultValues = {
  [fields.sciName]: '',
  [fields.sciRcs]: '',
};

function CivilForms({ readOnly, isFieldReadOnly }) {
  const [{ value: buyersType }] = useField(fields.buyersType);
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: 8,
        rowGap: 2,
      }}
    >
      <Box mt={2} sx={{ gridColumn: '1 / -1' }}>
        <FormikSelect
          required
          disabled={isFieldReadOnly(fields.buyersType)}
          name={fields.buyersType}
          label="Type des acquéreurs"
          FormControlProps={{
            color: 'secondary',
            margin: 'dense',
            disabled: isFieldReadOnly(fields.buyersType),
          }}
          margin="dense"
        >
          <MenuItem value={buyersTypes.physical}>
            Personne(s) physique(s)
          </MenuItem>
          <MenuItem value={buyersTypes.sci}>Société</MenuItem>
        </FormikSelect>
      </Box>
      {buyersType === buyersTypes.sci && (
        <>
          <FormikTextField
            as="fastfield"
            required
            disabled={isFieldReadOnly(fields.sciName)}
            name={fields.sciName}
            label="Nom de la société"
            color="secondary"
            margin="dense"
          />
          <FormikTextField
            as="fastfield"
            required
            disabled={isFieldReadOnly(fields.sciRcs)}
            name={fields.sciRcs}
            label="Numéro RCS de la société"
            color="secondary"
            margin="dense"
          />
        </>
      )}
      <FieldArray name={fields.civilStates}>
        {({ form }) =>
          form.values[fields.civilStates].map((_f, i) => (
            <CivilForm
              key={i}
              name={fields.civilStates}
              index={i}
              readOnly={readOnly}
              isFieldReadOnly={isFieldReadOnly}
            />
          ))
        }
      </FieldArray>
    </Box>
  );
}

const CivilView = withFormsBehavior(CivilForms, {
  type: 'civil',
  schema,
  getInitialValues: forms => ({
    ...defaultValues,
    ...schema.cast(
      Array.isArray(forms) ? { [fields.civilStates]: forms } : forms || {}
    ),
  }),
});

export default CivilView;
