import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import {
  useNavigate,
  useSearchParams,
  Link as RouterLink,
} from 'react-router-dom';
import IconGrabber from '@views/MangeData/components/IconGrabber';
import FormattedDateTime from '@components/FormattedDates/FormattedDateTime';
import {
  FormattedDate,
  FormattedTime,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { getDateTimezoneIndependantFromTimestamp } from '@utils/date-helpers';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import withPermissions, {
  TenantPermissionsComponent,
} from '@components/withTenantPermissions';
import tenantPermissions from '@utils/tenant-permissions';
import { getTermSheetElementDisplayName } from '@views/MangeData/components/utils';
import { Link } from '@mui/material';

function getTermSheetValueItemDisplay(value: any, item: any) {
  if (item === undefined || item === null) {
    return null;
  }

  switch (value?.type) {
    case 'Timestamp': {
      const convertedDate = parseInt(item, 10);
      return <FormattedDateTime value={convertedDate ?? undefined} />;
    }
    case 'DateOnly': {
      const convertedDate = parseInt(item, 10);
      return <FormattedDate value={convertedDate ?? undefined} />;
    }
    case 'TimeOnly': {
      const convertedDate = getDateTimezoneIndependantFromTimestamp(
        parseInt(item, 10)
      );
      return <FormattedTime value={convertedDate ?? undefined} />;
    }
    case 'Number': {
      return item.toLocaleString();
    }
    case 'Boolean': {
      return item.toLocaleString();
    }
    case 'Text': {
      const textItem =
        // eslint-disable-next-line no-nested-ternary
        typeof item === 'string'
          ? item
          : typeof item === 'object'
          ? item.value?.toString() ?? ''
          : item.toString();

      const isLink =
        textItem.startsWith('http://') || textItem.startsWith('https://');

      return isLink ? (
        <a href={textItem} target="_blank" rel="noreferrer">
          {textItem}
        </a>
      ) : (
        textItem
      );
    }
    default:
      break;
  }

  return item.toString();
}

export default function TermSheetCard({
  termSheetItem,
  parentId,
  isContractView,
}: {
  termSheetItem: any;
  parentId: string;
  isContractView: boolean;
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const backUrl = searchParams.get('backUrl');
  const IconButtonWithPermission = withPermissions(IconButton);

  function getReminderType(deadlineDateGapNumber: number) {
    if (deadlineDateGapNumber > 0) return 'After';
    return 'Before';
  }

  const displayName = getTermSheetElementDisplayName(
    intl,
    termSheetItem?.element ?? termSheetItem
  );

  return (
    <Box
      sx={{
        mt: 2,
        p: 1,
        bgcolor: theme => theme.palette.grey[100],
        width: '100%',
        borderRadius: '12px',
      }}
    >
      <Stack direction="column">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '24px',
              paddingTop: '5px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <IconGrabber icon={termSheetItem?.icon} iconSize="1.2rem" />
          </div>
          <div style={{ flex: 1 }}>
            <Typography
              variant="body2"
              sx={{
                flex: 1,
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '20px',
              }}
            >
              {displayName}
            </Typography>
          </div>
          <Stack direction="row" gap={1} ml="auto">
            <>
              {!termSheetItem.isProtected && (
                <IconButtonWithPermission
                  permissions={[tenantPermissions.TermSheetValuesUpdate]}
                  sx={{ fontSize: '1.1rem' }}
                  onClick={() =>
                    navigate(
                      `modals/edit-element/${termSheetItem.id}/edit?parentId=${
                        parentId || ''
                      }&backUrl=${encodeURIComponent(backUrl ?? '')}`,
                      { state: 'modals' }
                    )
                  }
                  color="primary"
                  edge="end"
                >
                  <EditIcon sx={{ fontSize: '1.1rem' }} />
                </IconButtonWithPermission>
              )}
              {!termSheetItem.isProtected && (
                <IconButtonWithPermission
                  permissions={[tenantPermissions.TermSheetValuesUpdate]}
                  sx={{ fontSize: '1.1rem' }}
                  color="error"
                  onClick={() =>
                    navigate(
                      `modals/delete-element/${
                        termSheetItem.id
                      }/delete?parentId=${
                        parentId || ''
                      }&backUrl=${encodeURIComponent(backUrl ?? '')}`,
                      { state: 'modals' }
                    )
                  }
                  edge="end"
                >
                  <DeleteOutlineIcon sx={{ fontSize: '1.1rem' }} />
                </IconButtonWithPermission>
              )}
            </>
          </Stack>
        </div>
        <Stack direction="row">
          <Stack sx={{ ml: 2 }}>
            {termSheetItem?.items?.map((item: any, index: number) => {
              // Items cannot be modified directly so the index is a good key.
              const key = index;
              const itemNode = getTermSheetValueItemDisplay(
                termSheetItem,
                item
              );
              return (
                <Typography
                  variant="body2"
                  key={key}
                  sx={{ overflowWrap: 'anywhere' }}
                >
                  {itemNode}
                </Typography>
              );
            })}
          </Stack>
        </Stack>
        <Stack mt={1} gap={0.5}>
          {termSheetItem?.deadline?.reminders?.map((reminder: any) => (
            <Typography variant="body2" key={reminder.id}>
              <Stack direction="row" gap={1}>
                <NotificationsActiveIcon fontSize="small" />
                <Link
                  component={RouterLink}
                  to={`modals/edit-reminder/${reminder.id}/edit?parentId=${parentId}`}
                >
                  <FormattedMessage
                    id={`Driveview.Reminder.${
                      reminder.deadlineDateGapUnit.name
                    }.${getReminderType(reminder.deadlineDateGapNumber)}`}
                    values={{
                      nbre: Math.abs(reminder.deadlineDateGapNumber),
                    }}
                  />
                </Link>
              </Stack>
            </Typography>
          ))}
        </Stack>
        {!isContractView &&
          Boolean(termSheetItem?.items?.[0]) &&
          (termSheetItem?.type === 'DateOnly' ||
            termSheetItem?.type === 'Timestamp') && (
            <TenantPermissionsComponent
              permissions={[tenantPermissions.RemindersCreate]}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                  marginTop: '15px',
                }}
              >
                <Button
                  onClick={() =>
                    navigate(
                      `modals/add-reminder/${termSheetItem.id}/${
                        termSheetItem.entityId
                      }/${
                        termSheetItem.items[0]?.toString() ?? ''
                      }/${encodeURIComponent(
                        displayName
                      )}/?parentId=${parentId}`
                    )
                  }
                  startIcon={<AddCircleIcon />}
                  variant="text"
                  size="small"
                >
                  <FormattedMessage
                    id="Driveview.Reminder.Buttons.Add.Label"
                    defaultMessage="Add a reminder"
                  />
                </Button>
              </div>
            </TenantPermissionsComponent>
          )}
      </Stack>
      <Typography variant="h4">{termSheetItem?.content}</Typography>
    </Box>
  );
}
