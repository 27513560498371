import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import PersonIcon from '@mui/icons-material/Person';

function AuthorChip({ name, ...rest }) {
  return <Chip icon={<PersonIcon />} label={name} {...rest} />;
}

AuthorChip.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default AuthorChip;
