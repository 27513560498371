import Typography from '@mui/material/Typography';
import { useOutletContext, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import useFetch from '@hooks/useFetch';
import { useCallback } from 'react';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';

export default function DeleteTermSheetElementModal({ entityId, refresh }) {
  const { close } = useOutletContext();
  const { id: elementId } = useParams();

  const {
    fetching,
    doFetch: doDelete,
    error,
  } = useFetch({
    url: `/term-sheets/entities/${entityId}/values/${elementId}`,
    method: 'DELETE',
    onSuccess: () => {
      close();
      refresh();
    },
  });

  const onDelete = useCallback(async () => {
    doDelete();
  }, [doDelete]);

  return (
    <DialogWithClose
      titleLabel={
        <FormattedMessage
          id="Driveview.Dialogs.DeleteTermSheetElement.Title"
          defaultMessage="Delete an element from the term sheet"
        />
      }
      dialogActions={
        <Button color="error" disabled={fetching} onClick={onDelete}>
          <FormattedMessage
            id="DriveView.DeleteTermSheetElement.Buttons.Delete.Label"
            defaultMessage="Delete"
          />
        </Button>
      }
    >
      <Stack gap={2}>
        <ErrorMessage error={error} />
        <Typography>
          <FormattedMessage
            id="DriveView.DeleteTermSheetElement.Description.Text"
            defaultMessage="Are you sure you want to delete this term sheet element ?"
          />
        </Typography>
      </Stack>
    </DialogWithClose>
  );
}
