import { useRecoilValue } from 'recoil';

import {
  tenantIsAuthenticatedSelector,
  tenantPermissionsSelector,
} from '@recoil/tenant-token';
import tenantPermissions from '@utils/tenant-permissions';

const useTenantPermissions = ({ permissions = [], mode = 'at-least' } = {}) => {
  let hasPermission = false;
  const isAuthenticated = useRecoilValue(tenantIsAuthenticatedSelector);
  const userPermissions = useRecoilValue(tenantPermissionsSelector);
  if (isAuthenticated) {
    if (userPermissions.includes(tenantPermissions.All)) {
      hasPermission = true;
    } else if (Array.isArray(permissions)) {
      if (mode === 'at-least') {
        hasPermission = permissions.some(p => userPermissions.includes(p));
      } else {
        hasPermission = permissions.every(p => userPermissions.includes(p));
      }
    } else {
      hasPermission = userPermissions.includes(permissions);
    }
  }
  return hasPermission;
};

export default useTenantPermissions;
