import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';

import ButtonTable from './buttons/button-table';
import ButtonImage from './buttons/button-image';

function ToolbarHtml() {
  return (
    <ButtonGroup variant="text">
      <ButtonTable />
      <ButtonImage />
    </ButtonGroup>
  );
}

export default ToolbarHtml;
