import { defineMessages } from 'react-intl';

export default defineMessages({
  GenericFieldsRequired: {
    id: 'Generic.Fields.Required',
    defaultMessage: '{label} is required',
  },
  GenericFieldsDateMaxToday: {
    id: 'Generic.Fields.Date.MaxToday',
    defaultMessage: '{label} cannot be greater than the current date',
  },
  GenericFieldsInvalidFormat: {
    id: 'Generic.Fields.InvalidFormat',
    defaultMessage: '{label} format is invalid',
  },
  GenericFieldsMustBePositive: {
    id: 'Generic.Fields.MustBePositive',
    defaultMessage: '{label} must be positive',
  },
  GenericBack: {
    id: 'Generic.Back',
    defaultMessage: 'Back',
  },
});
