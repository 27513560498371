import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { findParentNode } from '@tiptap/core';

import BorderAllIcon from '@mui/icons-material/BorderAll';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';
import BorderOuterIcon from '@mui/icons-material/BorderOuter';
import BorderRightIcon from '@mui/icons-material/BorderRight';
import BorderTopIcon from '@mui/icons-material/BorderTop';

import { useTiptapEditor } from '../../use-tiptap-editor';
import { useTiptapSelection } from '../../use-tiptap-selection';

function ButtonTableBorderAround() {
  const editor = useTiptapEditor();
  const selection = useTiptapSelection();
  const { borderTop, borderBottom, borderLeft, borderRight } =
    React.useMemo(() => {
      if (!selection) return {};
      const tableCell =
        selection.$anchor.node().type === editor.schema.nodes.tableCell
          ? selection.$anchor.node()
          : findParentNode(n => n.type === editor.schema.nodes.tableCell)(
              selection
            )?.node;
      if (!tableCell) return {};
      return {
        borderTop: tableCell.attrs.borderTop,
        borderBottom: tableCell.attrs.borderBottom,
        borderLeft: tableCell.attrs.borderLeft,
        borderRight: tableCell.attrs.borderRight,
      };
    }, [editor, selection]);
  return (
    <PopupState variant="popper" popupId="table-cell-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={
              <FormattedMessage id="Editor.Toolbar.Command.Table.CellParam.Border.Settings" />
            }
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <BorderAllIcon />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <MenuList sx={{ p: 0 }} component={Paper} elevation={8}>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .setCellAttribute('borderTop', 'none')
                        .setCellAttribute('borderBottom', 'none')
                        .setCellAttribute('borderLeft', 'none')
                        .setCellAttribute('borderRight', 'none')
                        .run();
                      popupState.close();
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={
                        <FormattedMessage id="Editor.Toolbar.Command.Table.CellParam.Border.None" />
                      }
                    >
                      <BorderClearIcon />
                    </Tooltip>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .setCellAttribute(
                          'borderTop',
                          borderTop ? undefined : 'none'
                        )
                        .run();
                      popupState.close();
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={
                        <FormattedMessage id="Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Top" />
                      }
                    >
                      <BorderTopIcon />
                    </Tooltip>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .setCellAttribute(
                          'borderBottom',
                          borderBottom ? undefined : 'none'
                        )
                        .run();
                      popupState.close();
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={
                        <FormattedMessage id="Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Bottom" />
                      }
                    >
                      <BorderBottomIcon />
                    </Tooltip>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .setCellAttribute(
                          'borderLeft',
                          borderLeft ? undefined : 'none'
                        )
                        .run();
                      popupState.close();
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={
                        <FormattedMessage id="Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Left" />
                      }
                    >
                      <BorderLeftIcon />
                    </Tooltip>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .setCellAttribute(
                          'borderRight',
                          borderRight ? undefined : 'none'
                        )
                        .run();
                      popupState.close();
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={
                        <FormattedMessage id="Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Right" />
                      }
                    >
                      <BorderRightIcon />
                    </Tooltip>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .setCellAttribute('borderTop', undefined)
                        .setCellAttribute('borderBottom', undefined)
                        .setCellAttribute('borderLeft', undefined)
                        .setCellAttribute('borderRight', undefined)
                        .run();
                      popupState.close();
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={
                        <FormattedMessage id="Editor.Toolbar.Command.Table.CellParam.Border.Enabled" />
                      }
                    >
                      <BorderOuterIcon />
                    </Tooltip>
                  </MenuItem>
                </MenuList>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonTableBorderAround;
