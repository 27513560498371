import React from 'react';
import { isActive } from '@tiptap/core';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';

import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import ColorPicker from '@components/Color/ColorPicker';

import { useTiptapEditor } from '../../use-tiptap-editor';
import { useTiptapSelection } from '../../use-tiptap-selection';

function ButtonTextColor() {
  const editor = useTiptapEditor();
  const selection = useTiptapSelection();
  const [updatedSelection, setUpdateStatus] = React.useState(false);
  if (updatedSelection) {
    setUpdateStatus(false);
  }
  const color = React.useMemo(() => {
    const node = selection?.$anchor.doc.nodeAt(selection.from);
    if (node) {
      setUpdateStatus(true); // set true to force reset color in ColorPicker
    }
    return node?.marks.find(m => m.type.name === 'textStyle')?.attrs.color;
  }, [selection]);

  return (
    <PopupState variant="popper" popupId="font-size-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={<FormattedMessage id="Editor.Toolbar.Command.TextColor" />}
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <FormatColorTextIcon />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <Paper elevation={8}>
                  <ColorPicker
                    color={color}
                    forceUpdateColor={updatedSelection}
                    onReset={() => {
                      editor.chain().focus().setColor(null).run();
                      popupState.close();
                    }}
                    onSubmit={color => {
                      editor.chain().focus().setColor(color).run();
                      popupState.close();
                    }}
                  />
                </Paper>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonTextColor;
