import { useLayoutEffect } from 'react';
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { hostIsAuthenticatedSelector } from '@recoil/host-token';
import {
  tenantAuthenticationSelector,
  tenantDecodedTokenSelector,
} from '@recoil/tenant-token';
import decode from 'jwt-decode';

function extractReturnUrlFromRaw(rawUrl) {
  try {
    const parsedUrl = new URL(rawUrl);
    return `${parsedUrl.pathname}${parsedUrl.search}${parsedUrl.hash}`;
  } catch {
    return rawUrl;
  }
}

function TokenCapture({ children, chooseNavigation }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenParam = useMatch('/capture') ? searchParams.get('token') : null;
  const rawReturnUrl = searchParams.get('returnUrl');
  const returnUrl = extractReturnUrlFromRaw(rawReturnUrl);
  const decodedTokenParam = tokenParam ? decode(tokenParam) : null;
  const hostState = useRecoilValue(hostIsAuthenticatedSelector);
  const setHostState = useSetRecoilState(hostIsAuthenticatedSelector);
  const tenantState = useRecoilValue(tenantAuthenticationSelector);
  const setTenantState = useSetRecoilState(tenantAuthenticationSelector);
  const decodedTenantToken = useRecoilValue(tenantDecodedTokenSelector);

  function isSameUser(tok1, tok2) {
    return decode(tok1.value).nameid === decode(tok2.value).nameid;
  }

  useLayoutEffect(() => {
    function handleHostToken(obj) {
      const state = {
        succeeded: true,
        token: obj.token,
        refreshToken: obj.refreshToken,
        tenants: obj.tenants ? JSON.parse(obj.tenants.value) : null,
      };
      setHostState(state);

      if (tenantState && !isSameUser(state.token, tenantState.token)) {
        setTenantState(null);
      }

      navigate(
        `/signin/authorize?redirectTo=${
          returnUrl ? encodeURIComponent(returnUrl) : ''
        }`,
        {
          state: location.state,
          replace: true,
        }
      );
    }

    function handleTenantToken(obj) {
      const tenantToken = obj.token;
      const tenantRefreshToken = obj.refreshToken;

      const state = {
        fromSearchParam: true,
        token: tenantToken,
        refreshToken: tenantRefreshToken,
      };

      setTenantState(state);

      if (hostState && !isSameUser(state.token, hostState.token)) {
        setHostState(null);
      }
    }

    if (decodedTokenParam) {
      if (decodedTokenParam.tenantToken && decodedTokenParam.hostToken) {
        handleHostToken({ token: decodedTokenParam.hostToken });
        handleTenantToken({ token: decodedTokenParam.tenantToken });
        return;
      }

      const { actort } = decode(decodedTokenParam.token.value);
      switch (actort) {
        case 'tenant-token': {
          handleTenantToken(decodedTokenParam);
          break;
        }
        case 'host-token': {
          handleHostToken(decodedTokenParam);
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [
    decodedTokenParam,
    hostState,
    setHostState,
    tenantState,
    setTenantState,
    location.state,
    navigate,
    returnUrl,
  ]);

  useLayoutEffect(() => {
    if (tokenParam && tenantState?.fromSearchParam) {
      const { navigationType, navigationElement } = decodedTenantToken;

      const to =
        returnUrl ||
        chooseNavigation({
          type: navigationType,
          element: navigationElement,
          token: decodedTenantToken,
        });

      navigate(to, {
        state: location.state,
        replace: true,
      });
    }
  }, [
    chooseNavigation,
    location,
    tokenParam,
    tenantState,
    decodedTenantToken,
    navigate,
    returnUrl,
  ]);

  return tokenParam ? null : children;
}

export default TokenCapture;
