import React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { FormattedMessage } from 'react-intl';

export default function ErrorAlert({ message }) {
  return (
    <Stack sx={{ width: '100%', fontWeight: 100 }} spacing={1}>
      <Alert
        sx={{ color: '#4A4A4A' }}
        icon={<CancelRoundedIcon color="error" />}
        severity="error"
      >
        <FormattedMessage id={message || 'Errors.ErrorOccured'} />
      </Alert>
    </Stack>
  );
}
