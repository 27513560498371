import React from 'react';
import FormikProvider from '@components/Formik/FormikProvider';
import * as Yup from 'yup';
import useFetch from '@hooks/useFetch';
import { useOutletContext, useParams } from 'react-router-dom';
import AddElementToTermSheetForm from './AddElementToTermSheetForm';

const schema = Yup.object().shape({
  icon: Yup.string().required('Generic.Fields.Required'),
  name: Yup.string().required('Generic.Fields.Required'),
  elementId: Yup.string(),
  entityType: Yup.string().required('Generic.Fields.Required'),
  content: Yup.string(),
});
const termSheetSchemaValidationSchema = Yup.object({
  values: Yup.array(schema),
});

export default function AddElementToTermSheetModal({
  disabled,
  title,
  refetch,
}) {
  const { close } = useOutletContext();
  const { id: entityId } = useParams();

  const {
    doFetch: doPost,
    error,
    fetching: posting,
  } = useFetch({
    url: `term-sheets/entities/${entityId}/values`,
    method: 'POST',
    onSuccess: () => {
      close();
      refetch();
    },
  });

  const onSubmit = values => {
    const send = doPost;
    send({
      body: {
        elementId: values.elementId,
        items: [values.content],
        name: values.name,
        icon: values.icon,
        entityType: values.entityType,
      },
    });
  };

  return (
    <FormikProvider
      schema={termSheetSchemaValidationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      enableReinitialize
    >
      <AddElementToTermSheetForm
        disabled={disabled || posting}
        title={title}
        actionButtonText="ManageDataView.CreateElement.Button.Create.Label"
        defaultActionButtonText="Create"
        error={error}
      />
    </FormikProvider>
  );
}
