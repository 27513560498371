import React from 'react';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

function DialogTitleWithClose({ children, onClose, ...props }) {
  return (
    <DialogTitle
      {...props}
      sx={{
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
      }}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[700],
          }}
        >
          <CloseIcon
            sx={{
              stroke: theme => theme.palette.grey[700],
              fontSize: '16px',
              strokeWidth: 0.5,
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default DialogTitleWithClose;
