import React, { useMemo } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AssignmentIcon from '@mui/icons-material/Assignment';
import useFetch from '@hooks/useFetch';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from 'recharts';

const isNullOrUndefined = v => v === null || v === undefined;

function StatHeader({ children }) {
  return (
    <Box
      sx={{
        bgcolor: '#F5F5F5',
        borderRadius: 1,
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#3D3935',
        fontWeight: 'bold',
        mt: 4,
        mb: 2,
      }}
    >
      {children}
    </Box>
  );
}

function StatGrid({ children }) {
  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr 1fr"
      gap={4}
      gridAutoRows="auto"
    >
      {children}
    </Box>
  );
}

function StatCard({
  label,
  value,
  gridRow = 'auto',
  gridColumn = 'auto',
  Icon = AssignmentIcon,
  fetching,
}) {
  return (
    <Paper
      sx={{
        boxShadow: 'rgb(225 225 225 / 25%) 0 0 17px 0',
        p: 3,
        gridRow,
        gridColumn,
      }}
    >
      <Stack direction="row" alignItems="center" gap={2} height="100%">
        {!fetching && <Icon />}
        <Box flexGrow={1}>
          {fetching ? (
            <Skeleton variant="rectangular" height={80} />
          ) : (
            <Typography>
              {label} : <strong>{value}</strong>
            </Typography>
          )}
        </Box>
      </Stack>
    </Paper>
  );
}

function StatChart({
  label,
  gridRow = 'auto',
  gridColumn = 'auto',
  fetching,
  children,
}) {
  return (
    <Paper
      sx={{
        boxShadow: 'rgb(225 225 225 / 25%) 0 0 17px 0',
        p: 3,
        gridRow,
        gridColumn,
        minHeight: '300px',
      }}
    >
      <Stack direction="column" height="100%">
        {!fetching && (
          <Box mb={4}>
            <Typography>{label}</Typography>
          </Box>
        )}
        <Box flexGrow={1}>
          {fetching ? (
            <Skeleton variant="rectangular" height="100%" />
          ) : (
            children
          )}
        </Box>
      </Stack>
    </Paper>
  );
}

function StatList({ gridRow, gridColumn, fetching, items, label }) {
  return (
    <Paper
      sx={{
        boxShadow: 'rgb(225 225 225 / 25%) 0 0 17px 0',
        p: 3,
        gridRow,
        gridColumn,
      }}
    >
      <List
        subheader={<ListSubheader component="div">{label}</ListSubheader>}
        sx={{ maxHeight: '300px', overflowY: 'auto' }}
      >
        {fetching ? (
          <Skeleton variant="rectangular" height={80} />
        ) : (
          items?.map(item => (
            <ListItem secondaryAction={item.secondaryAction}>
              <ListItemText primary={item.primary} secondary={item.secondary} />
            </ListItem>
          ))
        )}
      </List>
    </Paper>
  );
}

function StatisticsView() {
  const theme = useTheme();
  const { data, error, fetching } = useFetch({
    url: 'nexity/dashboard/stats',
  });

  const primaryColor = theme.palette.primary.main;
  const showMoreStats = Boolean(data?.showMoreStats);

  const modificationsCountPerVariableNameData =
    data?.modificationsCountPerVariableName;
  const modificationsCountPerVariableNameItems = useMemo(
    () =>
      modificationsCountPerVariableNameData?.map(p => ({
        primary: p.key.split('-')?.[0]?.trim() || '',
        secondary: p.key.split('-')?.[1]?.trim() || '',
        secondaryAction: <strong>{p.count} modif.</strong>,
      })),
    [modificationsCountPerVariableNameData]
  );

  return (
    <Container>
      <ErrorMessage error={error} />
      <StatHeader>Traitement des dossiers</StatHeader>
      <StatGrid>
        {showMoreStats && (
          <StatCard
            fetching={fetching}
            label="Nombre de lots en cours de contractualisation depuis plus de 4 jours"
            value={data?.pendingSpacesForDaysCount}
          />
        )}
        {showMoreStats && (
          <StatChart
            fetching={fetching}
            gridRow="span 3"
            gridColumn="span 2"
            label="Nouveaux contrats réalisés au mois"
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data?.newContractsCountPerMonth}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="key" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill={primaryColor} />
              </BarChart>
            </ResponsiveContainer>
          </StatChart>
        )}
        <StatCard
          fetching={fetching}
          label="Temps moyen de validation formulaire en jours"
          value={
            data?.averageTimeBetweenCreationAndClientInfoInDays?.toFixed(1) ??
            'N/A'
          }
        />
        <StatCard
          fetching={fetching}
          label="Nombre de contrats en cours de contractualisation"
          value={data?.spacesInContractualizationCount}
        />
        {showMoreStats && (
          <StatChart
            fetching={fetching}
            gridRow="span 3"
            gridColumn="span 2"
            label="Nombre de lots en cours de contractualisation par programme"
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data?.pendingSpacesCountPerProgram}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="key" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill={primaryColor} />
              </BarChart>
            </ResponsiveContainer>
          </StatChart>
        )}
        <StatCard
          fetching={fetching}
          label="Nombre de contrats en cours de validation manager"
          value={data?.spacesWaitingForAnimatorValidationCount}
        />
        {showMoreStats && (
          <StatCard
            fetching={fetching}
            label="Temps moyen entre la création des contrats et la pré-réservation en jours"
            value={
              data?.averageTimeBetweenContractsAndSignatureInDays?.toFixed(1) ??
              'N/A'
            }
          />
        )}
        {showMoreStats && (
          <StatCard
            fetching={fetching}
            label="Nombre de lots “en cours de contractualisation” remis en vente"
            value={data?.purgedSpacesCount}
          />
        )}
        {showMoreStats && (
          <StatList
            fetching={fetching}
            gridRow="span 3"
            gridColumn="span 2"
            label="Nombre de modifications par champs de modèles"
            items={modificationsCountPerVariableNameItems}
          />
        )}
      </StatGrid>
      <StatHeader>Suivi des enveloppes</StatHeader>
      <StatGrid>
        {showMoreStats && (
          <StatCard
            fetching={fetching}
            label="Nombre d'enveloppes créées"
            value={data?.createdSignaturesCount}
          />
        )}
        {showMoreStats && (
          <StatCard
            fetching={fetching}
            label="Pré-réservations effectuées en moins de 4 jours après le passage au statut en cours de contractualisation"
            value={
              !isNullOrUndefined(data?.reservationsInLessThanDaysPercent)
                ? `${data.reservationsInLessThanDaysPercent}%`
                : 'N/A'
            }
          />
        )}
        {showMoreStats && (
          <StatCard
            fetching={fetching}
            label="Pré-réservations effectuées en plus de 4 jours après le passage au statut en cours de contractualisation"
            value={
              !isNullOrUndefined(data?.reservationsInMoreThanDaysPercent)
                ? `${data.reservationsInMoreThanDaysPercent}%`
                : 'N/A'
            }
          />
        )}
        {showMoreStats && (
          <StatCard
            fetching={fetching}
            label="Nombre d'enveloppes en signature client depuis plus de 4 jours"
            value={data?.sentSignaturesForDaysCount}
          />
        )}
        {showMoreStats && (
          <StatCard
            fetching={fetching}
            label="Nombre d'enveloppes invalidées"
            value={data?.revokedSignaturesCount}
          />
        )}
        {showMoreStats && (
          <StatCard
            fetching={fetching}
            label="Nombre d'enveloppes signées par les 2 parties"
            value={data?.signedSignaturesCount}
          />
        )}
        <StatCard
          fetching={fetching}
          label="Nombre d'enveloppes en cours de signature"
          value={data?.spacesWaitingForSignatureCount}
        />
        <StatCard
          fetching={fetching}
          label="Nombre d'enveloppes expirant dans moins de 24h"
          value={data?.spaceSignaturesExpiringSoonCount}
        />
      </StatGrid>
    </Container>
  );
}

export default StatisticsView;
