import Fetching from '@components/Fetching/Fetching';
import { tenantIdentifierSelector } from '@recoil/tenant-token';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

function TenantRedirect({
  defaultRedirect = '/dashboard',
}: {
  defaultRedirect?: string;
}) {
  const { tenantId, '*': redirectTo } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentTenantId = useRecoilValue(tenantIdentifierSelector);

  useEffect(() => {
    if (tenantId === null) {
      navigate(defaultRedirect);
    } else if (tenantId === currentTenantId) {
      navigate(decodeURIComponent(`/${redirectTo}`));
    } else {
      navigate(
        `/signin/authorize?redirectTo=${location.pathname}${location.search}${location.hash}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenantId, location]);

  return <Fetching fetching />;
}

export default TenantRedirect;
