import { defineMessages } from 'react-intl';

export default defineMessages({
  HomepageWelcomeMessage: {
    id: 'Homepage.Welcome.Message',
    defaultMessage: 'Welcome!',
  },
  HomepageMenuLegalNotice: {
    id: 'Homepage.Menu.LegalNotice',
    defaultMessage: 'Legal notice',
  },
  HomepageMenuPrivacyPolicy: {
    id: 'Homepage.Menu.PrivacyPolicy',
    defaultMessage: 'Privacy policy',
  },
  HomepageMenuContactUs: {
    id: 'Homepage.Menu.ContactUs',
    defaultMessage: 'Contact us',
  },
});
