import { defineMessages } from 'react-intl';

export default defineMessages({
  EditorTitle: {
    id: 'Editor.Title',
    defaultMessage: 'Title of the dynamic document: <b>{name}</b>',
  },
  EditorToolbarCommandBold: {
    id: 'Editor.Toolbar.Command.Bold',
    defaultMessage: 'Bold',
  },
  EditorToolbarCommandItalic: {
    id: 'Editor.Toolbar.Command.Italic',
    defaultMessage: 'Italic',
  },
  EditorToolbarCommandUnderline: {
    id: 'Editor.Toolbar.Command.Underline',
    defaultMessage: 'Underline',
  },
  EditorToolbarCommandStrike: {
    id: 'Editor.Toolbar.Command.Strike',
    defaultMessage: 'Strikethrough',
  },
  EditorToolbarCommandHeading: {
    id: 'Editor.Toolbar.Command.Heading',
    defaultMessage: 'Paragraph type',
  },
  EditorToolbarCommandHeadingParagraph: {
    id: 'Editor.Toolbar.Command.Heading.Paragraph',
    defaultMessage: 'Paragraph',
  },
  EditorToolbarCommandHeadingLevel: {
    id: 'Editor.Toolbar.Command.Heading.Level',
    defaultMessage: 'Title {level}',
  },
  EditorToolbarCommandFontFamily: {
    id: 'Editor.Toolbar.Command.FontFamily',
    defaultMessage: 'Font family',
  },
  EditorToolbarCommandFontSize: {
    id: 'Editor.Toolbar.Command.FontSize',
    defaultMessage: 'Font size',
  },
  EditorToolbarCommandColorClear: {
    id: 'Editor.Toolbar.Command.Color.Clear',
    defaultMessage: 'Clear color',
  },
  EditorToolbarCommandTextColor: {
    id: 'Editor.Toolbar.Command.TextColor',
    defaultMessage: 'Text color',
  },
  EditorToolbarCommandBackgroundColor: {
    id: 'Editor.Toolbar.Command.BackgroundColor',
    defaultMessage: 'Background color',
  },
  EditorToolbarCommandClearFormat: {
    id: 'Editor.Toolbar.Command.ClearFormat',
    defaultMessage: 'Clear format',
  },
  EditorToolbarCommandAlignLeft: {
    id: 'Editor.Toolbar.Command.Align.Left',
    defaultMessage: 'Left align',
  },
  EditorToolbarCommandAlignCenter: {
    id: 'Editor.Toolbar.Command.Align.Center',
    defaultMessage: 'Center',
  },
  EditorToolbarCommandAlignRight: {
    id: 'Editor.Toolbar.Command.Align.Right',
    defaultMessage: 'Right align',
  },
  EditorToolbarCommandAlignJustify: {
    id: 'Editor.Toolbar.Command.Align.Justify',
    defaultMessage: 'Justify',
  },
  EditorToolbarCommandListUnordered: {
    id: 'Editor.Toolbar.Command.List.Unordered',
    defaultMessage: 'Unordered list',
  },
  EditorToolbarCommandListOrdered: {
    id: 'Editor.Toolbar.Command.List.Ordered',
    defaultMessage: 'Ordered list',
  },
  EditorToolbarCommandUndo: {
    id: 'Editor.Toolbar.Command.Undo',
    defaultMessage: 'Undo',
  },
  EditorToolbarCommandRedo: {
    id: 'Editor.Toolbar.Command.Redo',
    defaultMessage: 'Redo',
  },
  EditorToolbarCommandFormatPainter: {
    id: 'Editor.Toolbar.Command.Format.Painter',
    defaultMessage: 'Format Painter',
  },
  EditorToolbarCommandTableInsert: {
    id: 'Editor.Toolbar.Command.Table.Insert',
    defaultMessage: 'Insert table',
  },
  EditorToolbarCommandTableDelete: {
    id: 'Editor.Toolbar.Command.Table.Delete',
    defaultMessage: 'Delete table',
  },
  EditorToolbarCommandTableCell: {
    id: 'Editor.Toolbar.Command.Table.Cell',
    defaultMessage: 'Cell settings',
  },
  EditorToolbarCommandTableCellSplit: {
    id: 'Editor.Toolbar.Command.Table.Cell.Split',
    defaultMessage: 'Split cell',
  },
  EditorToolbarCommandTableCellParamBorderNone: {
    id: 'Editor.Toolbar.Command.Table.CellParam.Border.None',
    defaultMessage: 'Borderless',
  },
  EditorToolbarCommandTableCellParamBorderEnabled: {
    id: 'Editor.Toolbar.Command.Table.CellParam.Border.Enabled',
    defaultMessage: 'Enable borders',
  },
  EditorToolbarCommandTableCellParamBorderDotted: {
    id: 'Editor.Toolbar.Command.Table.CellParam.Border.Dotted',
    defaultMessage: 'Dotted border',
  },
  EditorToolbarCommandTableCellParamBorderDashed: {
    id: 'Editor.Toolbar.Command.Table.CellParam.Border.Dashed',
    defaultMessage: 'Dashed border',
  },
  EditorToolbarCommandTableCellParamBorderSolid: {
    id: 'Editor.Toolbar.Command.Table.CellParam.Border.Solid',
    defaultMessage: 'Solid border',
  },
  EditorToolbarCommandTableCellParamBorderStyle: {
    id: 'Editor.Toolbar.Command.Table.CellParam.Border.Style',
    defaultMessage: "Border's style",
  },
  EditorToolbarCommandTableCellParamBorderSettings: {
    id: 'Editor.Toolbar.Command.Table.CellParam.Border.Settings',
    defaultMessage: 'Borders settings',
  },
  EditorToolbarCommandTableCellParamBorderToggleTop: {
    id: 'Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Top',
    defaultMessage: 'Toggle the top border',
  },
  EditorToolbarCommandTableCellParamBorderToggleBottom: {
    id: 'Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Bottom',
    defaultMessage: 'Toggle the bottom border',
  },
  EditorToolbarCommandTableCellParamBorderToggleLeft: {
    id: 'Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Left',
    defaultMessage: 'Toggle the left border',
  },
  EditorToolbarCommandTableCellParamBorderToggleRight: {
    id: 'Editor.Toolbar.Command.Table.CellParam.Border.Toggle.Right',
    defaultMessage: 'Toggle the right border',
  },
  EditorToolbarCommandTableCellParamVerticalAlign: {
    id: 'Editor.Toolbar.Command.Table.CellParam.VerticalAlign',
    defaultMessage: 'Vertical alignment',
  },
  EditorToolbarCommandTableCellParamVerticalAlignTop: {
    id: 'Editor.Toolbar.Command.Table.CellParam.VerticalAlign.Top',
    defaultMessage: 'Top',
  },
  EditorToolbarCommandTableCellParamVerticalAlignMiddle: {
    id: 'Editor.Toolbar.Command.Table.CellParam.VerticalAlign.Middle',
    defaultMessage: 'Middle',
  },
  EditorToolbarCommandTableCellParamVerticalAlignBottom: {
    id: 'Editor.Toolbar.Command.Table.CellParam.VerticalAlign.Bottom',
    defaultMessage: 'Bottom',
  },
  EditorToolbarCommandTableColumnSizeLabel: {
    id: 'Editor.Toolbar.Command.Table.Column.Size.Label',
    defaultMessage: 'Column size',
  },
  EditorToolbarCommandTableColumnSizeResetDefault: {
    id: 'Editor.Toolbar.Command.Table.Column.Size.Reset.Default',
    defaultMessage: 'Reset default column size',
  },
  EditorToolbarCommandMerge: {
    id: 'Editor.Toolbar.Command.Table.Cell.Merge',
    defaultMessage: 'Merge cells',
  },
  EditorToolbarCommandTableColumn: {
    id: 'Editor.Toolbar.Command.Table.Column',
    defaultMessage: 'Column settings',
  },
  EditorToolbarCommandTableColumnBefore: {
    id: 'Editor.Toolbar.Command.Table.Column.Before',
    defaultMessage: 'Add column before',
  },
  EditorToolbarCommandTableColumnAfter: {
    id: 'Editor.Toolbar.Command.Table.Column.After',
    defaultMessage: 'Add column after',
  },
  EditorToolbarCommandTableColumnDelete: {
    id: 'Editor.Toolbar.Command.Table.Column.Delete',
    defaultMessage: 'Delete column',
  },
  EditorToolbarCommandTableRow: {
    id: 'Editor.Toolbar.Command.Table.Row',
    defaultMessage: 'Row settings',
  },
  EditorToolbarCommandTableRowBefore: {
    id: 'Editor.Toolbar.Command.Table.Row.Before',
    defaultMessage: 'Add row before',
  },
  EditorToolbarCommandTableRowAfter: {
    id: 'Editor.Toolbar.Command.Table.Row.After',
    defaultMessage: 'Add row after',
  },
  EditorToolbarCommandTableRowDelete: {
    id: 'Editor.Toolbar.Command.Table.Row.Delete',
    defaultMessage: 'Delete row',
  },
  EditorToolbarCommandTableHeader: {
    id: 'Editor.Toolbar.Command.Table.Header',
    defaultMessage: 'Header settings',
  },
  EditorToolbarCommandTableHeaderRow: {
    id: 'Editor.Toolbar.Command.Table.Header.Row',
    defaultMessage: 'Toggle header on first row',
  },
  EditorToolbarCommandTableHeaderColumn: {
    id: 'Editor.Toolbar.Command.Table.Header.Column',
    defaultMessage: 'Toggle header on first column',
  },
  EditorToolbarCommandTableHeaderCell: {
    id: 'Editor.Toolbar.Command.Table.Header.Cell',
    defaultMessage: 'Toggle header on cell',
  },
  EditorToolbarCommandTableCellBorderColor: {
    id: 'Editor.Toolbar.Command.Table.Cell.BorderColor',
    defaultMessage: 'Cell border color',
  },
  EditorToolbarCommandMarginLabel: {
    id: 'Editor.Toolbar.Command.Margin.Label',
    defaultMessage: 'Margin',
  },
  EditorToolbarCommandMarginTop: {
    id: 'Editor.Toolbar.Command.Margin.Top',
    defaultMessage: 'Margin top',
  },
  EditorToolbarCommandMarginBottom: {
    id: 'Editor.Toolbar.Command.Margin.Bottom',
    defaultMessage: 'Margin bottom',
  },
  EditorToolbarCommandMarginLeft: {
    id: 'Editor.Toolbar.Command.Margin.Left',
    defaultMessage: 'Margin left',
  },
  EditorToolbarCommandMarginRight: {
    id: 'Editor.Toolbar.Command.Margin.Right',
    defaultMessage: 'Margin right',
  },
  EditorToolbarCommandClauseQdataAdd: {
    id: 'Editor.Toolbar.Command.Clause.Qdata.Add',
    defaultMessage: 'Add a clause',
  },
  EditorToolbarCommandClauseScqAdd: {
    id: 'Editor.Toolbar.Command.Clause.Scq.Add',
    defaultMessage: 'Add a single choice clause',
  },
  EditorToolbarCommandClauseMcqAdd: {
    id: 'Editor.Toolbar.Command.Clause.Mcq.Add',
    defaultMessage: 'Add a multiple choice clause',
  },
  EditorToolbarCommandClauseDelete: {
    id: 'Editor.Toolbar.Command.Clause.Delete',
    defaultMessage: 'Delete clause',
  },
  EditorToolbarCommandLineAbove: {
    id: 'Editor.Toolbar.Command.Line.Above',
    defaultMessage: 'Add a new line above',
  },
  EditorToolbarCommandLineBelow: {
    id: 'Editor.Toolbar.Command.Line.Below',
    defaultMessage: 'Add a new line below',
  },
  EditorToolbarCommandVariabledAdd: {
    id: 'Editor.Toolbar.Command.Variable.Add',
    defaultMessage: 'Add a variable',
  },
  EditorToolbarCommandVariableLinkedAdd: {
    id: 'Editor.Toolbar.Command.Variable.Linked.Add',
    defaultMessage: 'Link an existing variable',
  },
  EditorToolbarCommandVariantAdd: {
    id: 'Editor.Toolbar.Command.Variant.Add',
    defaultMessage: 'Add a variant',
  },
  EditorToolbarCommandImageInsert: {
    id: 'Editor.Toolbar.Command.Image.Insert',
    defaultMessage: 'Insert image',
  },
  EditorToolbarCommandHeaderFooterToogle: {
    id: 'Editor.Toolbar.Command.HeaderFooter.Toogle',
    defaultMessage: 'Toggle header / footer',
  },
  EditorToolbarCommandLineHeight: {
    id: 'Editor.Toolbar.Command.Line.Height',
    defaultMessage: 'Line spacing',
  },
  EditorToolbarCommandCommentAdd: {
    id: 'Editor.Toolbar.Command.Comment.Add',
    defaultMessage: 'Add a comment',
  },
  EditorImageInvalidFormat: {
    id: 'Editor.Image.InvalidFormat',
    defaultMessage: 'File must be an image',
  },
  EditorImageFromComputerLabel: {
    id: 'Editor.Image.FromComputer.Label',
    defaultMessage: 'From computer',
  },
  EditorImageFromInternetLabel: {
    id: 'Editor.Image.FromInternet.Label',
    defaultMessage: 'From internet',
  },
  EditorImageFromInternetInvalidFormat: {
    id: 'Editor.Image.FromInternet.InvalidFormat',
    defaultMessage: 'Url of the image is invalid',
  },
  EditorImageFromInternetRequired: {
    id: 'Editor.Image.FromInternet.Required',
    defaultMessage: 'Url of the image is required',
  },
  EditorDialogDeleteVariableTitle: {
    id: 'Editor.Dialog.Delete.Variable.Title',
    defaultMessage: 'Confirm variable deletion',
  },
  EditorDialogDeleteVariableContent: {
    id: 'Editor.Dialog.Delete.Variable.Content',
    defaultMessage:
      'Do you confirm you want to delete the variable <b>{name}</b>?',
  },
  EditorDialogDeleteVariantTitle: {
    id: 'Editor.Dialog.Delete.Variant.Title',
    defaultMessage: 'Confirm variant deletion',
  },
  EditorDialogDeleteVariantContent: {
    id: 'Editor.Dialog.Delete.Variant.Content',
    defaultMessage:
      'Do you confirm you want to delete the variant <b>{name}</b>?',
  },
  EditorDialogDeleteClauseTitle: {
    id: 'Editor.Dialog.Delete.Clause.Title',
    defaultMessage: 'Confirm clause deletion',
  },
  EditorDialogDeleteClauseQdataContent: {
    id: 'Editor.Dialog.Delete.Clause.Qdata.Content',
    defaultMessage:
      'Do you confirm you want to delete the clause <b>{name}</b>?',
  },
  EditorDialogDeleteClauseContent: {
    id: 'Editor.Dialog.Delete.Clause.Content',
    defaultMessage:
      "Please choose the variant content to replace the clause you're about to delete:",
  },
  EditorDialogAddVariableLinkedTitle: {
    id: 'Editor.Dialog.Add.Variable.Linked.Title',
    defaultMessage: 'Link a variable',
  },
  EditorVariableFieldsLinkedClauseLabel: {
    id: 'Editor.Variable.Fields.Linked.Clause.Label',
    defaultMessage: 'Linked clause',
  },
  EditorVariableFieldsLinkedVariantLabel: {
    id: 'Editor.Variable.Fields.Linked.Variant.Label',
    defaultMessage: 'Linked variant',
  },
  EditorVariableFieldsLinkedVariableLabel: {
    id: 'Editor.Variable.Fields.Linked.Variable.Label',
    defaultMessage: 'Linked variable',
  },
  EditorVariableFieldsAllowedRolesLabel: {
    id: 'Editor.Variable.Fields.AllowedRoles.Label',
    defaultMessage: 'Allowed roles',
  },
  EditorClauseFieldsNameLabel: {
    id: 'Editor.Clause.Fields.Name.Label',
    defaultMessage: 'Clause name',
  },
  EditorClauseFieldsNameRequired: {
    id: 'Editor.Clause.Fields.Name.Required',
    defaultMessage: 'Clause name is required',
  },
  EditorClauseFieldsQuestionLabel: {
    id: 'Editor.Clause.Fields.Question.Label',
    defaultMessage: 'Title of the question',
  },
  EditorClauseFieldsQuestionRequired: {
    id: 'Editor.Clause.Fields.Question.Required',
    defaultMessage: 'Title of the question is required',
  },
  EditorClauseFieldsExplanationsLabel: {
    id: 'Editor.Clause.Fields.Explanations.Label',
    defaultMessage: 'Explanations',
  },
  EditorClauseFieldsExplanationsPlaceholder: {
    id: 'Editor.Clause.Fields.Explanations.Placeholder',
    defaultMessage: 'Provide indications to fill the form',
  },
  EditorClauseFieldsTypeLabel: {
    id: 'Editor.Clause.Fields.Type.Label',
    defaultMessage: 'Question type',
  },
  EditorClauseFieldsTypeScqLabel: {
    id: 'Editor.Clause.Fields.Type.Scq.Label',
    defaultMessage: 'Single choice question',
  },
  EditorClauseFieldsTypeMcqLabel: {
    id: 'Editor.Clause.Fields.Type.Mcq.Label',
    defaultMessage: 'Multiple choice questions',
  },
  EditorClauseFieldsMandatoryLabel: {
    id: 'Editor.Clause.Fields.Mandatory.Label',
    defaultMessage: 'Mandatory question',
  },
  EditorClauseFieldsRecursiveLabel: {
    id: 'Editor.Clause.Fields.Recursive.Label',
    defaultMessage: 'Recursive question',
  },
  EditorClauseFieldsFaqExpertiseLabel: {
    id: 'Editor.Clause.Fields.Faq.Expertise.Label',
    defaultMessage: 'Expertise',
  },
  EditorClauseFieldsFaqLanguageLabel: {
    id: 'Editor.Clause.Fields.Faq.Language.Label',
    defaultMessage: 'Language',
  },
  EditorClauseFieldsFaqContentLabel: {
    id: 'Editor.Clause.Fields.Faq.Content.Label',
    defaultMessage: 'Content',
  },
  EditorVariantFieldsNameLabel: {
    id: 'Editor.Variant.Fields.Name.Label',
    defaultMessage: 'Title of the answer',
  },
  EditorVariantFieldsNameRequired: {
    id: 'Editor.Variant.Fields.Name.Required',
    defaultMessage: 'Title of the answer is required',
  },
  EditorVariableFieldsNameLabel: {
    id: 'Editor.Variable.Fields.Name.Label',
    defaultMessage: 'Variable name',
  },
  EditorVariableFieldsNameRequired: {
    id: 'Editor.Variable.Fields.Name.Required',
    defaultMessage: 'Variable name is required',
  },
  EditorVariableFieldsTypeLabel: {
    id: 'Editor.Variable.Fields.Type.Label',
    defaultMessage: 'Variable type',
  },
  EditorVariableFieldsPayloadLabel: {
    id: 'Editor.Variable.Fields.Payload.Label',
    defaultMessage: 'Variable payload',
  },
  EditorVariableFieldsPayloadSelectExplanation: {
    id: 'Editor.Variable.Fields.Payload.Select.Explanation',
    defaultMessage:
      'A dropdown list provides a list from which the user can choose a value for the form. This value will then be injected into the document. You must separate each of the possible values with a comma, without spaces.',
  },
  EditorVariableFieldsPayloadRequired: {
    id: 'Editor.Variable.Fields.Payload.Required.',
    defaultMessage: 'The payload is required.',
  },
  EditorVariableFieldsPlaceholderLabel: {
    id: 'Editor.Variable.Fields.Placeholder.Label',
    defaultMessage: 'Suggestion value',
  },
  EditorVariableFieldsDefaultValueLabel: {
    id: 'Editor.Variable.Fields.DefaultValue.Label',
    defaultMessage: 'Default value',
  },
  EditorVariableFieldsRequiredLabel: {
    id: 'Editor.Variable.Fields.Required.Label',
    defaultMessage: 'Mandatory variable',
  },
  EditorVariableFieldsTypeTextLabel: {
    id: 'Editor.Variable.Fields.Type.Text.Label',
    defaultMessage: 'Text',
  },
  EditorVariableFieldsTypeMultilineLabel: {
    id: 'Editor.Variable.Fields.Type.Multiline.Label',
    defaultMessage: 'Multiline text',
  },
  EditorVariableFieldsTypeNumberLabel: {
    id: 'Editor.Variable.Fields.Type.Number.Label',
    defaultMessage: 'Number',
  },
  EditorVariableFieldsTypeDateLabel: {
    id: 'Editor.Variable.Fields.Type.Date.Label',
    defaultMessage: 'Date',
  },
  EditorVariableFieldsTypeDateSpanLabel: {
    id: 'Editor.Variable.Fields.Type.DateSpan.Label',
    defaultMessage: 'Date interval',
  },
  EditorVariableFieldsTypeTimeLabel: {
    id: 'Editor.Variable.Fields.Type.Time.Label',
    defaultMessage: 'Time',
  },
  EditorVariableFieldsTypeTimeSpanLabel: {
    id: 'Editor.Variable.Fields.Type.TimeSpan.Label',
    defaultMessage: 'Time interval',
  },
  EditorVariableFieldsTypeListLabel: {
    id: 'Editor.Variable.Fields.Type.List.Label',
    defaultMessage: 'Dropdown list',
  },
  EditorVariableFieldsTypeSelectListLabel: {
    id: 'Editor.Variable.Fields.Type.SelectList.Label',
    defaultMessage: 'Multiple choix dropdown list',
  },
  EditorVariableFieldsTypePhoneLabel: {
    id: 'Editor.Variable.Fields.Type.Phone.Label',
    defaultMessage: 'Phone',
  },
  EditorVariableFieldsTypeEmailLabel: {
    id: 'Editor.Variable.Fields.Type.Email.Label',
    defaultMessage: 'Email',
  },
  EditorVariableFieldsTypePercentLabel: {
    id: 'Editor.Variable.Fields.Type.Percent.Label',
    defaultMessage: 'Percent',
  },
  EditorVariableFieldsTypeImageLabel: {
    id: 'Editor.Variable.Fields.Type.Image.Label',
    defaultMessage: 'Image',
  },
  EditorVariableFieldsTypeCalculatedLabel: {
    id: 'Editor.Variable.Fields.Type.Calculated.Label',
    defaultMessage: 'Calculated',
  },
  EditorVariableFieldsTypeLinkedLabel: {
    id: 'Editor.Variable.Fields.Type.Linked.Label',
    defaultMessage: 'Linked',
  },
  EditorVariableFieldsPayloadListItemLabel: {
    id: 'Editor.Variable.Fields.Payload.ListItem.Label',
    defaultMessage: 'List items',
  },
  EditorVariableFieldsPayloadListItemRequired: {
    id: 'Editor.Variable.Fields.Payload.ListItem.Required',
    defaultMessage: 'List item value is required',
  },
  EditorVariableFieldsPayloadListItemFormatLabel: {
    id: 'Editor.Variable.Fields.Payload.ListItem.Format.Label',
    defaultMessage: 'Format',
  },
  EditorVariableFieldsPayloadListItemFormatUnit: {
    id: 'Editor.Variable.Fields.Payload.ListItem.Format.Unit',
    defaultMessage: 'Unit',
  },
  EditorVariableFieldsPayloadListItemFormatConjunction: {
    id: 'Editor.Variable.Fields.Payload.ListItem.Format.Conjunction',
    defaultMessage: 'Conjunction',
  },
  EditorVariableFieldsPayloadListItemFormatDisjunction: {
    id: 'Editor.Variable.Fields.Payload.ListItem.Format.Disjunction',
    defaultMessage: 'Disjunction',
  },
  EditorVariableFieldsPayloadListItemFormatExample: {
    id: 'Editor.Variable.Fields.Payload.ListItem.Format.Example',
    defaultMessage: 'Formatting example',
  },
  EditorVariableFieldsPayloadListItemStyleLabel: {
    id: 'Editor.Variable.Fields.Payload.ListItem.Style.Label',
    defaultMessage: 'Style',
  },
  EditorVariableFieldsPayloadListItemStyleShort: {
    id: 'Editor.Variable.Fields.Payload.ListItem.Style.Short',
    defaultMessage: 'Short',
  },
  EditorVariableFieldsPayloadListItemStyleNarrow: {
    id: 'Editor.Variable.Fields.Payload.ListItem.Style.Narrow',
    defaultMessage: 'Narrow',
  },
  EditorVariableFieldsPayloadListItemStyleLong: {
    id: 'Editor.Variable.Fields.Payload.ListItem.Style.Long',
    defaultMessage: 'Long',
  },
  EditorVariableFieldsPayloadDateTimeFormatLabel: {
    id: 'Editor.Variable.Fields.Payload.DateTime.Format.Label',
    defaultMessage: 'Format',
  },
  EditorVariableFieldsPayloadDateTimeFormatShort: {
    id: 'Editor.Variable.Fields.Payload.DateTime.Format.Short',
    defaultMessage: 'Short',
  },
  EditorVariableFieldsPayloadDateTimeFormatMedium: {
    id: 'Editor.Variable.Fields.Payload.DateTime.Format.Medium',
    defaultMessage: 'Medium',
  },
  EditorVariableFieldsPayloadDateTimeFormatLong: {
    id: 'Editor.Variable.Fields.Payload.DateTime.Format.Long',
    defaultMessage: 'Long',
  },
  EditorVariableFieldsPayloadDateTimeFormatFull: {
    id: 'Editor.Variable.Fields.Payload.DateTime.Format.Full',
    defaultMessage: 'Full',
  },
  EditorVariableFieldsPayloadDateTimeFormatExample: {
    id: 'Editor.Variable.Fields.Payload.DateTime.Format.Example',
    defaultMessage: 'Formatting example',
  },
  EditorVariableFieldsPayloadNumberFormatLabel: {
    id: 'Editor.Variable.Fields.Payload.Number.Format.Label',
    defaultMessage: 'Format',
  },
  EditorVariableFieldsPayloadNumberFormatDecimal: {
    id: 'Editor.Variable.Fields.Payload.Number.Format.Decimal',
    defaultMessage: 'Decimal',
  },
  EditorVariableFieldsPayloadNumberFormatPercent: {
    id: 'Editor.Variable.Fields.Payload.Number.Format.Percent',
    defaultMessage: 'Percent',
  },
  EditorVariableFieldsPayloadNumberFormatCurrency: {
    id: 'Editor.Variable.Fields.Payload.Number.Format.Currency',
    defaultMessage: 'Currency',
  },
  EditorVariableFieldsPayloadNumberCurrencyLabel: {
    id: 'Editor.Variable.Fields.Payload.Number.Currency.Label',
    defaultMessage: 'Currency',
  },
  EditorVariableFieldsPayloadNumberStyleLabel: {
    id: 'Editor.Variable.Fields.Payload.Number.Style.Label',
    defaultMessage: 'Style',
  },
  EditorVariableFieldsPayloadNumberStyleDecimal: {
    id: 'Editor.Variable.Fields.Payload.Number.Style.Symbol',
    defaultMessage: 'Symbol',
  },
  EditorVariableFieldsPayloadNumberStylePercent: {
    id: 'Editor.Variable.Fields.Payload.Number.Style.Code',
    defaultMessage: 'ISO Code',
  },
  EditorVariableFieldsPayloadNumberStyleCurrency: {
    id: 'Editor.Variable.Fields.Payload.Number.Style.Name',
    defaultMessage: 'Currency name',
  },
  EditorVariableFieldsPayloadNumberMaximumFractionDigitsLabel: {
    id: 'Editor.Variable.Fields.Payload.Number.MaximumFractionDigits.Label',
    defaultMessage: 'Maximum fraction digits',
  },
  EditorVariableFieldsPayloadNumberMaximumFractionDigitsRequired: {
    id: 'Editor.Variable.Fields.Payload.Number.MaximumFractionDigits.Required',
    defaultMessage: 'Maximum fraction digits is required',
  },
  EditorVariableFieldsPayloadNumberFormatExample: {
    id: 'Editor.Variable.Fields.Payload.Number.Format.Example',
    defaultMessage: 'Formatting example',
  },
  EditorConditionManageLabel: {
    id: 'Editor.Condition.Manage.Label',
    defaultMessage: 'Manage conditions',
  },
  EditorNegotiationBotMessagesClauseValidated: {
    id: 'Editor.Negotiation.Bot.Messages.Clause.Validated',
    defaultMessage: 'The clause has been validated.',
  },
  EditorNegotiationBotMessagesClauseInvalidated: {
    id: 'Editor.Negotiation.Bot.Messages.Clause.Invalidated',
    defaultMessage: 'The clause has been invalidated.',
  },
  EditorNegotiationBotMessagesClauseModified: {
    id: 'Editor.Negotiation.Bot.Messages.Clause.Modified',
    defaultMessage: 'The clause has been modified.',
  },
  EditorNegotiationSeeMessages: {
    id: 'Editor.Negotiation.See.Messages',
    defaultMessage: 'See the messages',
  },
  EditorNegotiationFAQDelete: {
    id: 'Editor.Negotiation.FAQ.Delete',
    defaultMessage: 'Delete FAQ',
  },
  EditorNegotiationEditClause: {
    id: 'Editor.Negotiation.Edit.Clause',
    defaultMessage: 'Edit the clause',
  },
  EditorSignatureAddSlotLabel: {
    id: 'Editor.Signature.AddSlot.Label',
    defaultMessage: 'Insert signature slot',
  },
  EditorSignatureOrientationLabel: {
    id: 'Editor.Signature.Orientation.Label',
    defaultMessage: 'Signatures orientation',
  },
  EditorSignatureTypeHorizontal: {
    id: 'Editor.Signature.Type.Horizontal',
    defaultMessage: 'Horizontal',
  },
  EditorSignatureTypeVertical: {
    id: 'Editor.Signature.Type.Vertical',
    defaultMessage: 'Vertical',
  },
  EditorSignatureTypeSignature: {
    id: 'Editor.Signature.Type.Signature',
    defaultMessage: 'Signature',
  },
  EditorSignatureTypeInitials: {
    id: 'Editor.Signature.Type.Initials',
    defaultMessage: 'Initials',
  },
  EditorSignatureTypeFirstName: {
    id: 'Editor.Signature.Type.FirstName',
    defaultMessage: 'First name',
  },
  EditorSignatureTypeLastName: {
    id: 'Editor.Signature.Type.LastName',
    defaultMessage: 'Last name',
  },
  EditorSignatureTypeDate: {
    id: 'Editor.Signature.Type.Date',
    defaultMessage: 'Signature date',
  },
  EditorSignatureTypeFreeText: {
    id: 'Editor.Signature.Type.FreeText',
    defaultMessage: 'Free text field',
  },
  EditorSignatureTypeCheckbox: {
    id: 'Editor.Signature.Type.Checkbox',
    defaultMessage: 'Checkbox',
  },
  EditorSignatureButtonAddSignature: {
    id: 'Editor.Signature.Button.Add.Signature',
    defaultMessage: 'Add signature',
  },
  EditorSignatureButtonAddInitials: {
    id: 'Editor.Signature.Button.Add.Initials',
    defaultMessage: 'Add initials',
  },
  EditorSignatureButtonAddFirstName: {
    id: 'Editor.Signature.Button.Add.FirstName',
    defaultMessage: 'Add first name',
  },
  EditorSignatureButtonAddLastName: {
    id: 'Editor.Signature.Button.Add.LastName',
    defaultMessage: 'Add last name',
  },
  EditorSignatureButtonAddDate: {
    id: 'Editor.Signature.Button.Add.Date',
    defaultMessage: 'Add signature date',
  },
  EditorSignatureButtonAddFreeText: {
    id: 'Editor.Signature.Button.Add.FreeText',
    defaultMessage: 'Add free text field',
  },
  EditorSignatureButtonAddCheckbox: {
    id: 'Editor.Signature.Button.Add.Checkbox',
    defaultMessage: 'Add checkbox',
  },
  EditorSignatureButtonRemoveSlot: {
    id: 'Editor.Signature.Button.Remove.Slot',
    defaultMessage: 'Remove signature slot',
  },
  EditorSignatureOrientationDescription: {
    id: 'Editor.Signature.Orientation.Description',
    defaultMessage: 'Defines how the multiple signatures will be displayed.',
  },
  EditorSignatureSettingsLabel: {
    id: 'Editor.Signature.Settings.Label',
    defaultMessage: 'Signature slot settings',
  },
  EditorSignatureRoleTargetLabel: {
    id: 'Editor.Signature.Role.Target.Label',
    defaultMessage: 'Signature role owner',
  },
  EditorSignatureRoleTargetDescription: {
    id: 'Editor.Signature.Role.Target.Description',
    defaultMessage:
      'Defines the users of a group that will be added for the signature.',
  },
  EditorSignatureSlotLabel: {
    id: 'Editor.Signature.Slot.Label',
    defaultMessage: 'Signature slot',
  },
  EditorSignatureSlotSharedContentLabel: {
    id: 'Editor.Signature.Slot.Shared.Content.Label',
    defaultMessage: 'Shared content',
  },
  EditorSignatureSlotSharedContentExplanation: {
    id: 'Editor.Signature.Slot.Shared.Content.Explanation',
    defaultMessage:
      'By enabling this checkbox, the signature content will not be duplicated regardless of the number of signers in the role. This option can only be enabled if the content only contains text and checkboxes.',
  },
  EditorSignatureSlotElementOnSharedContentUnavailableMessage: {
    id: 'Editor.Signature.Slot.Element.On.Shared.Content.Unavailable.Message',
    defaultMessage: 'This element is unavailable in a shared content',
  },
  EditorSignatureCheckboxLabel: {
    id: 'Editor.Signature.Checkbox.Label',
    defaultMessage: 'Signature checkbox',
  },
  EditorSignatureCheckboxLinked: {
    id: 'Editor.Signature.Checkbox.Linked',
    defaultMessage: 'Checkbox linked',
  },
  EditorSignatureCheckboxGroupName: {
    id: 'Editor.Signature.Checkbox.Group.Name',
    defaultMessage: 'Group name',
  },
  EditorSignatureCheckboxMinRequired: {
    id: 'Editor.Signature.Checkbox.Min.Required',
    defaultMessage: 'Minimum checked required',
  },
  EditorSignatureCheckboxMaxAllowed: {
    id: 'Editor.Signature.Checkbox.Max.Allowed',
    defaultMessage: 'Maximum checked allowed',
  },
  EditorSignatureFreeTextLabel: {
    id: 'Editor.Signature.FreeText.Label',
    defaultMessage: 'Free text element',
  },
  EditorSignatureFreeTextRowsLabel: {
    id: 'Editor.Signature.FreeText.Rows.Label',
    defaultMessage: 'Number of text rows',
  },
  EditorSignatureFreeTextTooltipTextLabel: {
    id: 'Editor.Signature.FreeText.TooltipText.Label',
    defaultMessage: 'Tooltip text',
  },
  EditorTrackChangesDeletionLabel: {
    id: 'Editor.TrackChanges.Deletion.Label',
    defaultMessage: 'Deletion',
  },
  EditorTrackChangesInsertionLabel: {
    id: 'Editor.TrackChanges.Insertion.Label',
    defaultMessage: 'Insertion',
  },
  EditorTrackChangesModificationLabel: {
    id: 'Editor.TrackChanges.Modification.Label',
    defaultMessage: 'Modification',
  },
  EditorTrackChangesAnnotationLabel: {
    id: 'Editor.TrackChanges.Annotation.Label',
    defaultMessage: 'Comments',
  },
  EditorTrackChangesInsertedLabel: {
    id: 'Editor.TrackChanges.Inserted.Label',
    defaultMessage: 'added',
  },
  EditorTrackChangesDeletedLabel: {
    id: 'Editor.TrackChanges.Deleted.Label',
    defaultMessage: 'deleted',
  },
  EditorTrackChangesReplacedLabel: {
    id: 'Editor.TrackChanges.Replaced.Label',
    defaultMessage: 'replaced',
  },
  EditorTrackChangesReplacedWithLabel: {
    id: 'Editor.TrackChanges.ReplacedWith.Label',
    defaultMessage: 'with',
  },
  EditorCommentMessageLabel: {
    id: 'Editor.Comment.Message.Label',
    defaultMessage: 'Message',
  },
  EditorCommentMessagePlaceholder: {
    id: 'Editor.Comment.Message.Placeholder',
    defaultMessage: 'Enter your message...',
  },
  EditorCommentMessageDeleted: {
    id: 'Editor.Comment.Message.Deleted',
    defaultMessage: 'This message has been deleted',
  },
  EditorPageBreakLabel: {
    id: 'Editor.Page.Break.Label',
    defaultMessage: 'Page break',
  },
  EditorPageBreakInsert: {
    id: 'Editor.Page.Break.Insert',
    defaultMessage: 'Insert a page break',
  },
  EditorSectionSameLabel: {
    id: 'Editor.Section.Same.Label',
    defaultMessage:
      'Same header and footer on all pages for the current section',
  },
  EditorSectionPageFirstDifferentLabel: {
    id: 'Editor.Section.Page.First.Different.Label',
    defaultMessage: 'First page different',
  },
  EditorSectionPageEvenOddDifferentLabel: {
    id: 'Editor.Section.Page.Even.Odd.Different.Label',
    defaultMessage: 'Even and odd pages differents',
  },
  EditorSectionPageFirstEvenOddDifferentLabel: {
    id: 'Editor.Section.Page.First.Even.Odd.Different.Label',
    defaultMessage: 'First page, even and odd pages differents',
  },
  EditorSectionPageFirstLabel: {
    id: 'Editor.Section.Page.First.Label',
    defaultMessage: 'First page',
  },
  EditorSectionPageEvenLabel: {
    id: 'Editor.Section.Page.Even.Label',
    defaultMessage: 'Even pages',
  },
  EditorSectionPageOddLabel: {
    id: 'Editor.Section.Page.Odd.Label',
    defaultMessage: 'Odd pages',
  },
  EditorSectionPageOtherLabel: {
    id: 'Editor.Section.Page.Other.Label',
    defaultMessage: 'Other pages',
  },
  EditorSectionPageHeadersOptionsLabel: {
    id: 'Editor.Section.Page.Headers.Options.Label',
    defaultMessage: 'Headers footers options',
  },
  EditorSectionPageNumberToggleLabel: {
    id: 'Editor.Section.Page.Number.Toggle.Label',
    defaultMessage: 'Toggle page number',
  },
  EditorSectionPageNumberLabel: {
    id: 'Editor.Section.Page.Number.Label',
    defaultMessage: 'Page number',
  },
  EditorSectionPageCloseLabel: {
    id: 'Editor.Section.Page.Close.Label',
    defaultMessage: 'Close edit menu',
  },
  EditorSectionOptionsLabel: {
    id: 'Editor.Section.Options.Label',
    defaultMessage: 'Options',
  },
  EditorTrackChangesAcceptedAddNoteBookModalTitle: {
    id: 'Editor.Track.Changes.Accepted.Add.NoteBook.Modal.Title',
    defaultMessage:
      "Would you like to add a note to this supplier's notebook (optional)",
  },
  EditorTrackChangesAcceptedBtnCancelModification: {
    id: 'Editor.Track.Changes.Accepted.Btn.Cancel.Modification',
    defaultMessage: 'Cancel the validation of this modification',
  },
  EditorTrackChangesAcceptedBtnSaveWithoutNodes: {
    id: 'Editor.Track.Changes.Accepted.Btn.Save.Without.Notes',
    defaultMessage: 'Save without adding notes',
  },
  EditorTrackChangesAcceptedBtnSaveAddNodes: {
    id: 'Editor.Track.Changes.Accepted.Btn.Save.Add.Notes',
    defaultMessage: 'Add note',
  },
  EditorNotebookSaveSuccess: {
    id: 'Editor.Notebook.Save.Success',
    defaultMessage: 'The notebook has been saved successfully',
  },
  EditorNotebookSavedFailure: {
    id: 'Editor.Save.Failure',
    defaultMessage: 'The save has failed',
  },
  EditorNumberingTypeLabel: {
    id: 'Editor.Numbering.Type.Label',
    defaultMessage: 'Numbering type:',
  },
  EditorNumberingTypeArticleExample: {
    id: 'Editor.Numbering.Type.Article.Example',
    defaultMessage: 'Article 1',
  },
  EditorNumberingTypeArticle: {
    id: 'Editor.Numbering.Type.Article',
    defaultMessage: 'Article',
  },
  EditorNumberingTypeNone: {
    id: 'Editor.Numbering.Type.None',
    defaultMessage: 'None',
  },
  EditorNumberingTypeNumerical: {
    id: 'Editor.Numbering.Type.Numerical',
    defaultMessage: 'Numerical (1)',
  },
  EditorNumberingTypeAlphaLower: {
    id: 'Editor.Numbering.Type.Alpha.Lower',
    defaultMessage: 'Lower alpha (a)',
  },
  EditorNumberingTypeAlphaUpper: {
    id: 'Editor.Numbering.Type.Alpha.Upper',
    defaultMessage: 'Upper alpha (A)',
  },
  EditorNumberingTypeRomanLower: {
    id: 'Editor.Numbering.Type.Roman.Lower',
    defaultMessage: 'Lower roman (i)',
  },
  EditorNumberingTypeRomanUpper: {
    id: 'Editor.Numbering.Type.Roman.Upper',
    defaultMessage: 'Upper roman (I)',
  },
  EditorNumberingSuffixListLabel: {
    id: 'Editor.Numbering.Suffix.List.Label',
    defaultMessage: 'List suffix:',
  },
  EditorNumberingSuffixLabel: {
    id: 'Editor.Numbering.Suffix.Label',
    defaultMessage: 'Suffix:',
  },
  EditorNumberingPrefixLabel: {
    id: 'Editor.Numbering.Prefix.Label',
    defaultMessage: 'Prefix:',
  },
  EditorNumberingPrefixSuffixSetLabel: {
    id: 'Editor.Numbering.Prefix.Suffix.Set.Label',
    defaultMessage: 'Set prefix and suffix',
  },
  EditorNumberingSetValue: {
    id: 'Editor.Numbering.Set.Value',
    defaultMessage: 'Set numbering value',
  },
  EditorNumberingSetValueTo: {
    id: 'Editor.Numbering.Set.Value.To',
    defaultMessage: 'Set value to:',
  },
  EditorNumberingListNew: {
    id: 'Editor.Numbering.List.New',
    defaultMessage: 'Start new list',
  },
  EditorNumberingListContinue: {
    id: 'Editor.Numbering.List.Continue',
    defaultMessage: 'Continue from previous list',
  },
  EditorNumberingMultilevelEnable: {
    id: 'Editor.Numbering.Multilevel.Enable',
    defaultMessage: 'Enable multilevel list',
  },
  EditorNumberingMultilevelDisable: {
    id: 'Editor.Numbering.Multilevel.Disable',
    defaultMessage: 'Disable multilevel list',
  },
  EditorTemplateAffectedInfoText: {
    id: 'Editor.Template.AffectedInfo.Text',
    defaultMessage:
      "Changes made to this model will apply to all versions of the model assigned to a BU. You can manage the model's assignments to BUs in 'Edit' then 'Manage assignment'.",
  },
});
