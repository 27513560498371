import React from 'react';
import { useFormikContext } from 'formik';

const FormikResetForm = ({ children, values }) => {
  const { resetForm } = useFormikContext();
  React.useEffect(() => {
    if (values) {
      resetForm({ values, touched: {}, errors: {} });
    }
  }, [values, resetForm]);
  return children;
};

export default FormikResetForm;
