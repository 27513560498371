import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Close } from '@material-ui/icons/';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import FormikRadioGroup from '@components/Formik/FormikRadioGroup';
import FormikTextField from '@components/Formik/FormikTextField';
import FormikProvider from '@components/Formik/FormikProvider';
import * as Yup from 'yup';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: '8px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export function SetNumberingForm({ onClose, disabled, open }) {
  const intl = useIntl();
  const optionsChoice = [
    {
      value: false,
      label: intl.formatMessage({
        id: 'Editor.Numbering.List.New',
      }),
      children: null,
    },
    {
      value: true,
      label: intl.formatMessage({
        id: 'Editor.Numbering.List.Continue',
      }),
      children: null,
    },
  ];
  return (
    <Fade in={open}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography sx={{ fontWeight: 700 }}>
            <FormattedMessage id="Editor.Numbering.Set.Value" />
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <FormikRadioGroup
          required
          as="field"
          name="continueNumbering"
          items={optionsChoice}
        />
        <Box sx={{ mt: 2 }}>
          <FormattedMessage id="Editor.Numbering.Set.Value.To" />
          <FormikTextField
            as="fastfield"
            required
            name="continueNumberingValue"
            type="number"
          />
        </Box>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
          sx={{ mt: 3 }}
        >
          <Button onClick={onClose} variant="outlined">
            <FormattedMessage id="Verbs.Cancel" />
          </Button>
          <Button type="submit" variant="contained" disabled={disabled}>
            <FormattedMessage id="Verbs.Done" />
          </Button>
        </Stack>
      </Box>
    </Fade>
  );
}

const schema = Yup.object().shape({
  continueNumbering: Yup.boolean().required(),
  continueNumberingValue: Yup.number().nullable(),
});

export default function SetNumberingValueModal({
  onClose,
  onSubmit,
  open,
  disabled,
  initialValues,
  ModalProps = {},
  NameFieldProps = {},
  children,
  ...rest
}) {
  return (
    <Modal open={open} onClose={onClose} {...ModalProps}>
      <FormikProvider
        schema={schema}
        initialValues={initialValues}
        validateOnBlur={false}
        onSubmit={onSubmit}
        {...rest}
      >
        <SetNumberingForm
          open={open}
          onClose={onClose}
          disabled={disabled}
          {...NameFieldProps}
        >
          {children}
        </SetNumberingForm>
      </FormikProvider>
    </Modal>
  );
}
