import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportIcon from '@mui/icons-material/Report';

import FormikTextField from '@components/Formik/FormikTextField';
import AddButton from '@components/Buttons/AddButton';

import * as yup from 'yup';
import {
  requiredIf,
  requiredOrStripWhen,
  stripWhenNot,
} from '@utils/yup-required-when';
import { fields as operationFields, fundingMethods } from './OperationForm';

const loanFields = {
  nature: 'nature',
  amount: 'amount',
  duration: 'duration',
  type: 'type',
  rate: 'rate',
  monthlyPayments: 'monthlyPayments',
};

export const fields = {
  loans: 'loans',
  amountTotal: 'amountTotal',
  monthlyPaymentsTotal: 'monthlyPaymentsTotal',
};

const getSchemaLoan = required =>
  yup
    .object()
    .shape({
      id: yup.string().default(uuidv4),
      [loanFields.nature]: requiredIf(yup.string().default(''), required),
      [loanFields.amount]: yup.lazy(values => {
        if (values != null && values !== undefined && values !== '') {
          return yup.number().min(0, 'Generic.Fields.MustBePositive');
        }
        return requiredIf(yup.string().default(''), required);
      }),
      [loanFields.duration]: yup.lazy(values => {
        if (values != null && values !== undefined && values !== '') {
          return yup.number().min(0, 'Generic.Fields.MustBePositive');
        }
        return requiredIf(yup.string().default(''), required);
      }),
      [loanFields.type]: requiredIf(yup.string().default(''), required),
      [loanFields.rate]: yup.lazy(values => {
        if (values != null && values !== undefined && values !== '') {
          return yup.number().min(0, 'Generic.Fields.MustBePositive').max(100);
        }
        return requiredIf(yup.string().default(''), required);
      }),
      [loanFields.monthlyPayments]: yup.lazy(values => {
        if (values != null && values !== undefined && values !== '') {
          return yup.number().min(0, 'Generic.Fields.MustBePositive');
        }
        return requiredIf(yup.string().default(''), required);
      }),
    })
    .default({});

export const getSchemaLoansForm = required => {
  const innerSchema = yup.array(getSchemaLoan(required)).default([]);
  return yup
    .object()
    .shape({
      [fields.loans]: (required ? requiredOrStripWhen : stripWhenNot)(
        !required
          ? innerSchema
          : innerSchema.min(
              1,
              'Au moins une ligne de prêt doit être décrite quand le mode de financement est à crédit'
            ),
        operationFields.fundingMethod,
        fundingMethods.credit
      ),
      [fields.amountTotal]: yup.number().default(0),
      [fields.monthlyPaymentsTotal]: yup.number().default(0),
    })
    .default({});
};

const Loan = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  borderRadius: 4,
  padding: theme.spacing(2, 1, 2, 2),
}));

function HelperText({ children }) {
  return (
    <Stack direction="row" mt={1}>
      <ReportIcon color="primary" sx={{ fontSize: 16 }} />
      <Typography variant="caption" display="block" sx={{ fontSize: 12 }}>
        {children}
      </Typography>
    </Stack>
  );
}

function Totals() {
  const {
    values: {
      [fields.loans]: loans,
      [fields.amountTotal]: oldAmountTotal,
      [fields.monthlyPaymentsTotal]: oldMonthlyPaymentsTotal,
    },
    setFieldValue,
  } = useFormikContext();

  React.useEffect(() => {
    const totals = loans.reduce(
      (sums, loan) => ({
        amount: sums.amount + Number(loan[loanFields.amount]) || 0,
        monthlyPayments:
          sums.monthlyPayments + Number(loan[loanFields.monthlyPayments]) || 0,
      }),
      { amount: 0, monthlyPayments: 0 }
    );
    if (totals.amount !== oldAmountTotal) {
      setFieldValue(fields.amountTotal, totals.amount);
    }
    if (totals.monthlyPayments !== oldMonthlyPaymentsTotal) {
      setFieldValue(fields.monthlyPaymentsTotal, totals.monthlyPayments);
    }
  }, [loans, oldAmountTotal, oldMonthlyPaymentsTotal, setFieldValue]);

  return (
    <Grid container columnSpacing={2} my={2}>
      <Grid item xs={6}>
        <FormikTextField
          as="fastfield"
          margin="dense"
          label="Montant total"
          disabled
          name={fields.amountTotal}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <InputAdornment position="end">&euro;</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField
          as="fastfield"
          margin="dense"
          label="Mensualités totales"
          disabled
          name={fields.monthlyPaymentsTotal}
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <InputAdornment position="end">&euro;</InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}

function Loans({ remove, readOnly, required }) {
  const {
    values: { [fields.loans]: loans = [] },
  } = useFormikContext();

  return (
    <>
      {loans.map((loan, index) => (
        <Loan key={loan.id}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={6} md={4} lg={2}>
              <FormikTextField
                as="fastfield"
                margin="dense"
                label="Nature du prêt"
                disabled={readOnly}
                required={required}
                name={`loans.${index}.${loanFields.nature}`}
              />
              <HelperText>
                Bancaire, 0%, 1%, PAS, PEL, Fonctionnaire, PLS…
              </HelperText>
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <FormikTextField
                as="fastfield"
                margin="dense"
                label="Montant"
                disabled={readOnly}
                name={`loans.${index}.${loanFields.amount}`}
                type="number"
                required={required}
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: (
                    <InputAdornment position="end">&euro;</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <FormikTextField
                as="fastfield"
                margin="dense"
                label="Durée (en mois)"
                disabled={readOnly}
                required={required}
                name={`loans.${index}.${loanFields.duration}`}
                type="number"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <FormikTextField
                as="fastfield"
                margin="dense"
                label="Type"
                disabled={readOnly}
                required={required}
                name={`loans.${index}.${loanFields.type}`}
              />
              <HelperText>Fixe, révisable, capé, in fine, …</HelperText>
            </Grid>
            <Grid item xs={5} md={3} lg>
              <FormikTextField
                as="fastfield"
                margin="dense"
                label="Taux"
                disabled={readOnly}
                required={required}
                name={`loans.${index}.${loanFields.rate}`}
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <FormikTextField
                as="fastfield"
                margin="dense"
                label="Mensualités"
                disabled={readOnly}
                required={required}
                name={`loans.${index}.${loanFields.monthlyPayments}`}
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: (
                    <InputAdornment position="end">&euro;</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} md={1} lg="auto" pt={2}>
              <IconButton onClick={() => remove(index)}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Loan>
      ))}
      {Boolean(loans.length) && <Totals />}
    </>
  );
}

const requiredSchemaLoan = getSchemaLoan(true);

function LoansForm({ readOnly, required }) {
  const {
    errors: { loans },
  } = useFormikContext();
  return (
    <FieldArray
      name={fields.loans}
      render={({ push, remove }) => (
        <Stack direction="column" gap={1} pt={1}>
          <Stack direction="row">
            <Typography variant="h5" gutterBottom>
              Prêt(s) à solliciter
            </Typography>
            <AddButton
              label="Ajouter une ligne de prêt"
              sx={{ ml: 'auto' }}
              onClick={() => push(requiredSchemaLoan.cast())}
              disabled={readOnly}
            />
          </Stack>
          {loans && !Array.isArray(loans) && (
            <Typography color="error">{loans}</Typography>
          )}
          <Loans remove={remove} readOnly={readOnly} required={required} />
        </Stack>
      )}
    />
  );
}

export default LoansForm;
