import React from 'react';
import { BubbleMenu } from '@tiptap/react';
import ButtonGroup from '@mui/material/ButtonGroup';

import { useTiptapEditor } from '../use-tiptap-editor';
import { containsNode as selectionContains, isEmpty } from '../utils/selection';
import { containsNode as documentContains } from '../utils/document';

import ButtonVariable from './buttons/button-variable';
import ButtonVariableLinked from './buttons/button-variable-linked';

function BubbleVariable() {
  const editor = useTiptapEditor();
  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{ duration: 100 }}
      shouldShow={({ editor, state: { doc, selection } }) => {
        const {
          schema: { nodes },
        } = editor;
        if (isEmpty(selection)) return false;
        if (!editor.isActive(nodes.clausevariant.name)) return false;
        if (!editor.isActive(nodes.clause.name)) return false;
        if (!documentContains(doc, nodes.variable)) return false;
        if (selectionContains(doc, selection, nodes.variable)) return false;
        return true;
      }}
    >
      <ButtonGroup
        variant="text"
        sx={{
          bgcolor: 'common.white',
          borderStyle: 'solid',
          borderColor: 'primary.light',
        }}
      >
        <ButtonVariable />
        <ButtonVariableLinked />
      </ButtonGroup>
    </BubbleMenu>
  );
}

export default BubbleVariable;
