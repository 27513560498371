import React, { Component, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import merge from 'lodash.merge';
import {
  Query,
  Builder,
  Utils as QbUtils,
  BasicConfig,
} from 'react-awesome-query-builder';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import { Route, Link } from 'react-router-dom';
import { AddAccountModalWithPermissions } from '@views/Administration/UserAccounts/components/AddAccountModal';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import ScmMuiDateWidget from '@components/Pickers/ScmMuiDateWidget';
import FormikSelect from '@components/Formik/FormikSelect';
import FormikContributorPicker from '@components/Formik/FormikContributorPicker';
import { CustomConfirmDialog } from '@editor/dialogs/CustomConfirmDialog';
import useFetch from '@hooks/useFetch';
import { roleTypeEnum, workflowContributorTypes } from '@views/constants';
import messages from '@i18n/keys';
import tenantPermissions from '@utils/tenant-permissions';
import useTenantPermissions from '@hooks/useTenantPermissions';
import WorkflowContributorDeleteDialog from './WorkflowContributorDeleteDialog';
import { useWorkflowContext } from '../WorkflowContextProvider';

export class ContributorConditionEditor extends Component {
  constructor(props) {
    super(props);
    const { fields } = this.props;
    const config = {
      ...props.editorCondtionsConfig,
      fields,
    };
    this.state = {
      tree: QbUtils.checkTree(
        QbUtils.loadFromJsonLogic(props.conditionState.value ?? null, config),
        config
      ),
      config,
    };
  }

  static renderBuilder = props => (
    <div className="query-builder-container" style={{ padding: '10px' }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  onChange = (immutableTree, config) => {
    const { conditionState } = this.props;
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    this.setState({ tree: immutableTree, config });
    const jsonTree = QbUtils.jsonLogicFormat(immutableTree, config);

    if (jsonTree.logic) {
      conditionState.update(jsonTree.logic);
    } else {
      conditionState.update(null);
    }
  };

  render() {
    const { tree, config } = this.state;
    return (
      <div>
        <Query
          {...config}
          value={tree}
          onChange={this.onChange}
          renderBuilder={ContributorConditionEditor.renderBuilder}
        />
      </div>
    );
  }
}

export const DeleteWorkflowContributorButton = React.forwardRef(
  ({ onClick, disabled }, ref) => (
    <IconButton ref={ref} disabled={disabled} onClick={onClick}>
      <DeleteOutlineIcon color={disabled ? 'disabled' : 'error'} />
    </IconButton>
  )
);

export const contributorCreatePermission =
  tenantPermissions.ContractsContributorsCreate;

const useConditionFields = variablesData =>
  useMemo(() => {
    const fields = {};
    if (!variablesData?.variables) return fields;

    variablesData?.variables?.forEach(variable => {
      if (!variable?.attrs) return;
      let { type } = variable.attrs;
      if (type === 'calculated') {
        type = 'number';
      } else if (type === 'linked') {
        return;
      }

      fields[variable.attrs.uid] = {
        label: variable.attrs.name,
        type,
      };
    });

    return fields;
  }, [variablesData]);

const factory = props => <ScmMuiDateWidget {...props} />;

function WorkflowContributorEditor({
  name = undefined,
  index,
  contributor: contributorProp = null,
  showSignature = false,
  readOnly = false,
  onRemove = () => {},
  variablesData,
  hideNewUser = false,
  hideAccess = false,
  hideCondition = false,
  hideRemove = false,
  usersOnly = false,
  schema = false,
  hideEmptyUser = false,
  allowGroupsToHaveUsers = null,
  rolesAndGroups = null,
  externalRole = null,
  navigateWorkflow = null,
  contributors = null,
  replace = null,
}) {
  const intl = useIntl();
  const [openPopup, setOpenPopup] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const valuePath = `${name}.${index}.condition`;
  const { values, setFieldValue } = useFormikContext();
  const { contractId, excludeRoleIds } = useWorkflowContext();
  const setConditionValue = condition => {
    setFieldValue(valuePath, JSON.stringify(condition));
  };

  const hasCreatePermission = useTenantPermissions({
    permissions: contributorCreatePermission,
  });

  const fields = useConditionFields(variablesData);

  const contributor = contributorProp ?? values?.[name]?.[index];
  const conditionValue = contributor?.condition
    ? JSON.parse(contributor.condition)
    : null;

  const InitialConfig = { ...BasicConfig };

  const types = {
    ...InitialConfig.types,
    // examples of overriding
    boolean: merge(InitialConfig.types.boolean, {
      widgets: {
        boolean: {
          widgetProps: {
            hideOperator: true,
            // operatorInlineLabel: "is",
          },
        },
      },
    }),
    text: merge(InitialConfig.types.text, {
      operators: [
        'equal',
        'not_equal',
        'like',
        'not_like',
        'is_empty',
        'is_not_empty',
        'is_null',
        'is_not_null',
      ],
    }),
  };

  const widgets = {
    ...InitialConfig.widgets,
    // examples of  overriding
    slider: {
      ...InitialConfig.widgets.slider,
      customProps: {
        width: '300px',
      },
    },
    rangeslider: {
      ...InitialConfig.widgets.rangeslider,
      customProps: {
        width: '300px',
      },
    },
    text: {
      ...InitialConfig.widgets.text,
      valuePlaceholder: intl.formatMessage({
        id: 'Workflow.Condtion.Value.Placeholder.Label',
      }),
    },
    date: {
      ...InitialConfig.widgets.date,
      dateFormat: 'DD.MM.YYYY',
      valueFormat: 'YYYY-MM-DD',
      jsType: 'number',
      factory,
      jsonLogic: {
        function(val) {
          const localDate = new Date(val);
          // return utc epoch date
          return Date.UTC(
            localDate.getFullYear(),
            localDate.getMonth(),
            localDate.getDate()
          );
        },
      },
    },
    time: {
      ...InitialConfig.widgets.time,
      timeFormat: 'HH:mm',
      valueFormat: 'HH:mm:ss',
    },
    datetime: {
      ...InitialConfig.widgets.datetime,
      timeFormat: 'HH:mm',
      dateFormat: 'DD.MM.YYYY',
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    treeselect: {
      ...InitialConfig.widgets.treeselect,
      customProps: {
        showSearch: true,
      },
    },
  };

  const localeSettings = {
    valueSourcesInfo: {
      value: {
        label: intl.formatMessage({ id: 'Workflow.Condtion.Type.Value.Label' }),
      },
      field: {
        label: intl.formatMessage({ id: 'Workflow.Condtion.Type.Field.Label' }),
      },
    },
    valueLabel: intl.formatMessage({
      id: 'Workflow.Condtion.Type.Value.Label',
    }),
    valuePlaceholder: intl.formatMessage({
      id: 'Workflow.Condtion.Type.Value.Label',
    }),
    fieldLabel: intl.formatMessage({
      id: 'Workflow.Condtion.Type.Field.Label',
    }),
    operatorLabel: intl.formatMessage({
      id: 'Workflow.Condtion.Operator.Operator.Label',
    }),
    fieldPlaceholder: intl.formatMessage({
      id: 'Workflow.Condtion.Type.SelectField.Label',
    }),
    operatorPlaceholder: intl.formatMessage({
      id: 'Workflow.Condtion.Type.SelectOperator.Label',
    }),
    deleteLabel: null,
    addGroupLabel: intl.formatMessage({
      id: 'Workflow.Condtion.Type.AddGroup.Label',
    }),
    addRuleLabel: intl.formatMessage({
      id: 'Workflow.Condtion.Type.AddRule.Label',
    }),
    addSubRuleLabel: intl.formatMessage({
      id: 'Workflow.Condtion.Type.AddSubRule.Label',
    }),
    delGroupLabel: null,
    notLabel: intl.formatMessage({
      id: 'Workflow.Condtion.Conjunction.Not.Label',
    }),
    valueSourcesPopupTitle: 'Select value source',
    removeRuleConfirmOptions: {
      title: intl.formatMessage({
        id: 'Workflow.Condtion.Remove.Rule.ConfirmMessage.Label',
      }),
      okText: intl.formatMessage({
        id: 'Workflow.Condtion.Remove.Ok.Label',
      }),
      okType: 'danger',
    },
    removeGroupConfirmOptions: {
      title: intl.formatMessage({
        id: 'Workflow.Condtion.Remove.Group.ConfirmMessage.Label',
      }),
      okText: intl.formatMessage({
        id: 'Workflow.Condtion.Remove.Ok.Label',
      }),
      okType: 'danger',
    },
  };

  const conjunctionsConfig = {
    AND: {
      label: intl.formatMessage({
        id: 'Workflow.Condtion.Conjunction.AND.Label',
      }),
    },
    OR: {
      label: intl.formatMessage({
        id: 'Workflow.Condtion.Conjunction.OR.Label',
      }),
    },
  };

  const operatorsConfig = {
    equal: {
      label: intl.formatMessage({
        id: 'Workflow.Condtion.Operator.Equal.Label',
      }),
    },
    not_equal: {
      label: intl.formatMessage({
        id: 'Workflow.Condtion.Operator.NotEqual.Label',
      }),
    },
    like: {
      label: intl.formatMessage({
        id: 'Workflow.Condtion.Operator.Like.Label',
      }),
    },
    not_like: {
      label: intl.formatMessage({
        id: 'Workflow.Condtion.Operator.NotLike.Label',
      }),
    },
    is_null: {
      label: intl.formatMessage({
        id: 'Workflow.Condtion.Operator.IsNull.Label',
      }),
    },
    is_not_null: {
      label: intl.formatMessage({
        id: 'Workflow.Condtion.Operator.IsNotNull.Label',
      }),
    },
    is_empty: {
      label: intl.formatMessage({
        id: 'Workflow.Condtion.Operator.IsEmpty.Label',
      }),
    },
    is_not_empty: {
      label: intl.formatMessage({
        id: 'Workflow.Condtion.Operator.IsNotEmpty.Label',
      }),
    },
  };

  const operators = {
    ...InitialConfig.operators,
    ...operatorsConfig,
  };

  const conjunctions = {
    ...InitialConfig.conjunctions,
    ...conjunctionsConfig,
  };

  const settings = {
    ...InitialConfig.settings,
    ...localeSettings,
    maxNesting: 3,
    canLeaveEmptyGroup: false,
  };

  const editorCondtionsConfig = {
    ...InitialConfig,
    conjunctions,
    operators,
    types,
    widgets,
    settings,
  };

  const { doFetch: doPost } = useFetch({
    method: 'POST',
    url: `contracts/${contractId}/workflow/contributors`,
    onSuccess: response => {
      if (!response?.data) return;
      const contributorToUpdate = contributors.find(
        contrib => contrib === contributor
      );
      const contributorUpdated = {
        ...contributorToUpdate,
        assignments: [
          ...contributorToUpdate.assignments,
          {
            type: workflowContributorTypes.user,
            typeLabel: intl.formatMessage({
              id: messages.contributor.pickers.type[
                workflowContributorTypes.user
              ].label,
            }),
            entity: {
              email: response.data.email,
              id: response.data.id,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
            },
          },
        ],
      };
      replace(index, contributorUpdated);
      navigateWorkflow();
    },
  });

  const handleCreateNewAccount = valuesNewUser => {
    const roleIds = valuesNewUser.groupIds ?? [];
    roleIds.push(externalRole.id);
    doPost({
      body: { ...valuesNewUser, roleIds, userEmail: valuesNewUser.email },
    });
  };

  const currentRoleOrGroup = rolesAndGroups?.find(
    roleOrGroup => roleOrGroup.id === contributor?.contributor?.entity?.id
  );
  const roleIsGroupNotInternal =
    currentRoleOrGroup &&
    currentRoleOrGroup.usersType !== 'Internal' &&
    ((currentRoleOrGroup.name === 'External' &&
      currentRoleOrGroup.type === roleTypeEnum.Role) ||
      currentRoleOrGroup.type === roleTypeEnum.Group);

  const showUsersPicker =
    (!schema ||
      (allowGroupsToHaveUsers &&
        currentRoleOrGroup?.type === roleTypeEnum.Group)) &&
    contributor?.contributor?.type === workflowContributorTypes.role;

  return (
    <ListItem component={Paper} variant="outlined">
      <Stack direction="row" width={1} columnGap={4}>
        <Box flexGrow={1}>
          <FormikContributorPicker
            disabled={readOnly || contributor.isEditable === false}
            required
            name={`${name}.${index}.contributor`}
            emptyUser={schema && !hideEmptyUser}
            usersOnly={usersOnly}
            excludeRoleIds={excludeRoleIds}
          />
          {showUsersPicker && (
            <>
              <FormikContributorPicker
                disabled={readOnly || contributor.isAssignable === false}
                PickerProps={{ disableClearable: true }}
                name={`${name}.${index}.assignments`}
                usersOnly
                multiple
                label={
                  <FormattedMessage id="Workflow.ContributorPicker.Users.Label" />
                }
                filterRoleIds={
                  contributor.contributor.entity?.id
                    ? [contributor.contributor.entity?.id]
                    : []
                }
                noResultElement={
                  !hideNewUser &&
                  hasCreatePermission &&
                  roleIsGroupNotInternal && (
                    <>
                      {' : '}
                      <Link
                        to={{
                          pathname: `contributor-modals/${contributor?.contributor?.entity?.id}/create`,
                        }}
                        state={{ modal: true }}
                      >
                        {`${intl.formatMessage({
                          id: 'Workflow.ContributorPicker.AddExternalUser.Label',
                        })}`}
                      </Link>
                    </>
                  )
                }
                excludeRoleIds={excludeRoleIds}
              />
              <DialogOutletRoutes
                path={`contributor-modals/${contributor.contributor.entity?.id}/*`}
                onClose={navigateWorkflow}
              >
                <Route
                  path="create"
                  element={
                    <AddAccountModalWithPermissions
                      permissions={contributorCreatePermission}
                      initialGroups={rolesAndGroups?.filter(
                        roleOrGroup =>
                          roleOrGroup.id === contributor.contributor.entity?.id
                      )}
                      groups={rolesAndGroups?.filter(
                        roleOrGroup =>
                          roleOrGroup.type === roleTypeEnum.Group &&
                          roleOrGroup.usersType !== 'Internal'
                      )}
                      onSubmit={handleCreateNewAccount}
                      hideEmptyList
                      showWorkflowAccess={false}
                      showSignatureWorkflowAccess={showSignature}
                      title={
                        <FormattedMessage id="Workflow.Contributor.Modal.Search.Title" />
                      }
                    />
                  }
                />
              </DialogOutletRoutes>
            </>
          )}
        </Box>
        <Divider orientation="vertical" flexItem />
        {!hideAccess && (
          <>
            <FormikSelect
              disabled={readOnly}
              FormControlProps={{ sx: { width: 350 } }}
              name={`${name}.${index}.access`}
              required
              label={
                <FormattedMessage id="Workflow.Contributor.Fields.Access.Label" />
              }
            >
              {(readOnly || parseInt(contributor?.access, 10) === 0) && (
                <MenuItem value={0}>
                  <FormattedMessage id="Workflow.Contributor.Fields.Access.0.Label" />
                </MenuItem>
              )}
              <MenuItem value={2}>
                <FormattedMessage id="Workflow.Contributor.Fields.Access.2.Label" />
              </MenuItem>
              <MenuItem value={3}>
                <FormattedMessage id="Workflow.Contributor.Fields.Access.3.Label" />
              </MenuItem>
              <MenuItem value={4}>
                <FormattedMessage id="Workflow.Contributor.Fields.Access.4.Label" />
              </MenuItem>
              <MenuItem value={5}>
                <FormattedMessage id="Workflow.Contributor.Fields.Access.5.Label" />
              </MenuItem>
              <MenuItem value={6}>
                <FormattedMessage id="Workflow.Contributor.Fields.Access.6.Label" />
              </MenuItem>
              {(showSignature || parseInt(contributor?.access, 10) === 1) && (
                <MenuItem value={1}>
                  <FormattedMessage id="Workflow.Contributor.Fields.Access.1.Label" />
                </MenuItem>
              )}
              {((showSignature && name !== 'mandatoryContributors') ||
                parseInt(contributor?.access, 10) === 7) && (
                <MenuItem value={7}>
                  <FormattedMessage id="Workflow.Contributor.Fields.Access.7.Label" />
                </MenuItem>
              )}
            </FormikSelect>
            <Divider orientation="vertical" flexItem />
          </>
        )}
        <Stack direction="row" alignItems="center" columnGap={2}>
          {!hideCondition && (
            <>
              <Button
                variant="text"
                color="primary"
                fullWidth
                startIcon={conditionValue ? <EditIcon /> : <AddCircleIcon />}
                sx={{ height: 'fit-content' }}
                disabled={readOnly}
                size="small"
                onClick={() => {
                  setOpenPopup(true);
                }}
              >
                <FormattedMessage
                  id={
                    conditionValue
                      ? 'Workflow.Contributor.Buttons.Condition.Edit'
                      : 'Workflow.Contributor.Buttons.Condition.Add'
                  }
                />
              </Button>
              <CustomConfirmDialog
                fullScreen
                openState={{ value: openPopup, update: setOpenPopup }}
                title={
                  <FormattedMessage id="Workflow.Contributor.Buttons.Condition.Edit" />
                }
                confirmText={<FormattedMessage id="Verbs.Validate" />}
                onSubmit={() => {}}
                content={
                  <ContributorConditionEditor
                    conditionState={{
                      value: conditionValue,
                      update: setConditionValue,
                    }}
                    editorCondtionsConfig={editorCondtionsConfig}
                    fields={fields}
                  />
                }
              />
            </>
          )}
          {!hideRemove && (
            <DeleteWorkflowContributorButton
              disabled={readOnly}
              onClick={() => {
                if (contributor?.contributor) {
                  setDeleteDialog(true);
                } else {
                  onRemove();
                }
              }}
            />
          )}
        </Stack>
      </Stack>
      {deleteDialog && (
        <WorkflowContributorDeleteDialog
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          onConfirm={() => {
            setDeleteDialog(false);
            onRemove();
          }}
          contributor={contributor?.contributor}
        />
      )}
    </ListItem>
  );
}

export default WorkflowContributorEditor;
