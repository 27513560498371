import React from 'react';
import { FormattedMessage } from 'react-intl';
import { alpha } from '@mui/system/colorManipulator';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';

import FormatPainter from '@editor/schema/extensions/format-painter';
import { useTiptapEditor } from '../../use-tiptap-editor';

function ButtonApplyStyle() {
  const editor = useTiptapEditor();
  const [isBtnActive, setActive] = React.useState(false);

  if (editor?.extensionStorage[FormatPainter.name]?.onStateUpdate === null) {
    // set update function
    editor.extensionStorage[FormatPainter.name].onStateUpdate = state => {
      setActive(state);
    };
  }

  return (
    <Tooltip
      placement="top"
      title={<FormattedMessage id="Editor.Toolbar.Command.Format.Painter" />}
    >
      <Button
        sx={{
          background: theme =>
            isBtnActive ? alpha(theme.palette.primary.main, 0.35) : 'inherit',
        }}
        onClick={event => {
          // console.log(event.detail);
          switch (event.detail) {
            case 1: {
              // single click
              editor.chain().toggleFormatPainter().focus().run();
              break;
            }
            case 2: {
              // double click
              editor.chain().enableFormatPainterMultiple().focus().run();
              break;
            }
            default: {
              break;
            }
          }
        }}
      >
        <FormatPaintIcon />
      </Button>
    </Tooltip>
  );
}

export default ButtonApplyStyle;
