import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box } from '@mui/material';
import FormikTextField from '@components/Formik/FormikTextField';

function MarginFormik() {
  return (
    <Box sx={{ background: 'white', padding: '5px 15px' }}>
      <FormikTextField
      as="fastfield"
        name="marginTop"
        label={<FormattedMessage id="Editor.Toolbar.Command.Margin.Top" />}
        size="small"
        margin="dense"
        type="number"
      />
      <FormikTextField
      as="fastfield"
        name="marginBottom"
        label={<FormattedMessage id="Editor.Toolbar.Command.Margin.Bottom" />}
        size="small"
        margin="dense"
        type="number"
      />
      <FormikTextField
      as="fastfield"
        name="marginLeft"
        label={<FormattedMessage id="Editor.Toolbar.Command.Margin.Left" />}
        size="small"
        margin="dense"
        type="number"
      />
      <FormikTextField
      as="fastfield"
        name="marginRight"
        label={<FormattedMessage id="Editor.Toolbar.Command.Margin.Right" />}
        size="small"
        margin="dense"
        type="number"
      />
    </Box>
  );
}

export default MarginFormik;
