import { defineMessages } from 'react-intl';

export default defineMessages({
  CampaignsCampaignsViewSelectDepartment: {
    id: 'Dashboard.Campaigns.CampaignsView.Select.Department',
    defaultMessage: 'Department',
  },
  CampaignsCampaignsViewSelectOptionsAllMembers: {
    id: 'Dashboard.Campaigns.CampaignsView.Select.Options.AllMembers',
    defaultMessage: 'All members',
  },
  CampaignsCampaignsViewSelectAllDepartments: {
    id: 'Dashboard.Campaigns.CampaignsView.Select.Options.AllDepartments',
    defaultMessage: 'All Departments',
  },
  CampaignsCampaignsViewSelectUser: {
    id: 'Dashboard.Campaigns.CampaignsView.Select.User',
    defaultMessage: 'User',
  },
  CampaignsCampaignsViewExpertSwitchAllSuppliers: {
    id: 'Dashboard.Campaigns.CampaignsView.ExpertSwitch.AllSuppliers',
    defaultMessage: 'Display all suppliers',
  },
  CampaignsCampaignsViewExpertSwitchCalled: {
    id: 'Dashboard.Campaigns.CampaignsView.ExpertSwitch.ClausesToBeValidated',
    defaultMessage: 'Display clauses to be validated',
  },
  SuppliersNegociatedButtonsAddLabel: {
    id: 'Dashboard.Suppliers.Negociated.Buttons.Add.Label',
    defaultMessage: 'Add a document to negociate',
  },
  SuppliersSignedButtonsAddLabel: {
    id: 'Dashboard.Suppliers.Signed.Buttons.Add.Label',
    defaultMessage: 'Add a non-negotiable document',
  },
  SuppliersNegociatedDialogsCreateTitle: {
    id: 'Dashboard.Suppliers.Negociated.Dialogs.Create.Title',
    defaultMessage: 'Create a document to negociate',
  },
  SuppliersNegociatedDialogsUpdateTitle: {
    id: 'Dashboard.Suppliers.Negociated.Dialogs.Update.Title',
    defaultMessage: 'Update a document to negociate',
  },
  SuppliersNegociatedDialogsViewTitle: {
    id: 'Dashboard.Suppliers.Negociated.Dialogs.View.Title',
    defaultMessage: 'View a document to negociate',
  },
  SuppliersNegociatedDialogsDeleteTitle: {
    id: 'Dashboard.Suppliers.Negociated.Dialogs.Delete.Title',
    defaultMessage: 'Delete a document',
  },
  SuppliersNegociatedDialogsDeleteMessage: {
    id: 'Dashboard.Suppliers.Negociated.Dialogs.Delete.Message',
    defaultMessage: 'You are about to delete the document <b>{name}</b>.',
  },
  SuppliersSignedDialogsCreateTitle: {
    id: 'Dashboard.Suppliers.Signed.Dialogs.Create.Title',
    defaultMessage: 'Create a non-negotiable document',
  },
  SuppliersSignedDialogsUpdateTitle: {
    id: 'Dashboard.Suppliers.Signed.Dialogs.Update.Title',
    defaultMessage: 'Update a non-negotiable document',
  },
  SuppliersSignedDialogsDeleteTitle: {
    id: 'Dashboard.Suppliers.Signed.Dialogs.Delete.Title',
    defaultMessage: 'Delete a document',
  },
  SuppliersSignedDialogsViewTitle: {
    id: 'Dashboard.Suppliers.Signed.Dialogs.View.Title',
    defaultMessage: 'View a non-negotiable document',
  },
  SuppliersSignedDialogsDeleteMessage: {
    id: 'Dashboard.Suppliers.Signed.Dialogs.Delete.Message',
    defaultMessage: 'You are about to delete the document <b>{name}</b>.',
  },
  SuppliersNegociatedLabel: {
    id: 'Dashboard.Suppliers.Negociated.Label',
    defaultMessage: 'Document to negociate',
  },
  SuppliersNegociatedFieldsNameLabel: {
    id: 'Dashboard.Suppliers.Negociated.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  SuppliersNegociatedFieldsTemplateLabel: {
    id: 'Dashboard.Suppliers.Negociated.Fields.Template.Label',
    defaultMessage: 'Template',
  },
  SuppliersNegociatedDialogRenegociateTitle: {
    id: 'Dashboard.Suppliers.Negociated.Dialog.Renegociate.Title',
    defaultMessage: 'Renegociate a contract',
  },
  SuppliersNegociatedRenegociationTypeLabel: {
    id: 'Dashboard.Suppliers.Negociated.RenegociationType.Label',
    defaultMessage: 'Type of renegociation',
  },
  SuppliersNegociatedDialogRenegociateParagraph: {
    id: 'Dashboard.Suppliers.Negociated.Dialog.Renegociate.Paragraph',
    defaultMessage:
      'You are about to renegociate the contract. Select how the contract should be renegociated.',
  },
  SuppliersNegociatedRenegociationTypeTemplateLabel: {
    id: 'Dashboard.Suppliers.Negociated.RenegociationType.Template.Label',
    defaultMessage: 'Regenerate the contract from its template',
  },
  SuppliersNegociatedRenegociationTypeAmendmentLabel: {
    id: 'Dashboard.Suppliers.Negociated.RenegociationType.Amendment.Label',
    defaultMessage: 'Create an amendment to the contract',
  },
  SuppliersSignedLabel: {
    id: 'Dashboard.Suppliers.Signed.Label',
    defaultMessage: 'Non-negotiable document',
  },
  SuppliersSignedFieldsNameLabel: {
    id: 'Dashboard.Suppliers.Signed.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  SuppliersSignedFieldsFileLabel: {
    id: 'Dashboard.Suppliers.Signed.Fields.File.Label',
    defaultMessage: 'File',
  },
  SuppliersSupportingLabel: {
    id: 'Dashboard.Suppliers.Supporting.Label',
    defaultMessage: 'Document to provide',
  },
  SuppliersNoResult: {
    id: 'Dashboard.Suppliers.NoResult',
    defaultMessage: 'No suppliers for the current business unit',
  },
  SuppliersSupportingDialogsViewTitle: {
    id: 'Dashboard.Suppliers.Supporting.Dialogs.View.Title',
    defaultMessage: 'View the supporting document',
  },
  SuppliersSupportingButtonsCreateLabel: {
    id: 'Dashboard.Suppliers.Supporting.Buttons.Create.Label',
    defaultMessage: 'Add a supporting document',
  },
  SuppliersSupportingDialogsCreateTitle: {
    id: 'Dashboard.Suppliers.Supporting.Dialogs.Create.Title',
    defaultMessage: 'Add a supporting document',
  },
  SuppliersSupportingDialogsUpdateTitle: {
    id: 'Dashboard.Suppliers.Supporting.Dialogs.Update.Title',
    defaultMessage: 'Update a supporting document',
  },
  SuppliersSupportingDialogsDeleteTitle: {
    id: 'Dashboard.Suppliers.Supporting.Dialogs.Delete.Title',
    defaultMessage: 'Delete a document',
  },
  SuppliersSupportingDialogsDeleteMessage: {
    id: 'Dashboard.Suppliers.Supporting.Dialogs.Delete.Message',
    defaultMessage: 'You are about to delete the document <b>{name}</b>.',
  },
  SuppliersSupportingFieldsNameLabel: {
    id: 'Dashboard.Suppliers.Supporting.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  SuppliersSupportingDocumentFieldsFileLabel: {
    id: 'Dashboard.Suppliers.Supporting.Document.Fields.File.Label',
    defaultMessage: 'File',
  },
  SuppliersSupportingDocumentDialogsCreateTitle: {
    id: 'Dashboard.Suppliers.Supporting.Document.Dialogs.Create.Title',
    defaultMessage: 'Upload a supporting document',
  },
  SuppliersSupportingDocumentDialogsDeleteTitle: {
    id: 'Dashboard.Suppliers.Supporting.Document.Dialogs.Delete.Title',
    defaultMessage: 'Delete a document',
  },
  SuppliersSupportingDocumentDialogsDeleteMessage: {
    id: 'Dashboard.Suppliers.Supporting.Document.Dialogs.Delete.Message',
    defaultMessage: 'You are about to delete the document <b>{name}</b>.',
  },
  CampaignsNoResult: {
    id: 'Dashboard.Campaigns.NoResult',
    defaultMessage: 'No campaigns for the current business unit',
  },
  CampaignsFoldersLabel: {
    id: 'Dashboard.Campaigns.Folders.Label',
    defaultMessage: 'Negociation campaign of {year}',
  },
  'Suppliers.Tabs.Contracts': {
    id: 'Dashboard.Suppliers.Tabs.Contracts',
    defaultMessage: 'Contracts',
  },
  'Suppliers.Tabs.ExpertTeam': {
    id: 'Dashboard.Suppliers.Tabs.ExpertTeam',
    defaultMessage: 'Expert team',
  },
  'Suppliers.Experts.SelectExpertView.Header.FiscalID': {
    id: 'Dashboard.Suppliers.Experts.SelectExpertView.Header.FiscalID',
    defaultMessage: 'Fiscal ID: {value}',
  },
  'Suppliers.Experts.SelectExpertView.Header.ProductManager': {
    id: 'Dashboard.Suppliers.Experts.SelectExpertView.Header.ProductManager',
    defaultMessage: 'Product manager: {value}',
  },
  'Suppliers.Experts.SelectExpertView.Header.AffectedDepartment': {
    id: 'Dashboard.Suppliers.Experts.SelectExpertView.Header.AffectedDepartment',
    defaultMessage: 'Affected department(s): {value}',
  },
  'Suppliers.Experts.SelectExpertView.Header.IdGHA': {
    id: 'Dashboard.Suppliers.Experts.SelectExpertView.Header.IdGHA',
    defaultMessage: 'FOUJUR ID: {value}',
  },
  'Suppliers.Experts.SelectExpertView.Header.PartnerID': {
    id: 'Dashboard.Suppliers.Experts.SelectExpertView.Header.PartnerID',
    defaultMessage: 'Partner ID: {value}',
  },
  'Supplier.Experts.Save.Success': {
    id: 'Dashboard.Supplier.Experts.Save.Success',
    defaultMessage: 'The selected experts have been saved.',
  },
  'Suppliers.Experts.SelectExpertView.Alerts.Information.Title': {
    id: 'Dashboard.Suppliers.Experts.SelectExpertView.Alerts.Information.Title',
    defaultMessage: 'Information',
  },
  'Suppliers.Experts.SelectExpertView.Alerts.Information.Description': {
    id: 'Dashboard.Suppliers.Experts.SelectExpertView.Alerts.Information.Description',
    defaultMessage:
      'Select the experts in charge of this supplier.{br}By default, the team of experts selected will be used for all suppliers in the same department. To modify this, uncheck the expertise(ies) concerned.',
  },
  'Suppliers.Experts.SelectExpertView.Title': {
    id: 'Dashboard.Suppliers.Experts.SelectExpertView.Title',
    defaultMessage: 'Expert team',
  },
  'Suppliers.Experts.SelectExpertView.NoAvailableExperts': {
    id: 'Dashboard.Suppliers.Experts.SelectExpertView.NoAvailableExperts',
    defaultMessage: 'There are no experts available for the department.',
  },
  'Suppliers.Experts.SelectExpertView.Nobody': {
    id: 'Dashboard.Suppliers.Experts.SelectExpertView.Nobody',
    defaultMessage: 'Nobody',
  },
  'Suppliers.Experts.SelectExpertView.Expert.Label': {
    id: 'Dashboard.Suppliers.Experts.SelectExpertView.Expert.Label',
    defaultMessage: 'Expert',
  },
  'Suppliers.Negotiated.NegotiatedViews.Alerts.Error.Title': {
    id: 'Dashboard.Suppliers.Negotiated.NegotiatedViews.Alerts.Error.Title',
    defaultMessage:
      'Please select the list of experts in charge of this file from the "Expert team" tab.',
  },
  'Leader.Member.FilesCount': {
    id: 'Dashboard.Leader.Member.FilesCount',
    defaultMessage: '{count} file(s)',
  },
  DashboardButtonNewLabel: {
    id: 'Dashboard.Button.New.Label',
    defaultMessage: 'New',
  },
  DashboardButtonSendToSignatureLabel: {
    id: 'Dashboard.Button.SendToSignature.Label',
    defaultMessage: 'Send to signature',
  },
  DashboardFolderNewLabel: {
    id: 'Dashboard.Folder.New.Label',
    defaultMessage: 'New folder',
  },
  DashboardFolderRenameLabel: {
    id: 'Dashboard.Folder.Rename.Label',
    defaultMessage: 'Rename folder',
  },
  DashboardNewTemplateLabel: {
    id: 'Dashboard.New.Template.Label',
    defaultMessage: 'New template',
  },
  DashboarRecycleBinLabel: {
    id: 'Dashboard.Recycle.Bin.Label',
    defaultMessage: 'Recycle bin',
  },
  'Leader.TotalGrowth': {
    id: 'Dashboard.Leader.TotalGrowth',
    defaultMessage: 'total growth',
  },
  'Leader.Order.Name': {
    id: 'Dashboard.Leader.Order.Name',
    defaultMessage: 'Name',
  },
  'Leader.Order.Progress': {
    id: 'Dashboard.Leader.Order.Progress',
    defaultMessage: 'Progress',
  },
  DashboardNewsTitleLabel: {
    id: 'Dashboard.News.Title.Label',
    defaultMessage: 'News',
  },
  DashboardNewsNotificationsTitleLabel: {
    id: 'Dashboard.News.Notifications.Title.Label',
    defaultMessage: 'Notifications',
  },
  DashboardNewsReadLabel: {
    id: 'Dashboard.News.Read.Button.Label',
    defaultMessage: 'read',
  },
  DashboardNewsUnReadLabel: {
    id: 'Dashboard.News.Unread.Button.Label',
    defaultMessage: 'unread',
  },
  DashboardNewsSwitchAllLabel: {
    id: 'Dashboard.News.Switch.All.Label',
    defaultMessage: 'All',
  },
  DashboardNewsSwitchUnreadLabel: {
    id: 'Dashboard.News.Switch.Unread.Label',
    defaultMessage: 'Unread',
  },
  DashboardNewsNoDataLabel: {
    id: 'Dashboard.News.NoData.Label',
    defaultMessage: 'No notifications',
  },
  DashboardNextDeadlinesTitle: {
    id: 'Dashboard.NextDeadlines.Title',
    defaultMessage: 'Next deadlines and reminders',
  },
  DashboardNextDeadlinesEmptyText: {
    id: 'Dashboard.NextDeadlines.Empty.Text',
    defaultMessage: "You don't have any reminder or any deadline.",
  },
  DashboardLastConsultedColumnsLastConsultedFolders: {
    id: 'Dashboard.LastConsulted.Columns.LastConsultedFolders',
    defaultMessage: 'Last consulted folders',
  },
  DashboardLastConsultedColumnsOwner: {
    id: 'Dashboard.LastConsulted.Columns.Owner',
    defaultMessage: 'Owner',
  },
  DashboardLastConsultedColumnsLastModificationDate: {
    id: 'Dashboard.LastConsulted.Columns.LastModificationDate',
    defaultMessage: 'Last modification date',
  },
  DashboardLastConsultedEmptyText: {
    id: 'Dashboard.LastConsulted.Empty.Text',
    defaultMessage: "You don't have any negotiation.",
  },
  DashboardCurrentNegotiationsTitle: {
    id: 'Dashboard.CurrentNegotiations.Title',
    defaultMessage: 'Current negotiations',
  },
  DashboardCurrentNegotiationsEmptyText: {
    id: 'Dashboard.CurrentNegotiations.Empty.Text',
    defaultMessage: "You don't have any negotiation.",
  },
  DashboardReminderModalReminderTypeBefore: {
    id: 'Dashboard.Reminder.Modal.ReminderType.Before',
    defaultMessage: 'before',
  },
  DashboardReminderModalReminderTypeAfter: {
    id: 'Dashboard.Reminder.Modal.ReminderType.After',
    defaultMessage: 'after',
  },
  DashboardReminderModalThe: {
    id: 'Dashboard.Reminder.Modal.The',
    defaultMessage: 'the',
  },
  DashboardReminderModalBy: {
    id: 'Dashboard.Reminder.Modal.By',
    defaultMessage: 'by',
  },
  DashboardSearchBreadcrumbLabel: {
    id: 'Dashboard.Search.Breadcrumb.Label',
    defaultMessage: 'Search',
  },
});
