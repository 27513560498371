import { defineMessages } from 'react-intl';

export default defineMessages({
  ModalButtonGroupCancelButtonLabel: {
    id: 'Modal.Button.Group.Cancel.Button.Label',
    defaultMessage: 'Cancel',
  },
  ModalButtonGroupSubmitButtonLabel: {
    id: 'Modal.Button.Group.Submit.Button.Label',
    defaultMessage: 'Confirm',
  },
  ModalButtonOkButtonLabel: {
    id: 'Modal.Button.Ok.Button.Label',
    defaultMessage: 'Ok',
  },
});
