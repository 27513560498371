import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinkIcon from '@mui/icons-material/Link';

import {
  resizeImageFile,
  resizeImageFromUrl,
} from '../../schema/extensions/image-tools';
import ButtonImagePanelUpload from './button-image-panel-upload';
import ButtonImagePanelUrl from './button-image-panel-url';
import { useTiptapEditor } from '../../use-tiptap-editor';

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

function ButtonImage() {
  const editor = useTiptapEditor();
  const [value, setValue] = React.useState('img-upload');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onUploadCallback = React.useCallback(
    (file, close) => {
      resizeImageFile(file)
        .then(base64 => {
          if (base64 !== null) {
            editor
              .chain()
              .focus()
              .setImage({ src: base64 })
              .scrollIntoView()
              .run();
          }
          close();
        })
        .catch(err => {
          console.log(err);
          close();
        });
    },
    [editor]
  );
  const onUrlCallback = React.useCallback(
    (url, close) => {
      resizeImageFromUrl(url, editor)
        .then(base64 => {
          if (base64 !== null) {
            editor
              .chain()
              .focus()
              .setImage({ src: base64 })
              .scrollIntoView()
              .run();
          }
          close();
        })
        .catch(close);
    },
    [editor]
  );
  return (
    <PopupState variant="popper" popupId="table-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={
              <FormattedMessage id="Editor.Toolbar.Command.Image.Insert" />
            }
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <AddPhotoAlternateIcon />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <Paper sx={{ p: 0, pb: 1, width: '40vw' }} elevation={8}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                  >
                    <LinkTab value="img-upload" icon={<CloudUploadIcon />} />
                    <LinkTab value="img-url" icon={<LinkIcon />} />
                  </Tabs>
                  {value === 'img-upload' && (
                    <ButtonImagePanelUpload
                      onSubmit={file =>
                        onUploadCallback(file, popupState.close)
                      }
                    />
                  )}
                  {value === 'img-url' && (
                    <ButtonImagePanelUrl
                      onSubmit={file => onUrlCallback(file, popupState.close)}
                    />
                  )}
                </Paper>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonImage;
