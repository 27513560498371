import { defineMessages } from 'react-intl';

export default defineMessages({
  SigninFieldsEmailLabel: {
    id: 'Signin.Fields.Email.Label',
    defaultMessage: 'Email',
  },
  SigninFieldsEmailRequired: {
    id: 'Signin.Fields.Email.Required',
    defaultMessage: 'Email is required',
  },
  SigninFieldsEmailInvalid: {
    id: 'Signin.Fields.Email.Invalid',
    defaultMessage: 'Email is invalid',
  },
  SigninFieldsPasswordLabel: {
    id: 'Signin.Fields.Password.Label',
    defaultMessage: 'Password',
  },
  SigninFieldsPasswordRequired: {
    id: 'Signin.Fields.Password.Required',
    defaultMessage: 'Password is required',
  },
  SigninFieldsPasswordConfirmationLabel: {
    id: 'Signin.Fields.PasswordConfirmation.Label',
    defaultMessage: 'Confirm password',
  },
  SigninFieldsPasswordResetLabel: {
    id: 'Signin.Fields.PasswordReset.Label',
    defaultMessage: 'Forgot password',
  },
  SigninButtonInitializePassword: {
    id: 'Signin.Button.InitializePassword',
    defaultMessage: 'Reset my password',
  },
  SigninButtonResetPassword: {
    id: 'Signin.Button.ResetPassword',
    defaultMessage: 'Reset my password',
  },
  SigninForgetPasswordDescriptionFieldMessage: {
    id: 'Signin.ForgetPassword.Description.Field.Message',
    defaultMessage:
      'Please fill your email address for resetting your password.',
  },
  SigninResetPasswordFieldsDescripionMessage: {
    id: 'Signin.Reset.Password.Fields.Description.Message',
    defaultMessage: 'Please enter the password you wish to use.',
  },
  SigninPasswordRequirementsValidateMessage: {
    id: 'Signin.Password.Requirements.Validate.Message',
    defaultMessage:
      'Your password must contain at least 12 characters including 1 number, 1 uppercase letter, 1 lowercase letter and 1 special character.',
  },
  SigninButton: {
    id: 'Signin.Button',
    defaultMessage: 'Sign in',
  },
  ResetPasswordFieldsNewPasswordLabel: {
    id: 'ResetPassword.Fields.NewPassword.Label',
    defaultMessage: 'New password',
  },
  ResetPasswordFieldsNewPasswordRequired: {
    id: 'ResetPassword.Fields.NewPassword.Required',
    defaultMessage: 'New password is required',
  },
  ResetPasswordFieldsConfirmPasswordLabel: {
    id: 'ResetPassword.Fields.ConfirmPassword.Label',
    defaultMessage: 'Confirm password',
  },
  ResetPasswordFieldsConfirmPasswordRequired: {
    id: 'ResetPassword.Fields.ConfirmPassword.Required',
    defaultMessage: 'Confirm password is required',
  },
  ResetPasswordFieldsConfirmPasswordDifferent: {
    id: 'ResetPassword.Fields.ConfirmPassword.Different',
    defaultMessage: 'Confirm password must be the same as the new password',
  },
  ResetPasswordButton: {
    id: 'ResetPassword.Button',
    defaultMessage: 'Reset password',
  },
  AuthorizeFieldsTenantLabel: {
    id: 'Authorize.Fields.Tenant.Label',
    defaultMessage: 'Business unit',
  },
  AuthorizeFieldsTenantRequired: {
    id: 'Authorize.Fields.Tenant.Required',
    defaultMessage: 'Business unit is required',
  },
  AuthorizeButton: {
    id: 'Authorize.Button',
    defaultMessage: 'Log in',
  },
  ResetPasswordGoToSignIn: {
    id: 'ResetPassword.GoToSignIn',
    defaultMessage: 'Sign in',
  },
  ForgotPasswordFieldsEmailLabel: {
    id: 'ForgotPassword.Fields.Email.Label',
    defaultMessage: 'Email',
  },
  ForgotPasswordButton: {
    id: 'ForgotPassword.Button',
    defaultMessage: 'Confirm',
  },
  ForgotPasswordGoToSignIn: {
    id: 'ForgotPassword.GoToSignIn',
    defaultMessage: 'Sign in',
  },
  BackToHomeButton: {
    id: 'BackToHome.Button',
    defaultMessage: 'Back to home page',
  },
  ReSendInvitationButton: {
    id: 'ReSendInvitation.Button',
    defaultMessage: 'Re-send the invitation',
  },
  SigninButtonNext: {
    id: 'Signin.Button.Next',
    defaultMessage: 'Next',
  },
});
