import UserChip from '@components/UserAvatar/UserChip';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage, FormattedDate } from 'react-intl';
import Fetching from '@components/Fetching/Fetching';

export default function FolderInfoCard({ information, fetching }) {
  return (
    <Box sx={{ bgcolor: '#FFFFFF', my: 2, borderRadius: '6px', p: 1 }}>
      <Fetching fetching={fetching}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              <FormattedMessage
                id="DriveView.FolderInfo.Card.Type.Label"
                defaultMessage="Type"
              />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                bgcolor: 'grey.200',
                width: 'fit-content',
                borderRadius: '6px',
                p: '6px 8px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gap: 1,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                {information?.type}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              <FormattedMessage
                id="DriveView.FolderInfo.Card.Created.By.Label"
                defaultMessage="Crée par"
              />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Tooltip
              placement="top"
              title={information?.creatorUser?.email ?? ''}
            >
              <Box
                sx={{
                  bgcolor: 'grey.200',
                  width: 'fit-content',
                  borderRadius: '6px',
                  maxWidth: '100%',
                }}
              >
                <UserChip
                  style={{
                    fontWeight: 600,
                    color: '#666666',
                    bgcolor: 'transparent',
                    fontSize: '11px',
                    whiteSpace: 'nowrap',
                    overFlow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  email={information?.creatorUser?.email ?? ''}
                  firstName={information?.creatorUser?.firstName ?? ''}
                  lastName={information?.creatorUser?.lastName ?? ''}
                />
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              <FormattedMessage
                id="DriveView.FolderInfo.Card.The.Label"
                defaultMessage="Le"
              />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                bgcolor: 'grey.200',
                width: 'fit-content',
                borderRadius: '6px',
                p: '6px 8px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gap: 1,
              }}
            >
              <CalendarTodayIcon fontSize="small" color="inherit" />
              <Typography variant="h4">
                <FormattedDate
                  value={information?.creationTime}
                  year="numeric"
                  month="long"
                  day="numeric"
                  sty
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Fetching>
    </Box>
  );
}
