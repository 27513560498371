import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useDisclosure from '@hooks/useDisclosure';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import ModalButtonGroup from './ModalButtonGroup';

export default function ConfirmModal({
  isOpen,
  onClose,
  onSubmit,
  title,
  description,
  descriptionValues = null,
  cancelButtonText,
  submitButtonText,
}) {
  return (
    <Dialog
      open={isOpen}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '8px',
          boxShadow: 10,
        },
      }}
      maxWidth="sm"
    >
      <DialogTitle sx={{ fontWeight: 700 }}>
        {title ? (
          <Typography variant="h5" component="h2" sx={{ fontWeight: 700 }}>
            <FormattedMessage id={title} />
          </Typography>
        ) : null}
      </DialogTitle>
      <DialogContent>
        {description ? (
          <DialogContentText sx={{ fontWeight: 700, color: 'initial' }}>
            <Typography sx={{ mt: 2, fontWeight: 700, fontSize: '12px' }}>
              {descriptionValues ? (
                <FormattedMessage id={description} values={descriptionValues} />
              ) : (
                <FormattedMessage id={description} />
              )}
            </Typography>
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', justifyContent: 'center', px: 5, py: 2 }}
      >
        <ModalButtonGroup
          variant="error"
          handleClose={onClose}
          handleSubmit={onSubmit}
          cancelButtonText={cancelButtonText ?? 'Cancel'}
          submitButtonText={submitButtonText ?? 'Confirm'}
        />
      </DialogActions>
    </Dialog>
  );
}

export function ConfirmModalButton({ onSubmit, ...rest }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen}>Open Dialog</Button>
      <ConfirmModal
        {...rest}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </>
  );
}
