import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  useParams,
  useSearchParams,
  useNavigate,
  Routes,
  Route,
} from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fetching from '@components/Fetching/Fetching';
import WorkflowStepperWrapper, {
  drawerWidth,
} from '@components/Workflow/WorkflowStepperWrapper';
import useFetch from '@hooks/useFetch';
import { localeState } from '@recoil/locale';
import { useRecoilValue } from 'recoil';
import useMessage from '@rottitime/react-hook-message-event';
import { useSnackbar } from 'notistack';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import ContractWorkflowView from '@components/Workflow/ContractWorkflowView';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { useAppContext } from 'src/contexts/AppContext';
import { forwardRef, useCallback, useRef, useState } from 'react';
import { tenantRolesIsInternalSelector } from '@recoil/tenant-token';
import ShareMenu from '@views/Drive/ShareMenu/ShareMenu';
import {
  useContractFetch,
  useTermSheetFetch,
} from '@views/Drive/ShareMenu/ShareMenuTabs/TermSheet/TermSheet';
import useMountedRef from '@hooks/useMountedRef';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import AddElementToTermSheetModal from '@views/Drive/components/AddElementToTermSheetModal';
import EditElementToTermSheetModal from '@views/Drive/components/EditElementToTermSheetModal';
import DeleteTermSheetElementModal from '@views/Drive/components/DeleteTermSheetElementModal';
import { useDocumentLinksFetch } from '@views/Drive/ShareMenu/DocumentLinksCard/DocumentLinksCard';
import NavigationBlocker, {
  defaultNavigationBlockMessage,
} from '@components/Alerts/NavigationBlocker';
import ContractNotesView from './ContractNotesView';
import ContractAnnexView from './ContractAnnexView';
import ContractVersionView from './components/Versionning/ContractVersionView';
import ExportTermsheetModal from '../Drive/ShareMenu/ShareMenuTabs/TermSheet/ExportTermsheetModal';

const ContractEmbeddedIFrame = forwardRef(function ContractEmbeddedIFrame(
  { contractId, combined, iframeParameters },
  ref
) {
  if (!process.env.REACT_APP_EMBEDDED_URI) {
    throw new Error('Embedded uri not set.');
  }
  const locale = useRecoilValue(localeState);

  const theme = useTheme();
  const appContext = useAppContext();

  const primaryColor = theme.palette.primary.main.replace('#', '');
  const secondaryColor = theme.palette.secondary.main.replace('#', '');

  const showHeader =
    iframeParameters?.showHeader ??
    appContext.contract?.embedded?.showHeader ??
    null;

  const url = `${process.env.REACT_APP_EMBEDDED_URI
    }?selector=root&view=contract&uid=${contractId}${iframeParameters.mode ? `&mode=${iframeParameters.mode}` : ''
    }&locale=${locale}&primaryColor=${primaryColor}&secondaryColor=${secondaryColor}&sidebarWidth=${iframeParameters?.drawerWidth || drawerWidth
    }&folderId=${iframeParameters?.folderId}&dev=${process.env.REACT_APP_EDITOR_DEV ?? 'false'
    }${iframeParameters.hideSignatureButton ? '&hideSignatureButton=true' : ''}${typeof showHeader === 'boolean' ? `&showHeader=${showHeader}` : ''
    }${iframeParameters.hideNextStepButton ? '&hideNextStepButton=true' : ''
    }&token=`;

  return (
    <iframe
      ref={ref}
      src={`${url}${combined?.token.value}`}
      title="embedded"
      width="100%"
      height="100%"
      style={{ border: 0 }}
    />
  );
});

export function ContractEmbeddedView({
  backUrl: backUrlProp = '/drive',
  hideWorkflow: hideWorkflowProp = false,
  hideTabs = false,
  iframeParameters = {},
  fetchAdeoOpenClauses = null,
  fetchAdeoClausesMessages = null,
  hideEditWorkflowButton = false,
  hideWorkflowInfo = false,
}) {
  const iframeRef = useRef();
  const mountedRef = useMountedRef();
  const appContext = useAppContext();
  const hideWorkflow = hideWorkflowProp || appContext.contract.workflow.hidden;
  const isContractSidebarShown = appContext.contract.showSidebar;

  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const backUrl = searchParams.get('backUrl') || backUrlProp;

  const { contractId, supplierFolderId } = useParams();

  const { data: combined, fetching: fetchingCombined } = useFetch({
    url: '/authentication/authenticate/current-combined',
  });

  const {
    fetching: fetchingContract,
    data: contract,
    doFetch: refreshContract,
    error: contractError,
  } = useFetch({
    url: `/contracts/${contractId}?includeWorkflow=true&includeStatus=true`,
  });

  const { doFetch: doPostComment } = useFetch({
    method: 'POST',
    url: `contracts/${contractId}/comment`,
  });

  const { fetching: fetchingAccess, data: dataAccess } = useFetch({
    url: `/contracts/${contractId}/access`,
  });
  const { doFetch: doPutClosedClauseStatus } = useFetch({
    method: 'PUT',
    url: `contracts/${contractId}/content/closed-clause-status`,
    cachePolicy: 'network-only',
  });

  const termSheetFetch = useTermSheetFetch({ entityId: contractId });

  const contractFetch = useContractFetch({ entityId: contractId });

  const documentLinksfetch = useDocumentLinksFetch({ documentId: contractId });

  const isInternal = useRecoilValue(tenantRolesIsInternalSelector);
  const [isExternalUser] = useState(!isInternal);

  const getEditorContent = useCallback(
    () =>
      new Promise(resolve => {
        const listener = event => {
          if (event.data?.type !== 'content') return;
          if (mountedRef.current) resolve(event.data.payload?.content);
          window.removeEventListener('message', listener);
        };

        window.addEventListener('message', listener);

        iframeRef.current.contentWindow.postMessage(
          { type: 'content', payload: null },
          '*'
        );
      }),
    [mountedRef]
  );

  const customUi = appContext.contract.useCustomUi({
    contractId,
    contract,
    fetchingContract,
    contractError,
    refreshContract,
    getEditorContent,
  });

  useMessage('navigation', (send, payload) => {
    if (payload?.back === true) {
      navigate(backUrl);
    }
  });

  useMessage('comment', (send, payload) => {
    // eslint-disable-next-line no-param-reassign
    payload.documentFolderId = supplierFolderId;
    doPostComment({ body: payload });
  });

  useMessage('call-reviewer', (send, payload) => {
    if (payload.type === 'resolved') {
      doPutClosedClauseStatus({ body: payload });
    } else if (payload.type === 'assigned-reviewer') {
      if (payload.success && payload.workflowUsersUpdated === true) {
        refreshContract();
      }
    }
  });

  useMessage('contract-workflow-step', (send, payload) => {
    if (payload?.completed === true) {
      enqueueSnackbar(
        formatMessage({ id: 'Contract.Workflow.Complete.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      refreshContract();
    }
    if (payload?.rejected === true) {
      enqueueSnackbar(
        formatMessage({ id: 'Contract.Workflow.Reject.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      refreshContract();
    }
    if (payload?.validated === true) {
      enqueueSnackbar(
        formatMessage({ id: 'Contract.Workflow.Validate.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      refreshContract();
    }
    if (payload?.invalidated === true) {
      enqueueSnackbar(
        formatMessage({ id: 'Contract.Workflow.Invalidate.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      refreshContract();
    }
  });

  useMessage('contract-signature', (send, payload) => {
    if (payload?.sended === true) {
      enqueueSnackbar(
        formatMessage({ id: 'Contract.Signature.Send.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      refreshContract();
    }
    if (payload?.canceled === true) {
      enqueueSnackbar(
        formatMessage({ id: 'Contract.Signature.Cancel.Success' }),
        {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }
      );
      refreshContract();
    }
  });

  useMessage('negotiation', (send, payload) => {
    // events for 'expertise' negotiation type
    // see negotiation-types.ts for possible type values
    switch (payload.type) {
      case 'call_expert':
        if (fetchAdeoOpenClauses) {
          fetchAdeoOpenClauses.doFetch({
            body: {
              type: payload.type,
              clauseId: payload.clauseId,
              expertiseId: payload.expertiseId,
              expertCalled: true,
              clauseName: payload.clauseName,
            },
          });
        }
        break;
      case 'clause_validated':
        if (fetchAdeoOpenClauses) {
          fetchAdeoOpenClauses.doFetch({
            body: {
              type: payload.type,
              clauseId: payload.clauseId,
              clauseName: payload.clauseName,
            },
          });
        }
        break;
      case 'Negotiating':
        if (fetchAdeoOpenClauses) {
          fetchAdeoOpenClauses.doFetch({
            body: {
              type: payload.type,
              clauseId: payload.clauseId,
              expertiseId: null,
              expertCalled: false,
              clauseName: payload.clauseName,
            },
          });
        }
        break;
      case 'message':
        if (fetchAdeoClausesMessages) {
          fetchAdeoClausesMessages.doFetch({
            body: {
              expertiseId: payload.expertiseId,
              clauseId: payload.clauseId,
              clauseName: payload.clauseName,
              channel: payload.channel,
            },
          });
        }
        break;
      default:
        break;
    }
  });

  useMessage('pdf', (send, payload) => {
    if (payload?.error) {
      enqueueSnackbar(formatMessage({ id: 'Contract.PDF.Preview.Error' }), {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  });

  const navBack = () => {
    const url = `.?backUrl=${encodeURIComponent(backUrl)}`;
    navigate(url, { replace: true, state: 'modals' });
  };

  const [tabValue, setTabValue] = useState(0);
  const isContractTabOpen = tabValue === 0;

  const onTabChange = (_event, newTabValue) => {
    if (
      !isContractTabOpen ||
      !customUi.blockNavigation ||
      // eslint-disable-next-line no-alert
      window.confirm(defaultNavigationBlockMessage)
    ) {
      setTabValue(newTabValue);
    }
  };

  const refresh = () => {
    refreshContract();
  };
  return (
    <>
      <Fetching
        fetching={fetchingContract || fetchingCombined || fetchingAccess}
        sx={{ flexGrow: 1 }}
      >
        {contractError ? (
          <Box>
            <ErrorMessage error={contractError} />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gridTemplateRows: 'auto 1fr',
              gridTemplateAreas: '"workflow header" "workflow content"',
              flexGrow: 1,
              position: 'relative',
              height: '100%',
            }}
          >
            {!hideWorkflow && (
              <Stack flexDirection="column" gridArea="workflow">
                <WorkflowStepperWrapper
                  status={contract?.status}
                  workflow={contract?.workflow}
                  refresh={refresh}
                  workFlowAccess={dataAccess?.workflowAccesses}
                  hideEditWorkflowButton={hideEditWorkflowButton}
                  hideWorkflowInfo={hideWorkflowInfo}
                />
              </Stack>
            )}
            <Box
              gridArea="header"
              sx={
                isContractSidebarShown
                  ? {
                    bgcolor: 'grey.100',
                  }
                  : {}
              }
            >
              {Boolean(customUi.prelude) && customUi.prelude}

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {!hideTabs && (
                  <Tabs
                    value={tabValue}
                    onChange={onTabChange}
                    sx={{
                      border: 'none',
                    }}
                  >
                    <Tab label="Document" value={0} />
                    {!appContext.contract.versions.hidden &&
                      !isExternalUser && <Tab label="Versions" value={1} />}
                    {!appContext.contract.annexes.hidden && (
                      <Tab label="Annexes" value={2} />
                    )}
                    {!appContext.contract.notes.hidden && !isExternalUser && (
                      <Tab label="Notes" value={3} />
                    )}
                  </Tabs>
                )}

                {Boolean(customUi.actions) && (
                  <Stack direction="row" mr={2} gap={2}>
                    {customUi.actions}
                  </Stack>
                )}
              </Box>
            </Box>

            <Stack
              flexDirection="column"
              gridArea="content"
              sx={{ overflow: 'hidden' }}
            >
              {(hideTabs || tabValue === 0) && combined ? (
                <Grid
                  container
                  wrap="nowrap"
                  sx={{
                    height: '100%',
                  }}
                >
                  {!!customUi.blockNavigation && (
                    <NavigationBlocker message={null} unsavedData />
                  )}

                  <Grid
                    item
                    md={isContractSidebarShown ? 9.5 : 12}
                    sx={{
                      borderTop: isContractSidebarShown
                        ? '1px solid #D1D1D1'
                        : 'none',
                    }}
                  >
                    <ErrorBoundary>
                      <ContractEmbeddedIFrame
                        ref={iframeRef}
                        iframeParameters={iframeParameters}
                        contractId={contractId}
                        combined={combined}
                      />
                    </ErrorBoundary>
                  </Grid>

                  {isContractSidebarShown && (
                    <Grid item md={2.5}>
                      <ShareMenu
                        documentInformation={undefined}
                        contract={contract}
                        entityId={contractId}
                        entityType="document"
                        onClose={() => { }}
                        parentId={undefined}
                        fetching={fetchingContract}
                        isContractView
                        termSheetFetch={termSheetFetch}
                        hideInfoTab
                        contractFetch={contractFetch}
                        documentLinksFetch={documentLinksfetch}
                      />
                    </Grid>
                  )}
                </Grid>
              ) : null}
              {!hideTabs && tabValue === 1 ? (
                <ErrorBoundary>
                  <ContractVersionView contractId={contractId} />
                </ErrorBoundary>
              ) : null}
              {!hideTabs && tabValue === 2 ? (
                <ErrorBoundary>
                  <ContractAnnexView contractId={contractId} />
                </ErrorBoundary>
              ) : null}
              {!hideTabs && tabValue === 3 ? (
                <ErrorBoundary>
                  <Box sx={{ overflow: 'auto' }}>
                    <ContractNotesView contractId={contractId} />
                  </Box>
                </ErrorBoundary>
              ) : null}
            </Stack>
          </Box>
        )}
      </Fetching>

      <DialogOutletRoutes path="modals/*" onClose={navBack}>
        <Route
          path="add-element/:id"
          element={
            <AddElementToTermSheetModal
              refresh={() => termSheetFetch.doFetch()}
              title={
                <FormattedMessage id="DriveView.Dialogs.NewTermSheetElement.Title" />
              }
              disabled={false}
            />
          }
        />
        <Route
          path="edit-element/:id/edit"
          element={
            <EditElementToTermSheetModal
              entityId={contractId}
              refresh={() => termSheetFetch.doFetch()}
              title={
                <FormattedMessage id="DriveView.Dialogs.EditTermSheetElement.Title" />
              }
            />
          }
        />
        <Route
          path="delete-element/:id/delete"
          element={
            <DeleteTermSheetElementModal
              entityId={contractId}
              refresh={() => termSheetFetch.doFetch()}
            />
          }
        />
        <Route
          path="term-sheet/export/:entityId/:entityType"
          element={<ExportTermsheetModal />}
        />
      </DialogOutletRoutes>
    </>
  );
}

function ContractView(props) {
  const { workflowExcludeRoleIds, ...rest } = props;
  return (
    <Routes>
      <Route
        path="*"
        element={
          <ErrorBoundary>
            <ContractEmbeddedView {...rest} />
          </ErrorBoundary>
        }
      />
      <Route
        path="workflow/*"
        element={
          <ErrorBoundary>
            <ContractWorkflowView
              {...rest}
              excludeRoleIds={workflowExcludeRoleIds}
              backUrl=".."
            />
          </ErrorBoundary>
        }
      />
    </Routes>
  );
}

export default ContractView;
