import React from 'react';
import { isActive } from '@tiptap/core';
import { FormattedMessage } from 'react-intl';
import { alpha } from '@mui/system/colorManipulator';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';

import { useTiptapEditor } from '../use-tiptap-editor';
import { useTiptapState } from '../use-tiptap-state';

function ToolbarFormat() {
  const editor = useTiptapEditor();
  const bold = useTiptapState(
    state => Boolean(state) && isActive(state, 'bold')
  );
  const italic = useTiptapState(
    state => Boolean(state) && isActive(state, 'italic')
  );
  const underline = useTiptapState(
    state => Boolean(state) && isActive(state, 'underline')
  );
  const strike = useTiptapState(
    state => Boolean(state) && isActive(state, 'strike')
  );
  return (
    <ButtonGroup variant="text">
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.Bold" />}
      >
        <Button
          sx={{
            background: theme =>
              bold ? alpha(theme.palette.primary.main, 0.35) : 'inherit',
          }}
          onClick={() => editor.chain().focus().toggleBold().run()}
        >
          <FormatBoldIcon />
        </Button>
      </Tooltip>
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.Italic" />}
      >
        <Button
          sx={{
            background: theme =>
              italic ? alpha(theme.palette.primary.main, 0.35) : 'inherit',
          }}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        >
          <FormatItalicIcon />
        </Button>
      </Tooltip>
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.Underline" />}
      >
        <Button
          sx={{
            background: theme =>
              underline ? alpha(theme.palette.primary.main, 0.35) : 'inherit',
          }}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
        >
          <FormatUnderlinedIcon />
        </Button>
      </Tooltip>
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.Strike" />}
      >
        <Button
          sx={{
            background: theme =>
              strike ? alpha(theme.palette.primary.main, 0.35) : 'inherit',
          }}
          onClick={() => editor.chain().focus().toggleStrike().run()}
        >
          <StrikethroughSIcon />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
}

export default ToolbarFormat;
