import React, { useMemo } from 'react';
import { HostAuthenticated } from '@components/ProtectedRoutes/HostAuthenticatedRoute';
import { TenantAuthenticated } from '@components/ProtectedRoutes/TenantAuthenticatedRoute';
import messages from '@i18n/keys';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AuthorizeElevateForm from '@views/SigninView/AuthorizeElevateForm';
import AuthorizeForm from '@views/SigninView/AuthorizeForm';
import { FormattedMessage } from 'react-intl';
import { Navigate, Route, Routes, useResolvedPath } from 'react-router-dom';
import useNexityTheme from '@shells/nexity/hooks/useNexityTheme';
import artchipel from '../../assets/artchipel.jpg';
import gardens from '../../assets/edouard-denis/gardens.jpg';
import nexityLogo from '../../assets/logo-big.png';
import edouardDenisLogo from '../../assets/edouard-denis/logo-blue-on-transparent.png';
import Footer from '../Footers/Footer';
import SigninForm from './SigninForm';

const NexityBackgroundImage = styled(Box)`
  background-image: url(${artchipel});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100%;
  min-width: 100%;
`;

const EdouardDenisBackgroundImage = styled(Box)`
  background-image: url(${gardens});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100%;
  min-width: 100%;
`;

const NexityLogoImage = styled('img')`
  height: 130px;
  align-self: center;
  margin-top: -110px;
`;

const EdouardDenisLogoImage = styled('img')`
  height: 6rem;
  align-self: center;
  margin-bottom: 2rem;
`;

export function SigninLayout({ children, hideWelcome }) {
  const { name } = useNexityTheme();
  const BackgroundImage =
    name === 'edouard-denis'
      ? EdouardDenisBackgroundImage
      : NexityBackgroundImage;
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '120px 1fr 120px',
      }}
    >
      <BackgroundImage sx={{ gridColumn: 1, gridRow: 2 }} />
      <Stack
        sx={{
          gridRow: 2,
          gridColumn: 1,
          bgcolor: 'background.paper',
          width: '500px',
          p: 4,
          alignSelf: 'center',
          justifySelf: 'center',
        }}
      >
        {name === 'edouard-denis' ? (
          <EdouardDenisLogoImage src={edouardDenisLogo} alt="Edouard Denis" />
        ) : (
          <NexityLogoImage src={nexityLogo} alt="Nexity" />
        )}
        {!hideWelcome && (
          <Typography>
            <FormattedMessage id={messages.homepage.welcome.message} />
          </Typography>
        )}
        {children}
      </Stack>
      <Footer />
    </Box>
  );
}

function SigninView({ sendSmsUrl }) {
  const signinUrl = useResolvedPath('.').pathname;
  const { name } = useNexityTheme();
  const sendSmsBody = useMemo(() => ({ themeName: name }), [name]);

  return (
    <SigninLayout>
      <Routes>
        <Route index element={<SigninForm />} />
        <Route path="authorize/*">
          <Route
            path="elevate/*"
            element={
              <TenantAuthenticated>
                <AuthorizeElevateForm
                  backTo={signinUrl}
                  sendSmsUrl={sendSmsUrl}
                  sendSmsBody={sendSmsBody}
                />
              </TenantAuthenticated>
            }
          />
          <Route
            path="*"
            element={
              <HostAuthenticated>
                <AuthorizeForm backTo={signinUrl} tenantClient="nexity" />
              </HostAuthenticated>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="/signin" replace />} />
      </Routes>
    </SigninLayout>
  );
}

export default SigninView;
