import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

import { useTiptapEditor } from '../use-tiptap-editor';

import ButtonDebug from './buttons/button-debug';

function ToolbarHistory() {
  const editor = useTiptapEditor();
  return (
    <ButtonGroup variant="text" sx={{ ml: { xl: 'auto' } }}>
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.Undo" />}
      >
        <Button
          sx={{ color: 'primary.main' }}
          onClick={() => editor.chain().focus().undo().run()}
        >
          <UndoIcon />
        </Button>
      </Tooltip>
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.Redo" />}
      >
        <Button
          sx={{ color: 'primary.main' }}
          onClick={() => editor.chain().focus().redo().run()}
        >
          <RedoIcon />
        </Button>
      </Tooltip>
      {process.env.NODE_ENV !== 'production' && <ButtonDebug />}
    </ButtonGroup>
  );
}

export default ToolbarHistory;
