import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '@i18n/keys';

import FormsToCompleteTitle from './FormsToComplete/FormsToCompleteTitle';
import FormsToCompleteRow from './FormsToComplete/FormsToCompleteRow';

function FormsToComplete({ id, forms }) {
  return [
    <FormsToCompleteTitle key="title" />,
    <FormsToCompleteRow
      key="civil"
      id={id}
      type="civil"
      status={forms?.civil?.status ?? 0}
    >
      <FormattedMessage
        id={messages.nexity.folders.formstocomplete.civilstate}
      />
    </FormsToCompleteRow>,
    <FormsToCompleteRow
      key="family"
      id={id}
      type="family"
      status={forms?.family?.status ?? 0}
    >
      <FormattedMessage
        id={messages.nexity.folders.formstocomplete.familysituation}
      />
    </FormsToCompleteRow>,
    <FormsToCompleteRow
      key="financing"
      id={id}
      type="financing"
      status={forms?.financing?.status ?? 0}
    >
      <FormattedMessage
        id={messages.nexity.folders.formstocomplete.financing}
      />
    </FormsToCompleteRow>,
    <FormsToCompleteRow
      key="project"
      id={id}
      type="project"
      status={forms?.project?.status ?? 0}
    >
      <FormattedMessage id={messages.nexity.folders.formstocomplete.project} />
    </FormsToCompleteRow>,
  ];
}

export default FormsToComplete;
