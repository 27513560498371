import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { Field } from 'formik';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
      borderRadius: '6px',
    },
    '&:not(.Mui-error):hover fieldset': {
      borderColor: 'transparent',
      borderRadius: '6px',
    },
    '&:not(.Mui-error).Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-input': {
    // backgroundColor: '#E8E8E8',
    // borderRadius: '6px',
  },
});

function MuiTextField({ field, form, label, ...props }) {
  const { touched, errors } = form;
  const { name } = field;
  const error = Boolean(touched?.[name]) && Boolean(errors?.[name]);
  return (
    <>
      <Typography variant="subtitle1" color="GrayText">
        {label}
      </Typography>
      <FormControl fullWidth>
        <StyledTextField
          size="small"
          fullWidth
          sx={{ borderRadius: '6px' }}
          {...props}
          {...field}
          error={error}
          helperText={
            error && (
              <FormattedMessage
                id={errors[name]}
                values={{
                  label: props?.label ?? '',
                  value: field?.value ?? '',
                }}
              />
            )
          }
        />
      </FormControl>
    </>
  );
}

export default function FormikTextField(props) {
  return <Field component={MuiTextField} {...props} />;
}
