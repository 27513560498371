import ProjectForm, { schema, initialValues } from './ProjectForm';
import withFormsBehavior from '../withFormsBehavior';

const ProjectView = withFormsBehavior(ProjectForm, {
  type: 'project',
  schema,
  getInitialValues: (forms = {}) => ({
    ...initialValues,
    ...forms,
  }),
});

export default ProjectView;
