import React, { ComponentProps } from 'react';
import MuiIconButton from '@mui/material/IconButton';
import { Link, To } from 'react-router-dom';

export default function IconButton({
  to,
  ...props
}: { to?: To } & ComponentProps<typeof MuiIconButton>) {
  const isLink = to !== null && to !== undefined;
  return (
    <MuiIconButton
      component={isLink ? Link : 'button'}
      to={isLink ? to : undefined}
      {...props}
    />
  );
}
