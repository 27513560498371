import ErrorAlert from '@components/Alerts/ErrorAlert';
import React from 'react';
import { ErrorBoundary as LibErrorBoundary } from 'react-error-boundary';

function ErrorBoundaryFallback() {
  return <ErrorAlert />;
}

function ErrorBoundary({ children }) {
  if (!children) {
    return null;
  }
  return (
    <LibErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      {children}
    </LibErrorBoundary>
  );
}

export default ErrorBoundary;
