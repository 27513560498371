import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import FormikProvider from '@components/Formik/FormikProvider';
import FormikTextField from '@components/Formik/FormikTextField';
import { findParentNode } from '@tiptap/core';
import TableCell from '@tiptap/extension-table-cell';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { Box } from '@mui/material';

import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';

import { useTiptapEditor } from '../../use-tiptap-editor';
import { useTiptapSelection } from '../../use-tiptap-selection';

const schema = Yup.object().shape({
  width: Yup.number()
    .nullable(true)
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (val === Number(val) ? val : null)),
});

function ButtonTableColumn() {
  const editor = useTiptapEditor();
  const selection = useTiptapSelection();
  const node = React.useMemo(() => {
    if (!selection) return null;
    const parent = findParentNode(node => node.type.name === TableCell.name)(
      selection
    );
    return parent;
  }, [selection]);
  const currentValues = React.useMemo(
    () => ({
      width: node?.node.attrs.colwidth?.[0] ?? null,
    }),
    [node]
  );
  const onSubmitCallback = React.useCallback(
    values => {
      if (node && currentValues.width !== values.width) {
        editor
          .chain()
          .focus()
          .setTableColsAttribute('colwidth', values.width)
          .run();
      }
    },
    [node]
  );
  return (
    <PopupState variant="popper" popupId="table-column-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={
              <FormattedMessage id="Editor.Toolbar.Command.Table.Column" />
            }
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <TableRowsOutlinedIcon sx={{ transform: 'rotate(90deg)' }} />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <Box
                  sx={{
                    boxShadow:
                      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                  }}
                >
                  <MenuList
                    sx={{ p: 0, boxShadow: 'none' }}
                    component={Paper}
                    elevation={8}
                  >
                    <MenuItem
                      onClick={() => {
                        editor.chain().focus().addColumnBefore().run();
                        popupState.close();
                      }}
                    >
                      <FormattedMessage id="Editor.Toolbar.Command.Table.Column.Before" />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        editor.chain().focus().addColumnAfter().run();
                        popupState.close();
                      }}
                    >
                      <FormattedMessage id="Editor.Toolbar.Command.Table.Column.After" />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        editor.chain().focus().deleteColumn().run();
                        popupState.close();
                      }}
                    >
                      <FormattedMessage id="Editor.Toolbar.Command.Table.Column.Delete" />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        editor
                          .chain()
                          .focus()
                          .setTableColsAttribute('colwidth', null)
                          .run();
                        popupState.close();
                      }}
                    >
                      <FormattedMessage id="Editor.Toolbar.Command.Table.Column.Size.Reset.Default" />
                    </MenuItem>
                  </MenuList>
                  <Box sx={{ background: 'white', padding: '5px 15px' }}>
                    <FormikProvider
                      schema={schema}
                      initialValues={currentValues}
                      enableReinitialize
                      onSubmit={onSubmitCallback}
                    >
                      <FormikTextField
                      as="fastfield"
                        name="width"
                        label={
                          <FormattedMessage id="Editor.Toolbar.Command.Table.Column.Size.Label" />
                        }
                        size="small"
                        margin="dense"
                        type="number"
                      />
                      <Button variant="contained" sx={{ mt: 1 }} type="submit">
                        <FormattedMessage id="Verbs.Apply" />
                      </Button>
                    </FormikProvider>
                  </Box>
                </Box>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonTableColumn;
