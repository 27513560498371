import React from 'react';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import messages from '@i18n/keys';

function FormsToCompleteTitle() {
  return (
    <Typography
      component={Stack}
      direction="row"
      gap={1}
      sx={{ bgcolor: 'secondary.main', p: 1, color: 'common.white' }}
    >
      <DynamicFormIcon sx={{ color: 'common.white' }} />
      <FormattedMessage id={messages.nexity.folders.formstocomplete.label} />
    </Typography>
  );
}

export default FormsToCompleteTitle;
