import { localeStateSelector, isLocaleForcedState } from '@recoil/locale';
import { tokenState } from '@recoil/user';

const getLocaleFromCookie = () => {
  const key = '.AspNetCore.Culture';
  const pair = document.cookie.split('; ').find(row => row.startsWith(key));
  return pair?.substring(key.length + 3, key.length + 5);
};

const getTokenFromCookie = () => {
  const key = 'token';
  const pair = document.cookie.split('; ').find(row => row.startsWith(key));
  return pair?.split('=')[1];
};

const initializeState = ({ set }, forcedLanguage) => {
  let locale = 'fr';
  if (!forcedLanguage)
    locale = getLocaleFromCookie() || localStorage.getItem('locale') || 'fr';
  else {
    locale = forcedLanguage;
    set(isLocaleForcedState, true);
  }
  set(localeStateSelector, locale);
  const token = getTokenFromCookie() || process.env.REACT_APP_API_TOKEN;
  set(tokenState, token);
};

export default initializeState;
