import { defineMessages } from 'react-intl';

export default defineMessages({
  UserAccountsButtonsSettingsLabel: {
    id: 'UserAccounts.Buttons.Settings.Label',
    defaultMessage: 'Settings',
  },
  AdminUserAccountsUrl: {
    id: 'Admin.UserAccounts.Url',
    defaultMessage: 'Configure user accounts',
  },
  UserAccountsButtonsAddUserLabel: {
    id: 'UserAccounts.Buttons.AddUser.Label',
    defaultMessage: 'Add User',
  },
  UserAccountsButtonsManageRolesLabel: {
    id: 'UserAccounts.Buttons.ManageRoles.Label',
    defaultMessage: 'Manage user roles',
  },
  UserAccountsAccountsTableColumnsNomLabel: {
    id: 'UserAccounts.AccountsTable.Columns.Nom.Label',
    defaultMessage: 'Last name',
  },
  UserAccountsAccountsTableColumnsFirstNameLabel: {
    id: 'UserAccounts.AccountsTable.Columns.FirstName.Label',
    defaultMessage: 'First Name',
  },
  UserAccountsAccountsTableColumnsEmailLabel: {
    id: 'UserAccounts.AccountsTable.Columns.Email.Label',
    defaultMessage: 'E-mail',
  },
  UserAccountsAccountsTableColumnsRoleLabel: {
    id: 'UserAccounts.AccountsTable.Columns.Role.Label',
    defaultMessage: 'Roles',
  },
  UserAccountsAccountsTableColumnsGroupLabel: {
    id: 'UserAccounts.AccountsTable.Columns.Group.Label',
    defaultMessage: 'Groups',
  },
  UserAccountsAccountsTableSharedNomLabel: {
    id: 'UserAccounts.AccountsTable.Shared.Nom.Label',
    defaultMessage: 'Shared documents',
  },
  UserAccountsAccountsTableColumnsActivationLabel: {
    id: 'UserAccounts.AccountsTable.Columns.Activation.Label',
    defaultMessage: 'Activation',
  },
  UserAccountsAccountsTableColumnsActionLabel: {
    id: 'UserAccounts.AccountsTable.Columns.Action.Label',
    defaultMessage: 'Action',
  },
  UserAccountsAccountLinkButtonLabel: {
    id: 'UserAccounts.AccountLinkButton.Label',
    defaultMessage: 'Edit',
  },
  UserAccountsSuccessCreateLabel: {
    id: 'UserAccounts.Create.Success.Label',
    defaultMessage: 'The account has been updated.',
  },
  UserAccountsEditAccountModalButtonsSaveLabel: {
    id: 'UserAccounts.EditAccountModal.Buttons.Save.Label',
    defaultMessage: 'Valid',
  },
  UserAccountsEditTitleLabel: {
    id: 'UserAccounts.Edit.Title.Label',
    defaultMessage: 'Edit a user account',
  },
  UserAccountsAccountsTableColumnsPhoneNumberLabel: {
    id: 'UserAccounts.AccountsTable.Columns.PhoneNumber.Label',
    defaultMessage: 'Phone number',
  },
  UserAccountsLockAccountButtonsSaveLabel: {
    id: 'UserAccounts.LockAccount.Buttons.Save.Label',
    defaultMessage: 'Disable',
  },
  UserAccountsLockAccountTitleText: {
    id: 'UserAccounts.LockAccount.Title.Text',
    defaultMessage: 'Are you sure you want to disable this user?',
  },
  UserAccountsLockAccountDescriptionText: {
    id: 'UserAccounts.LockAccount.Description.Text',
    defaultMessage:
      'The user will receive a notification that his account has been deactivated. They will no longer have access to the platform.',
  },
  UserAccountsLockAccountTitleLabel: {
    id: 'UserAccounts.LockAccount.Title.Label',
    defaultMessage: 'Disable',
  },
  UserAccountsUnLockAccountButtonsSaveLabel: {
    id: 'UserAccounts.UnlockAccount.Buttons.Save.Label',
    defaultMessage: 'Reactivate',
  },
  UserAccountsUnLockAccountTitleText: {
    id: 'UserAccounts.UnlockAccount.Title.Text',
    defaultMessage: 'Are you sure you want to reactivate this user?',
  },
  UserAccountsUnLockAccountDescriptionText: {
    id: 'UserAccounts.UnlockAccount.Description.Text',
    defaultMessage:
      'The user will receive a notification that his account has been reactivated. Access to the platform will be restored.',
  },
  UserAccountsUnLockAccountTitleLabel: {
    id: 'UserAccounts.UnlockAccount.Title.Label',
    defaultMessage: 'Reactivate',
  },
  UserAccountsCreateNewAccountTitleLabel: {
    id: 'UserAccounts.CreateNewAccount.Title.Label',
    defaultMessage: 'Add a new user account',
  },
  ExternalUserAccountsAccountsTableColumnsNomLabel: {
    id: 'ExternalUsersAccountsView.Table.TableHeader.LastName.Label',
    defaultMessage: 'Last name',
  },
  ExternalUserAccountsAccountsTableColumnsFirstNameLabel: {
    id: 'ExternalUsersAccountsView.Table.TableHeader.FirstName.Label',
    defaultMessage: 'First Name',
  },
  ExternalUserAccountsAccountsTableColumnsEmailLabel: {
    id: 'ExternalUsersAccountsView.Table.TableHeader.Email.Label',
    defaultMessage: 'E-mail',
  },
  ExternalUserAccountsAccountsTableColumnsPhoneNumberLabel: {
    id: 'ExternalUsersAccountsView.Table.TableHeader.Phone.Number.Label',
    defaultMessage: 'Phone number',
  },
  ExternalUserAccountsAccountsTableColumnsActionLabel: {
    id: 'ExternalUsersAccountsView.Table.TableHeader.Actions.Label',
    defaultMessage: 'Action',
  },
  ExternalAccountReactivateModalTitleLabel: {
    id: 'ExternalAccount.Reactivate.Modal.Title.Label',
    defaultMessage: 'Reactivate an external account',
  },
  ExternalAccountDisableModalTitleLabel: {
    id: 'ExternalAccount.Disable.Modal.Title.Label',
    defaultMessage: 'Disable an external account',
  },
  ExternalAccountModalButtonsReactivateLabel: {
    id: 'ExternalAccount.Modal.Buttons.Reactivate.Label',
    defaultMessage: 'Reactivate',
  },
  ExternalAccountModalButtonsDisableLabel: {
    id: 'ExternalAccount.Modal.Buttons.Disable.Label',
    defaultMessage: 'Disable',
  },
  ExternalAccountModalDescriptionsReactivateLabel: {
    id: 'ExternalAccount.Modal.Descriptions.Reactivate.Label',
    defaultMessage:
      'Are you sure you want to Reactivate this contributor s external account?',
  },
  ExternalAccountModalDescriptionsDisableLabel: {
    id: 'ExternalAccount.Modal.Descriptions.Disable.Label',
    defaultMessage:
      'Are you sure you want to Disable this contributor s external account?',
  },
  ExternalAccountsViewEmptyLabel: {
    id: 'External.Accounts.View.Empty.Label',
    defaultMessage: 'Users are empty',
  },
  ExternalAccountInvitationModalTitleLabel: {
    id: 'ExternalAccount.Invitation.Modal.Title.Label',
    defaultMessage: 'Send invitation link',
  },
  ExternalAccountModalDescriptionsinvitationText: {
    id: 'ExternalAccount.Modal.Descriptions.invitation.Text',
    defaultMessage:
      'Here is the link that was sent by email to your external contact:',
  },
  ExternalAccountsViewLockSuccess: {
    id: 'ExternalAccountsView.Lock.Success',
    defaultMessage: 'User has been locked successfully',
  },
  ExternalAccountLockModalTitleLabel: {
    id: 'ExternalAccount.Lock.Modal.Title.Label',
    defaultMessage: 'Lock an external account',
  },
  ExternalAccountModalButtonsLockLabel: {
    id: 'ExternalAccount.Modal.Buttons.Lock.Label',
    defaultMessage: 'Lock',
  },
  ExternalAccountModalDescriptionsLockText: {
    id: 'ExternalAccount.Modal.Descriptions.Lock.Text',
    defaultMessage:
      'Are you sure you want to lock this contributor s external account?',
  },
  ExternalAccountModalTitleLabel: {
    id: 'ExternalAccount.Modal.Title.Label',
    defaultMessage: 'Documents Shared With',
  },
  SharedModalDocumentLabel: {
    id: 'SharedModal.Document.Label',
    defaultMessage: 'Document',
  },
  SharedModalLocationsLabel: {
    id: 'SharedModal.Locations.Label',
    defaultMessage: 'Location(s)',
  },
  ExternalAccountsViewUnlockSuccess: {
    id: 'ExternalAccountsView.Unlock.Success',
    defaultMessage: 'User has been Unlocked successfully',
  },
  ExternalAccountUnLockModalTitleLabel: {
    id: 'ExternalAccount.UnLock.Modal.Title.Label',
    defaultMessage: 'Unlock an external account',
  },
  ExternalAccountModalButtonsUnlockLabel: {
    id: 'ExternalAccount.Modal.Buttons.Unlock.Label',
    defaultMessage: 'Unlock',
  },
  ExternalAccountModalDescriptionsUnlockText: {
    id: 'ExternalAccount.Modal.Descriptions.Unlock.Text',
    defaultMessage:
      'Are you sure you want to Unlock this contributor s external account?',
  },
});
