import { defineMessages } from 'react-intl';

export default defineMessages({
  AddTeam: {
    id: 'Buttons.Teams.Add',
    defaultMessage: 'Add a leader',
  },
  NoTeams: {
    id: 'Teams.NoResult',
    defaultMessage: 'No team for the current business unit',
  },
  AddProjectManager: {
    id: 'Buttons.ProjectManager.Add',
    defaultMessage: 'Add a project manager',
  },
  AddTeamDialogTitle: {
    id: 'Dialogs.Teams.Add.Title',
    defaultMessage: 'Add a leader',
  },
  UpdateTeamDialogTitle: {
    id: 'Dialogs.Teams.Update.Title',
    defaultMessage: 'Update a leader',
  },
  DeleteTeamDialogTitle: {
    id: 'Dialogs.Teams.Delete.Title',
    defaultMessage: 'Delete a leader',
  },
  DeleteTeamDialogMessage: {
    id: 'Dialogs.Teams.Delete.Message',
    defaultMessage:
      'You are about to delete the leader <b>{firstName} {lastName} ({externalId}) - {type, select, 1 {Offer leader} other {Market manager}}</b>',
  },
  TeamLeaderUserLabel: {
    id: 'Teams.LeaderUser.Label',
    defaultMessage: 'Collaborator (Name or LDAP number)',
  },
  TeamLeaderUserRequired: {
    id: 'Teams.LeaderUser.Required',
    defaultMessage: 'Collaborator is required',
  },
  TeamTypeLabel: {
    id: 'Teams.Type.Label',
    defaultMessage: 'Role of the leader',
  },
  TeamTypeRequired: {
    id: 'Teams.Type.Required',
    defaultMessage: 'Role of the leader is required',
  },
  TeamTypeOther: {
    id: 'Teams.Type.Other',
    defaultMessage: 'Other',
  },
  TeamTypeOfferLeader: {
    id: 'Teams.Type.OfferLeader',
    defaultMessage: 'Offer leader',
  },
  TeamTypeMarketManager: {
    id: 'Teams.Type.MarketManager',
    defaultMessage: 'Market manager',
  },
  TeamTitle: {
    id: 'Teams.TeamTitle',
    defaultMessage: '{displayName} - {role}',
  },
  TeamProjectManagerRequired: {
    id: 'Teams.ProjectManager.Required',
    defaultMessage: 'Project manager is required',
  },
});
