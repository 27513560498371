import useTenantPermissions from '@hooks/useTenantPermissions';
import tenantPermissions from '@utils/tenant-permissions';

export function useFormElevatedPermission() {
  const hasPermission = useTenantPermissions({
    permissions: tenantPermissions.FormsStatus,
  });
  return hasPermission;
}

export const statuses = {
  pending: 0,
  sent: 1,
  rejected: 2,
  validated: 3,
};

function useFormsReadOnly(status) {
  const hasPermission = useTenantPermissions({
    permissions: tenantPermissions.FormsStatus,
  });
  if (status === statuses.pending) return false;
  if (status === statuses.rejected) return false;
  if (status === statuses.validated) return true;
  if (status === statuses.sent) return !hasPermission;
  return true;
}

export default useFormsReadOnly;
