import React, {
  ReactNode,
  PropsWithChildren,
  ComponentProps,
  MouseEventHandler,
} from 'react';
import { FormattedMessage } from 'react-intl';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

type DialogActionsWithCloseProps = PropsWithChildren<{
  onClose: MouseEventHandler<HTMLButtonElement>;
  closeLabel?: ReactNode | null | undefined;
  disabled?: boolean | null | undefined;
  color?: ComponentProps<typeof Button>['color'] | null | undefined;
}>;

function DialogActionsWithClose({
  children,
  onClose,
  closeLabel = <FormattedMessage id="Verbs.Cancel" />,
  disabled,
  color,
}: DialogActionsWithCloseProps) {
  return (
    <DialogActions>
      <Button
        onClick={onClose}
        variant="outlined"
        color={color || 'error'}
        disabled={disabled || false}
      >
        {closeLabel}
      </Button>
      {children}
    </DialogActions>
  );
}

export default DialogActionsWithClose;
