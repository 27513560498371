import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import * as Yup from 'yup';

import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import FormikProvider from '@components/Formik/FormikProvider';
import FormikTextField from '@components/Formik/FormikTextField';
import { Box } from '@mui/material';
import { hostIsAuthenticatedSelector } from '@recoil/host-token';
import { signin } from '@utils/authentication';
import useNexityTheme from '@shells/nexity/hooks/useNexityTheme';

export const defaultSchema = Yup.object().shape({
  email: Yup.string()
    .ensure()
    .trim()
    .email('Signin.Fields.Email.Invalid')
    .required('Signin.Fields.Email.Required'),
  password: Yup.string()
    .ensure()
    .trim()
    .required('Signin.Fields.Password.Required'),
});

export const defaultValues = {
  email: '',
  password: '',
};

function SigninForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { name } = useNexityTheme();
  const tenantLabel = name === 'edouard-denis' ? 'Edouard Denis' : 'Nexity';
  const returnUrl = location.state?.from
    ? `${location.state.from.pathname}${location.state.from.search}${location.state.from.hash}`
    : '/dashboard';
  const [error, setError] = React.useState('');
  const setHostAuthenticationState = useSetRecoilState(
    hostIsAuthenticatedSelector
  );
  const onSubmitCallback = async form => {
    const result = await signin(form);
    if (!result.succeeded) {
      setError(result.error);
      setHostAuthenticationState(null);
    } else {
      setHostAuthenticationState(result);
      navigate('/signin/authorize', { state: location.state });
    }
  };
  return (
    <Box>
      <FormikProvider
        schema={defaultSchema}
        initialValues={defaultValues}
        onSubmit={onSubmitCallback}
      >
        {error !== '' && <ErrorMessage error={error} sx={{ mt: 1, mb: 2 }} />}
        <FormikTextField
          as="fastfield"
          autoFocus
          name="email"
          required
          autoComplete="email"
          label={<FormattedMessage id="Signin.Fields.Email.Label" />}
        />
        <FormikTextField
          as="fastfield"
          type="password"
          name="password"
          required
          autoComplete="current-password"
          label={<FormattedMessage id="Signin.Fields.Password.Label" />}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          <FormattedMessage id="Signin.Button" />
        </Button>
        <Button
          component={Link}
          to="/forgot-password"
          fullWidth
          variant="text"
          color="secondary"
          size="small"
          sx={{ mt: 2 }}
        >
          Mot de passe oublié ?
        </Button>
      </FormikProvider>
      <form action={`${process.env.REACT_APP_API_URI}/nexity/login`}>
        <input type="hidden" name="themeName" value={name} />
        <input type="hidden" name="returnUrl" value={returnUrl} />
        <Button
          type="submit"
          fullWidth
          variant="text"
          color="secondary"
          size="small"
          sx={{ mt: 2 }}
        >
          Connexion {tenantLabel}
        </Button>
      </form>
    </Box>
  );
}

export default SigninForm;
