import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector';

import { useTiptapContext } from './use-tiptap-context';

const defaultSelectionSelector = selection => selection;

export const useTiptapSelection = (
  selector = defaultSelectionSelector,
  isEqual
) => {
  const context = useTiptapContext();
  return useSyncExternalStoreWithSelector(
    context.subscribe,
    () => context.editor?.view?.state?.selection,
    null,
    selector,
    isEqual
  );
};
