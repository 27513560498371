import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { Button, Typography } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import useFetch from '@hooks/useFetch';
import Fetching from '@components/Fetching/Fetching';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import { Link, Route } from 'react-router-dom';
import NavigationModal from '@components/Modals/NavigationModal';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import useNavigateModal from '@hooks/useNavigateModal';
import AddAnnexFromDocumentModal from './components/Annexes/AddAnnexFromDocumentModal';
import AnnexList from './components/Annexes/AnnexList';
import DeleteAnnexModal from './components/Annexes/DeleteAnnexModal';
import EditAnnexModal from './components/Annexes/EditAnnexModal';
import AddAnnexFromFileModal from './components/Annexes/AddAnnexFromFileModal';

const getAddAnnexOptions = () => [
  {
    id: 'Contract.Annex.Button.New.Label',
    component: Link,
    to: `new`,
    state: { modal: true },
  },
  { type: 'divider' },
  {
    id: 'Contract.Annex.Button.Import.Label',
    component: Link,
    to: `import`,
    state: { modal: true },
  },
];

type ContractAnnexViewProps = {
  contractId: string;
};

function ContractAnnexView({ contractId }: ContractAnnexViewProps) {
  const intl = useIntl();
  const navigateModal = useNavigateModal();
  const { data, fetching, doFetch } = useFetch({
    url: `documents/${contractId}/annexes`,
    method: 'GET',
    cachePolicy: 'network-only',
  });
  const annexes = data?.annexes?.sort(
    (a: any, b: any) => a.orderId - b.orderId
  );

  const navigateToContract = () => navigateModal(`.`);

  const refresh = () => {
    navigateToContract();
    doFetch();
  };

  const onDelete = (annexId: string) => {
    navigateModal(`annex-modals/annex/delete/${annexId}`);
  };

  const { doFetch: doGetPdfLink } = useFetch({
    method: 'GET',
    lazy: true,
    onSuccess: (response: any) => {
      window.open(response?.data?.uri, '_blank', 'noreferrer');
    },
    onError: (response: any) => {
      const dataError = 'data' in response ? (response.data as any) : null;
      const errors = dataError?.Errors ?? dataError?.errors;
      if (errors) {
        const listError = Object.values(errors)
          .flat()
          .map((key: any) =>
            intl.formatMessage({
              id: `Errors.${typeof key === 'object' ? key?.message : key}`,
            })
          );
        toast.error(listError.join(', '), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    },
  });

  const onDownload = (annexId: string) => {
    doGetPdfLink({
      url: `documents/${contractId}/annexes/${annexId}/view`,
    });
  };

  const onUpdate = (annexId: string) => {
    navigateModal(`annex-modals/annex/update/${annexId}`);
  };
  const { doFetch: doUpdateOrder } = useFetch({
    url: `documents/${contractId}/annexes/order`,
    method: 'PUT',
    lazy: true,
    onSuccess: () => {
      toast.success(
        intl.formatMessage({ id: 'Contract.Annex.Toast.Reorder.Title' }),
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
    },
  });

  const onMove = (annexesOrdered: any[]) => {
    doUpdateOrder({
      body: {
        documentAnnexOrder: annexesOrdered.map(x => ({
          id: x.id,
          orderId: x.orderId,
        })),
      },
    });
  };

  const addAnnexOptions = useMemo(() => getAddAnnexOptions(), []);

  return (
    <>
      <Stack
        direction="column"
        sx={{ width: '100%', mb: 5, p: '24px 48px 24px 48px' }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Typography
            component="span"
            variant="caption"
            display="flex"
            alignItems="center"
          >
            <FormattedMessage id="Contract.Annew.List.Helper.Description" />{' '}
            <DragIndicatorIcon />
          </Typography>
          <Button
            sx={{ borderRadius: 10 }}
            variant="contained"
            onClick={() => navigateModal('annex-modals/annex/add')}
          >
            <FormattedMessage id="Contract.Annex.List.Button.Add" />
          </Button>
        </Stack>
        <Fetching fetching={fetching}>
          <AnnexList
            annexes={annexes}
            onDelete={onDelete}
            onDownload={onDownload}
            onUpdate={onUpdate}
            onMove={onMove}
          />
        </Fetching>
      </Stack>
      <DialogOutletRoutes path="annex-modals/*" onClose={navigateToContract}>
        <Route
          path="annex/add"
          element={
            <NavigationModal
              title={<FormattedMessage id="Contract.Annex.Dialog.Add.Title" />}
              buttons={addAnnexOptions}
            />
          }
        />
        <Route
          path="annex/add/new"
          element={
            <AddAnnexFromDocumentModal
              fetching={null}
              disabled={false}
              onSuccess={refresh}
              contractId={contractId}
            />
          }
        />
        <Route
          path="annex/add/import"
          element={
            <AddAnnexFromFileModal
              fetching={null}
              disabled={false}
              onSuccess={refresh}
              contractId={contractId}
            />
          }
        />
        <Route
          path="annex/delete/:annexId"
          element={
            <DeleteAnnexModal documentId={contractId} onSuccess={refresh} />
          }
        />
        <Route
          path="annex/update/:annexId"
          element={
            <EditAnnexModal documentId={contractId} onSuccess={refresh} />
          }
        />
      </DialogOutletRoutes>
    </>
  );
}

export default ContractAnnexView;
