import React from 'react';
import { isActive } from '@tiptap/core';
import { FormattedMessage } from 'react-intl';
import { alpha } from '@mui/system/colorManipulator';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import { useTiptapEditor } from '../use-tiptap-editor';
import { useTiptapState } from '../use-tiptap-state';

function ToolbarAlign() {
  const editor = useTiptapEditor();
  const left = useTiptapState(
    state => Boolean(state) && isActive(state, null, { textAlign: 'left' })
  );
  const center = useTiptapState(
    state => Boolean(state) && isActive(state, null, { textAlign: 'center' })
  );
  const right = useTiptapState(
    state => Boolean(state) && isActive(state, null, { textAlign: 'right' })
  );
  const justify = useTiptapState(
    state => Boolean(state) && isActive(state, null, { textAlign: 'justify' })
  );
  return (
    <ButtonGroup variant="text">
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.Align.Left" />}
      >
        <Button
          sx={{
            background: theme =>
              left ? alpha(theme.palette.primary.main, 0.35) : 'inherit',
          }}
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
        >
          <FormatAlignLeftIcon />
        </Button>
      </Tooltip>
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.Align.Center" />}
      >
        <Button
          sx={{
            background: theme =>
              center ? alpha(theme.palette.primary.main, 0.35) : 'inherit',
          }}
          onClick={() =>
            editor?.chain()?.focus()?.setTextAlign('center')?.run()
          }
        >
          <FormatAlignCenterIcon />
        </Button>
      </Tooltip>
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.Align.Right" />}
      >
        <Button
          sx={{
            background: theme =>
              right ? alpha(theme.palette.primary.main, 0.35) : 'inherit',
          }}
          onClick={() => editor?.chain()?.focus()?.setTextAlign('right')?.run()}
        >
          <FormatAlignRightIcon />
        </Button>
      </Tooltip>
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.Align.Justify" />}
      >
        <Button
          sx={{
            background: theme =>
              justify ? alpha(theme.palette.primary.main, 0.35) : 'inherit',
          }}
          onClick={() =>
            editor?.chain()?.focus()?.setTextAlign('justify')?.run()
          }
        >
          <FormatAlignJustifyIcon />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
}

export default ToolbarAlign;
