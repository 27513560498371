import { defineMessages } from 'react-intl';

export default defineMessages({
  IntegrationsViewButtonsSeeMoreLabel: {
    id: 'IntegrationsView.Buttons.SeeMore.Label',
    defaultMessage: 'See more',
  },
  IntegrationsViewCardsSalesForceDescription: {
    id: 'IntegrationsView.Cards.SalesForce.Description',
    defaultMessage: 'Synchronise your Seraphin documents with Salesforce',
  },
  IntegrationsViewCardsDocuSignDescription: {
    id: 'IntegrationsView.Cards.DocuSign.Description',
    defaultMessage:
      'Sign all your documents with your Docusign subscription directly in Seraphin',
  },
  IntegrationsViewCardsYouSignDescription: {
    id: 'IntegrationsView.Cards.YouSign.Description',
    defaultMessage:
      'Sign your documents with your Yousign subscription directly from Seraphin',
  },
  IntegrationsViewCardsLexPersonaDescription: {
    id: 'IntegrationsView.Cards.LexPersona.Description',
    defaultMessage:
      'Sign your documents with your Lex Persona subscription directly from Seraphin',
  },
});
