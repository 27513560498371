import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';

function RoleChip({ name, disabled, ...rest }) {
  return (
    <Chip
      icon={disabled ? <NoAccountsIcon /> : <PeopleAltIcon />}
      label={name}
      {...rest}
    />
  );
}

RoleChip.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default RoleChip;
