import MenuButton from '@components/MenuButton/MenuButton';
import {
  MenuButtonOptionDividerType,
  MenuButtonOptionItemType,
} from '@components/MenuButton/MenuButtonOption';
import UserChip from '@components/UserAvatar/UserChip';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NIL as NIL_UUID } from 'uuid';
import FormattedDateTime from '@components/FormattedDates/FormattedDateTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import CompareIcon from '@material-ui/icons/Compare';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { FormattedMessage, useIntl } from 'react-intl';

type VersionListItemProps = {
  version: any;
  isFirst: boolean;
  canContribute: boolean;
  onDownload: (versionId: string) => void;
  onRestore: (versionId: string) => void;
  onCompare: (versionId: string) => void;
};

function VersionListItem({
  version,
  isFirst,
  canContribute,
  onDownload,
  onRestore,
  onCompare,
}: VersionListItemProps) {
  const intl = useIntl();
  const options = useMemo<
    (MenuButtonOptionDividerType | MenuButtonOptionItemType)[]
  >(
    () => [
      {
        label: intl.formatMessage({
          id: 'Contract.Version.Menu.Download.Label',
        }),
        icon: <GetAppIcon />,
        disabled: true,
        onClick: () => onDownload(version.id),
      },
      ...(!isFirst
        ? [
            {
              label: intl.formatMessage({
                id: 'Contract.Version.Menu.Compare.Label',
              }),
              icon: <CompareIcon />,
              disabled: true,
              onClick: () => onCompare(version.id),
            },
          ]
        : []),
      ...(!isFirst
        ? [
            {
              label: intl.formatMessage({
                id: 'Contract.Version.Menu.Restore.Label',
              }),
              icon: <SettingsBackupRestoreIcon />,
              disabled: !canContribute,
              onClick: () => onRestore(version.id),
            },
          ]
        : []),
    ],
    [version, isFirst, onDownload, onCompare, onRestore, intl, canContribute]
  );

  return (
    <Card
      variant="outlined"
      sx={{
        bgcolor: 'transparent',
        color: 'text.primary',
        mb: 1,
        '&:last-child': { mb: 0 },
      }}
    >
      <CardContent sx={{ py: 1, px: 2, '&:last-child': { pb: 1, mb: 0 } }}>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '20px',
              pb: 1,
              pt: 1,
            }}
          >
            {isFirst && (
              <FormattedMessage id="Contract.Version.Default.Label" />
            )}
            {version.id === NIL_UUID && !isFirst && (
              <FormattedMessage id="Contract.Version.Initial.Label" />
            )}
            {version.id !== NIL_UUID && !isFirst && (
              <FormattedDateTime value={version.creationTime} />
            )}
          </Typography>
          <MenuButton iconButton options={options}>
            <MoreVertIcon color="primary" />
          </MenuButton>
        </Stack>
        <Stack sx={{ mt: 1 }}>
          <Stack direction="row" gap={1}>
            <Typography sx={{ fontSize: '11px', pb: 1 }}>
              {isFirst && (
                <FormattedMessage id="Contract.Version.Default.Label" />
              )}
              {version.id === NIL_UUID && !isFirst && (
                <FormattedMessage id="Contract.Version.Initial.Label" />
              )}
              {version.id !== NIL_UUID && !isFirst && (
                <>{version.name.toString()}</>
              )}
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <UserChip
              key={version?.creatorUser?.id}
              pictureUrl={undefined}
              style={undefined}
              {...version?.creatorUser}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default VersionListItem;
