import { Transform } from 'prosemirror-transform';

/**
 * Returns a new `Transform` based on all steps of the passed transactions.
 */
export default function combineTransactionSteps(oldDoc, transactions = []) {
  const transform = new Transform(oldDoc);

  transactions.forEach(transaction => {
    transaction.steps.forEach(step => {
      transform.step(step);
    });
  });

  return transform;
}
