import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

function FormsAlerts({ status, reason }) {
  const alerts = {
    2: <Alert severity="error">Formulaire rejeté : {reason}</Alert>,
    3: <Alert severity="success">Formulaire validé</Alert>,
  };

  const alert = alerts[status || 0];

  return alert ? (
    <Box mt={2} mb={4}>
      {alert}
    </Box>
  ) : null;
}

export default FormsAlerts;
