import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import AvTimerIcon from '@mui/icons-material/AvTimer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

import Fetching from '@components/Fetching/Fetching';

import useBreadcrumbs from '@hooks/useBreadcrumbs';
import useFetch from '@hooks/useFetch';

import messages from '@i18n/keys';

import FormsToCompleteTitle from './FormsToCompleteTitle';
import CivilView from './Civil/CivilView';
import FamilyView from './Family/FamilyView';
import FinancingView from './Financing/FinancingView';
import ProjectView from './Project/ProjectView';

const STATUS_ICON = [
  <AvTimerIcon fontSize="small" />,
  <HourglassBottomIcon fontSize="small" />,
  <CancelIcon fontSize="small" />,
  <CheckCircleIcon fontSize="small" />,
];

const TYPE_MESSAGES = {
  civil: messages.nexity.folders.formstocomplete.civilstate,
  family: messages.nexity.folders.formstocomplete.familysituation,
  financing: messages.nexity.folders.formstocomplete.financing,
  project: messages.nexity.folders.formstocomplete.project,
};

const formsViewComponents = {
  civil: CivilView,
  family: FamilyView,
  financing: FinancingView,
  project: ProjectView,
};

const breadcrumbs = [{ to: '/', message: messages.header.menu.drive }];

function FormsView() {
  const { id, type } = useParams();
  const { data, fetching, doFetch } = useFetch({
    url: `/nexity/dashboard/folders/${id}`,
    cachePolicy: 'network-only',
  });

  const breadcrumbsMemo = React.useMemo(() => {
    if (data) {
      return [
        ...breadcrumbs,
        { to: `/folders/${id}`, label: data.folder.name },
        { to: '', message: TYPE_MESSAGES[type] },
      ];
    }
    return breadcrumbs;
  }, [data, id, type]);
  useBreadcrumbs(breadcrumbsMemo);

  const preventModifications = Boolean(data?.preventModifications);
  const canInvalidateForm = Boolean(data?.canInvalidateForm);
  const areClientInfoValidated = Boolean(data?.space?.clientInfoValidated);
  const FormsViewComponent = formsViewComponents[type];

  // Message displayed on the form validation modal
  const validationMessageByFormType = {
    project: `Attention : la signature sur place avec votre conseiller nécessite que le vendeur ait une adresse email valide et un compte DocuSign actif. En validant le formulaire, l'adresse email vendeur 
    ${data ? data.prospect.Dealer.Email : 'N/A'} 
    sera donc utilisée pour superviser la signature client.`,
  };

  // Condition to display the validation message on the form validation modal
  const validationMessageConditionValuesByFormType = {
    project: {
      signatureOption: 'in-person',
    },
  };

  return (
    <Fetching fetching={fetching} sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          flexGrow: 1,
          display: 'grid',
          gridTemplateColumns: '175px 1fr 175px',
        }}
      >
        <Box sx={{ p: 2, gridColumn: 2 }}>
          <FormsToCompleteTitle />
          <Tabs
            value={type}
            variant="fullWidth"
            TabIndicatorProps={{ sx: { display: 'none' } }}
          >
            <Tab
              component={Link}
              label={
                <FormattedMessage
                  id={messages.nexity.folders.formstocomplete.civilstate}
                />
              }
              to={`/folders/${id}/forms/civil`}
              icon={STATUS_ICON[data?.forms?.civil?.status ?? 0]}
              iconPosition="end"
              value="civil"
              sx={{
                minHeight: '40px',
                py: 0,
                px: 2,
                bgcolor: 'grey.100',
                color: 'grey.400',
              }}
            />
            <Tab
              component={Link}
              label={
                <FormattedMessage
                  id={messages.nexity.folders.formstocomplete.familysituation}
                />
              }
              to={`/folders/${id}/forms/family`}
              icon={STATUS_ICON[data?.forms?.family?.status ?? 0]}
              iconPosition="end"
              value="family"
              sx={{
                minHeight: '40px',
                py: 0,
                px: 2,
                bgcolor: 'grey.100',
                color: 'grey.400',
              }}
            />
            <Tab
              component={Link}
              label={
                <FormattedMessage
                  id={messages.nexity.folders.formstocomplete.financing}
                />
              }
              to={`/folders/${id}/forms/financing`}
              icon={STATUS_ICON[data?.forms?.financing?.status ?? 0]}
              iconPosition="end"
              value="financing"
              sx={{
                minHeight: '40px',
                py: 0,
                px: 2,
                bgcolor: 'grey.100',
                color: 'grey.400',
              }}
            />
            <Tab
              component={Link}
              label={
                <FormattedMessage
                  id={messages.nexity.folders.formstocomplete.project}
                />
              }
              to={`/folders/${id}/forms/project`}
              icon={STATUS_ICON[data?.forms?.project?.status ?? 0]}
              iconPosition="end"
              value="project"
              sx={{
                minHeight: '40px',
                py: 0,
                px: 2,
                bgcolor: 'grey.100',
                color: 'grey.400',
              }}
            />
          </Tabs>
          <FormsViewComponent
            id={id}
            refresh={doFetch}
            form={data?.forms[type]}
            preventModifications={preventModifications}
            areClientInfoValidated={areClientInfoValidated}
            canInvalidateForm={canInvalidateForm}
            fetchData={data}
            validationMessage={validationMessageByFormType[type]}
            validationMessageConditionValues={
              validationMessageConditionValuesByFormType[type]
            }
          />
        </Box>
      </Box>
    </Fetching>
  );
}

export default FormsView;
