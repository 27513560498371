import React, { useEffect, useMemo } from 'react';
import Alert from '@mui/material/Alert';
import { useSearchParams, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import Button from '@mui/material/Button';

import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import FormikProvider from '@components/Formik/FormikProvider';
import FormikTextField from '@components/Formik/FormikTextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useFetch from '@hooks/useFetch';
import Fetching from '@components/Fetching/Fetching';
import useNexityTheme from '@shells/nexity/hooks/useNexityTheme';

export const defaultSchema = yup.object().shape({
  newPassword: yup.string().ensure().trim().required('Generic.Fields.Required'),
  confirmPassword: yup
    .string()
    .ensure()
    .trim()
    .required('Generic.Fields.Required')
    .test({
      message: 'ResetPassword.Fields.ConfirmPassword.Different',
      test: (confirmPassword, { parent }) =>
        confirmPassword === parent.newPassword,
    }),
});

export const defaultValues = {
  newPassword: '',
  confirmPassword: '',
};

const hasError = (fetchError, errorMessage) =>
  Object.values(fetchError?.data?.errors ?? {})
    .flat()
    .some(em => em === errorMessage);

function ResetPasswordForm() {
  const theme = useNexityTheme();
  const [params] = useSearchParams();
  const {
    email,
    emailConfirmationToken,
    passwordResetToken,
    tenantId,
    folderId,
  } = useMemo(
    () => ({
      email: params.get('email') ?? '',
      emailConfirmationToken: params.get('emailConfirmationToken') ?? '',
      passwordResetToken: params.get('passwordResetToken') ?? '',
      tenantId: params.get('tenantId') ?? '',
      folderId: params.get('folderId') ?? '',
    }),
    [params]
  );

  const resetPassword = useFetch({
    method: 'POST',
    url: 'nexity/authentication/password/reset',
  });

  const confirmEmail = useFetch({
    method: 'POST',
    url: 'authentication/email/confirm',
  });

  const resendEmail = useFetch({
    method: 'POST',
    url: 'nexity/authentication/send-creation-mail',
  });

  useEffect(() => {
    if (
      !confirmEmail.didFetch &&
      emailConfirmationToken &&
      !confirmEmail.fetching
    ) {
      confirmEmail.doFetch({ body: { email, token: emailConfirmationToken } });
    }
  }, [email, emailConfirmationToken, confirmEmail]);

  const onSubmitCallback = values => {
    resetPassword.doFetch({
      body: {
        ...values,
        email,
        token: passwordResetToken,
        themeName: theme.name,
      },
    });
  };

  const onResendClick = () => {
    resendEmail.doFetch({ body: { tenantId, folderId, email } });
  };

  const wasPasswordResetted =
    resetPassword.didFetch && !resetPassword.fetching && !resetPassword.error;

  const canResendEmail = useMemo(
    () =>
      hasError(confirmEmail.error, 'EmailConfirmationInvalidToken') ||
      hasError(resetPassword.error, 'ResetPasswordInvalidToken'),
    [confirmEmail.error, resetPassword.error]
  );

  const wasEmailResent =
    resendEmail.didFetch && !resendEmail.fetching && !resendEmail.error;

  return (
    <Box>
      <FormikProvider
        schema={defaultSchema}
        initialValues={defaultValues}
        onSubmit={onSubmitCallback}
      >
        <Fetching fetching={confirmEmail.fetching || resendEmail.fetching}>
          {!wasEmailResent && (
            <ErrorMessage
              error={confirmEmail.error || resendEmail.error}
              sx={{ mt: 1, mb: 2 }}
            />
          )}
          {wasEmailResent && (
            <Alert severity="success" sx={{ mt: 1, mb: 2 }}>
              Un email vous a été renvoyé.
            </Alert>
          )}
          {!confirmEmail.error && !wasEmailResent && (
            <>
              {Boolean(emailConfirmationToken) && (
                <Alert severity="success" sx={{ mt: 1, mb: 2, mx: 'auto' }}>
                  Votre email a été confirmé.
                </Alert>
              )}

              <ErrorMessage error={resetPassword.error} sx={{ mt: 1, mb: 2 }} />
              {wasPasswordResetted && (
                <Alert severity="success" sx={{ mt: 1, mb: 2 }}>
                  Votre mot de passe a été changé.
                </Alert>
              )}

              {!canResendEmail && (
                <>
                  {!resetPassword.fetching &&
                    (!resetPassword.didFetch || resetPassword.error) && (
                      <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
                        <div>Le mot de passe doit avoir :</div>
                        <div> - au moins 12 caractères ;</div>
                        <div> - une majuscule ;</div>
                        <div> - une minuscule ;</div>
                        <div> - un chiffre ;</div>
                        <div> - un caractère spécial.</div>
                      </Alert>
                    )}

                  {wasPasswordResetted ? (
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      component={Link}
                      to="/"
                    >
                      <FormattedMessage id="ResetPassword.GoToSignIn" />
                    </Button>
                  ) : (
                    <>
                      <FormikTextField
                        as="fastfield"
                        type="password"
                        name="newPassword"
                        required
                        autoComplete="new-password"
                        label={
                          <FormattedMessage id="ResetPassword.Fields.NewPassword.Label" />
                        }
                        disabled={resetPassword.fetching}
                      />
                      <FormikTextField
                        as="fastfield"
                        type="password"
                        name="confirmPassword"
                        required
                        autoComplete="confirm-password"
                        label={
                          <FormattedMessage id="ResetPassword.Fields.ConfirmPassword.Label" />
                        }
                        disabled={resetPassword.fetching}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        disabled={resetPassword.fetching}
                      >
                        <FormattedMessage id="ResetPassword.Button" />
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {canResendEmail && !wasEmailResent && (
            <Stack direction="row" justifyContent="center">
              <Button fullWidth onClick={onResendClick}>
                Renvoyer un email
              </Button>
            </Stack>
          )}
        </Fetching>
      </FormikProvider>
    </Box>
  );
}

export default ResetPasswordForm;
