import { Button, Stack } from '@mui/material';
import { Link, Route, useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import { AddAccountModalWithPermissions } from '@views/Administration/UserAccounts/components/AddAccountModal';
import { useCallback } from 'react';
import useFetch from '@hooks/useFetch';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { WorkflowContributorAccess } from '@views/constants';
import withPermissions from '@components/withTenantPermissions';
import { contributorCreatePermission } from '@components/WorkflowGeneric/WorkflowContributors/WorkflowContributorEditor';

export default function ContractExternalContributor({
  workflowStepId,
  refresh,
  workFlowAccess,
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const navigateToWorkflow = () => navigate(`.`);
  const { contractId } = useParams();
  const { doFetch: doPost } = useFetch({
    method: 'POST',
    onSuccess: () => {
      toast.success(
        intl.formatMessage({ id: 'Workflow.Contributor.Modal.Add.Success' }),
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      navigateToWorkflow();
      if (refresh) {
        refresh();
      }
    },
    onError: response => {
      const dataError = 'data' in response ? response.data : null;
      const errors = dataError?.Errors ?? dataError?.errors;
      if (errors) {
        const listError = Object.values(errors)
          .flat()
          .map(key =>
            intl.formatMessage({
              id: `Errors.${typeof key === 'object' ? key?.message : key}`,
            })
          );
        toast.error(listError.join(', '), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(intl.formatMessage({ id: 'Errors.ErrorOccured' }), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    },
  });

  const handleCreateNewAccount = useCallback(
    values => {
      const { groupIds, roleIds, ...finalValues } = values;
      doPost({
        url: `contracts/${contractId}/workflow/steps/${workflowStepId}/contributors`,
        body: {
          ...finalValues,
        },
      });
    },
    [contractId, workflowStepId, doPost]
  );

  const ButtonWithPermission = withPermissions(Button);

  return (
    <>
      <Stack sx={{ py: '5px' }} gap={2}>
        <ButtonWithPermission
          permissions={contributorCreatePermission}
          component={Link}
          to="contributor-modals/create"
          sx={{ textDecoration: 'none', textTransform: 'unset' }}
          variant="text"
          startIcon={<AddIcon />}
        >
          Ajouter un contributeur
        </ButtonWithPermission>
      </Stack>
      <DialogOutletRoutes
        path="contributor-modals/*"
        onClose={navigateToWorkflow}
      >
        <Route
          path="create"
          element={
            <AddAccountModalWithPermissions
              permissions={contributorCreatePermission}
              onSubmit={handleCreateNewAccount}
              hideEmptyList="true"
              showWorkflowAccess="true"
              initWorkflowAccessValue={
                workFlowAccess != null
                  ? WorkflowContributorAccess[
                      workFlowAccess[0]?.replace('-r', 'R')
                    ]
                  : 0
              }
              disabledWorkflowAccess
              title={
                <FormattedMessage id="Workflow.Contributor.Modal.Add.Title" />
              }
            />
          }
        />
      </DialogOutletRoutes>
    </>
  );
}
