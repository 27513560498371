import { useMemo, useCallback } from 'react';
import { useSnackbar } from 'notistack';

export default function useCustomizedSnackbar() {
  const snackbar = useSnackbar();
  const { enqueueSnackbar } = snackbar;

  const enqueueCustomizedSnackbar = useCallback(
    (message, options) =>
      enqueueSnackbar(message, {
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        ...options,
      }),
    [enqueueSnackbar]
  );

  return useMemo(
    () => ({
      ...snackbar,
      enqueueSnackbar: enqueueCustomizedSnackbar,
    }),
    [snackbar, enqueueCustomizedSnackbar]
  );
}
