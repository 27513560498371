import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useFetch from '@hooks/useFetch';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { FormattedMessage } from 'react-intl';
import { bold } from '@utils/chunks';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import FormattedDateTime from '@components/FormattedDates/FormattedDateTime';

type ActivityConfiguration = {
  getMessageDescriptorId: (data: { data: object }) => string;
};

const messageDescriptorIdPerType: Record<number, ActivityConfiguration> = {
  101: {
    getMessageDescriptorId: activity => {
      if (
        !('creatorUserName' in activity.data && activity.data.creatorUserName)
      ) {
        return 'delegatorFullName' in activity.data &&
          typeof activity.data.delegatorFullName === 'string'
          ? 'Notifications.Types.DocumentCreatedWithUserAndDelegation.Text'
          : 'Notifications.Types.DocumentCreatedWithUser.Text';
      }
      return 'Notifications.Types.DocumentCreated.Text';
    },
  },
  104: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.DocumentDuplicatedWithDelegation.Text'
        : 'Notifications.Types.DocumentDuplicated.Text',
  },
  201: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.WorkflowRejectedWithDelegation.Text'
        : 'Notifications.Types.WorkflowRejected.Text',
  },
  202: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.WorkflowStepValidatedWithDelegation.Text'
        : 'Notifications.Types.WorkflowStepValidated.Text',
  },
  203: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.WorkflowStepInvalidatedWithDelegation.Text'
        : 'Notifications.Types.WorkflowStepInvalidated.Text',
  },
  206: {
    getMessageDescriptorId: activity => {
      if (
        !(
          'currentStepHasManyContributors' in activity.data &&
          activity.data.currentStepHasManyContributors
        )
      ) {
        return 'delegatorFullName' in activity.data &&
          typeof activity.data.delegatorFullName === 'string'
          ? 'Notifications.Types.WorkflowStepCompletedOneUserActivityWithDelegation.Text'
          : 'Notifications.Types.WorkflowStepCompletedOneUserActivity.Text';
      }
      return 'Notifications.Types.WorkflowStepCompletedMultiUsersActivity.Text';
    },
  },
  209: {
    getMessageDescriptorId: activity => {
      if (
        !(
          'lastStepHasManyContributors' in activity.data &&
          activity.data.lastStepHasManyContributors
        )
      ) {
        return 'delegatorFullName' in activity.data &&
          typeof activity.data.delegatorFullName === 'string'
          ? 'Notifications.Types.WorkflowCompletedOneUserWithDelegationActivity.Text'
          : 'Notifications.Types.WorkflowCompletedOneUserActivity.Text';
      }
      return 'Notifications.Types.WorkflowCompletedMultiUsersActivity.Text';
    },
  },
  210: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.WorkflowStepAddedWithDelegation.Text'
        : 'Notifications.Types.WorkflowStepAdded.Text',
  },
  211: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.WorkflowStepRemovedWithDelegation.Text'
        : 'Notifications.Types.WorkflowStepRemoved.Text',
  },
  220: {
    getMessageDescriptorId: () => 'Notifications.Types.SignerRefused.Text',
  },
  401: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.ContentCommentedWithDelegationActivity.Text'
        : 'Notifications.Types.ContentCommentedActivity.Text',
  },
  402: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.ContentRevisedWithDelegationActivity.Text'
        : 'Notifications.Types.ContentRevisedActivity.Text',
  },
  403: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.ClosedClauseContentCommentedWithDelegationActivity.Text'
        : 'Notifications.Types.ClosedClauseContentCommentedActivity.Text',
  },
  404: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.ClosedClauseContentRevisedWithDelegationActivity.Text'
        : 'Notifications.Types.ClosedClauseContentRevisedActivity.Text',
  },
  405: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.ClosedClauseResolvedWithDelegationActivity.Text'
        : 'Notifications.Types.ClosedClauseResolvedActivity.Text',
  },
  406: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.ClosedClauseAssignedWithDelegationActivity.Text'
        : 'Notifications.Types.ClosedClauseAssignedActivity.Text',
  },
  407: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.ClosedClauseReassignedWithDelegationActivity.Text'
        : 'Notifications.Types.ClosedClauseReassignedActivity.Text',
  },
  408: {
    getMessageDescriptorId: activity =>
      'delegatorFullName' in activity.data &&
      typeof activity.data.delegatorFullName === 'string'
        ? 'Notifications.Types.AbandonContractWithDelegationActivity.Text'
        : 'Notifications.Types.AbandonContractActivity.Text',
  },
};

type NotificationResponse = {
  id: string;
  type: number;
  data: object;
  publicationDate: string;
};

function Activity({ activity }: { activity: NotificationResponse }) {
  const messageDescriptorId = messageDescriptorIdPerType[activity.type];
  if (!messageDescriptorId) return null;
  return (
    <ErrorBoundary>
      <Box
        sx={{
          bgcolor: 'modal.main',
          borderRadius: '8px',
          p: '16px',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2" sx={{ fontSize: '11px' }}>
            <FormattedDateTime value={activity.publicationDate} />
          </Typography>
        </Stack>
        <Typography
          variant="body2"
          sx={{
            fontSize: '12px',
            lineHeight: '20px',
            mb: 1,
            mt: 1,
          }}
        >
          <FormattedMessage
            id={messageDescriptorId.getMessageDescriptorId(activity)}
            values={{
              ...activity.data,
              b: bold,
            }}
          />
        </Typography>
      </Box>
    </ErrorBoundary>
  );
}

function DocumentActivies({ documentId }: { documentId: string }) {
  const activitiesFetch = useFetch({
    url: `documents/${documentId}/activities`,
  });

  const activities = activitiesFetch.data?.activities;

  return (
    <Box
      style={{
        maxHeight: 'calc(100vh - 205px)',
        marginLeft: '-24px',
        marginRight: '-24px',
        marginBottom: '-24px',
        padding: '24px',
        overflow: 'auto',
      }}
    >
      <ErrorMessage error={activitiesFetch.error} />
      <Stack spacing={2}>
        {activities?.map((activity: NotificationResponse) => (
          <Activity key={activity.id} activity={activity} />
        ))}
        {activities == null ||
          (activities.length <= 0 && (
            <Typography sx={{ fontSize: '12px', color: 'secondary.main' }}>
              <FormattedMessage
                id="SharedMenu.Activity.NoData"
                defaultMessage="Aucune  activité"
              />
            </Typography>
          ))}
      </Stack>
    </Box>
  );
}

export default DocumentActivies;
