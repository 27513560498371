import SelectPopoverFilters from './SelectPopoverFilters';
import TextPopoverFilters from './TextPopoverFilters';
import AutoCompletePopoverFilters from './AutoCompletePopoverFilters';
import CheckboxPopoverFilters from './CheckboxPopoverFilters';
import NumberPopoverFilters from './NumberPopoverFilters';
import TimeStampPopoverFilters from './TimeStampPopoverFilters';
import { PopoverFiltersProps, SearchFilterType } from './helpers/types';

function PopoverFilters(props: PopoverFiltersProps) {
  const { filter } = props;
  const { type } = filter;
  return (
    <>
      {type === SearchFilterType.Select && <SelectPopoverFilters {...props} />}
      {type === SearchFilterType.Text && <TextPopoverFilters {...props} />}
      {type === SearchFilterType.Number && <NumberPopoverFilters {...props} />}
      {type === SearchFilterType.AutoComplete && (
        <AutoCompletePopoverFilters {...props} />
      )}
      {type === SearchFilterType.Checkbox && (
        <CheckboxPopoverFilters {...props} />
      )}
      {type === SearchFilterType.Timestamp && (
        <TimeStampPopoverFilters {...props} />
      )}
    </>
  );
}

export default PopoverFilters;
