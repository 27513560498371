import React from 'react';
import PropTypes from 'prop-types';

import { GUID_EMPTY } from '@utils/contributor-helpers';

import AuthorChip from './AuthorChip';
import RoleChip from './RoleChip';
import UserChip from './UserChip';

function ContributorChip({
  type,
  entityId,
  authorLabel = null,
  entity = {},
  ...rest
}) {
  if (type === 1 && entityId === GUID_EMPTY) {
    return <AuthorChip name={authorLabel} {...entity} {...rest} />;
  }
  if (type === 1) {
    return <UserChip {...entity} {...rest} />;
  }
  if (type === 2) {
    return <RoleChip {...entity} {...rest} />;
  }
  return null;
}

ContributorChip.propTypes = {
  type: PropTypes.number.isRequired,
  entityId: PropTypes.string.isRequired,
  authorLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  entity: PropTypes.shape({}),
};

ContributorChip.defaultProps = {
  authorLabel: null,
  entity: {},
};

export default ContributorChip;
