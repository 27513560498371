import React from 'react';
import { isActive } from '@tiptap/core';
import { FormattedMessage } from 'react-intl';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';

import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

import ColorPicker from '@components/Color/ColorPicker';
import { useTiptapEditor } from '../../use-tiptap-editor';
import { useTiptapSelection } from '../../use-tiptap-selection';

function ButtonBackgroundColor() {
  const editor = useTiptapEditor();
  const selection = useTiptapSelection();
  const [updatedSelection, setUpdateStatus] = React.useState(false);
  if (updatedSelection) {
    setUpdateStatus(false);
  }
  const color = React.useMemo(() => {
    if (!selection) return undefined;
    const node = selection.$anchor.doc.nodeAt(
      selection.constructor.name === 'CellSelection'
        ? selection.$anchorCell.pos
        : selection.from
    );
    if (node) {
      setUpdateStatus(true); // set true to force reset color in ColorPicker
    }
    return selection.constructor.name === 'CellSelection'
      ? node?.attrs.backgroundColor
      : node?.marks.find(m => m.type.name === 'textStyle')?.attrs
          .backgroundColor;
  }, [selection]);
  return (
    <PopupState variant="popper" popupId="font-size-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={
              <FormattedMessage id="Editor.Toolbar.Command.BackgroundColor" />
            }
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <FormatColorFillIcon />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <Paper elevation={8}>
                  <ColorPicker
                    color={color ?? '#FFFFFF'}
                    forceUpdateColor={updatedSelection}
                    onReset={() => {
                      if (
                        editor.view.state.selection.constructor.name ===
                        'CellSelection'
                      ) {
                        // set cell border color
                        editor
                          .chain()
                          .focus()
                          .setCellAttribute('backgroundColor', null)
                          .run();
                      } else {
                        editor.chain().focus().setBackgroundColor(null).run();
                      }
                      popupState.close();
                    }}
                    onSubmit={color => {
                      if (
                        editor.view.state.selection.constructor.name ===
                        'CellSelection'
                      ) {
                        // set cell border color
                        editor
                          .chain()
                          .focus()
                          .setCellAttribute('backgroundColor', color)
                          .run();
                      } else {
                        editor.chain().focus().setBackgroundColor(color).run();
                      }
                      popupState.close();
                    }}
                  />
                </Paper>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonBackgroundColor;
