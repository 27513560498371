import { useEffect, useMemo, useState } from 'react';
import useBreadcrumbs from '@hooks/useBreadcrumbs';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import useFetch from '@hooks/useFetch';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import FileIcon from '@components/Icons/FileIcon';
import Fetching from '@components/Fetching/Fetching';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import FolderIcon from '@mui/icons-material/Folder';
import FormattedDateTime from '@components/FormattedDates/FormattedDateTime';
import Chip from '@mui/material/Chip';
import messages from '@i18n/keys';
import useDebounced from '@hooks/useDebounced';
import SearchWithFilters from '@components/Search/SearchWithFilters';
import useFilters from '../Drive/useFilters';

const folderChipConfigurations = [
  { label: 'Entretien', color: 'info' },
  { label: 'Complétion', color: 'info' },
  { label: 'Validation', color: 'secondary' },
  { label: 'Contrat', color: 'primary' },
  { label: 'Signature', color: 'warning' },
  { label: 'Terminé', color: 'success' },
];

function FolderChip({ timelineStepIndex }) {
  const stepConfiguration = folderChipConfigurations[timelineStepIndex];
  if (!stepConfiguration) return null;
  const { label, color } = stepConfiguration;
  return <Chip label={label} color={color} size="small" />;
}

function FolderRow({ folder }) {
  return (
    <Grid
      container
      component={Link}
      to={`/folders/${folder.id}`}
      sx={{
        p: 1.5,
        cursor: 'pointer',
        '&:hover': {
          color: '#4f1fc6',
          bgcolor: 'primary.100',
        },
        textDecoration: 'unset',
      }}
    >
      <Grid item xs={6}>
        <Stack direction="row" alignItems="center" gap={1}>
          <FolderIcon fontSize="small" />
          <Typography color="GrayText" sx={{ fontSize: '12px' }}>
            {folder.name}
          </Typography>
          <FolderChip timelineStepIndex={folder.timelineStepIndex} />
        </Stack>
      </Grid>

      <Grid item xs={3}>
        <Typography color="GrayText" sx={{ fontSize: '12px' }}>
          {folder.buyersNames.join(', ')}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color="GrayText" sx={{ fontSize: '12px' }}>
          <FormattedDateTime value={folder.lastModificationTime} />
        </Typography>
      </Grid>
    </Grid>
  );
}

function DashboardFolders({ folders, fetching, title }) {
  return (
    <Box mt={5}>
      <Grid
        container
        sx={{ borderBottom: '1px solid', borderColor: 'grey.200', my: 2 }}
      >
        <Grid item xs={6}>
          <Typography color="GrayText" sx={{ fontWeight: 700 }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color="GrayText" sx={{ fontSize: '12px' }}>
            Nom du prospect
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color="GrayText" sx={{ fontSize: '12px' }}>
            Date modification
          </Typography>
        </Grid>
      </Grid>
      <Fetching fetching={fetching}>
        {folders?.length ? (
          folders.map(folder => <FolderRow key={folder.id} folder={folder} />)
        ) : (
          <Stack alignItems="center" justifyContent="center">
            <FileIcon />
            <Typography color="GrayText" sx={{ fontSize: '12px' }}>
              Aucun dossier
            </Typography>
          </Stack>
        )}
      </Fetching>
    </Box>
  );
}

const breadcrumbs = [
  {
    to: '/dashboard',
    message: messages.header.menu.dashboard,
  },
];

function DashboardView() {
  useBreadcrumbs(breadcrumbs);
  const navigate = useNavigate();

  const [searchRequest, setSearchRequest] = useState('');
  const debouncedSearchRequest = useDebounced(searchRequest, 1000);
  const [advancedSearchRequest, setAdvancedSearchRequest] = useState({});
  const [finalSearchRequest, setFinalSearchRequest] = useState({});

  const { data, fetching, error } = useFetch({
    url: `/nexity/dashboard/advanced`,
    method: 'GET',
  });

  const onChange = event => {
    setSearchRequest(event.target.value);
  };

  const handleSearch = () => {
    setFinalSearchRequest(advancedSearchRequest);
  };

  useEffect(() => {
    if (searchRequest !== debouncedSearchRequest) return;
    if (searchRequest === '' && JSON.stringify(finalSearchRequest) === '{}')
      return;
    navigate({
      pathname: '/',
      search: `?query=${searchRequest}&filters=${encodeURIComponent(
        JSON.stringify(finalSearchRequest)
      )}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchRequest, finalSearchRequest]);

  const foldersComponents = useMemo(
    () => (
      <>
        <DashboardFolders
          title="A valider"
          folders={data?.urgentFolders}
          fetching={fetching}
        />
        <DashboardFolders
          title="En cours"
          folders={data?.pendingFolders}
          fetching={fetching}
        />
        <DashboardFolders
          title="Terminés"
          folders={data?.completedFolders}
          fetching={fetching}
        />
      </>
    ),
    [data, fetching]
  );

  const filters = useFilters();

  return (
    <Stack direction="column" sx={{ width: '100%' }}>
      <Grid container justifyContent="center">
        <Grid item xs={8}>
          <Box sx={{ mt: 4, mx: 2 }}>
            <SearchWithFilters
              onChange={onChange}
              filters={filters}
              onSearch={handleSearch}
              advancedSearchRequest={advancedSearchRequest}
              setAdvancedSearchRequest={setAdvancedSearchRequest}
              filterTitleSx={{ fontSize: '16px', fontWeight: 700 }}
            />
          </Box>
          {error && <ErrorMessage error={error} />}
          {foldersComponents}
        </Grid>
      </Grid>
    </Stack>
  );
}

export default DashboardView;
