import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Fetching from '@components/Fetching/Fetching';
import DialogTitleWithClose from './DialogTitleWithClose';

interface ConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  titleComponent?: ReactNode; // Allows elements, null, or undefined
  actionComponent?: ReactNode; // Allows elements, null, or undefined
  closeLabel?: ReactNode; // Allows elements, null, or undefined
  closeColor?: 'primary' | 'error';
  children?: ReactNode;
  fetching?: boolean;
}

function ConfirmDialog({
  open,
  onClose,
  titleComponent = null,
  actionComponent = null,
  closeLabel = <FormattedMessage id="Verbs.Cancel" />,
  closeColor,
  children,
  fetching,
  ...rest
}: ConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" {...rest}>
      {titleComponent && (
        <DialogTitleWithClose onClose={onClose}>
          {titleComponent}
        </DialogTitleWithClose>
      )}
      <Fetching fetching={fetching}>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color={closeColor ?? 'error'}
            variant="outlined"
          >
            {closeLabel}
          </Button>
          {actionComponent}
        </DialogActions>
      </Fetching>
    </Dialog>
  );
}

ConfirmDialog.defaultProps = {
  titleComponent: null,
  actionComponent: null,
  closeLabel: <FormattedMessage id="Verbs.Cancel" />,
};

export default ConfirmDialog;
