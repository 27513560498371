export function requiredWhen(node, key, value) {
  return node.when(key, {
    is: value,
    then: node.required('Generic.Fields.Required'),
    otherwise: node,
  });
}

export function requiredOrStripWhen(node, key, value) {
  return node.when(key, {
    is: value,
    then: node.required('Generic.Fields.Required'),
    otherwise: node.strip(),
  });
}

export function stripWhenNot(node, key, value) {
  return node.when(key, {
    is: value,
    then: node,
    otherwise: node.strip(),
  });
}

export function requiredIf(node, required) {
  if (required) {
    return node.required('Generic.Fields.Required');
  }

  return node;
}
