import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useMessage from '@rottitime/react-hook-message-event';
import { useRecoilValue } from 'recoil';
import { localeState } from '@recoil/locale';
import Fetching from '@components/Fetching/Fetching';
import useFetch from '@hooks/useFetch';

function EmbeddedView({ path }) {
  const navigate = useNavigate();
  const locale = useRecoilValue(localeState);
  const theme = useTheme();
  const { data, fetching } = useFetch({
    url: '/authentication/authenticate/current-combined',
  });
  const primaryColor = theme.palette.primary.main.replace('#', '');
  const secondaryColor = theme.palette.secondary.main.replace('#', '');

  useMessage('navigation', (send, payload) => {
    if ('to' in payload) {
      navigate(payload.to);
    }
  });

  const baseUri =
    process.env.REACT_APP_EMBEDDED_ROUTER_URI ||
    process.env.REACT_APP_EMBEDDED_URI ||
    '';

  const url = `${baseUri.replace(
    'iframe.html',
    'router.html'
  )}?path=${path}&locale=${locale}&primaryColor=${primaryColor}&secondaryColor=${secondaryColor}&dev=${
    process.env.REACT_APP_EDITOR_DEV ?? 'false'
  }&token=`;

  return (
    <Fetching fetching={fetching} sx={{ flexGrow: 1 }}>
      {data ? (
        <iframe
          src={`${url}${data?.token.value}`}
          title="embedded"
          width="100%"
          height="100%"
          style={{ border: 0 }}
        />
      ) : null}
    </Fetching>
  );
}

export default EmbeddedView;
