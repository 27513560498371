import { useCallback, useMemo, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import { refresh } from '@utils/authentication';
import {
  tenantTokenSelector,
  tenantAuthenticationSelector,
} from '@recoil/tenant-token';
import {
  hostIsAuthenticatedSelector,
  hostTokenSelector,
} from '@recoil/host-token';
import { useFetchContext } from '@contexts/FetchContext';
import { useFetch as useLibFetch } from './useLibFetch';

const urlPrefix = process.env.REACT_APP_API_URI;

type DoFetch<T = any> = (options: any) => Promise<T>;

export type ResponseWithData<T> = Response & { data: T | null };

type OnSuccess<T> = (response: ResponseWithData<T>) => void;

const processUrl = (urlProp?: string | null) => {
  if (urlProp === undefined) return undefined;
  if (urlProp === null) return null;
  const slash = urlProp.startsWith('/') ? '' : '/';
  return `${urlPrefix}${slash}${urlProp}`;
};

const singledPromise = <Arguments extends unknown[], Result>(
  getPromise: (...args: Arguments) => Promise<Result>
) => {
  let promise: Promise<Result> | null = null;
  let promiseTime: Date | null = null;
  return (...args: Arguments) => {
    const currentTime = new Date();
    if (promise) {
      if (promiseTime) {
        const deltaTimeMilliseconds =
          currentTime.valueOf() - promiseTime.valueOf();
        const minDeltaTimeMilliseconds = 30 * 1000;
        if (deltaTimeMilliseconds < minDeltaTimeMilliseconds) {
          return promise;
        }
      }
    }

    promise = getPromise(...args);
    promiseTime = currentTime;

    return promise.finally(() => {
      promise = null;
      promiseTime = null;
    });
  };
};

const singledRefresh = singledPromise(refresh);

function useGenericFetch<T = any>(props: any) {
  const ref = useRef<{ didFetch: boolean; onSuccess: OnSuccess<T> | null }>({
    didFetch: false,
    onSuccess: null,
  });

  const optionsRef = useRef({});
  const doFetchRef = useRef<DoFetch<T> | null>();
  const navigate = useNavigate();
  const token = useRecoilValue(props.tokenSelector) as any;
  const localState = useRecoilValue(props.authenticationSelector) as any;
  const setLocalState = useSetRecoilState(props.authenticationSelector) as any;

  function getRequestKey({
    url = '',
    method = '',
    responseType = '',
    body = '',
  } = {}) {
    return [url, method.toUpperCase(), token, responseType, body].join('||');
  }

  const url = processUrl(props?.url);
  const cachePolicy = props?.cachePolicy || 'network-only';

  ref.current.onSuccess = props?.onSuccess;
  const onSuccess = useCallback(response => {
    optionsRef.current = {};
    ref.current.onSuccess?.(response);
  }, []);

  const onErrorProp = props?.onError;
  // TODO: check if onError can call stale methods
  const onError = useCallback(
    async responseOrError => {
      const isError = responseOrError instanceof Error;
      if (isError && responseOrError.name === 'AbortError') {
        return;
      }

      let ignoreErrorAndRetry = false;

      if (
        responseOrError.status === 401 &&
        responseOrError.data?.error !== 'WrongTokenType'
      ) {
        let tokenForRetry;
        const apiToken = await singledRefresh(
          localState.token.value,
          localState.refreshToken.value
        );
        if (!apiToken.succeeded) {
          const now = new Date();
          const storedToken = getRecoil(props.authenticationSelector) as any;
          const storedTokenExpires = storedToken?.token?.expires
            ? new Date(storedToken.token.expires)
            : null;
          const isStoredTokenValidAfterApiCall =
            storedTokenExpires && now < storedTokenExpires;

          if (!isStoredTokenValidAfterApiCall) {
            optionsRef.current = {};
            setLocalState(null);
            navigate('/signin');
            tokenForRetry = null;
            ignoreErrorAndRetry = false;
          } else {
            // Here, we skipped a race condition if multiple refresh requests happen at the same time.
            tokenForRetry = storedToken;
            ignoreErrorAndRetry = true;
          }
        } else {
          setLocalState(apiToken);
          tokenForRetry = apiToken;
          ignoreErrorAndRetry = true;
        }

        if (ignoreErrorAndRetry) {
          let headers: Record<string, string> = {
            Authorization: `Bearer ${tokenForRetry.token.value}`,
          };
          if (!(props?.noContentType ?? false)) {
            headers['Content-Type'] = 'application/json';
          }
          headers = { ...headers, ...props?.init?.headers };
          if (doFetchRef.current) {
            doFetchRef.current({
              ...optionsRef.current,
              headers,
            });
          }
        }
      }

      if (!ignoreErrorAndRetry) {
        onErrorProp?.(responseOrError);
      }
    },
    [
      onErrorProp,
      localState?.refreshToken?.value,
      localState?.token?.value,
      navigate,
      setLocalState,
      props.authenticationSelector,
      props?.noContentType,
      props?.init,
    ]
  );

  let headers: Record<string, string> = {
    Authorization: `Bearer ${token}`,
  };
  if (!(props?.noContentType ?? false)) {
    headers['Content-Type'] = 'application/json';
  }
  if (props?.hostLevel) {
    headers['X-SmartClause-Host-Enabled'] = 'true';
  }
  if (props?.hostTenants) {
    headers['X-SmartClause-Tenants-Enabled'] = 'true';
    if (props?.hostTenantsRequested) {
      headers['X-SmartClause-Tenants-Requested'] =
        // eslint-disable-next-line no-nested-ternary
        typeof props.hostTenantsRequested === 'string'
          ? props.hostTenantsRequested
          : Array.isArray(props.hostTenantsRequested)
          ? props.hostTenantsRequested.join(',')
          : '';
    }
  }
  if (props?.tenantParent) {
    headers['X-SmartClause-Tenant-Parent'] = 'true';
  }
  headers = { ...headers, ...props?.init?.headers };

  const result = useLibFetch({
    ...props,
    url,
    cachePolicy,
    init: {
      ...props?.init,
      headers,
    },
    onSuccess,
    onError,
    getRequestKey,
  });

  const innerDoFetch = result.doFetch;
  const doFetch = useCallback(
    (options?: any) => {
      ref.current.didFetch = true;
      optionsRef.current = options || {};
      const processedUrl = processUrl(options?.url);
      return innerDoFetch({
        ...options,
        url: processedUrl,
      });
    },
    [innerDoFetch]
  );

  useEffect(() => {
    doFetchRef.current = doFetch;
  }, [doFetch]);

  ref.current.didFetch ||= Boolean(result.fetching);
  const { didFetch } = ref.current;
  const succeeded = didFetch && !result.fetching && !result.error;
  const errored = didFetch && !result.fetching && Boolean(result.error);

  return useMemo(
    () =>
      ({
        ...result,
        doFetch,
        didFetch,
        succeeded,
        errored,
        data: result.data as T | null,
      } as const),
    [result, doFetch, didFetch, succeeded, errored]
  );
}

export type UseFetchProps<T> = {
  onSuccess?(response: ResponseWithData<T>): void;
} & Record<string, any>;

export default function useFetch<T = any>(props: UseFetchProps<T>) {
  const contextValue = useFetchContext();
  const host = props?.host ?? contextValue.host;
  const hostLevel = props?.hostLevel ?? contextValue.hostLevel;
  const hostTenants = props?.hostTenants ?? contextValue.hostTenants;
  const hostTenantsRequested =
    props?.hostTenantsRequested ?? contextValue.hostTenantsRequested;
  const tenantParent = props?.tenantParent ?? contextValue.tenantParent;
  return useGenericFetch<T>({
    ...props,
    hostLevel,
    hostTenants,
    hostTenantsRequested,
    tenantParent,
    tokenSelector: host ? hostTokenSelector : tenantTokenSelector,
    authenticationSelector: host
      ? hostIsAuthenticatedSelector
      : tenantAuthenticationSelector,
  });
}

export type UseFetchValue<T = any> = ReturnType<typeof useFetch<T>>;
