import React from 'react';
import { Editor } from '@tiptap/react';

import PureEditorContent from './PureEditorContent';
import { useTiptapContext } from './use-tiptap-context';
import {
  transformPastedHTML,
  handlePaste,
} from './schema/extensions/helpers/clean-pasted-html';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';

function TiptapContentInner({
  variant = 'page',
  extensions = [],
  content,
  editable = true,
  autofocus = false,
  editorProps = {},
  ...props
}) {
  const { editor, setEditor, ReactSyncExternalStorePlugin } =
    useTiptapContext();
  React.useEffect(
    function initEditor() {
      if (editor === undefined) {
        const instance = new Editor({
          extensions,
          content,
          editable,
          autofocus,
          injectCSS: true,
          editorProps: {
            ...editorProps,
            attributes: {
              class: 'scm-editor',
            },
            plugins: [ReactSyncExternalStorePlugin],
            handlePaste: handlePaste,
            transformPastedHTML: transformPastedHTML,
          },
          ...props,
        });
        setEditor(instance);
      }
    },
    [editor]
  );
  if (!!!editor) return null;
  return <PureEditorContent variant={variant} />;
}

function TiptapContent(props) {
  return (
    <ErrorBoundary>
      <TiptapContentInner {...props} />
    </ErrorBoundary>
  )
}

export default TiptapContent;
