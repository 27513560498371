import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { valueFromPath } from '@utils/json-path';

function MuiFileField({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  inputProps,
  accept,
  label,
  onChanged,
  ...props
}) {
  const { name } = field;
  const error = name.includes('.') ? valueFromPath(errors, name) : errors[name];
  const touch = name.includes('.')
    ? valueFromPath(touched, name)
    : touched[name];
  const hasError = Boolean(touch) && Boolean(error);
  return (
    <TextField
      fullWidth
      margin="normal"
      autoComplete="off"
      InputProps={{
        sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <Button
              disableElevation
              sx={{ borderRadius: 0 }}
              variant="contained"
              component="label"
            >
              <FormattedMessage id="Verbs.Choose" defaultMessage="Choose" />
              <input
                type="file"
                hidden
                {...inputProps}
                accept={accept?.join(',') ?? inputProps?.accept}
                onChange={event => {
                  const file = event.currentTarget.files?.[0];
                  setFieldTouched(name, true);
                  setFieldValue(name, file);
                  onChanged?.(event, file);
                }}
              />
            </Button>
          </InputAdornment>
        ),
      }}
      {...props}
      label={label}
      value={field.value?.name ?? ''}
      onChange={() => {}}
      onBlur={() => {}}
      error={hasError}
      helperText={
        hasError && (
          <FormattedMessage
            id={error}
            values={{
              label: label ?? '',
              value: field?.value ?? '',
            }}
          />
        )
      }
      sx={{ flexGrow: 1 }}
    />
  );
}

function FormikFileField(props) {
  return <Field component={MuiFileField} {...props} />;
}

export default FormikFileField;
