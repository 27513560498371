import useIntlMessageExists from '@hooks/useMessageExists';
import { ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';

export const defaultFallbackId = 'Errors.ErrorOccuredFallback';

export default function FormattedMessageWithFallback({
  id,
  code,
  fallbackId = defaultFallbackId,
  ...props
}: ComponentProps<typeof FormattedMessage> & {
  code?: string;
  fallbackId?: string;
}) {
  const messageExist = useIntlMessageExists(id);
  const fallbackExist = useIntlMessageExists(fallbackId);
  return !messageExist && fallbackExist ? (
    <FormattedMessage
      id={fallbackId}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      values={{ code: code || id }}
    />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormattedMessage id={id} {...props} />
  );
}
