export enum DocumentLinkTypes {
  None = 0,
  IsLinkedTo = 1,
  IsAmendmentOf = 2,
  HasForAmendment = 3,
  IsMasterAgreementOf = 4,
  HasForMasterAgreement = 5,
  IsAnnexOf = 6,
  HasForAnnex = 7,
}

export const DocumentLinkTypesOptions = [
  {
    value: DocumentLinkTypes.None,
    title: 'DriveView.DocumentLinks.DocumentTypeName.None',
    messageDescriptorId: 'DriveView.DocumentLinks.Type.Choose',
  },
  {
    value: DocumentLinkTypes.IsLinkedTo,
    title: 'DriveView.DocumentLinks.DocumentTypeName.LinkedDocument',
    messageDescriptorId: 'DriveView.DocumentLinks.Type.IsLinkedTo',
  },
  {
    value: DocumentLinkTypes.IsAmendmentOf,
    title: 'DriveView.DocumentLinks.DocumentTypeName.OriginalContract',
    messageDescriptorId: 'DriveView.DocumentLinks.Type.IsAmendmentOf',
  },
  {
    value: DocumentLinkTypes.HasForAmendment,
    title: 'DriveView.DocumentLinks.DocumentTypeName.Amendment',
    messageDescriptorId: 'DriveView.DocumentLinks.Type.HasForAmendment',
  },
  {
    value: DocumentLinkTypes.IsMasterAgreementOf,
    title: 'DriveView.DocumentLinks.DocumentTypeName.ApplicationAgreement',
    messageDescriptorId: 'DriveView.DocumentLinks.Type.IsMasterAgreementOf',
  },
  {
    value: DocumentLinkTypes.HasForMasterAgreement,
    title: 'DriveView.DocumentLinks.DocumentTypeName.MasterAgreement',
    messageDescriptorId: 'DriveView.DocumentLinks.Type.HasForMasterAgreement',
  },
  {
    value: DocumentLinkTypes.IsAnnexOf,
    title: 'DriveView.DocumentLinks.DocumentTypeName.MainContract',
    messageDescriptorId: 'DriveView.DocumentLinks.Type.IsAnnexOf',
  },
  {
    value: DocumentLinkTypes.HasForAnnex,
    title: 'DriveView.DocumentLinks.DocumentTypeName.Annex',
    messageDescriptorId: 'DriveView.DocumentLinks.Type.HasForAnnex',
  },
] as const;
