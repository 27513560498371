import React, { lazy } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import TenantAuthenticatedRoute from '@components/ProtectedRoutes/TenantAuthenticatedRoute';
import NotFoundView from '@views/NotFoundView/NotFoundView';
import { RolesComponent } from '@components/withTenantRoles';
import EmbeddedView from '@views/EmbeddedView';
import DashboardView from './views/Dashboard/DashboardView';
import DriveView from './views/Drive/DriveView';
import SigninView from './views/Signin/SigninView';
import ResetPasswordView from './views/ResetPassword/ResetPasswordView';
import ForgotPasswordView from './views/ForgotPassword/ForgotPasswordView';
import FoldersView from './views/Folders/FolderView';
import FormsView from './views/Folders/FormsToComplete/FormsView';
import {
  nexityAdminViewRoles,
  nexityDashboardViewRoles,
  nexityStatisticsViewRoles,
} from './nexity-constants';
import StatisticsView from './views/Statistics/StatisticsView';
import TenantRedirect from '../../views/Tenant/TenantRedirect';

const AdministrationView = lazy(() =>
  import('./views/Administration/AdministrationView')
);
const ContractualPacksView = lazy(() =>
  import('./views/Administration/ContractualPacks/ContractualPacksView')
);
const TemplatesView = lazy(() =>
  import('@views/Administration/Templates/TemplatesView')
);
const TemplateCompletionCustomView = lazy(() =>
  import('./views/Administration/Templates/TemplateCompletionCustomView')
);

const LegalNoticeView = lazy(() =>
  import('./views/LegalNotice/LegalNoticeView')
);
const AuthenticatedLayout = lazy(() => import('@shells/nexity/nexity-layout'));

const templatesBreadcrumbs = [
  { to: '/admin', message: 'Administration' },
  {
    to: '/admin/templates',
    message: 'Paramétrer les templates',
  },
];

function NexityRoutes() {
  return (
    <Routes>
      <Route
        path="signin/*"
        element={<SigninView sendSmsUrl="/nexity/authentication/send-sms" />}
      />
      <Route path="reset-password/*" element={<ResetPasswordView />} />
      <Route path="forgot-password/*" element={<ForgotPasswordView />} />
      <Route
        path="tenant/:tenantId/*"
        element={<TenantRedirect defaultRedirect="/" />}
      />
      <Route path="*" element={<TenantAuthenticatedRoute requireMfa />}>
        <Route path="*" element={<AuthenticatedLayout />}>
          <Route path="folders/:id/forms/:type/*" element={<FormsView />} />
          <Route path="folders/:id/*" element={<FoldersView />} />
          <Route
            path="dashboard/*"
            element={
              <RolesComponent
                roles={nexityDashboardViewRoles}
                fallback={<Navigate replace to="/" />}
              >
                <DashboardView />
              </RolesComponent>
            }
          />
          <Route index element={<DriveView />} />
          <Route
            path="admin/*"
            element={
              <RolesComponent
                roles={nexityAdminViewRoles}
                fallback={<Navigate replace to="/" />}
              >
                <Outlet />
              </RolesComponent>
            }
          >
            <Route index element={<AdministrationView />} />
            <Route
              path="contractual-packs/*"
              element={<ContractualPacksView />}
            />
            <Route
              path="templates/*"
              element={
                <TemplatesView
                  breadcrumbs={templatesBreadcrumbs}
                  TemplateCompletionView={TemplateCompletionCustomView}
                />
              }
            />
            <Route
              path="users/*"
              element={<EmbeddedView path="/administration/users/internals" />}
            />
            <Route
              path="external/*"
              element={<EmbeddedView path="/administration/users/externals" />}
            />
          </Route>
          <Route
            path="statistics/*"
            element={
              <RolesComponent
                roles={nexityStatisticsViewRoles}
                fallback={<Navigate replace to="/" />}
              >
                <StatisticsView />
              </RolesComponent>
            }
          />
          <Route path="legal-notice/*" element={<LegalNoticeView />} />
          <Route path="*" element={<NotFoundView />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default NexityRoutes;
