import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormikTextField from '@components/Formik/FormikTextField';
import FormikSelect from '@components/Formik/FormikSelect';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { termSheetValueTypeOptions } from '@utils/api/types';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import FormikCheckbox from '@components/Formik/FormikCheckbox';
import FormikDatePicker from '@components/Formik/FormikDatePicker';
import FormikTimePicker from '@components/Formik/FormikTimePicker';
import FormikDateTimePicker from '@components/Formik/FormikDateTimePicker';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import BorderedIcon from '../../MangeData/components/BorderedIcon';

function getFormikField(values, label, name) {
  switch (values.type) {
    case 'Number':
      return (
        <FormikTextField
          sx={{ marginTop: 0 }}
          as="field"
          label={label}
          name={name}
          type="number"
        />
      );
    case 'Text':
      return (
        <FormikTextField
          sx={{ marginTop: 0 }}
          as="field"
          name={name}
          label={label}
          type="text"
        />
      );
    case 'Boolean':
      return (
        <FormikCheckbox
          sx={{ marginTop: 0 }}
          as="field"
          name={name}
          label={label}
        />
      );
    case 'Timestamp':
      return (
        <FormikDateTimePicker
          sx={{ marginTop: 0 }}
          TextFieldProps={{ sx: { marginTop: 0 } }}
          as="field"
          label={label}
          name={name}
        />
      );
    case 'DateOnly':
      return (
        <FormikDatePicker
          sx={{ marginTop: 0 }}
          TextFieldProps={{ sx: { marginTop: 0 } }}
          as="field"
          label={label}
          name={name}
        />
      );
    case 'TimeOnly':
      return (
        <FormikTimePicker
          sx={{ marginTop: 0 }}
          TextFieldProps={{ sx: { marginTop: 0 } }}
          as="field"
          label={label}
          name={name}
        />
      );
    default:
      return (
        <FormikTextField
          sx={{ marginTop: 0 }}
          as="field"
          name={name}
          label={label}
          type="text"
          disabled
        />
      );
  }
}

export default function EditElementToTermSheetForm({
  disabled,
  title,
  actionButtonText,
  defaultActionButtonText,
  error,
}) {
  const { values } = useFormikContext();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const label = (
    <FormattedMessage
      id="ManageDataView.Dialogs.Create.Fields.Content.Label"
      defaultMessage="Content"
    />
  );
  const name = 'content';

  return (
    <DialogWithClose
      titleLabel={title}
      dialogActions={
        <Button
          onClick={() => {
            if (!values.icon) {
              enqueueSnackbar(
                formatMessage({
                  id: 'CreatePivotForm.Icon.Validate.Error',
                }),
                {
                  variant: 'error',
                  autoHideDuration: 4000,
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                }
              );
            }
          }}
          type="submit"
          disabled={disabled}
        >
          <FormattedMessage
            id={actionButtonText}
            defaultMessage={defaultActionButtonText}
          />
        </Button>
      }
    >
      <Stack gap={2} key={disabled}>
        <Stack gap={1}>
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '0.7875rem',
                color: 'secondary.main',
              }}
            >
              <FormattedMessage
                id="ManageDataView.Dialogs.Create.Fields.Icon.Label"
                defaultMessage="Icon"
              />
            </Typography>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '0.7875rem',
                color: 'error.main',
                ml: '2px',
              }}
            >
              *
            </Typography>
          </Stack>
          <BorderedIcon disabled />
        </Stack>

        <FormikTextField
          required
          label={
            <FormattedMessage
              id="ManageDataView.Dialogs.Create.Fields.Name.Label"
              defaultMessage="Name"
            />
          }
          name="name"
          color="secondary"
          margin="dense"
          readOnly
        />
        <FormikSelect
          required
          label={
            <FormattedMessage
              id="ManageDataView.Dialogs.Create.Fields.DataType.Label"
              defaultMessage="Type"
            />
          }
          name="type"
          FormControlProps={{
            color: 'secondary',
            margin: 'dense',
          }}
          margin="dense"
          disabled
        >
          {termSheetValueTypeOptions.map(type => (
            <MenuItem key={type.title} value={type.title}>
              <FormattedMessage
                id={type.messageDescriptorId}
                defaultMessage={type.title}
              />
            </MenuItem>
          ))}
        </FormikSelect>
        {getFormikField(values, label, name)}
        <ErrorMessage error={error} />
      </Stack>
    </DialogWithClose>
  );
}
