import { defineMessages } from 'react-intl';

export default defineMessages({
  HelpCenterHtmlTitle: {
    id: 'HelpCenter.Html.Title',
    defaultMessage: 'Introductory text',
  },
  HelpCenterDocumentTitle: {
    id: 'HelpCenter.Document.Title',
    defaultMessage: 'Documents',
  },
  HelpCenterDocumentButtonAdd: {
    id: 'HelpCenter.Document.Buttons.Add',
    defaultMessage: 'Add a document',
  },
  HelpCenterDocumentDialogsCreateTitle: {
    id: 'HelpCenter.Document.Dialogs.Create.Title',
    defaultMessage: 'Add an help center document',
  },
  HelpCenterDocumentDialogsUpdateTitle: {
    id: 'HelpCenter.Document.Dialogs.Update.Title',
    defaultMessage: 'Modify an help center document',
  },
  HelpCenterDocumentDialogsDeleteTitle: {
    id: 'HelpCenter.Document.Dialogs.Delete.Title',
    defaultMessage: 'Delete an help center document',
  },
  HelpCenterDocumentDialogsDeleteMessage: {
    id: 'HelpCenter.Document.Dialogs.Delete.Message',
    defaultMessage:
      'You are about to delete the document <b>{name} - {role}</b>',
  },
  HelpCenterDocumentFieldsNameLabel: {
    id: 'HelpCenter.Document.Fields.Name.Label',
    defaultMessage: 'Document name',
  },
  HelpCenterDocumentFieldsNameRequired: {
    id: 'HelpCenter.Document.Name.Required',
    defaultMessage: 'Document name is required',
  },
  HelpCenterDocumentRoleEverybody: {
    id: 'HelpCenter.Document.Role.Everybody',
    defaultMessage: 'Everybody',
  },
  HelpCenterDocumentFieldsRoleLabel: {
    id: 'HelpCenter.Document.Fields.Role.Label',
    defaultMessage: 'Role',
  },
  HelpCenterDocumentFieldsRoleRequired: {
    id: 'HelpCenter.Document.Fields.Role.Required',
    defaultMessage: 'Role is required',
  },
  HelpCenterDocumentFieldsFileLabel: {
    id: 'HelpCenter.Document.Fields.File.Label',
    defaultMessage: 'Document to upload',
  },
  HelpCenterDocumentFieldsFileRequired: {
    id: 'HelpCenter.Document.Fields.File.Required',
    defaultMessage: 'Document to upload is required',
  },
  HelpCenterDocumentFieldsFileInvalid: {
    id: 'HelpCenter.Document.Fields.File.InvalidFormat',
    defaultMessage: 'Document to upload must be a PDF',
  },
  HelpCenterMenuReleases: {
    id: 'HelpCenter.Menu.Releases',
    defaultMessage: 'Release notes',
  },
  HelpCenterMenuHelp: {
    id: 'HelpCenter.Menu.Help',
    defaultMessage: 'Help center',
  },
  HelpCenterReleaseInternalTitle: {
    id: 'HelpCenter.Release.Internal.Title',
    defaultMessage: 'Release note for Leroy Merlin employees',
  },
  HelpCenterReleaseExternalTitle: {
    id: 'HelpCenter.Release.External.Title',
    defaultMessage: 'Release note for Suppliers',
  },
  HelpCenterReleaseButtonsPublish: {
    id: 'HelpCenter.Release.Buttons.Publish',
    defaultMessage: 'Publish',
  },
});
