import React from 'react';
import { FormattedMessage } from 'react-intl';
import { findParentNode } from '@tiptap/core';
import TableCell from '@tiptap/extension-table-cell';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ColorPicker from '@components/Color/ColorPicker';
import { useTiptapEditor } from '../../use-tiptap-editor';
import { useTiptapSelection } from '../../use-tiptap-selection';

function ButtonTableColor() {
  const editor = useTiptapEditor();
  const selection = useTiptapSelection();
  const [updatedSelection, setUpdateStatus] = React.useState(false);
  if (updatedSelection) {
    setUpdateStatus(false);
  }
  const color = React.useMemo(() => {
    if (!selection) return undefined;
    const node =
      selection.constructor.name === 'CellSelection'
        ? selection.$anchor.doc.nodeAt(selection.$anchorCell.pos)
        : findParentNode(node => node.type.name === TableCell.name)(selection)
            ?.node;
    if (node) {
      setUpdateStatus(true); // set true to force reset color in ColorPicker
    }
    return node?.attrs.backgroundColor;
  }, [selection]);
  return (
    <PopupState variant="popper" popupId="table-cell-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={
              <FormattedMessage id="Editor.Toolbar.Command.BackgroundColor" />
            }
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <FormatColorFillIcon />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <Paper elevation={8}>
                  <ColorPicker
                    color={color ?? '#FFFFFF'}
                    forceUpdateColor={updatedSelection}
                    onReset={() => {
                      editor
                        .chain()
                        .focus()
                        .setCellAttribute('backgroundColor', null)
                        .run();
                      popupState.close();
                    }}
                    onSubmit={color => {
                      editor
                        .chain()
                        .focus()
                        .setCellAttribute('backgroundColor', color)
                        .run();
                      popupState.close();
                    }}
                  />
                </Paper>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonTableColor;
