import DialogWithClose from '@components/Dialog/DialogWithClose';
import useFetch from '@hooks/useFetch';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { Stack } from '@mui/system';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RuleIcon from '@mui/icons-material/Rule';
import NumbersIcon from '@mui/icons-material/Numbers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import {
  SmartClauseTenantResponse,
  TermSheetElementResponse,
  TermSheetElementsListResponse,
} from '@api/types';
import Fetching from '@components/Fetching/Fetching';
import { useOutletContext } from 'react-router-dom';
import StyledSearch from '@components/Search/StyledSearch';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import useDebounced from '@hooks/useDebounced';
import { getTermSheetElementDisplayName } from '@views/MangeData/components/utils';
import { useIntl } from 'react-intl';

interface TermSheetFilterModalProps {
  onSelectedFilter?: (item: TermSheetElementResponse) => void;
  selectedFilters?: string[];
}

const filterTypeTermSheet = [
  {
    type: 'Text',
    label: 'Texte',
    icon: <FormatSizeIcon />,
  },
  {
    type: 'Number',
    label: 'Numérique',
    icon: <NumbersIcon />,
  },
  {
    type: 'Boolean',
    label: 'Vrai/Faux',
    icon: <RuleIcon />,
  },
  {
    type: 'Timestamp',
    label: 'Timestamp',
    icon: <DateRangeIcon />,
  },
  {
    type: 'DateOnly',
    label: 'DateOnly',
    icon: <DateRangeIcon />,
  },
] as const;

function TermSheetFilterModal({
  onSelectedFilter,
  selectedFilters,
}: TermSheetFilterModalProps) {
  const intl = useIntl();
  const { close } = useOutletContext<{ close: any }>();
  const [termSheets, setTermSheets] = useState<TermSheetElementResponse[]>([]);
  const [filteredTermSheets, setFilteredTermSheets] = useState<
    TermSheetElementResponse[]
  >([]);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounced(searchValue, 500);

  const termSheetFetch = useFetch<TermSheetElementsListResponse>({
    url: `term-sheets/elements`,
    method: 'GET',
    onSuccess: (response: any) => {
      const result = response?.data?.termSheetElements.filter(
        (item: TermSheetElementResponse) =>
          filterTypeTermSheet.some(x => x.type === item.valueType) &&
          !selectedFilters?.includes(item.id)
      );

      setTermSheets(result);
      setFilteredTermSheets(result);
    },
  });

  useEffect(() => {
    const filtered = termSheets.filter(x =>
      x.name.toLowerCase().includes(debouncedSearchValue.toLowerCase())
    );
    setFilteredTermSheets(filtered);
  }, [debouncedSearchValue, termSheets]);

  const handleClick = useCallback(
    (item: TermSheetElementResponse) => {
      if (onSelectedFilter !== undefined) {
        onSelectedFilter(item);
      }
      close();
    },
    [close, onSelectedFilter]
  );

  const elementsGroupedByTenant = useMemo(() => {
    const map = new Map<
      string,
      {
        tenant: SmartClauseTenantResponse;
        elements: TermSheetElementResponse[];
      }
    >();
    filteredTermSheets.forEach(element => {
      if (!element.tenantId) return;
      const tenantAndElements = map.get(element.tenantId);
      if (tenantAndElements) {
        tenantAndElements.elements.push(element);
      } else {
        map.set(element.tenantId, {
          tenant: element.tenant,
          elements: [element],
        });
      }
    });
    const result = Array.from(map.values());
    result.sort((lhs, rhs) => lhs.tenant.name.localeCompare(rhs.tenant.name));
    result.forEach(value => {
      value.elements.sort((lhs, rhs) => lhs.name.localeCompare(rhs.name));
    });
    return result;
  }, [filteredTermSheets]);

  const buttons = useMemo(
    () =>
      elementsGroupedByTenant?.map(({ tenant, elements }) => (
        <Box>
          <Box mb={1}>
            <Divider>
              <Chip label={tenant.name} />
            </Divider>
          </Box>
          <Stack direction="row" gap={1} flexWrap="wrap">
            {elements.map(element => (
              <Button
                key={element.id}
                size="small"
                color="secondary"
                disableElevation
                variant="outlined"
                startIcon={
                  filterTypeTermSheet.find(x => x.type === element.valueType)
                    ?.icon
                }
                sx={{ borderRadius: 1 }}
                onClick={() => handleClick(element)}
              >
                {getTermSheetElementDisplayName(intl, element)}
              </Button>
            ))}
          </Stack>
        </Box>
      )),
    [elementsGroupedByTenant, handleClick, intl]
  );

  return (
    <DialogWithClose titleLabel="Ajouter des filtres de recherche">
      <Stack direction="column" gap={2}>
        <StyledSearch value={searchValue} setValue={setSearchValue} />
        <Fetching fetching={termSheetFetch.fetching}>
          <ErrorBoundary>
            <ErrorMessage error={termSheetFetch.error} sx={{ mb: 2 }} />
            {buttons}
            {!termSheetFetch.error && filteredTermSheets.length === 0 && (
              <Typography variant="caption">Aucun filtre disponible</Typography>
            )}
          </ErrorBoundary>
        </Fetching>
      </Stack>
    </DialogWithClose>
  );
}

export default TermSheetFilterModal;
