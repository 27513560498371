import React from 'react';
import * as Yup from 'yup';
import { useField } from 'formik';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';

import FormikSelect from '@components/Formik/FormikSelect';
import { requiredIf, requiredWhen } from '@utils/yup-required-when';

// import { fields as operationFields } from './OperationForm';
// import { fields as loansFields } from './LoansForm';
import { fields as operationFields, fundingMethods } from './OperationForm';

// const toFixedRounded = num => (Math.round(num * 100) / 100).toFixed(2);

export const fields = {
  // annualResources: 'annualResources',
  propertyGoal: 'propertyGoal',
  situation: 'situation',
};

const propertyGoals = {
  principalResidence: 'principalResidence',
  secondaryResidence: 'secondaryResidence',
  rentalInvestment: 'rentalInvestment',
};

const noop = node => node;

export const getSchemaProvisionalForm = required =>
  Yup.object().shape({
    // [fields.annualResources]: Yup.lazy(values => {
    //   if (values != null && values !== undefined && values !== '') {
    //     return Yup.number().min(0, 'Generic.Fields.MustBePositive');
    //   }
    //   return Yup.string().default('').required('Generic.Fields.Required');
    // }),
    [fields.propertyGoal]: requiredIf(
      Yup.string().trim().default(''),
      required
    ),
    [fields.situation]: (required ? requiredWhen : noop)(
      Yup.string().default(''),
      fields.propertyGoal,
      propertyGoals.principalResidence
    ),
    [operationFields.fundingMethod]: requiredIf(
      Yup.string().default(''),
      required
    ),
  });

function ProvisionalForm({ readOnly = false, required: requiredProp }) {
  // const [{ value: amountTotal }] = useField(loansFields.amountTotal);
  const [{ value: propertyGoal }] = useField(`${fields.propertyGoal}`);
  const [, , { setValue: setFundingMethod }] = useField(
    `${operationFields.fundingMethod}`
  );
  // const [, , { setValue: setEstimate }] = useField(
  //   `${operationFields.warrantyCostEstimate}`
  // );

  const required =
    requiredProp && propertyGoal === propertyGoals.principalResidence;

  const onFundingMethodChange = event => {
    const newFundingMethod = event.target.value;
    setFundingMethod(newFundingMethod);
    // const newEstimate = toFixedRounded((amountTotal ?? 0) * 0.01);
    // setEstimate(newFundingMethod === fundingMethods.cash ? '' : newEstimate);
  };

  return (
    <Stack direction="column" gap={1} pt={1}>
      <Typography variant="h5" gutterBottom>
        Financement prévisionnel
      </Typography>
      <Grid container columnSpacing={4}>
        <Grid item xs={6}>
          <Stack direction="column" gap={1}>
            {/* <FormikTextField
              required
              disabled={readOnly}
              margin="dense"
              color="secondary"
              name={fields.annualResources}
              label="Ressources annuelles"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="end">&euro;</InputAdornment>
                ),
              }}
            /> */}
            <FormikSelect
              as="field"
              required={required}
              disabled={readOnly}
              name={fields.situation}
              label="Situation actuelle"
              FormControlProps={{
                color: 'secondary',
                margin: 'dense',
                disabled: readOnly,
              }}
              margin="dense"
            >
              <MenuItem value="owner">Propriétaire</MenuItem>
              <MenuItem value="tenant">Locataire</MenuItem>
            </FormikSelect>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="column" gap={1}>
            <FormikSelect
              required={required}
              disabled={readOnly}
              name={fields.propertyGoal}
              label="Destination du bien"
              FormControlProps={{
                color: 'secondary',
                margin: 'dense',
                disabled: readOnly,
              }}
              margin="dense"
            >
              <MenuItem value={propertyGoals.principalResidence}>
                Résidence principale
              </MenuItem>
              <MenuItem value={propertyGoals.secondaryResidence}>
                Résidence secondaire
              </MenuItem>
              <MenuItem value={propertyGoals.rentalInvestment}>
                Investissement locatif
              </MenuItem>
            </FormikSelect>
            <FormikSelect
              as="field"
              required={required}
              disabled={readOnly}
              name={operationFields.fundingMethod}
              label="Mode de financement"
              FormControlProps={{
                color: 'secondary',
                margin: 'dense',
                disabled: readOnly,
              }}
              margin="dense"
              onChange={onFundingMethodChange}
            >
              <MenuItem value={fundingMethods.credit}>Crédit</MenuItem>
              <MenuItem value={fundingMethods.cash}>Comptant</MenuItem>
            </FormikSelect>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default ProvisionalForm;
