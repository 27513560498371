import ResetPasswordForm from './ResetPasswordForm';
import { SigninLayout } from '../Signin/SigninView';

function ResetPasswordView() {
  return (
    <SigninLayout hideWelcome>
      <ResetPasswordForm />
    </SigninLayout>
  );
}

export default ResetPasswordView;
