/* eslint-disable react/no-array-index-key */
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FieldArray } from 'formik';
import * as Yup from 'yup';

import FamilyForm, {
  schemaFamilyForm,
  defaultFamilyFormValues,
} from './FamilyForm';

import withFormsBehavior from '../withFormsBehavior';

const schema = Yup.object().shape({
  forms: Yup.array().of(schemaFamilyForm),
});

function FamilyForms({ isFieldReadOnly }) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: 8,
        rowGap: 4,
      }}
    >
      <FieldArray name="forms">
        {({ form }) =>
          form.values.forms?.map((_f, i) => (
            <Stack gap={2} key={i}>
              <FamilyForm
                name="forms"
                index={i}
                isFieldReadOnly={isFieldReadOnly}
              />
            </Stack>
          ))
        }
      </FieldArray>
    </Box>
  );
}

const defaultFormValues = {
  ...defaultFamilyFormValues,
};

const getFormWithDefaults = form => {
  const result = { ...form };
  Object.entries(defaultFormValues).forEach(([key, defaultValue]) => {
    const formValue = result[key];
    if (formValue === null || formValue === undefined) {
      result[key] = defaultValue;
    }
  });
  return result;
};

const FamilyView = withFormsBehavior(FamilyForms, {
  type: 'family',
  schema,
  getInitialValues: forms => ({
    forms: (forms || []).map(getFormWithDefaults),
  }),
});

export default FamilyView;
