import React from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import Alert from '@mui/material/Alert';

function WorkflowStepWarning({ allowNoContributors = false }) {
  const { values } = useFormikContext();
  if (
    !allowNoContributors &&
    !values?.mandatoryContributors?.length &&
    !values?.otherContributors?.length
  ) {
    return (
      <Alert severity="warning">
        <FormattedMessage id="Workflow.Step.Fields.Contributors.Required" />
      </Alert>
    );
  }
  return null;
}

export default WorkflowStepWarning;
