/* eslint-disable */
/* generated */

const tenantPermissions = {
  None: 0,
  All: 32767,
  UsersList: 1,
  UsersCreate: 2,
  UsersUpdate: 3,
  UsersDetail: 4,
  UsersDelete: 5,
  UsersExternalList: 6,
  UsersExternalCreate: 7,
  UsersExternalUpdate: 8,
  UsersExternalDetail: 9,
  UsersExternalDelete: 10,
  UsersSearch: 11,
  UsersRolesPermissionsUpdate: 12,
  UsersDeactivatedStatusUpdate: 13,
  UsersSelfUpdate: 14,
  RolesList: 100,
  RolesCreate: 101,
  RolesUpdate: 102,
  RolesDetail: 103,
  RolesDelete: 104,
  RolesSearch: 105,
  TemplatesList: 200,
  TemplatesCreate: 201,
  TemplatesUpdate: 202,
  TemplatesDetail: 203,
  TemplatesDelete: 204,
  TemplateFoldersList: 205,
  TemplateFoldersCreate: 206,
  TemplateFoldersUpdate: 207,
  TemplateFoldersDetail: 208,
  TemplateFoldersDelete: 209,
  TemplatesPublish: 210,
  TemplatesUnpublish: 211,
  TemplatesSearch: 212,
  ReferentialsList: 300,
  ReferentialsCreate: 301,
  ReferentialsUpdate: 302,
  ReferentialsDetail: 303,
  ReferentialsDelete: 304,
  ReferentialElementsList: 305,
  ReferentialElementsCreate: 306,
  ReferentialElementsUpdate: 307,
  ReferentialElementsDetail: 308,
  ReferentialElementsDelete: 309,
  ReferentialProviderInfoList: 310,
  ReferentialProviderInfoCreate: 311,
  ReferentialProviderInfoUpdate: 312,
  ReferentialProviderInfoDetail: 313,
  ReferentialProviderInfoDelete: 314,
  ReferentialProviderInfoTemplateUpdate: 315,
  ReferentialElementsSearch: 316,
  DepartmentsList: 400,
  DepartmentsCreate: 401,
  DepartmentsUpdate: 402,
  DepartmentsDetail: 403,
  DepartmentsDelete: 404,
  DepartmentExpertiseUsersList: 405,
  DepartmentExpertiseUsersCreate: 406,
  DepartmentExpertiseUsersUpdate: 407,
  DepartmentExpertiseUsersDetail: 408,
  DepartmentExpertiseUsersDelete: 409,
  TeamsList: 500,
  TeamsCreate: 501,
  TeamsUpdate: 502,
  TeamsDetail: 503,
  TeamsDelete: 504,
  TeamUsersList: 505,
  TeamUsersCreate: 506,
  TeamUsersUpdate: 507,
  TeamUsersDetail: 508,
  TeamUsersDelete: 509,
  TenantsList: 600,
  TenantsCreate: 601,
  TenantsUpdate: 602,
  TenantsDetail: 603,
  TenantsDelete: 604,
  DocumentsList: 700,
  DocumentsCreate: 701,
  DocumentsUpdate: 702,
  DocumentsDetail: 703,
  DocumentsDelete: 704,
  DocumentFoldersList: 705,
  DocumentFoldersCreate: 706,
  DocumentFoldersUpdate: 707,
  DocumentFoldersDetail: 708,
  DocumentFoldersDelete: 709,
  DocumentsUpload: 710,
  DocumentsBypassAccessCheck: 711,
  DocumentsAnalysisCreate: 712,
  DocumentsAnalysisCancel: 713,
  DocumentsDuplicate: 714,
  DocumentsMove: 715,
  DocumentFoldersMove: 716,
  DocumentTermSheetExport: 717,
  ContractsList: 800,
  ContractsCreate: 801,
  ContractsUpdate: 802,
  ContractsDetail: 803,
  ContractsDelete: 804,
  ContractsAccessRead: 805,
  ContractsAccessWrite: 806,
  ContractsPdf: 807,
  ContractsContributorsCreate: 808,
  ContractsContributorsDelete: 809,
  DashboardView: 900,
  SuppliersList: 1000,
  SuppliersCreate: 1001,
  SuppliersUpdate: 1002,
  SuppliersDetail: 1003,
  SuppliersDelete: 1004,
  SuppliersLock: 1005,
  SuppliersUnlock: 1006,
  ExpertisesList: 1100,
  ExpertisesCreate: 1101,
  ExpertisesUpdate: 1102,
  ExpertisesDetail: 1103,
  ExpertisesDelete: 1104,
  ContractualPacksList: 1200,
  ContractualPacksCreate: 1201,
  ContractualPacksUpdate: 1202,
  ContractualPacksDetail: 1203,
  ContractualPacksDelete: 1204,
  CampaignsList: 1300,
  CampaignsCreate: 1301,
  CampaignsUpdate: 1302,
  CampaignsDetail: 1303,
  CampaignsDelete: 1304,
  HtmlContentsList: 1400,
  HtmlContentsCreate: 1401,
  HtmlContentsUpdate: 1402,
  HtmlContentsDetail: 1403,
  HtmlContentsDelete: 1404,
  HelpCenterFilesList: 1500,
  HelpCenterFilesCreate: 1501,
  HelpCenterFilesUpdate: 1502,
  HelpCenterFilesDetail: 1503,
  HelpCenterFilesDelete: 1504,
  TermSheetElementsList: 1700,
  TermSheetElementsCreate: 1701,
  TermSheetElementsUpdate: 1702,
  TermSheetElementsDetail: 1703,
  TermSheetElementsDelete: 1704,
  TermSheetElementsSearch: 1705,
  TermSheetValuesList: 1706,
  TermSheetValuesCreate: 1707,
  TermSheetValuesUpdate: 1708,
  TermSheetValuesDetail: 1709,
  TermSheetValuesDelete: 1710,
  TermSheetValuesSearch: 1711,
  FormsCreate: 1800,
  FormsStatus: 1801,
  DefaultValuesList: 1900,
  DefaultValuesCreate: 1901,
  DefaultValuesUpdate: 1902,
  DefaultValuesDetail: 1903,
  DefaultValuesDelete: 1904,
  TokensList: 2000,
  TokensCreate: 2001,
  TokensUpdate: 2002,
  TokensDetail: 2003,
  TokensDelete: 2004,
  SignatureCreate: 2102,
  SignatureDelete: 2103,
  SeedingUsers: 2200,
  SeedingTenants: 2201,
  ClausesList: 2300,
  ClausesCreate: 2301,
  ClausesUpdate: 2302,
  ClausesDetail: 2303,
  ClausesDelete: 2304,
  ClausesPublish: 2305,
  ClausesUnpublish: 2306,
  ClausesRestore: 2307,
  ClauseFoldersList: 2320,
  ClauseFoldersCreate: 2321,
  ClauseFoldersUpdate: 2322,
  ClauseFoldersDetail: 2323,
  ClauseFoldersDelete: 2324,
  ClauseFoldersRestore: 2325,
  ClauseTagAdd: 2340,
  ClauseTagRemove: 2341,
  TagList: 2400,
  TagCreate: 2401,
  TagUpdate: 2402,
  TagDelete: 2403,
  CalendarView: 2500,
  RemindersList: 2501,
  RemindersCreate: 2502,
  RemindersUpdate: 2503,
  RemindersDetail: 2504,
  RemindersDelete: 2505,
  DeadlinesList: 2506,
  DeadlinesCreate: 2507,
  DeadlinesUpdate: 2508,
  DeadlinesDetail: 2509,
  DeadlinesDelete: 2510,
  NomenclatureList: 2600,
  NomenclatureCreate: 2601,
  NomenclatureUpdate: 2602,
  NomenclatureDetail: 2603,
  NomenclatureDelete: 2604,
  VersionsList: 2700,
  VersionsCreate: 2701,
  VersionsUpdate: 2702,
  VersionsDetail: 2703,
  VersionsDelete: 2704,
  NotesList: 2800,
  NotesCreate: 2801,
  NotesUpdate: 2802,
  NotesDetail: 2803,
  NotesDelete: 2804,
  NotesOthersDelete: 2805,
  AdministrationView: 2900,
};

export const featurePermissions = {
  Users: [
    tenantPermissions.UsersList,
    tenantPermissions.UsersCreate,
    tenantPermissions.UsersUpdate,
    tenantPermissions.UsersDetail,
    tenantPermissions.UsersDelete,
    tenantPermissions.UsersExternalList,
    tenantPermissions.UsersExternalCreate,
    tenantPermissions.UsersExternalUpdate,
    tenantPermissions.UsersExternalDetail,
    tenantPermissions.UsersExternalDelete,
    tenantPermissions.UsersSearch,
    tenantPermissions.UsersRolesPermissionsUpdate,
    tenantPermissions.UsersDeactivatedStatusUpdate,
    tenantPermissions.UsersSelfUpdate,
  ],
  Roles: [
    tenantPermissions.RolesList,
    tenantPermissions.RolesCreate,
    tenantPermissions.RolesUpdate,
    tenantPermissions.RolesDetail,
    tenantPermissions.RolesDelete,
    tenantPermissions.RolesSearch,
  ],
  Templates: [
    tenantPermissions.TemplatesList,
    tenantPermissions.TemplatesCreate,
    tenantPermissions.TemplatesUpdate,
    tenantPermissions.TemplatesDetail,
    tenantPermissions.TemplatesDelete,
    tenantPermissions.TemplateFoldersList,
    tenantPermissions.TemplateFoldersCreate,
    tenantPermissions.TemplateFoldersUpdate,
    tenantPermissions.TemplateFoldersDetail,
    tenantPermissions.TemplateFoldersDelete,
    tenantPermissions.TemplatesPublish,
    tenantPermissions.TemplatesUnpublish,
    tenantPermissions.TemplatesSearch,
  ],
  Referentials: [
    tenantPermissions.ReferentialsList,
    tenantPermissions.ReferentialsCreate,
    tenantPermissions.ReferentialsUpdate,
    tenantPermissions.ReferentialsDetail,
    tenantPermissions.ReferentialsDelete,
    tenantPermissions.ReferentialElementsList,
    tenantPermissions.ReferentialElementsCreate,
    tenantPermissions.ReferentialElementsUpdate,
    tenantPermissions.ReferentialElementsDetail,
    tenantPermissions.ReferentialElementsDelete,
    tenantPermissions.ReferentialProviderInfoList,
    tenantPermissions.ReferentialProviderInfoCreate,
    tenantPermissions.ReferentialProviderInfoUpdate,
    tenantPermissions.ReferentialProviderInfoDetail,
    tenantPermissions.ReferentialProviderInfoDelete,
    tenantPermissions.ReferentialProviderInfoTemplateUpdate,
    tenantPermissions.ReferentialElementsSearch,
  ],
  Departments: [
    tenantPermissions.DepartmentsList,
    tenantPermissions.DepartmentsCreate,
    tenantPermissions.DepartmentsUpdate,
    tenantPermissions.DepartmentsDetail,
    tenantPermissions.DepartmentsDelete,
    tenantPermissions.DepartmentExpertiseUsersList,
    tenantPermissions.DepartmentExpertiseUsersCreate,
    tenantPermissions.DepartmentExpertiseUsersUpdate,
    tenantPermissions.DepartmentExpertiseUsersDetail,
    tenantPermissions.DepartmentExpertiseUsersDelete,
  ],
  Teams: [
    tenantPermissions.TeamsList,
    tenantPermissions.TeamsCreate,
    tenantPermissions.TeamsUpdate,
    tenantPermissions.TeamsDetail,
    tenantPermissions.TeamsDelete,
    tenantPermissions.TeamUsersList,
    tenantPermissions.TeamUsersCreate,
    tenantPermissions.TeamUsersUpdate,
    tenantPermissions.TeamUsersDetail,
    tenantPermissions.TeamUsersDelete,
  ],
  Tenants: [
    tenantPermissions.TenantsList,
    tenantPermissions.TenantsCreate,
    tenantPermissions.TenantsUpdate,
    tenantPermissions.TenantsDetail,
    tenantPermissions.TenantsDelete,
  ],
  Documents: [
    tenantPermissions.DocumentsList,
    tenantPermissions.DocumentsCreate,
    tenantPermissions.DocumentsUpdate,
    tenantPermissions.DocumentsDetail,
    tenantPermissions.DocumentsDelete,
    tenantPermissions.DocumentFoldersList,
    tenantPermissions.DocumentFoldersCreate,
    tenantPermissions.DocumentFoldersUpdate,
    tenantPermissions.DocumentFoldersDetail,
    tenantPermissions.DocumentFoldersDelete,
    tenantPermissions.DocumentsUpload,
    tenantPermissions.DocumentsBypassAccessCheck,
    tenantPermissions.DocumentsAnalysisCreate,
    tenantPermissions.DocumentsAnalysisCancel,
    tenantPermissions.DocumentsDuplicate,
    tenantPermissions.DocumentsMove,
    tenantPermissions.DocumentFoldersMove,
  ],
  Contracts: [
    tenantPermissions.ContractsList,
    tenantPermissions.ContractsCreate,
    tenantPermissions.ContractsUpdate,
    tenantPermissions.ContractsDetail,
    tenantPermissions.ContractsDelete,
    tenantPermissions.ContractsAccessRead,
    tenantPermissions.ContractsAccessWrite,
    tenantPermissions.ContractsPdf,
    tenantPermissions.ContractsContributorsCreate,
    tenantPermissions.ContractsContributorsDelete,
  ],
  Dashboard: [
    tenantPermissions.DashboardView,
  ],
  Suppliers: [
    tenantPermissions.SuppliersList,
    tenantPermissions.SuppliersCreate,
    tenantPermissions.SuppliersUpdate,
    tenantPermissions.SuppliersDetail,
    tenantPermissions.SuppliersDelete,
    tenantPermissions.SuppliersLock,
    tenantPermissions.SuppliersUnlock,
  ],
  Expertises: [
    tenantPermissions.ExpertisesList,
    tenantPermissions.ExpertisesCreate,
    tenantPermissions.ExpertisesUpdate,
    tenantPermissions.ExpertisesDetail,
    tenantPermissions.ExpertisesDelete,
  ],
  ContractualPacks: [
    tenantPermissions.ContractualPacksList,
    tenantPermissions.ContractualPacksCreate,
    tenantPermissions.ContractualPacksUpdate,
    tenantPermissions.ContractualPacksDetail,
    tenantPermissions.ContractualPacksDelete,
  ],
  Campaigns: [
    tenantPermissions.CampaignsList,
    tenantPermissions.CampaignsCreate,
    tenantPermissions.CampaignsUpdate,
    tenantPermissions.CampaignsDetail,
    tenantPermissions.CampaignsDelete,
  ],
  HtmlContents: [
    tenantPermissions.HtmlContentsList,
    tenantPermissions.HtmlContentsCreate,
    tenantPermissions.HtmlContentsUpdate,
    tenantPermissions.HtmlContentsDetail,
    tenantPermissions.HtmlContentsDelete,
  ],
  HelpCenterFiles: [
    tenantPermissions.HelpCenterFilesList,
    tenantPermissions.HelpCenterFilesCreate,
    tenantPermissions.HelpCenterFilesUpdate,
    tenantPermissions.HelpCenterFilesDetail,
    tenantPermissions.HelpCenterFilesDelete,
  ],
  TermSheets: [
    tenantPermissions.TermSheetElementsList,
    tenantPermissions.TermSheetElementsCreate,
    tenantPermissions.TermSheetElementsUpdate,
    tenantPermissions.TermSheetElementsDetail,
    tenantPermissions.TermSheetElementsDelete,
    tenantPermissions.TermSheetElementsSearch,
    tenantPermissions.TermSheetValuesList,
    tenantPermissions.TermSheetValuesCreate,
    tenantPermissions.TermSheetValuesUpdate,
    tenantPermissions.TermSheetValuesDetail,
    tenantPermissions.TermSheetValuesDelete,
    tenantPermissions.TermSheetValuesSearch,
  ],
  Forms: [
    tenantPermissions.FormsCreate,
    tenantPermissions.FormsStatus,
  ],
  DefaultValues: [
    tenantPermissions.DefaultValuesList,
    tenantPermissions.DefaultValuesCreate,
    tenantPermissions.DefaultValuesUpdate,
    tenantPermissions.DefaultValuesDetail,
    tenantPermissions.DefaultValuesDelete,
  ],
  Tokens: [
    tenantPermissions.TokensList,
    tenantPermissions.TokensCreate,
    tenantPermissions.TokensUpdate,
    tenantPermissions.TokensDetail,
    tenantPermissions.TokensDelete,
  ],
  Signature: [
    tenantPermissions.SignatureCreate,
    tenantPermissions.SignatureDelete,
  ],
  Seeding: [
    tenantPermissions.SeedingUsers,
    tenantPermissions.SeedingTenants,
  ],
  Clauses: [
    tenantPermissions.ClausesList,
    tenantPermissions.ClausesCreate,
    tenantPermissions.ClausesUpdate,
    tenantPermissions.ClausesDetail,
    tenantPermissions.ClausesDelete,
    tenantPermissions.ClausesPublish,
    tenantPermissions.ClausesUnpublish,
    tenantPermissions.ClausesRestore,
    tenantPermissions.ClauseFoldersList,
    tenantPermissions.ClauseFoldersCreate,
    tenantPermissions.ClauseFoldersUpdate,
    tenantPermissions.ClauseFoldersDetail,
    tenantPermissions.ClauseFoldersDelete,
    tenantPermissions.ClauseFoldersRestore,
    tenantPermissions.ClauseTagAdd,
    tenantPermissions.ClauseTagRemove,
  ],
  Tags: [
    tenantPermissions.TagList,
    tenantPermissions.TagCreate,
    tenantPermissions.TagUpdate,
    tenantPermissions.TagDelete,
  ],
  Calendar: [
    tenantPermissions.CalendarView,
    tenantPermissions.RemindersList,
    tenantPermissions.RemindersCreate,
    tenantPermissions.RemindersUpdate,
    tenantPermissions.RemindersDetail,
    tenantPermissions.RemindersDelete,
    tenantPermissions.DeadlinesList,
    tenantPermissions.DeadlinesCreate,
    tenantPermissions.DeadlinesUpdate,
    tenantPermissions.DeadlinesDetail,
    tenantPermissions.DeadlinesDelete,
  ],
  Nomenclature: [
    tenantPermissions.NomenclatureList,
    tenantPermissions.NomenclatureCreate,
    tenantPermissions.NomenclatureUpdate,
    tenantPermissions.NomenclatureDetail,
    tenantPermissions.NomenclatureDelete,
  ],
  Versions: [
    tenantPermissions.VersionsList,
    tenantPermissions.VersionsCreate,
    tenantPermissions.VersionsUpdate,
    tenantPermissions.VersionsDetail,
    tenantPermissions.VersionsDelete,
  ],
  Notes: [
    tenantPermissions.NotesList,
    tenantPermissions.NotesCreate,
    tenantPermissions.NotesUpdate,
    tenantPermissions.NotesDetail,
    tenantPermissions.NotesDelete,
    tenantPermissions.NotesOthersDelete,
  ],
  Administration: [
    tenantPermissions.AdministrationView,
  ],
};

export default tenantPermissions;