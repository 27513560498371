import React from 'react';
import { isActive } from '@tiptap/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { alpha } from '@mui/system/colorManipulator';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { bindToggle } from 'material-ui-popup-state';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useTiptapEditor } from '../use-tiptap-editor';
import { useTiptapSelection } from '../use-tiptap-selection';
import { useTiptapState } from '../use-tiptap-state';
import ButtonLineHeight from './buttons/button-line-height';
import ButtonMargin from './buttons/button-margin';

import SelectButton from '@components/Buttons/SelectButton';
import SetNumberingValueModal from '@components/Modals/SetNumberingValueModal';
import SetPrefixSuffixModal from '@components/Modals/SetPrefixSuffixModal';

import { findParentNodeClosestToPos } from '@tiptap/core';

function ToolbarList() {
  const intl = useIntl();
  const editor = useTiptapEditor();
  const selection = useTiptapSelection();
  const bullet = useTiptapState(
    state => Boolean(state) && isActive(state, 'bulletList')
  );
  const ordered = useTiptapState(
    state => Boolean(state) && isActive(state, 'orderedList')
  );
  const [openNumModal, setOpenNumModal] = React.useState(false);
  const [openPrefixSuffixModal, setOpenPrefixSuffixModal] =
    React.useState(false);
  const selectedNumberedList = React.useMemo(
    () =>
      selection
        ? findParentNodeClosestToPos(
            selection.$anchor.doc.resolve(selection.from),
            node => node.type.name === 'orderedList'
          )
        : null,
    [selection]
  );
  const listPrefixSuffix = React.useMemo(
    () =>
      selectedNumberedList
        ? {
            numberingPrefix:
              selectedNumberedList.node.attrs.numberingPrefix?.trim(),
            numberingSuffix:
              selectedNumberedList.node.attrs.numberingSuffix?.trim(),
          }
        : { numberingPrefix: '', numberingSuffix: '' },
    [selectedNumberedList]
  );

  return (
    <ButtonGroup variant="text">
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.List.Unordered" />}
      >
        <Button
          sx={{
            background: theme =>
              bullet ? alpha(theme.palette.primary.main, 0.35) : 'inherit',
          }}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <FormatListBulletedIcon />
        </Button>
      </Tooltip>
      <SelectButton
        variant="text"
        buttons={popupState => (
          <>
            <Tooltip
              placement="top"
              title={
                <FormattedMessage id="Editor.Toolbar.Command.List.Ordered" />
              }
            >
              <Button
                sx={{
                  background: theme =>
                    ordered
                      ? alpha(theme.palette.primary.main, 0.35)
                      : 'inherit',
                }}
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
              >
                <FormatListNumberedIcon />
              </Button>
            </Tooltip>
            <Button
              size="small"
              {...bindToggle(popupState)}
              sx={{
                minWidth: 'unset !important',
                pl: 0,
                pr: 0,
                background: theme =>
                  ordered ? alpha(theme.palette.primary.main, 0.35) : 'inherit',
              }}
            >
              <ArrowDropDownIcon />
            </Button>
          </>
        )}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        {popupState => (
          <>
            <Box
              component={Paper}
              sx={{
                pt: 2,
                pb: 2,
                pr: 3,
                pl: 3,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box>
                <FormattedMessage id="Editor.Numbering.Type.Label" />
                <MenuList component={Paper} elevation={8}>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .setNumberingType(null)
                        .enableNumberingType(true)
                        .run();
                      popupState.close();
                    }}
                  >
                    <FormattedMessage id="Editor.Numbering.Type.Numerical" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .enableNumberingType(true)
                        .setNumberingType('lower-alpha')
                        .run();
                      popupState.close();
                    }}
                  >
                    <FormattedMessage id="Editor.Numbering.Type.Alpha.Lower" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .enableNumberingType(true)
                        .setNumberingType('upper-alpha')
                        .run();
                      popupState.close();
                    }}
                  >
                    <FormattedMessage id="Editor.Numbering.Type.Alpha.Upper" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .enableNumberingType(true)
                        .setNumberingType('lower-roman')
                        .run();
                      popupState.close();
                    }}
                  >
                    <FormattedMessage id="Editor.Numbering.Type.Roman.Lower" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .enableNumberingType(true)
                        .setNumberingType('upper-roman')
                        .run();
                      popupState.close();
                    }}
                  >
                    <FormattedMessage id="Editor.Numbering.Type.Roman.Upper" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .enableNumberingType(true)
                        .setNumberingType(null)
                        .setNumberingPrefix(
                          intl.formatMessage({
                            id: 'Editor.Numbering.Type.Article',
                          }) + ' '
                        )
                        .run();
                      popupState.close();
                    }}
                  >
                    <FormattedMessage id="Editor.Numbering.Type.Article.Example" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor.chain().focus().enableNumberingType(false).run();
                      popupState.close();
                    }}
                  >
                    <FormattedMessage id="Editor.Numbering.Type.None" />
                  </MenuItem>
                </MenuList>
              </Box>
              <Box sx={{ mt: 1, mb: 1 }}>
                <FormattedMessage id="Editor.Numbering.Suffix.List.Label" />
                <MenuList component={Paper} elevation={8}>
                  <MenuItem
                    onClick={() => {
                      editor.chain().focus().setNumberingSuffix('. ').run();
                      popupState.close();
                    }}
                  >
                    X.
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor.chain().focus().setNumberingSuffix(') ').run();
                      popupState.close();
                    }}
                  >
                    {'X)'}
                  </MenuItem>
                </MenuList>
              </Box>
              <Button
                onClick={() => {
                  setOpenNumModal(true);
                }}
                disabled={!selectedNumberedList}
              >
                <FormattedMessage id="Editor.Numbering.Set.Value" />
              </Button>
              <Button
                onClick={() => {
                  setOpenPrefixSuffixModal(true);
                }}
                disabled={!selectedNumberedList}
              >
                <FormattedMessage id="Editor.Numbering.Prefix.Suffix.Set.Label" />
              </Button>
              <Button
                onClick={() => {
                  editor
                    .chain()
                    .focus()
                    .enableNumberingMultilevel(
                      !selectedNumberedList?.node.attrs.numberingMultilevel
                    )
                    .run();
                }}
                disabled={!selectedNumberedList}
              >
                {!selectedNumberedList?.node.attrs.numberingMultilevel && (
                  <FormattedMessage id="Editor.Numbering.Multilevel.Enable" />
                )}
                {selectedNumberedList?.node.attrs.numberingMultilevel && (
                  <FormattedMessage id="Editor.Numbering.Multilevel.Disable" />
                )}
              </Button>
              <SetNumberingValueModal
                onClose={() => {
                  setOpenNumModal(false);
                }}
                onSubmit={values => {
                  if (values.continueNumbering) {
                    editor
                      .chain()
                      .focus()
                      .setContinueNumberingValue(values.continueNumberingValue)
                      .run();
                  } else {
                    editor.chain().focus().disableContinueNumbering().run();
                  }
                  setOpenNumModal(false);
                }}
                open={openNumModal}
                disabled={false}
                initialValues={selectedNumberedList?.node.attrs}
                ModalProps={null}
                NameFieldProps={null}
              />
              <SetPrefixSuffixModal
                onClose={() => {
                  setOpenPrefixSuffixModal(false);
                }}
                onSubmit={values => {
                  editor
                    .chain()
                    .focus()
                    .setNumberingPrefix(values.numberingPrefix)
                    .setNumberingSuffix(values.numberingSuffix)
                    .run();
                  setOpenPrefixSuffixModal(false);
                }}
                open={openPrefixSuffixModal}
                disabled={false}
                initialValues={listPrefixSuffix}
                ModalProps={null}
                NameFieldProps={null}
              />
            </Box>
          </>
        )}
      </SelectButton>
      <ButtonMargin />
      <ButtonLineHeight />
    </ButtonGroup>
  );
}

export default ToolbarList;
