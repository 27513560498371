import {
  CircularProgress,
  FormControl,
  Popper,
  TextField,
  Autocomplete,
} from '@mui/material';
import { useMemo, useState } from 'react';
import useFetch from '@hooks/useFetch';
import styled from '@emotion/styled';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { useIntl } from 'react-intl';
import cloneDeep from 'lodash.clonedeep';
import { useAppContext } from '@contexts/AppContext';
import FilterWrapper from './FilterWrapper';
import { getTextLabel } from './helpers/format-label';
import { PopoverFiltersProps } from './helpers/types';

const StyledAutoComplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    padding: 0,
  },
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:not(.Mui-error):hover fieldset': {
      borderColor: 'transparent',
    },
    '&:not(.Mui-error).Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: '#666666',
  },
});

const StyledPopper = styled((props: any) => (
  <Popper placement="bottom" {...props} />
))(() => ({
  minWidth: 'fit-content',
  maxWidth: '100vh',
}));

function AutoCompletePopoverFilters({
  filter,
  isTrash = false,
  onValidate = undefined,
  onRemoveFilter = undefined,
  advancedSearchRequest = undefined,
  setAdvancedSearchRequest = undefined,
  titleSx,
}: PopoverFiltersProps) {
  const {
    id,
    data = [],
    multiple = false,
    showSearchButton,
    termSheetValueType = undefined,
    urlData = undefined,
    tenantId = undefined,
    dataMappingFromUrl = undefined,
  } = filter;
  const appContext = useAppContext();
  const loadTermSheetOptions = appContext.drive.filters.loadOptions ?? true;
  const [searchRequest, setSearchRequest] = useState(
    advancedSearchRequest ?? {}
  );
  const [localData, setLocalData] = useState<any[]>(data);
  const autoCompletionValues = useFetch({
    url:
      urlData && urlData.indexOf('?') > 0
        ? `${urlData}&trash=${isTrash}`
        : `${urlData}?trash=${isTrash}`,
    hostTenantsRequested: tenantId,
    lazy:
      (urlData === undefined && dataMappingFromUrl === undefined) ||
      !loadTermSheetOptions,
    method: 'GET',
    onSuccess: (response: any) => {
      if (dataMappingFromUrl !== undefined) {
        const values = dataMappingFromUrl(response?.data);
        setLocalData(values);
      }
    },
  });

  const currentValue = useMemo(() => {
    if (termSheetValueType) {
      const index = searchRequest?.termSheetQuery?.findIndex(
        (x: any) => x?.elementId === id
      );
      if (index >= 0) {
        return searchRequest?.termSheetQuery[index].items;
      }
      return multiple ? [] : '';
    }
    // eslint-disable-next-line no-nested-ternary
    return searchRequest?.[id] !== undefined
      ? searchRequest?.[id]
      : multiple
      ? []
      : '';
  }, [searchRequest, id, multiple, termSheetValueType]);

  const setCurrentValue = (value: any, forceUpdate = false) => {
    const tmpValue = cloneDeep(searchRequest);
    if (termSheetValueType) {
      const index = tmpValue.termSheetQuery?.findIndex(
        (x: any) => x?.elementId === id
      );
      if (index >= 0) {
        tmpValue.termSheetQuery.splice(index, 1);
      }
      if ((multiple && value.length > 0) || (!multiple && value)) {
        if (tmpValue.termSheetQuery === undefined) {
          tmpValue.termSheetQuery = [];
        }
        tmpValue.termSheetQuery.push({
          elementId: id,
          items: value,
        });
      }
      if (forceUpdate === true && setAdvancedSearchRequest) {
        setAdvancedSearchRequest(tmpValue);
      } else {
        setSearchRequest(tmpValue);
      }
    } else if ((multiple && value.length === 0) || (!multiple && !value)) {
      // eslint-disable-next-line no-param-reassign
      delete searchRequest[id];
      if (forceUpdate === true && setAdvancedSearchRequest) {
        setAdvancedSearchRequest({ ...searchRequest });
      } else {
        setSearchRequest({ ...searchRequest });
      }
    } else if (forceUpdate === true && setAdvancedSearchRequest) {
      setAdvancedSearchRequest({
        ...searchRequest,
        [id]: value,
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        [id]: value,
      });
    }
  };
  const onChange = (event: any, value: any) => {
    if (
      setAdvancedSearchRequest === undefined ||
      advancedSearchRequest === undefined
    ) {
      return;
    }
    setCurrentValue(value?.value ?? '');
  };
  const onClear = (forceUpdate = false) => {
    if (multiple) {
      setCurrentValue([], forceUpdate);
    } else {
      setCurrentValue('', forceUpdate);
    }
  };
  const onDelete = () => {
    onClear(true);
    if (onRemoveFilter) {
      onRemoveFilter(id);
    }
  };

  const onLocalValidate = () => {
    if (setAdvancedSearchRequest) {
      setAdvancedSearchRequest(searchRequest);
    }
    if (onValidate) {
      onValidate();
    }
  };
  const intl = useIntl();
  const getLabel = (option: any) => {
    if (option === undefined || option === null || option === '') {
      return '';
    }
    if (
      localData !== undefined &&
      localData.length > 0 &&
      typeof option === 'string'
    ) {
      const indexOpt = localData.findIndex((x: any) => x.value === option);
      return getTextLabel(localData[indexOpt]?.label, termSheetValueType, intl);
    }
    return typeof option === 'string'
      ? getTextLabel(option, termSheetValueType, intl)
      : getTextLabel(option?.label, termSheetValueType, intl) ?? '';
  };

  return (
    <FilterWrapper
      showSearchButton={showSearchButton}
      onValidate={onLocalValidate}
      onClear={onClear}
      onDelete={onDelete}
      filter={filter}
      isRemovable={
        !(filter.isAdditionalFilter ?? false) &&
        termSheetValueType !== undefined
      }
      titleSx={titleSx}
    >
      <FormControl fullWidth>
        <StyledAutoComplete
          freeSolo={!loadTermSheetOptions}
          options={localData}
          getOptionLabel={getLabel}
          fullWidth
          onChange={onChange}
          PopperComponent={StyledPopper}
          loading={autoCompletionValues.fetching}
          disabled={autoCompletionValues.errored}
          value={currentValue}
          isOptionEqualToValue={(option: any, value: any) =>
            typeof value === 'string'
              ? option?.value === value
              : option?.value === value?.value
          }
          onInputChange={(event, value) => {
            if (!loadTermSheetOptions) {
              onChange(event, { value });
            }
          }}
          noOptionsText="Aucun résultat"
          sx={{
            width: '320px',
          }}
          renderInput={(params: any) => (
            <StyledTextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {autoCompletionValues.fetching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </FormControl>
      <ErrorMessage
        error={autoCompletionValues.error}
        sx={{ mt: 2, width: '320px' }}
      />
    </FilterWrapper>
  );
}

export default AutoCompletePopoverFilters;
