import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import DialogOutlet from '@components/Dialog/DialogOutlet';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import FormikProvider from '@components/Formik/FormikProvider';
import FormikTextField from '@components/Formik/FormikTextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import useNexityTheme from '@shells/nexity/hooks/useNexityTheme';

const reasonSchema = yup.object({
  reason: yup.string().trim().required('Generic.Fields.Required'),
});

function FormsInvalidateModalContent({
  putting,
  onSubmit,
  putError,
  areClientInfoValidated,
}) {
  return (
    <FormikProvider
      schema={reasonSchema}
      initialValues={{ reason: '' }}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      <DialogWithClose
        titleLabel="Invalidation du formulaire"
        dialogActions={
          <Button type="submit" variant="contained" disabled={putting}>
            Invalider le formulaire
          </Button>
        }
      >
        <ErrorMessage error={putError} />
        <Typography variant="body1">
          Le message à destination du client
        </Typography>
        <Typography variant="body2">
          Ajouter un message à destination du client pour justifier le motif
          d&apos;invalidation du formulaire.
        </Typography>
        <Box my={4}>
          <FormikTextField
            as="fastfield"
            required
            name="reason"
            label="Messsage"
            multiline
            rows={3}
            color="secondary"
            margin="dense"
          />
        </Box>
        <Typography variant="body2">
          Ce message sera disponible sur l&apos;espace personnel du client.
          {areClientInfoValidated && (
            <>
              <br />
              L&apos;espace client sera automatiquement invalidé.
            </>
          )}
        </Typography>
      </DialogWithClose>
    </FormikProvider>
  );
}

function FormsValidateModalContent({
  putting,
  onSubmit,
  putError,
  values,
  validationMessage,
  validationMessageConditionValues,
}) {
  let showValidationMessage = validationMessage ?? false;

  if (validationMessageConditionValues) {
    Object.keys(validationMessageConditionValues).forEach(key => {
      if (
        values[key] &&
        values[key] !== validationMessageConditionValues[key]
      ) {
        showValidationMessage = false;
      }
    });
  }

  return (
    <DialogWithClose
      titleLabel="Valider du formulaire"
      dialogActions={
        <Button
          type="button"
          variant="contained"
          disabled={putting}
          onClick={onSubmit}
        >
          Valider le formulaire
        </Button>
      }
    >
      <ErrorMessage error={putError} />
      <Typography variant="body1">
        Vous vous apprêtez à valider le formulaire complété par le client.
      </Typography>
      {Boolean(showValidationMessage) && (
        <Typography variant="body1">
          <br />
          {validationMessage}
        </Typography>
      )}
    </DialogWithClose>
  );
}

function FormsModals({
  schema,
  putting,
  doPut,
  putError,
  areClientInfoValidated,
  validationMessage,
  validationMessageConditionValues,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { values } = useFormikContext();
  const { name } = useNexityTheme();

  const onCloseCallback = React.useCallback(() => {
    navigate({ pathname: '.', hash: location.hash });
  }, [navigate, location.hash]);

  const doModalPut = modalValues => {
    const castedValues = schema.cast(values);
    const forms = castedValues?.forms ?? castedValues;
    doPut({
      body: { forms, ...modalValues, themeName: name },
    }).then(response => {
      if (response.ok) onCloseCallback();
    });
  };

  const onInvalidate = ({ reason }) => {
    doModalPut({ status: 2, reason });
  };

  const onValidate = () => {
    doModalPut({ status: 3, reason: '' });
  };

  return (
    <Routes>
      <Route
        path="modals"
        element={<DialogOutlet open onClose={onCloseCallback} />}
      >
        <Route
          path="invalidate"
          element={
            <FormsInvalidateModalContent
              putting={putting}
              onSubmit={onInvalidate}
              putError={putError}
              areClientInfoValidated={areClientInfoValidated}
            />
          }
        />
        <Route
          path="validate"
          element={
            <FormsValidateModalContent
              putting={putting}
              onSubmit={onValidate}
              putError={putError}
              values={values}
              validationMessage={validationMessage}
              validationMessageConditionValues={
                validationMessageConditionValues
              }
            />
          }
        />
      </Route>
    </Routes>
  );
}

export default FormsModals;
