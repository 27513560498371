import { useMemo, useCallback } from 'react';
import useFetch from '@hooks/useFetch';
import FormikProvider from '@components/Formik/FormikProvider';
import useTenantRoles from '@hooks/useTenantRoles';
import { nexityRoles } from '@shells/nexity/nexity-constants';
import useFormsReadOnly from './useFormsReadOnly';
import FormsAlerts from './FormsAlerts';
import FormsButtons from './FormsButtons';
import FormsModals from './FormsModals';

const withFormsBehavior = (
  FormsComponent,
  {
    type,
    schema: schemaProp,
    getInitialValues,
    nonRequiredSchema,
    requiredSchema,
  }
) =>
  function WithFormsBehaviorComponent({
    id,
    form: { forms, status = 0, reason = '', preFilled } = {},
    refresh = () => {},
    preventModifications,
    areClientInfoValidated,
    canInvalidateForm,
    fetchData,
    validationMessage,
    validationMessageConditionValues,
  }) {
    const readOnlyFromStatus = useFormsReadOnly(status);
    const readOnly = Boolean(preventModifications) || readOnlyFromStatus;
    const initialValues = useMemo(() => getInitialValues(forms), [forms]);
    const {
      fetching: putting,
      doFetch: doPut,
      error,
    } = useFetch({
      url: `nexity/dashboard/folders/${id}/forms/${type}`,
      method: 'PUT',
      onSuccess: () => refresh(),
    });

    const preFilledSet = useMemo(() => new Set(preFilled), [preFilled]);
    const isFieldReadOnly = useCallback(
      fieldName => readOnly || preFilledSet.has(fieldName),
      [readOnly, preFilledSet]
    );

    const isAdmin = useTenantRoles({ roles: [nexityRoles.administrators] });
    const needsToBeRequired = Boolean(
      fetchData?.isDealer ||
        fetchData?.isAnimator ||
        fetchData?.isAffiliateValidator ||
        fetchData?.isManager ||
        isAdmin
    );

    const schema =
      (needsToBeRequired ? requiredSchema : nonRequiredSchema) || schemaProp;

    return (
      <FormikProvider
        validateOnBlur={false}
        validateOnChange={false}
        schema={schema}
        initialValues={initialValues}
      >
        <FormsAlerts status={status} reason={reason} />
        <FormsComponent
          readOnly={readOnly}
          isFieldReadOnly={isFieldReadOnly}
          required={needsToBeRequired}
        />
        <FormsButtons
          status={status}
          putting={putting}
          schema={schema}
          doPut={doPut}
          preventModifications={preventModifications}
          canInvalidateForm={canInvalidateForm}
        />
        <FormsModals
          putting={putting}
          schema={schema}
          doPut={doPut}
          putError={error}
          areClientInfoValidated={areClientInfoValidated}
          validationMessage={validationMessage}
          validationMessageConditionValues={validationMessageConditionValues}
        />
      </FormikProvider>
    );
  };

export default withFormsBehavior;
