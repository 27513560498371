import React from 'react';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';

export default function AddButton({ label, to, ...ButtonProps }) {
  const isLink = to !== null && to !== undefined;
  return (
    <Button
      startIcon={<AddCircleIcon />}
      variant="text"
      color="primary"
      component={isLink ? Link : undefined}
      to={isLink ? to : undefined}
      {...ButtonProps}
    >
      {label}
    </Button>
  );
}
