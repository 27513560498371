/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import ContributorChip from '@components/Chips/ContributorChip';

function WorkflowStepCard({
  selected,
  name,
  isFirst,
  mandatoryContributors = [],
  otherContributors = [],
  allowNoContributors = false,
}) {
  return (
    <Card
      variant="outlined"
      sx={{
        bgcolor: selected ? 'primary.main' : 'background.paper',
        color: selected ? 'common.white' : 'text.primary',
      }}
    >
      <CardContent sx={{ py: 1, px: 2, '&:last-child': { pb: 1 } }}>
        {!allowNoContributors &&
          !isFirst &&
          !otherContributors.length &&
          !mandatoryContributors.length && (
            <WarningAmberIcon color="warning" sx={{ float: 'right' }} />
          )}
        <Typography variant="subtitle2">{name}</Typography>
        {(!!mandatoryContributors.length || !!otherContributors.length) && (
          <Stack
            display="inline-flex"
            direction="row"
            flexWrap="wrap"
            gap={1}
            mt={1}
          >
            {mandatoryContributors.map(
              ({ contributor: { type, entity } }, index) => (
                <ContributorChip
                  key={index}
                  sx={{ bgcolor: 'grey.200' }}
                  type={type}
                  entityId={entity?.id}
                  entity={entity}
                  authorLabel={
                    <FormattedMessage id="Workflow.Step.Author.Label" />
                  }
                />
              )
            )}
            {otherContributors.map(
              ({ contributor: { type, entity } }, index) => (
                <ContributorChip
                  key={index}
                  sx={{ bgcolor: 'grey.200' }}
                  type={type}
                  entityId={entity?.id}
                  entity={entity}
                  authorLabel={
                    <FormattedMessage id="Workflow.Step.Author.Label" />
                  }
                />
              )
            )}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}

export default WorkflowStepCard;
