import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FastField, Field } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

function MuiRadioGroup({
  field,
  form: { touched, errors },
  items = [],
  FormControlProps = {},
  ...props
}) {
  const { name } = field;
  const error = Boolean(touched[name]) && Boolean(errors[name]);
  return (
    <FormControl error={error} {...FormControlProps}>
      <RadioGroup
        margin="normal"
        {...props}
        {...field}
        onChange={props?.onChange ?? field?.onChange}
      >
        {items.map(({ value, label, children }) => [
          <FormControlLabel
            key={value}
            value={value}
            label={label}
            control={<Radio size="small" />}
          />,
          children,
        ])}
      </RadioGroup>
      {error && (
        <FormHelperText>
          <FormattedMessage
            id={errors[field.name]}
            values={{ label: props?.label ?? '', value: field?.value ?? '' }}
          />
        </FormHelperText>
      )}
    </FormControl>
  );
}

function FormikRadioGroup({ as = 'field', ...props }) {
  if (as === 'fastfield') {
    return <FastField component={MuiRadioGroup} {...props} />;
  }
  return <Field component={MuiRadioGroup} {...props} />;
}

export default FormikRadioGroup;
