import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

export const statuses = {
  unknown: null,
  missing: 0,
  toBeValidated: 1,
  toSendForSignature: 2,
  toBeSigned: 3,
  signedByPm: 4,
  signedBySupplier: 5,
  signed: 6,
  canceled: 7,
};

export const apiStatuses = {
  pending: 1,
  finished: 2,
  signing: 3,
  signed: 4,
};

export const apiStatusTexts = {
  pending: 'pending',
  finished: 'finished',
  signing: 'signing',
  signed: 'signed',
};

export const apiSignerStatuses = {
  pending: 0,
  completed: 1,
  rejected: 2,
};

export const mapStatus = (apiStatus, isSigningWorkflowStep) =>
  ({
    [apiStatuses.pending]: isSigningWorkflowStep
      ? statuses.toSendForSignature
      : statuses.toBeValidated,
    [apiStatuses.finished]: statuses.signed,
    [apiStatuses.signing]: statuses.toBeSigned,
    [apiStatuses.signed]: statuses.signed,
  }[apiStatus] || statuses.toSendForSignature);

export const mapDocumentStatus = document => {
  if (!document.contract) {
    return mapStatus(document.status, true);
  }

  const currentStep = document.contract?.workflow?.steps.find(
    s => s.endTime === null
  );
  const isSigningStep =
    currentStep?.mandatoryContributors.some(c => c.access === 1) ||
    currentStep?.otherContributors.some(c => c.access === 1);
  return mapStatus(document.status, isSigningStep);
};

const STATUS_COLORS = [
  'error',
  'info',
  'info',
  'warning',
  'info',
  'info',
  'success',
  'error',
];

const StyledChip = styled(Chip)(({ theme, color }) => ({
  color: theme.palette[color][700],
  borderColor: theme.palette[color].main,
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: '3px 23px',
  borderRadius: '4px',
  fontSize: '14px',
  fontWeight: 700,
  backgroundColor: theme.palette[color][100],
  cursor: 'inherit',
}));

function DocumentChip({ status, ...rest }) {
  return (
    <StyledChip
      color={STATUS_COLORS[status]}
      label={<FormattedMessage id={`Document.Fields.Status.${status}`} />}
      {...rest}
    />
  );
}

DocumentChip.propTypes = {
  status: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7]).isRequired,
};

export default DocumentChip;
