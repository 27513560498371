import { IntlShape } from 'react-intl';
import { intlMessageExists } from '@hooks/useMessageExists';

// eslint-disable-next-line import/prefer-default-export
export function getTermSheetElementDisplayName(
  intl: IntlShape,
  element:
    | {
        name: string;
        isSystem: boolean;
      }
    | null
    | undefined
) {
  if (!element) return '';

  const { name, isSystem } = element;

  if (isSystem) {
    const messageId = `TermSheet.Entities.Elements.System.${name}.Label`;
    if (intlMessageExists(intl, messageId)) {
      return intl.formatMessage({ id: messageId }) || name;
    }
  }

  return name;
}
