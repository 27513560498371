import { Extension } from '@tiptap/react';
import {
  findParentNode,
  findChildren,
  findParentNodeClosestToPos,
} from '@tiptap/core';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';

const TableCustomCommands = Extension.create({
  name: 'table-custom-commands',
  addCommands() {
    return {
      setTableColsAttribute:
        (attributeName, value) =>
        ({ state, tr }) => {
          const currentCell = findParentNode(
            node => node.type.name === TableCell.name
          )(state.selection);
          const table = findParentNodeClosestToPos(
            state.doc.resolve(currentCell.pos),
            node => node.type.name === Table.name
          );

          const cellsPosData = getTableCellsPosData(table);
          const currentCellPosData = cellsPosData.find(
            cell => cell.node === currentCell.node
          );
          // to simplify the operation, we take the first column of the selected cells
          const cellsOnSameCol = cellsPosData.filter(
            cell =>
              cell.x.end >= currentCellPosData.x.start &&
              cell.x.start <= currentCellPosData.x.start
          );

          for (const cellData of cellsOnSameCol) {
            let newValue = value;

            if (attributeName === 'colwidth') {
              if (cellData.x.start === cellData.x.end) {
                // single column
                newValue = value !== null ? [value] : null;
              } else {
                // merged columns
                newValue = [
                  ...(cellData.node.attrs.colwidth ??
                    Array(cellData.node.attrs.colspan).fill(0)),
                ];
                newValue[currentCellPosData.x.start - cellData.x.start] =
                  value !== null ? value : 0;
              }
            }
            tr.setMeta('notrackchanges', true);
            tr.setNodeAttribute(cellData.pos, attributeName, newValue);
          }
          state.apply(tr);
        },
    };
  },
});

export default TableCustomCommands;

/// Get all cells with positions data in the table
function getTableCellsPosData(table) {
  const allCells = findChildren(
    table.node,
    node => node.type.name === TableCell.name
  );

  allCells.forEach(cell => {
    cell.pos += table.pos + 1;
  });

  const cellsPosData = [];
  const rows = table.node.content.content.filter(
    n => n.type.name === TableRow.name
  );
  const maxCells = Math.max(...rows.map(row => row.content.content.length));
  const mapPosData =
    maxCells !== -Infinity ? rows.map(r => Array(maxCells).fill()) : [];

  for (let rowIdx = 0; rowIdx < rows.length; ++rowIdx) {
    const row = table.node.content.content[rowIdx];
    const cols = row.content.content.filter(
      n => n.type.name === TableCell.name
    );

    for (let colIdx = 0; colIdx < cols.length; ++colIdx) {
      const posDataIdx = mapPosData[rowIdx].findIndex(d => d === undefined);
      const cell = cols[colIdx];
      for (let spanIdx = 0; spanIdx < cell.attrs.rowspan; ++spanIdx) {
        const rowPos = rowIdx + spanIdx;

        mapPosData[rowPos].fill(
          spanIdx === 0 ? colIdx : 'x',
          posDataIdx,
          posDataIdx + cell.attrs.colspan
        );
      }
      cellsPosData.push({
        node: cell,
        pos: allCells.find(n => n.node === cell).pos,
        x: {
          start: posDataIdx,
          end: posDataIdx + (cell.attrs.colspan - 1),
        },
        y: { start: rowIdx, end: rowIdx + (cell.attrs.rowspan - 1) },
      });
    }
  }
  return cellsPosData;
}
