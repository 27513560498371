import { defineMessages } from 'react-intl';

export default defineMessages({
  WorkflowStepIdentifierRequired: {
    id: 'Workflow.Step.Fields.Identifier.Required',
    defaultMessage: 'Step identifier is required',
  },
  WorkflowStepNameLabel: {
    id: 'Workflow.Step.Fields.Name.Label',
    defaultMessage: 'Step name',
  },
  WorkflowStepNameDefault: {
    id: 'Workflow.Step.Fields.Name.Default',
    defaultMessage: 'New workflow step',
  },
  WorkflowStepNameRequired: {
    id: 'Workflow.Step.Fields.Name.Required',
    defaultMessage: 'Step name is required',
  },
  WorkflowStepButtonsAdd: {
    id: 'Workflow.Step.Buttons.Add',
    defaultMessage: 'Add a workflow step',
  },
  WorkflowStepMandatoryLabel: {
    id: 'Workflow.Step.Fields.Mandatory.Label',
    defaultMessage: 'Mandatory contributors',
  },
  WorkflowStepMandatoryRequired: {
    id: 'Workflow.Step.Fields.Mandatory.Required',
    defaultMessage:
      'This workflow step requires at least one mandatory contributor',
  },
  WorkflowStepMandatoryButtonsAdd: {
    id: 'Workflow.Step.Buttons.Mandatory.Add',
    defaultMessage: 'Add a mandatory contributor',
  },
  WorkflowStepOthersLabel: {
    id: 'Workflow.Step.Fields.Others.Label',
    defaultMessage: 'Others contributors',
  },
  WorkflowStepOthersButtonsAdd: {
    id: 'Workflow.Step.Buttons.Others.Add',
    defaultMessage: 'Add another contributor',
  },
  WorkflowStepDialogsDeleteTitle: {
    id: 'Workflow.Step.Dialogs.Delete.Title',
    defaultMessage: 'Delete a workflow step',
  },
  WorkflowStepDialogsDeleteMessage: {
    id: 'Workflow.Step.Dialogs.Delete.Message',
    defaultMessage: 'You are about to delete the workflow step <b>{name}</b>',
  },
  WorkflowStepAuthorLabel: {
    id: 'Workflow.Step.Author.Label',
    defaultMessage: 'Document author',
  },
  WorkflowContributorAccessLabel: {
    id: 'Workflow.Contributor.Fields.Access.Label',
    defaultMessage: 'Contributor access',
  },
  WorkflowContributorAccessRequired: {
    id: 'Workflow.Contributor.Fields.Access.Required',
    defaultMessage: 'Contributor access is required',
  },
  WorkflowConditionOperatorEqualLabel: {
    id: 'Workflow.Condtion.Operator.Equal.Label',
    defaultMessage: 'Equals',
  },
  WorkflowConditionOperatorNotEqualLabel: {
    id: 'Workflow.Condtion.Operator.NotEqual.Label',
    defaultMessage: 'Different to',
  },
  WorkflowConditionOperatorLikeLabel: {
    id: 'Workflow.Condtion.Operator.Like.Label',
    defaultMessage: 'Contains',
  },
  WorkflowConditionOperatorNotLikeLabel: {
    id: 'Workflow.Condtion.Operator.NotLike.Label',
    defaultMessage: 'Does not contain',
  },
  WorkflowConditionOperatorIsNullLabel: {
    id: 'Workflow.Condtion.Operator.IsNull.Label',
    defaultMessage: 'Is null',
  },
  WorkflowConditionOperatorIsNotNullLabel: {
    id: 'Workflow.Condtion.Operator.IsNotNull.Label',
    defaultMessage: 'Is not null',
  },
  WorkflowConditionOperatorIsEmptyLabel: {
    id: 'Workflow.Condtion.Operator.IsEmpty.Label',
    defaultMessage: 'Is empty',
  },
  WorkflowConditionOperatorIsNotEmptyLabel: {
    id: 'Workflow.Condtion.Operator.IsNotEmpty.Label',
    defaultMessage: 'Is not empty',
  },
  WorkflowConditionOperatorOperatorLabel: {
    id: 'Workflow.Condtion.Operator.Operator.Label',
    defaultMessage: 'Operator',
  },
  WorkflowConditionTypeAddRuleLabel: {
    id: 'Workflow.Condtion.Type.AddRule.Label',
    defaultMessage: 'Add a rule',
  },
  WorkflowConditionTypeAddGroupLabel: {
    id: 'Workflow.Condtion.Type.AddGroup.Label',
    defaultMessage: 'Add a group',
  },
  WorkflowConditionTypeValueLabel: {
    id: 'Workflow.Condtion.Type.Value.Label',
    defaultMessage: 'Value',
  },
  WorkflowConditionTypeFieldLabel: {
    id: 'Workflow.Condtion.Type.Field.Label',
    defaultMessage: 'Field',
  },
  WorkflowConditionTypeSelectFieldLabel: {
    id: 'Workflow.Condtion.Type.SelectField.Label',
    defaultMessage: 'Select a field',
  },
  WorkflowConditionTypeSelectOperatorLabel: {
    id: 'Workflow.Condtion.Type.SelectOperator.Label',
    defaultMessage: 'Select an operator',
  },
  WorkflowConditionValuePlaceholderLabel: {
    id: 'Workflow.Condtion.Value.Placeholder.Label',
    defaultMessage: 'Enter a value',
  },
  WorkflowConditionRemoveRuleOkLabel: {
    id: 'Workflow.Condtion.Remove.Ok.Label',
    defaultMessage: 'Yes',
  },
  WorkflowConditionRemoveRuleConfirmMessageLabel: {
    id: 'Workflow.Condtion.Remove.Rule.ConfirmMessage.Label',
    defaultMessage: 'Are you sure delete this rule?',
  },
  WorkflowConditionRemoveGroupConfirmMessageLabel: {
    id: 'Workflow.Condtion.Remove.Group.ConfirmMessage.Label',
    defaultMessage: 'Are you sure delete this group?',
  },
  WorkflowConditionCunjunctionNotLabel: {
    id: 'Workflow.Condtion.Conjunction.Not.Label',
    defaultMessage: 'Not',
  },
  WorkflowConditionCunjunctionANDLabel: {
    id: 'Workflow.Condtion.Conjunction.AND.Label',
    defaultMessage: 'And',
  },
  WorkflowConditionCunjunctionORLabel: {
    id: 'Workflow.Condtion.Conjunction.OR.Label',
    defaultMessage: 'Or',
  },
  WorkflowConditionTypeAddSubRuleLabel: {
    id: 'Workflow.Condtion.Type.AddSubRule.Label',
    defaultMessage: 'Add a subrule',
  },
  WorkflowStepAccessNoneLabel: {
    id: 'Workflow.Contributor.Fields.Access.0.Label',
    defaultMessage: 'None',
  },
  WorkflowStepAccessFormLabel: {
    id: 'Workflow.Contributor.Fields.Access.2.Label',
    defaultMessage: 'Form',
  },
  WorkflowStepAccessFormRevisionLabel: {
    id: 'Workflow.Contributor.Fields.Access.3.Label',
    defaultMessage: 'Form / revision',
  },
  WorkflowStepAccessRevisionLabel: {
    id: 'Workflow.Contributor.Fields.Access.4.Label',
    defaultMessage: 'Revision (comment and modification)',
  },
  WorkflowStepAccessRevisionCommentsLabel: {
    id: 'Workflow.Contributor.Fields.Access.5.Label',
    defaultMessage: 'Revision (comment only)',
  },
  WorkflowStepAccessReadOnlyLabel: {
    id: 'Workflow.Contributor.Fields.Access.6.Label',
    defaultMessage: 'Read only',
  },
  WorkflowStepSendForSignatureLabel: {
    id: 'Workflow.Contributor.Fields.Access.7.Label',
    defaultMessage: 'Send for signature',
  },
  WorkflowStepAccessSignatureLabel: {
    id: 'Workflow.Contributor.Fields.Access.1.Label',
    defaultMessage: 'Signature',
  },
  WorkflowStepConditionButtonsAdd: {
    id: 'Workflow.Contributor.Buttons.Condition.Add',
    defaultMessage: 'Add a condition',
  },
  WorkflowStepConditionButtonsEdit: {
    id: 'Workflow.Contributor.Buttons.Condition.Edit',
    defaultMessage: 'Edit condition',
  },
  WorkflowContributorDialogsDeleteTitle: {
    id: 'Workflow.Contributor.Dialogs.Delete.Title',
    defaultMessage: 'Delete a contributor',
  },
  WorkflowContributorDialogsDeleteMessage: {
    id: 'Workflow.Contributor.Dialogs.Delete.Message',
    defaultMessage: 'You are about to delete the contributor <b>{name}</b>',
  },
  WorkflowContributorEntityRequired: {
    id: 'Workflow.Contributor.Fields.Entity.Required',
    defaultMessage: 'Contributor is required',
  },
  WorkflowSettingsTitle: {
    id: 'Workflow.Settings.Title',
    defaultMessage: 'Workflow settings: <b>{name}</b>',
  },
  WorkflowSettingsSave: {
    id: 'Workflow.Settings.Save.Success',
    defaultMessage: 'Workflow settings has been saved successfully',
  },
  OnlyOneContributorByStep: {
    id: 'Workflow.Step.Fields.Contributors.OnlyOneContributorByStep',
    defaultMessage: 'Only one contributor per workflow step',
  },
  WorkflowContributorsRequired: {
    id: 'Workflow.Step.Fields.Contributors.Required',
    defaultMessage: 'This workflow step requires at least one contributor',
  },
  WorkflowContributorPickerLabel: {
    id: 'Workflow.ContributorPicker.Label',
    defaultMessage: 'Collaborateur',
  },
  WorkflowContributorPickerUsersLabel: {
    id: 'Workflow.ContributorPicker.Users.Label',
    defaultMessage: 'Users',
  },
  WorkflowContributorPickerOpenText: {
    id: 'Workflow.ContributorPicker.Open.Text',
    defaultMessage: 'Open',
  },
  WorkflowContributorPickerCloseText: {
    id: 'Workflow.ContributorPicker.Close.Text',
    defaultMessage: 'Close',
  },
  WorkflowContributorPickerClearText: {
    id: 'Workflow.ContributorPicker.Clear.Text',
    defaultMessage: 'Clear',
  },
  WorkflowContributorPickerLoadingText: {
    id: 'Workflow.ContributorPicker.Loading.Text',
    defaultMessage: 'Loading...',
  },
  WorkflowContributorPickerNoOptionsText: {
    id: 'Workflow.ContributorPicker.NoOptions.Text',
    defaultMessage: 'No options',
  },
  WorkflowStepFieldsNotifyContributorsLabel: {
    id: 'Workflow.Step.Fields.NotifyContributors.Label',
    defaultMessage:
      'People to be notified following the validation of the step',
  },
  WorkflowStepButtonsNotifyContributorsAdd: {
    id: 'Workflow.Step.Buttons.NotifyContributors.Add',
    defaultMessage: 'Add a contributor to notify',
  },
  WorkflowStepFieldsCompletionMessageLabel: {
    id: 'Workflow.Step.Fields.CompletionMessage.Label',
    defaultMessage: 'Customize the notification to send after step validation',
  },
  WorkflowStepFieldsCompletionMessagePlaceholder: {
    id: 'Workflow.Step.Fields.CompletionMessage.Placeholder',
    defaultMessage: 'Rédigez votre message',
  },
  WorkflowContributorButtonsRemoveNextStepContributorWarningText: {
    id: 'Workflow.Contributor.Buttons.Remove.NextStepContributorWarningText',
    defaultMessage:
      'You cannot delete this contributor as they belong to the next step.',
  },
  WorkflowStepDuration: {
    id: 'Workflow.Step.Duration.Message',
    defaultMessage: 'Duration step : <b>{duration}</b> day(s)',
  },
  WorkflowStepContributorBy: {
    id: 'Workflow.Step.Contributor.By',
    defaultMessage: 'by',
  },
  WorkflowStepContributorFor: {
    id: 'Workflow.Step.Contributor.Delegator',
    defaultMessage: 'for {delegatorUserName}',
  },
  WorkFlowStepEditTitle: {
    id: 'WorkFlow.Step.Edit.Title',
    defaultMessage: 'Edit the workflow for this document',
  },
  WorkflowStepButtonsBackLabel: {
    id: 'Workflow.Step.Buttons.Back.Label',
    defaultMessage: 'Back to the document',
  },
  WorkflowStepCompletedLabel: {
    id: 'Workflow.Step.Completed.Label',
    defaultMessage: 'All steps are completed',
  },
  ReaderStepperEditLabel: {
    id: 'Reader.Stepper.Edit.Label',
    defaultMessage: 'Edit',
  },
  WorkflowContributorModalRemoveTitle: {
    id: 'Workflow.Contributor.Modal.Remove.Title',
    defaultMessage: 'Remove contributor from workspace',
  },
  WorkflowContributorModalRemoveDescription: {
    id: 'Workflow.Contributor.Modal.Remove.Description',
    defaultMessage:
      'You are about to exclude the contributor from the collaborative workspace. This user will no longer be able to access the document.\n\r\n\rHowever, any revisions and comments will still be visible to other users.',
  },
  WorkflowContributorModalAddTitle: {
    id: 'Workflow.Contributor.Modal.Add.Title',
    defaultMessage: 'Add a contributor to the step',
  },
  WorkflowContributorModalAddSuccess: {
    id: 'Workflow.Contributor.Modal.Add.Success',
    defaultMessage: 'The contributor has been added in step',
  },
  WorkflowContributorModalRemoveSuccess: {
    id: 'Workflow.Contributor.Modal.remove.Success',
    defaultMessage: 'The contributor has been removed from the step',
  },
  WorkflowContributorModalSearchTitle: {
    id: 'Workflow.Contributor.Modal.Search.Title',
    defaultMessage: 'Add a contributor',
  },
  WorkflowContributorModalSearchDescription: {
    id: 'Workflow.Contributor.Modal.Search.Description',
    defaultMessage: 'Select the type of user to add',
  },
  WorkflowContributorModalSearchOption1Title: {
    id: 'Workflow.Contributor.Modal.Search.Option.1.Title',
    defaultMessage: 'Existing user',
  },
  WorkflowContributorModalSearchOption2Title: {
    id: 'Workflow.Contributor.Modal.Search.Option.2.Title',
    defaultMessage: 'New external user',
  },
  WorkflowContributorModalSearchSelectLabel: {
    id: 'Workflow.Contributor.Modal.Search.Select.Label',
    defaultMessage: 'Contributor',
  },
  WorkflowContributorPickerAddExternalUserLabel: {
    id: 'Workflow.ContributorPicker.AddExternalUser.Label',
    defaultMessage: 'Add an external user',
  },
});
