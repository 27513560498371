import frMessages from './fr.json';
import enMessages from './en.json';
import esMessages from './es.json';

const messages = {
  fr: frMessages,
  en: enMessages,
  es: esMessages,
};

export default messages;
