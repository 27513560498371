import { defineMessages } from 'react-intl';

export default defineMessages({
  ExpertiseButtonsAdd: {
    id: 'Expertise.Buttons.Add',
    defaultMessage: 'Add an expertise',
  },
  DepartmentButtonsAdd: {
    id: 'Department.Buttons.Add',
    defaultMessage: 'Add a department',
  },
  DepartmentNoResult: {
    id: 'Department.NoResult',
    defaultMessage: 'No department for the current business unit',
  },
  DepartmentDialogsCreateTitle: {
    id: 'Department.Dialogs.Create.Title',
    defaultMessage: 'Add a department',
  },
  DepartmentDialogsUpdateTitle: {
    id: 'Department.Dialogs.Update.Title',
    defaultMessage: 'Modify a department',
  },
  DepartmentDialogsDeleteTitle: {
    id: 'Department.Dialogs.Delete.Title',
    defaultMessage: 'Delete a department',
  },
  DepartmentDialogsDeleteMessage: {
    id: 'Department.Dialogs.Delete.Message',
    defaultMessage:
      'You are about to delete the department <b>{name} ({externalId})</b>',
  },
  DepartmentFieldsNameLabel: {
    id: 'Department.Fields.Name.Label',
    defaultMessage: 'Department name',
  },
  DepartmentFieldsNameRequired: {
    id: 'Department.Fields.Name.Required',
    defaultMessage: 'Department name is required',
  },
  DepartmentFieldsExternalIdLabel: {
    id: 'Department.Fields.ExternalId.Label',
    defaultMessage: 'Department number',
  },
  DepartmentFieldsExternalIdRequired: {
    id: 'Department.Fields.ExternalId.Required',
    defaultMessage: 'Department number is required',
  },
  DepartmentFieldsNotificationModeLabel: {
    id: 'Department.Fields.NotificationMode.Label',
    defaultMessage: 'Role of the Expert during the negociation',
  },
  DepartmentFieldsNotificationModeRequired: {
    id: 'Department.Fields.NotificationMode.Required',
    defaultMessage: 'Role of the Expert during the negociation is required',
  },
  DepartmentFieldsNotificationModeProjectManager: {
    id: 'Department.Fields.NotificationMode.ProjectManager',
    defaultMessage:
      'Experts only intervene at the request of the offer leader who is their only intermediary',
  },
  DepartmentFieldsNotificationModeExpert: {
    id: 'Department.Fields.NotificationMode.Expert',
    defaultMessage:
      'Experts are contacted directly as soon as a supplier asks a question about the contract',
  },
});
