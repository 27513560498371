import React from 'react';
import { BubbleMenu } from '@tiptap/react';
import ButtonGroup from '@mui/material/ButtonGroup';

import { useTiptapEditor } from '../use-tiptap-editor';
import { containsNode as selectionContains, isEmpty } from '../utils/selection';
import { containsNode as documentContains } from '../utils/document';

import ToolbarClause from './toolbar-clause';

function BubbleClauseOnly() {
  const editor = useTiptapEditor();
  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{ duration: 100 }}
      shouldShow={({ editor, state: { doc, selection } }) => {
        const {
          schema: { nodes },
        } = editor;
        if (selection.constructor.name === 'CellSelection') return false;
        if (editor.isActive(nodes.clausevariant.name)) return false;
        if (editor.isActive(nodes.clause.name)) return false;
        if (selectionContains(doc, selection, nodes.clausevariant))
          return false;
        if (selectionContains(doc, selection, nodes.clause)) return false;
        const variable = editor.isActive(nodes.variable.name);
        if (isEmpty(selection) && !variable) return false;
        if (
          documentContains(doc, nodes.variable) &&
          !selectionContains(doc, selection, nodes.variable)
        )
          return false;
        return true;
      }}
    >
      <ButtonGroup
        variant="text"
        sx={{
          bgcolor: 'common.white',
          borderStyle: 'solid',
          borderColor: 'primary.light',
        }}
      >
        <ToolbarClause />
      </ButtonGroup>
    </BubbleMenu>
  );
}

export default BubbleClauseOnly;
