import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ComponentProps } from 'react';
import useFetch from '@hooks/useFetch';
import { DriveEntityType } from '@views/constants';
import ShareMenuTabs from './ShareMenuTabs/ShareMenuTabs';

type ShareMenuProps = {
  documentInformation: any;
  entityId: string;
  entityType: DriveEntityType;
  parentId: string;
  contract?: any;
  fetching?: boolean;
  isContractView: boolean;
  onClose(): void;
  termSheetFetch: ComponentProps<typeof ShareMenuTabs>['termSheetFetch'];
  hideInfoTab?: boolean;
  contractFetch: ComponentProps<typeof ShareMenuTabs>['contractFetch'];
  documentLinksFetch: ComponentProps<typeof ShareMenuTabs>['documentLinksfetch'];
};

export default function ShareMenu(props: ShareMenuProps) {
  const {
    documentInformation,
    onClose,
    isContractView,
    fetching: fetchingProp,
    parentId,
    entityId,
    entityType,
    contract: contractProp,
    termSheetFetch,
    hideInfoTab,
    contractFetch,
    documentLinksFetch,
  } = props;

  const { fetching: fetchingContract, data: contractData } = useFetch({
    url: `contracts/${entityId}?includeWorkflow=true&includeTermSheetTemplate=true&includeCurrentDocumentAnalysis=true`,
    lazy: !entityId,
    method: 'GET',
  });

  const fetching = fetchingProp || fetchingContract;
  const contract = contractProp ?? contractData;

  return (
    <Box
      sx={{
        height: '100%',
        bgcolor: 'grey.100',
        p: '24px',
        borderLeft: theme => `solid 1px ${theme.palette.grey[400]}`,
        overflow: 'auto',
      }}
    >
      <Stack
        sx={{ mb: 4 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3">{documentInformation?.name}</Typography>
        {!isContractView && (
          <IconButton onClick={() => onClose()} edge="end" size="small">
            <CloseIcon />
          </IconButton>
        )}
      </Stack>

      <ShareMenuTabs
        fetching={fetching}
        parentId={parentId}
        entityId={entityId}
        entityType={entityType}
        contract={contract}
        isContractView={isContractView}
        item={documentInformation}
        termSheetFetch={termSheetFetch}
        hideInfoTab={hideInfoTab}
        documentLinksfetch={documentLinksFetch}
        contractFetch={contractFetch}
      />
    </Box>
  );
}
