// check if the a (group of) node type is/are in the given ranges
// nodeType is either a node type or an array of node type
export function hasNodeTypeInRange(doc, nodeType, startPos, endPos) {
  return getNodeNodeTypeInRange(doc, nodeType, startPos, endPos).length > 0;
}

// returns the nodes which are in the given ranges of the type of nodeType
// nodeType is either a node type or an array of node type
export function getNodeNodeTypeInRange(doc, nodeType, startPos, endPos) {
  const checkTypes = Array.isArray(nodeType) ? nodeType : [nodeType];
  const nodesFound = [];

  doc.descendants((node, pos) => {
    if (endPos < pos) {
      return false;
    }
    if (checkTypes.includes(node.type) && pos >= startPos && pos <= endPos) {
      nodesFound.push({ node, pos });
    }
  });
  return nodesFound;
}

export const containsNode = (doc, selection, type) => {
  let contains = false;
  doc.nodesBetween(selection.from, selection.to, node => {
    contains |= node.type === type;
  });
  return contains;
};

export const isEmpty = selection => {
  if (selection.empty) return true;
  const fragment = selection.content().content;
  return fragment.textBetween(0, fragment.size).trim() === '';
};

export const selectionContent = selection => {
  const fragment = selection.content().content;
  return fragment.textBetween(0, fragment.size);
};
