import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormikSelect from '@components/Formik/FormikSelect';
import { nexitySignatureOptions } from '@shells/nexity/nexity-constants';

function SignatureFormFields({ readOnly = false }) {
  return (
    <FormikSelect
      required
      disabled={readOnly}
      margin="dense"
      color="secondary"
      name="signatureOption"
      label="Type de signature"
      FormControlProps={{
        color: 'secondary',
        margin: 'dense',
        disabled: readOnly,
      }}
    >
      <MenuItem value={nexitySignatureOptions.inPerson}>
        Signature sur place avec votre conseiller
      </MenuItem>
      <MenuItem value={nexitySignatureOptions.online}>
        Signature à distance
      </MenuItem>
    </FormikSelect>
  );
}

function SignatureForm({ readOnly = false }) {
  return (
    <Stack direction="column" gap={1} pt={1}>
      <Typography variant="h5" gutterBottom>
        Signature Docusign du contrat de réservation
      </Typography>
      <SignatureFormFields readOnly={readOnly} />
    </Stack>
  );
}

export default SignatureForm;
