import { getParentAlignment } from './clean-pasted-html';

export function fixPastedImages(htmlNodes, originalHtml) {
  const images = [...htmlNodes.querySelectorAll('img, v\\3Aimagedata')];

  for (const image of images) {
    // replace image by image-plugin div to handle new image
    const div = document.createElement('div');
    div.className = 'imagePluginRoot';
    div.setAttribute('imageplugin-align', getParentAlignment(image));
    div.setAttribute('imageplugin-width', image.width ?? null);
    div.setAttribute('imageplugin-height', image.height ?? null);
    div.setAttribute('imageplugin-src', image.src);

    // handle images from word
    const vshapesId =
      image.tagName === 'V:IMAGEDATA'
        ? [image.parentNode.id]
        : image.getAttribute('v:shapes')?.split(' ') ?? [];
    let shapesId = [];

    for (const vshapeId of vshapesId) {
      const regex = new RegExp(`<v:shape[^<>]*id="${vshapeId}"[^<>]*>`);
      const vShape = regex.exec(originalHtml);
      if (vShape && vShape[0]) {
        const shapeId = /o:spid="(?<spid>[^"]*)"/
          .exec(vShape[0])
          ?.groups.spid?.substring(7); // extract spid and get id afer _x0000_

        if (shapeId) {
          shapesId.push(shapeId);
        }
      }
    }

    if (shapesId.length > 0) {
      div.setAttribute('imageplugin-shapeId', shapesId.join(','));
    }

    image.parentNode.insertBefore(div, image); // replace image by div
    image.remove();
  }
}

export function hexToBase64(hex) {
  const hexaChars = hex.match(/[0-9a-f]{2}/gi);
  const decChars = [];

  for (let i = 0; i < hexaChars.length; i++) {
    decChars.push(String.fromCharCode(parseInt(hexaChars[i], 16)));
  }

  const dec = decChars.join('');
  return window.btoa(dec);
}

function getRtfData(rtf, letter, pType, rtfMap) {
  let imgs = rtf.split(pType);

  for (let i = 1; i < imgs.length; i++) {
    let imgData = imgs[i];
    imgData = imgData.split('shplid');

    if (imgData.length > 1) {
      imgData = imgData[1];
      let id = '';
      let t = 0;

      while (t < imgData.length) {
        if (
          imgData[t] === '\\' ||
          imgData[t] === '{' ||
          imgData[t] === ' ' ||
          imgData[t] === '\r' ||
          imgData[t] === '\n'
        ) {
          break;
        }

        id += imgData[t];
        t++;
      }

      const bliptabSplit = imgData.split('bliptag');

      if (bliptabSplit && bliptabSplit.length < 2) {
        continue;
      }

      let imageType = null;

      if (bliptabSplit[0].indexOf('pngblip') !== -1) {
        imageType = 'image/png';
      } else if (bliptabSplit[0].indexOf('jpegblip') !== -1) {
        imageType = 'image/jpeg';
      }

      if (!imageType) {
        continue;
      }

      const bracketSplit = bliptabSplit[1].split('}');

      if (bracketSplit && bracketSplit.length < 2) {
        continue;
      }

      let spaceSplit = void 0;

      if (
        bracketSplit.length > 2 &&
        bracketSplit[0].indexOf('blipuid') !== -1
      ) {
        spaceSplit = bracketSplit[1].split(' ');
      } else {
        spaceSplit = bracketSplit[0].split(' ');

        if (spaceSplit && spaceSplit.length < 2) {
          continue;
        }

        spaceSplit.shift();
      }

      const image_hex = spaceSplit.join('');
      rtfMap[letter + id] = {
        imageHex: image_hex,
        imageType: imageType,
      };
    }
  }
}

export function buildRtfMap(rtf) {
  const rtfMap = {};

  getRtfData(rtf, 'i', '\\shppict', rtfMap);
  getRtfData(rtf, 's', '\\shp{', rtfMap);
  return rtfMap;
}
