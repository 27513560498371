import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { SearchFilter } from './Filters/helpers/types';
import { getFilterName } from './Filters/FilterWrapper';

interface ButtonSearchFilterProps {
  id: string;
  isTermSheet: boolean;
  isMultiple: boolean;
  advancedSearchRequest?: any;
  onClick: (event: any, id: string) => void;
  filter: SearchFilter;
}

function ButtonSearchFilter({
  id,
  isTermSheet,
  isMultiple,
  advancedSearchRequest = undefined,
  onClick,
  filter,
}: ButtonSearchFilterProps) {
  const intl = useIntl();

  const variantBtn = () => {
    if (
      advancedSearchRequest === undefined ||
      Object.keys(advancedSearchRequest).length === 0
    ) {
      return 'outlined';
    }
    if (isTermSheet) {
      const index = advancedSearchRequest?.termSheetQuery?.findIndex(
        (x: any) => x?.elementId === id
      );
      if (isMultiple) {
        return index >= 0 &&
          advancedSearchRequest?.termSheetQuery[index]?.items > 0
          ? 'contained'
          : 'outlined';
      }
      return index >= 0 &&
        advancedSearchRequest?.termSheetQuery[index]?.items !== ''
        ? 'contained'
        : 'outlined';
    }
    if (isMultiple) {
      return advancedSearchRequest?.[id]?.length > 0 ? 'contained' : 'outlined';
    }
    return (advancedSearchRequest?.[id] ?? '') !== ''
      ? 'contained'
      : 'outlined';
  };

  return (
    <Button
      size="small"
      variant={variantBtn()}
      color="secondary"
      disableElevation
      onClick={event => onClick(event, id)}
      endIcon={<KeyboardArrowDownIcon />}
      sx={{ borderRadius: 1, fontSize: '0.60rem', letterSpacing: '0.60px' }}
    >
      {getFilterName(intl, filter)}
    </Button>
  );
}

export default ButtonSearchFilter;
