export const clean = str => {
  const trimmed = (str ?? '').trim();
  return trimmed.length === 0
    ? { value: '', first: '' }
    : { value: trimmed, first: trimmed[0].toUpperCase() };
};

export const compute = (email, firstName = '', lastName = '', extra = '') => {
  const cleaned = {
    email: clean(email),
    firstName: clean(firstName),
    lastName: clean(lastName),
    extra: clean(extra),
  };
  cleaned.userName = {
    value: `${cleaned.firstName.value} ${cleaned.lastName.value}`.trim(),
    first: `${cleaned.firstName.first}${cleaned.lastName.first}`.trim(),
  };
  if (cleaned.userName.value.length > 0) {
    if (extra) {
      cleaned.userName.value = `${cleaned.userName.value} (${extra})`;
    }
    return cleaned.userName;
  }
  return cleaned.email;
};

export const username = ({
  email,
  firstName = '',
  lastName = '',
  extra = '',
}) => compute(email, firstName, lastName, extra);

export const getUserDisplayName = obj =>
  compute(obj?.email, obj?.firstName, obj?.lastName, obj?.externalId).value;

export const getUserFullName = obj =>
  compute(obj?.email, obj?.firstName, obj?.lastName, null).value;
