import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormikTextField from '@components/Formik/FormikTextField';
import FormikSelect from '@components/Formik/FormikSelect';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { termSheetValueTypeOptions } from '@utils/api/types';
import { useField, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import FormikCheckbox from '@components/Formik/FormikCheckbox';
import FormikTermSheetElementSelect from '@components/Formik/FormikTermSheetElementSelect';
import FormikDatePicker from '@components/Formik/FormikDatePicker';
import FormikTimePicker from '@components/Formik/FormikTimePicker';
import FormikDateTimePicker from '@components/Formik/FormikDateTimePicker';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import BorderedIcon from '../../MangeData/components/BorderedIcon';

export default function AddElementToTermSheetForm({
  disabled,
  title,
  actionButtonText,
  defaultActionButtonText,
  error,
}) {
  const { values } = useFormikContext();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const label = (
    <FormattedMessage
      id="ManageDataView.Dialogs.Create.Fields.Content.Label"
      defaultMessage="Content"
    />
  );
  const name = 'content';
  const [, , { setValue: setElementNameValue }] = useField('name');
  const [, , { setValue: setElementIdValue }] = useField('elementId');
  const [, , { setValue: setElementIconValue }] = useField('icon');
  const [, , { setValue: setElementEntityTypeValue }] = useField('entityType');

  return (
    <DialogWithClose
      titleLabel={title}
      dialogActions={
        <Button
          onClick={() => {
            if (!values.icon) {
              enqueueSnackbar(
                formatMessage({
                  id: 'CreatePivotForm.Icon.Validate.Error',
                }),
                {
                  variant: 'error',
                  autoHideDuration: 4000,
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                }
              );
            }
          }}
          type="submit"
          disabled={disabled}
        >
          <FormattedMessage
            id={actionButtonText}
            defaultMessage={defaultActionButtonText}
          />
        </Button>
      }
    >
      <Stack gap={2}>
        <Stack gap={1}>
          <Stack direction="row" alignItems="center">
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
                fontSize: '0.7875rem',
                color: 'secondary.main',
              }}
            >
              <FormattedMessage
                id="ManageDataView.Dialogs.Create.Fields.Icon.Label"
                defaultMessage="Icon"
              />
            </Typography>
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
                fontSize: '0.7875rem',
                color: 'error.main',
                ml: '2px',
              }}
            >
              *
            </Typography>
          </Stack>
          <BorderedIcon disabled />
        </Stack>
        <FormikTermSheetElementSelect
          required
          label={
            <FormattedMessage
              id="ManageDataView.Dialogs.Create.Fields.Name.Label"
              defaultMessage="Name"
            />
          }
          name="name"
          color="secondary"
          margin="dense"
          onChange={(event, newElement) => {
            setElementNameValue(newElement?.name ?? null);
            setElementIdValue(newElement?.id ?? null);
            setElementIconValue(
              newElement?.icon === '' ? 'star' : newElement?.icon
            );
            setElementEntityTypeValue(newElement?.valueType);
          }}
          disabled={disabled}
        />
        <FormikSelect
          required
          disabled
          label={
            <FormattedMessage
              id="ManageDataView.Dialogs.Create.Fields.DataType.Label"
              defaultMessage="Type"
            />
          }
          name="entityType"
          FormControlProps={{
            color: 'secondary',
            margin: 'dense',
          }}
          margin="dense"
        >
          {termSheetValueTypeOptions.map(type => (
            <MenuItem key={type.title} value={type.title}>
              <FormattedMessage
                id={type.messageDescriptorId}
                defaultMessage={type.title}
              />
            </MenuItem>
          ))}
        </FormikSelect>
        {values.entityType === 'Number' && (
          <FormikTextField
            as="field"
            label={label}
            name={name}
            type="number"
            disabled={disabled}
            sx={{ marginTop: 0 }}
          />
        )}
        {values.entityType === 'Text' && (
          <FormikTextField
            as="field"
            label={label}
            name={name}
            type="text"
            disabled={disabled}
            sx={{ marginTop: 0 }}
          />
        )}
        {values.entityType === 'Boolean' && (
          <FormikCheckbox
            as="field"
            name={name}
            label={values.entityType}
            disabled={disabled}
            sx={{ marginTop: 0 }}
          />
        )}
        {values.entityType === 'Timestamp' && (
          <FormikDateTimePicker
            as="field"
            label={label}
            name={name}
            disabled={disabled}
            sx={{ marginTop: 0 }}
          />
        )}
        {values.entityType === 'DateOnly' && (
          <FormikDatePicker
            as="field"
            label={label}
            name={name}
            disabled={disabled}
            sx={{ marginTop: 0 }}
          />
        )}
        {values.entityType === 'TimeOnly' && (
          <FormikTimePicker
            as="field"
            label={label}
            name={name}
            disabled={disabled}
            sx={{ marginTop: 0 }}
          />
        )}
        <ErrorMessage error={error} />
      </Stack>
    </DialogWithClose>
  );
}
