import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React from 'react';

export type TiptapContextListener = (view: EditorView, state: EditorState) => void;

export type TiptapContextValue = {
  editor: unknown;
  setEditor():unknown,
  users: unknown[];
  setUsers(): unknown;
  listeners: unknown;
  subscribe(listener: TiptapContextListener): () => void;
  ReactSyncExternalStorePlugin: unknown;
}

export const TiptapContext = React.createContext<TiptapContextValue>({
  editor: undefined,
  setEditor: () => {},
  users: [],
  setUsers: () => {},
  listeners: undefined,
  subscribe: (_listener) => { return () => {}; },
  ReactSyncExternalStorePlugin: undefined,
});

TiptapContext.displayName = 'TiptapContext';

export const useTiptapContext = () => {
  const context = React.useContext(TiptapContext);
  if (!context) {
    throw Error(
      'TiptapContext is not defined. Did you forget to wrap your component in a TiptapProvider?'
    );
  }
  return context;
};
