import React, { useMemo, useState } from 'react';
import Stepper, { stepperClasses } from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import CachedIcon from '@mui/icons-material/Cached';
import HistoryIcon from '@mui/icons-material/History';
import { styled } from '@mui/material/styles';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Box from '@mui/material/Box';
import { FormattedMessage, useIntl } from 'react-intl';
import ContributorChip from '@components/Chips/ContributorChip';
import { bold } from '@utils/chunks';
import { useRecoilValue } from 'recoil';
import {
  tenantRolesIsInternalSelector,
  tenantUserIdentifierSelector,
} from '@recoil/tenant-token';
import ContractExternalContributor from '@components/External/ContractExternalContributor';
import GenericAlert from '@components/Alerts/GenericAlert';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@components/Buttons/IconButtons/IconButton';
import ConfirmModal from '@components/Modals/ConfirmModal';
import useFetch from '@hooks/useFetch';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compute } from '@utils/username-helpers';
import { workflowContributorTypes } from '@views/constants';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';

const getStatus = (endTime, isActiveStep) => {
  if (endTime != null) return 'completed';
  return isActiveStep ? 'inprogress' : 'todo';
};

const stepIsValidateByUser = (stepValidation, contributorEntityId) => {
  if (!stepValidation?.length) {
    return null;
  }

  return stepValidation.find(x => x.validatorUserId === contributorEntityId);
};

const colors = {
  completed: {
    iconBackground: '#91D754',
    iconColor: '#ffffff',
    textColor: '#91d754',
    textBackground: '#f2faea',
    connectorBorderColor: '#91D754',
  },
  inprogress: {
    iconBackground: '#e2f3ff',
    iconColor: '#0D99FF',
    textColor: '#8dcfff',
    textBackground: '#e2f3ff',
    connectorBorderColor: '#9e9e9e',
  },
  todo: {
    iconBackground: '#E8E8E8',
    iconColor: '#9e9e9e',
    textColor: '#9e9e9e',
    textBackground: 'transparent',
    connectorBorderColor: '#9e9e9e',
  },
};

const StyledStepper = styled(Stepper)(() => ({
  [`&.${stepperClasses.vertical} .MuiStepContent-root`]: {
    borderLeft: `solid 1px ${colors.todo.connectorBorderColor}`,
  },
}));

const StyledConnector = styled(StepConnector, {
  shouldForwardProp: prop => prop !== 'activeStep',
})(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: colors.todo.connectorBorderColor,
    minHeight: 0,
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: colors.todo.connectorBorderColor,
    minHeight: 0,
  },
}));

const StyleLabel = styled(StepLabel)(({ status, open }) => ({
  [`& .MuiStepLabel-labelContainer`]: {
    backgroundColor: colors[status].textBackground,
  },
  [`& .MuiStepLabel-iconContainer`]: !open && {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  [`& .MuiStepLabel-label`]: {
    color: colors[status].textColor,
    borderRadius: '4px',
    padding: '4px 6px',
    fontWeight: 'normal',
    fontSize: '13px',
  },
  [`& .MuiStepLabel-label.Mui-completed`]: {
    color: colors[status].textColor,
  },
  [`& .MuiStepLabel-label.Mui-active`]: {
    color: colors[status].textColor,
  },
}));

const StyledStepIconRoot = styled('div', {
  shouldForwardProp: prop => prop !== 'backgroundColor',
})(({ backgroundColor }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '100%',
  backgroundColor,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const icons = {
  completed: () => (
    <StyledStepIconRoot backgroundColor={colors.completed.iconBackground}>
      <CheckIcon
        sx={{ color: colors.completed.iconColor, fontSize: '12px !important' }}
      />
    </StyledStepIconRoot>
  ),
  inprogress: () => (
    <StyledStepIconRoot backgroundColor={colors.inprogress.iconBackground}>
      <CachedIcon
        sx={{ color: colors.inprogress.iconColor, fontSize: '12px !important' }}
      />
    </StyledStepIconRoot>
  ),
  todo: () => (
    <StyledStepIconRoot backgroundColor={colors.todo.iconBackground}>
      <HistoryIcon
        sx={{ color: colors.todo.iconColor, fontSize: '12px !important' }}
      />
    </StyledStepIconRoot>
  ),
};

function ContributorDisplay({ userId, user, disabled, type, stepValidation }) {
  const validationUser = stepIsValidateByUser(stepValidation, userId);
  const validationDelegateUser = validationUser?.delegateUser;
  const hasDelegateUser = Boolean(validationDelegateUser);
  const entity = hasDelegateUser ? validationDelegateUser : user;
  const entityId = entity.id;
  const contributorChip = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        my: '2px',
        height: '32px',
      }}
      key={entityId}
    >
      <ContributorChip
        key={entityId}
        sx={{
          bgcolor: 'grey.200',
          textOverflow: 'ellipsis',
          maxWidth: 160,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        type={type}
        entityId={entityId}
        entity={entity}
        authorLabel={<FormattedMessage id="Workflow.Step.Author.Label" />}
        disabled={disabled}
      />
      {validationUser ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            my: '2px',
            height: '32px',
            pl: 1,
          }}
          key={entityId}
        >
          <CheckIcon
            sx={{
              color: '#91d754',
              fontSize: '18px',
            }}
          />
        </Stack>
      ) : null}
    </Stack>
  );
  return hasDelegateUser ? (
    <Box>
      {contributorChip}
      <Stack direction="column" sx={{ pl: 1, py: '2px' }} gap={1}>
        <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
          <FormattedMessage
            id="Workflow.Step.Contributor.Delegator"
            values={{
              delegatorUserName: compute(
                // eslint-disable-next-line react/destructuring-assignment
                user.email,
                // eslint-disable-next-line react/destructuring-assignment
                user.firstName,
                // eslint-disable-next-line react/destructuring-assignment
                user.lastName
              ).value,
            }}
          />
        </Typography>
      </Stack>
    </Box>
  ) : (
    contributorChip
  );
}

function WorkflowStepContent({
  step,
  isActiveStep,
  isCreation,
  isOpen,
  isInternal,
  workFlowAccess,
  refresh = () => {},
}) {
  const status = useMemo(
    () => (isCreation ? 'completed' : getStatus(step.endTime, isActiveStep)),
    [isCreation, step, isActiveStep]
  );

  const contributors = [
    ...(step?.mandatoryContributors ?? []),
    ...(step?.otherContributors ?? []),
  ];
  const currentUserId = useRecoilValue(tenantUserIdentifierSelector);

  const intl = useIntl();
  const { doFetch: doDelete } = useFetch({
    method: 'DELETE',
    onSuccess: () => {
      toast.success(
        intl.formatMessage({ id: 'Workflow.Contributor.Modal.remove.Success' }),
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      if (refresh) {
        refresh();
      }
    },
    onError: () => {
      toast.error(intl.formatMessage({ id: 'Errors.ErrorOccured' }), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    },
  });

  const [isModalOpen, setModalOpened] = useState(false);
  const [contributorToDelete, setContributorToDelete] = useState(null);
  const close = () => setModalOpened(false);
  const { contractId } = useParams();
  const handleSubmit = () => {
    if (contributorToDelete) {
      close();
      doDelete({
        url: `contracts/${contractId}/workflow/steps/${step.id}/contributors/${contributorToDelete.id}`,
      });
    }
  };

  const deleteContributor = contributor => {
    setContributorToDelete(contributor);
    setModalOpened(true);
  };

  return (
    <>
      <StyleLabel
        StepIconComponent={icons[status]}
        status={status}
        open={isOpen}
      >
        {isOpen ? step?.name : null}
      </StyleLabel>
      {isOpen ? (
        <StepContent>
          <Box sx={{ py: '10px' }}>
            {contributors && (
              <Box>
                {contributors?.length > 0 && (
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    gap={1}
                    sx={{ my: 1 }}
                  >
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      sx={{ my: '6px' }}
                    >
                      <Typography variant="subtitle1">
                        <FormattedMessage id="Workflow.Step.Contributor.By" />
                      </Typography>
                    </Stack>
                    <Stack direction="column">
                      {contributors.map(contributor => (
                        <Stack
                          direction="column"
                          alignItems="flex-start"
                          sx={{ my: '2px' }}
                          key={contributor.id}
                          gap={1}
                        >
                          {contributor.type === workflowContributorTypes.role &&
                          contributor.assignments?.length ? (
                            contributor.assignments.map(assignment => (
                              <ErrorBoundary>
                                <ContributorDisplay
                                  userId={assignment.userId}
                                  user={assignment.user}
                                  disabled={contributor.disabled}
                                  type={1}
                                  stepValidation={step.validations}
                                />
                              </ErrorBoundary>
                            ))
                          ) : (
                            <ErrorBoundary>
                              <ContributorDisplay
                                userId={contributor.entityId}
                                user={contributor.entity}
                                disabled={contributor.disabled}
                                type={contributor.type}
                                stepValidation={step.validations}
                              />
                            </ErrorBoundary>
                          )}
                        </Stack>
                      ))}
                    </Stack>
                    <Stack direction="column">
                      {contributors.map(contributor => (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            alignItems: 'center',
                            my: '2px',
                            height: '32px',
                          }}
                          key={contributor.id}
                        >
                          {!isInternal &&
                            contributor.isAddedByExternalUser === true &&
                            contributor.creatorUserId === currentUserId &&
                            isActiveStep &&
                            !stepIsValidateByUser(
                              step.validations,
                              contributor.entityId
                            ) && (
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => deleteContributor(contributor)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                )}
              </Box>
            )}
            {step?.displayDuration ? (
              <Box>
                <Stack>
                  <Typography variant="subtitle1">
                    <FormattedMessage
                      id="Workflow.Step.Duration.Message"
                      values={{ duration: step.displayDuration, b: bold }}
                    />
                  </Typography>
                </Stack>
              </Box>
            ) : null}
            {!isInternal && isActiveStep && (
              <ContractExternalContributor
                workflowStepId={step?.id}
                workFlowAccess={workFlowAccess}
                refresh={refresh}
              />
            )}
          </Box>
        </StepContent>
      ) : null}
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={close}
        onSubmit={handleSubmit}
        title="Workflow.Contributor.Modal.Remove.Title"
        description="Workflow.Contributor.Modal.Remove.Description"
      />
    </>
  );
}

function useWorkflowActiveStep(workflow) {
  return useMemo(() => {
    let result = null;
    let completed = false;

    if (workflow.currentStepId) {
      result = workflow.steps?.findIndex(s => s.id === workflow.currentStepId);
      completed = false;
    } else {
      result = workflow.steps?.findIndex(s => !s.endTime);
      completed = result === -1 && workflow.steps?.length > 0;
    }

    return {
      activeStep:
        result === -1 || result === null || result === undefined
          ? null
          : result,
      completed,
    };
  }, [workflow]);
}

export default function WorkflowStepper({
  workflow,
  isOpen,
  workFlowAccess = null,
  hideWorkflowInfo = false,
  refresh = () => {},
}) {
  const { activeStep, completed } = useWorkflowActiveStep(workflow);

  const isInternal = useRecoilValue(tenantRolesIsInternalSelector);

  if (!workflow) return null;

  return (
    <Box sx={{ width: '100%', padding: '16px' }}>
      <StyledStepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<StyledConnector />}
      >
        {!workflow.isFirstStepCreation && isInternal && (
          <Step active>
            <WorkflowStepContent isCreation isOpen={isOpen} refresh={refresh} />
          </Step>
        )}
        {workflow.steps.map((step, stepIndex) => (
          <Step key={step.id} active>
            <WorkflowStepContent
              step={step}
              isActiveStep={stepIndex === activeStep}
              isOpen={isOpen}
              isInternal={isInternal}
              workFlowAccess={workFlowAccess}
              refresh={refresh}
            />
          </Step>
        ))}
      </StyledStepper>
      {completed && (
        <Paper square elevation={0} sx={{ marginTop: '15px' }}>
          <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
            <FormattedMessage id="Workflow.Step.Completed.Label" />
          </Typography>
        </Paper>
      )}
      {!isInternal && isOpen && !hideWorkflowInfo && (
        <Box sx={{ marginTop: '15px' }}>
          <GenericAlert
            description={
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                <FormattedMessage
                  id="Contract.External.Wrapper.Label"
                  values={{
                    b: bold,
                  }}
                />
              </Typography>
            }
          />
        </Box>
      )}
    </Box>
  );
}
