import { Extension } from '@tiptap/react';

const ScmHelper = Extension.create({
  name: 'scm-helper',

  addCommands() {
    return {
      updateNodeAttributes:
        (typeOrName, attributes = {}) =>
        ({ tr, state, dispatch }) => {
          const nodeType =
            typeof typeOrName === 'string' ? typeOrName : typeOrName.name;

          if (dispatch) {
            tr.selection.ranges.forEach(range => {
              const from = range.$from.pos;
              const to = range.$to.pos;

              state.doc.nodesBetween(from, to, (node, pos) => {
                if (nodeType === node.type.name) {
                  for (const [key, value] of Object.entries(attributes)) {
                    tr.setNodeAttribute(pos, key, value);
                  }
                }
              });
            });
          }
          return true;
        },
    };
  },
});

export function getSxStyleFromMarks(marks) {
  if (!marks || marks.length === 0) return {};
  const styles = {};
  for (const mark of marks) {
    if (mark.type && mark.attrs) {
      if (mark.type.name === 'textStyle') {
        for (const [key, value] of Object.entries(mark.attrs)) {
          if (value) {
            styles[key] = value + '!important';
          }
        }
      } else if (mark.type.name === 'highlight') {
        styles['backgroundColor'] = mark.attrs.color + '!important';
      }
    }
  }
  return styles;
}

export default ScmHelper;
