import React from 'react';

import useTenantPermissions from '@hooks/useTenantPermissions';

export function TenantPermissionsComponent({
  permissions = [],
  mode = 'at-least',
  invert = false,
  children,
}) {
  const hasPermission = useTenantPermissions({ permissions, mode });
  if (invert && hasPermission) return null;
  if (!invert && !hasPermission) return null;
  return children;
}

const withTenantPermissions = WrappedComponent =>
  function withTenantPermissionsWrapped({
    permissions = [],
    mode = 'at-least',
    invert = false,
    ...rest
  }) {
    return (
      <TenantPermissionsComponent
        permissions={permissions}
        mode={mode}
        invert={invert}
      >
        <WrappedComponent {...rest} />
      </TenantPermissionsComponent>
    );
  };

export default withTenantPermissions;
