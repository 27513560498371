import React from 'react';
import Grid from '@mui/material/Grid';

import { useField } from 'formik';
import ProvisionalForm, { getSchemaProvisionalForm } from './ProvisionalForm';
import OperationForm, {
  getSchemaOperationForm,
  fundingMethods,
  fields as operationFields,
  defaultOperationFormValues,
} from './OperationForm';
import LoansForm, { getSchemaLoansForm } from './LoansForm';

import withFormsBehavior from '../withFormsBehavior';

const getSchema = required =>
  getSchemaProvisionalForm(required)
    .concat(getSchemaOperationForm(required))
    .concat(getSchemaLoansForm(required))
    .default({});

function FinancingForm({ readOnly = false, required }) {
  const [{ value: fundingMethod }] = useField(operationFields.fundingMethod);
  return (
    <Grid container columnSpacing={8} rowSpacing={8}>
      <Grid item xs={12}>
        <ProvisionalForm readOnly={readOnly} required={required} />
      </Grid>
      <Grid item xs={12}>
        <OperationForm readOnly={readOnly} required={required} />
      </Grid>
      {fundingMethod === fundingMethods.credit && (
        <Grid item xs={12}>
          <LoansForm readOnly={readOnly} required={required} />
        </Grid>
      )}
    </Grid>
  );
}

const requiredSchema = getSchema(true);
const nonRequiredSchema = getSchema(false);

const FinancingView = withFormsBehavior(FinancingForm, {
  type: 'financing',
  schema: null,
  requiredSchema,
  nonRequiredSchema,
  getInitialValues: forms =>
    requiredSchema.cast({
      ...defaultOperationFormValues,
      ...(forms || {}),
    }),
});

export default FinancingView;
