/* eslint-disable no-return-assign */
export const valueFromPath = (json, path) =>
  path.split('.').reduce((o, i) => {
    if (o && Array.isArray(o) && o[parseInt(i, 10)]) {
      return o[parseInt(i, 10)];
    }
    if (o && o[i]) {
      return o[i];
    }
    return null;
  }, json);

export const valueToPath = (json, path, value) => {
  let copy = json;
  const segments = path.split('.');
  const last = segments.pop();
  segments.forEach(
    segment => (copy[segment] = copy[segment] || {}) && (copy = copy[segment])
  );
  copy[last] = value;
  return json;
};
