import { DialogOutletContext } from '@components/Dialog/DialogOutlet';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import useFetch from '@hooks/useFetch';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useOutletContext } from 'react-router-dom';

export default function UpdateBuyersNotificationDialog({
  folderId,
  buyersNotification,
  refreshFolder,
}: {
  folderId: string;
  buyersNotification: boolean;
  refreshFolder: () => void;
}) {
  const { close } = useOutletContext<DialogOutletContext>();

  const submitUpdate = useFetch({
    method: 'PUT',
    url: `/nexity/dashboard/folders/${folderId}/notifications/settings`,
    onSuccess: () => {
      close();
      refreshFolder();
    },
  });

  const onSubmitCallback = useCallback(() => {
    submitUpdate.doFetch({ body: { buyersNotification } });
  }, [submitUpdate, buyersNotification]);

  return (
    <DialogWithClose
      titleLabel="Notifications client(s)"
      dialogActions={
        <Button onClick={onSubmitCallback} disabled={submitUpdate.fetching}>
          <FormattedMessage id="Verbs.Validate" />
        </Button>
      }
    >
      <ErrorMessage error={submitUpdate.error} />
      <Stack gap={2}>
        {buyersNotification && (
          <Typography>
            Vous vous apprêtez à activer les notifications.
          </Typography>
        )}
        {!buyersNotification && (
          <Typography>
            Vous vous apprêtez à désactiver les notifications.
          </Typography>
        )}
      </Stack>
    </DialogWithClose>
  );
}
