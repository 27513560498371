import { LicenseInfo } from '@mui/x-license-pro';

export default function MuiProLicense() {
  /* LicenseInfo.setLicenseKey(process.env.MUI_PRO_LICENSE_KEY); */
  LicenseInfo.setLicenseKey(
    '75a85231e1791131f6cd2daf4d9fb328Tz03NDMxNixFPTE3MjU4MTE0ODIwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
  );

  return null;
}
