import { JSONContent } from '@tiptap/core';

export type TemplateDetailResponse = {
  id: string;
  name: string;
  description: string | null;
  folderId: string;
  isPublished: boolean;
  // folder: TemplateFolderResponse | null;
  creatorUserId: string;
  creationTime: string;
  creatorUser: UserResponse | null;
  lastModifierUserId: string;
  lastModificationTime: string;
  lastModifierUser: UserResponse | null;
  // content: object | null;
  // workflowSchema: WorkflowSchemaResponse | null;
  termSheetSchema: TermSheetSchemaResponse | null;
  // completionSchema: CompletionSchemaResponse | null;
  // termSheet: TermSheetResponse | null;
  nomenclature: NomenclatureResponse | null;
  affectation: AffectationResponse | null;
};

export type TermSheetSchemaResponse = {
  creationTime: string;
  creatorUserId: string;
  lastModificationTime: string;
  lastModifierUserId: string;
  items: TermSheetSchemaValueResponse[];
};

export type TermSheetSchemaValueResponse = {
  name: string | null;
  icon: string | null;
  elementId: string | null;
  source: number;
  type: number;
  variableIds: string[] | null;
};

export type TermSheetElementsListResponse = {
  termSheetElements: TermSheetElementResponse[];
  total: number;
};

export type TermSheetElementResponse = {
  id: string;
  icon: string;
  isProtected: boolean;
  isSystem: boolean;
  name: string;
  valueType: string;
  tenantId: string;
  tenant: SmartClauseTenantResponse;
};

export enum TermSheetValueType {
  Text = 1,
  Number,
  Boolean,
  Identifier,
  Timestamp,
  CustomObject,
  DateOnly,
  TimeOnly,
}

export enum TermSheetValueSource {
  Free = 1,
  Contract,
}

export const termSheetValueSourceOptions = [
  {
    value: TermSheetValueSource.Contract,
    title: 'Data from the contract (automatic recovery)',
    messageDescriptorId: 'TermSheet.DataSource.Contract.Label',
  },
  {
    value: TermSheetValueSource.Free,
    title: 'Free Data',
    messageDescriptorId: 'TermSheet.DataSource.Free.Label',
  },
] as const;

export const termSheetValueTypeOptions = [
  {
    value: TermSheetValueType.Text,
    title: 'Text',
    messageDescriptorId: 'TermSheet.DataType.Text.Label',
  },
  {
    value: TermSheetValueType.Number,
    title: 'Number',
    messageDescriptorId: 'TermSheet.DataType.Number.Label',
  },
  {
    value: TermSheetValueType.Boolean,
    title: 'Boolean',
    messageDescriptorId: 'TermSheet.DataType.Boolean.Label',
  },
  {
    value: TermSheetValueType.Timestamp,
    title: 'Timestamp',
    messageDescriptorId: 'TermSheet.DataType.Timestamp.Label',
  },
  {
    value: TermSheetValueType.DateOnly,
    title: 'DateOnly',
    messageDescriptorId: 'TermSheet.DataType.DateOnly.Label',
  },
  {
    value: TermSheetValueType.TimeOnly,
    title: 'TimeOnly',
    messageDescriptorId: 'TermSheet.DataType.TimeOnly.Label',
  },
] as const;

export type NomenclatureResponse = {
  content: JSONContent | null;
};

export type AffectationResponse = {
  affectedTenants: AffectationTenantResponse[];
  availableTenants: AffectationTenantResponse[];
};

export type AffectationTenantResponse = {
  id: string;
  name: string;
};

export type TemplateVariablesListResponse = {
  variables: ContentNodeWithoutTypeResponse[];
  clauses: ContentNodeWithoutTypeResponse[];
  clauseVariants: ContentNodeWithoutTypeResponse[];
};

export type ContentNodeWithoutTypeResponse = {
  attrs: {
    uid: string;
    name: string;
  };
  path?: ContentNodeResponse[];
};

export type ContentNodeResponse = {
  type: string;
} & ContentNodeWithoutTypeResponse;

export type NotificationsListResponse = {
  notifications: NotificationResponse[];
  total: number;
};

export type NotificationResponse = {
  id: string;
  type: number;
  data: object;
  seen: boolean;
  publicationDate: string;
  tenantId: string;
};

export type ContractNoteResponse = {
  id: string;
  roleId: string | null;
  title: string;
  contentType: string;
  content: string;
  isSystem: boolean;
  isPublished: boolean;
  notificationType: number | null;
  creationTime: string;
  creatorUser: UserResponse;
};

export type ContractNotesListResponse = {
  notes: ContractNoteResponse[];
};

export type UserResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  externalId: string;
};

export type ContractsPreviewCreateResponse = {
  completionReferentials: {
    id: string;
    name: string;
  }[];
  nomenclature: NomenclatureResponse;
};

export type ReferentialElementsSearchResponse = {
  referentialElements: ReferentialElementResponse[];
};

export type ReferentialElementResponse = {
  id: string;
  displayName: string;
};

export type LaPosteContractStatus =
  | 'modifications-busdev'
  | 'accepted-busdev'
  | 'refused-busdev'
  | 'modifications-jurist'
  | 'accepted-jurist'
  | 'refused-jurist'
  | 'sent-for-sign-paper'
  | 'sent-for-sign-electronically'
  | 'signed-manually'
  | 'signed-electronically'
  | 'saved'
  | 'expired'
  | 'client-refused'
  | 'terminated'
  | 'client-blocked';

export type LaPosteContractsDetail = {
  isAnyBscc: boolean;
  isAnyColis: boolean;
  isAnyBusdev: boolean;
  isAnyJurist: boolean;
  isAnyAdv: boolean;
  isAnyAdmin: boolean;
  isAssignedCommercial: boolean;
  isAssignedBusdev: boolean;
  isAssignedJurist: boolean;
  hasAssignedBusdevInCurrentStep: boolean;
  hasAssignedJuristInCurrentStep: boolean;
  hasAssignedAdvInCurrentStep: boolean;
  status: string;
  clientStatus: LaPosteContractStatus;
  workflowType: 'classic' | 'automated' | 'activation';
  visibleActions: LaPosteContractsDetailActions;
  enabledActions: LaPosteContractsDetailActions;
};

export type LaPosteContractsDetailActions = {
  abandon: boolean;
  reviewClient: boolean;
  askModificationsBusdev: boolean;
  assignSelfBusdev: boolean;
  acceptBusdev: boolean;
  refuseBusdev: boolean;
  regainControl: boolean;
  askModificationsJurist: boolean;
  assignSelfJurist: boolean;
  acceptJurist: boolean;
  refuseJurist: boolean;
  assignSelfAdv: boolean;
  signElectronically: boolean;
  signPaper: boolean;
  completeSignature: boolean;
  expireSignature: boolean;
  save: boolean;
  sendCommercial: boolean;
  clientRefused: boolean;
  terminate: boolean;
  sendActivation: boolean;
  downloadPdf: boolean;
  unlockSignature: boolean;
  updateContent: boolean;
};

export type DocumentsSearchResponse = {
  progress: number | null;
  items: DocumentsSearchDocumentResponse[];
  paging: DocumentsSearchSearchPagingResponse;
  queryRequest: DocumentsSearchSearchQueryResponse;
  path: DocumentsSearchPathItemResponse[];
};

export type SmartClauseTenantResponse = {
  id: string;
  name: string;
};

export type DriveEntityType = 'document' | 'folder';

export type DocumentsSearchDocumentResponse = {
  id: string;
  type: DriveEntityType;
  name: string;
  status: string;
  clientStatus: number | null;
  contract: DocumentsSearchContractResponse | null;
  creationTime: string;
  creatorUser: UserResponse | null;
  lastModificationTime: string;
  lastModifierUser: UserResponse | null;
  progress: number | null;
  tenantId: string;
  tenant: SmartClauseTenantResponse | null;
  canRemindSigners: boolean | null;
};

export type DocumentsSearchSearchPagingResponse = {
  total: number;
  currentPage: number;
  totalPage: number;
};

export type DocumentsSearchSearchQueryResponse = {
  query: string;
  offset: number | null;
  limit: number | null;
  orderBy: string;
  orderDirection: string;
};

export type DocumentsSearchUserResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type DocumentsSearchContractResponse = {
  id: string;
};

export type DocumentsSearchPathItemResponse = {
  id: string;
  name: string;
};

export type CalendarYearResponse = {
  months: CalendarMonthResponse[];
};

export type CalendarMonthResponse = {
  monthNumber: number;
  reminderCount: number;
  weeks: CalendarWeekResponse[];
};

export type CalendarWeekResponse = {
  weekNumber: number;
  dates: CalendarDateResponse[];
};

export type CalendarIncomingResponse = CalendarDateResponse[];

export type CalendarDateResponse = {
  dateValue: string;
  items: CalendarItemResponse[];
};

export type CalendarItemResponse = {
  id: string;
  name: string;
  description: string;
  type: 'deadline' | 'reminder';
  priority: number;
  isCompleted: boolean;
  isSystem: boolean;
  completionDateTime: string | null;
  completorFullName: string | null;
  notes?: string | null;
  documentId: string | null;
  documentName: string | null;
  document: {
    id: string;
    name: string;
    tenant: {
      id: string;
      name: string;
    } | null;
  } | null;
};

export type AuthenticationTicketResponse = {
  result: AuthenticationResultResponse;
  token: SecurityTokenResponse;
  refreshToken: SecurityTokenResponse;
};

export type AuthenticationResultResponse = {
  succeeded: boolean;
  isNotFound: boolean;
  hasNoPassword: boolean;
  isFailed: boolean;
  isLockedOut: boolean;
  isForbidden: boolean;
  isDeactivated: boolean;
  requireMfa: boolean;
};

export type SecurityTokenResponse = {
  value: string;
  expires: string;
};

export type DelegatorsResponse = {
  delegatorUsers: (UserResponse & {
    isDisabled: boolean;
  })[];
};

export type ReferentialsListResponse = {
  referentials: ReferentialResponse[];
  total: number;
};

export type ReferentialResponse = {
  id: string;
  name: string;
  keyFieldName: string;
  externalId: string;
  providerInfoId: string;
  actions: Record<string, ReferentialActionResponse>;
};

export type ReferentialActionResponse = {
  enabled: boolean;
  fileTypes?: string[];
};

export const workflowContributorAccess = {
  Sign: 'sign',
  Form: 'form',
  FormReview: 'form-review',
  Review: 'review',
  Comment: 'comment',
  ReadOnly: 'read-only',
  SendSignature: 'send-signature',
} as const;

export type WorkflowContributorAccess =
  (typeof workflowContributorAccess)[keyof typeof workflowContributorAccess];

export type ContractsAccessResponse = {
  success: boolean;
  contractAccesses: string[];
  workflowAccesses: WorkflowContributorAccess[];
  versionAccesses: string[];
  contractVersionAccesses: string[];
  negociateType: string;
  schemaAccesses: ContractRoleAccessResponse;
};

export type ContractRoleAccessResponse = {
  restrictedClauses: string[];
  restrictedVariables: string[];
  closedClauses: ClosedClauseResponse[];
};

export type ClosedClauseResponse = {
  recursiveLinkedUid: string;
  role: RoleTypeResponse;
  user: UserTypeResponse;
  payload: PayloadResponse;
};

export type RoleTypeResponse = {
  id: string;
  name: string;
  type: 1 | 2;
};

export type UserTypeResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type PayloadResponse = {
  canShow: boolean;
  canCallExpert: boolean;
  canModify: boolean;
};
