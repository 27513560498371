import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import useFetch from '@hooks/useFetch';
import Tooltip from '@mui/material/Tooltip';

function DocumentUploadRowWithConfidentiality({
  id,
  name,
  documentFolderName,
  documentId,
  folderId,
  canVisualize,
  canSetPrivacy,
  isPrivate,
  canAction,
  refreshPage,
  canRemove,
  isRequired,
}) {
  const navigate = useNavigate();

  const { doFetch: doPut } = useFetch({
    url: '/',
    method: 'POST',
    onSuccess: () => {
      navigate(`/folders/${folderId}`);
      refreshPage();
    },
  });
  const onSubmitCallback = () => {
    doPut({
      url: `/nexity/dashboard/folders/${folderId}/supporting/${id}/confidentiality`,
      body: {
        Confidentiality: !isPrivate,
      },
    });
  };
  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      sx={{ width: '100%', px: 2 }}
    >
      <Paper elevation={2} sx={{ my: 1, p: 2, width: '100%' }}>
        <Stack direction="row" gap={2} alignItems="center">
          <FileCopyIcon />
          <Box sx={{ mr: 'auto' }}>
            <Typography>{documentFolderName}</Typography>
            {isRequired && (
              <Typography
                color="primary"
                sx={{
                  fontStyle: 'italic',
                  fontSize: '12px',
                }}
              >
                (Pièce obligatoire)
              </Typography>
            )}
          </Box>
          {documentId && (
            <>
              {canAction && (
                <Button
                  variant="text"
                  color="error"
                  onClick={() => navigate(`modals/delete/${documentId}`)}
                >
                  <FormattedMessage
                    id="FormView.DocumentToImport.DocumentUploadRow.Button.Delete"
                    defaultMessage="Supprimer"
                  />
                </Button>
              )}
              {canAction && (
                <Button
                  variant="text"
                  color="error"
                  onClick={() => navigate(`modals/replace/${id}`)}
                >
                  <FormattedMessage
                    id="FormView.DocumentToImport.DocumentUploadRow.Button.Replace"
                    defaultMessage="Remplacer"
                  />
                </Button>
              )}
              {canVisualize && (
                <Button
                  variant="contained"
                  component={Link}
                  to={`modals/view/${documentId}/${encodeURIComponent(name)}`}
                >
                  <FormattedMessage
                    id="FormView.DocumentToImport.DocumentUploadRow.Button.Consult"
                    defaultMessage="Consulter"
                  />
                </Button>
              )}
            </>
          )}
          {canRemove && (
            <Button
              variant="text"
              color="error"
              onClick={() => navigate(`modals/remove/${id}`)}
            >
              <FormattedMessage
                id="FormView.DocumentToImport.DocumentUploadRow.Button.Remove"
                defaultMessage="Enlever"
              />
            </Button>
          )}
          {(documentId === null || documentId === undefined) && canAction && (
            <Button
              variant="contained"
              onClick={() => navigate(`modals/import/${id}`)}
            >
              <FormattedMessage
                id="FormView.DocumentToImport.DocumentUploadRow.Button.Import"
                defaultMessage="Importer"
              />
            </Button>
          )}
        </Stack>
      </Paper>
      <Box width={40}>
        {Boolean(canSetPrivacy) && (
          <Tooltip
            title={
              isPrivate
                ? 'Rendre le document accessible aux autres co-acquéreurs'
                : 'Rendre le document confidentiel aux autres co-acquéreurs'
            }
            arrow
          >
            <IconButton onClick={onSubmitCallback}>
              {isPrivate ? <Lock /> : <LockOpen />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Stack>
  );
}

export default DocumentUploadRowWithConfidentiality;
