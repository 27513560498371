import React, { ComponentProps, PropsWithChildren } from 'react';
import { Formik, Form, FormikValues, FormikHelpers } from 'formik';
import { BaseSchema } from 'yup';
import { AnyObject } from 'yup/lib/types';

function FormikProvider<Values extends FormikValues>({
  children,
  schema,
  initialValues,
  onSubmit,
  FormProps = {},
  ...props
}: ComponentProps<typeof Formik<Values>> &
  PropsWithChildren<{
    initialValues: Values;
    onSubmit: (values: Values, context: FormikHelpers<Values>) => void;
    schema: BaseSchema<any, AnyObject, Values>;
    FormProps?: any;
  }>) {
  const onSubmitCallback = React.useCallback(
    (values, context) => {
      onSubmit?.(schema ? schema.cast(values) : values, context);
    },
    [schema, onSubmit]
  );
  return (
    <Formik<Values>
      validationSchema={schema}
      initialValues={initialValues ?? {}}
      onSubmit={onSubmitCallback}
      {...props}
    >
      <Form {...FormProps} noValidate>
        {children}
      </Form>
    </Formik>
  );
}

export default FormikProvider;
