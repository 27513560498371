import React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import ToolbarFonts from './toolbars/toolbar-fonts';
import ToolbarFormat from './toolbars/toolbar-format';
import ToolbarAlign from './toolbars/toolbar-align';
import ToolbarList from './toolbars/toolbar-list';
import ToolbarHtml from './toolbars/toolbar-html';
import ToolbarDocument from './toolbars/toolbar-document';
import ToolbarHistory from './toolbars/toolbar-history';

import BubbleTable from './toolbars/bubble-table';
import BubbleClause from './toolbars/bubble-clause';
import BubbleClauseOnly from './toolbars/bubble-clause-only';
import BubbleVariable from './toolbars/bubble-variable';
import BubbleVariableOnly from './toolbars/bubble-variable-only';
import BubbleVariableLinkedOnly from './toolbars/bubble-variable-linked-only';

// variant: editor, negociate, html
function EditorToolbar({ variant = 'editor' }) {
  return (
    <AppBar position="relative" elevation={0}>
      <Toolbar variant="dense" disableGutters>
        <Box
          sx={{
            bgcolor: 'common.white',
            width: 1,
            m: 1,
            borderRadius: 2,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            '& > *': {
              m: 0.5,
            },
          }}
        >
          <ToolbarFonts />
          <ToolbarFormat />
          <ToolbarAlign />
          <ToolbarList />
          <ToolbarHtml />
          {variant !== 'html' && (
            <ToolbarDocument withSlot={variant === 'editor'} />
          )}
          <ToolbarHistory />
        </Box>
      </Toolbar>
      <BubbleTable />
      {variant === 'editor' && (
        <>
          <BubbleClause />
          <BubbleClauseOnly />
          <BubbleVariable />
          <BubbleVariableOnly />
          {/* <BubbleVariableLinkedOnly /> */}
        </>
      )}
    </AppBar>
  );
}

export default EditorToolbar;
