import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import messages from '@i18n/keys';

import nexityLogo from '@shells/nexity/assets/logo-small.png';
import edouardDenisLogo from '@shells/nexity/assets/edouard-denis/logo-blue-on-transparent.png';
import useNexityTheme from '@shells/nexity/hooks/useNexityTheme';

function Footer({ borderTop = false, gridRow = 3 }) {
  const { name } = useNexityTheme();
  const logo = name === 'edouard-denis' ? edouardDenisLogo : nexityLogo;
  return (
    <Stack
      component="footer"
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        gridRow,
        p: 2,
        pl: 6,
        ...(borderTop && {
          borderTop: theme => `solid 1px ${theme.palette.grey[400]}`,
        }),
      }}
    >
      <Box>
        <img src={logo} alt="footer logo" style={{ height: '62px' }} />
      </Box>
      <Stack>
        <Button
          component={Link}
          to="/legal-notice"
          variant="text"
          color="grey"
          sx={{ textTransform: 'none', justifyContent: 'flex-start', py: 0.5 }}
        >
          <FormattedMessage id={messages.homepage.menu.legalnotice} />
        </Button>
        <Button
          href={
            name === 'edouard-denis'
              ? 'https://edouarddenis-immobilier.com/donnees-personnelles'
              : 'https://www.nexity.fr/actualites/juridique/politique-de-protection-des-donnees'
          }
          variant="text"
          color="grey"
          sx={{ textTransform: 'none', justifyContent: 'flex-start', py: 0.5 }}
          target="_blank"
        >
          <FormattedMessage id={messages.homepage.menu.privacypolicy} />
        </Button>
        <Button
          href={
            name === 'edouard-denis'
              ? 'https://edouarddenis-immobilier.com/donnees-personnelles/contact-DPO'
              : 'https://app.witik.io/fr/form/nexity/exercice-des-droits-rgpd'
          }
          variant="text"
          color="grey"
          sx={{ textTransform: 'none', justifyContent: 'flex-start', py: 0.5 }}
          target="_blank"
        >
          Données personnelles – Exercez vos droits
        </Button>
      </Stack>
    </Stack>
  );
}

export default Footer;
