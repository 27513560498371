import { defineMessages } from 'react-intl';

export default defineMessages({
  TemplateContentSaveSuccess: {
    id: 'Template.Content.Save.Success',
    defaultMessage: 'Document content has been saved successfully',
  },
  TemplateContentSaveError: {
    id: 'Template.Content.Save.Error',
    defaultMessage: 'An error occured during the saving process',
  },
  TemplateAutoCompletionSaveError: {
    id: 'Template.AutoCompletion.Save.Error',
    defaultMessage: "An error occured when saving contract's autocompletion",
  },
  TemplateScopeSchemaSaveError: {
    id: 'Template.ScopeSchema.Save.Error',
    defaultMessage: "An error occured when saving contract user's permissions",
  },
  TemplateCreateButtonLabel: {
    id: 'Template.Create.Button.Label',
    defaultMessage: 'Create the template',
  },
  TemplateCreateWordTitle: {
    id: 'Template.Create.Word.Title',
    defaultMessage: 'Create a template from a word document',
  },
  TemplateCreateBlankTitle: {
    id: 'Template.Create.Blank.Title',
    defaultMessage: 'Create a blank template',
  },
  TemplateCreateBlankButtonLabel: {
    id: 'Template.Create.Blank.Button.Label',
    defaultMessage: 'Create a blank template',
  },
  TemplateCreateImportWordButtonLabel: {
    id: 'Template.Create.ImportWord.Button.Label',
    defaultMessage: 'Import a word file',
  },
  TemplateCreateImportZipButtonLabel: {
    id: 'Template.Create.ImportZip.Button.Label',
    defaultMessage: 'Import a zip file',
  },
  TemplateFieldsNameLabel: {
    id: 'Template.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  TemplateFolderCreateButtonLabel: {
    id: 'Template.Folder.Create.Button.Label',
    defaultMessage: 'Create the folder',
  },
  TemplateFolderFieldsNameLabel: {
    id: 'Template.Folder.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  TemplatesFolderFieldsNameLabel: {
    id: 'Templates.Folder.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  TemplatesFolderDeleteDescription: {
    id: 'Templates.Folder.Delete.Description',
    defaultMessage:
      'You are about to delete a folder. Are you sure you want to continue?',
  },
  TemplatesViewButtonsAddTemplateLabel: {
    id: 'TemplatesView.Buttons.AddTemplate.Label',
    defaultMessage: 'Template',
  },
  TemplatesViewButtonsAddFolderLabel: {
    id: 'TemplatesView.Buttons.AddFolder.Label',
    defaultMessage: 'Folder',
  },
  TemplatesViewButtonsNewLabel: {
    id: 'TemplatesView.Buttons.New.Label',
    defaultMessage: 'New',
  },
  TemplatesViewPopupTitle: {
    id: 'TemplatesView.Popup.Title',
    defaultMessage: 'New Template',
  },
  TemplatesCreatePopupTitle: {
    id: 'Templates.Create.Popup.Title',
    defaultMessage: 'New folder',
  },
  TemplateHeaderNameLabel: {
    id: 'Template.Header.Name.Label',
    defaultMessage: 'Name',
  },
  TemplateHeaderStatutLabel: {
    id: 'Template.Header.Statut.Label',
    defaultMessage: 'Statut',
  },
  TemplateHeaderLastModifLabel: {
    id: 'Template.Header.LastModif.Label',
    defaultMessage: 'Last modification',
  },
  TemplateComponentsTemplateOptionsButtonsEditLabel: {
    id: 'TemplateComponents.TemplateOptions.Buttons.Edit.Label',
    defaultMessage: 'Edit',
  },
  TemplatesViewNamingBreadcrumbLabel: {
    id: 'TemplatesView.Naming.Breadcrumb.Label',
    defaultMessage: 'Rename the document "{name}"',
  },
  TemplatesViewNamingTabsTitleLabel: {
    id: 'TemplatesView.Naming.Tabs.Title.Label',
    defaultMessage: 'Model title',
  },
  TemplatesViewNamingTabsNomenclatureLabel: {
    id: 'TemplatesView.Naming.Tabs.Nomenclature.Label',
    defaultMessage: 'Nomenclature of documents created',
  },
  TemplatesViewNamingNomenclatureAvailableFormatsText: {
    id: 'TemplatesView.Naming.Nomenclature.AvailableFormats.Text',
    defaultMessage: 'Available formats: {formats}',
  },
  TemplatesViewNamingNomenclatureDynamicChoicesCreationDateLabel: {
    id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Label',
    defaultMessage: 'Document creation date',
  },
  'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.DayMonthYearLong':
    {
      id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.DayMonthYearLong',
      defaultMessage: 'DD-MM-YYYY',
    },
  'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.DayMonthYearShort':
    {
      id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.DayMonthYearShort',
      defaultMessage: 'DD-MM-YY',
    },
  'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.YearLongMonthDay':
    {
      id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.YearLongMonthDay',
      defaultMessage: 'YYYY-MM-DD',
    },
  'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.YearShortMonthDay':
    {
      id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.YearShortMonthDay',
      defaultMessage: 'YY-MM-DD',
    },
  'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.MonthDayYearLong':
    {
      id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.MonthDayYearLong',
      defaultMessage: 'MM-DD-YYYY',
    },
  'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.MonthDayYearShort':
    {
      id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDate.Options.MonthDayYearShort',
      defaultMessage: 'MM-DD-YY',
    },
  TemplatesViewNamingNomenclatureDynamicChoicesCreationYearLabel: {
    id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationYear.Label',
    defaultMessage: 'Document creation year',
  },
  'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationYear.Options.YearLong':
    {
      id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationYear.Options.YearLong',
      defaultMessage: 'YYYY',
    },
  'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationYear.Options.YearShort':
    {
      id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationYear.Options.YearShort',
      defaultMessage: 'YY',
    },
  TemplatesViewNamingNomenclatureDynamicChoicesCreationMonthLabel: {
    id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationMonth.Label',
    defaultMessage: 'Document creation month',
  },
  'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationMonth.Options.Month':
    {
      id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationMonth.Options.Month',
      defaultMessage: 'MM',
    },
  TemplatesViewNamingNomenclatureDynamicChoicesCreationDayLabel: {
    id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDay.Label',
    defaultMessage: 'Document creation day',
  },
  'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDay.Options.Day': {
    id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.CreationDay.Options.Day',
    defaultMessage: 'DD',
  },
  TemplatesViewNamingNomenclatureDynamicChoicesFolderNameLabel: {
    id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.FolderName.Label',
    defaultMessage: 'Folder name',
  },
  TemplatesViewNamingNomenclatureDynamicChoicesTemplateNameLabel: {
    id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.TemplateName.Label',
    defaultMessage: 'Template name',
  },
  TemplatesViewNamingNomenclatureTitleText: {
    id: 'TemplatesView.Naming.Nomenclature.Title.Text',
    defaultMessage:
      'Configure the nomenclature of documents created from the model',
  },
  TemplatesViewNamingNomenclatureExplanationText: {
    id: 'TemplatesView.Naming.Nomenclature.Explanation.Text',
    defaultMessage:
      'Select the default nomenclature below, or customize a nomenclature dedicated to this model.',
  },
  TemplatesViewNamingNomenclatureDefaultNomenclatureLabel: {
    id: 'TemplatesView.Naming.Nomenclature.DefaultNomenclature.Label',
    defaultMessage: 'Default nomenclature',
  },
  TemplatesViewNamingNomenclatureCustomNomenclatureLabel: {
    id: 'TemplatesView.Naming.Nomenclature.CustomNomenclature.Label',
    defaultMessage: 'Custom nomenclature',
  },
  TemplatesViewNamingNomenclatureSelectedFormatText: {
    id: 'TemplatesView.Naming.Nomenclature.SelectedFormat.Text',
    defaultMessage: 'Format: {format}',
  },
  TemplatesViewNamingNomenclatureDocumentPlaceholderText: {
    id: 'TemplatesView.Naming.Nomenclature.Document.Placeholder.Text',
    defaultMessage: 'Enter text or insert dynamic data',
  },
  TemplatesViewNamingNomenclatureInsertDynamicDataText: {
    id: 'TemplatesView.Naming.Nomenclature.InsertDynamicData.Text',
    defaultMessage: 'Insert dynamic data',
  },
  TemplatesViewNamingNomenclatureDynamicChoicesReferentialElementNameLabel: {
    id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.ReferentialElementName.Label',
    defaultMessage: 'Data from a repository',
  },
  TemplatesViewNamingNomenclatureDynamicChoicesReferentialElementNameDefaultName:
    {
      id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.ReferentialElementName.DefaultName',
      defaultMessage: 'Referential',
    },
  TemplatesViewNamingNomenclatureDynamicChoicesReferentialElementNameSelectedReferential:
    {
      id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.ReferentialElementName.SelectedReferential',
      defaultMessage: 'Referential: {referentialName}',
    },
  TemplatesViewNamingNomenclatureDynamicChoicesReferentialElementNameSelectedReferentialFirstLine:
    {
      id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.ReferentialElementName.SelectedReferential.FirstLine',
      defaultMessage: '{referentialKeyFieldName}',
    },
  TemplatesViewNamingUpdateSuccessText: {
    id: 'TemplatesView.Naming.Update.Success.Text',
    defaultMessage: 'The naming scheme was updated.',
  },
  TemplatesViewNamingUpdateErrorText: {
    id: 'TemplatesView.Naming.Update.Error.Text',
    defaultMessage: 'An error occured when updating the naming scheme.',
  },
  TemplateViewTemplateInfoCardCreatedByLabel: {
    id: 'TemplateView.TemplateInfo.Card.CreatedBy.Label',
    defaultMessage: 'Created by',
  },
  TemplateViewTemplateInfoCardCreationTimeLabel: {
    id: 'TemplateView.TemplateInfo.Card.CreationTime.Label',
    defaultMessage: 'Creation time',
  },
  TemplateViewTemplateInfoCardLastModificationTimeLabel: {
    id: 'TemplateView.TemplateInfo.Card.LastModificationTime.Label',
    defaultMessage: 'Last modification time',
  },
  TemplatesViewNamingTitleTabNameTitleText: {
    id: 'TemplatesView.Naming.TitleTab.Name.Title.Text',
    defaultMessage: 'Change template name',
  },
  TemplatesViewNamingTitleTabNameExplanationText: {
    id: 'TemplatesView.Naming.TitleTab.Name.Explanation.Text',
    defaultMessage:
      'The title will appear in the drop-down list accessible to those who can create documents based on this template.',
  },
  TemplatesViewNamingTitleTabDescriptionTitleText: {
    id: 'TemplatesView.Naming.TitleTab.Description.Title.Text',
    defaultMessage: 'Template description',
  },
  TemplatesViewNamingTitleTabDescriptionExplanationText: {
    id: 'TemplatesView.Naming.TitleTab.Description.Explanation.Text',
    defaultMessage:
      'Share in a few lines the essential information to know about this model.',
  },
  TemplatesViewNamingNomenclatureDynamicChoicesReferentialElementNameNoData: {
    id: 'TemplatesView.Naming.Nomenclature.DynamicChoices.ReferentialElementName.NoData',
    defaultMessage: 'No data from a repository',
  },
  TemplatesViewAffectationBreadcrumbLabel: {
    id: 'TemplatesView.Affectation.Breadcrumb.Label',
    defaultMessage: 'Manage affectation',
  },
  TemplatesViewAffectationUpdateSuccessText: {
    id: 'TemplatesView.Affectation.Update.Success.Text',
    defaultMessage: 'The affectation was saved',
  },
  TemplatesViewAffectationUpdateErrorText: {
    id: 'TemplatesView.Affectation.Update.Error.Text',
    defaultMessage: 'The affectation could not be saved',
  },
  TemplatesViewAffectationTitle: {
    id: 'TemplatesView.Affectation.Title',
    defaultMessage: 'Manage affectation of the template "{templateName}"',
  },
  TemplatesViewAffectationText: {
    id: 'TemplatesView.Affectation.Text',
    defaultMessage: 'Affectation of the template',
  },
  TemplatesViewAffectationSelectMessage: {
    id: 'TemplatesView.Affectation.Select.Message',
    defaultMessage:
      'Select the BU(s) to which the template must be affected so the users of this BU(s) can create contracts from this template',
  },
});
