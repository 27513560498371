import React from 'react';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { requiredOrStripWhen } from '@utils/yup-required-when';
import NotaryForm from './NotaryForm';
import SignatureForm from './SignatureForm';

const requiredStringIfNotary = requiredOrStripWhen(
  yup.string().trim(),
  'callNotary',
  'true'
);

export const schema = yup.object({
  callNotary: yup.string().trim().required('Generic.Fields.Required'),
  address: requiredStringIfNotary,
  phone: requiredStringIfNotary,
  fullName: requiredStringIfNotary,
  presenceOfTheNotaryAtTheSigning: requiredStringIfNotary,
  signatureOption: yup.string().trim().required('Generic.Fields.Required'),
});

export const initialValues = {
  callNotary: '',
  address: '',
  phone: '',
  fullName: '',
  presenceOfTheNotaryAtTheSigning: '',
  signatureOption: '',
};

function ProjectForm({ readOnly = false }) {
  return (
    <Grid container columnSpacing={8} rowSpacing={8}>
      <Grid item xs={6}>
        <NotaryForm readOnly={readOnly} />
      </Grid>
      <Grid item xs={6}>
        <SignatureForm readOnly={readOnly} />
      </Grid>
    </Grid>
  );
}

export default ProjectForm;
