import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';

import { useTiptapEditor } from '../../use-tiptap-editor';

function ButtonTableBorderStyle() {
  const editor = useTiptapEditor();
  return (
    <PopupState variant="popper" popupId="table-cell-popup-menu">
      {popupState => (
        <>
          <Tooltip
            placement="top"
            title={
              <FormattedMessage id="Editor.Toolbar.Command.Table.CellParam.Border.Style" />
            }
          >
            <Button sx={{ color: 'primary.main' }} {...bindToggle(popupState)}>
              <BorderStyleIcon />
            </Button>
          </Tooltip>
          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                <MenuList sx={{ p: 0 }} component={Paper} elevation={8}>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .setCellAttribute('borderStyle', 'dotted')
                        .run();
                      popupState.close();
                    }}
                  >
                    <FormattedMessage id="Editor.Toolbar.Command.Table.CellParam.Border.Dotted" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .setCellAttribute('borderStyle', 'dashed')
                        .run();
                      popupState.close();
                    }}
                  >
                    <FormattedMessage id="Editor.Toolbar.Command.Table.CellParam.Border.Dashed" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      editor
                        .chain()
                        .focus()
                        .setCellAttribute('borderStyle', 'solid')
                        .run();
                      popupState.close();
                    }}
                  >
                    <FormattedMessage id="Editor.Toolbar.Command.Table.CellParam.Border.Solid" />
                  </MenuItem>
                </MenuList>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default ButtonTableBorderStyle;
