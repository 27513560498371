import { useLayoutEffect } from 'react';

const baseTitle = process.env.REACT_APP_TITLE || '';

function getCompleteTitle(title: string | null | undefined) {
  if (!title) return baseTitle;
  if (!baseTitle) return title;
  return `${title} - ${baseTitle}`;
}

function useWindowTitle(title: string | null | undefined) {
  const completeTitle = getCompleteTitle(title);
  useLayoutEffect(() => {
    document.title = completeTitle;
    return () => {
      document.title = baseTitle;
    };
  }, [completeTitle]);

  return completeTitle;
}

export default useWindowTitle;
