import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';

export type DialogOutletContext = {
  close: () => void;
  setCloseable: (closeable: boolean) => void;
};

function DialogOutlet({ open = false, onClose = () => {}, ...props }) {
  const [opened, setOpened] = useState(open);
  const [closeable, setCloseable] = useState(true);
  const close = () => setOpened(false);
  const context: DialogOutletContext = { close, setCloseable };
  return (
    <Dialog
      open={opened}
      onClose={closeable ? close : undefined}
      closeAfterTransition
      TransitionProps={{ onExited: onClose }}
      fullWidth
      maxWidth="md"
      sx={{
        ".MuiDialog-container:has(form[data-modal='top']) > div": {
          alignSelf: 'start',
        },
      }}
      {...props}
    >
      <Outlet context={context} />
    </Dialog>
  );
}

export default DialogOutlet;
