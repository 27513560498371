import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FastField, Field } from 'formik';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import { valueFromPath } from '@utils/json-path';

const StyledPopper = styled(props => <Popper placement="bottom" {...props} />)(
  () => ({
    minWidth: 'fit-content',
    maxWidth: '100vh',
  })
);

function MuiAutocomplete({
  field,
  form: { setFieldValue, errors, touched } = {},
  label,
  fitContent = false,
  loading,
  ...props
}) {
  const { name } = field;
  const error = name.includes('.') ? valueFromPath(errors, name) : errors[name];
  const touch = name.includes('.')
    ? valueFromPath(touched, name)
    : touched[name];
  const hasError = Boolean(touch) && Boolean(error);

  return (
    <Autocomplete
      fullWidth
      margin="normal"
      freeSolo
      PopperComponent={fitContent ? StyledPopper : Popper}
      loading={loading}
      {...props}
      {...field}
      onChange={(_event, value) => {
        setFieldValue(name, value);
        if (props?.onChange) {
          props.onChange(value);
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={hasError}
          helperText={
            hasError && (
              <FormattedMessage
                id={error}
                values={{
                  label: label ?? '',
                  value: field?.value ?? '',
                }}
              />
            )
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

function FormikAutocomplete({ as = 'fast', ...props }) {
  if (as === 'field') {
    return <Field component={MuiAutocomplete} {...props} />;
  }
  return <FastField component={MuiAutocomplete} {...props} />;
}

export default FormikAutocomplete;
