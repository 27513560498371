import React from 'react';
import ReactDOM from 'react-dom';
import Paper from '@mui/material/Paper';

import { TiptapContext } from './use-tiptap-context';

function Portals({ renderers }) {
  return (
    <>
      {Array.from(renderers).map(([key, renderer]) =>
        ReactDOM.createPortal(renderer.reactElement, renderer.element, key)
      )}
    </>
  );
}

export class PureEditorContent extends React.Component {
  static contextType = TiptapContext;

  editorContentRef = null;

  constructor(props) {
    super(props);
    this.editorContentRef = React.createRef();
    this.state = {
      renderers: new Map(),
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate() {
    this.init();
  }

  init() {
    const editor = this.props.editor ?? this.context.editor;
    if (editor && editor.options.element) {
      if (editor.contentComponent) {
        return;
      }
      const element = this.editorContentRef.current;
      element.append(...editor.options.element.childNodes);
      editor.setOptions({
        element,
      });
      editor.contentComponent = this;
      editor.createNodeViews();
    }
  }

  componentWillUnmount() {
    const editor = this.props.editor ?? this.context.editor;
    if (!editor) {
      return;
    }
    if (!editor.isDestroyed) {
      editor.view.setProps({
        nodeViews: {},
      });
    }
    editor.contentComponent = null;
    if (!editor.options.element.firstChild) {
      return;
    }
    const newElement = document.createElement('div');
    newElement.append(...editor.options.element.childNodes);
    editor.setOptions({
      element: newElement,
    });
  }

  render() {
    const { editor, variant = 'page', ...rest } = this.props;
    const size = { width: '100%' };
    if (variant === 'page') {
      size.width = '21cm';
      size.minHeight = '29.7cm';
    } else if (variant === 'html') {
      size.minHeight = '300px';
      size.border = 'none';
    } else {
      size.height = '100%';
    }
    return (
      <>
        <Paper
          component="section"
          elevation={0}
          square
          variant="outlined"
          sx={{
            ...size,
            margin: 'auto',
            overflow: 'hidden',
            display: 'flex',
            '& > div': { flexGrow: 1, display: 'flex', overflow: 'hidden' },
          }}
        >
          <div spellCheck={false} ref={this.editorContentRef} {...rest} />
        </Paper>
        <Portals renderers={this.state.renderers} />
      </>
    );
  }
}

const EditorContent = React.memo(PureEditorContent);

export default EditorContent;
