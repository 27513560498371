import React from 'react';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function SelectButton({
  variant,
  buttonText,
  buttons,
  children,
  onClick = () => {},
}) {
  return (
    <PopupState variant="popper" popupId="select-button-menu">
      {popupState => (
        <>
          <ButtonGroup variant={variant}>
            {buttons && buttons(popupState)}
            {!buttons && (
              <>
                <Button onClick={() => onClick()}>{buttonText}</Button>
                <Button
                  size="small"
                  {...bindToggle(popupState)}
                  sx={{ minWidth: 'unset !important', pl: 0, pr: 0 }}
                >
                  <ArrowDropDownIcon />
                </Button>
              </>
            )}
          </ButtonGroup>

          {popupState.isOpen && (
            <ClickAwayListener onClickAway={popupState.close}>
              <Popper {...bindPopper(popupState)} placement="bottom-start">
                {children && children(popupState)}
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </PopupState>
  );
}

export default SelectButton;
