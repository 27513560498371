import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { toHslColor } from '@utils/hue-from-string';
import { compute } from '@utils/username-helpers';

function UserChip({
  email = 'author@seraphin.legal',
  pictureUrl = '',
  firstName = '',
  lastName = '',
  extra = '',
  style,
  ...rest
}) {
  const userName = compute(email, firstName, lastName, extra);
  return (
    <Chip
      sx={style}
      avatar={
        <Avatar sx={{ bgcolor: toHslColor(email) }} src={pictureUrl}>
          {userName.first}
        </Avatar>
      }
      label={userName.value}
      {...rest}
    />
  );
}

UserChip.propTypes = {
  email: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  extra: PropTypes.string,
};

UserChip.defaultProps = {
  pictureUrl: '',
  firstName: '',
  lastName: '',
  extra: '',
};

export default UserChip;
