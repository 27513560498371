import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import EuroIcon from '@mui/icons-material/Euro';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import CakeIcon from '@mui/icons-material/Cake';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import SellIcon from '@mui/icons-material/Sell';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentTurnedOutlinedInIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import EmailIcon from '@mui/icons-material/Email';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

export const iconOptions = [
  {
    name: 'star',
    BorderIcon: StarBorderIcon,
    Icon: StarIcon,
  },
  {
    name: 'groups',
    BorderIcon: GroupsOutlinedIcon,
    Icon: GroupsIcon,
  },
  {
    name: 'folder',
    BorderIcon: FolderOutlinedIcon,
    Icon: FolderIcon,
  },
  {
    name: 'time',
    BorderIcon: AccessTimeOutlinedIcon,
    Icon: AccessTimeIcon,
  },
  {
    name: 'birthday-cake',
    BorderIcon: CakeOutlinedIcon,
    Icon: CakeIcon,
  },
  {
    name: 'euro',
    BorderIcon: EuroOutlinedIcon,
    Icon: EuroIcon,
  },
  {
    name: 'calender',
    BorderIcon: CalendarMonthOutlinedIcon,
    Icon: CalendarMonthIcon,
  },
  {
    name: 'lock',
    BorderIcon: LockOutlinedIcon,
    Icon: LockIcon,
  },
  {
    name: 'cloud',
    BorderIcon: CloudOutlinedIcon,
    Icon: CloudIcon,
  },
  {
    name: 'check',
    BorderIcon: AssignmentTurnedOutlinedInIcon,
    Icon: AssignmentTurnedInIcon,
  },
  {
    name: 'email',
    BorderIcon: EmailOutlinedIcon,
    Icon: EmailIcon,
  },
  {
    name: 'sell',
    BorderIcon: SellOutlinedIcon,
    Icon: SellIcon,
  },
  {
    name: 'payment',
    BorderIcon: PaymentOutlinedIcon,
    Icon: PaymentIcon,
  },
] as const;

export const defaultIconOption = iconOptions[0];

export const getIconPerName = (name: string) => {
  const loweredName = name?.toLowerCase();
  return iconOptions.find(o => o.name === loweredName) ?? defaultIconOption;
};
