import React from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { hostIsAuthenticatedSelector } from '@recoil/host-token';

export function HostAuthenticated({ children }) {
  const location = useLocation();
  const isAuthenticated = useRecoilValue(hostIsAuthenticatedSelector);
  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  return children || null;
}

function HostAuthenticatedRoute(props) {
  return (
    <HostAuthenticated {...props}>
      <Outlet />
    </HostAuthenticated>
  );
}

export default HostAuthenticatedRoute;
