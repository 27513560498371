import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import { useTiptapEditor } from '../use-tiptap-editor';

import { clauseType } from '@smartclause-tiptap-extensions/schema-core/dist';
import ClauseIcon from '../ClauseIcon';

function ToolbarClause() {
  const editor = useTiptapEditor();
  return (
    <>
      <Tooltip
        placement="top"
        title={
          <FormattedMessage id="Editor.Toolbar.Command.Clause.Qdata.Add" />
        }
      >
        <Button
          sx={{ color: 'primary.main' }}
          onClick={() => {
            editor.chain().focus().addClause(clauseType.qdata.value).run();
          }}
        >
          <ClauseIcon type={clauseType.qdata.value} />
        </Button>
      </Tooltip>
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.Clause.Scq.Add" />}
      >
        <Button
          sx={{ color: 'primary.main' }}
          onClick={() => {
            editor.chain().focus().addClause(clauseType.scq.value).run();
          }}
        >
          <ClauseIcon type={clauseType.scq.value} />
        </Button>
      </Tooltip>
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Toolbar.Command.Clause.Mcq.Add" />}
      >
        <Button
          sx={{ color: 'primary.main' }}
          onClick={() => {
            editor.chain().focus().addClause(clauseType.mcq.value).run();
          }}
        >
          <ClauseIcon type={clauseType.mcq.value} />
        </Button>
      </Tooltip>
    </>
  );
}

export default ToolbarClause;
