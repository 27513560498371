import { FormattedMessage } from 'react-intl';
import { Box, Typography, Grid } from '@mui/material';
import Fetching from '@components/Fetching/Fetching';

export default function FolderInfoOptionsCard({ fetching, status }) {
  return (
    <Box>
      <Fetching fetching={fetching}>
        <Box sx={{ bgcolor: '#FFFFFF', my: 2, borderRadius: '6px', p: 1 }}>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                <FormattedMessage
                  id="DriveView.FolderInfo.Fields.Status.Label"
                  defaultMessage="Statut"
                />
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Box
                sx={{
                  bgcolor: 'grey.200',
                  width: 'fit-content',
                  borderRadius: '6px',
                  p: '6px 8px',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: 1,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  <FormattedMessage id={`Document.Card.${status}`} />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Fetching>
    </Box>
  );
}
