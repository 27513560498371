import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import RateReviewIcon from '@mui/icons-material/RateReview';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';

import { useTiptapEditor } from '../use-tiptap-editor';

import { HeaderFooterPageType } from '@smartclause-tiptap-extensions/schema-core/dist';

function ToolbarDocument({ withSlot = true }) {
  const editor = useTiptapEditor();
  return (
    <ButtonGroup variant="text">
      <Tooltip
        placement="top"
        title={
          <FormattedMessage id="Editor.Toolbar.Command.HeaderFooter.Toogle" />
        }
      >
        <Button
          sx={{ color: 'primary.main' }}
          onClick={() =>
            editor
              .chain()
              .focus()
              .createMissingHeadersTypes(
                HeaderFooterPageType.otherPages.value,
                { selection: editor.state.selection }
              )
              .toggleHeadersEdit(editor.state.selection)
              .setSectionStartSelection(editor.state.selection)
              .run()
          }
        >
          <WysiwygIcon />
        </Button>
      </Tooltip>
      {withSlot && (
        <Tooltip
          placement="top"
          title={<FormattedMessage id="Editor.Signature.AddSlot.Label" />}
        >
          <Button
            sx={{ color: 'primary.main' }}
            onClick={() =>
              editor
                .chain()
                .focus()
                .insertSignatureSlot()
                // .setSectionStartSelection(editor.state.selection)
                .run()
            }
          >
            <RateReviewIcon />
          </Button>
        </Tooltip>
      )}
      <Tooltip
        placement="top"
        title={<FormattedMessage id="Editor.Page.Break.Insert" />}
      >
        <Button
          sx={{ color: 'primary.main' }}
          onClick={() => editor.chain().focus().insertPageBreak().run()}
        >
          <InsertPageBreakIcon />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
}

export default ToolbarDocument;
