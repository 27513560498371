import { Extension } from '@tiptap/react';
import cloneDeep from 'lodash.clonedeep';
import { AddMarkStep } from 'prosemirror-transform';
import { Plugin, PluginKey } from 'prosemirror-state';

const FormatPainter = Extension.create({
  name: 'format-painter',
  addStorage() {
    return {
      enabled: false,
      enabledMultiple: false,
      copiedMarks: null,
      onStateUpdate: null,
    };
  },
  addCommands() {
    return {
      toggleFormatPainter:
        () =>
        ({ state }) => {
          this.storage.enabled = !this.storage.enabled;
          this.storage.enabledMultiple = false;
          if (this.storage.enabled) {
            this.storage.copiedMarks = getSelectionMarks(state);
          } else {
            this.storage.copiedMarks = null;
          }
          this.storage.onStateUpdate?.(this.storage.enabled);
        },
      disableFormatPainter: () => () => {
        this.storage.enabled = false;
        this.storage.enabledMultiple = false;
        this.storage.copiedMarks = null;
        this.storage.onStateUpdate?.(this.storage.enabled);
      },
      enableFormatPainterMultiple:
        () =>
        ({ state }) => {
          this.storage.enabled = true;
          this.storage.enabledMultiple = true;
          this.storage.copiedMarks = getSelectionMarks(state);
          this.storage.onStateUpdate?.(this.storage.enabled);
        },
      applyFormatPainter:
        () =>
        ({ tr, state }) => {
          if (
            this.storage.copiedMarks &&
            this.storage.copiedMarks.length > 0 &&
            state.selection.from !== state.selection.to
          ) {
            for (const mark of this.storage.copiedMarks) {
              const step = new AddMarkStep(
                state.selection.from,
                state.selection.to,
                this.editor.schema.marks[mark.type].create(mark.attrs)
              );
              tr.step(step);
            }
            state.apply(tr);
          }
        },
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('format-painter-event-handler'),
        props: {
          handleDOMEvents: {
            mouseup: (view, event) => {
              if (this.storage.enabled) {
                // apply saved style on mouse up
                if (this.storage.enabledMultiple) {
                  this.editor.commands.applyFormatPainter();
                } else {
                  this.editor
                    .chain()
                    .applyFormatPainter()
                    .disableFormatPainter()
                    .run();
                }
              }
            },
            keypress: (view, event) => {
              if (this.storage.enabled) {
                this.editor.commands.disableFormatPainter();
              }
            },
            keydown: (view, event) => {
              const keysToEnd = ['Backspace', 'Delete', 'Escape'];
              if (this.storage.enabled && keysToEnd.includes(event.key)) {
                this.editor.commands.disableFormatPainter();
              }
            },
          },
        },
      }),
    ];
  },
});

export default FormatPainter;

function getSelectionMarks(editorState) {
  const selectedNode = editorState.doc.nodeAt(editorState.selection.from);

  if (selectedNode && selectedNode.marks) {
    return cloneDeep(selectedNode.marks);
  }
  return null;
}
