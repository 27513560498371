import React from 'react';
import { useFormikContext, setNestedObjectValues } from 'formik';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

import withCondition from '@components/withCondition';
import withTenantPermissions from '@components/withTenantPermissions';

import tenantPermissions from '@utils/tenant-permissions';
import useNexityTheme from '@shells/nexity/hooks/useNexityTheme';

const BoxWithCondition = withCondition(Box);
const BoxWithConditionAndPermissions = withTenantPermissions(BoxWithCondition);

function FormsButtons({
  status,
  schema: schemaProp,
  putting,
  doPut,
  preventModifications,
  canInvalidateForm,
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { values, validateForm, setErrors, setTouched } = useFormikContext();
  const { name } = useNexityTheme();

  const onSaveClick = () => {
    const castedValues = schemaProp.cast(values);
    const forms = castedValues?.forms ?? castedValues;
    doPut({ body: { forms, themeName: name } }).then(response => {
      if (response.ok) {
        enqueueSnackbar('Formulaire enregistré', {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar("Le formulaire n'a pas pu être enregistré", {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    });
  };

  const doIfValid = callback => {
    validateForm(values).then(errors => {
      const hasErrors = Boolean(Object.keys(errors).length);
      if (hasErrors) {
        setTouched(setNestedObjectValues(errors, true));
        setErrors(errors);
      } else {
        callback();
      }
    });
  };

  const onSendClick = () => {
    doIfValid(() => {
      const castedValues = schemaProp.cast(values);
      const forms = castedValues?.forms ?? castedValues;
      doPut({
        body: { status: 1, reason: '', forms, themeName: name },
      });
    });
  };

  const onModifyClick = () => {
    doPut({ body: { status: 0, reason: '', themeName: name } });
  };

  const openModal = path => {
    navigate(path);
  };

  const openModalIfValid = path => {
    doIfValid(() => {
      navigate(path);
    });
  };

  const saveButton = (
    <Button type="button" disabled={putting} onClick={onSaveClick}>
      Enregistrer le formulaire
    </Button>
  );

  return (
    <Stack direction="row" justifyContent="center" mt={4} gap={2}>
      <BoxWithCondition condition={[0, 2].includes(status)}>
        {saveButton}
      </BoxWithCondition>
      <BoxWithConditionAndPermissions
        condition={[1].includes(status)}
        permissions={tenantPermissions.FormsStatus}
      >
        {saveButton}
      </BoxWithConditionAndPermissions>

      <BoxWithConditionAndPermissions
        condition={[0, 2].includes(status)}
        permissions={tenantPermissions.FormsStatus /* for the buyers */}
        invert
      >
        <Button type="submit" disabled={putting} onClick={onSendClick}>
          Valider le formulaire
        </Button>
      </BoxWithConditionAndPermissions>

      <BoxWithConditionAndPermissions
        condition={!preventModifications && [1].includes(status)}
        permissions={tenantPermissions.FormsStatus}
        invert
      >
        <Button type="submit" disabled={putting} onClick={onModifyClick}>
          Modifier le formulaire
        </Button>
      </BoxWithConditionAndPermissions>

      <BoxWithConditionAndPermissions
        condition={[1].includes(status)}
        permissions={tenantPermissions.FormsStatus}
      >
        <Button
          type="button"
          disabled={putting}
          onClick={() => openModal('modals/invalidate')}
        >
          Rejeter le formulaire
        </Button>
      </BoxWithConditionAndPermissions>

      <BoxWithConditionAndPermissions
        condition={canInvalidateForm && [3].includes(status)}
        permissions={tenantPermissions.FormsStatus}
      >
        <Button
          type="button"
          disabled={putting}
          onClick={() => openModal('modals/invalidate')}
        >
          Invalider le formulaire
        </Button>
      </BoxWithConditionAndPermissions>

      <BoxWithConditionAndPermissions
        condition={[0, 1, 2].includes(status)}
        permissions={tenantPermissions.FormsStatus /* for the dealer */}
      >
        <Button
          type="button"
          disabled={putting}
          onClick={() => openModalIfValid('modals/validate')}
        >
          Valider le formulaire
        </Button>
      </BoxWithConditionAndPermissions>
    </Stack>
  );
}

export default FormsButtons;
