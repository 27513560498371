import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MuiList from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { blockDocumentTypes } from '@shells/nexity/nexity-constants';
import { List, arrayRemove, arrayMove } from 'react-movable';

export const blockDocumentIcons = {
  [blockDocumentTypes.template]: InsertDriveFileIcon,
  [blockDocumentTypes.nexityPiece]: FilePresentIcon,
  [blockDocumentTypes.prospectPiece]: TextSnippetIcon,
};

function Document({ document, domProps, onDelete }) {
  const type = document?.type;
  const Icon = blockDocumentIcons[type];
  const name = document?.name || document?.template?.name || 'Document';

  return (
    <ListItem
      sx={{ '&:hover': { bgcolor: 'grey.100', cursor: 'default' } }}
      secondaryAction={
        <IconButton
          color="primary"
          size="small"
          sx={{ ml: 'auto' }}
          onClick={onDelete}
        >
          <DeleteOutlineIcon />
        </IconButton>
      }
      {...domProps}
    >
      <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }} data-movable-handle>
        <DragIndicatorIcon />
      </ListItemIcon>
      <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );
}

export default function Block({
  index,
  name,
  documents = [],
  onDocumentsChange,
}) {
  const handleMoveDocument = useCallback(
    ({ oldIndex, newIndex }) =>
      onDocumentsChange(index, arrayMove(documents, oldIndex, newIndex)),
    [documents, index, onDocumentsChange]
  );

  const handleDeleteDocument = useCallback(
    documentIndex =>
      onDocumentsChange(index, arrayRemove(documents, documentIndex)),
    [documents, index, onDocumentsChange]
  );

  const list = useMemo(
    () => (
      <List
        lockVertically
        values={documents}
        onChange={handleMoveDocument}
        renderList={({ children, props }) => (
          <MuiList component="div" disablePadding {...props}>
            {children}
          </MuiList>
        )}
        renderItem={({ value: document, index: documentIndex, props }) => (
          <Document
            document={document}
            documents={documents}
            index={index}
            documentIndex={documentIndex}
            domProps={props}
            onDelete={() => handleDeleteDocument(documentIndex)}
          />
        )}
      />
    ),
    [documents, handleDeleteDocument, handleMoveDocument, index]
  );

  return (
    <Paper sx={{ my: 2, px: 2, py: 1, maxWidth: '600px' }}>
      <Stack direction="row" alignItems="center">
        <Typography sx={{ fontWeight: 700 }}>{name}</Typography>
        <IconButton
          color="grey"
          component={Link}
          to={{
            pathname: `modals/rename-block/${index}`,
            search: `?name=${name}`,
          }}
          sx={{ ml: 'auto' }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="primary"
          component={Link}
          to={{ pathname: `modals/delete-block/${index}` }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Stack>
      <Stack sx={{ ml: 2, position: 'relative' }} gap={1}>
        {list}
        <Button
          size="small"
          sx={{ my: 1.25, marginRight: 'auto' }}
          color="secondary"
          startIcon={<AddCircleIcon />}
          component={Link}
          to={{
            pathname: `modals/add-document/${index}/${documents?.length ?? 0}`,
          }}
        >
          Ajouter un document
        </Button>
      </Stack>
    </Paper>
  );
}
