import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import FunctionsIcon from '@mui/icons-material/Functions';

import { useTiptapEditor } from '../../use-tiptap-editor';

function ButtonVariable() {
  const editor = useTiptapEditor();
  return (
    <Tooltip
      placement="top"
      title={<FormattedMessage id="Editor.Toolbar.Command.Variable.Add" />}
    >
      <Button
        sx={{ color: 'primary.main' }}
        onClick={() =>
          editor.chain().focus().addVariable().scrollIntoView().run()
        }
      >
        <FunctionsIcon />
      </Button>
    </Tooltip>
  );
}

export default ButtonVariable;
