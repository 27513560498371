import { defineMessages } from 'react-intl';

export default defineMessages({
  DriveViewTrashLabel: {
    id: 'DriveView.Trash.Label',
    defaultMessage: 'Trash',
  },
  DriveViewButtonsNewLabel: {
    id: 'DriveView.Buttons.New.Label',
    defaultMessage: 'New',
  },
  DriveViewColumnsNameText: {
    id: 'DriveView.Columns.Name.Text',
    defaultMessage: 'Name',
  },
  DriveViewColumnsStatusText: {
    id: 'DriveView.Columns.Status.Text',
    defaultMessage: 'Status',
  },
  DriveViewColumnsOwnerText: {
    id: 'DriveView.Columns.Owner.Text',
    defaultMessage: 'Owner name',
  },
  DriveViewColumnsLastModificationText: {
    id: 'DriveView.Columns.LastModification.Text',
    defaultMessage: 'Last modification',
  },
  DriveViewDialogsCreateFolderFieldsNameLabel: {
    id: 'DriveView.Dialogs.CreateFolder.Fields.Name.Label',
    defaultMessage: 'Name',
  },
  DriveViewDialogsCreateFolderFieldsIsFrozenLabel: {
    id: 'DriveView.Dialogs.CreateFolder.Fields.IsFrozen.Label',
    defaultMessage: 'This folder (name and location) is frozen',
  },
  DriveViewDialogsCreateFolderFieldsIsContentFrozenLabel: {
    id: 'DriveView.Dialogs.CreateFolder.Fields.IsContentFrozen.Label',
    defaultMessage: 'The content of this folder is frozen',
  },
  DriveViewDialogsCreateFolderFieldsIsConfidentialLabel: {
    id: 'DriveView.Dialogs.CreateFolder.Fields.IsConfidential.Label',
    defaultMessage: 'This folder is confidential',
  },
  DriveViewDialogsCreateFolderFieldsIsConfidentialTooltip: {
    id: 'DriveView.Dialogs.CreateFolder.Fields.IsConfidential.Tooltip',
    defaultMessage:
      'A confidential folder is only visible to the users to whom it has been shared.',
  },
  DriveViewButtonsAddDocumentLabel: {
    id: 'DriveView.Buttons.AddDocument.Label',
    defaultMessage: 'Document',
  },
  DriveViewButtonsDuplicateLabel: {
    id: 'DriveView.Buttons.Duplicate.Label',
    defaultMessage: 'Create a copy',
  },
  DriveViewButtonsRemindSignerLabel: {
    id: 'DriveView.Buttons.RemindSigner.Label',
    defaultMessage: 'Remind signers',
  },
  DriveViewModalRemindSignerTitle: {
    id: 'DriveView.Modal.RemindSigner.Title',
    defaultMessage: 'Remind Signers',
  },
  DriveViewModalRemindSignerText: {
    id: 'DriveView.Modal.RemindSigner.Text',
    defaultMessage: 'Current signers will be reminded.',
  },
  DriveViewButtonsAddFolderLabel: {
    id: 'DriveView.Buttons.AddFolder.Label',
    defaultMessage: 'Folder',
  },
  DriveViewAddDocumentButtonsBlankLabel: {
    id: 'DriveView.AddDocument.Buttons.Blank.Label',
    defaultMessage: 'From a template',
  },
  DriveViewAddDocumentButtonsImportLabel: {
    id: 'DriveView.AddDocument.Buttons.Import.Label',
    defaultMessage: 'Import a document',
  },
  DriveViewAddFolderButtonsNewLabel: {
    id: 'DriveView.AddFolder.Buttons.New.Label',
    defaultMessage: 'New folder',
  },
  DriveViewAddFolderButtonsImportLabel: {
    id: 'DriveView.AddFolder.Buttons.Import.Label',
    defaultMessage: 'Import a folder',
  },
  DriveViewDialogsEditTermSheetTemplateButton: {
    id: 'DriveView.Dialogs.EditTermSheetTemplate.Button',
    defaultMessage: 'Link',
  },
  DriveViewDialogsEditWorkflowTemplateInputLabel: {
    id: 'DriveView.Dialogs.EditWorkflowTemplate.Input.Label',
    defaultMessage: 'Template',
  },
  DriveViewDialogsEditTermSheetTemplateInputLabel: {
    id: 'DriveView.Dialogs.EditTermSheetTemplate.Input.Label',
    defaultMessage: 'Template',
  },
  DriveViewDialogsEditTermSheetTemplateTitle: {
    id: 'DriveView.Dialogs.EditTermSheetTemplate.Title',
    defaultMessage: 'Link to a contract form template',
  },
  DriveViewDialogsNewFolderTitle: {
    id: 'DriveView.Dialogs.NewFolder.Title',
    defaultMessage: 'New folder',
  },
  DriveViewDialogsImportFolderTitle: {
    id: 'DriveView.Dialogs.ImportFolder.Title',
    defaultMessage: 'Import a folder',
  },
  DriveViewDialogsNewTermSheetElementTitle: {
    id: 'DriveView.Dialogs.NewTermSheetElement.Title',
    defaultMessage: 'Add an element in the term sheet',
  },
  DriveViewDialogsEditTermSheetElementTitle: {
    id: 'DriveView.Dialogs.EditTermSheetElement.Title',
    defaultMessage: 'Update an element in the term sheet',
  },
  DriveViewDialogsImportFolderFieldsFileLabel: {
    id: 'DriveView.Dialogs.ImportFolder.Fields.File.Label',
    defaultMessage: 'Folder to import',
  },
  DriveViewDialogsDeleteDocumentTitle: {
    id: 'DriveView.Dialogs.DeleteDocument.Title',
    defaultMessage: 'Delete a document',
  },
  DriveViewDialogsDeleteDocumentWarning: {
    id: 'DriveView.Dialogs.DeleteDocument.Warning',
    defaultMessage: 'Attention',
  },
  DriveViewDialogsDeleteDocumentExplanation: {
    id: 'DriveView.Dialogs.DeleteDocument.Explanation',
    defaultMessage:
      'Are you sure you want to delete this document? It will be placed in the trash.',
  },
  DriveViewDialogsDeleteDocumentFolderTitle: {
    id: 'DriveView.Dialogs.DeleteDocumentFolder.Title',
    defaultMessage: 'Delete a folder',
  },
  DriveViewDialogsDeleteDocumentFolderWarning: {
    id: 'DriveView.Dialogs.DeleteDocumentFolder.Warning',
    defaultMessage: 'Attention',
  },
  DriveViewDialogsDeleteDocumentFolderExplanation: {
    id: 'DriveView.Dialogs.DeleteDocumentFolder.Explanation',
    defaultMessage:
      'Are you sure you want to delete this folder? It will be placed in the trash.',
  },
  DriveViewShareModalDocumentUsersTitle: {
    id: 'DriveView.Share.Modal.Document.Users.Title',
    defaultMessage: 'Users with document access',
  },
  DriveViewShareModalFolderUsersTitle: {
    id: 'DriveView.Share.Modal.Folder.Users.Title',
    defaultMessage: 'Users with folder access',
  },
  DriveViewShareModalSaveError: {
    id: 'DriveView.Share.Modal.Save.Error',
    defaultMessage: 'Your sharing settings have not been saved',
  },
  DriveViewShareModalSaveSuccess: {
    id: 'DriveView.Share.Modal.Save.Success',
    defaultMessage: 'Your sharing settings have been saved',
  },
  DriveViewShareModalRemoveRights: {
    id: 'DriveView.Share.Modal.RemoveRights',
    defaultMessage: 'Remove access',
  },
  DriveViewShareModalRightsContributor: {
    id: 'DriveView.Share.Modal.Rights.Contributor',
    defaultMessage: 'Contributor',
  },
  DriveViewShareModalRightsOwner: {
    id: 'DriveView.Share.Modal.Rights.Owner',
    defaultMessage: 'Owner',
  },
  DriveViewShareModalRightsReadAndWrite: {
    id: 'DriveView.Share.Modal.Rights.ReadAndWrite',
    defaultMessage: 'Read and write',
  },
  DriveViewShareModalRightsReadOnly: {
    id: 'DriveView.Share.Modal.Rights.ReadOnly',
    defaultMessage: 'Read only',
  },
  DriveViewShareModalRightsVisible: {
    id: 'DriveView.Share.Modal.Rights.Visible',
    defaultMessage: 'Visible',
  },
  DriveViewShareModalSearchPlaceHolder: {
    id: 'DriveView.Share.Modal.Search.PlaceHolder',
    defaultMessage: 'Add users or groups',
  },
  DriveViewShareModalTypeAccessTitle: {
    id: 'DriveView.Share.Modal.Type.Access.Title',
    defaultMessage: 'Type of access',
  },
  DriveviewTermSheetAnalyseAI: {
    id: 'Driveview.TermSheet.Analyse.AI',
    defaultMessage: 'AI',
  },
  DriveviewTermSheetAnalyseDataProcessing: {
    id: 'Driveview.TermSheet.Analyse.DataProcessing',
    defaultMessage: 'Data processing',
  },
  DriveviewTermSheetAnalyseFormatNotSupported: {
    id: 'Driveview.TermSheet.Analyse.FormatNotSupported',
    defaultMessage: 'Format not supported',
  },
  DriveviewTermSheetAnalyseNoElementsToExtract: {
    id: 'Driveview.TermSheet.Analyse.NoElementsToExtract',
    defaultMessage: 'No elements to extract',
  },
  DriveviewTermSheetAnalyseStatusTreatmentCancelled: {
    id: 'Driveview.TermSheet.Analyse.Status.TreatmentCancelled',
    defaultMessage: 'Treatment cancelled',
  },
  DriveviewTermSheetAnalyseStatusTreatmentFailed: {
    id: 'Driveview.TermSheet.Analyse.Status.TreatmentFailed',
    defaultMessage: 'Treatment failed',
  },
  DriveviewTermSheetAnalyseStatusTreatmentInProgress: {
    id: 'Driveview.TermSheet.Analyse.Status.TreatmentInProgress',
    defaultMessage: 'Treatment in progress',
  },
  DriveviewTermSheetAnalyseStatusTreatmentPending: {
    id: 'Driveview.TermSheet.Analyse.Status.TreatmentPending',
    defaultMessage: 'Treatment pending',
  },
  DriveviewTermSheetAnalyseStatusTreatmentSuccessful: {
    id: 'Driveview.TermSheet.Analyse.Status.TreatmentSuccessful',
    defaultMessage: 'Treatment successful',
  },
  DriveviewTermSheetButtonsAddLabel: {
    id: 'Driveview.TermSheet.Buttons.Add.Label',
    defaultMessage: 'Add an element',
  },
  DriveviewTermSheetButtonsExportLabel: {
    id: 'Driveview.TermSheet.Buttons.Export.Label',
    defaultMessage: 'Export',
  },
  DriveviewTermSheetElements: {
    id: 'Driveview.TermSheet.Elements',
    defaultMessage: 'Elements',
  },
  DriveviewTermSheetTemplateAssociatedTemplate: {
    id: 'Driveview.TermSheet.Template.AssociatedTemplate',
    defaultMessage: 'Associated template',
  },
  DriveviewTermSheetTemplateNoAssociatedTemplate: {
    id: 'Driveview.TermSheet.Template.NoAssociatedTemplate',
    defaultMessage: 'No associated template',
  },
  DriveViewDialogsDeleteTermSheetElementTitle: {
    id: 'Driveview.Dialogs.DeleteTermSheetElement.Title',
    defaultMessage: 'Delete an element from the term sheet',
  },
  DriveViewDeleteTermSheetElementButtonsDeleteLabel: {
    id: 'DriveView.DeleteTermSheetElement.Buttons.Delete.Label',
    defaultMessage: 'Delete',
  },
  DriveViewDeleteTermSheetElementDescriptionText: {
    id: 'DriveView.DeleteTermSheetElement.Description.Text',
    defaultMessage: 'Are you sure you want to delete this term sheet element ?',
  },
  DriveviewReminderButtonsAddLabel: {
    id: 'Driveview.Reminder.Buttons.Add.Label',
    defaultMessage: 'Add a reminder',
  },
  DriveviewReminderDayBefore: {
    id: 'Driveview.Reminder.Day.Before',
    defaultMessage: 'Reminder {nbre} day(s) before',
  },
  DriveviewReminderDayAfter: {
    id: 'Driveview.Reminder.Day.After',
    defaultMessage: 'Reminder {nbre} day(s) after',
  },
  DriveviewReminderWeekBefore: {
    id: 'Driveview.Reminder.Week.Before',
    defaultMessage: 'Reminder {nbre} week(s) before',
  },
  DriveviewReminderWeekAfter: {
    id: 'Driveview.Reminder.Week.After',
    defaultMessage: 'Reminder {nbre} week(s) after',
  },
  DriveviewReminderMonthBefore: {
    id: 'Driveview.Reminder.Month.Before',
    defaultMessage: 'Reminder {nbre} month(s) before',
  },
  DriveviewReminderMonthAfter: {
    id: 'Driveview.Reminder.Month.After',
    defaultMessage: 'Reminder {nbre} month(s) after',
  },
  DriveViewSearchExportResultsTooltip: {
    id: 'DriveView.Search.ExportResults.Tooltip',
    defaultMessage: 'Export results to csv',
  },
  DriveViewSearchExportResultsError: {
    id: 'DriveView.Search.ExportResults.Error',
    defaultMessage: 'An error occured while exporting results to a csv file.',
  },
  DriveViewButtonsAddMailMergeLabel: {
    id: 'DriveView.Buttons.AddMailMerge.Label',
    defaultMessage: 'Mail Merge',
  },
  DriveViewTermsheetModalExportTitle: {
    id: 'DriveView.Termsheet.Modal.Export.Title',
    defaultMessage: 'Export the termsheet',
  },
  DriveViewTermsheetModalExportMessage: {
    id: 'DriveView.Termsheet.Modal.Export.Message',
    defaultMessage:
      'You are about to download a PDF version of the term sheet. Do you want to continue?',
  },
  DriveViewColumnsProgressText: {
    id: 'DriveView.Columns.Progress.Text',
    defaultMessage: 'Progress',
  },
  DriveViewDocumentLinksLabel: {
    id: 'DriveView.DocumentLinks.Label',
    defaultMessage: 'Links',
  },
  DriveViewDocumentLinksButtonAdd: {
    id: 'DriveView.DocumentLinks.Button.Add',
    defaultMessage: 'Link to another document',
  },
  DriveViewDocumentLinksButtonUpdate: {
    id: 'DriveView.DocumentLinks.Button.Update',
    defaultMessage: 'Edit linked documents',
  },
  DriveViewDocumentLinksDialogsAnnexWarning: {
    id: 'DriveView.DocumentLinks.Dialogs.AnnexWarning',
    defaultMessage:
      'The annexes link can only be modified via the master contract annexes tab.',
  },
  DriveViewDocumentLinksDialogsButtonAdd: {
    id: 'DriveView.DocumentLinks.Dialogs.Button.Add',
    defaultMessage: 'Add a link to a new document',
  },
  DriveViewDocumentLinksDialogsButtonSave: {
    id: 'DriveView.DocumentLinks.Dialogs.Button.Save',
    defaultMessage: 'Save',
  },
  DriveViewDocumentLinksDialogsInputPlaceholder: {
    id: 'DriveView.DocumentLinks.Dialogs.Input.Placeholder',
    defaultMessage: 'Find the document to link',
  },
  DriveViewDocumentLinksDialogsTitle: {
    id: 'DriveView.DocumentLinks.Dialogs.Title',
    defaultMessage: 'Link to another document',
  },
  DriveViewDocumentLinksDialogsSubtitle: {
    id: 'DriveView.DocumentLinks.Dialogs.Subtitle',
    defaultMessage:
      'Link this document to one or more other documents and specify the link type',
  },
  DriveViewDocumentLinksLabelLinkedDocument: {
    id: 'DriveView.DocumentLinks.DocumentTypeName.LinkedDocument',
    defaultMessage: 'Linked document',
  },
  DriveViewDocumentLinksLabelOriginalContract: {
    id: 'DriveView.DocumentLinks.DocumentTypeName.OriginalContract',
    defaultMessage: 'Original contract',
  },
  DriveViewDocumentLinksLabelAmendment: {
    id: 'DriveView.DocumentLinks.DocumentTypeName.Amendment',
    defaultMessage: 'Amendment',
  },
  DriveViewDocumentLinksDocumentTypeNameApplicationAgreement: {
    id: 'DriveView.DocumentLinks.DocumentTypeName.ApplicationAgreement',
    defaultMessage: 'Application agreement',
  },
  DriveViewDocumentLinksDocumentTypeNameMasterAgreement: {
    id: 'DriveView.DocumentLinks.DocumentTypeName.MasterAgreement',
    defaultMessage: 'Master agreement',
  },
  DriveViewDocumentLinksDocumentTypeNameMainContract: {
    id: 'DriveView.DocumentLinks.DocumentTypeName.MainContract',
    defaultMessage: 'Main contract',
  },
  DriveViewDocumentLinksDocumentTypeNameAnnex: {
    id: 'DriveView.DocumentLinks.DocumentTypeName.Annex',
    defaultMessage: 'Annex',
  },
  DriveViewDocumentLinksTypeChoose: {
    id: 'DriveView.DocumentLinks.Type.Choose',
    defaultMessage: 'Choose the type of link',
  },
  DriveViewDocumentLinksTypeHasForAmendment: {
    id: 'DriveView.DocumentLinks.Type.HasForAmendment',
    defaultMessage: 'Has for amendment',
  },
  DriveViewDocumentLinksTypeHasForAnnex: {
    id: 'DriveView.DocumentLinks.Type.HasForAnnex',
    defaultMessage: 'Has for annex',
  },
  DriveViewDocumentLinksTypeHasForMasterAgreement: {
    id: 'DriveView.DocumentLinks.Type.HasForMasterAgreement',
    defaultMessage: 'Has for master agreement',
  },
  DriveViewDocumentLinksTypeIsAmendmentOf: {
    id: 'DriveView.DocumentLinks.Type.IsAmendmentOf',
    defaultMessage: 'Is amendment of',
  },
  DriveViewDocumentLinksTypeIsAnnexOf: {
    id: 'DriveView.DocumentLinks.Type.IsAnnexOf',
    defaultMessage: 'Is annex of',
  },
  DriveViewDocumentLinksTypeIsLinkedTo: {
    id: 'DriveView.DocumentLinks.Type.IsLinkedTo',
    defaultMessage: 'Is linked to',
  },
  DriveViewDocumentLinksTypeIsMasterAgreementOf: {
    id: 'DriveView.DocumentLinks.Type.IsMasterAgreementOf',
    defaultMessage: 'Is master agreement of',
  },
  DriveViewModalImportDocumentTitle: {
    id: 'DriveView.Modal.ImportDocument.Title',
    defaultMessage: 'Import a document',
  },
  DriveViewModalImportDocumentButtonImport: {
    id: 'DriveView.Modal.ImportDocument.Button.Import',
    defaultMessage: 'Import',
  },
  DriveViewUploadFileModalLabel: {
    id: 'DriveView.Upload.File.Modal.Label',
    defaultMessage: 'Document to import',
  },
  DriveViewImportTypeActionsLabel: {
    id: 'DriveView.Import.Type.Actions.Label',
    defaultMessage: 'What actions would you like to take on this document ?',
  },
  DriveViewImportActionsToReviewLabel: {
    id: 'DriveView.Import.Actions.ToReview.Label',
    defaultMessage: 'Review / Modify',
  },
  DriveViewImportActionsToCommentLabel: {
    id: 'DriveView.Import.Actions.ToComment.Label',
    defaultMessage: 'Get approval',
  },
  DriveViewImportActionsToSignLabel: {
    id: 'DriveView.Import.Actions.ToSign.Label',
    defaultMessage: 'Sign',
  },
  DriveViewImportTypeDocumentStatutLabel: {
    id: 'DriveView.Import.Type.Document.Statut.Label',
    defaultMessage: 'Select document status : ',
  },
  DriveViewImportFieldNameLabel: {
    id: 'DriveView.Import.Field.Name.Label',
    defaultMessage: 'Document name',
  },
  DriveViewImportFieldValidatedDateLabel: {
    id: 'DriveView.Import.Field.Validated.Date.Label',
    defaultMessage: 'Please enter the document validation date',
  },
  DriveViewImportFieldSignedDateLabel: {
    id: 'DriveView.Import.Field.Signed.Date.Label',
    defaultMessage: 'Please enter the date the document was signed',
  },
  DriveViewImportTypeDocumentWorkFlowLabel: {
    id: 'DriveView.Import.Type.Document.WorkFlow.Label',
    defaultMessage: 'Choose the workflow to be applied to the document : ',
  },
  DriveViewImportTypeFicheContratAssociateLabel: {
    id: 'DriveView.Import.Type.FicheContrat.Associate.Label',
    defaultMessage: 'Associate the imported document with a contract form',
  },
  DriveViewImportTypeFicheContratCompletionLabel: {
    id: 'DriveView.Import.Type.FicheContrat.Completion.Label',
    defaultMessage: 'Completing the contract form',
  },
  DriveViewImportTypeToAnalyseByIaLabel: {
    id: 'DriveView.Import.Type.ToAnalyseByIa.Label',
    defaultMessage: 'Enable document analysis by artificial intelligence',
  },
  DriveViewImportTypeToAnalyseByIaTooltipsNoFicheContratLabel: {
    id: 'DriveView.Import.Type.ToAnalyseByIa.Tooltips.NoFicheContrat.Label',
    defaultMessage:
      'Associate the imported document with a contract form template to activate the IA feature.',
  },
  DriveViewImportTypeToAnalyseByIaTooltipsWrongDocumentLabel: {
    id: 'DriveView.Import.Type.ToAnalyseByIa.Tooltips.WrongDocument.Label',
    defaultMessage: 'AI processing not available for this format.',
  },
  DriveViewImportTypeNoStatusLabel: {
    id: 'DriveView.Import.Type.No.Status.Label',
    defaultMessage: 'No status (by default)',
  },
  DriveViewImportTypePendingLabel: {
    id: 'DriveView.Import.Type.Pending.Label',
    defaultMessage: 'Pending document with workflow',
  },
  DriveViewImportTypeSignedLabel: {
    id: 'DriveView.Import.Type.Signed.Label',
    defaultMessage: 'Signed document',
  },
  DriveViewImportTypeFinishedLabel: {
    id: 'DriveView.Import.Type.Finished.Label',
    defaultMessage: 'Validated document',
  },
  DriveViewImportTypeDefaultWorkFlowLabel: {
    id: 'DriveView.Import.Type.Default.WorkFlow.Label',
    defaultMessage: 'Workflow by default',
  },
  DriveViewImportTypeSpecificWorkFlowLabel: {
    id: 'DriveView.Import.Type.Specific.WorkFlow.Label',
    defaultMessage: 'Specific workflows',
  },
  DriveViewImportDocumentMessageSuccess: {
    id: 'DriveView.Import.Document.Message.Success',
    defaultMessage: 'Document has been saved',
  },
  DriveViewImportContractMessageSuccess: {
    id: 'DriveView.Import.Contract.Message.Success',
    defaultMessage: 'Contract has been created',
  },
  DriveViewImportContractHasStateMessageError: {
    id: 'DriveView.Import.Contract.HasState.Message.Error',
    defaultMessage: 'Contract with state not managed yet',
  },
  DriveViewImportDocumentDocxMessageError: {
    id: 'DriveView.Import.Document.Docx.Message.Error',
    defaultMessage: 'Docx contract not managed yet',
  },
});
