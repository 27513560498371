import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SketchPicker } from 'react-color';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';

function ColorPicker({ color, forceUpdateColor, onSubmit, onReset, children }) {
  const [currentColor, setColor] = React.useState(color ?? '#000');

  if (color && color !== currentColor && forceUpdateColor) {
    setColor(color); // force set color
  }
  return (
    <>
      <SketchPicker
        color={currentColor}
        onChangeComplete={colr => setColor(colr.hex)}
      />
      {!children && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            variant="outlined"
            onClick={onReset}
            startIcon={<FormatColorResetIcon />}
          >
            <FormattedMessage id="Editor.Toolbar.Command.Color.Clear" />
          </Button>
          <Button variant="contained" onClick={() => onSubmit(currentColor)}>
            <FormattedMessage id="Verbs.Done" />
          </Button>
        </Box>
      )}
      {children && children({ currentColor })}
    </>
  );
}

export default ColorPicker;
