import { atom, selector } from 'recoil';
import decode from 'jwt-decode';

const defaultToken = localStorage.getItem('token');

export const tokenState = atom({
  key: 'token',
  default: defaultToken,
});

export const authenticatedSelector = selector({
  key: 'authenticatedSelector',
  get: ({ get }) => {
    const token = get(tokenState);
    if (!token) return null;
    return decode(token);
  },
});

export const userDisplayNameSelector = selector({
  key: 'userDisplayNameSelector',
  get: ({ get }) => {
    const user = get(authenticatedSelector);
    const name = `${user.family_name} ${user.given_name}`.trim();
    if (name !== '') return name;
    return user.email;
  },
});

export const userPermissionsSelector = selector({
  key: 'userPermissionsSelector',
  get: ({ get }) => {
    const user = get(authenticatedSelector);
    return (user?.role ?? '').split('').map(c => c.charCodeAt(0));
  },
});
