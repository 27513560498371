import React, { useMemo, useState } from 'react';
import { apiStatusTexts } from '@components/Chips/DocumentChip';
import DialogActionsWithClose from '@components/Dialog/DialogActionsWithClose';
import DialogOutletRoutes from '@components/Dialog/DialogOutletRoutes';
import DialogTitleWithClose from '@components/Dialog/DialogTitleWithClose';
import DialogViewer from '@components/Dialog/DialogViewer';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import Fetching from '@components/Fetching/Fetching';
import FormikFileField from '@components/Formik/FormikFileField';
import FormikProvider from '@components/Formik/FormikProvider';
import FormikTextField from '@components/Formik/FormikTextField';
import useFetch from '@hooks/useFetch';
import { DialogContent, styled } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  blockDocumentTypes,
  nexityFolderStatuses,
  nexitySignatureOptions,
  nexitySignatureStatuses,
  nexityUploadAccept,
} from '@shells/nexity/nexity-constants';
import {
  Link,
  Route,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import * as yup from 'yup';
import FormikAutocomplete from '@components/Formik/FormikAutocomplete';
import AddButton from '@components/Buttons/AddButton';
import DeleteIconButton from '@components/Buttons/IconButtons/DeleteIconButton';
import { FormattedMessage } from 'react-intl';
import { blockDocumentIcons } from '../Administration/ContractualPacks/BlockSettings/components/Block';
import DocumentChip from './components/DocumentChip';
import FolderAlerts from './FolderAlerts';

const FolderDocumentBox = styled(Box)(({ sx: { filled } }) => ({
  backgroundColor: filled ? '#B5B5B5' : 'white',
  borderColor: '#DADADA',
  borderStyle: 'solid',
  borderRadius: '4px',
  borderWidth: '1px',
}));

function ErrorMessageDetail({ error }) {
  console.log('detail', error);

  if (typeof error !== 'object') return null;

  const names =
    error?.uncompletedContractNames ?? error?.missingDocumentNames ?? [];

  return (
    Boolean(names?.length) && (
      <ul>
        {names.map(name => (
          <li>{name}</li>
        ))}
      </ul>
    )
  );
}

function FolderDocument({ spaceDocument, preventModifications, invalidated }) {
  const { packDocument, documents, canImport } = spaceDocument;
  const { type, template } = packDocument;
  const packDocumentName = template?.name || packDocument.name;
  const Icon = blockDocumentIcons[type];
  const isTemplate = type === blockDocumentTypes.template;
  const isMbk = type === blockDocumentTypes.nexityPiece;
  const firstDocument = documents?.[0];
  const contract = firstDocument?.contract;
  const contractValidated = firstDocument?.validated;

  return (
    <FolderDocumentBox
      sx={{
        px: 2,
        filled: Boolean(contract?.isWhiteSpace || !documents?.length),
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ py: 1 }}>
        <Icon />
        <Stack
          direction="row"
          alignItems="center"
          ml={1}
          my={1}
          mr="auto"
          gap={2}
          sx={{ width: '100%' }}
        >
          <Typography mr="auto">{packDocumentName}</Typography>
          {Boolean(contract) && !invalidated && (
            <DocumentChip
              status={
                contractValidated
                  ? apiStatusTexts.finished
                  : apiStatusTexts.pending
              }
            />
          )}
          {Boolean(documents.length) && !isTemplate && !isMbk && canImport && (
            <Button
              variant="outlined"
              component={Link}
              to={`modals/document/prospect/import/${encodeURIComponent(
                packDocumentName
              )}`}
            >
              Importer
            </Button>
          )}
        </Stack>
        {isTemplate && Boolean(contract) && (
          <Button
            component={Link}
            to={`../contract/${contract.id}`}
            sx={{ ml: 1 }}
          >
            Accéder
          </Button>
        )}
        {isMbk && Boolean(firstDocument) && (
          <Stack direction="row" spacing={1}>
            {!preventModifications && (
              <>
                <Button
                  variant="outlined"
                  component={Link}
                  to={`modals/document/${
                    firstDocument.id
                  }/mbk/replace/${encodeURIComponent(packDocumentName)}`}
                >
                  Remplacer
                </Button>
                <Button
                  variant="outlined"
                  component={Link}
                  to={`modals/document/${
                    firstDocument.id
                  }/mbk/delete/${encodeURIComponent(packDocumentName)}`}
                >
                  Supprimer
                </Button>
              </>
            )}
            <Button
              component={Link}
              to={`modals/document/${
                firstDocument.id
              }/view/${encodeURIComponent(firstDocument.name)}`}
            >
              Consulter
            </Button>
          </Stack>
        )}
        {!documents.length &&
          !preventModifications &&
          (isMbk ? (
            <Button
              variant="outlined"
              component={Link}
              to={`modals/document/mbk/import/${encodeURIComponent(
                packDocumentName
              )}`}
            >
              Importer
            </Button>
          ) : (
            Boolean(canImport) && (
              <Button
                variant="outlined"
                component={Link}
                to={`modals/document/prospect/import/${encodeURIComponent(
                  packDocumentName
                )}`}
              >
                Importer
              </Button>
            )
          ))}
      </Stack>
      {!isTemplate && !isMbk && (
        <>
          {Boolean(documents.length) && <Divider sx={{ mb: 2 }} />}
          {documents.map(document => (
            <Stack
              key={document.id}
              direction="row"
              alignItems="center"
              pl={4}
              gap={1}
              pb={2}
            >
              <Typography mr="auto">{document.name || 'Document'}</Typography>
              {!preventModifications && (
                <>
                  <Button
                    variant="outlined"
                    component={Link}
                    to={`modals/document/${
                      document.id
                    }/replace/${encodeURIComponent(packDocumentName)}`}
                  >
                    Remplacer
                  </Button>
                  <Button
                    variant="outlined"
                    component={Link}
                    to={`modals/document/${
                      firstDocument.id
                    }/delete/${encodeURIComponent(packDocumentName)}`}
                  >
                    Supprimer
                  </Button>
                </>
              )}
              <Button
                component={Link}
                to={`modals/document/${document.id}/view/${encodeURIComponent(
                  document.name || packDocumentName
                )}`}
              >
                Consulter
              </Button>
            </Stack>
          ))}
        </>
      )}
    </FolderDocumentBox>
  );
}

function FolderBlock({ spaceBlock, preventModifications, invalidated }) {
  return (
    <Paper sx={{ px: 3, py: 2 }}>
      <Typography
        sx={{ mb: 2, textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {spaceBlock.packBlock.name}
      </Typography>
      <Stack gap={1}>
        {spaceBlock.spaceDocuments.map((spaceDocument, index) => (
          <FolderDocument
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            spaceDocument={spaceDocument}
            preventModifications={preventModifications}
            invalidated={invalidated}
          />
        ))}
      </Stack>
    </Paper>
  );
}

function UploadMultipleFileInputs({ fileFields, setFileFields }) {
  return (
    <>
      {fileFields.map(fieldId => (
        <Box
          key={`area-${fieldId}`}
          sx={{
            display: 'flex',
          }}
        >
          <FormikFileField
            key={fieldId}
            name={fieldId}
            required
            accept={nexityUploadAccept}
            label={
              <FormattedMessage
                id="FormView.DocumentToImport.DocumentUploadRow.Fields.Document"
                defaultMessage="Document à importer"
              />
            }
          />
          {Boolean(fileFields.length > 1) && (
            <DeleteIconButton
              key={`delete-btn-${fieldId}`}
              sx={{
                mt: 4,
                ml: 1,
              }}
              onClick={() => {
                setFileFields(fileFields.filter(ff => ff !== fieldId));
              }}
            />
          )}
        </Box>
      ))}
      <Box sx={{ textAlign: 'center' }}>
        <AddButton
          variant="text"
          label="Ajouter un document"
          onClick={() => {
            const newFileFieldNumber =
              parseInt(
                fileFields[fileFields.length - 1].replace('file', ''),
                10
              ) + 1;
            setFileFields(current => [...current, `file${newFileFieldNumber}`]);
          }}
        />
      </Box>
    </>
  );
}

function ReplaceDocumentDialog({ folderId, mbk }) {
  const { close } = useOutletContext();
  const { documentId } = useParams();
  const { documentName } = useParams();
  const [fileFields, setFileFields] = useState(['file1']);

  const post = useFetch({
    url: mbk
      ? `nexity/dashboard/folders/${folderId}/contractualization/documents/${documentId}/replace`
      : `nexity/dashboard/folders/${folderId}/documents/${documentId}/replace`,
    noContentType: true,
    method: 'PUT',
    onSuccess: () => {
      close();
    },
  });

  const onSubmit = values => {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      if (key.includes('file')) {
        formData.append('Files', values[key]);
      }
    });
    post.doFetch({ body: formData });
  };

  return (
    <FormikProvider onSubmit={onSubmit}>
      <DialogTitleWithClose onClose={close}>
        Remplacer un document
      </DialogTitleWithClose>
      <DialogContent>
        <ErrorMessage error={post.error} />
        <Typography sx={{ mb: 2 }}>
          Veuillez sélectionner le/les document(s) pour remplacer :
          {documentName}.
        </Typography>
        <UploadMultipleFileInputs
          fileFields={fileFields}
          setFileFields={setFileFields}
        />
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button type="submit" variant="contained" disabled={post.fetching}>
          Remplacer
        </Button>
      </DialogActionsWithClose>
    </FormikProvider>
  );
}

function ImportDocumentDialog({ folderId, detail }) {
  const { close } = useOutletContext();
  const { documentName } = useParams();
  const [fileFields, setFileFields] = useState(['file1']);
  const isDocumentNameValid = useMemo(
    () =>
      detail.data?.spaceBlocks?.some(spaceBlock =>
        spaceBlock?.spaceDocuments?.some(
          spaceDocument => spaceDocument?.packDocument?.name === documentName
        )
      ),
    [detail.data?.spaceBlocks, documentName]
  );

  const post = useFetch({
    url: `nexity/dashboard/folders/${folderId}/contractualization/documents`,
    noContentType: true,
    method: 'POST',
    onSuccess: () => {
      close();
    },
  });

  const onSubmit = values => {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      if (key.includes('file')) {
        formData.append('Files', values[key]);
      }
    });
    formData.set('documentName', documentName);
    post.doFetch({ body: formData });
  };

  return (
    <FormikProvider onSubmit={onSubmit}>
      <DialogTitleWithClose onClose={close}>
        Importer un document
      </DialogTitleWithClose>
      <DialogContent>
        <Fetching fetching={detail.fetching}>
          {isDocumentNameValid ? (
            <>
              <ErrorMessage error={post.error} />
              <Typography sx={{ mb: 2 }}>
                Veuillez sélectionner le/les document(s) pour : {documentName}.
              </Typography>
              <UploadMultipleFileInputs
                fileFields={fileFields}
                setFileFields={setFileFields}
              />
            </>
          ) : (
            <Alert severity="error">
              Le document {documentName} ne peut pas être importé dans la
              contractualisation.
            </Alert>
          )}
        </Fetching>
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button
          type="submit"
          variant="contained"
          disabled={detail.fetching || post.fetching || !isDocumentNameValid}
        >
          Importer
        </Button>
      </DialogActionsWithClose>
    </FormikProvider>
  );
}

function ImportProspectDocumentDialog({ folderId, buyers }) {
  const { close } = useOutletContext();
  const { documentName } = useParams();
  const [fileFields, setFileFields] = useState(['file1']);

  const buyersNames =
    buyers?.map(buyer => `${buyer.FirstName} ${buyer.LastName}`) ?? [];

  const uploadDocument = useFetch({
    url: `nexity/dashboard/folders/${folderId}/supporting/create-folder-and-upload-document`,
    method: 'POST',
    noContentType: true,
    onSuccess: () => {
      close();
    },
  });

  const onSubmit = values => {
    const formData = new FormData();
    formData.set(
      'FolderDocumentName',
      `[${values.buyerName}] - ${documentName}`
    );
    Object.keys(values).forEach(key => {
      if (key.includes('file')) {
        formData.append('Files', values[key]);
      }
    });

    uploadDocument.doFetch({ body: formData });
  };

  return (
    <FormikProvider
      schema={yup.object({
        buyerName: yup.string().nullable().required('Generic.Fields.Required'),
      })}
      initialValues={{ buyerName: null }}
      onSubmit={onSubmit}
    >
      <DialogTitleWithClose onClose={close}>
        Importer un document
      </DialogTitleWithClose>
      <DialogContent>
        <ErrorMessage error={uploadDocument.error} />
        <Typography sx={{ mb: 2 }}>
          Veuillez sélectionner le nom de l&apos;acquéreur et le/les document(s)
          pour : {documentName}.
        </Typography>
        <FormikAutocomplete
          as="field"
          name="buyerName"
          required
          label="Nom Acquéreur"
          size="small"
          options={buyersNames}
          disabled={uploadDocument.fetching}
        />
        <UploadMultipleFileInputs
          fileFields={fileFields}
          setFileFields={setFileFields}
        />
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button
          type="submit"
          variant="contained"
          disabled={uploadDocument.fetching}
        >
          Importer
        </Button>
      </DialogActionsWithClose>
    </FormikProvider>
  );
}

function DeleteDocumentDialog({ folderId, mbk }) {
  const { close } = useOutletContext();
  const { documentId } = useParams();
  const { documentName } = useParams();

  const post = useFetch({
    url: mbk
      ? `nexity/dashboard/folders/${folderId}/contractualization/documents/${documentId}`
      : `nexity/dashboard/folders/${folderId}/documents/${documentId}`,
    noContentType: true,
    method: 'DELETE',
    onSuccess: () => {
      close();
    },
  });

  const onSubmit = values => {
    const formData = new FormData();
    formData.set('file', values.file);
    post.doFetch();
  };

  return (
    <FormikProvider onSubmit={onSubmit}>
      <DialogTitleWithClose onClose={close}>
        Supprimer un document
      </DialogTitleWithClose>
      <DialogContent>
        <Typography>
          Vous vous apprêtez à supprimer le document : {documentName}
        </Typography>
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button type="submit" variant="contained" disabled={post.fetching}>
          Supprimer
        </Button>
      </DialogActionsWithClose>
    </FormikProvider>
  );
}

const invalidateSchema = yup.object({
  message: yup.string().required('Generic.Fields.Required'),
});

function InvalidateDialog({ folderId }) {
  const { close } = useOutletContext();

  const post = useFetch({
    url: `nexity/dashboard/folders/${folderId}/contractualization/invalidate`,
    method: 'POST',
    onSuccess: () => {
      close();
    },
  });

  const onSubmit = values => {
    post.doFetch({ body: values });
  };

  return (
    <FormikProvider
      schema={invalidateSchema}
      initialValues={{ message: '' }}
      onSubmit={onSubmit}
    >
      <DialogTitleWithClose onClose={close}>
        Invalider un contrat
      </DialogTitleWithClose>
      <DialogContent>
        <ErrorMessage error={post.error} />
        <Typography>Vous vous apprêtez à invalider le contrat.</Typography>
        <FormikTextField
          name="message"
          label="Votre message à destination du vendeur"
          multiline
          rows={5}
          required
        />
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button type="submit" variant="contained" disabled={post.fetching}>
          Invalider
        </Button>
      </DialogActionsWithClose>
    </FormikProvider>
  );
}

function ValidateDialog({ folderId }) {
  const { close } = useOutletContext();

  const post = useFetch({
    url: `nexity/dashboard/folders/${folderId}/contractualization/validate`,
    method: 'POST',
    onSuccess: () => {
      close();
    },
  });

  return (
    <>
      <DialogTitleWithClose onClose={close}>
        Validation du contrat
      </DialogTitleWithClose>
      <DialogContent>
        <ErrorMessage
          error={post.error}
          sx={{ mb: 2 }}
          DetailComponent={ErrorMessageDetail}
        />
        <Typography>Vous vous apprêtez à valider le contrat.</Typography>
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button
          onClick={() => post.doFetch()}
          variant="contained"
          disabled={post.fetching}
        >
          Valider
        </Button>
      </DialogActionsWithClose>
    </>
  );
}

function SendForSignatureDialog({ folderId, signatureOption, detail }) {
  const listenerRef = React.useRef(null);
  const { close, setCloseable } = useOutletContext();

  const preventClosingDialog = () => {
    setCloseable(false);
    const listener = event => {
      event.preventDefault();
      const message =
        "L'envoi en signature est en cours, êtes-vous sûr de vouloir quitter la page ?";
      // eslint-disable-next-line no-param-reassign
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', listener);
    listenerRef.current = listener;
  };

  const allowClosingDialog = () => {
    setCloseable(true);
    const listener = listenerRef.current;
    if (listener) {
      window.removeEventListener('beforeunload', listener);
      listenerRef.current = null;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => allowClosingDialog, []);

  const post = useFetch({
    url: `nexity/dashboard/folders/${folderId}/signature/send`,
    method: 'POST',
    onSuccess: () => {
      allowClosingDialog();
      close();
    },
    onError: () => {
      allowClosingDialog();
    },
  });

  const onSubmit = () => {
    post.doFetch();
    preventClosingDialog();
  };

  return (
    <>
      <DialogTitleWithClose onClose={post.fetching ? null : close}>
        Envoyer en signature
      </DialogTitleWithClose>
      <DialogContent>
        <Fetching fetching={detail.fetching}>
          {post.fetching ? (
            <Box>
              <Typography>
                L&apos;envoi en signature peut prendre quelques minutes. Merci
                de patienter.
              </Typography>
              <LinearProgress sx={{ mt: 2 }} />
            </Box>
          ) : (
            <>
              {signatureOption === nexitySignatureOptions.online ? (
                <Typography>
                  Vous vous apprêtez à envoyer le contrat en signature à
                  distance.
                </Typography>
              ) : (
                <>
                  <Typography>
                    Vous vous apprêtez à envoyer le contrat en signature sur
                    place avec le conseiller.
                  </Typography>
                  {!post.error && (
                    <Alert severity="info" sx={{ mt: 2 }}>
                      Assurez vous que l&apos;hôte ait un compte DocuSign valide
                      et actif pour signer en personne.
                    </Alert>
                  )}
                </>
              )}
              <ErrorMessage error={post.error} sx={{ mt: 2 }} />
            </>
          )}
        </Fetching>
        <FormikProvider onSubmit={onSubmit}>
          <DialogActionsWithClose onClose={close}>
            <Button
              type="submit"
              variant="contained"
              disabled={post.fetching || detail.fetching}
            >
              Envoyer
            </Button>
          </DialogActionsWithClose>
        </FormikProvider>
      </DialogContent>
    </>
  );
}

function RevokeSignatureDialog({ folderId }) {
  const { close } = useOutletContext();

  const post = useFetch({
    url: `nexity/dashboard/folders/${folderId}/signature/revoke`,
    method: 'POST',
    onSuccess: () => {
      close();
    },
  });

  const onSubmit = values => {
    post.doFetch({ body: values });
  };

  return (
    <FormikProvider
      schema={invalidateSchema}
      initialValues={{ message: '' }}
      onSubmit={onSubmit}
    >
      <DialogTitleWithClose onClose={close}>
        Révoquer l&apos;envoi en signature
      </DialogTitleWithClose>
      <DialogContent>
        <ErrorMessage error={post.error} />
        <Typography>
          Vous vous apprêtez à révoquer l&apos;envoi du contrat en signature.
        </Typography>
        <FormikTextField
          name="message"
          label="Votre message de revocation"
          multiline
          rows={5}
          required
        />
      </DialogContent>
      <DialogActionsWithClose onClose={close}>
        <Button type="submit" variant="contained" disabled={post.fetching}>
          Révoquer la signature
        </Button>
      </DialogActionsWithClose>
    </FormikProvider>
  );
}

function FolderContractualizationDetail({
  id,
  detail,
  spaceData,
  refreshFolder: refreshFolderProp,
  fetching,
}) {
  const data = spaceData;
  const fetchingData = fetching;
  const validated =
    detail.data?.validatedContractualization &&
    detail.data?.validatedByAffiliate;
  const canValidate = Boolean(detail.data?.canValidate);
  const canSendForSignature = Boolean(detail.data?.canSendForSignature);
  const canRevokeSignature = Boolean(detail.data?.canRevokeSignature);
  const invalidatedContractualization = Boolean(
    detail.data?.invalidatedContractualization
  );
  const signed = data?.space?.status === nexityFolderStatuses.signed;
  const signing =
    detail.data?.signature?.status === nexitySignatureStatuses.pending;
  const signatureOption = detail.data?.signatureOption;

  const refreshFolder = () => {
    detail.doFetch();
    refreshFolderProp();
  };

  const navigate = useNavigate();
  const onClose = () => {
    navigate('.');
    refreshFolder();
  };

  return (
    <>
      <Fetching fetching={!fetching && detail.fetching && !fetchingData}>
        {!fetching && !fetchingData && (
          <Stack direction="column" sx={{ m: 4 }} gap={4}>
            {detail.data?.spaceBlocks.map(spaceBlock => (
              <FolderBlock
                key={spaceBlock.packBlock.name}
                spaceBlock={spaceBlock}
                preventModifications={validated || signed || signing}
                invalidated={invalidatedContractualization}
              />
            ))}
            <Stack direction="row" gap={2} justifyContent="center">
              {canValidate && (
                <Button component={Link} to="modals/validate">
                  Valider le contrat
                </Button>
              )}
              {canSendForSignature && (
                <Button component={Link} to="modals/send-for-signature">
                  Envoyer en signature
                </Button>
              )}
              {canRevokeSignature && (
                <Button component={Link} to="modals/revoke-signature">
                  Révoquer l&apos;envoi en signature
                </Button>
              )}
            </Stack>
          </Stack>
        )}
      </Fetching>
      {/* We need to render these dialogs even when we are fetching the folder detail data */}
      <DialogOutletRoutes path="modals/*" onClose={onClose}>
        <Route
          path="document/:documentId/view/:documentName"
          element={
            <DialogViewer paramName="documentId" nameParamName="documentName" />
          }
        />
        <Route
          path="document/:documentId/replace/:documentName"
          element={<ReplaceDocumentDialog folderId={id} />}
        />
        <Route
          path="document/:documentId/mbk/replace/:documentName"
          element={<ReplaceDocumentDialog folderId={id} mbk />}
        />
        <Route
          path="document/mbk/import/:documentName"
          element={<ImportDocumentDialog folderId={id} detail={detail} />}
        />
        <Route
          path="document/prospect/import/:documentName"
          element={
            <ImportProspectDocumentDialog
              folderId={id}
              buyers={data?.prospect?.Buyers}
            />
          }
        />
        <Route
          path="document/:documentId/mbk/delete/:documentName"
          element={<DeleteDocumentDialog folderId={id} mbk />}
        />
        <Route
          path="document/:documentId/delete/:documentName"
          element={<DeleteDocumentDialog folderId={id} />}
        />
        <Route path="invalidate" element={<InvalidateDialog folderId={id} />} />
        <Route path="validate" element={<ValidateDialog folderId={id} />} />
        <Route
          path="send-for-signature"
          element={
            <SendForSignatureDialog
              folderId={id}
              signatureOption={signatureOption}
              detail={detail}
            />
          }
        />
        <Route
          path="revoke-signature"
          element={<RevokeSignatureDialog folderId={id} />}
        />
      </DialogOutletRoutes>
    </>
  );
}

function FolderContractualization(props) {
  const { id, fetching } = props;

  const detail = useFetch({
    /* NOTE: the added "for=contractualization" is a hack to prevent useFetch from cancelling
     * the request when quickly switching to the Contract Pdf tab.
     */
    url: `/nexity/dashboard/folders/${id}/contractualization?for=contractualization`,
  });

  return (
    <Box>
      {!fetching && <FolderAlerts detail={detail} contractualization />}
      <FolderContractualizationDetail {...props} detail={detail} />
    </Box>
  );
}

export default FolderContractualization;
