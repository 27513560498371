import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import FormikResetForm from '@components/Formik/FormikResetForm';
import FormikSubmitOnChange from '@components/Formik/FormikSubmitOnChange';
import FormikTextField from '@components/Formik/FormikTextField';
import FormikCheckbox from '@components/Formik/FormikCheckbox';
import { useField } from 'formik';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import WorkflowContributorList from '../WorkflowContributors/WorkflowContributorList';
import WorkflowStepDeleteDialog from './WorkflowStepDeleteDialog';
import WorkflowStepWarning from './WorkflowStepWarning';

import {
  stepActiveContributors,
  useWorkflowContext,
} from '../WorkflowContextProvider';

function WorkflowStepEditor({
  variablesData,
  error,
  schema,
  hideNotifyContributors,
  hideNewUser,
  onlyOneContributorByStep = false,
  hideAccess = false,
  hideCondition = false,
  hideRemove = false,
  hideMandatoryContributors = false,
  hideOtherContributor = false,
  hideRemoveStep = false,
  hideEmptyUser = false,
  cantRenameStep = false,
  allowNoContributors = false,
  allowGroupsToHaveUsers = null,
}) {
  const {
    readOnly,
    islast,
    active,
    nextStep,
    methods: { remove },
  } = useWorkflowContext();

  const intl = useIntl();
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [{ value: notifyContributors }] = useField('notifyContributors');
  const nextStepActiveContributors = stepActiveContributors(nextStep);

  const [{ value: hasCompletionMessage }] = useField('hasCompletionMessage');
  const hasNotifyContributors = Boolean(notifyContributors?.length);

  if (!active) return null;

  return (
    <FormikResetForm values={active}>
      <FormikSubmitOnChange debounceMilliseconds={100} />
      <Slide in direction="left" key={active.id} unmountOnExit mountOnEnter>
        <Stack gap={4}>
          <ErrorMessage error={error} />
          <Paper variant="outlined" sx={{ p: 1, px: 2, mt: 1 }}>
            <Stack direction="row" justifyContent="space-between">
              <FormikTextField
                as="fastfield"
                autoFocus
                name="name"
                readOnly={readOnly || cantRenameStep}
                required
                sx={{ maxWidth: 1 / 2 }}
                label={
                  <FormattedMessage id="Workflow.Step.Fields.Name.Label" />
                }
              />
              {!readOnly && !hideRemoveStep && (
                <Stack justifyContent="flex-start" pt={2}>
                  <IconButton onClick={() => setDeleteDialog(true)}>
                    <DeleteOutlineIcon
                      color="error"
                      sx={{ fill: theme => theme.palette.error.main }}
                    />
                  </IconButton>
                </Stack>
              )}
            </Stack>
          </Paper>
          {!readOnly && (
            <WorkflowStepWarning allowNoContributors={allowNoContributors} />
          )}
          {!hideMandatoryContributors && (
            <WorkflowContributorList
              schema={schema}
              name="mandatoryContributors"
              readOnly={readOnly}
              showSignature={islast}
              hideNewUser={hideNewUser}
              hideAccess={hideAccess}
              hideCondition={hideCondition}
              hideRemove={hideRemove}
              primary={
                <FormattedMessage id="Workflow.Step.Fields.Mandatory.Label" />
              }
              action={
                <FormattedMessage id="Workflow.Step.Buttons.Mandatory.Add" />
              }
              variablesData={variablesData}
              onlyOneContributorByStep={onlyOneContributorByStep}
              hideEmptyUser={hideEmptyUser}
              allowGroupsToHaveUsers={allowGroupsToHaveUsers}
            />
          )}
          {!hideOtherContributor && (
            <WorkflowContributorList
              schema={schema}
              name="otherContributors"
              readOnly={readOnly}
              showSignature={islast}
              hideNewUser={hideNewUser}
              hideAccess={hideAccess}
              hideCondition={hideCondition}
              hideRemove={hideRemove}
              primary={
                <FormattedMessage id="Workflow.Step.Fields.Others.Label" />
              }
              action={
                <FormattedMessage id="Workflow.Step.Buttons.Others.Add" />
              }
              variablesData={variablesData}
              onlyOneContributorByStep={onlyOneContributorByStep}
              hideEmptyUser={hideEmptyUser}
              allowGroupsToHaveUsers={allowGroupsToHaveUsers}
            />
          )}
          {!hideNotifyContributors && (
            <WorkflowContributorList
              schema={schema}
              name="notifyContributors"
              readOnlyContributors={nextStepActiveContributors}
              readOnly={readOnly}
              hideNewUser={hideNewUser}
              hideAccess
              hideCondition
              usersOnly
              primary={
                <FormattedMessage id="Workflow.Step.Fields.NotifyContributors.Label" />
              }
              action={
                <FormattedMessage id="Workflow.Step.Buttons.NotifyContributors.Add" />
              }
              variablesData={variablesData}
              allowGroupsToHaveUsers={allowGroupsToHaveUsers}
            >
              {hasNotifyContributors && (
                <Box my={2}>
                  <FormikCheckbox
                    as="fastfield"
                    name="hasCompletionMessage"
                    label={
                      <FormattedMessage id="Workflow.Step.Fields.CompletionMessage.Label" />
                    }
                  />
                  {Boolean(hasCompletionMessage) && (
                    <FormikTextField
                      as="fastfield"
                      name="completionMessage"
                      multiline
                      rows={5}
                      placeholder={intl.formatMessage({
                        id: 'Workflow.Step.Fields.CompletionMessage.Placeholder',
                      })}
                      margin="none"
                    />
                  )}
                </Box>
              )}
            </WorkflowContributorList>
          )}
        </Stack>
      </Slide>
      {deleteDialog && (
        <WorkflowStepDeleteDialog
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          onConfirm={() => {
            setDeleteDialog(false);
            remove(active);
          }}
        />
      )}
    </FormikResetForm>
  );
}

export default WorkflowStepEditor;
