import useTenantPermissions from '@hooks/useTenantPermissions';
import { Divider, MenuItem, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type MenuButtonOptionDividerType = { type: 'divider' };
export type MenuButtonOptionDividerProps = {
  option?: MenuButtonOptionDividerType;
  index?: number;
};

function MenuButtonOptionDivider({
  option,
  index = 0,
}: MenuButtonOptionDividerProps) {
  return option?.type === 'divider' ? (
    <Divider key={`divider-${index}`} sx={{ my: 0.5 }} />
  ) : null;
}

export type MenuButtonOptionItemType = {
  type?: 'item';
  id?: string;
  label?: string;
  icon?: ReactNode;
  defaultMessage?: string;
  onClick?(option: MenuButtonOptionItemType): void;
  permissions?: [];
  disabled?: boolean;
};
export type MenuButtonOptionItemProps = {
  option?: MenuButtonOptionItemType;
  index?: number;
  handleClose?(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
};

function MenuButtonOptionItem({
  option,
  index = 0,
  handleClose,
}: MenuButtonOptionItemProps) {
  const hasPermission = useTenantPermissions({
    permissions: option?.permissions,
  });
  if (
    option === undefined ||
    (!hasPermission && option.permissions && option.permissions.length > 0)
  )
    return null;

  return (
    <MenuItem
      disabled={option.disabled}
      key={index}
      {...option}
      onClick={event => {
        if (option.onClick) {
          option.onClick(option);
        }
        if (handleClose) {
          handleClose(event);
        }
      }}
      disableRipple
    >
      <Stack direction="row" alignItems="center" gap={0.2}>
        {option.icon}
        <Typography sx={{ fontSize: '11px' }}>
          {!option.id ? (
            option.defaultMessage ?? option.label
          ) : (
            <FormattedMessage
              id={option.id}
              defaultMessage={option.defaultMessage ?? option.label}
            />
          )}
        </Typography>
      </Stack>
    </MenuItem>
  );
}

export type MenuButtonOptionProps = {
  option: MenuButtonOptionItemType | MenuButtonOptionDividerType | null;
  index: number;
  handleClose?(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
};

export default function MenuButtonOption({
  option,
  index,
  handleClose,
}: MenuButtonOptionProps) {
  return option?.type === 'divider' ? (
    <MenuButtonOptionDivider option={option} index={index} />
  ) : (
    <MenuButtonOptionItem
      handleClose={handleClose}
      option={option ?? undefined}
      index={index}
    />
  );
}
