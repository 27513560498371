import React from 'react';
import { Button, DialogContent } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import DialogTitleWithClose from '@components/Dialog/DialogTitleWithClose';
import DialogActionsWithClose from '@components/Dialog/DialogActionsWithClose';
import * as yup from 'yup';
import '@utils/yup/phone'; // add Yup.phone() validation
import FormikPhone from '@components/Formik/FormikPhone';
import { Form, Formik, FormikProvider } from 'formik';
import useFetch from '@hooks/useFetch';

function UpdateDealerDialog({ folderId, dealer, refreshFolder }) {
  const { close } = useOutletContext();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Format d'email invalide")
      .required('Champ requis'),
    lastname: yup.string().required('Champ requis'),
    firstname: yup.string().required('Champ requis'),
    tel: yup
      .string()
      .trim()
      .phone('Generic.Fields.InvalidFormat')
      .required('Champ requis'),
  });

  const initialValues = {
    email: dealer?.Email,
    phoneNumber: dealer?.Tel,
  };
  const submitForm = useFetch({
    method: 'PUT',
    url: `/nexity/dashboard/folders/${folderId}/folderInformations/updateDealer`,
    onSuccess: () => {
      close();
      refreshFolder();
    },
  });

  const onSubmitCallback = React.useCallback(
    values => {
      submitForm.doFetch({ body: { dealer: values } });
    },
    [submitForm]
  );

  return (
    <FormikProvider>
      <Formik
        schema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmitCallback}
      >
        <Form>
          <DialogTitleWithClose onClose={close}>
            Modifier le numéro de téléphone du vendeur
          </DialogTitleWithClose>
          <DialogContent>
            <FormikPhone
              sx={{ mb: 2 }}
              as="fastfield"
              margin="dense"
              name="phoneNumber"
              label="Téléphone"
              defaultCountry="fr"
            />
          </DialogContent>
          <DialogActionsWithClose onClose={close}>
            <Button type="submit" variant="contained">
              Enregistrer
            </Button>
          </DialogActionsWithClose>
        </Form>
      </Formik>
    </FormikProvider>
  );
}

export default UpdateDealerDialog;
